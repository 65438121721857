// @flow
import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import {Switch} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
};

const VisitorCategoryFieldRequiredInImport = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props

    const toggleVisitorCategoryRequiredInImport = () => {
        updateExhibition(id, !exhibition.visitorCategoryRequiredInImport, Update.VisitorCategoryRequired)
    }

    return (
        <ListItem>
            <ListItemText
                primary={t('Visitor Category Field Required In Import')}
                secondary={t('If you enable this, then Characterization Column is required when importing visitors from excel file.')}
            />
            <Switch
                edge="end"
                onChange={toggleVisitorCategoryRequiredInImport}
                checked={exhibition.visitorCategoryRequiredInImport}
            />
        </ListItem>
    );
};

export default VisitorCategoryFieldRequiredInImport