// @flow
import React, {useMemo} from 'react';
import Box from "@mui/material/Box";
import useUserData from "../../../../../../../hooks/user/useUserData";
import {muiGridLocales} from "../../../../../../../helpers/muiGridLocales";
import {DataGridPremium, GridToolbar, useGridApiRef, useKeepGroupedColumnsHidden} from "@mui/x-data-grid-premium";
import {
	CustomPagination
} from "../../../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import {formatDate} from "../../../../../../../helpers/date";
import dayjs from "dayjs";

type Props = {};

const VisitsDay = (props: Props) => {
	const {loading, data, t, generalReport} = props
	const {language, dateFormat} = useUserData()
	
	const apiRef = useGridApiRef();
	const initialState = useKeepGroupedColumnsHidden({
		apiRef,
		initialState: {
			density: "compact",
			rowGrouping: {
				model: generalReport ? ['groupingColumn'] : ['exhibitionStartDt']
			}
		},
	});
	
	const columns = useMemo(() => [
			...(generalReport ? [{
				field: 'groupingColumn',
				description: t('Exhibition'),
				headerName: t('Exhibition'),
				width: 800,
			}] : []),
			...(!generalReport ? [{
				field: 'exhibitionStartDt',
				description: t('Exhibition Date'),
				headerName: t('Exhibition Date'),
				width: 400,
				valueFormatter: (value, row, column, apiRef) => {
					return `${formatDate(row.exhibitionStartDt, dateFormat)} / ${row.exhibition}`
				}
			}] : []),
			{
				field: 'entranceDate',
				description: t('EntranceDate'),
				headerName: t('EntranceDate'),
				flex: 1,
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format(dateFormat) : '')
				}
			},
			{
				field: 'entranceLobby1Count',
				description: t('EntranceLobby1Count'),
				headerName: t('EntranceLobby1Count'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'entranceLobby2Count',
				description: t('EntranceLobby2Count'),
				headerName: t('EntranceLobby2Count'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'entranceLoungeCount',
				description: t('EntranceLoungeCount'),
				headerName: t('EntranceLoungeCount'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'entranceCount',
				description: t('EntranceCount'),
				headerName: t('EntranceCount'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'visitorCount',
				description: t('VisitorCount'),
				headerName: t('VisitorCount'),
				flex: 1,
				type: 'number',
			},
		],
		[dateFormat, generalReport, t]
	)
	
	return (
		<Box style={{height: 600, width: '100%'}}>
			<DataGridPremium
				apiRef={apiRef}
				sx={{bgcolor: 'background.paper'}}
				loading={loading}
				initialState={initialState}
				localeText={muiGridLocales(language)}
				rows={data}
				columns={columns}
				pagination
				slots={{
					toolbar: GridToolbar,
					pagination: CustomPagination
				}}
				slotProps={{toolbar: {csvOptions: {disableToolbarButton: true}}}}
				defaultGroupingExpansionDepth={-1}
			/>
		</Box>
	);
};

export default VisitsDay