// @flow
import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {Field} from "formik";
import WysiwygEditor from "../../../../../components/form/editor/editor";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {Paper} from "@mui/material";

type Props = {};

const EditorByLanguage = (props: Props) => {
	const {language, t} = props
	return (
		<Paper sx={{px: 2, pb: 1}} variant={"outlined"}>
			<Toolbar disableGutters variant={"dense"}>
				<Typography variant="h6">
					{t('Text')}
				</Typography>
			</Toolbar>
			<Divider sx={{mb: 1}} light/>
			<Box sx={{width: '100%'}}>
				<Field
					name={"body." + language}
					component={WysiwygEditor}
					minHeight={'400px'}
				/>
			</Box>
			<Stack direction="row" spacing={1} sx={{mt: 1}}>
				<Button
					size={"small"}
					type={"submit"}
					startIcon={<SaveIcon/>}
					variant="contained"
				>
					{normalizeGreek(t('Save'))}
				</Button>
			</Stack>
		</Paper>
	);
};

export default EditorByLanguage