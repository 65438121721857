// @flow
import React from 'react';
import Box from "@mui/material/Box";
import RenderField from "../renderField/renderField";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";

type Props = {

};

const RenderFieldInGroups = (props: Props) => {
    const {fields, autoMargins = true, formik, values, handleBlur, handleChange, touched, errors} = props
    const {t} = useTranslation(EXHIBITION)
    const companyFieldGroup = fields.filter(f => f.groupOfCompany === true)
    const privateFieldGroup = fields.filter(f => f.groupOfCompany !== true)
    return (
        <React.Fragment>
            <div style={{marginBottom: "2px"}}>
                <Box
                    sx={{mb: 1}}
                    style={{padding: "4px", textAlign: "center", fontWeight: "bold", backgroundColor: "#C1C5C7", fontSize: "1.1rem"}}
                >
                    {t('Company profile group')}
                </Box>
                {companyFieldGroup
                    .map(f => (
                        <Box
                            sx={{mb: 1}}
                            key={f.id}
                        >
                            <RenderField
                                formik={formik}
                                values={values}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                fields={fields}
                                field={f}
                            />
                        </Box>
                    ))}
            </div>
            <div>
                <Box
                    sx={{mb: 1}}
                    style={{padding: "4px", textAlign: "center", fontWeight: "bold", backgroundColor: "#C1C5C7", fontSize: "1.1rem"}}
                >
                    {t('Personal profile group')}
                </Box>
                {privateFieldGroup
                    .map(f => (
                        <Box
                            sx={{mb: 1}}
                            key={f.id}
                        >
                            <RenderField
                                formik={formik}
                                values={values}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                fields={fields}
                                field={f}
                            />
                        </Box>
                    ))}
            </div>
        </React.Fragment>
    );
};

export default RenderFieldInGroups