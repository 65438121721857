import React from 'react';
import update from 'immutability-helper';

import {isNextSibling, isPrevSibling, move, updateDepth} from './utils';
import useAnimationFrame from './useAnimationFrame';
import context from './context';
import SortlyContext from './sortlyContext';
import ItemContext from './itemContext';
import Item from './Item';


const isRef = (obj: any) => (
    // eslint-disable-next-line no-prototype-builtins
    obj !== null && typeof obj === 'object' && obj.hasOwnProperty('current')
);

const getElConnectableElement = (connectedDropTarget) => {
    if (!connectedDropTarget) {
        return null;
    }
    const connectable = connectedDropTarget.current || connectedDropTarget;
    if (!connectable) {
        return null;
    }
    return isRef(connectable)
        ? (connectable.current) : (connectable);
};


const detectMove = (items, dragMonitor, dragId, targetId, dropEl, horizontal) => {
    const pointerOffset = dragMonitor.getClientOffset();

    if (!pointerOffset) {
        return items;
    }

    const targetBoundingRect = dropEl.getBoundingClientRect();
    const sourceIndex = items.findIndex(({id}) => id === dragId);
    const targetIndex = items.findIndex(({id}) => id === targetId);

    if (sourceIndex === -1 || targetIndex === -1) {
        return items;
    }

    if (!horizontal) {
        const hoverMiddleY = (targetBoundingRect.bottom - targetBoundingRect.top) / 2;
        const hoverClientY = pointerOffset.y - targetBoundingRect.top;
        if (
            (hoverClientY < hoverMiddleY && isNextSibling(items, sourceIndex, targetIndex)) // Dragging downwards
            || (hoverClientY > hoverMiddleY && isPrevSibling(items, sourceIndex, targetIndex)) // Dragging upwards
        ) {
            return items;
        }
    } else {
        const hoverMiddleX = (targetBoundingRect.right - targetBoundingRect.left) / 2;
        const hoverClientX = pointerOffset.x - targetBoundingRect.left;

        if (
            (hoverClientX < hoverMiddleX && isNextSibling(items, sourceIndex, targetIndex)) // Dragging forwards
            || (hoverClientX > hoverMiddleX && isPrevSibling(items, sourceIndex, targetIndex)) // Dragging backwards
        ) {
            return items;
        }
    }

    return move(items, sourceIndex, targetIndex);
};

/**
 * @hidden
 */
const detectIndent = (items, dragMonitor, dragId, dragEl, threshold, initialDepth, maxDepth) => {
    if (maxDepth === 0) {
        return items;
    }
    const sourceClientOffset = dragMonitor.getSourceClientOffset();
    if (!sourceClientOffset) {
        return items;
    }
    const boundingRect = dragEl.getBoundingClientRect();
    const movementX = sourceClientOffset.x - boundingRect.left;
    if (Math.abs(movementX) < threshold) {
        return items;
    }
    const index = items.findIndex(({id}) => id === dragId);
    if (index === -1) {
        return items;
    }
    const item = items[index];
    const depth = item.depth + (movementX > 0 ? 1 : -1);
    return updateDepth(items, index, depth, maxDepth);
}

const typeSeq = (() => {
    let seq = 0;
    return () => {
        seq += 1;
        return `SORTLY-${seq}`;
    };
})()

function Sortly(props) {
    const {
        type: typeFromProps,
        items,
        children,
        threshold = 20,
        maxDepth = Infinity,
        horizontal,
        onChange,
        onDragEnd
    } = props;
    const [type, setType] = React.useState(typeFromProps || typeSeq());
    React.useEffect(() => {
        if (typeFromProps) {
            setType(typeFromProps)
        }
    }, [typeFromProps])
    const {dragMonitor, connectedDragSource, initialDepth} = React.useContext(context);
    const dndData = React.useRef({});
    const [startAnim, stopAnim, isActive] = useAnimationFrame(React.useCallback(() => {
        const {dropTargetId, connectedDropTarget} = dndData.current;
        if (!dragMonitor) {
            return;
        }
        const dragItem = dragMonitor.getItem();
        if (!dragItem) {
            return;
        }
        const {id: dragId} = dragItem;
        let newItems;
        if (!dropTargetId || dragId === dropTargetId) {
            const el = getElConnectableElement(connectedDragSource);
            if (initialDepth !== undefined && el) {
                newItems = detectIndent(items, dragMonitor, dragId, el, threshold, initialDepth, maxDepth);
            }
        } else if (connectedDropTarget) {
            const dropElement = getElConnectableElement(connectedDropTarget);
            if (dropElement) {
                newItems = detectMove(items, dragMonitor, dragId, dropTargetId, dropElement, horizontal);
            }
        }
        if (newItems && newItems !== items) {
            onChange(newItems);
        }
    }, [connectedDragSource, dragMonitor, horizontal, initialDepth, items, maxDepth, onChange, threshold]));
    const handleHoverBegin = React.useCallback(
        (id, connectedDropTarget) => {
            dndData.current = update(dndData.current, {
                dropTargetId: {$set: id}, connectedDropTarget: {$set: connectedDropTarget}
            });
        }, []
    )
    const handleHoverEnd = React.useCallback((id) => {
        if (dndData.current.dropTargetId === id) {
            dndData.current = update(dndData.current, {
                dropTargetId: {$set: undefined}, connectedDropTarget: {$set: undefined}
            });
        }
    }, []);
    React.useEffect(() => {
        if (dragMonitor) {
            startAnim()
        } else {
            stopAnim()
        }
        return () => {
            stopAnim()
        }
    }, [dragMonitor, startAnim, stopAnim]);

    React.useEffect(() => {
        if (!isActive) {
            onDragEnd()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);
    return (
        <SortlyContext.Provider value={{items}}>
            {items.map((data, index) => (
                <ItemContext.Provider
                    key={data.id}
                    value={{
                        index,
                        id: data.id,
                        type,
                        depth: data.depth,
                        data,
                        onHoverBegin: handleHoverBegin,
                        onHoverEnd: handleHoverEnd,
                    }}
                >
                    <Item
                        index={index}
                        id={data.id}
                        depth={data.depth}
                        data={data}
                    >
                        {children}
                    </Item>
                </ItemContext.Provider>
            ))}
        </SortlyContext.Provider>
    )
}

export default Sortly;
