import {eventStatus} from "./date";
import type {Exhibition} from "../types/exhibition/types";
import {normalizeGreek} from "./normalizeGreek";

type FilterParameters = {
    status?: Array<number>,
    query?: string
}

export const filterExhibitions = (items: Array<Exhibition>, filterFunctionParameters: FilterParameters) => {
    const {query, status: paramsStatus} = filterFunctionParameters
    let tempItems = items
    if (query && query !== '') {
        tempItems = tempItems.filter(i => i.title.toLowerCase().includes(query.toLowerCase()))
    }
    if (paramsStatus && paramsStatus.length > 0) {
        tempItems = tempItems.filter(i => {
            const {dateFrom, dateTo} = i
            const status = eventStatus(dateFrom, dateTo)
            return (
                (paramsStatus.indexOf(eventStatus.status.EXPIRED) > -1 && status === eventStatus.status.EXPIRED) ||
                (paramsStatus.indexOf(eventStatus.status.UPCOMING) > -1 && status === eventStatus.status.UPCOMING) ||
                (paramsStatus.indexOf(eventStatus.status.ONGOING) > -1 && status === eventStatus.status.ONGOING)
            )
        })
    }
    return tempItems
}

export const filterHeaders = (items, filterFunctionParameters: FilterParameters) => {
    const {query} = filterFunctionParameters
    let tempItems = items
    if (query && query !== '') {
        tempItems = tempItems.filter(i => normalizeGreek(i.description.toLowerCase()).includes(normalizeGreek(query.toLowerCase())))
    }
    return tempItems
}
