import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {parseUserMessage} from "../../types/user/parse";
import {parseReports} from "../../types/reports/parse";

const selectLoading = createSelector(
	(state) => state.reports,
	(item) => item.get('loading')
)

const selectMessage = createSelector(
	(state) => state.reports.get('message'),
	(item) => parseUserMessage(item)
)

const selectReports = createSelector(
	[
		(state) => state.reports.get('data'),
		(state) => state.currentUser.get('language')
	],
	(data, language) => parseReports(data, language)
)

export const useReportsData = () => {
	const loading = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const reports = useSelector(selectReports)
	return {
		loading,
		message,
		reports
	}
}