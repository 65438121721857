import {useParams} from "react-router";
import useExhibitionAction from "./useExhibitionAction";
import useExhibitionData from "./useExhibitionData";
import {useEffect} from "react";
import {useExhibitionFileAction} from "../exhibitionFIles/useExhibitionFileAction";
import {useExhibitionFileData} from "../exhibitionFIles/useExhibitionFileData";

export const useExhibitor = ({language, overrideExhibitorId, section} = {}) => {
	const {exhibitorId: paramsId, id: exhibitionId} = useParams()
	const id = overrideExhibitorId ?? paramsId
	const {files, loading: loadingFiles} = useExhibitionFileData({exhibitorId: id})
	const {exhibitorById: exhibitor, loading} = useExhibitionData({
		exhibitorId: id,
		id: exhibitionId,
		overrideLanguage: language
	})
	const {getExhibitionFiles} = useExhibitionFileAction()
	const {getExhibitorProfile} = useExhibitionAction()
	useEffect(() => {
		getExhibitorProfile(id, exhibitionId)
	}, [getExhibitorProfile, id, exhibitionId, section])
	useEffect(() => {
		getExhibitionFiles(exhibitionId, id)
	}, [exhibitionId, getExhibitionFiles, id])
	return {
		id,
		exhibitionId,
		exhibitor,
		files,
		loadingFiles,
		loading
	}
}