// @flow 
import React from 'react';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {normalizeGreek} from "../../../../../../helpers/normalizeGreek";

type Props = {};

const NewViewListButton = (props: Props) => {
	const {t, label, onLabelChange, onSubmit, isValid} = props;
	const [isAddingView, setIsAddingView] = React.useState(false);
	
	const handleSubmitForm = (event) => {
		onSubmit();
		setIsAddingView(false);
		event.preventDefault();
	};
	return (
		<React.Fragment>
			<Button
				endIcon={<AddIcon/>}
				size="small"
				onClick={() => setIsAddingView(true)}
			>
				{normalizeGreek(t('Save current view'))}
			</Button>
			<Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
				<form onSubmit={handleSubmitForm}>
					<DialogTitle>{t('New custom view')}</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							value={label}
							onChange={onLabelChange}
							margin="dense"
							size="small"
							label={t('Custom view label')}
							variant="standard"
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button type="button" onClick={() => setIsAddingView(false)}>
							{normalizeGreek(t('Cancel'))}
						</Button>
						<Button type="submit" disabled={!isValid}>
							{normalizeGreek(t('Create view'))}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
};

export default NewViewListButton