//@flow
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {PreregistrationRegions, PreregistrationRegionsApproval} from "../../../../../../helpers/constants";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
}

const PreregistrationCountriesApproval = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props
    const onChange = (value) => {
        updateExhibition(id, value, Update.PreregistrationApprove)
    }
    const approvalOptions = useMemo(() => {
        const options = []
        if (exhibition.preregistrationCountry === PreregistrationRegions.GreeceOnly || exhibition.preregistrationCountry === PreregistrationRegions.Everywhere) {
            options.push(<MenuItem
                key={PreregistrationRegionsApproval.GreeceOnly}
                value={PreregistrationRegionsApproval.GreeceOnly}
            >
                {t('Greece')}
            </MenuItem>)
        }
        if (exhibition.preregistrationCountry === PreregistrationRegions.AbroadOnly || exhibition.preregistrationCountry === PreregistrationRegions.Everywhere) {
            options.push(<MenuItem
                key={PreregistrationRegionsApproval.AbroadOnly}
                value={PreregistrationRegionsApproval.AbroadOnly}
            >
                {t('Abroad')}
            </MenuItem>)
        }
        if (exhibition.preregistrationCountry === PreregistrationRegions.Everywhere) {
            options.push(<MenuItem
                key={PreregistrationRegionsApproval.Everywhere}
                value={PreregistrationRegionsApproval.Everywhere}
            >
                {t('Everywhere')}
            </MenuItem>)
        }
        if (!exhibition.preregistrationNeedsApproval) {
            options.push(<MenuItem
                key={PreregistrationRegionsApproval.NoApproval}
                value={PreregistrationRegionsApproval.NoApproval}
            >
                {t('No Approval')}
            </MenuItem>)
        }
        return options
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exhibition.preregistrationCountry, exhibition.preregistrationApprovePerCountry, exhibition.preregistrationNeedsApproval, t])
    return (
        <ListItem disabled={!exhibition.preregistrationEnabled && exhibition.preregistrationNeedsApproval}>
            <ListItemText
                primary={t("Preregistration Approval Type")}
                secondary={t("Select the countries that preregistration approval is required.")}
            />
            <Select
                labelId={'preregistrationCountryApprovalLabel'}
                variant={'standard'}
                disabled={!exhibition.preregistrationEnabled || !exhibition.preregistrationNeedsApproval}
                value={exhibition.preregistrationApprovePerCountry}
                onChange={(evt) => onChange(evt.target.value)}
                size={"small"}
                sx={{minWidth: '220px'}}
            >
                {approvalOptions}
            </Select>
        </ListItem>
    )
}

export default PreregistrationCountriesApproval
