// @flow
import React from 'react';
import Dialog from '@mui/material/Dialog';
import type {BadgeTemplateTypes} from "../../../types/badge/types";
import Form from "./form";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import type {VisitorCategory} from "../../../types/categories/type";

type Props = {
    category?: Object<VisitorCategory>,
    open: boolean,
    onClose: Function,
    exhibitionId: string,
    saveVisitorCategory: Function,
    updateVisitorCategory: Function,
    badgeTemplateTypes: Array<BadgeTemplateTypes>
};

const AddVisitorCategory = (props: Props) => {
    const {
        open,
        onClose,
        exhibitionId,
        category,
        saveVisitorCategory,
        updateVisitorCategory,
        badgeTemplateTypes
    } = props
    const {t} = useTranslation(EXHIBITION)
    const formTitle = category && category.id ? t('Edit visitor category') : t('Add visitor category')

    const initialValues = {
        textColor: category ? category.textColor : '',
        backgroundColor: category ? category.backgroundColor : '',
        text: {
            el: category && category.text && category.text.el ? category.text.el : '',
            en: category && category.text && category.text.en ? category.text.en : ''
        },
        explanation: {
            el: category && category.explanation && category.explanation.el ? category.explanation.el : '',
            en: category && category.explanation && category.explanation.en ? category.explanation.en : '',
        },
        displayVisitorLabelInBadge: category ? category.displayVisitorLabelInBadge : true,
        displayBadgeInEnglish: category ? category.displayBadgeInEnglish : false,
        showPreregistration: category ? category.showPreregistration : false,
        showOrganizer: category ? category.showOrganizer : true,
        badgeTemplateTypeId: category && category.badgeTemplateTypeId ? category.badgeTemplateTypeId : badgeTemplateTypes.length > 0 && badgeTemplateTypes[0].id ? badgeTemplateTypes[0].id : '',
        regBadgeTemplateTypeId: category && category.regBadgeTemplateTypeId ? category.regBadgeTemplateTypeId : badgeTemplateTypes.length > 0 && badgeTemplateTypes[0].id ? badgeTemplateTypes[0].id : ''
    }

    const onSubmit = (values, {resetForm}) => {
        if (category && category.id) {
            updateVisitorCategory(category.id, values)
        } else {
            saveVisitorCategory(values)
        }
        resetForm()
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <Form
                title={formTitle}
                onClose={onClose}
                badgeTemplateTypes={badgeTemplateTypes}
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        </Dialog>
    );
};

export default AddVisitorCategory