// @flow
import * as React from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../../../locales/components/namespaces";

type Props = {
    visitorCategoryRequiredInImport: boolean,
    limitedView: boolean
};

export const Organizer = ({limitedView, visitorCategoryRequiredInImport}: Props) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    return (
        <TableContainer component={Paper} variant={"outlined"} sx={{mb: 1}}>
            <Table sx={{minWidth: 650}} size={"small"}>
                <TableHead>
                    <TableRow>

                        <TableCell>{t('ErpCode')}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{t('Name')}</TableCell>
                        <TableCell>{t('Vat')}</TableCell>
                        <TableCell>{t('Email')}</TableCell>
                        <TableCell>{t('Phone')} ({t('optional')})</TableCell>
                        <TableCell>{t('Mobile Phone')} ({t('optional')})</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{t('City')} ({t('optional')})</TableCell>
                        <TableCell>{t('Address')} ({t('optional')})</TableCell>
                        <TableCell>{t('Country ERP Code')} ({t('optional')})</TableCell>
                        <TableCell>{t('Postal Code')} ({t('optional')})</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{t('Interests (ERP Codes)')} ({t('optional')})</TableCell>
                        {!limitedView && <TableCell>{`${t('Characterization')} ${!visitorCategoryRequiredInImport ? t('optional') : ''}`}</TableCell>}
                        <TableCell>{t('Position')} ({t('optional')})</TableCell>
                        <TableCell>{t('Other Interests')} ({t('optional')})</TableCell>
                        <TableCell>{t('Company Name')} ({t('optional')})</TableCell>
                        <TableCell>{t('Company Firm')} ({t('optional')})</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell>0000541</TableCell>
                        <TableCell></TableCell>
                        <TableCell>ALEXANDRA ALEXANDRI</TableCell>
                        <TableCell>021198312</TableCell>
                        <TableCell>someone@email.com</TableCell>
                        <TableCell>266666666</TableCell>
                        <TableCell><strong>30</strong>6996969696</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>ATHENS</TableCell>
                        <TableCell>ALEXANDRAS 45</TableCell>
                        <TableCell>ΕΛΛΑΣ</TableCell>
                        <TableCell>26264</TableCell>
                        <TableCell></TableCell>
                        <TableCell>20000,20700</TableCell>
                        {!limitedView && <TableCell>VIP</TableCell>}
                        <TableCell>Gifts</TableCell>
                        <TableCell>ERGOLOGIC</TableCell>
                        <TableCell>ERGOLOGIC</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};