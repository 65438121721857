// @flow
import dayjs from "dayjs";
import React, { useMemo } from 'react';
import Box from "@mui/material/Box";
import useUserData from "../../../../../../../hooks/user/useUserData";
import { muiGridLocales } from "../../../../../../../helpers/muiGridLocales";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import {
	CustomPagination
} from "../../../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";

type Props = {};

const DefaultPartnerScans = (props: Props) => {
	const { loading, data, t } = props
	const { language, dateFormat } = useUserData()
	
	const columns = useMemo(() => [
			{
				field: 'entryDate',
				description: t('EntranceDate'),
				headerName: t('EntranceDate'),
				flex: 1,
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format(dateFormat) : '')
				}
			},
			{
				field: 'countCoorganization',
				description: t('Number of scans for default partner badge.'),
				headerName: t('Coorganization Scans'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'countExhibition',
				description: t('Number of scans for default partner badge.'),
				headerName: t('Exhibition Scans'),
				flex: 1,
				type: 'number',
			},
		],
		[dateFormat, t]
	)
	
	return (
		<Box style={{ height: 400, width: '100%' }}>
			<DataGridPremium
				sx={{ bgcolor: 'background.paper' }}
				loading={loading}
				initialState={{
					density: "compact"
				}}
				localeText={muiGridLocales(language)}
				rows={data}
				columns={columns}
				pagination
				slots={{
					toolbar: GridToolbar,
					pagination: CustomPagination
				}}
				slotProps={{ toolbar: { csvOptions: { disableToolbarButton: true } } }}
			/>
		</Box>
	);
};

export default DefaultPartnerScans