//@flow
// noinspection ES6UnusedImports

import React from 'react'
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import {Editor} from '@tinymce/tinymce-react'
import 'tinymce/plugins/image'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/table'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import './editor.css'
import Box from "@mui/material/Box";
import './el'
import {useEditorParameters} from "./useEditorParameters";

type Props = {
    label?: string,
    field: {
        name: string,
        value: string,
    },
    form: {
        handleChange?: Function,
        handleBlur?: Function,
        initialValues: Object
    }
}

const WysiwygEditor = (props: Props) => {
    const {editorType, minHeight, ...otherProps} = props
    const parameters = useEditorParameters(otherProps, editorType)

    return (
        <Box sx={{
            '& .tox-tinymce': {
                minHeight: minHeight
            }
        }}>
            <Editor
                {...parameters}
            />
        </Box>
    )
}

export default WysiwygEditor
