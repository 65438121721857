export const FAST_SCAN_BADGE = 'FAST_SCAN_BADGE'
export const FAST_SCAN_BADGE_SUCCEEDED = 'FAST_SCAN_BADGE_SUCCEEDED'
export const FAST_SCAN_BADGE_FAILED = 'FAST_SCAN_BADGE_FAILED'

export const SCAN_BADGE = 'SCAN_BADGE'
export const SCAN_BADGE_SUCCEEDED = 'SCAN_BADGE_SUCCEEDED'
export const SCAN_BADGE_FAILED = 'SCAN_BADGE_FAILED'
export const CLEAR_SCANNED_BADGE = 'CLEAR_SCANNED_BADGE'
export const REGISTER_NEW_SCANNED_BADGE = 'REGISTER_NEW_SCANNED_BADGE'

export const GET_BADGE = 'GET_BADGE'
export const GET_BADGE_SUCCEEDED = 'GET_BADGE_SUCCEEDED'
export const GET_BADGE_FAILED = 'GET_BADGE_FAILED'

export const GET_BADGE_CONFIGURATION = 'GET_BADGE_CONFIGURATION'
export const GET_BADGE_CONFIGURATION_SUCCEEDED = 'GET_BADGE_CONFIGURATION_SUCCEEDED'
export const GET_BADGE_CONFIGURATION_FAILED = 'GET_BADGE_CONFIGURATION_FAILED'

export const UPDATE_BADGE_CONFIGURATION = 'UPDATE_BADGE_CONFIGURATION'
export const UPDATE_BADGE_CONFIGURATION_SUCCEEDED = 'UPDATE_BADGE_CONFIGURATION_SUCCEEDED'
export const UPDATE_BADGE_CONFIGURATION_FAILED = 'UPDATE_BADGE_CONFIGURATION_FAILED'

export const FAST_SCAN = 'FAST_SCAN'
export const FAST_SCAN_SUCCEEDED = 'FAST_SCAN_SUCCEEDED'
export const FAST_SCAN_FAILED = 'FAST_SCAN_FAILED'

export const GET_BADGE_TEMPLATE_TYPES = 'GET_BADGE_TEMPLATE_TYPES'
export const GET_BADGE_TEMPLATE_TYPES_SUCCEEDED = 'GET_BADGE_TEMPLATE_TYPES_SUCCEEDED'
export const GET_BADGE_TEMPLATE_TYPES_FAILED = 'GET_BADGE_TEMPLATE_TYPES_FAILED'
