//@flow
import React, {useCallback, useEffect} from 'react';
import type {Exhibition} from "../../types/exhibition/types";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import NoContentSection from "../../components/general/noContentSection/noContentSection";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {normalizeGreek} from "../../helpers/normalizeGreek";
import {useFAQData} from "../../hooks/faqs/useFAQData";
import {useFAQAction} from "../../hooks/faqs/useFAQAction";
import DragDropTree from "../../components/general/dragDropTree/dragDropTree";
import FormRow from "./partials/formRow";
import {useMultilingualText} from "../../hooks/useMultilingualText";
import {useDragDropTree} from "../../components/general/dragDropTree/useDragDropTree";

type Props = {
    exhibition: Exhibition
}

export const TopicFormContext = React.createContext(null)

const RenderTitle = ({data: f}) => {
    const title = useMultilingualText(f.title)
    return title
}

const FaqsSettings = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {exhibition} = props
    const {getFaqs, createFaq, reorderFaqs} = useFAQAction()
    const {faqs, loading} = useFAQData({exhibitionId: exhibition.id, overrideLanguage: 'any'})
    useEffect(() => {
        getFaqs(exhibition.id)
    }, [exhibition.id, getFaqs])
    const onAdd = () => {
        createFaq(exhibition.id)
    }

    const onDragEndCallback = useCallback((updatedItems) => {
        reorderFaqs(exhibition.id, updatedItems)
    }, [exhibition.id, reorderFaqs])

    const {itemsList: faqsList, onDragEnd} = useDragDropTree({
        unprocessedItems: faqs,
        onDragEndCallback: onDragEndCallback
    })


    const addButton = (
        <Button
            sx={{my: 1}}
            size={"small"}
            variant={"contained"}
            startIcon={<AddIcon/>}
            type="button" onClick={onAdd}>
            {normalizeGreek(t('Add topic'))}
        </Button>
    )
    return (
        <TopicFormContext.Provider
            value={{
                loading: loading,
                exhibitionId: exhibition.id,
            }}
        >
            {faqs && faqs.length > 0 ? (
                <React.Fragment>
                    <DragDropTree
                        items={faqsList}
                        renderItemTitle={RenderTitle}
                        renderItemActions={FormRow}
                        onDragEnd={onDragEnd}
                    />
                    {addButton}
                </React.Fragment>
            ) : loading ? (
                <ContentLoader
                    loading={loading}
                />
            ) : (
                <NoContentSection
                    title={t("Menu not configured yet")}
                    message={
                        <Box>
                            {t('You can create your first topic by clicking the button below.')}
                        </Box>
                    }
                    cta={addButton}
                />
            )}
        </TopicFormContext.Provider>
    )
}

export default FaqsSettings;
