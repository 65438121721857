//@flow
import React from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import CustomizePreregistration from "../../../../components/app/customizePreregistration/customizePreregistration";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import {usePreregistrationForm} from "../../../../hooks/usePreregistrationForm";

const PreregistrationFields = () => {
    const {t} = useTranslation(EXHIBITION)
    const {exhibition, loading, id} = useExhibition()
    const {fields} = usePreregistrationForm({exhibition})
    return (
        <React.Fragment>
            {exhibition ? (
                <React.Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                            {t('Home')}
                        </Breadcrumb.Link>
                        <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
                            {exhibition.title}
                        </Breadcrumb.Link>
                        <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
                            {t('Settings')}
                        </Breadcrumb.Link>
                        <Breadcrumb.Item>
                            {t('Preregistration')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t('Field Settings')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <CustomizePreregistration
                        exhibitionId={id}
                        fields={fields}
                    />
                </React.Fragment>
            ) : (
                <ContentLoader
                    loading={loading}
                    notFoundWhenNotLoading
                />
            )}
        </React.Fragment>
    )
}

export default PreregistrationFields;
