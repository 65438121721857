import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

type Option = {
    id: string,
    label: string
}

type Props = {
    selected: Array<string>,
    options: Array<Option>,
    onSelectionChange: Function
}

export default function TransferList(props: Props) {
    const {selected, options, onSelectionChange} = props
    const [checked, setChecked] = React.useState([])

    const left = options.filter(o => !selected.includes(o.id)).map(o => o.id)
    const right = selected

    const leftOptions = options.filter(o => !selected.includes(o.id))
    const rightOptions = options.filter(o => selected.includes(o.id))

    const leftChecked = intersection(checked, left)
    const rightChecked = intersection(checked, right)

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setChecked(newChecked)
    }

    const numberOfChecked = (items) => intersection(checked, items).length

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items))
        } else {
            setChecked(union(checked, items))
        }
    }

    const handleCheckedRight = () => {
        onSelectionChange(right.concat(leftChecked))
        setChecked(not(checked, leftChecked))
    }

    const handleCheckedLeft = () => {
        onSelectionChange(not(right, rightChecked))
        setChecked(not(checked, rightChecked))
    }

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{px: 2, py: 1}}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider/>
            <List
                sx={{
                    minWidth: 200,
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                disablePadding
                component="div"
                role="list"
            >
                {items.map((item: Option) => {
                    const labelId = `transfer-list-all-item-${item.id}-label`;
                    return (
                        <ListItem
                            key={item.id}
                            role="listitem"
                            button
                            onClick={handleToggle(item.id)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={item.label}
                            />
                        </ListItem>
                    );
                })}
                <ListItem/>
            </List>
        </Card>
    )
    return (
        <Grid container spacing={2}>
            <Grid item>{customList('Choices', leftOptions)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Chosen', rightOptions)}</Grid>
        </Grid>
    );
}