// @flow
import React, {useMemo, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, ListItem, ListItemAvatar} from "@mui/material";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 8,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
}

const focusedStyle = {
    borderColor: '#2196f3'
}

const acceptStyle = {
    borderColor: '#00e676'
}

const rejectStyle = {
    borderColor: '#ff1744'
}

const ImageThumb = ({file, onRemove}) => {
    return (
        <ListItem
            disablePadding
            secondaryAction={
                <IconButton
                    color={"error"}
                    size={"small"}
                    onClick={onRemove}
                >
                    <DeleteIcon/>
                </IconButton>
            }
        >
            <ListItemAvatar>
                <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{width: 128, mr: .5, display: "flex"}}>
                    <img
                        src={file.preview}
                        alt="preview"
                        style={{maxWidth: "100%", height: "auto"}}
                    />
                </Box>
            </ListItemAvatar>
        </ListItem>
    )
}

type Props = {
    accept?: string,
    multiple?: boolean,
    onSelected: Function,
    onRemove: Function,
    files?: Array<{
        name: string,
        preview: string,
    }>
}

function ImageFileUpload(props: Props) {
    const {t} = useTranslation()
    const {files: initialFiles, multiple, accept, onSelected, onRemove} = props
    const [files, setFiles] = useState(initialFiles)
    const [filesInitial, setFilesInitial] = useState([])
    const {
        isFocused,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple,
        accept,
        onDrop: acceptedFiles => {
            onSelected(acceptedFiles)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }
    })

    const remove = file => {
        const newFiles = [...files]
        const newFilesInitial = [...filesInitial]
        newFiles.splice(file, 1)
        newFilesInitial.splice(file, 1)
        setFiles(newFiles)
        setFilesInitial(newFilesInitial)
        onRemove(newFilesInitial)
    }

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ])

    return (
        <Box sx={{width: "100%", flexGrow: 1,}}>
            {!(files && files.length > 0 && !multiple) && (
                <div {...getRootProps({style, className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>
                </div>
            )}
            {files && files.length > 0 && (
                <List sx={{minWidth: 256}}>
                    {files.map((file, i) => (
                        <ImageThumb
                            key={file.name}
                            file={file}
                            onRemove={() => remove(i)}
                        />
                    ))}
                </List>
            )}
        </Box>
    )
}

export default ImageFileUpload