// @flow
import React from 'react';
import {Field, FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {FormControl} from "@mui/material";
import TextInput from "../../../form/textfield/textfield";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import Popover from "@mui/material/Popover";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../locales/components/namespaces";
import * as yup from "yup";

type Props = {};

const SendToOtherEmail = (props: Props) => {
	const {sendExhibitorInvitations, exhibitionId, sendToOtherEmail, handleSendToOtherEmail} = props
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	
	const handlePopoverClose = () => {
		handleSendToOtherEmail({anchorEl: null, open: false, exhibitorId: ''});
		formik.resetForm()
	}
	
	const onSubmit = (values) => {
		sendExhibitorInvitations(exhibitionId, 'ids', [sendToOtherEmail?.exhibitorId], values.email, false)
		handlePopoverClose()
	}
	
	const validationSchema = yup.object({
		email: yup
		.string()
		.email(t('Enter a valid email'))
		.required(t('required')),
	})
	
	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit
	})
	
	
	return (
		<Popover
			disableRestoreFocus
			onClose={handlePopoverClose}
			open={sendToOtherEmail.open}
			anchorEl={sendToOtherEmail.anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
		>
			<FormikProvider value={formik}>
				<Box component="form" onSubmit={formik.handleSubmit} sx={{width: '100%', minWidth: '300px'}}>
					<Box sx={{width: '100%', padding: '20px'}}>
						<FormControl variant="standard" color={'error'} fullWidth>
							<Field
								size={"small"}
								name={'email'}
								label={t("Email")}
								component={TextInput}
							/>
						</FormControl>
					</Box>
					<Box sx={{
						padding: '0 20px 20px',
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between'
					}}>
						<Button
							size={"small"}
							type={"submit"}
							variant={"contained"}
							startIcon={<SendIcon/>}
						>
							{t('Send')}
						</Button>
						<Button
							size={"small"}
							variant={"outlined"}
							startIcon={<CancelIcon/>}
							type={"button"}
							onClick={handlePopoverClose}
						>
							{normalizeGreek(t('Cancel'))}
						</Button>
					</Box>
				</Box>
			</FormikProvider>
		</Popover>
	);
};

export default SendToOtherEmail