import type {Badge, BadgeConfiguration, BadgeTemplateTypes, UnmappedBadgeConfiguration} from "./types";
import {parseMultiple, withLanguage} from "../../helpers/parse";
import {parseExhibition, parseExhibitor} from "../exhibition/parse";

export const parseBadge: Badge = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        type: datum.get('type'),
        statusId: datum.get('statusId'),
        approved: datum.get('approved'),
        statusComment: datum.get('statusComment'),
        template: {
            id: datum.getIn(['template', 'id']),
            colors: parseMultiple(datum.getIn(['template', 'colors']), (i) => i),
        },
        user: {
            name: withLanguage(language)(datum.getIn(['user', 'name'])),
            id: datum.getIn(['user', 'id']),
            company: datum.getIn(['user', 'company']),
            email: datum.getIn(['user', 'email']),
            role: datum.getIn(['user', 'role'])
        },
        info: withLanguage(language)(datum.get('info')),
        exhibition: parseExhibition(datum.get('exhibition'), language),
        referencedBy: parseExhibitor(datum.get('referencedBy'), language),
        preregistrationFormValues: datum.get('preregistrationFormValues')?.toJS(),
    }
}

export const parseBadgeConfiguration: BadgeConfiguration = (datum: UnmappedBadgeConfiguration) => {
    if (!datum) return
    return {
        content: withLanguage('any')(datum.get('content')),
        contentPartner: withLanguage('any')(datum.get('contentPartner')),
        colorPartner1: datum.get('colorPartner1'),
        colorPartner2: datum.get('colorPartner2'),
        colorFont: datum.get('colorFont'),
        colorVisitor1: datum.get('colorVisitor1'),
        colorVisitor2: datum.get('colorVisitor2'),
        colorProvider1: datum.get('colorProvider1'),
        colorProvider2: datum.get('colorProvider2'),
        colorStaff1: datum.get('colorStaff1'),
        colorStaff2: datum.get('colorStaff2'),
        colorOrganizer1: datum.get('colorOrganizer1'),
        colorOrganizer2: datum.get('colorOrganizer2'),
        visitorBadgeTemplateTypeId: datum.get('visitorBadgeTemplateTypeId'),
        visitorRegBadgeTemplateTypeId: datum.get('visitorRegBadgeTemplateTypeId'),
        partnerBadgeTemplateTypeId: datum.get('partnerBadgeTemplateTypeId'),
        providerBadgeTemplateTypeId: datum.get('providerBadgeTemplateTypeId'),
        staffBadgeTemplateTypeId: datum.get('staffBadgeTemplateTypeId'),
        organizerBadgeTemplateTypeId: datum.get('organizerBadgeTemplateTypeId'),
    }
}

export const parseBadgeTemplateTypes: BadgeTemplateTypes = (datum, language) => {
    if(!datum) return
    return {
        id: datum.get('id'),
        description: withLanguage(language)(datum.get('description'))
    }
}