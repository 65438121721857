// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import {ListSubheader, TextField} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_EXHIBITION_QUICK_BADGE,
	ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE
} from "../../../../../routers/routes";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {useHistory} from "react-router-dom";
import Divider from "@mui/material/Divider";
import {NumericFormat} from "react-number-format";
import {Update} from "../../../../../hooks/exhibitions/useExhibitionAction";
import {useDebouncedCallback} from "../../../../../hooks/useDebouncedCallback";
import Typography from "@mui/material/Typography";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";

type Props = {};

const BadgeSettings = (props: Props) => {
	const {id, updateExhibition, exhibition} = props
	const {t} = useTranslation(EXHIBITION)
	const history = useHistory()
	const onChangeValidQrDays = (value) => {
		updateExhibition(id, value, Update.ValidQrDays)
	}
	const {
		value: validQrDays,
		debouncedOnChange: debouncedOnChangeValidQrDays
	} = useDebouncedCallback(onChangeValidQrDays, exhibition.validQrDays)
	return (
		<List
			subheader={<ListSubheader sx={{backgroundColor: '#F9FBF2'}}><Typography variant={'button'}
																					sx={{color: '#000000'}}>{normalizeGreek(t('Badge'))}</Typography></ListSubheader>}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE, {id}, {section: "design-and-print-settings"}))
				}}
			>
				<ListItemText primary={t("Customize, Visitor Categories And Special Rules")}/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_QUICK_BADGE, {id: id}))
				}}
			>
				<ListItemText primary={t("Quick Print Badge")}/>
			</ListItem>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t("Visitors QR Validity Period After Exhibition")}
					secondary={t('If set, visitors will be able to visit other exhibitions, by using their QR code of this exhibition. QR code will be valid for upcoming exhibitions within the days limit you set after this exhibition.')}
				/>
				<NumericFormat
					defaultValue={validQrDays}
					onChange={debouncedOnChangeValidQrDays}
					variant={'outlined'}
					inputProps={{maxLength: 3}}
					required
					thousandSeparator={''}
					decimalScale={0}
					allowNegative={false}
					customInput={TextField}
					size={'small'}
					allowLeadingZeros={true}
				/>
			</ListItem>
		</List>
	);
};

export default BadgeSettings