import type {MessageTemplate, MessageTemplateType, MessageTemplateTypeVariable} from "./types";

export const parseMessageTemplate: MessageTemplate = (datum) => {
    if(!datum) return;
    return {
        id: datum.get('id'),
        typeId: datum.get('typeId'),
        exhibitionId: datum.get('exhibitionId'),
        title: datum.get('title')?.toJS(),
        body: datum.get('body')?.toJS(),
        isActive: datum.get('isActive')
    }
}

export const parseMessageTemplateType: MessageTemplateType = (datum) => {
    if(!datum) return;
    return {
        id: datum.get('id'),
        description: datum.get('description'),
        isSMS: datum.get('isSMS')
    }
}

export const parseMessageTemplateTypeVariables: MessageTemplateTypeVariable = (datum) => {
    if(!datum) return;
    return {
        parentId: datum.get('parentId'),
        hasChildren: datum.get('hasChildren'),
        id: datum.get('id'),
        description: datum.get('description')
    }
}