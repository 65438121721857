import React from 'react'
import ReactDOM from 'react-dom/client'
import './locales/i18n'
import {Provider} from 'react-redux'
import store, {persistor} from './store/store'
import {PersistGate} from 'redux-persist/es/integration/react'
import App from './App'
import {LicenseInfo} from '@mui/x-license';
import {MUI_LICENSE_KEY} from "./helpers/constants";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    // </React.StrictMode>
)
