// @flow
import * as React from 'react';
import {useState} from 'react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import {FormControl, FormControlLabel} from "@mui/material";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {AuthenticatedLink} from "../../../components/general/authenticatedLink/authenticatedLink";
import {API} from "../../../helpers/constants";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import TextField from "@mui/material/TextField";
import {formatDate} from "../../../helpers/date";
import {muiDatePickersLocales} from "../../../helpers/muiGridLocales";
import useUserData from "../../../hooks/user/useUserData";

type Props = {};

export const ExportInWordPopOver = (props: Props) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {language, dateFormat} = useUserData()
    const {exportInWordAnchor, handleCloseExportInWord, exhibitionId} = props
    const [orderDataBy, setOrderDataBy] = useState('exhibitor')
    const [exportLanguage, setExportLanguage] = useState('el')
    const [value, setValue] = React.useState([null, null])
    const updateDates = (newV, index) => {
        const newValue = value.map((v, j) => j === index ? newV : v)
        setValue(newValue)
    }
    return (
        <React.Fragment>
            <Box onClick={(e) => e.stopPropagation()}>
                <Popover
                    disableRestoreFocus
                    onClose={handleCloseExportInWord}
                    open={Boolean(exportInWordAnchor)}
                    anchorEl={exportInWordAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Stack
                        direction={'column'}
                        minHeight={'240px'}
                        maxWidth={'360px'}
                        justifyContent={'space-around'}
                        p={2}
                        width={'100%'}
                    >
                        <Typography
                            variant="body"
                            color={'textSecondary'}
                        >
                            {t('Select export filters and press EXPORT.')}
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="export-word-buttons-group"
                                name="exportWord"
                                value={orderDataBy}
                                onChange={(evt) => setOrderDataBy(evt.target.value)}
                            >
                                <FormControlLabel value="exhibitor" control={<Radio/>} label={t("Exhibitor")}/>
                                <FormControlLabel value="country" control={<Radio/>} label={t("Country")}/>
                                <FormControlLabel value="kiosk" control={<Radio/>} label={t("Kiosk")}/>
                            </RadioGroup>
                        </FormControl>
                        <Typography
                            variant="body"
                            color={'textSecondary'}
                        >
                            {t('Select export language.')}
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="export-word-language"
                                name="exportWordLanguage"
                                value={exportLanguage}
                                onChange={(evt) => setExportLanguage(evt.target.value)}
                                sx={{flexDirection: 'row'}}
                            >
                                <FormControlLabel value="el" control={<Radio/>} label={t("Greek")}/>
                                <FormControlLabel value="en" control={<Radio/>} label={t("English")}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{mb: 1}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}
                                                  localeText={muiDatePickersLocales(language)}>
                                <DatePicker
                                    label={t('From Issue Date')}
                                    value={value[0]}
                                    format={dateFormat}
                                    onChange={(newValue) => {
                                        updateDates(newValue, 0);
                                    }}
                                    renderInput={(params) => <TextField size={"small"} {...params} />}
                                />
                                <Box sx={{my: 1}}/>
                                <DatePicker
                                    size={"small"}
                                    format={dateFormat}
                                    label={t('To Issue Date')}
                                    value={value[1]}
                                    onChange={(newValue) => {
                                        updateDates(newValue, 1);
                                    }}
                                    renderInput={(params) => <TextField size={"small"} {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <AuthenticatedLink
                            filename={'export'}
                            url={`${API}/en/exhibition/exportcatalogword/${exhibitionId}?order=${orderDataBy}&lang=${exportLanguage}${value[0] ? '&FromPublishedAt=' + formatDate(value[0], formatDate.formatTypes.DATE_ONLY_FILTER) : ''}${value[1] ? '&ToPublishedAt=' + formatDate(value[1], formatDate.formatTypes.DATE_ONLY_FILTER) : ''}`}
                        >
                            <Button
                                variant={"outlined"}
                                size={"small"}
                            >
                                {normalizeGreek(t('Export'))}
                            </Button>
                        </AuthenticatedLink>
                    </Stack>
                </Popover>
            </Box>
        </React.Fragment>
    );
};