import {actionCreator} from "../../helpers/actionCreator";
import * as ActionTypes from "./types";

export const checkAPIConnectivity = actionCreator(ActionTypes.CHECK_API_CONNECTIVITY)
export const checkAPIConnectivitySucceeded = actionCreator(ActionTypes.CHECK_API_CONNECTIVITY_SUCCEEDED)
export const checkAPIConnectivityFailed = actionCreator(ActionTypes.CHECK_API_CONNECTIVITY_FAILED)

export const userLogin = actionCreator(ActionTypes.USER_LOGIN)
export const userLoginSucceeded = actionCreator(ActionTypes.USER_LOGIN_SUCCEEDED)
export const userLoginFailed = actionCreator(ActionTypes.USER_LOGIN_FAILED)

export const userLogout = actionCreator(ActionTypes.USER_LOGOUT)
export const userLogoutSucceeded = actionCreator(ActionTypes.USER_LOGOUT_SUCCEEDED)
export const userLogoutFailed = actionCreator(ActionTypes.USER_LOGOUT_FAILED)

export const setUserLanguage = actionCreator(ActionTypes.SET_USER_LANGUAGE)