//@flow
import Typography from "@mui/material/Typography";
import type { Exhibition } from "../../../../../../types/exhibition/types";
import { Update } from "../../../../../../hooks/exhibitions/useExhibitionAction";
import { useDebouncedCallback } from "../../../../../../hooks/useDebouncedCallback";
import ListItem from "@mui/material/ListItem";
import { TextField } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { EXHIBITION } from "../../../../../../locales/components/namespaces";

type Props = {
	id: string,
	exhibition: Exhibition,
	updateExhibition: Function
}

const PreregistrationGTAG = (props: Props) => {
	const { t } = useTranslation(EXHIBITION)
	const { id, exhibition, updateExhibition } = props
	const onChange = (value) => {
		updateExhibition(id, value, Update.GoogleTagCode)
	}
	const { value, debouncedOnChange } = useDebouncedCallback(onChange, exhibition.googleTagCode)
	return (
		<>
			<ListItem disabled={!exhibition.preregistrationEnabled}>
				<ListItemText
					primary={t("Google Tag Code")}
					secondary={t("To enable gtag script for preregistration insert a valid gtag code here. If empty, gtag script will not execute.")}
				/>
				
				<TextField
					disabled={!exhibition.preregistrationEnabled}
					defaultValue={value}
					onChange={debouncedOnChange}
					size={"small"}
					fullWidth
					sx={{ minWidth: '200px' }}
					inputProps={{ inputMode: 'text', maxLength: 100 }}
					placeholder={'X-XXXXXXXXXX'}
				/>
			
			</ListItem>
			<ListItem>
				<Typography
					variant={'body2'}
				>
					<strong>{t('Valid Script Example for GOOGLE TAG')}</strong>
					<br/>
					<code>
						{`
							<script async src="https://www.googletagmanager.com/gtag/js?id=X-XXXXXXXXXX"></script>
							<script>  window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'X-XXXXXXXXXX'); </script>
						`}
					</code>
					<br/>
					<a href={'https://developers.google.com/tag-platform/gtagjs'} target={'_blank'} rel={'noreferrer'}>
						{t('Read More On Google Docs')}
					</a>
				</Typography>
			</ListItem>
		</>
	
	)
}

export default PreregistrationGTAG
