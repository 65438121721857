// @flow
import React from 'react';
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";

type Props = {};

const DisplayImagePopOver = ({displayImage, handleDisplayImage}: Props) => {
	
	const handlePopoverClose = () => {
		handleDisplayImage({anchorEl: null, open: false, url: ''})
	}
	return (
		<React.Fragment>
			<Box onClick={(e) => e.stopPropagation()}>
				<Popover
					disableRestoreFocus
					onClose={handlePopoverClose}
					open={displayImage.open}
					anchorEl={displayImage.anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Box sx={{width: '100%', padding: '20px'}}>
						{displayImage.url && (
							<img
								src={displayImage.url}
								alt="logo"
								style={{maxHeight: '400px'}}
							/>
						)}
					</Box>
				</Popover>
			</Box>
		</React.Fragment>
	);
};

export default DisplayImagePopOver