// @flow 
import React from 'react';
import useVisitorForm from "../../../hooks/visitorForm/useVisitorForm";
import Box from "@mui/material/Box";
import {FormikProvider} from "formik";
import FormToolbar from "./formToolbar/formToolbar";
import Fields from "./fields/fields";

type Props = {};

export const VisitorFormContext = React.createContext({
    categories: []
})

const Form = ({
                  id,
                  exhibition,
                  countries,
                  interests,
                  categories,
                  phoneCodes,
                  defaultCountry,
                  defaultPhoneCode,
                  preregistrationFormValues
              }: Props) => {
    const {
        companyNameFieldRef,
        nameFieldRef,
        surnameFieldRef,
        vatFieldRef,
        emailFieldRef,
        mobilePhoneFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        instagramFieldRef,
        facebookFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef,
        otherInterestsFieldRef,
        formik,
        fields,
        fieldValues,
        submitForm,
        onPhoneCodeChange
    } = useVisitorForm({
        id,
        exhibition,
        countries,
        interests,
        categories,
        phoneCodes,
        defaultCountry,
        defaultPhoneCode,
        preregistrationFormValues
    })

    return (
        <Box sx={{width: '100%'}}>
            <VisitorFormContext.Provider
                value={{
                    id: id,
                    exhibition: exhibition,
                    interests: interests,
                    categories: categories,
                    countries: countries,
                    phoneCodes: phoneCodes,
                    companyNameFieldRef: companyNameFieldRef,
                    nameFieldRef: nameFieldRef,
                    surnameFieldRef: surnameFieldRef,
                    vatFieldRef: vatFieldRef,
                    emailFieldRef: emailFieldRef,
                    mobilePhoneFieldRef: mobilePhoneFieldRef,
                    phoneFieldRef: phoneFieldRef,
                    companyAddressFieldRef: companyAddressFieldRef,
                    cityFieldRef: cityFieldRef,
                    companyWebsiteFieldRef: companyWebsiteFieldRef,
                    instagramFieldRef: instagramFieldRef,
                    facebookFieldRef: facebookFieldRef,
                    positionFieldRef: positionFieldRef,
                    personNumberFieldRef: personNumberFieldRef,
                    postalCodeFieldRef: postalCodeFieldRef,
                    otherInterestsFieldRef: otherInterestsFieldRef,
                    defaultCountry: defaultCountry,
                    defaultPhoneCode: defaultPhoneCode,
                    onPhoneCodeChange: onPhoneCodeChange
                }}
            >
                <FormikProvider value={formik}>
                    <Box
                        id={"preregistration"}
                        component="form"
                        autoComplete={'off'}
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <Fields
                            formik={formik}
                            fields={fields}
                            values={fieldValues}
                            handleBlur={formik.handleBlur}
                            handleChange={formik.handleChange}
                            touched={formik.touched}
                            errors={formik.errors}
                        />
                    </Box>
                    <FormToolbar
                        formik={formik}
                        submitForm={submitForm}
                    />
                </FormikProvider>
            </VisitorFormContext.Provider>
        </Box>
    );
};

export default Form