// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../../locales/components/namespaces";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FormControl} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {muiDatePickersLocales} from "../../../../../../../helpers/muiGridLocales";
import TextField from "@mui/material/TextField";
import {AuthenticatedLink} from "../../../../../../../components/general/authenticatedLink/authenticatedLink";
import {API} from "../../../../../../../helpers/constants";
import {formatDate} from "../../../../../../../helpers/date";
import Button from "@mui/material/Button";
import useUserData from "../../../../../../../hooks/user/useUserData";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {normalizeGreek} from "../../../../../../../helpers/normalizeGreek";
import {FormikProvider, useFormik} from "formik";
import SelectMultipleCountries from "../../../../../../../components/form/selectCountries/selectMultipleCountries";
import AutocompleteMultiple from "../../../../../../../components/form/autocompleteMultiple/autocompleteMultiple";

type Props = {};

const ExportPreregistationReportPopOver = (props: Props) => {
	const {exhibitionId, exportPreregInExcelAnchor, handleCloseExportPreregInExcel, countries, categories, interests} = props
	const {t} = useTranslation(EXHIBITION)
	const {language, dateFormat} = useUserData()
	
	const formik = useFormik({
		initialValues: {
			dates: [null, null],
			countries: [],
			interests: [],
			categories: [],
		},
		onSubmit: () => {},
	})
	
	const updateDates = (newV, index) => {
		const newValue = formik.values.dates.map((v, j) => j === index ? newV : v)
		formik.setFieldValue('dates', newValue)
	}
	
	const url = useMemo(() => {
		const dateFrom = formik.values.dates[0] ? 'fromDt=' + formatDate(formik.values.dates[0], formatDate.formatTypes.DATE_ONLY_FILTER) : ''
		const dateTo = formik.values.dates[1] ? '&toDt=' + formatDate(formik.values.dates[1], formatDate.formatTypes.DATE_ONLY_FILTER) : ''
		const countryIds = formik.values.countries.length > 0 ? '&CountryIds=' + formik.values.countries.map(i => i.id).toString() : ''
		const characterizationIds = formik.values.categories.length > 0 ? '&CharacterizationIds=' + formik.values.categories.map(i => i.id).toString() : ''
		const interestIds = formik.values.interests.length > 0 ? '&InterestIds=' + formik.values.interests.map(i => i.id).toString() : ''
		return `${API}/en/exhibition/preregistrations/export/${exhibitionId}?${dateFrom}${dateTo}${countryIds}${characterizationIds}${interestIds}`
	}, [exhibitionId, formik.values.categories, formik.values.countries, formik.values.dates, formik.values.interests])

	return (
		<React.Fragment>
			<Box onClick={(e) => e.stopPropagation()}>
				<Popover
					disableRestoreFocus
					onClose={() => {
						handleCloseExportPreregInExcel()
						formik.resetForm()
					}}
					open={Boolean(exportPreregInExcelAnchor)}
					anchorEl={exportPreregInExcelAnchor}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<FormikProvider value={formik}>
						<Stack
							direction={'column'}
							minHeight={'240px'}
							maxWidth={'360px'}
							justifyContent={'space-around'}
							p={5}
							width={'100%'}
						>
							<Typography
								variant="body"
								mb={1}
								color={'textSecondary'}
							>
								{t('Select export filters and press EXPORT.')}
							</Typography>
							<FormControl sx={{mb: 1}}>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									localeText={muiDatePickersLocales(language)}
								>
									<DatePicker
										format={dateFormat}
										label={t('Prereg. Date From')}
										value={formik.values.dates[0]}
										onChange={(newValue) => {
											updateDates(newValue, 0);
										}}
										renderInput={(params) => <TextField size={"small"} {...params} />}
									/>
									<Box sx={{my: 0.9}}/>
									<DatePicker
										format={dateFormat}
										size={"small"}
										label={t('Prereg. Date To')}
										value={formik.values.dates[1]}
										onChange={(newValue) => {
											updateDates(newValue, 1);
										}}
										renderInput={(params) => <TextField size={"small"} {...params} />}
									/>
								</LocalizationProvider>
							</FormControl>
							<FormControl
								sx={{mb: 1}}
								variant="standard"
								fullWidth
							>
								<SelectMultipleCountries
									label={t('Countries')}
									onChange={(event, newValue) => {
										formik.setFieldValue('countries', newValue)
									}}
									value={formik.values.countries}
									onBlur={formik.handleBlur}
									countries={countries}
									name={`countries`}
								/>
							</FormControl>
							<FormControl
								sx={{mb: 1}}
								variant="standard"
								fullWidth
							>
								<AutocompleteMultiple
									label={t('Characterization')}
									onChange={(event, newValue) => {
										formik.setFieldValue('categories', newValue)
									}}
									value={formik.values.categories}
									onBlur={formik.handleBlur}
									data={categories}
									name={`categories`}
								/>
							</FormControl>
							<FormControl
								variant="standard"
								fullWidth
								sx={{mb: 1}}
							>
								<AutocompleteMultiple
									label={t('Profession Domains')}
									onChange={(event, newValue) => {
										formik.setFieldValue('interests', newValue)
									}}
									value={formik.values.interests}
									onBlur={formik.handleBlur}
									data={interests}
									name={`interests`}
								/>
							</FormControl>
							<Box mt={3} width={'100%'}>
								<AuthenticatedLink
									filename={'export'}
									url={url}
								>
									<Button
										sx={{width: '100%'}}
										variant={"outlined"}
										size={"small"}
									>
										<FileDownloadIcon/>
										{normalizeGreek(t('Export'))}
									</Button>
								</AuthenticatedLink>
							</Box>
						</Stack>
					</FormikProvider>
				</Popover>
			</Box>
		</React.Fragment>
	);
};

export default ExportPreregistationReportPopOver