import {useEffect} from "react";
import {useRulesAction} from "./useRulesAction";
import {useRulesData} from "./useRulesData";

export const useRules = (id) => {
    const {getRules} = useRulesAction({exhibitionId: id})
    const {loading, rules, updateRuleGridSource} = useRulesData()
    useEffect(() => {
        getRules(id)
    }, [getRules, id, updateRuleGridSource])

    return {
        rules,
        loading
    }
}