// @flow
import React, {useState} from 'react'
import {ChevronRight} from '@mui/icons-material'
import {CheckboxState} from '../../helpers/constants'
import CheckBox from '../checkBox/checkBox'

const CheckBoxListRow = ({ item, itemState, getChildNodes, onClick, indentLevel }) => {
    const [isCollapsed, setIsCollapsed] = useState(true)

    return (
        <li className="tree-list-item">
            {item.hasChildren && <ChevronRight
                className={`tree-chevron ${!isCollapsed && 'tree-chevron_rotated'}`}
                onClick={() => setIsCollapsed(!isCollapsed)}
            />}
            <CheckBox
                onClick={() => onClick(item.id, item)}
                ischecked={itemState === CheckboxState.CHECKED}
                isindeterminate={itemState === CheckboxState.INDETERMINATE}
            />
            <span>{item.description}</span>
            {item.hasChildren && (
                <ul
                    className={`tree-list tree-children ${!isCollapsed && 'tree-children_collapsed'}`}
                    style={{ paddingLeft: indentLevel * 10 }}
                >
                    {getChildNodes(item.id)}
                </ul>
            )}
        </li>
    )
}

export default CheckBoxListRow