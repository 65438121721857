//@flow
import React, {useContext, useEffect} from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import BadgeForm from "../../../../components/app/badgeForm/badgeForm";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import {Tab, Tabs} from "@mui/material";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import {TabPanel} from "../../../../components/general/tabPanel/tabPanel";
import {TitleContext} from "../../../main/content";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import Categories from "./categories/categories";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {
    ROUTE_PAGE_EXHIBITION,
    ROUTE_PAGE_EXHIBITION_SETTINGS,
    ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE,
    ROUTE_PAGE_HOME
} from "../../../../routers/routes";
import useBadgeAction from "../../../../hooks/badges/useBadgeAction";
import useBadgeData from "../../../../hooks/badges/useBadgeData";
import Rules from "./rules/rules";
import useQueryParams from "../../../../hooks/useQueryParams";
import {Link} from "react-router-dom";

const BadgeCustomize = () => {
    const {t} = useTranslation(EXHIBITION)
    const {exhibition, loading, id} = useExhibition()
    const {setToolbar} = useContext(TitleContext)
    const queryParams = useQueryParams()
    const section = queryParams.get('section') ?? ""
    const {getBadgeTemplateTypes} = useBadgeAction()
    const {badgeTemplateTypes} = useBadgeData()
    useEffect(() => {
        getBadgeTemplateTypes()
    }, [getBadgeTemplateTypes])
    useEffect(() => {
        setToolbar(
            <React.Fragment>
                <Tabs
                    variant="scrollable"
                    value={section}
                    indicatorColor="primary"
                    textColor="inherit"
                >
                    <Tab
                        component={Link}
                        value={"design-and-print-settings"}
                        to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE, {id}, {section: "design-and-print-settings"})}
                        label={normalizeGreek(t("Design-and-print-settings"))}
                    />
                    <Tab
                        component={Link}
                        value={"visitor-categories"}
                        to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE, {id}, {section: "visitor-categories"})}
                        label={normalizeGreek(t("Visitor-categories"))}
                    />
                    <Tab
                        component={Link}
                        value={"special-badge-rules"}
                        to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE, {id}, {section: "special-badge-rules"})}
                        label={normalizeGreek(t("Special-badge-rules"))}
                    />
                </Tabs>
            </React.Fragment>
        )
        return () => {
            setToolbar(null)
        }
    }, [id, section, setToolbar, t])
    return (
        <div>
            {exhibition ? (
                <React.Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                            {t('Home')}
                        </Breadcrumb.Link>
                        <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
                            {exhibition.title}
                        </Breadcrumb.Link>
                        <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
                            {t('Settings')}
                        </Breadcrumb.Link>
                        {section && section !== "" && (
                            <Breadcrumb.Item>
                                {t(section.charAt(0).toUpperCase() + section.slice(1))}
                            </Breadcrumb.Item>
                        )}
                    </Breadcrumb>
                    <TabPanel isVisible={section === "design-and-print-settings"}>
                        <BadgeForm
                            exhibitionId={id}
                            badgeTemplateTypes={badgeTemplateTypes}
                        />
                    </TabPanel>
                    <TabPanel isVisible={section === "visitor-categories"}>
                        <Categories
                            exhibitionId={id}
                        />
                    </TabPanel>
                    <TabPanel isVisible={section === "special-badge-rules"}>
                        <Rules
                            exhibitionId={id}
                        />
                    </TabPanel>
                </React.Fragment>
            ) : (
                <ContentLoader
                    loading={loading}
                    notFoundWhenNotLoading
                />
            )}
        </div>
    )
}

export default BadgeCustomize;
