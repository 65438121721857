//@flow
import React from 'react';
import {Chip} from "@mui/material";
import {eventStatus} from "../../../helpers/date";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";

type Props = {
    dateFrom: Date,
    dateTo: Date,
}

const ExhibitionStatus = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {dateFrom, dateTo, ...other} = props
    const status = eventStatus(dateFrom, dateTo)
    return (
        <Chip
            {...other}
            variant="filled"
            color={status === eventStatus.status.EXPIRED ?
                "info" : status === eventStatus.status.UPCOMING ?
                    "warning" : "success"}
            size="small"
            label={status === eventStatus.status.EXPIRED ?
                t("status_1") : status === eventStatus.status.UPCOMING ?
                    t("status_2") : t("status_3")}
        />
    )
}

export default ExhibitionStatus;