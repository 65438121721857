//@flow
import React, {Fragment} from 'react';
import {ListItem, ListItemText, Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";

const RowOptions = ({formik, index, rowField}) => {
	const {t} = useTranslation(EXHIBITION)
	const onClick = e => {
		// e.preventDefault()
		// e.stopPropagation()
	}
	const field = formik.values.fields.find(f => f.id === rowField.id)
	return (
		<Fragment>
			<ListItem onClick={onClick}>
				<ListItemText primary={t("Required")}/>
				<Switch
					edge="end"
					name={`fields[${index}].required`}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					checked={formik.values.fields[index].required}
				/>
			</ListItem>
			<ListItem onClick={onClick}>
				<ListItemText primary={t("Enabled")}/>
				<Switch
					edge="end"
					name={`fields[${index}].enabled`}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					checked={formik.values.fields[index].enabled}
				/>
			</ListItem>
			{field.type !== "surname" && (
				<ListItem onClick={onClick}>
					<ListItemText
						primary={t("Visible on registration form")}
					/>
					<Switch
						edge="end"
						name={`fields[${index}].visibleOnRegistrationForm`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						checked={formik.values.fields[index].visibleOnRegistrationForm}
					/>
				</ListItem>
			)}
			<ListItem onClick={onClick}>
				<ListItemText
					primary={t("Visible on preregistration form")}
				/>
				<Switch
					edge="end"
					name={`fields[${index}].visibleOnPreRegistrationForm`}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					checked={formik.values.fields[index].visibleOnPreRegistrationForm}
				/>
			</ListItem>
			{field.type !== "surname" && (
				<ListItem onClick={onClick}>
					<ListItemText
						primary={t("Visible on confirmation display")}
						secondary={t("Disable this option if you want to hide this field from visitor display after a badge is scanned.")}
					/>
					<Switch
						edge="end"
						name={`fields[${index}].visibleOnConfirmationDisplay`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						checked={formik.values.fields[index].visibleOnConfirmationDisplay}
					/>
				</ListItem>
			)}
			<ListItem onClick={onClick}>
				<ListItemText
					primary={t("Visible on Company Group")}
					secondary={t("Enable this option to place field in Company Group Of Preregistration Form. If disabled field will be placed in Personal Information Group.")}
				/>
				<Switch
					edge="end"
					name={`fields[${index}].groupOfCompany`}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					checked={formik.values.fields[index].groupOfCompany}
				/>
			</ListItem>
			{field.type === "interests" && (
				<Fragment>
					<ListItem onClick={onClick}>
						<ListItemText
							primary={t("Allow Multiple Selection")}
						/>
						<Switch
							edge="end"
							name={`fields[${index}].multiple`}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.fields[index].multiple}
						/>
					</ListItem>
					<ListItem onClick={onClick}>
						<ListItemText
							primary={t("Allow Adding New")}
						/>
						<Switch
							edge="end"
							name={`fields[${index}].addNew`}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.fields[index].addNew}
						/>
					</ListItem>
				</Fragment>
			)}
			{field.type === "email" && (
				<ListItem onClick={onClick}>
					<ListItemText
						primary={t("Display Confirm Email Field In Preregistration")}
						secondary={t("Enable this option to display a second email field for email confirmation check.")}
					/>
					<Switch
						edge="end"
						name={`fields[${index}].confirmEmailInPreRegistration`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						checked={formik.values.fields[index].confirmEmailInPreRegistration}
					/>
				</ListItem>
			)}
			{field.type === "vat" && (
				<>
					<ListItem onClick={onClick}>
						<ListItemText
							primary={t("Activate Taxis Search on Preregistration")}
							secondary={t("Enable this option to allow searching Tax Registration Number in TAXIS API on preregistration form.")}
						/>
						<Switch
							edge="end"
							name={`fields[${index}].enableTaxisSearchPreregistration`}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.fields[index].enableTaxisSearchPreregistration}
						/>
					</ListItem>
					<ListItem onClick={onClick}>
						<ListItemText
							primary={t("Activate Taxis Search on Registration")}
							secondary={t("Enable this option to allow searching Tax Registration Number in TAXIS API on registration form.")}
						/>
						<Switch
							edge="end"
							name={`fields[${index}].enableTaxisSearchRegistration`}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.fields[index].enableTaxisSearchRegistration}
						/>
					</ListItem>
				</>
			)}
		</Fragment>
	)
}

export default RowOptions;
