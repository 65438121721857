//@flow
import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type Props = {
    title?: string,
    message?: string,
    cta?: any,
}

const NoContentSection = (props: Props) => {
    const {title, message, cta} = props
    return (
        <Box sx={{p: title ? 8 : 2}}>
            <Stack
                direction={"column"}
                spacing={1}
                sx={{textAlign: "center", height: '100%'}}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Typography variant={"h5"} component={"div"}>
                    {title}
                </Typography>
                <Typography variant={"body1"} component={"div"}>
                    {message}
                    {cta}
                </Typography>
            </Stack>
        </Box>
    )
}

export default NoContentSection;
