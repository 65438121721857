// @flow
import * as React from 'react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Field, FormikProvider, useFormik} from "formik";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import {PAGE_SETTINGS} from "../../../../../locales/pages/namespaces";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import * as yup from "yup";
import TextInput from "../../../../../components/form/textfield/textfield";
import {FormControl} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import {PartnerTypes} from "../../../../../helpers/constants";

type Props = {};

export const SendPopOver = (props: Props) => {
    const {sendPopOver, handleSendPopOver, selectionModel, loading, onSendBadge} = props
    const {t} = useTranslation([PAGE_SETTINGS])

    const onSubmit = (values) => {
        onSendBadge()
    }

    const validationSchema = yup.object({
        email: yup
            .string()
            .email(t('Enter a valid email')),
        mobilePhone: yup
            .string()
    })

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit
    })

    const handlePopoverClose = () => {
        handleSendPopOver({anchorEl: null, open: false});
        formik.resetForm()
    }

    const sendInfoText1 = props.type === PartnerTypes.Partner ? 'Send badges to selected partners by pressing SEND button, without filling in an email.' :
        props.type === PartnerTypes.Partner ? 'Send badges to selected service providers by pressing SEND button, without filling in an email.' : 
		 props.type === PartnerTypes.Partner ? 'Send badges to selected exhibition staff by pressing SEND button, without filling in an email.' : 'Send badges to selected organizer by pressing SEND button, without filling in an email.'

    return (
        <React.Fragment>
            <Box onClick={(e) => e.stopPropagation()}>
                <Popover
                    disableRestoreFocus
                    onClose={handlePopoverClose}
                    open={sendPopOver.open}
                    anchorEl={sendPopOver.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <FormikProvider value={formik}>
                        <Box component="form" onSubmit={formik.handleSubmit} sx={{width: '100%', minWidth: '300px'}}>
                            <Box sx={{width: '100%', padding: '20px'}}>
                                <Typography variant="body" color={'textSecondary'}>
                                    {t(sendInfoText1)}
                                    <br/>
                                    <br/>
                                    {t('To send all badges as a single file to a specific recipient, fill in an e-mail.')}
                                    <br/>
                                    {/*{t('** Feature Available From 21/02/2023 **')}*/}
                                </Typography>
                                <FormControl variant="standard" color={'error'} fullWidth sx={{pt: 1}}>
                                    <Field
                                        size={"small"}
                                        name={'email'}
                                        label={t("Email")}
                                        component={TextInput}   
                                        // disabled={true}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{
                                padding: '0 20px 20px',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between'
                            }}>
                                <LoadingButton
                                    disabled={!(selectionModel && selectionModel.length > 0)}
                                    loading={loading}
                                    loadingPosition="start"
                                    onClick={() => onSendBadge(formik.values)}
                                    startIcon={<SendIcon/>}
                                    variant={"contained"}
                                    size={"small"}
                                >
                                    {normalizeGreek(t('Send'))}
                                </LoadingButton>
                                <Button
                                    size={"small"}
                                    variant={"outlined"}
                                    startIcon={<CancelIcon/>}
                                    type={"button"}
                                    onClick={handlePopoverClose}
                                >
                                    {normalizeGreek(t('Cancel'))}
                                </Button>
                            </Box>
                        </Box>
                    </FormikProvider>
                </Popover>
            </Box>
        </React.Fragment>
    );
};