import dayjs from "dayjs";
import { defaultPreregistrationFields } from "../../components/app/customizePreregistration/defaultFields";
import { API, EXHIBITION_IMAGE_RELATIVE_PATH } from "../../helpers/constants";
import { eventStatus } from "../../helpers/date";
import { mapMultiple } from "../../helpers/map";
import { mapInterests } from "../interests/map";
import type {
	BusinessCategory,
	Exhibition,
	ExhibitionsGroupedHeaders,
	ExhibitionsGroupedHeadersUnmapped,
	Exhibitor,
	FAQ,
	Partner,
	UnmappedBusinessCategory,
	UnmappedExhibition,
	UnmappedExhibitor,
	UnmappedFAQ,
	UnmappedPartner,
	UnmappedVisitor,
	UnmappedVisitorReference,
	Visitor,
	VisitorReference
} from "./types";

export const mapExhibitionsGroupedHeaders: Array<ExhibitionsGroupedHeaders> = (datum: Array<ExhibitionsGroupedHeadersUnmapped>) => {
	if (!(datum && datum.length > 0)) return []
	return datum.map(d => ({
		DescriptionEN: d.DescriptionEN,
		description: {
			en: d.DescriptionEN,
			el: d.DescriptionEL
		}
	}))
}

export const mapBusinessCategories: Array<BusinessCategory> = (datum: Array<UnmappedBusinessCategory>) => {
	if (!(datum && datum.length > 0)) return []
	return datum.map(d => ({
		id: d.id,
		description: {
			en: d.DescriptionEN.trim(),
			el: d.DescriptionEL.trim()
		}
	}))
}


export const mapFAQ: FAQ = (datum: UnmappedFAQ) => {
	if (!datum) return
	return {
		id: Number(datum.id),
		parent: Number(datum.ParentId),
		title: {
			en: datum.TitleEN,
			el: datum.TitleEL,
		},
		content: {
			en: datum.ContentEN,
			el: datum.ContentEL,
		},
		weight: Number(datum.Ordr),
		isNew: datum.isNew === '1'
	}
}

export const mapVisitorReference: VisitorReference = (datum: UnmappedVisitorReference, index) => {
	if (!datum) return
	return {
		id: datum.ExhibitorId,
		exhibitor: {
			id: datum.ExhibitorId,
			name: datum.ExhibitorName,
		},
		invitationDate: new Date(datum.InvitationDt),
		statusId: Number(datum.StatusId)
	}
}

const mapCatalogAddress = (datum, index) => {
	return {
		category: datum[`CategAddr${index}`] ?? '',
		country: {
			en: datum[`addr${index}CountryId`] ?? '',
			el: datum[`addr${index}CountryId`] ?? '',
		},
		phoneCode: {
			en: datum[`addr${index}PhoneCodeCountryId`] ?? '',
			el: datum[`addr${index}PhoneCodeCountryId`] ?? '',
		},
		street: {
			en: datum[`addr${index}AddressEN`] ?? '',
			el: datum[`addr${index}AddressEL`] ?? '',
		},
		postalCode: {
			en: datum[`addr${index}ZipCodeEN`] ?? '',
			el: datum[`addr${index}ZipCodeEL`] ?? '',
		},
		city: {
			en: datum[`addr${index}CityEN`] ?? '',
			el: datum[`addr${index}CityEL`] ?? '',
		},
		phone: {
			en: datum[`addr${index}AddrPhoneEN`] ?? '',
			el: datum[`addr${index}AddrPhoneEL`] ?? '',
		},
	}
}

export const mapExhibitor: Exhibitor = (datum: UnmappedExhibitor) => {
	if (!datum) return
	return {
		code: datum.ErpCode,
		id: datum.id,
		businessCategories: mapBusinessCategories(datum.businessCategories),
		interests: mapInterests(datum.interests),
		address1: mapCatalogAddress(datum, 1),
		address2: mapCatalogAddress(datum, 2),
		address1CountryId: datum.addr1CountryId,
		businessPhoneNumber: datum.ExhibitorBusinessPhone,
		homePhoneNumber: datum.ExhibitorMobilePhone,
		phoneNumber: datum.ExhibitorMobilePhone,
		email: datum.ExhibitorEmail,
		email2: datum.ExhibitorEmail2,
		emailCatalogue: datum.EmailCatalogue ?? '',
		name: datum.ExhibitorName,
		kiosk: datum.Kiosk,
		kioskSign: datum.KioskSign,
		room: datum.Room,
		visitors: mapMultiple(datum.visitors, mapVisitor),
		partners: mapMultiple(datum.partners, mapPartner),
		catalog: {
			brand: {
				en: datum.CatalogBrandEN ?? '',
				el: datum.CatalogBrandEL ?? ''
			},
			profile: {
				en: datum.CatalogProfileEN ?? '',
				el: datum.CatalogProfileEL ?? ''
			},
		},
		website: datum.WebSite ?? '',
		social: {
			fb: datum.Facebook ?? '',
			instagram: datum.Instagram ?? '',
			linkedIn: datum.LinkedIn ?? ''
		},
		catalogueProfileLength: datum.CatalogueProfileLength ? Number(datum.CatalogueProfileLength) : '',
		username: datum.userName,
		lastLogin: datum.lastLogin ? new Date(datum.lastLogin) : '',
		confirmedCatalogue: datum.ConfirmedCatalogue === '1',
		brands: datum.brands && datum.brands.length > 0 ? datum.brands.map(brand => brand.Description) : [],
		editMode: false,
		isKioskSignReadOnly: datum.isKioskSignReadOnly === '1',
		invitesToVisitorsCounter: datum.VisitorCounter ? Number(datum.VisitorCounter) : 0
	}
}

export const mapVisitor: Visitor = (datum: UnmappedVisitor) => {
	if (!datum) return
	return {
		InvitationStatus: datum.InvitationStatus === '1',
		Badge: datum.Badge,
		Characterization: {
			id: datum.CharacterizationId,
			description: {
				en: datum.CharacterizationEN,
				el: datum.CharacterizationEL,
			}
		},
		City: datum.address && datum.address.City ? datum.address.City : '',
		CompanyAddress: datum.address && datum.address.Address ? datum.address.Address : '',
		CompanyFirm: datum.CompanyFirm,
		CompanyName: datum.CompanyName,
		ContactId: datum.ContactId,
		CountryId: datum.CountryId,
		ExhibitionId: datum.ExhibitionId,
		InvitedBy: {
			id: datum.ExhibitorId,
			name: datum.ExhibitorName,
			totalInvitations: Number(datum.ExhibitorCount),
		},
		InvitationDt: datum.InvitationDt,
		PreregistrationDt: datum.PreregistrationDt,
		LastModifiedAt: datum.LastModifiedAt,
		PersonCount: datum.PersonCount,
		PostalCode: datum.address && datum.address.ZipCode ? datum.address.ZipCode : '',
		Status: datum.Status,
		StatusId: Number(datum.StatusId),
		VatNumber: datum.VatNumber,
		VisitorBusinessPhone: datum.VisitorBusinessPhone,
		VisitorEmail: datum.VisitorEmail,
		VisitorMobilePhone: datum.VisitorMobilePhone,
		VisitorName: datum.VisitorName,
		Website: datum.Website,
		Instagram: datum.Instagram,
		Facebook: datum.Facebook,
		businessCategories: mapBusinessCategories(datum.businessCategories),
		existsInExhibition: datum.existsInExhibition,
		hasRevisited: datum.hasRevisited,
		id: datum.id,
		interests: mapInterests(datum.interests),
		isApproveRegistration: datum.isApproveRegistration,
		isIndividual: datum.isIndividual,
		organizerApprovalRejectReason: datum.organizerApprovalRejectReason,
		FromRegistration: datum.FromRegistration === '1',
		FromPreregistration: datum.FromPreregistration === '1',
	}
}

export const mapPartner: Partner = (datum: UnmappedPartner) => {
	if (!datum) return
	return {
		id: datum.id,
		// id: datum.eMail,
		name: datum.PartnerName,
		nameEn: datum.PartnerNameEN,
		email: datum.eMail,
		badgeCode: datum.Badge,
		type: datum.Type,
		companyName: datum.Company
	}
}

export const mapTranslations = (datum) => {
	if (!datum) return
	const translations = {}
	for (let [key, value] of Object.entries(datum)) {
		translations[key] = {
			value: {
				en: value?.Value?.EN,
				el: value?.Value?.EL,
			},
			description: {
				en: value?.Description?.EN,
				el: value?.Description?.EL,
			}
		}
	}
	return translations
}

export const mapExhibition: Exhibition = (datum: UnmappedExhibition) => {
	if (!datum) return
	const from = dayjs(datum.StartDt)
	const to = dayjs(datum.EndDt)
	const lastModifiedAt = dayjs(datum.LastModifiedAt)
	const preregistrationFrom = datum.StartDtPreregister ? dayjs(datum.StartDtPreregister) : null
	const preregistrationTo = datum.EndDtPreregister ? dayjs(datum.EndDtPreregister) : null
	return {
		id: datum.id,
		erpId: datum.ErpId,
		erpCode: datum.ErpCode,
		dateFrom: from,
		dateTo: to,
		daysForCatalogSubmission: Number(datum.DaysForCatalogSubmission),
		typeId: datum.TypeId,
		categoryId: datum.CategId,
		familyId: datum.FamilyId,
		numberOfPartnersPerExhibitor: Number(datum.PartnerNum),
		preregistrationEnabled: datum.isPreregistration === "1",
		preregistrationFormFields: datum.PreRegForm ? JSON.parse(datum.PreRegForm) : defaultPreregistrationFields,
		lastModifiedBy: datum.LastModifiedBy,
		lastModifiedAt: lastModifiedAt,
		isRequireRegistration: datum.isRequireRegistration === '1',
		type: datum.Type,
		category: datum.Category ? datum.Category.split(",") : [],
		family: datum.Family,
		image: {
			el: datum.Logo ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.Logo : null,
			en: datum.LogoEN ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.LogoEN : null,
		},
		title: {
			en: datum.DescriptionEN ?? '',
			el: datum.DescriptionEL ?? '',
		},
		description: {
			en: datum.SummaryEN ?? '',
			el: datum.SummaryEL ?? '',
		},
		welcomeMessage: datum.WelcomeText ?? {
			en: datum.WelcomeTextEN ?? '',
			el: datum.WelcomeTextEL ?? '',
		},
		preregistrationFrom: preregistrationFrom,
		preregistrationTo: preregistrationTo,
		preregistrationNeedsApproval: datum.isApproveRegistration === "1",
		catalogLocked: datum.isProfileLocked === "1",
		catalogEnableKioskSign: datum.CatalogEnableKioskSign === "1",
		catalogueLanguage: Number(datum.CatalogueLanguage),
		displayExhibitorKitAndAddsToExhibitor: datum.ExhibitorKitAndAds === "1",
		displayVisitorsToExhibitor: datum.ExhibitorVisitors === "1",
		displayPartnersToExhibitor: datum.ExhibitorPartners === "1",
		displayServiceProvidersToExhibitor: datum.ExhibitorServiceProviders === '1',
		displayStaffToExhibitor: datum.ExhibitorStaff === '1',
		displayOrganizersToExhibitor: datum.ExhibitorOrganizers === '1',
		hasVisitors: datum.hasVisitors === '1',
		hasPartners: datum.hasPartners === '1',
		hasServiceProviders: datum.hasServiceProviders === '1',
		hasStaff: datum.hasStaff === '1',
		hasOrganizers: datum.hasOrganizers === '1',
		exhibitorInvitesVisitorsWithBarcode: datum.ExhibitorVisitorBarcode === "1",
		coorganizationId: datum.CoorganizationId,
		coorganizationDescription: {
			en: datum.CoorganizationEN ?? '',
			el: datum.CoorganizationEL ?? '',
		},
		visitorCategoryRequiredInImport: datum.VisitorCategoryRequired === "1",
		preregistrationRedirect: datum.PreregistrationRedirect,
		googleTagCode: datum.GoogleTagCode,
		preregistrationHasFieldGroups: datum.PreregistrationHasFieldGroups === "1",
		domainPortal: datum.DomainPortal,
		domainPreregistration: datum.DomainPreregistration,
		domainRegistration: datum.DomainRegistration,
		disableRegistrationMessages: datum.DisableRegistrationMessages === '1',
		preregistrationCountry: datum.PreregistrationCountry,
		preregistrationApprovePerCountry: Number(datum.PreregistrationApprove),
		catalogueProfileLength: Number(datum.CatalogueProfileLength),
		hasExhibitorBrands: datum.hasExhibitorBrands === '1',
		visitorBadgeTemplateTypeId: datum.VisitorBadgeTemplateTypeId,
		visitorRegBadgeTemplateTypeId: datum.VisitorRegBadgeTemplateTypeId,
		partnerBadgeTemplateTypeId: datum.PartnerBadgeTemplateTypeId,
		providerBadgeTemplateTypeId: datum.ProviderBadgeTemplateTypeId,
		staffBadgeTemplateTypeId: datum.StaffBadgeTemplateTypeId,
		organizerBadgeTemplateTypeId: datum.OrganizerBadgeTemplateTypeId,
		sendFromEmail: datum.SendFromEmail,
		sendFromName: datum.SendFromName,
		isKioskSignEditable: datum.isKioskSignEditable === '1',
		registrationStartDays: Number(datum.RegistrationStartDays),
		registrationEndDays: Number(datum.RegistrationEndDays),
		validQrDays: Number(datum.ValidQrDays),
		quickPrintFooterImage: {
			el: datum.CharacterizationImgEL ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.CharacterizationImgEL : null,
			en: datum.CharacterizationImgEN ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.CharacterizationImgEN : null,
		},
		daysForCatalogNotification: Number(datum.DaysForCatalogNotification),
		status: eventStatus(from, to),  // TODO::Check functionality,
		translations: mapTranslations(datum.translations),
		buttonColor: datum.ButtonColor ? datum.ButtonColor : '#AEBBFF'
	}
}

