// @flow
import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import {Switch} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";

type Props = {
	id: string,
	exhibition: Exhibition,
	updateExhibition: Function
};

const DisplayExhibitorKitAndAdsToExhibitor = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {id, exhibition, updateExhibition} = props
	
	const toggleDisplayExhibitorKitAndAddsToExhibitor = () => {
		updateExhibition(id, !exhibition.displayExhibitorKitAndAddsToExhibitor, Update.ExhibitorKitAndAds)
	}
	return (
		<ListItem>
			<ListItemText
				primary={t('Display Marketing Kit And Advertisement Logos Tab To Exhibitor')}
				secondary={t('If you disable this, then exhibitors will not be able to access Marketing Kit and advertisement logos from their profile.')}
			/>
			<Switch
				edge="end"
				onChange={toggleDisplayExhibitorKitAndAddsToExhibitor}
				checked={exhibition.displayExhibitorKitAndAddsToExhibitor}
			/>
		</ListItem>
	);
};

export default DisplayExhibitorKitAndAdsToExhibitor