// @flow
import React from 'react';
import {IconButton} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RowInputs from "./rowInputs";

type Props = {};

const Row = (props: Props) => {
    const {
        row,
        rows,
        onUpdateRow,
        handleRows,
        onEditRow,
        onSaveRow,
        onCancelEditRow,
        onDeleteRow,
        rowIndex
    } = props
    const isEditMode = rows[rowIndex].editMode

    return (
        <>
            <RowInputs
                rows={rows}
                row={row}
                rowIndex={rowIndex}
                handleRows={handleRows}
                isEditMode={isEditMode}
            />
            {isEditMode ? (
                <>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            if (/^\d+$/.test(row?.id)) {
                                onUpdateRow(rowIndex, row)
                            } else {
                                onSaveRow(rowIndex, row)
                            }
                        }}
                    >
                        <SaveIcon/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() => onCancelEditRow(rowIndex, row)}
                    >
                        <CancelIcon/>
                    </IconButton>
                </>
            ) : (
                <>
                    <IconButton
                        color="primary"
                        onClick={() => onEditRow(rowIndex, row)}
                    >
                        <EditIcon/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() => onDeleteRow(rowIndex, row)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </>
            )}
        </>
    )
};

export default Row