import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import List from '@mui/material/List';
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {ListSubheader, Paper} from "@mui/material";
import FileRow from "./fileRow";
import {EXHIBITION} from "../../../locales/components/namespaces";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import type {ExhibitionFile} from "../../../types/exhibitionFiles/types";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import {normalizeGreek} from "../../../helpers/normalizeGreek";

const Input = styled('input')({
    display: 'none',
})

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
    borderWidth: 2,
    borderColor: '#616161',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#616161',
    outline: 'none',
    transition: 'border .24s ease-in-out'
}

const focusedStyle = {
    borderColor: '#2196f3'
}

const acceptStyle = {
    borderColor: '#00e676'
}

const rejectStyle = {
    borderColor: '#ff1744'
}

type Props = {
    name?: string,
    files?: Array<ExhibitionFile>,
    allowExcelFilesOnly?: boolean,
    allowAliasEdit?: boolean,
    allowDisplayOrder?: boolean,
    showAsButton?: boolean,
    onFilesUpload: Function,
    onUpdateAlias?: Function,
    accept?: Array<Object>
}

/*
 * accept = An Object with the keys set to the MIME type and the values an Array of file extensions
 * Get mime types https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */

const FileUpload = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {
        showAsButton,
        name,
        files,
        multiple,
        allowDisplayOrder,
        allowAliasEdit,
        onFilesUpload,
        onRemove,
        onUpdateAlias,
        accept
    } = props
    const {
        isFocused,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: accept,
        multiple: multiple,
        onDrop: acceptedFiles => {
            onFilesUpload(acceptedFiles)
        }
    })
    const removeFile = (file, index) => () => {
        onRemove(file, index)
    }
    const updateAlias = file => (alias, fileDisplayOrder) => {
        onUpdateAlias(file, alias, fileDisplayOrder)
    }
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ])
    return (
        <section className="container" style={{width: "100%"}}>
            {showAsButton ? (
                <label htmlFor={name}>
                    <Input
                        {...getInputProps()}
                    />
                    <Button
                        startIcon={<UploadIcon/>}
                        variant="contained"
                        component="span"
                    >
                        {normalizeGreek(t('Upload File'))}
                    </Button>
                </label>
            ) : (
                <div {...getRootProps({style, className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <Typography variant={"subtitle1"} sx={{textAlign: "center"}} component={"div"}>
                        <p>
                            {t("Drag 'n' drop some files here, or click to select files")}
                        </p>
                        <UploadFileIcon sx={{fontSize: 28}}/>
                    </Typography>
                </div>
            )}
            {files && files.length > 0 && (
                <Paper variant={"outlined"} sx={{mt: 1}}>
                    <List
                        disablePadding
                        subheader={
                            <ListSubheader>
                                <strong>{t('Uploaded File(s)')}</strong>
                            </ListSubheader>
                        }
                        dense
                        sx={{width: "100%"}}
                    >
                        <Box sx={{py: .5, maxHeight: 400, overflow: "auto"}}>
                            {files.map((file, index) => (
                                <FileRow
                                    allowDisplayOrder={allowDisplayOrder}
                                    key={index}
                                    file={file}
                                    showAliasInput={allowAliasEdit}
                                    onRemoveFile={removeFile(file, index)}
                                    onUpdateAlias={updateAlias(file)}
                                />
                            ))}
                        </Box>
                    </List>
                </Paper>
            )}
        </section>
    )
}

export default FileUpload