//@flow
import React, {useContext} from 'react';
import {Chip} from "@mui/material";
import RowOptions from "./rowOptions";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {PreregistrationFormContext} from "./customizePreregistration";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import ItemContext from "../../general/dragDropTree/Sortly/itemContext";
import Box from "@mui/material/Box";
import {Delete} from "@mui/icons-material";

const Row = (props) => {
    const {data: field} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    const {formik} = useContext(PreregistrationFormContext)
    const isEnabled = field.enabled
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const {index} = useContext(ItemContext)

    const isLayoutElement = field.type === 'layout_element'
    const shouldNotDelete = formik.values.fields.find(f => f.parent === field.id)
    const removeRow = () => {
        formik.setFieldValue('fields', formik.values.fields.filter(f => f.id !== field.id))
    }
    return (
        <Box>
            {!isEnabled && (
                <Chip
                    variant={"outlined"}
                    color={"info"}
                    size={"small"}
                    label={normalizeGreek(t("Disabled"))}
                />
            )}
            {isLayoutElement
                && !shouldNotDelete && (
                    <IconButton
                        onClick={removeRow}
                        color={"error"}
                    >
                        <Delete/>
                    </IconButton>
                )}
            {!isLayoutElement && (
                <React.Fragment>
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: 400,
                            },
                        }}
                    >
                        <RowOptions
                            formik={formik}
                            index={index}
                            rowField={field}
                        />
                    </Menu>
                </React.Fragment>
            )}
        </Box>
    )
}

export default Row
