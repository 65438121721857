//@flow
import React, {useContext, useEffect, useState} from 'react';
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import {Tab, Tabs} from "@mui/material";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import {TabPanel} from "../../../../components/general/tabPanel/tabPanel";
import {TitleContext} from "../../../main/content";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import {useExhibitionBusinessCategories} from "../../../../hooks/exhibitions/useExhibitionBusinessCategories";
import BusinessCategoriesList from "../../../../components/app/businessCategoriesList/businessCategoriesList";
import Toolbar from "@mui/material/Toolbar";


const TabInterest = () => {
    const {businessCategories, loading} = useExhibitionBusinessCategories({language: 'any'})
    return (
        <React.Fragment>
            <Toolbar variant={"dense"}/>
            {businessCategories && businessCategories.length > 0 ? (
                <BusinessCategoriesList
                    items={businessCategories}
                    asTable
                />
            ) : (
                <ContentLoader
                    loading={loading}
                    notFoundWhenNotLoading
                />
            )}
        </React.Fragment>
    )
}
const BusinessCategories = () => {
    const {t} = useTranslation(EXHIBITION)
    const {setToolbar} = useContext(TitleContext)
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => setValue(newValue)
    useEffect(() => {
        setToolbar(
            <React.Fragment>
                <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                >
                    <Tab value={0} label={normalizeGreek(t("Business Categories"))}/>
                </Tabs>
            </React.Fragment>
        )
        return () => {
            setToolbar(null)
        }
    }, [setToolbar, t, value])
    return (
        <React.Fragment>
            <TabPanel isVisible={value === 0}>
                <TabInterest/>
            </TabPanel>
            <TabPanel isVisible={value === 1}>

            </TabPanel>
        </React.Fragment>
    )
}

export default BusinessCategories;