import {actionCreator} from '../helpers/actionCreator'
import * as ActionTypes from './types'

export * from './faqs/actions'
export * from './currentUser/actions'
export * from './exhibitionFiles/actions'
export * from './badge/actions'

export const getExhibitions = actionCreator(ActionTypes.GET_EXHIBITIONS)
export const getExhibitionsSucceeded = actionCreator(ActionTypes.GET_EXHIBITIONS_SUCCEEDED)
export const getExhibitionsFailed = actionCreator(ActionTypes.GET_EXHIBITIONS_FAILED)

export const getExhibitionsList = actionCreator(ActionTypes.GET_EXHIBITIONS_LIST)
export const getExhibitionsListSucceeded = actionCreator(ActionTypes.GET_EXHIBITIONS_LIST_SUCCEEDED)
export const getExhibitionsListFailed = actionCreator(ActionTypes.GET_EXHIBITIONS_LIST_FAILED)

export const getActiveExhibitions = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS)
export const getActiveExhibitionsSucceeded = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS_SUCCEEDED)
export const getActiveExhibitionsFailed = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS_FAILED)

export const getExhibitionDetails = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS)
export const getExhibitionDetailsSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_SUCCEEDED)
export const getExhibitionDetailsFailed = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_FAILED)

export const getExhibitionDetailsPublic = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_PUBLIC)
export const getExhibitionDetailsPublicSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_PUBLIC_SUCCEEDED)
export const getExhibitionDetailsPublicFailed = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_PUBLIC_FAILED)

export const clearRegisteredSuccessfullyFlag = actionCreator(ActionTypes.CLEAR_REGISTERED_SUCCESSFULLY_FLAG)

export const setVisitorApprovalStatus = actionCreator(ActionTypes.SET_VISITOR_APPROVAL_STATUS)
export const setVisitorApprovalStatusSucceeded = actionCreator(ActionTypes.SET_VISITOR_APPROVAL_STATUS_SUCCEEDED)
export const setVisitorApprovalStatusFailed = actionCreator(ActionTypes.SET_VISITOR_APPROVAL_STATUS_FAILED)

export const getExhibitorProfile = actionCreator(ActionTypes.GET_EXHIBITOR_PROFILE)
export const getExhibitorProfileSucceeded = actionCreator(ActionTypes.GET_EXHIBITOR_PROFILE_SUCCEEDED)
export const getExhibitorProfileFailed = actionCreator(ActionTypes.GET_EXHIBITOR_PROFILE_FAILED)

export const getVisitorProfile = actionCreator(ActionTypes.GET_VISITOR_PROFILE)
export const getVisitorProfileSucceeded = actionCreator(ActionTypes.GET_VISITOR_PROFILE_SUCCEEDED)
export const getVisitorProfileFailed = actionCreator(ActionTypes.GET_VISITOR_PROFILE_FAILED)

export const getVisitorExhibitors = actionCreator(ActionTypes.GET_VISITOR_EXHIBITORS)
export const getVisitorExhibitorsSucceeded = actionCreator(ActionTypes.GET_VISITOR_EXHIBITORS_SUCCEEDED)
export const getVisitorExhibitorsFailed = actionCreator(ActionTypes.GET_VISITOR_EXHIBITORS_FAILED)

export const updateExhibitorProfile = actionCreator(ActionTypes.UPDATE_EXHIBITOR_PROFILE)
export const updateExhibitorProfileSucceeded = actionCreator(ActionTypes.UPDATE_EXHIBITOR_PROFILE_SUCCEEDED)
export const updateExhibitorProfileFailed = actionCreator(ActionTypes.UPDATE_EXHIBITOR_PROFILE_FAILED)

export const clearUpdateExhibitionStatus = actionCreator(ActionTypes.CLEAR_UPDATE_EXHIBITION_STATUS)
export const updateExhibition = actionCreator(ActionTypes.UPDATE_EXHIBITION)
export const updateExhibitionSucceeded = actionCreator(ActionTypes.UPDATE_EXHIBITION_SUCCEEDED)
export const updateExhibitionFailed = actionCreator(ActionTypes.UPDATE_EXHIBITION_FAILED)

export const updateExhibitionLogo = actionCreator(ActionTypes.UPDATE_EXHIBITION_LOGO)
export const updateExhibitionLogoSucceeded = actionCreator(ActionTypes.UPDATE_EXHIBITION_LOGO_SUCCEEDED)
export const updateExhibitionLogoFailed = actionCreator(ActionTypes.UPDATE_EXHIBITION_LOGO_FAILED)

export const clearActionOnVisitorStatus = actionCreator(ActionTypes.CLEAR_ACTION_ON_VISITOR_STATUS)
export const clearActionOnExhibitorStatus = actionCreator(ActionTypes.CLEAR_ACTION_ON_EXHIBITOR_STATUS)
export const createVisitor = actionCreator(ActionTypes.CREATE_VISITOR)
export const createVisitorSucceeded = actionCreator(ActionTypes.CREATE_VISITOR_SUCCEEDED)
export const createVisitorFailed = actionCreator(ActionTypes.CREATE_VISITOR_FAILED)

export const updateVisitor = actionCreator(ActionTypes.UPDATE_VISITOR)
export const updateVisitorSucceeded = actionCreator(ActionTypes.UPDATE_VISITOR_SUCCEEDED)
export const updateVisitorFailed = actionCreator(ActionTypes.UPDATE_VISITOR_FAILED)

export const removeVisitor = actionCreator(ActionTypes.REMOVE_VISITOR)
export const removeVisitorSucceeded = actionCreator(ActionTypes.REMOVE_VISITOR_SUCCEEDED)
export const removeVisitorFailed = actionCreator(ActionTypes.REMOVE_VISITOR_FAILED)

export const clearBulkVisitorStatus = actionCreator(ActionTypes.CLEAR_BULK_VISITORS_STATUS)
export const createBulkVisitors = actionCreator(ActionTypes.CREATE_BULK_VISITORS)
export const createBulkVisitorsSucceeded = actionCreator(ActionTypes.CREATE_BULK_VISITORS_SUCCEEDED)
export const createBulkVisitorsFailed = actionCreator(ActionTypes.CREATE_BULK_VISITORS_FAILED)

export const creatBulkVisitorsForBadges = actionCreator(ActionTypes.CREATE_BULK_VISITORS_FOR_BADGES)
export const creatBulkVisitorsForBadgesSucceeded = actionCreator(ActionTypes.CREATE_BULK_VISITORS_FOR_BADGES_SUCCEEDED)
export const creatBulkVisitorsForBadgesFailed = actionCreator(ActionTypes.CREATE_BULK_VISITORS_FOR_BADGES_FAILED)

export const clearActionOnPartnerStatus = actionCreator(ActionTypes.CLEAR_ACTION_ON_PARTNER_STATUS)
export const createPartner = actionCreator(ActionTypes.CREATE_PARTNER)
export const createPartnerSucceeded = actionCreator(ActionTypes.CREATE_PARTNER_SUCCEEDED)
export const createPartnerFailed = actionCreator(ActionTypes.CREATE_PARTNER_FAILED)

export const clearBulkPartnersStatus = actionCreator(ActionTypes.CLEAR_BULK_PARTNERS_STATUS)
export const createBulkPartners = actionCreator(ActionTypes.CREATE_BULK_PARTNERS)
export const createBulkPartnersSucceeded = actionCreator(ActionTypes.CREATE_BULK_PARTNERS_SUCCEEDED)
export const createBulkPartnersFailed = actionCreator(ActionTypes.CREATE_BULK_PARTNERS_FAILED)

export const storeExhibitionAttachment = actionCreator(ActionTypes.STORE_EXHIBITION_ATTACHMENT)
export const storeExhibitionAttachmentSucceeded = actionCreator(ActionTypes.STORE_EXHIBITION_ATTACHMENT_SUCCEEDED)
export const storeExhibitionAttachmentFailed = actionCreator(ActionTypes.STORE_EXHIBITION_ATTACHMENT_FAILED)

export const getVisitorCategories = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES)
export const getVisitorCategoriesSucceeded = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES_SUCCEEDED)
export const getVisitorCategoriesFailed = actionCreator(ActionTypes.GET_VISITOR_CATEGORIES_FAILED)

export const saveVisitorCategories = actionCreator(ActionTypes.SAVE_VISITOR_CATEGORIES)
export const saveVisitorCategoriesSucceeded = actionCreator(ActionTypes.SAVE_VISITOR_CATEGORIES_SUCCEEDED)
export const saveVisitorCategoriesFailed = actionCreator(ActionTypes.SAVE_VISITOR_CATEGORIES_FAILED)

export const updateVisitorCategories = actionCreator(ActionTypes.UPDATE_VISITOR_CATEGORIES)
export const updateVisitorCategoriesSucceeded = actionCreator(ActionTypes.UPDATE_VISITOR_CATEGORIES_SUCCEEDED)
export const updateVisitorCategoriesFailed = actionCreator(ActionTypes.UPDATE_VISITOR_CATEGORIES_FAILED)

export const deleteVisitorCategory = actionCreator(ActionTypes.DELETE_VISITOR_CATEGORY)
export const deleteVisitorCategorySucceeded = actionCreator(ActionTypes.DELETE_VISITOR_CATEGORY_SUCCEEDED)
export const deleteVisitorCategoryFailed = actionCreator(ActionTypes.DELETE_VISITOR_CATEGORY_FAILED)

export const removePartner = actionCreator(ActionTypes.REMOVE_PARTNER)
export const removePartnerSucceeded = actionCreator(ActionTypes.REMOVE_PARTNER_SUCCEEDED)
export const removePartnerFailed = actionCreator(ActionTypes.REMOVE_PARTNER_FAILED)

export const sendVisitorInvitations = actionCreator(ActionTypes.SEND_VISITOR_INVITATIONS)
export const sendVisitorInvitationsSucceeded = actionCreator(ActionTypes.SEND_VISITOR_INVITATIONS_SUCCEEDED)
export const sendVisitorInvitationsFailed = actionCreator(ActionTypes.SEND_VISITOR_INVITATIONS_FAILED)

export const sendExhibitorInvitations = actionCreator(ActionTypes.SEND_EXHIBITOR_INVITATIONS)
export const sendExhibitorInvitationsSucceeded = actionCreator(ActionTypes.SEND_EXHIBITOR_INVITATIONS_SUCCEEDED)
export const sendExhibitorInvitationsFailed = actionCreator(ActionTypes.SEND_EXHIBITOR_INVITATIONS_FAILED)

export const sendPartnerBadges = actionCreator(ActionTypes.SEND_PARTNER_BADGES)
export const sendPartnerBadgesSucceeded = actionCreator(ActionTypes.SEND_PARTNER_BADGES_SUCCEEDED)
export const sendPartnerBadgesFailed = actionCreator(ActionTypes.SEND_PARTNER_BADGES_FAILED)

export const getExhibitionBusinessCategories = actionCreator(ActionTypes.GET_EXHIBITION_BUSINESS_CATEGORIES)
export const getExhibitionBusinessCategoriesSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_BUSINESS_CATEGORIES_SUCCEEDED)
export const getExhibitionBusinessCategoriesFailed = actionCreator(ActionTypes.GET_EXHIBITION_BUSINESS_CATEGORIES_FAILED)

export const getInterests = actionCreator(ActionTypes.GET_INTERESTS)
export const getInterestsSucceeded = actionCreator(ActionTypes.GET_INTERESTS_SUCCEEDED)
export const getInterestsFailed = actionCreator(ActionTypes.GET_INTERESTS_FAILED)

export const updateInterests = actionCreator(ActionTypes.UPDATE_INTERESTS)
export const updateInterestsSucceeded = actionCreator(ActionTypes.UPDATE_INTERESTS_SUCCEEDED)
export const updateInterestsFailed = actionCreator(ActionTypes.UPDATE_INTERESTS_FAILED)

export const getExhibitionEmails = actionCreator(ActionTypes.GET_EXHIBITION_EMAILS)
export const getExhibitionEmailsSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_EMAILS_SUCCEEDED)
export const getExhibitionEmailsFailed = actionCreator(ActionTypes.GET_EXHIBITION_EMAILS_FAILED)

export const getCountries = actionCreator(ActionTypes.GET_COUNTRIES)
export const getCountriesSucceeded = actionCreator(ActionTypes.GET_COUNTRIES_SUCCEEDED)
export const getCountriesFailed = actionCreator(ActionTypes.GET_COUNTRIES_FAILED)

export const getMessageTemplateTypes = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_TYPES)
export const getMessageTemplateTypesSucceeded = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_TYPES_SUCCEEDED)
export const getMessageTemplateTypesFailed = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_TYPES_FAILED)

export const getMessageTemplateTypeVariables = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_TYPE_VARIABLES)
export const getMessageTemplateTypeVariablesSucceeded = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_SUCCEEDED)
export const getMessageTemplateTypeVariablesFailed = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_FAILED)

export const getMessageTemplate = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE)
export const getMessageTemplateSucceeded = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_SUCCEEDED)
export const getMessageTemplateFailed = actionCreator(ActionTypes.GET_MESSAGE_TEMPLATE_FAILED)

export const updateMessageTemplate = actionCreator(ActionTypes.UPDATE_MESSAGE_TEMPLATE)
export const updateMessageTemplateSucceeded = actionCreator(ActionTypes.UPDATE_MESSAGE_TEMPLATE_SUCCEEDED)
export const updateMessageTemplateFailed = actionCreator(ActionTypes.UPDATE_MESSAGE_TEMPLATE_FAILED)

export const transferExhibitorsFromEntersoft = actionCreator(ActionTypes.TRANSFER_EXHIBITORS_FROM_ENTERSOFT)
export const transferExhibitorsFromEntersoftSucceeded = actionCreator(ActionTypes.TRANSFER_EXHIBITORS_FROM_ENTERSOFT_SUCCEEDED)
export const transferExhibitorsFromEntersoftFailed = actionCreator(ActionTypes.TRANSFER_EXHIBITORS_FROM_ENTERSOFT_FAILED)
export const transferExhibitorsToEntersoft = actionCreator(ActionTypes.TRANSFER_EXHIBITORS_TO_ENTERSOFT)
export const transferExhibitorsToEntersoftSucceeded = actionCreator(ActionTypes.TRANSFER_EXHIBITORS_TO_ENTERSOFT_SUCCEEDED)
export const transferExhibitorsToEntersoftFailed = actionCreator(ActionTypes.TRANSFER_EXHIBITORS_TO_ENTERSOFT_FAILED)
export const clearTransferMessage = actionCreator(ActionTypes.CLEAR_TRANSFER_MESSAGE)

export const exhibitorCatalogueConfirm = actionCreator(ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM)
export const exhibitorCatalogueConfirmSucceeded = actionCreator(ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_SUCCEEDED)
export const exhibitorCatalogueConfirmFailed = actionCreator(ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_FAILED)

export const exhibitorCatalogueConfirmReset = actionCreator(ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_RESET)
export const exhibitorCatalogueConfirmResetSucceeded = actionCreator(ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_RESET_SUCCEEDED)
export const exhibitorCatalogueConfirmResetFailed = actionCreator(ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_RESET_FAILED)

export const getRules = actionCreator(ActionTypes.GET_RULES)
export const getRulesSucceeded = actionCreator(ActionTypes.GET_RULES_SUCCEEDED)
export const getRulesFailed = actionCreator(ActionTypes.GET_RULES_FAILED)

export const saveRule = actionCreator(ActionTypes.SAVE_RULE)
export const saveRuleSucceeded = actionCreator(ActionTypes.SAVE_RULE_SUCCEEDED)
export const saveRuleFailed = actionCreator(ActionTypes.SAVE_RULE_FAILED)

export const updateRule = actionCreator(ActionTypes.UPDATE_RULE)
export const updateRuleSucceeded = actionCreator(ActionTypes.UPDATE_RULE_SUCCEEDED)
export const updateRuleFailed = actionCreator(ActionTypes.UPDATE_RULE_FAILED)

export const deleteRule = actionCreator(ActionTypes.DELETE_RULE)
export const deleteRuleSucceeded = actionCreator(ActionTypes.DELETE_RULE_SUCCEEDED)
export const deleteRuleFailed = actionCreator(ActionTypes.DELETE_RULE_FAILED)

export const getRuleDetails = actionCreator(ActionTypes.GET_RULE_DETAILS)
export const getRuleDetailsSucceeded = actionCreator(ActionTypes.GET_RULE_DETAILS_SUCCEEDED)
export const getRuleDetailsFailed = actionCreator(ActionTypes.GET_RULE_DETAILS_FAILED)

export const saveRuleDetails = actionCreator(ActionTypes.SAVE_RULE_DETAILS)
export const saveRuleDetailsSucceeded = actionCreator(ActionTypes.SAVE_RULE_DETAILS_SUCCEEDED)
export const saveRuleDetailsFailed = actionCreator(ActionTypes.SAVE_RULE_DETAILS_FAILED)

export const updateRuleDetails = actionCreator(ActionTypes.UPDATE_RULE_DETAILS)
export const updateRuleDetailsSucceeded = actionCreator(ActionTypes.UPDATE_RULE_DETAILS_SUCCEEDED)
export const updateRuleDetailsFailed = actionCreator(ActionTypes.UPDATE_RULE_DETAILS_FAILED)

export const deleteRuleDetails = actionCreator(ActionTypes.DELETE_RULE_DETAILS)
export const deleteRuleDetailsSucceeded = actionCreator(ActionTypes.DELETE_RULE_DETAILS_SUCCEEDED)
export const deleteRuleDetailsFailed = actionCreator(ActionTypes.DELETE_RULE_DETAILS_FAILED)

export const addRuleDetails = actionCreator(ActionTypes.ADD_RULE_DETAILS)
export const editRuleDetails = actionCreator(ActionTypes.EDIT_RULE_DETAILS)
export const setRuleDetails = actionCreator(ActionTypes.SET_RULE_DETAILS)
export const cancelEditRuleDetails = actionCreator(ActionTypes.CANCEL_EDIT_RULE_DETAILS)
export const clearRuleStatus = actionCreator(ActionTypes.CLEAR_RULE_STATUS)

export const getCoorganizations = actionCreator(ActionTypes.GET_COORGANIZATIONS)
export const getCoorganizationsSucceeded = actionCreator(ActionTypes.GET_COORGANIZATIONS_SUCCEEDED)
export const getCoorganizationsFailed = actionCreator(ActionTypes.GET_COORGANIZATIONS_FAILED)

export const saveCoorganization = actionCreator(ActionTypes.SAVE_COORGANIZATION)
export const saveCoorganizationSucceeded = actionCreator(ActionTypes.SAVE_COORGANIZATION_SUCCEEDED)
export const saveCoorganizationFailed = actionCreator(ActionTypes.SAVE_COORGANIZATION_FAILED)

export const updateCooranization = actionCreator(ActionTypes.UPDATE_COORGANIZATION)
export const updateCooranizationSucceeded = actionCreator(ActionTypes.UPDATE_COORGANIZATION_SUCCEEDED)
export const updateCooranizationFailed = actionCreator(ActionTypes.UPDATE_COORGANIZATION_FAILED)

export const deleteCoorganization = actionCreator(ActionTypes.DELETE_COORGANIZATION)
export const deleteCoorganizationSucceeded = actionCreator(ActionTypes.DELETE_COORGANIZATION_SUCCEEDED)
export const deleteCoorganizationFailed = actionCreator(ActionTypes.DELETE_COORGANIZATION_FAILED)

export const clearCoorganizationStatus = actionCreator(ActionTypes.CLEAR_COORGANIZATION_STATUS)

export const addExhibitionToCoorganization = actionCreator(ActionTypes.ADD_EXHIBITION_TO_COORGANIZATION)
export const addExhibitionToCoorganizationSucceeded = actionCreator(ActionTypes.ADD_EXHIBITION_TO_COORGANIZATION_SUCCEEDED)
export const addExhibitionToCoorganizationFailed = actionCreator(ActionTypes.ADD_EXHIBITION_TO_COORGANIZATION_FAILED)

export const removeExhibitionFromCoorganization = actionCreator(ActionTypes.REMOVE_EXHIBITION_FROM_COORGANIZATION)
export const removeExhibitionFromCoorganizationSucceeded = actionCreator(ActionTypes.REMOVE_EXHIBITION_FROM_COORGANIZATION_SUCCEEDED)
export const removeExhibitionFromCoorganizationFailed = actionCreator(ActionTypes.REMOVE_EXHIBITION_FROM_COORGANIZATION_FAILED)

export const setExhibitorEditMode = actionCreator(ActionTypes.SET_EXHIBITOR_EDIT_MODE)

export const updateQuickPrintFooterImage = actionCreator(ActionTypes.UPDATE_QUICK_PRINT_FOOTER_IMAGE)
export const updateQuickPrintFooterImageSucceeded = actionCreator(ActionTypes.UPDATE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED)
export const updateQuickPrintFooterImageFailed = actionCreator(ActionTypes.UPDATE_QUICK_PRINT_FOOTER_IMAGE_FAILED)

export const deleteQuickPrintFooterImage = actionCreator(ActionTypes.DELETE_QUICK_PRINT_FOOTER_IMAGE)
export const deleteQuickPrintFooterImageSucceeded = actionCreator(ActionTypes.DELETE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED)
export const deleteQuickPrintFooterImageFailed = actionCreator(ActionTypes.DELETE_QUICK_PRINT_FOOTER_IMAGE_FAILED)

export const deleteMultipleVisitors = actionCreator(ActionTypes.DELETE_MULTIPLE_VISITORS)
export const deleteMultipleVisitorsSucceeded = actionCreator(ActionTypes.DELETE_MULTIPLE_VISITORS_SUCCEEDED)
export const deleteMultipleVisitorsFailed = actionCreator(ActionTypes.DELETE_MULTIPLE_VISITORS_FAILED)

export const getReportData = actionCreator(ActionTypes.GET_REPORT_DATA)
export const getReportDataSucceeded = actionCreator(ActionTypes.GET_REPORT_DATA_SUCCEEDED)
export const getReportDataFailed = actionCreator(ActionTypes.GET_REPORT_DATA_FAILED)
export const setReportMessage = actionCreator(ActionTypes.SET_REPORT_MESSAGE)

export const getGridState = actionCreator(ActionTypes.GET_GRID_STATE)
export const getGridStateSucceeded = actionCreator(ActionTypes.GET_GRID_STATE_SUCCEEDED)
export const getGridStateFailed = actionCreator(ActionTypes.GET_GRID_STATE_FAILED)

export const saveGridState = actionCreator(ActionTypes.SAVE_GRID_STATE)
export const saveGridStateSucceeded = actionCreator(ActionTypes.SAVE_GRID_STATE_SUCCEEDED)
export const saveGridStateFailed = actionCreator(ActionTypes.SAVE_GRID_STATE_FAILED)
export const setGridStateMessage = actionCreator(ActionTypes.SET_GRID_STATE_MESSAGE)
export const deleteGridState = actionCreator(ActionTypes.DELETE_GRID_STATE)
export const deleteGridStateSucceeded = actionCreator(ActionTypes.DELETE_GRID_STATE_SUCCEEDED)
export const deleteGridStateFailed = actionCreator(ActionTypes.DELETE_GRID_STATE_FAILED)
export const setActiveGridState = actionCreator(ActionTypes.SET_ACTIVE_GRID_STATE)
export const setActiveGridStateSucceeded = actionCreator(ActionTypes.SET_ACTIVE_GRID_STATE_SUCCEEDED)
export const setActiveGridStateFailed = actionCreator(ActionTypes.SET_ACTIVE_GRID_STATE_FAILED)
export const setNewGridStateLabel = actionCreator(ActionTypes.SET_NEW_GRID_STATE_LABEL)
export const toggleGridPopper = actionCreator(ActionTypes.TOGGLE_GRID_POPPER)

export const getExhibitionsGroupedHeaders = actionCreator(ActionTypes.GET_EXHIBITIONS_GROUPED_HEADERS)
export const getExhibitionsGroupedHeadersSucceeded = actionCreator(ActionTypes.GET_EXHIBITIONS_GROUPED_HEADERS_SUCCEEDED)
export const getExhibitionsGroupedHeadersFailed = actionCreator(ActionTypes.GET_EXHIBITIONS_GROUPED_HEADERS_FAILED)

export const getExhibitionsGroupedDetails = actionCreator(ActionTypes.GET_EXHIBITIONS_GROUPED_DETAILS)
export const getExhibitionsGroupedDetailsSucceeded = actionCreator(ActionTypes.GET_EXHIBITIONS_GROUPED_DETAILS_SUCCEEDED)
export const getExhibitionsGroupedDetailsFailed = actionCreator(ActionTypes.GET_EXHIBITIONS_GROUPED_DETAILS_FAILED)

export const setExhibitionMessage = actionCreator(ActionTypes.SET_EXHIBITION_MESSAGE)

export const getUITranslations = actionCreator(ActionTypes.GET_UI_TRANSLATIONS)
export const getUITranslationsSucceeded = actionCreator(ActionTypes.GET_UI_TRANSLATIONS_SUCCEEDED)
export const getUITranslationsFailed = actionCreator(ActionTypes.GET_UI_TRANSLATIONS_FAILED)

export const updateUITranslation = actionCreator(ActionTypes.UPDATE_UI_TRANSLATION)
export const updateUITranslationSucceeded = actionCreator(ActionTypes.UPDATE_UI_TRANSLATION_SUCCEEDED)
export const updateUITranslationFailed = actionCreator(ActionTypes.UPDATE_UI_TRANSLATION_FAILED)

export const setUITransMessage = actionCreator(ActionTypes.SET_UI_TRANSLATION_MESSAGE)

export const copyExhibitionSettings = actionCreator(ActionTypes.COPY_EXHIBITION_SETTINGS)
export const copyExhibitionSettingsSucceeded = actionCreator(ActionTypes.COPY_EXHIBITION_SETTINGS_SUCCEEDED)
export const copyExhibitionSettingsFailed = actionCreator(ActionTypes.COPY_EXHIBITION_SETTINGS_FAILED)