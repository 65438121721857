import React, {useEffect} from "react";
import Toolbar from "@mui/material/Toolbar";
import {Checkbox, FormControl, InputLabel, ListItemText, OutlinedInput, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const Filters = ({onFilterChange}) => {
    const {t} = useTranslation(EXHIBITION)
    const [filterByStatus, setFilterByStatus] = React.useState([])
    const [changedByUser, setChangedByUser] = React.useState(false)
    const handleChange = (callback, value) => {
        setChangedByUser(true)
        callback(value)
    }
    useEffect(() => {
        if (changedByUser) {
            onFilterChange({
                status: filterByStatus,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByStatus])
    return (
        <Toolbar disableGutters>
            <FormControl sx={{minWidth: 200}} size={"small"}>
                <InputLabel id="filter-by-status">{t('Status')}</InputLabel>
                <Select
                    labelId="filter-by-status"
                    label={t('Status')}
                    value={filterByStatus}
                    multiple
                    onChange={(e) => handleChange(setFilterByStatus, e.target.value)}
                    input={<OutlinedInput label={t('Status')}/>}
                    renderValue={(selected) => selected.map(s => t("status_" + s)).join(', ')}
                    MenuProps={MenuProps}
                >
                    {[1, 2, 3].map((item) => (
                        <MenuItem value={item} key={item}>
                            <Checkbox checked={filterByStatus.indexOf(item) > -1}/>
                            <ListItemText primary={t("status_" + item)}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Toolbar>
    )
}

export default Filters