// @flow
import React, {useMemo} from 'react';
import Table from "../../../components/grids/table/table";
import UnstructuredTableView from "../../../components/grids/table/partials/views/unstructuredTableView";
import {Grid} from "@mui/material";
import {EventStatus} from "../../../helpers/date";
import ExhibitionPreviewHorizontal from "../../../components/app/exhibitionPreview/exhibitionPreviewHorizontal";
import useExhibitionAction from "../../../hooks/exhibitions/useExhibitionAction";
import {filterExhibitions} from "../../../helpers/filterExhibitions";
import useExhibitionData from "../../../hooks/exhibitions/useExhibitionData";

type Props = {};

const ExhibitorView = (props: Props) => {
	const {indexTable} = useExhibitionData()
	const {getExhibitions} = useExhibitionAction()
	
	const items = filterExhibitions(indexTable.items, {})
	
	const renderRow = useMemo(() =>
		(row) => (
			<Grid key={row.id} item xs={12}>
				<ExhibitionPreviewHorizontal
					exhibition={row.original}
				/>
			</Grid>
		), [])
	
	const data = {
		loading: indexTable.loading,
		count: items.length,
		items: items.filter(item => item.status !== EventStatus.EXPIRED)
	}
	
	return (
		<Grid container spacing={4}>
			<Table
				data={data}
				columns={[
					{
						header: '',
						accessor: 'id',
					}
				]}
				dataManager={getExhibitions}
				view={UnstructuredTableView}
				noMeta
				noPagination
				renderRow={renderRow}
			/>
		</Grid>
	);
};

export default ExhibitorView