// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import * as yup from "yup";
import {FormikProvider, useFormik} from "formik";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ImageFileUpload from "../../../../../components/general/imageFileUpload/imageFileUpload";

type Props = {};

const FooterImage = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {id, language, exhibition, deleteQuickPrintFooterImage, updateQuickPrintFooterImage} = props
	const onChange = (value) => {
		updateQuickPrintFooterImage(id, {file: value, language})
	}
	
	const validationSchema = yup.object({})
	const formik = useFormik({
		initialValues: {
			files: (
				exhibition.quickPrintFooterImage &&
				exhibition.quickPrintFooterImage[language]
			) ? [
				{
					preview: exhibition.quickPrintFooterImage[language],
					name: "quickPrintFooterImage",
				}
			] : []
		},
		validationSchema: validationSchema,
	})
	const onFileChange = (val) => {
		onChange(val)
	}
	return (
		<ListItem>
			<ListItemText
				sx={{paddingRight: 1}}
				primary={t("Footer Image") + " " + language.toUpperCase()}
			/>
			<FormikProvider value={formik}>
				<Box
					sx={{
						'& .MuiTextField-root': {my: 1},
					}}
					component="form"
					onSubmit={formik.handleSubmit}
				>
					<ImageFileUpload
						files={formik.values.files}
						accept={{
							"image/*": [".jpeg", ".png"]
						}}
						multiple={false}
						onRemove={() => deleteQuickPrintFooterImage(id, language)}
						onSelected={onFileChange}
					/>
				</Box>
			</FormikProvider>
		</ListItem>
	)
};

export default FooterImage