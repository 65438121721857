//@flow
import React, {useEffect} from 'react';
import ScanDetails from "../../components/app/scanDetails/scanDetails";
import {useVisitor} from "../../hooks/exhibitions/useVisitor";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import useBadgeData from "../../hooks/badges/useBadgeData";
import useBadgeAction from "../../hooks/badges/useBadgeAction";

const VisitorInner = ({badgeId, profile}) => {
    const {badgeById: badge} = useBadgeData({badgeId: badgeId})
    const {getBadge} = useBadgeAction()
    useEffect(() => {
        getBadge(badgeId)
    }, [badgeId, getBadge])
    if (!badge) return null
    return (
        <ScanDetails
            profile={profile}
            badge={badge}
            limitedView={false}
        />
    )
}

const VisitorProfile = ({id: propsId}) => {
    const {visitor, loading} = useVisitor({
        overrideVisitorId: propsId,
        doNotFetchExhibitors: true,
        doNotFetchCategories: true
    })
    return (
        <React.Fragment>
            {visitor ? (
                <VisitorInner
                    badgeId={visitor.Badge}
                    profile={visitor}
                />
            ) : (
                <ContentLoader
                    loading={loading}
                />
            )}
        </React.Fragment>
    )
}

export default VisitorProfile
