//@flow
import React from 'react';
import {ListSubheader, Switch, TextField} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import {Update} from "../../../../../hooks/exhibitions/useExhibitionAction";
import {useDebouncedCallback} from "../../../../../hooks/useDebouncedCallback";
import type {Exhibition} from "../../../../../types/exhibition/types";
import CatalogueLanguage from "./components/catalogueLanguage";
import DisplayBrandsInCatalog from "./components/displayBrandsInCatalog";
import {NumericFormat} from "react-number-format";
import Typography from "@mui/material/Typography";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";

type Props = {
	id: string,
	exhibition: Exhibition,
	updateExhibition: Function
}

const CatalogSettings = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {id, exhibition, updateExhibition} = props
	const onChange = (value) => {
		updateExhibition(id, value, Update.DaysForCatalogSubmission)
	}
	const onChangeCatalogueProfileLength = (value) => {
		updateExhibition(id, value, Update.CatalogueProfileLength)
	}
	const onChangeDaysForCatalogNotification = (value) => {
		updateExhibition(id, value, Update.DaysForCatalogNotification)
	}
	const {value, debouncedOnChange} = useDebouncedCallback(onChange, exhibition.daysForCatalogSubmission)
	const {
		value: daysForCatalogNotification,
		debouncedOnChange: debouncedOnChangeDaysForCatalogNotification
	} = useDebouncedCallback(onChangeDaysForCatalogNotification, exhibition.daysForCatalogNotification)
	const {
		value: catalogueProfileLength,
		debouncedOnChange: debouncedOnChangeCatalogueLength
	} = useDebouncedCallback(onChangeCatalogueProfileLength, exhibition.catalogueProfileLength)
	const togglePreregistrationEnabled = () => {
		updateExhibition(id, !exhibition.catalogLocked, Update.CatalogLocked)
	}
	const toggleCatalogKioskSign = () => {
		updateExhibition(id, !exhibition.catalogEnableKioskSign, Update.CatalogEnableKioskSign)
	}
	const toggleIsKioskSignEditable = () => {
		updateExhibition(id, !exhibition.isKioskSignEditable, Update.isKioskSignEditable)
	}
	return (
		<List
			subheader={
				<ListSubheader sx={{backgroundColor: '#F9FBF2'}}>
					<Typography
						variant={'button'}
						sx={{color: '#000000'}}>
						{normalizeGreek(t('Catalogue'))}
					</Typography>
				</ListSubheader>
			}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<CatalogueLanguage
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t('Catalogue Locked')}
					secondary={t('If you enable this, then exhibitors will not be able to edit their catalogue information. However they will be able to view them. Organizers will not be able to edit exhibitor catalogues either. Catalogues can only be updated from EnterSoft data transfer.')}
				/>
				<Switch
					edge="end"
					onChange={togglePreregistrationEnabled}
					checked={exhibition.catalogLocked}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				disabled={exhibition.catalogLocked}
			>
				<ListItemText
					primary={t("Number of days before catalog becomes non editable")}
					secondary={t("Exhibitors will not be able to update their catalog profile after the number of days you specify here, counting from event start date. Organizers will still be able to update exhibitor catalogues after this date.")}
				/>
				<NumericFormat
					defaultValue={value}
					onChange={debouncedOnChange}
					variant={'outlined'}
					inputProps={{maxLength: 5}}
					required
					thousandSeparator={''}
					decimalScale={0}
					allowNegative={false}
					customInput={TextField}
					size={'small'}
					allowLeadingZeros={true}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t("Number of days before end of catalogue submission to alert exhibitors")}
					secondary={t("If email type 'Ενημέρωση Καταλόγου' is activated, set here the number of days before end of catalogue submission, that an automatic e-mail will alert exhibitors.")}
				/>
				<NumericFormat
					defaultValue={daysForCatalogNotification}
					onChange={debouncedOnChangeDaysForCatalogNotification}
					variant={'outlined'}
					inputProps={{maxLength: 3}}
					required
					thousandSeparator={''}
					decimalScale={0}
					allowNegative={false}
					customInput={TextField}
					size={'small'}
					allowLeadingZeros={true}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t("Default maximum number of characters in catalog")}
				/>
				<NumericFormat
					defaultValue={catalogueProfileLength}
					onChange={debouncedOnChangeCatalogueLength}
					variant={'outlined'}
					inputProps={{maxLength: 5}}
					required
					thousandSeparator={''}
					decimalScale={0}
					allowNegative={false}
					customInput={TextField}
					size={'small'}
					allowLeadingZeros={true}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t('Show kiosk sign')}
				/>
				<Switch
					edge="end"
					onChange={toggleCatalogKioskSign}
					checked={exhibition.catalogEnableKioskSign}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t('Kiosk Sign Editable')}
					secondary={t('Allow exhibitor to edit their kiosk sign. You can disable this feature for specific exhibitors, from exhibitor settings.')}
				/>
				<Switch
					edge="end"
					onChange={toggleIsKioskSignEditable}
					checked={exhibition.isKioskSignEditable}
				/>
			</ListItem>
			<Divider component="li"/>
			<DisplayBrandsInCatalog
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
		</List>
	)
}

export default CatalogSettings;