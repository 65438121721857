import React from "react";
import Checkbox from "./partials/checkbox";
import Text from "./partials/text";
import InterestsCheckBoxList from "./partials/interestsCheckBoxList";
import SelectCountry from "./partials/selectCountry";
import SelectCategory from "./partials/selectCategory";
import MobilePhone from "./partials/mobilePhone";

const getInputComponent = (fieldType) => {
    switch (fieldType) {
        case ("agreeToTerms"):
            return Checkbox
        case ("isIndividual"):
            return Checkbox
        case ("hasRevisited"):
            return Checkbox
        case ("country"):
            return SelectCountry
        case ("characterization"):
            return SelectCategory
        case ("interests"):
            return InterestsCheckBoxList
        case ("mobile"):
            return MobilePhone
        default:
            return Text
    }
}

const Input = React.memo((props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors} = props
    const InputComponent = getInputComponent(field.type)
    return (
        <div>
            <InputComponent
                formik={formik}
                values={values}
                field={field}
                value={value}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
            />
        </div>
    )
})

export default Input
