import {UnmappedActiveExhibitions} from "./types";

export const mapActiveExhibitions: UnmappedActiveExhibitions = (datum: UnmappedActiveExhibitions) => {
    if (!datum) return
    return {
        title: datum.Description ?? {
            en: datum.Description ?? '',
            el: datum.Description ?? '',
            // en: datum.DescriptionEN ?? '',
            // el: datum.DescriptionEL ?? '',
        },
        id: datum.ids ? datum.ids.split(',')[0] : datum.id,
        coorganizationIds: datum.ids,
        isCoorganization: datum.isCoorganization === "1",
    }
}