import {useParams} from "react-router";
import {useEffect} from "react";
import useBadgeAction from "./useBadgeAction";
import useBadgeData from "./useBadgeData";

export const useBadgeConfiguration = ({id: propsId} = {}) => {
    const {id: paramsId} = useParams()
    const id = propsId ?? paramsId
    const {getBadgeConfiguration} = useBadgeAction()
    const {badgeConfigurationByExhibitionId: badgeConfiguration, loading} = useBadgeData({exhibitionId: id})
    useEffect(() => {
        getBadgeConfiguration(id)
    }, [getBadgeConfiguration, id])
    return {
        id,
        badgeConfiguration,
        loading
    }
}