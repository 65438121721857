// @flow
import React, {useEffect, useState} from 'react';
import {EventStatus} from "../../../../../helpers/date";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import ExcelImport from "../../common/visitors/excelImport";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import useExhibitionAction from "../../../../../hooks/exhibitions/useExhibitionAction";
import {useExhibition} from "../../../../../hooks/exhibitions/useExhibition";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION, EXHIBITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {API, PaperSize} from "../../../../../helpers/constants";
import {printFile} from "../../../../../helpers/fileHandle";
import {useAuthenticatedFile} from "../../../../../components/general/authenticatedLink/useAuthenticatedFile";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import Form from "./partials/form";
import {useCountries} from "../../../../../hooks/countries/useCountries";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {ListItem} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

type Props = {
    exhibitionId: string
};

const FastPrintBadges = (props: Props) => {
    const {exhibitionId} = props
    const {t} = useTranslation(EXHIBITION)
    const {loading: isLoading, visitorBulkImportStatus, actionOnVisitorStatus} = useExhibitionData()
    const {clearBulkVisitorStatus, createVisitor, clearActionOnVisitorStatus} = useExhibitionAction()
    const [showFileUpload, setShowFileUpload] = useState(false)
    const {exhibition} = useExhibition({id: exhibitionId, noFetchFiles: true})
    const {loading, fetchFile} = useAuthenticatedFile()
    const {countries} = useCountries()

    useEffect(() => {
        if (
            visitorBulkImportStatus &&
            visitorBulkImportStatus.errors.length === 0 &&
            visitorBulkImportStatus.newItems.length > 0 &&
            visitorBulkImportStatus.importNum >= 0
        ) {
            const url = API + `/en/visitor/import-badge/${exhibition.visitorBadgeTemplateId}/${visitorBulkImportStatus.importNum}`
            fetchFile(url, printFile)
            setShowFileUpload(false);
            clearBulkVisitorStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitorBulkImportStatus])

    useEffect(() => {
        if (
            actionOnVisitorStatus &&
            actionOnVisitorStatus.type === "success" &&
            actionOnVisitorStatus.parameters &&
            actionOnVisitorStatus.parameters.id
        ) {
            const url = API + `/en/visitor/badge/${PaperSize.A4}/${actionOnVisitorStatus.parameters.id}`;
            fetchFile(url, printFile)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionOnVisitorStatus])

    return (
        <Box>
            <Paper sx={{mb: 1}} variant={"outlined"}>
                <List>
                    <ListItem component={"div"}>
                        <ListItemText
                            primary={
                                <Typography component={"div"} variant={"body1"}>
                                    <Trans
                                        i18nKey={'quick_import_visitors_for_badge_printing'}
                                        ns={EXHIBITOR_PROFILE}
                                    >
                                        On this page you can quick add <strong>VISITORS</strong> and print their badges.<br/>
                                        Press the <strong>IMPORT FROM EXCEL</strong> button to import multiple visitors
                                        or fill in the form for a single visitor and press <strong>SAVE VISITOR AND
                                        PRINT BADGE</strong> button.<br/><br/>
                                        <strong>Attention:</strong> Follow the import guidelines for successful visitor
                                        upload. Please do not close the window before printing process finishes.<br/>
                                    </Trans>
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </Paper>
            {exhibition &&
                exhibition.status !== EventStatus.EXPIRED && (
                    <Paper sx={{p: 2, pt: 0}}>
                        <Toolbar disableGutters variant={"dense"}>
                            <Box sx={{flexGrow: 1}}/>
                            <Stack direction="row">
                                <Button
                                    size={"small"}
                                    onClick={() => setShowFileUpload(true)}
                                >
                                    {normalizeGreek(t('Import from excel'))}
                                </Button>
                            </Stack>
                        </Toolbar>
                        <Form
                            loading={isLoading && loading}
                            exhibitionId={exhibitionId}
                            exhibition={exhibition}
                            createVisitor={createVisitor}
                            actionOnVisitorStatus={actionOnVisitorStatus}
                            clearActionOnVisitorStatus={clearActionOnVisitorStatus}
                            countries={countries}
                        />
                    </Paper>
                )}
            <ExcelImport
                isShortOrganizerImport={true}
                preregistrationNeedsApproval={false}
                visitorCategoryRequiredInImport={exhibition?.visitorCategoryRequiredInImport}
                exhibitionId={exhibitionId}
                show={showFileUpload}
                onClose={() => {
                    setShowFileUpload(false);
                    clearBulkVisitorStatus();
                }}
            />
            <ContentLoader
                withBackDrop={true}
                useCircularLoader={true}
                loading={loading}
                message={t("Printing... Please don't close the browser before the process ends.")}
            />
        </Box>
    );
};

export default FastPrintBadges