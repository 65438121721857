//@flow
import React from 'react';
import {FormikProvider, useFormik} from "formik";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import Toolbar from "@mui/material/Toolbar";
import useExhibitionAction, {Update} from "../../../hooks/exhibitions/useExhibitionAction";
import {useTranslation} from "react-i18next";
import {EXHIBITION, VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import ExhibitionUpdateStatus from "../alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import {useDragDropTree} from "../../general/dragDropTree/useDragDropTree";
import DragDropTree from "../../general/dragDropTree/dragDropTree";
import {ListItemText} from "@mui/material";
import Row from "./row";
import {v4 as uuidv4} from 'uuid';

type Props = {
    exhibitionId: string,
    fields: Array<Object>
}

export const PreregistrationFormContext = React.createContext(null)

const RenderTitle = ({data: item}) => {
    const {t} = useTranslation(VISITOR_PROFILE)
    return (
        <ListItemText
            primary={t(item.name)}
        />
    )
}

export const ElementTypes = {
    Column: 1,
    Row: 2,
}

const addBlockElement = (type) => {
    const keys = Object.keys(ElementTypes)
    return ({
        id: uuidv4(),
        type: 'layout_element',
        blockElementType: type,
        name: keys[keys.findIndex(s => ElementTypes[s] === type)],
        parent: 0,
        enabled: true,
        weight: 1
    })
}

const CustomizePreregistration = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {exhibitionId, fields} = props
    const {updateExhibition} = useExhibitionAction()
    const onSubmit = (values) => {
        updateExhibition(exhibitionId, values.fields, Update.PreregistrationForm)
    }
    const formik = useFormik({
        initialValues: {fields: fields},
        enableReinitialize: true,
        onSubmit: onSubmit,
    })

    const addElement = (type) => {
        const newFields = [addBlockElement(type), ...formik.values.fields,]
        formik.setFieldValue('fields', newFields)
    }
    const handleDragEnd = (updatedItems, outputItems) => {
        formik.setFieldValue('fields', outputItems)
    }
    const {itemsList, onDragEnd} = useDragDropTree({
        unprocessedItems: formik.values.fields,
        onDragEndCallback: handleDragEnd
    })
    return (
        <FormikProvider value={formik}>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{px: 1}}>
                    <PreregistrationFormContext.Provider value={{formik: formik}}>
                        <Toolbar disableGutters variant={"dense"}>
                            <Button onClick={() => addElement(ElementTypes.Column)}>
                                {normalizeGreek(t('Add Column'))}
                            </Button>
                            <Button onClick={() => addElement(ElementTypes.Row)}>
                                {normalizeGreek(t('Add Row'))}
                            </Button>
                            <Box sx={{flexGrow: 1}}/>
                            <Button
                                size={"small"}
                                type={"submit"}
                                startIcon={<SaveIcon/>}
                                variant="contained"
                            >
                                {normalizeGreek(t('Save'))}
                            </Button>
                        </Toolbar>
                        <Divider sx={{mb: 1}} light/>
                        <DragDropTree
                            items={itemsList}
                            renderItemTitle={RenderTitle}
                            renderItemActions={Row}
                            onDragEnd={onDragEnd}
                        />
                    </PreregistrationFormContext.Provider>
                </Box>
                <ExhibitionUpdateStatus/>
            </Box>
        </FormikProvider>
    )
}

export default CustomizePreregistration;
