import {createSelector} from "reselect";
import {parseMultiple} from "../../../helpers/parse";
import {parsePermission} from "../types/parse";
import {useSelector} from "react-redux";
import {checkPermissions} from "../helpers/checkPermissions";

const selectCurrentUserPermissions = createSelector(
    (state) => state.permissions.get('currentUser'),
    (data) => parseMultiple(data, parsePermission)
)

export const useHasPermission = (requiredPermissions: Array<string>, {shouldView, shouldEdit}) => {
    const currentUserPermissions = useSelector(selectCurrentUserPermissions)
    const {canView, canEdit} = checkPermissions(requiredPermissions, currentUserPermissions)
    return !((!canView && shouldView) || (!canEdit && shouldEdit));
}