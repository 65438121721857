import {actionCreator} from "../../helpers/actionCreator";
import * as ActionTypes from "./types";

export const clearScannedBadge = actionCreator(ActionTypes.CLEAR_SCANNED_BADGE)
export const registerNewScannedBadge = actionCreator(ActionTypes.REGISTER_NEW_SCANNED_BADGE)

export const getBadge = actionCreator(ActionTypes.GET_BADGE)
export const getBadgeSucceeded = actionCreator(ActionTypes.GET_BADGE_SUCCEEDED)
export const getBadgeFailed = actionCreator(ActionTypes.GET_BADGE_FAILED)

export const getBadgeConfiguration = actionCreator(ActionTypes.GET_BADGE_CONFIGURATION)
export const getBadgeConfigurationSucceeded = actionCreator(ActionTypes.GET_BADGE_CONFIGURATION_SUCCEEDED)
export const getBadgeConfigurationFailed = actionCreator(ActionTypes.GET_BADGE_CONFIGURATION_FAILED)

export const updateBadgeConfiguration = actionCreator(ActionTypes.UPDATE_BADGE_CONFIGURATION)
export const updateBadgeConfigurationSucceeded = actionCreator(ActionTypes.UPDATE_BADGE_CONFIGURATION_SUCCEEDED)
export const updateBadgeConfigurationFailed = actionCreator(ActionTypes.UPDATE_BADGE_CONFIGURATION_FAILED)

export const getBadgeTemplateTypes = actionCreator(ActionTypes.GET_BADGE_TEMPLATE_TYPES)
export const getBadgeTemplateTypesSucceeded = actionCreator(ActionTypes.GET_BADGE_TEMPLATE_TYPES_SUCCEEDED)
export const getBadgeTemplateTypesFailed = actionCreator(ActionTypes.GET_BADGE_TEMPLATE_TYPES_FAILED)