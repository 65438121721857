import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../locales/components/namespaces";
import {CatalogueLanguages} from "../../../../helpers/constants";

const useAddVisitorForm = ({
                               exhibition,
                               limitedView,
                               exhibitionId,
                               exhibitorId,
                               createVisitor,
                               isShortOrganizerAddVisitor = false,
                               clearActionOnVisitorStatus = () => {
                               },
                               visitorApproved = false
                           }) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const validationSchema = yup.object({
        name: exhibition && exhibition?.catalogueLanguage === CatalogueLanguages.EN ? yup.string().required(t('required')).matches(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi, t('Only latin and special characters allowed.')) : yup.string().required(t('required')),
        companyName: exhibition && exhibition?.catalogueLanguage === CatalogueLanguages.EN ? yup.string().matches(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi, t('Only latin and special characters allowed.')) : yup.string().notRequired(),
        email: yup.string().email(t('Enter a valid email')).test(
            'required',
            t('required'),
            function (value) {
                return isShortOrganizerAddVisitor ? true : value
            }
        ),
        mobilePhone: yup.number().positive(t('Enter a valid phone')).integer(t('Enter a valid phone')).test(
            'Enter a valid phone',
            t('Enter a valid phone'),
            function (value) {
                return value ? String(value).length >= 8 : true
            }
        ),
        characterization: yup.mixed().test(
            'required', t('required'), function (value) {
                return !limitedView && exhibition && exhibition.visitorCategoryRequiredInImport === true ? value : true
            }
        ),
        countries: yup.mixed().test(
            'required', t('required'), function (value) {
                if (isShortOrganizerAddVisitor === true) {
                    return value && value?.id
                } else {
                    return true
                }
            }
        )
    })

    const onSubmit = (values, {resetForm}) => {
        createVisitor(exhibitionId, exhibitorId, values, false, false, false)
        resetForm()
        clearActionOnVisitorStatus()
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            companyName: '',
            email: '',
            mobilePhone: '',
            characterization: '',
            countries: '',
            visitorApproved: visitorApproved,
            vatNumber: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return {
        formik
    }
}

export default useAddVisitorForm