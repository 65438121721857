import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import {showNoValueIfDisabled} from "./catalog";
import {FormControl, FormHelperText} from "@mui/material";
import {ErrorMessage} from "formik";
import SelectCountry from "../../../components/form/selectCountries/selectCountries";
import {CatalogueLanguages} from "../../../helpers/constants";

const AddressLine = ({
						 formik,
						 language,
						 index,
						 inputVariant,
						 disabled,
						 countriesEl,
						 countriesEn,
						 phoneCodesEl,
						 phoneCodesEn,
						 catalogueLanguage,
						 t
					 }) => {
	const updatePhoneValue = (key, lang, address, prevCode, newCode, setFieldValue) => {
		const currentPhoneValue = address[key][lang];
		if (!newCode || String(currentPhoneValue).startsWith(String(newCode))) {
			return;
		}
		const newPhoneValue = prevCode && String(currentPhoneValue).startsWith(String(prevCode)) ? String(currentPhoneValue).replace(String(prevCode), String(newCode)) : String(newCode) + String(currentPhoneValue);
		setFieldValue(`address${index}.${key}.${lang}`, newPhoneValue);
	}
	
	return (
		<Grid container spacing={1.5} sx={{mb: 3, mt: 1}}>
			<Grid item xs={12} md={6}>
				<FormControl
					variant="standard"
					color={'error'}
					fullWidth
					error={
						formik.touched[`address${index}.street.${language}`] &&
						Boolean(formik.errors[`address${index}.street.${language}`])
					}
				>
					<TextField
						InputLabelProps={{shrink: true}}
						disabled={disabled}
						fullWidth
						variant={inputVariant}
						size={"small"}
						label={(t('catalogue_address')) + " " + index}
						name={`address${index}.street.${language}`}
						value={showNoValueIfDisabled(formik.values[`address${index}`].street[language], disabled)}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						inputProps={{maxLength: 250}}
					/>
					<FormHelperText sx={{color: '#d32f2f'}}>
						<ErrorMessage name={`address${index}.street.${language}`}/>
					</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6}>
				<FormControl
					variant="standard"
					color={'error'}
					fullWidth
					error={
						formik.touched[`address${index}.city.${language}`] &&
						Boolean(formik.errors[`address${index}.city.${language}`])
					}
				>
					<TextField
						InputLabelProps={{shrink: true}}
						disabled={disabled}
						fullWidth
						variant={inputVariant}
						size={"small"}
						label={t('catalogue_city')}
						name={`address${index}.city.${language}`}
						value={showNoValueIfDisabled(formik.values[`address${index}`].city[language], disabled)}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						inputProps={{maxLength: 50}}
					/>
					<FormHelperText sx={{color: '#d32f2f'}}>
						<ErrorMessage name={`address${index}.city.${language}`}/>
					</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item xs={6}>
				<FormControl
					variant="standard"
					color={'error'}
					fullWidth
					error={
						formik.touched[`address${index}.country.${language}`] &&
						Boolean(formik.errors[`address${index}.country.${language}`])
					}
				>
					<SelectCountry
						formik={formik}
						disabled={disabled}
						label={t('catalogue_country')}
						onChange={(event, newValue, reason) => {
							if (reason === 'clear') {
								formik.setFieldValue(`address${index}.country.el`, null)
								formik.setFieldValue(`address${index}.country.en`, null)
								return
							}
							const countryId = newValue.id
							formik.setFieldValue(`address${index}.country.en`, countriesEn.find(f => f.id === countryId));
							formik.setFieldValue(`address${index}.country.el`, countriesEl.find(f => f.id === countryId));
						}}
						value={showNoValueIfDisabled(formik.values[`address${index}`].country[`${language}`], disabled)}
						onBlur={formik.handleBlur}
						countries={language === 'el' ? countriesEl : countriesEn}
						required={index === 1}
						name={`address${index}.country.${language}`}
						variant={inputVariant}
					/>
					<FormHelperText sx={{color: '#d32f2f'}}>
						<ErrorMessage name={`address${index}.country.${language}`}/>
					</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6}>
				<FormControl
					variant="standard"
					color={'error'}
					fullWidth
					error={
						formik.touched[`address${index}.postalCode.${language}`] &&
						Boolean(formik.errors[`address${index}.postalCode.${language}`])
					}
				>
					<TextField
						InputLabelProps={{shrink: true}}
						disabled={disabled}
						fullWidth
						size={"small"}
						variant={inputVariant}
						label={t('catalogue_postal')}
						name={`address${index}.postalCode.${language}`}
						value={showNoValueIfDisabled(formik.values[`address${index}`].postalCode[language], disabled)}
						onChange={(evt) => {
							if (catalogueLanguage === CatalogueLanguages.ALL) {
								formik.setFieldValue(`address${index}.postalCode.en`, evt.target.value)
								formik.setFieldValue(`address${index}.postalCode.el`, evt.target.value)
							} else {
								formik.setFieldValue(`address${index}.postalCode.${language}`, evt.target.value)
							}
						}}
						onBlur={formik.handleBlur}
						inputProps={{maxLength: 50}}
					/>
					<FormHelperText sx={{color: '#d32f2f'}}>
						<ErrorMessage name={`address${index}.postalCode.${language}`}/>
					</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item xs={6}>
				<FormControl
					variant="standard"
					color={'error'}
					fullWidth
					error={
						formik.touched[`address${index}.phoneCode.${language}`] &&
						Boolean(formik.errors[`address${index}.phoneCode.${language}`])
					}
				>
					<SelectCountry
						formik={formik}
						disabled={disabled}
						label={t('catalogue_phone_code')}
						onChange={(event, newValue, reason) => {
							const {values, setFieldValue} = formik;
							const newPhoneCode = newValue?.phoneCode
							const previousPhoneCode = formik.values[`address${index}`]?.phoneCode[`${language}`]?.phoneCode
							if (reason === 'clear') {
								setFieldValue(`address${index}.phoneCode.el`, null)
								setFieldValue(`address${index}.phoneCode.en`, null)
								return
							}
							const newPhoneCodeId = newValue.id
							if (newPhoneCode !== '30') {
								if (catalogueLanguage === CatalogueLanguages.EN) {
									updatePhoneValue('phone', language, values[`address${index}`], previousPhoneCode, newPhoneCode, setFieldValue);
								} else if (catalogueLanguage === CatalogueLanguages.EL) {
									updatePhoneValue('phone', 'el', values[`address${index}`], previousPhoneCode, newPhoneCode, setFieldValue);
								} else {
									updatePhoneValue('phone', 'en', values[`address${index}`], previousPhoneCode, newPhoneCode, setFieldValue);
									updatePhoneValue('phone', 'el', values[`address${index}`], previousPhoneCode, newPhoneCode, setFieldValue);
								}
							}
							setFieldValue(`address${index}.phoneCode.el`, phoneCodesEl.find(f => f.id === newPhoneCodeId));
							setFieldValue(`address${index}.phoneCode.en`, phoneCodesEn.find(f => f.id === newPhoneCodeId));
						}}
						value={showNoValueIfDisabled(formik.values[`address${index}`].phoneCode[`${language}`], disabled)}
						onBlur={formik.handleBlur}
						countries={language === 'el' ? phoneCodesEl : phoneCodesEn}
						required={false}
						name={`address${index}.phoneCode.${language}`}
						variant={inputVariant}
					/>
					<FormHelperText sx={{color: '#d32f2f'}}>
						<ErrorMessage name={`address${index}.phoneCode.${language}`}/>
					</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item xs={6}>
				<FormControl
					variant="standard"
					color={'error'}
					fullWidth
					error={
						formik.touched[`address${index}.phone.${language}`] &&
						Boolean(formik.errors[`address${index}.phone.${language}`])
					}
				>
					<TextField
						InputLabelProps={{shrink: true}}
						disabled={disabled}
						fullWidth
						variant={inputVariant}
						size={"small"}
						label={t('catalogue_phone')}
						name={`address${index}.phone.${language}`}
						value={showNoValueIfDisabled(formik.values[`address${index}`].phone[language], disabled)}
						onChange={(evt) => {
							if (catalogueLanguage === CatalogueLanguages.ALL) {
								formik.setFieldValue(`address${index}.phone.el`, evt.target.value)
								formik.setFieldValue(`address${index}.phone.en`, evt.target.value)
							} else {
								formik.setFieldValue(`address${index}.phone.${language}`, evt.target.value)
							}
						}}
						onBlur={formik.handleBlur}
						inputProps={{maxLength: 250}}
						required={index === 1}
					/>
					<FormHelperText sx={{color: '#d32f2f'}}>
						<ErrorMessage name={`address${index}.phone.${language}`}/>
					</FormHelperText>
				</FormControl>
			</Grid>
		</Grid>
	)
}

export default AddressLine
