import history from './store/history'
import {ConnectedRouter} from 'connected-react-router'
import {loadModules} from "./store/loadModule";
import Main from "./pages/main/main";
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import {PermissionsModule} from "./modules/permission";
import {lightBlue, lime, purple, teal} from '@mui/material/colors';
import SnackbarProviderExtended from "./components/messages/snackbarProviderExtended/snackbarProviderExtended";

export const ENABLED_MODULES = loadModules([PermissionsModule])

export const ROUTES = ENABLED_MODULES.loadRoutes()

const theme = createTheme({
	typography: {
		fontSize: 13,
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
	},
	palette: {
		background: {
			// default: "#F5F5F4"
		},
		primary: {
			main: '#8c9eff',
			// dark: '#5870cb',
			// light: '#c0cfff',
		},
		secondary: {
			main: '#F9FBF2',
		},
		neutral: {
			main: '#64748B',
			contrastText: '#fff'
		},
		step1: {
			contrastText: '#1A2027',
			main: lime["200"],
		},
		step2: {
			contrastText: '#1A2027',
			main: teal["200"],
		},
		step3: {
			contrastText: '#1A2027',
			main: lightBlue["200"],
		},
		step4: {
			contrastText: '#1A2027',
			main: purple["200"],
		},
		step5: {
			contrastText: '#1A2027',
			main: teal.A400,
		},
		step6: {
			contrastText: '#fff',
			main: lightBlue.A700,
		},
	}
})

function App() {
	return (
		<ThemeProvider theme={theme}>
			<SnackbarProviderExtended>
				<ConnectedRouter history={history}>
					<CssBaseline/>
					<Main/>
				</ConnectedRouter>
			</SnackbarProviderExtended>
		</ThemeProvider>
	)
}

export default App
