// @flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple, parseMultipleFromReference} from "../../helpers/parse";
import {
    parseBulkImportStatus,
    parseBusinessCategory,
    parseExhibition,
    parseExhibitor,
    parseMessageStatus,
    parseVisitor,
    parseVisitorReference,
    sortExhibitions
} from "../../types/exhibition/parse";
import type {BusinessCategory, Exhibition, Exhibitor, Visitor, VisitorReference} from "../../types/exhibition/types";

const selectVisitorBulkImportStatus = createSelector(
    (state) => state.exhibition.get('bulkImportVisitorStatus'),
    (item) => parseBulkImportStatus(item)
)

const selectPartnerBulkImportStatus = createSelector(
    (state) => state.exhibition.get('bulkImportPartnerStatus'),
    (item) => parseBulkImportStatus(item)
)

const selectActionOnVisitorStatus = createSelector(
    (state) => state.exhibition.get('actionOnVisitorStatus'),
    (item) => parseMessageStatus(item)
)

const selectActionOnExhibitorStatus = createSelector(
    (state) => state.exhibition.get('actionOnExhibitorStatus'),
    (item) => parseMessageStatus(item)
)

const selectActionOnPartnerStatus = createSelector(
    (state) => state.exhibition.get('actionOnPartnerStatus'),
    (item) => parseMessageStatus(item)
)

const selectUpdatingStatus = createSelector(
    (state) => state.exhibition.get('updatingStatus'),
    (item) => item ? {
        type: item.get('type'),
        message: item.get('message'),
    } : null
)

const selectBulkImporting = createSelector(
    (state) => state.exhibition,
    (item) => item.get('bulkImporting')
)

const selectLoading = createSelector(
    (state) => state.exhibition,
    (item) => item.get('loading')
)

const selectIndexTable = createSelector(
    [
        (state) => state.exhibition.get('indexTable'),
        (state) => state.exhibition.get('byId'),
        (state) => state.currentUser.get('language'),
    ],
    (indexTable, byId, language) => ({
        loading: indexTable.get('loading'),
        count: indexTable.get('count'),
        items: sortExhibitions(
            parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseExhibition(i, language))
        ),
    })
)

const selectById = createSelector(
    [
        (state) => state.exhibition.get('byId'),
        (state) => state.currentUser.get('language'),
        (state, id) => id,
        (state, id, language) => language
    ],
    (byId, userLanguage, id, overrideLanguage) => parseExhibition(byId.get(id), overrideLanguage ?? userLanguage)
)

const selectBusinessCategoriesByExhibitionId = createSelector(
    [
        (state, id) => state.exhibition.getIn(['businessCategoriesByExhibitionId', id]),
        (state) => state.currentUser.get('language'),
        (state, id, language) => language
    ],
    (data, userLanguage, overrideLanguage) => parseMultiple(data, (i) => parseBusinessCategory(i, overrideLanguage ?? userLanguage))
)

const selectExhibitorById = createSelector(
    [
        (state) => state.exhibition.get('exhibitorById'),
        (state) => state.currentUser.get('language'),
        (state, id, exhibitionId) => {
            if (!exhibitionId) return id
            return state
                .exhibition
                .getIn(['exhibitorByExhibitionId', exhibitionId, id])
        },
        (state, id, exhibitionId, language) => language
    ],
    (byId, userLanguage, id, overrideLanguage) => parseExhibitor(byId.get(id), overrideLanguage ?? userLanguage)
)

const selectVisitorById = createSelector(
    [
        (state) => state.exhibition.get('visitorById'),
        (state) => state.currentUser.get('language'),
        (state, id) => id
    ],
    (byId, language, id) => parseVisitor(byId.get(id), language)
)

const selectExhibitorsByVisitorId = createSelector(
    [
        (state) => state.exhibition.get('exhibitorsByVisitorId'),
        (state) => state.currentUser.get('language'),
        (state, id) => id,
    ],
    (byId, language, id) => parseMultiple(byId.get(id), parseVisitorReference)
)

const selectExhibitionsList = createSelector(
    [
        (state) => state.exhibition.get('exhibitionsList'),
        (state) => state.currentUser.get('language')
    ],
    (data, language) => parseMultiple(data, i => parseExhibition(i, language))
)

const useExhibitionData = ({id, overrideLanguage, exhibitorId, visitorId} = {}) => {
    const actionOnVisitorStatus = useSelector(selectActionOnVisitorStatus)
    const actionOnExhibitorStatus = useSelector(selectActionOnExhibitorStatus)
    const actionOnPartnerStatus = useSelector(selectActionOnPartnerStatus)
    const visitorBulkImportStatus = useSelector(selectVisitorBulkImportStatus)
    const partnerBulkImportStatus = useSelector(selectPartnerBulkImportStatus)
    const loading = useSelector(selectLoading)
    const bulkImporting = useSelector(selectBulkImporting)
    const updatingStatus = useSelector(selectUpdatingStatus)
    const indexTable = useSelector(selectIndexTable)
    const exhibitionsList = useSelector(selectExhibitionsList)
    const byId: Exhibition = useSelector((state) => selectById(state, id, overrideLanguage))
    const businessCategoriesByExhibitionId: Array<BusinessCategory> = useSelector((state) => selectBusinessCategoriesByExhibitionId(state, id, overrideLanguage))
    const exhibitorById: Exhibitor = useSelector((state) => selectExhibitorById(state, exhibitorId, id, overrideLanguage))
    const visitorById: Visitor = useSelector((state) => selectVisitorById(state, visitorId))
    const exhibitorsByVisitorId: Array<VisitorReference> = useSelector((state) => selectExhibitorsByVisitorId(state, visitorId))

    return {
        exhibitionsList,
        businessCategoriesByExhibitionId,
        exhibitorsByVisitorId,
        actionOnVisitorStatus,
        actionOnExhibitorStatus,
        actionOnPartnerStatus,
        visitorBulkImportStatus,
        partnerBulkImportStatus,
        indexTable,
        byId,
        exhibitorById,
        visitorById,
        loading,
        bulkImporting,
        updatingStatus
    }
}

export default useExhibitionData