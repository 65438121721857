//@flow
import React from 'react'

type Props = {
    ischecked?: boolean,
    isindeterminate?: boolean,
    onClick?: Function
}

const CheckBox = ({ischecked = false, isindeterminate = false, onClick}: Props) => {
    return (
        <span
            className={`tree-checkbox ${isindeterminate && 'tree-checkbox_isIndeterminate'} ${ischecked && 'tree-checkbox_isChecked'}`}
            onClick={onClick}
        />
    )
}

export default CheckBox
