//@flow
import React from 'react';
import {Paper} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import type {Badge} from "../../../types/badge/types";
import PrintBadgeButton from "../printBadgeButton/printBadgeButton";
import Barcode from "react-barcode";

type Props = {
    badge: Badge
}

const BadgeDetails = (props: Props) => {
    const {badge, showToolbar} = props
    return (
        <Paper sx={{p: 1}} elevation={0}>
            {showToolbar && (
                <Toolbar disableGutters variant={"dense"} sx={{mb: 2}}>
                    <Box sx={{flexGrow: 1}}/>
                    <PrintBadgeButton
                        userId={badge.user.id}
                        type={badge.type}
                        autoReloadFile={true}
                        printSize={props.printSize}
                    />
                </Toolbar>
            )}
            <Box sx={{'& svg': {width: "100%"}}}>
                <Barcode
                    value={badge.id}
                />
            </Box>
        </Paper>
    )
}

export default BadgeDetails
