//@flow
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import { ListItem, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import Images from "../../../../../assets";
import ExhibitionUpdateStatus from "../../../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import FileUpload from "../../../../../components/form/fileUpload/fileUpload";
import { AuthenticatedLink } from "../../../../../components/general/authenticatedLink/authenticatedLink";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import CustomTranslation from "../../../../../components/general/customTranslation/customTranslation";
import { API, FileTypes } from "../../../../../helpers/constants";
import { handleAxiosErrors } from "../../../../../helpers/errorHandling";
import { normalizeGreek } from "../../../../../helpers/normalizeGreek";
import { useExhibitionFileUpload } from "../../../../../hooks/exhibitionFIles/useExhibitionFileUpload";
import { useExhibition } from "../../../../../hooks/exhibitions/useExhibition";
import useUserData from "../../../../../hooks/user/useUserData";
import { EXHIBITION } from "../../../../../locales/components/namespaces";

const ExhibitorKit = (props) => {
	const {t} = useTranslation(EXHIBITION)
	const {exhibitor} = props
	const { token, language } = useUserData()
	const {exhibition, files, id} = useExhibition({language: 'any'})
	const [loading, setLoading] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [image, setImage] = useState("");
	const {
		getFiles,
	} = useExhibitionFileUpload({
		exhibitionId: id,
		typeId: FileTypes.ExhibitorKit
	})
	
	const getAdLogo = () => {
		setLoading(true)
		axios({
			url: `${API}/en/exhibitor/get-logo/${id}?ExhibitorId=${exhibitor?.id}`,
			method: 'get',
			responseType: "arraybuffer",
			headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`}
		}).then((response) => {
			const base64 = btoa(
				new Uint8Array(response.data).reduce(
					(data, byte) => data + String.fromCharCode(byte),
					""
				)
			)
			setImage(base64);
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
		})
	}
	
	useEffect(() => {
		getAdLogo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	const uploadFile = (file) => {
		axios({
			url: `${API}/en/exhibitor/upload-logo/${id}`,
			method: 'post',
			headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`},
			data: {
				file: file,
				ExhibitorId: exhibitor?.id
			}
		}).then((response) => {
			getAdLogo()
			enqueueSnackbar(t('Advertisement logo uploaded successfully!'), {
				variant: 'success',
				autoHideDuration: 1000,
			})
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to upload advertisement logo!'), {
				variant: 'error',
				autoHideDuration: 0,
			}))
		})
	}
	
	const deleteAdLogo = (file) => {
		setLoadingDelete(true)
		axios({
			url: `${API}/en/exhibitor/delete-logo/${id}`,
			method: 'post',
			headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`},
			data: {
				ExhibitorId: exhibitor?.id
			}
		}).then((response) => {
			setLoadingDelete(false)
			setImage('')
			enqueueSnackbar(t('Advertisement logo deleted successfully!'), {
				variant: 'success',
				autoHideDuration: 1000,
			})
		}).catch((error) => {
			setLoadingDelete(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete advertisement logo!'), {
				variant: 'error',
				autoHideDuration: 0,
			}))
		})
	}
	
	const kitFile = useMemo(() => {
		const kit = getFiles(files.filter(f => f.type === FileTypes.ExhibitorKit), '')
		return kit && kit.length > 0 ? kit[0] : null
	}, [files, getFiles])
	
	return (
		<div>
			{exhibition ? (
				<React.Fragment>
					<Paper sx={{mb: 1}} variant={"outlined"}>
						<List>
							<ListItem component={"div"}>
								<ListItemText
									primary={
										<CustomTranslation
											code={'marketing_kit'}
											translations={exhibition?.translations}
										>
											<Typography component={"div"} variant={"body1"}>
												<Trans
													i18nKey={'exhibitor_kit_and_ad_logos'}
													ns={EXHIBITION}
												>
													Here you can upload your company's logo
													in <strong>JPG</strong> or <strong>PNG</strong> format at the
													highest
													possible resolution, for use in advertisements and in the <strong>exhibitor
													list</strong>.<br/>
													Additionally you can download the <strong>Marketing Kit</strong>,
													useful <strong>promotional material</strong> to better promote your
													participation in the exhibition.
												</Trans>
											</Typography>
										</CustomTranslation>
									}
								/>
							</ListItem>
						</List>
					</Paper>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8} lg={9}>
							<Paper sx={{px: 2, pb: 2}} variant={"outlined"}>
								<Toolbar disableGutters variant={"dense"}>
									<Typography variant="h6">
										{t('Advertisement Logo')}
									</Typography>
									<LoadingButton
										loading={loadingDelete}
										color={'error'}
										loadingPosition="start"
										variant={"outlined"}
										size={"small"}
										startIcon={<DeleteIcon/>}
										onClick={deleteAdLogo}
										sx={{
											marginLeft: 'auto'
										}}
									>
										{normalizeGreek(t('Delete Logo'))}
									</LoadingButton>
								</Toolbar>
								<Divider sx={{mb: 2}} light/>
								{!loading && image && (
									<img src={`data:image/jpeg;base64,${image}`} alt="ad logo"
										 style={{maxWidth: '100%', maxHeight: '600px'}}/>
								)}
								<FileUpload
									accept={{
										"image/*": [".jpeg", ".png"]
									}}
									multiple={false}
									onFilesUpload={(files) => {
										if (files && files.length > 0) uploadFile(files[0])
									}}
								/>
							</Paper>
						</Grid>
						<Grid item xs={12} md={4} lg={3}>
							<Paper sx={{
								px: 2,
								pb: 2,
								height: '100%'
							}} variant={"outlined"}>
								<Box>
									<Toolbar disableGutters variant={"dense"}>
										<Typography variant="h6">
											{t('Marketing Kit')}
										</Typography>
									</Toolbar>
									<Divider sx={{mb: 2}} light/>
								</Box>
								{kitFile ? (
									<AuthenticatedLink
										filename={kitFile.name}
										url={kitFile.url}
									>
										<Button
											variant={"text"}
										>
											<img
												src={language === 'el' ? Images.kitEl : Images.kitEn}
												alt={'Kit Download'}
												style={{ width: '100%', maxWidth: '380px' }}
											/>
										</Button>
									</AuthenticatedLink>
								) : (
									<Typography variant="body1">
										{t('Marketing Kit has not been uploaded yet from exhibition organizer.')}
									</Typography>
								)}
							</Paper>
						</Grid>
					</Grid>
				</React.Fragment>
			) : (
				<ContentLoader
					loading={loading}
				/>
			)}
			<ExhibitionUpdateStatus/>
		</div>
	)
}

export default ExhibitorKit;
