//@flow
import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import TextField from "@mui/material/TextField";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import {LinkedIn, Mail} from "@mui/icons-material";
import {showNoValueIfDisabled} from "./catalog";
import {FormControl, FormHelperText} from "@mui/material";
import {ErrorMessage} from "formik";
import Typography from "@mui/material/Typography";

const CatalogSocial = ({formik, inputVariant, disabled}) => {
    return (
        <Grid container spacing={1.5}>
            <Grid item xs={12}>
                <Typography
                    variant="subtitle2"
                    component={"div"}
                    sx={{
                        fontWeight: "bold",
                        mb: 3,
                        textAlign: "center",
                        bgcolor: 'grey.400',
                        color: 'grey.400.contrastText'
                    }}
                >
                    Socials
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Mail sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                    <FormControl
                        variant="standard"
                        color={'error'}
                        fullWidth
                        error={
                            formik.touched['social.email'] &&
                            Boolean(formik.errors['social.email'])
                        }
                    >
                        <TextField
                            InputLabelProps={{shrink: true}}
                            disabled={disabled}
                            variant={inputVariant}
                            label="E-mail"
                            required
                            type={'email'}
                            size={"small"}
                            name={'social.email'}
                            value={showNoValueIfDisabled(formik.values.social.email, disabled)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            inputProps={{maxLength: 250}}
                        />
                        <FormHelperText sx={{color: '#d32f2f'}}>
                            <ErrorMessage name={'social.email'}/>
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <LinkedIn sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                    <FormControl
                        variant="standard"
                        color={'error'}
                        fullWidth
                        error={
                            formik.touched['social.linkedIn'] &&
                            Boolean(formik.errors['social.linkedIn'])
                        }
                    >
                        <TextField
                            InputLabelProps={{shrink: true}}
                            disabled={disabled}
                            fullWidth
                            size={"small"}
                            variant={inputVariant}
                            label="LinkedIn"
                            name={'social.linkedIn'}
                            value={showNoValueIfDisabled(formik.values.social.linkedIn, disabled)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            inputProps={{maxLength: 250}}
                        />
                        <FormHelperText sx={{color: '#d32f2f'}}>
                            <ErrorMessage name={'social.linkedIn'}/>
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <FacebookIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                    <FormControl
                        variant="standard"
                        color={'error'}
                        fullWidth
                        error={
                            formik.touched['social.fb'] &&
                            Boolean(formik.errors['social.fb'])
                        }
                    >
                        <TextField
                            InputLabelProps={{shrink: true}}
                            disabled={disabled}
                            fullWidth
                            variant={inputVariant}
                            label="Facebook"
                            size={"small"}
                            name={'social.fb'}
                            value={showNoValueIfDisabled(formik.values.social.fb, disabled)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            inputProps={{maxLength: 250}}
                        />
                        <FormHelperText sx={{color: '#d32f2f'}}>
                            <ErrorMessage name={'social.fb'}/>
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <InstagramIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                    <FormControl
                        variant="standard"
                        color={'error'}
                        fullWidth
                        error={
                            formik.touched['social.instagram'] &&
                            Boolean(formik.errors['social.instagram'])
                        }
                    >
                        <TextField
                            InputLabelProps={{shrink: true}}
                            disabled={disabled}
                            fullWidth
                            variant={inputVariant}
                            label="Instagram"
                            size={"small"}
                            name={'social.instagram'}
                            value={showNoValueIfDisabled(formik.values.social.instagram, disabled)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            inputProps={{maxLength: 250}}
                        />
                        <FormHelperText sx={{color: '#d32f2f'}}>
                            <ErrorMessage name={'social.instagram'}/>
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <LanguageIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                    <FormControl
                        variant="standard"
                        color={'error'}
                        fullWidth
                        error={
                            formik.touched['social.website'] &&
                            Boolean(formik.errors['social.website'])
                        }
                    >
                        <TextField
                            InputLabelProps={{shrink: true}}
                            disabled={disabled}
                            fullWidth
                            size={"small"}
                            variant={inputVariant}
                            label="Website"
                            name={'social.website'}
                            value={showNoValueIfDisabled(formik.values.social.website, disabled)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            inputProps={{maxLength: 250}}
                        />
                        <FormHelperText sx={{color: '#d32f2f'}}>
                            <ErrorMessage name={'social.website'}/>
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CatalogSocial
