// @flow
import React, {useEffect, useMemo, useState} from 'react';
import Box from "@mui/material/Box";
import useExhibitionData from "../../../../hooks/exhibitions/useExhibitionData";
import * as yup from "yup";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION, GENERAL_SETTINGS} from "../../../../locales/components/namespaces";
import {Checkbox, FormControl, FormControlLabel, FormHelperText, ListItem, Paper, TextField} from "@mui/material";
import {API, Permissions} from "../../../../helpers/constants";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import useExhibitionAction from "../../../../hooks/exhibitions/useExhibitionAction";
import {formatDate} from "../../../../helpers/date";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import _ from "lodash";
import {showOption} from "../../../../helpers/helpers";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import useUserData from "../../../../hooks/user/useUserData";
import axios from "axios";
import {downloadFile} from "../../../../helpers/fileHandle";
import {ImportExportOutlined} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../../helpers/errorHandling";
import Divider from "@mui/material/Divider";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

type Props = {};

const MultipleExports = (props: Props) => {
	const {language, token} = useUserData()
	const {getExhibitions} = useExhibitionAction()
	const {t} = useTranslation(GENERAL_SETTINGS)
	const {indexTable: exhibitions} = useExhibitionData()
	const [loading, setLoading] = useState(false)
	
	useEffect(() => {
		getExhibitions()
	}, [getExhibitions])
	
	const validationSchema = yup.object({
		exhibitionIds: yup
		.array()
		.min(1, t("select at least one exhibition"))
		.required(t("select at least one exhibition"))
	})
	
	const onSubmit = (values) => {
		setLoading(true)
		const exhibitionIds = values.exhibitionIds.map(e => e.id)?.toString()
		axios({
			url: `${API}/en/exhibition/exportcatalogword/${exhibitionIds}?order=${values.orderDataBy}&lang=${values.exportWordLanguage}`,
			method: 'get',
			responseType: "blob",
			headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`}
		}).then((response) => {
			const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
			const href = window.URL.createObjectURL(blob)
			downloadFile(href, 'word-export')
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to export!'), {
				variant: 'warning',
				autoHideDuration: null,
			}))
		})
	}
	
	const formik = useFormik({
		initialValues: {
			exportWordLanguage: 'el',
			orderDataBy: 'exhibitor',
			exhibitionIds: []
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	const mappedExhibitions = useMemo(() => (
		exhibitions?.items.map(exhibition => ({
			id: exhibition.id,
			coorganizationId: exhibition.coorganizationId,
			description: `${exhibition.title} ${formatDate(exhibition.dateFrom, formatDate.formatTypes.DATE_ONLY)} - ${formatDate(exhibition.dateTo, formatDate.formatTypes.DATE_ONLY)}`,
			dateFrom: exhibition.dateFrom
		}))
	), [exhibitions?.items])
	
	return (
		<React.Fragment>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('Multiple Exhibition Export')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<List>
					<ListItem component={"div"}>
						<ListItemText
							primary={
								<Typography component={"div"} variant={"body1"}>
									<Trans
										i18nKey={'multiple_catalogue_exports'}
										ns={EXHIBITION}
									>
										On this page you can export multiple or single exhibition catalogues
										in <strong>word</strong>.
									</Trans>
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Paper>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<FormikProvider value={formik}>
					{mappedExhibitions && mappedExhibitions.length > 0 ? (
						<Box component="form" onSubmit={formik.handleSubmit}>
							<Stack
								direction={'column'}
								minHeight={'240px'}
								maxWidth={'500px'}
								justifyContent={'space-around'}
								p={2}
								width={'100%'}
							>
								<Typography
									component={'div'}
									variant="body1"
									color={'textSecondary'}
								>
									{t('Select export filters and press EXPORT.')}
								</Typography>
								<Divider/>
								<Typography
									mt={1}
									mb={1}
									variant="body"
									color={'textSecondary'}
								>
									{t('Sort results by')}
								</Typography>
								<FormControl>
									<RadioGroup
										aria-labelledby="export-word-buttons-group"
										name="exportWord"
										value={formik.values.orderDataBy}
										onChange={formik.handleChange}
									>
										<FormControlLabel value="exhibitor" control={<Radio/>} label={t("Exhibitor")}/>
										<FormControlLabel value="country" control={<Radio/>} label={t("Country")}/>
										<FormControlLabel value="kiosk" control={<Radio/>} label={t("Kiosk")}/>
									</RadioGroup>
								</FormControl>
								<Typography
									variant="body"
									color={'textSecondary'}
									mt={1}
									mb={1}
								>
									{t('Select export language.')}
								</Typography>
								<FormControl>
									<RadioGroup
										aria-labelledby="export-word-language"
										name="exportWordLanguage"
										value={formik.values.exportWordLanguage}
										onChange={formik.handleChange}
										sx={{flexDirection: 'row'}}
									>
										<FormControlLabel value="el" control={<Radio/>} label={t("Greek")}/>
										<FormControlLabel value="en" control={<Radio/>} label={t("English")}/>
									</RadioGroup>
								</FormControl>
								<Typography
									mt={1}
									mb={1}
									variant="body"
									color={'textSecondary'}
								>
									{t('Select one or more exhibitions')}
								</Typography>
								<FormControl
									sx={{mb: 1}}
									variant="standard"
									fullWidth
									error={
										formik.touched["exhibitionIds"] &&
										Boolean(formik.errors["exhibitionIds"])
									}
								>
									<Autocomplete
										multiple
										options={_.orderBy(mappedExhibitions, 'dateFrom', 'desc')}
										value={formik.values.exhibitionIds}
										isOptionEqualToValue={(opt, val) => {
											return opt.id === val.id || !val
										}}
										getOptionLabel={showOption(language)}
										selectOnFocus
										clearOnBlur
										disableCloseOnSelect
										onChange={(evt, newValue, reason, details) => {
											formik.setFieldValue('exhibitionIds', newValue)
										}}
										autoHighlight={true}
										size="small"
										fullWidth
										renderOption={(props, option, {selected}) => (
											<li {...props}>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
													checkedIcon={<CheckBoxIcon fontSize="small"/>}
													style={{marginRight: 8}}
													checked={selected}
												/>
												{showOption(language)(option)}
											</li>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												inputProps={{
													...params.inputProps,
													autoComplete: 'none',
												}}
												label={t("Select Exhibitions")}
											/>
										)}
									/>
									<FormHelperText>
										<ErrorMessage name={"exhibitionIds"}/>
									</FormHelperText>
								</FormControl>
								<LoadingButton
									size={"small"}
									startIcon={<ImportExportOutlined/>}
									variant="contained"
									color="success"
									type={'submit'}
									loading={loading}
									loadingPosition="start"
								>
									{normalizeGreek(t('Export'))}
								</LoadingButton>
							</Stack>
						</Box>
					) : (
						<ContentLoader
							loading={exhibitions?.loading}
						/>
					)}
				</FormikProvider>
			</Paper>
		</React.Fragment>
	)
};

export default withPermissionHOC(MultipleExports, [Permissions.BeOrganizer])