import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {parseMessageStatus} from "../../types/exhibition/parse";

const selectLoading = createSelector(
    (state) => state.entersoftTransfer.get('loading'),
    (item) => item
)

const selectTransferStarted = createSelector(
    (state) => state.entersoftTransfer.get('transferStarted'),
    (item) => item
)

const selectTransferEnded = createSelector(
    (state) => state.entersoftTransfer.get('transferEnded'),
    (item) => item
)

const selectTransferMessage = createSelector(
    (state) => state.entersoftTransfer.get('message'),
    (item) => parseMessageStatus(item)
)

export const useEntersoftTransferData = () => {
    const loadingTransfer: boolean = useSelector(selectLoading)
    const transferStarted: boolean = useSelector(selectTransferStarted)
    const transferEnded: boolean = useSelector(selectTransferEnded)
    const transferMessage = useSelector(selectTransferMessage)
    return {
        loadingTransfer,
        transferEnded,
        transferStarted,
        transferMessage
    }
}