import {useExhibitionImageAction} from "./useExhibitionImageAction";
import {useEffect} from "react";
import {useExhibitionImageData} from "./useExhibitionImageData";
import {enqueueSnackbar} from "notistack";
import {EXHIBITION} from "../../locales/components/namespaces";
import {useTranslation} from "react-i18next";

export const useExhibitionImages = (id) => {
	const {t} = useTranslation(EXHIBITION)
	const {getExhibitionImages, setFileMessage} = useExhibitionImageAction()
	const {loading, images, message} = useExhibitionImageData(id)
	useEffect(() => {
		getExhibitionImages(id)
	}, [getExhibitionImages, id])
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setFileMessage(null)
		}
	}, [message, setFileMessage, t])
	return {
		id,
		loading,
		images
	}
}