//@flow
import React, {useContext, useEffect} from 'react';
import {TitleContext} from "../../../pages/main/content";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ExhibitionStatus from "../exhibitionStatus/exhibitionStatus";
import {Chip} from "@mui/material";
import {formatDate} from "../../../helpers/date";
import {useMultilingualText} from "../../../hooks/useMultilingualText";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import useUserData from "../../../hooks/user/useUserData";
import {showOption} from "../../../helpers/helpers";

export const PageTitleContent = ({exhibition}) => {
    const {dateFrom, dateTo, coorganizationDescription} = exhibition
    const title = useMultilingualText(exhibition.title)
    const {language} = useUserData()
    return (
        <Typography variant="h6" component="div">
            <Box>
                {normalizeGreek(title?.toUpperCase())}
            </Box>
            <Box sx={{fontSize: '12px'}}>
                {normalizeGreek(showOption(language)(coorganizationDescription).toUpperCase())}
            </Box>
            <Box sx={{
                '& .MuiChip-root': {
                    height: 20,
                    borderRadius: "2px",
                }
            }}>
                <ExhibitionStatus
                    sx={{mr: 1}}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                />
                <Chip sx={{mr: 1}} size={"small"}
                      label={formatDate(dateFrom.locale(language), formatDate.formatTypes.FULL_DESCRIPTION)}/>
                <Chip size={"small"}
                      label={formatDate(dateTo.locale(language), formatDate.formatTypes.FULL_DESCRIPTION)}/>
            </Box>
        </Typography>
    )
}
const ExhibitionPageTitle = ({exhibition}) => {
    const {setTitle} = useContext(TitleContext)
    const {language} = useUserData()
    useEffect(() => {
        setTitle(
            <PageTitleContent
                exhibition={exhibition}
                language={language}
            />
        )
        return () => {
            setTitle(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])
    return null
}

export default ExhibitionPageTitle;