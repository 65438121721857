import {useEffect} from "react";
import {useCountriesAction} from "./useCountriesAction";
import {useCountriesData} from "./useCountriesData";

export const useCountries = () => {
    const {getCountries} = useCountriesAction()
    const {
        loading,
        sortedCountries: countries,
        phoneCodes,
        defaultCountry,
        defaultPhoneCode,
        sortedCountriesEl: countriesEl,
        sortedCountriesEn: countriesEn,
        phoneCodesEl,
        phoneCodesEn
    } = useCountriesData()
    useEffect(() => {
        getCountries()
    }, [getCountries])
    return {
        defaultCountry,
        defaultPhoneCode,
        phoneCodes,
        countries,
        countriesEl,
        countriesEn,
        phoneCodesEl,
        phoneCodesEn,
        loading
    }
}