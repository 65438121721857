//@flow
import React, {useEffect} from 'react';
import {useFAQData} from "../../../../../hooks/faqs/useFAQData";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import NoContentSection from "../../../../../components/general/noContentSection/noContentSection";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import {useFAQAction} from "../../../../../hooks/faqs/useFAQAction";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION} from "../../../../../routers/routes";

type Props = {
    faqId: string,
}

const FaqSection = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {faqId, exhibitionId} = props
    const {getFaqDetails} = useFAQAction()
    const {faq, loadingFaq} = useFAQData({exhibitionId: exhibitionId, faqId: faqId})
    useEffect(() => {
        // if (expanded) {
        getFaqDetails(faqId, exhibitionId)
        // }
    }, [exhibitionId, faqId, getFaqDetails])

    return (
        <React.Fragment>
            {faq && faq.content ? (
                <React.Fragment>
                    <Toolbar disableGutters variant={"dense"}>
                        <IconButton component={Link} to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibitionId})}>
                            <ChevronLeftIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            {faq.title}
                        </Typography>
                    </Toolbar>
                    <Divider sx={{mb: 2}} light/>
                    <Typography
                        sx={{
                            '& p': {
                                fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                            },
                            '& p:nth-of-type(1)': {
                                marginTop: 0
                            },
                            '& img': {
                                maxWidth: "100%",
                                height: "auto"
                            },
                            '& table': {
                                width: "100%!important",
                                marginLeft: "0!important",
                                marginRight: "0!important",
                                // height: "auto"
                            }
                        }}
                        component={"div"}
                        variant={"body1"}
                        dangerouslySetInnerHTML={{__html: faq.content}}
                    />
                </React.Fragment>
            ) : !loadingFaq ? (
                <NoContentSection
                    message={t("Topic body not configured yet.")}
                />
            ) : (
                <ContentLoader
                    loading={loadingFaq}
                />
            )}
        </React.Fragment>
    )
}

export default FaqSection;
