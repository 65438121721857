//@flow
import React from 'react';
import type {Exhibition} from "../../../../../types/exhibition/types";
import Box from "@mui/material/Box";
import FastReports from "./fastReports/fastReports";
import {useReports} from "../../../../../hooks/reports/useReports";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {Trans} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import Paper from "@mui/material/Paper";
import {withPermissionHOC} from "../../../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../../../helpers/constants";

type Props = {
    exhibition: Exhibition
}

const Reports = (props: Props) => {
    const {exhibition} = props
    const {loading, reports} = useReports(exhibition.id)
    return reports ? (
        <Box>
            <Paper sx={{mb: 1}} variant={"outlined"}>
                <List>
                    <ListItem component={"div"}>
                        <ListItemText
                            primary={
                                <Typography component={"div"} variant={"body1"}>
                                    <Trans
                                        i18nKey={'reports_statistics'}
                                        ns={EXHIBITION}
                                    >
                                        On this page you can have an overview of <strong>exhibition statistics</strong>.
                                        <br/>
                                        After the exhibition, you can also <strong>export</strong> exhibition visitors
                                        in excel and use this excel
                                        to <strong>import them in your ERP</strong>.
                                        <br/>
                                        If exhibition is part of a coorganization, excel includes visitors from all
                                        coorganization exhibitions. (Visitors with same data, appear only once in
                                        export.)
                                        <br/><br/>
                                        <strong>Visitors scanned after registration, can also include visitors that
                                            initially submitted their preregistration form!</strong>
                                        <br/><br/>
                                        <strong>Total Visitors</strong> = <strong>Actual Visits From Visitors Submitted
                                        Through ERP</strong> + <strong>Actual Visits From Non ERP Submitted
                                        Visitors</strong>
                                    </Trans>
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </Paper>
            <FastReports
                exhibition={exhibition}
                exhibitionId={exhibition.id}
                reports={reports}
                loading={loading}
            />
        </Box>
    ) : (
        <ContentLoader
            loading={loading}
        />
    )
}

export default withPermissionHOC(Reports, [Permissions.SeeReports])