//@flow
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {PreregistrationRegions, PreregistrationRegionsApproval} from "../../../../../../helpers/constants";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
}

const PreregistrationCountries = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props
    const onChange = (value) => {
        updateExhibition(id, value, Update.PreregistrationCountry)
        let preregistrationRegionsApproval = PreregistrationRegionsApproval.NoApproval
        if (exhibition.preregistrationNeedsApproval) {
            if (value === PreregistrationRegions.AbroadOnly) preregistrationRegionsApproval = PreregistrationRegionsApproval.AbroadOnly
            if (value === PreregistrationRegions.GreeceOnly) preregistrationRegionsApproval = PreregistrationRegionsApproval.GreeceOnly
            if (value === PreregistrationRegions.Everywhere) preregistrationRegionsApproval = PreregistrationRegionsApproval.Everywhere
        }
        updateExhibition(id, preregistrationRegionsApproval, Update.PreregistrationApprove)
    }
    return (
        <ListItem disabled={!exhibition.preregistrationEnabled}>
            <ListItemText
                primary={t("Pregistration Active Countries")}
                secondary={t("Select if preregistration is available everywhere, only in Greece or only outside Greece.")}
            />
            <Select
                labelId={'preregistrationCountryLabel'}
                variant={'standard'}
                disabled={!exhibition.preregistrationEnabled}
                value={exhibition.preregistrationCountry}
                onChange={(evt) => onChange(evt.target.value)}
                size={"small"}
                sx={{minWidth: '220px'}}
            >
                <MenuItem
                    key={PreregistrationRegions.Everywhere}
                    value={PreregistrationRegions.Everywhere}
                >
                    {t('Everywhere')}
                </MenuItem>
                <MenuItem
                    key={PreregistrationRegions.GreeceOnly}
                    value={PreregistrationRegions.GreeceOnly}
                >
                    {t('Greece')}
                </MenuItem>
                <MenuItem
                    key={PreregistrationRegions.AbroadOnly}
                    value={PreregistrationRegions.AbroadOnly}
                >
                    {t('Abroad')}
                </MenuItem>
            </Select>
        </ListItem>
    )
}

export default PreregistrationCountries
