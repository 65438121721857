// @flow
import * as React from 'react';
import {useMemo} from 'react';
import _ from 'lodash'
import Box from "@mui/material/Box";
import RenderFieldArray from "../renderFieldArray/renderFieldArray";
import {listToTree} from "../../../general/dragDropTree/listToTree";

type Props = {
    formik: Object,
    fields: Array,
    values: Object,
    handleBlur: Function,
    handleChange: Function,
    touched: Object,
    errors: Object
};

const Fields = ({
                    formik,
                    fields,
                    values,
                    handleBlur,
                    handleChange,
                    touched,
                    errors,
                }: Props) => {

    const listFields = useMemo(() => {
            const getMembers = (node) => {
                const {data, children} = node
                if (!children || !children.length) {
                    return data;
                }
                return [data, _.flatMapDeep(children, getMembers)];
            }
            const tree = listToTree({items: fields})
            return tree.length > 0 ? _.flatMapDeep(tree, getMembers) : fields
        },
        [fields]
    )

    return (
        <Box
            sx={{
                '& .Mui-disabled': {
                    color: 'text.primary',
                    WebkitTextFillColor: 'initial!important',
                },
                '& .MuiInputLabel-root.Mui-disabled': {
                    color: 'text.primary',
                    WebkitTextFillColor: 'initial!important',
                },
                textAlign: 'left'
            }}>
            <RenderFieldArray
                formik={formik}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                fields={listFields}
            />
        </Box>
    );
};

export default Fields
