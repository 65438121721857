//@flow
import React, {useMemo} from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import ExhibitionUpdateStatus from "../../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import useUserData from "../../../../hooks/user/useUserData";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useExhibitionFileUpload} from "../../../../hooks/exhibitionFIles/useExhibitionFileUpload";
import {API, FileTypes} from "../../../../helpers/constants";
import {ListItem, Paper} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FileUpload from "../../../../components/form/fileUpload/fileUpload";
import Grid from "@mui/material/Grid";
import {enqueueSnackbar} from "notistack";
import Button from "@mui/material/Button";
import {AuthenticatedLink} from "../../../../components/general/authenticatedLink/authenticatedLink";
import {DownloadTwoTone} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

const ExhibitorKitAndLogos = () => {
	const {t} = useTranslation(EXHIBITION)
	const {language} = useUserData()
	const {exhibition, files, loading, id} = useExhibition({language: 'any'})
	
	const {
		getFiles,
		onUpdateAlias,
		onFilesUpload,
		onFileRemove,
	} = useExhibitionFileUpload({
		exhibitionId: id,
		typeId: FileTypes.ExhibitorKit
	})
	const kitFiles = useMemo(() => (getFiles(files.filter(f => f.type === FileTypes.ExhibitorKit), '')), [files, getFiles])
	return (
		<div>
			{exhibition ? (
				<React.Fragment>
					<Breadcrumb>
						<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
							{t('Home')}
						</Breadcrumb.Link>
						<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
							{exhibition.title[language]}
						</Breadcrumb.Link>
						<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
							{t('Settings')}
						</Breadcrumb.Link>
						<Breadcrumb.Item>
							{t('Marketing Kit & Advertisement Logos')}
						</Breadcrumb.Item>
					</Breadcrumb>
					<Paper sx={{mb: 1}} variant={"outlined"}>
						<List>
							<ListItem component={"div"}>
								<ListItemText
									primary={
										<Typography component={"div"} variant={"body1"}>
											<Trans
												i18nKey={'exhibitor_kit_and_logos'}
												ns={EXHIBITION}
											>
												Upload a <strong>single</strong> file for Marketing Kit. <br/>
												Only <strong>.zip</strong> and <strong>.7z</strong> file extensions are
												allowed.
											</Trans>
										</Typography>
									}
								/>
							</ListItem>
						</List>
					</Paper>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8} lg={9}>
							<Paper sx={{px: 2, pb: 2}} variant={"outlined"}>
								<Toolbar disableGutters variant={"dense"}>
									<Typography variant="h6">
										{t('Marketing Kit')}
									</Typography>
								</Toolbar>
								<Divider sx={{mb: 2}} light/>
								<FileUpload
									accept={{
										'application/x-7z-compressed': ['.7z'],
										'application/zip': ['.zip']
									}}
									allowAliasEdit
									files={kitFiles}
									multiple={false}
									onUpdateAlias={onUpdateAlias}
									onRemove={onFileRemove}
									onFilesUpload={(files) => {
										if (kitFiles && kitFiles.length > 0) {
											enqueueSnackbar(t('You can only upload a single file. Please remove your old file to upload again!'), {
												variant: 'warning',
											})
										} else {
											if (files && files.length > 0) files.forEach((f) => onFilesUpload(f, ''))
										}
									}}
								/>
							</Paper>
						</Grid>
						<Grid item xs={12} md={4} lg={3}>
							<Paper sx={{
								px: 2,
								pb: 2,
								height: '100%'
							}} variant={"outlined"}>
								<Box>
									<Toolbar disableGutters variant={"dense"}>
										<Typography variant="h6">
											{t('Advertisement Logos')}
										</Typography>
									</Toolbar>
									<Divider sx={{mb: 2}} light/>
								</Box>
								<AuthenticatedLink
									filename={'logos'}
									url={`${API}/en/exhibitor/get-logos/${id}`}
								>
									<Button
										variant={"outlined"}
										size={"small"}
										color={'success'}
										startIcon={<DownloadTwoTone/>}
									>
										<Typography variant="h6">
											{normalizeGreek(t('Download Exhibitor Advertisement Logos As Single Zip File'))}
										</Typography>
									</Button>
								</AuthenticatedLink>
							</Paper>
						</Grid>
					</Grid>
				</React.Fragment>
			) : (
				<ContentLoader
					loading={loading}
				/>
			)}
			<ExhibitionUpdateStatus/>
		</div>
	)
}

export default ExhibitorKitAndLogos;
