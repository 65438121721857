// @flow
import React from 'react';
import {Checkbox, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Box from "@mui/material/Box";
import useUserData from "../../../hooks/user/useUserData";
import {showOption} from "../../../helpers/helpers";

type Props = {};

const SelectMultipleCountries = (props: Props) => {
		const {variant, disabled, label, value, onChange, countries, required, shrink = true} = props
		const {language} = useUserData()
		return (
			<>
				<Autocomplete
					disabled={disabled}
					multiple
					options={countries}
					value={value}
					isOptionEqualToValue={(opt, val) => {
						return opt.id === val.id || !val
					}}
					getOptionLabel={showOption(language)}
					selectOnFocus
					clearOnBlur
					disableCloseOnSelect
					onChange={onChange}
					autoHighlight={true}
					size="small"
					fullWidth
					renderOption={(props, option, {selected}) => (
						<Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
								checkedIcon={<CheckBoxIcon fontSize="small"/>}
								style={{marginRight: 8}}
								checked={selected}
							/>
							<img
								loading="lazy"
								width="20"
								srcSet={`${option.image} 2x`}
								src={option.image}
								alt=""
							/>
							{showOption(language)(option)}
						</Box>
					)}
					renderInput={(params) => (
						<TextField
							required={required}
							variant={variant}
							InputLabelProps={{shrink: shrink}}
							sx={{
								'& .MuiOutlinedInput-root': {
									bgcolor: "background.paper"
								}
							}}
							{...params}
							inputProps={{
								...params.inputProps,
								autoComplete: 'none',
							}}
							label={label}
						/>
					)}
				/>
			</>
		);
	}
;

export default SelectMultipleCountries