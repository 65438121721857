//@flow
import React from 'react';
import {useExhibitionFileUpload} from "../../../hooks/exhibitionFIles/useExhibitionFileUpload";
import EditorWithAttachmentsForm from "../../form/editorWithAttachmentsForm/editorWithAttachmentsForm";
import type {ExhibitionFile} from "../../../types/exhibitionFiles/types";

type Props = {
    text: {
        en: string,
        el: string,
    },
    exhibitionId: string,
    typeId: string,
    onSubmit: Function,
    files: Array<ExhibitionFile>
}

const ExhibitionContentWithFilesSection = (props: Props) => {
    const {typeId, exhibitionId, files, onSubmit, text} = props
    const {
        getFiles,
        onUpdateAlias,
        onFilesUpload,
        onFileRemove,
    } = useExhibitionFileUpload({
        exhibitionId: exhibitionId,
        typeId: typeId
    })
    return (
        <EditorWithAttachmentsForm
            initialText={text}
            onSubmit={onSubmit}
            files={{
                en: getFiles(files, 'en'),
                el: getFiles(files, 'el')
            }}
            onUpdateAlias={onUpdateAlias}
            onFilesUpload={onFilesUpload}
            onFileRemove={onFileRemove}
        />
    )
}

export default ExhibitionContentWithFilesSection;
