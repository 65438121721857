//@flow
import React from 'react';
import {Alert, AlertTitle, ListItem, ListItemText} from "@mui/material";
import {BulkImportStatus} from "../../../../helpers/constants";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

type Props = {
    onClose: Function,
    status: {
        statusCode: number,
        message?: string,
        newItems?: Array<string>,
        errors?: Array<string>
    }
}

const mapCodeToInfo = (code) => {
    switch (code) {
        case BulkImportStatus.AllSuccessful:
            return {
                severity: "success",
                title: "Success",
                message: "Imported successfully."
            }
        case BulkImportStatus.AllErrors:
            return {
                severity: "error",
                title: "Error",
                message: "Import failed check description bellow for more details."
            }
        case BulkImportStatus.SucceededWithErrors:
            return {
                severity: "warning",
                title: "Warning",
                message: "Import process finished with errors. Some data could not be imported check description for more details."
            }
        case BulkImportStatus.NothingToDo:
        default:
            return {
                severity: "info",
                title: "Info",
                message: "Nothing to do here."
            }
    }
}

const BulkImportAlert = (props: Props) => {
    const {status, onClose} = props
    if (!status) return null
    const {severity, title, message} = mapCodeToInfo(status.statusCode)
    return (
        <Alert
            severity={severity}
            sx={{
                '& .MuiAlert-message': {
                    width: '100%'
                }
            }}
            onClose={onClose}
        >
            <AlertTitle> {title}</AlertTitle>
            <Box sx={{mb: 1}}>
                {message}
            </Box>
            <List
                dense
                disablePadding
                sx={{flexGrow: 1, maxHeight: 120, overflow: 'auto'}}
            >
                {status.errors && status.errors.length > 0 && (
                    status.errors.map((i, index) =>
                        <ListItem divider disablePadding key={i + index}>
                            <ListItemText primary={i}/>
                        </ListItem>
                    )
                )}
            </List>
        </Alert>
    )
}

export default BulkImportAlert;