// @flow
import React, {useEffect, useMemo, useState} from 'react';
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {MoveDown} from "@mui/icons-material";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import ContentLoader from "../../general/contentLoader/contentLoader";
import ActionStatus from "../alerts/actionStatus/actionStatus";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {useEntersoftTransferAction} from "../../../hooks/entersoftTransfer/useEntersoftTransferAction";
import {useEntersoftTransferData} from "../../../hooks/entersoftTransfer/useEntersoftTransferData";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {Alert, AlertTitle} from "@mui/material";
import Button from "@mui/material/Button";

type Props = {
	exhibitionId: string
};

const TransferDataToEntersoft = (props: Props) => {
	const {exhibitionId, ids} = props
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	const {loadingTransfer, transferMessage, transferEnded} = useEntersoftTransferData()
	const {transferExhibitorsToEntersoft, clearTransferMessage} = useEntersoftTransferAction()
	const [open, setOpen] = useState(false)
	const transferLabel = useMemo(() => (
		ids.length > 0 ? (
			normalizeGreek(t('Transfer {{count}} Catalogues to EnterSoft', {count: ids.length}))
		) : (
			normalizeGreek(t('Transfer Catalogues To EnterSoft'))
		)
	), [ids.length, t])
	useEffect(() => {
		if (transferEnded) setOpen(false)
	}, [transferEnded])
	return (
		<Box
			sx={{
				mr: 'auto'
			}}
		>
			<Button
				variant={"outlined"}
				size={"small"}
				color={'error'}
				startIcon={<MoveDown/>}
				onClick={() => setOpen(true)}
				sx={{
					marginRight: 'auto'
				}}
			>
				{transferLabel}
			</Button>
			<ActionStatus
				status={transferMessage}
				onClose={clearTransferMessage}
			/>
			<Dialog
				disableEscapeKeyDown
				open={Boolean(open)}
				fullWidth={true}
				maxWidth={'sm'}
			>
				<DialogTitle>{t('Transfer Confirmation')}</DialogTitle>
				<DialogContent>
					<Alert severity="warning" sx={{fontSize: '14px'}}>
						<AlertTitle>{t('Warning')}</AlertTitle>
						<Trans
							i18nKey={'transfer_confirmation'}
							ns={EXHIBITOR_PROFILE}
							defaults={"<br/><strong>ATTENTION</strong>: THIS IS AN IRREVERSIBLE PROCESS. <br/><br/> EXHIBITION CATALOGUES WILL BE TRANSFERRED TO ENTERSOFT DATABASE FROM PORTAL.<br/><br/><strong>YOU CANNOT UNDO THIS ACTION.</strong><br/> DO YOU WISH TO CONTINUE?"}
							components={{bold: <strong/>}}
						/>
					</Alert>
				</DialogContent>
				<DialogActions>
					<Button
						size={"small"}
						onClick={() => setOpen(false)}
						color="error"
						sx={{
							marginRight: 'auto'
						}}
					>
						{normalizeGreek(t('Cancel'))}
					</Button>
					<LoadingButton
						loading={loadingTransfer}
						loadingPosition="start"
						variant={"contained"}
						size={"small"}
						color={'success'}
						startIcon={<MoveDown/>}
						onClick={() => transferExhibitorsToEntersoft(exhibitionId, ids)}
					>
						{normalizeGreek(t('Confirm And Start Transfer EnterSoft'))}
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<ContentLoader
				withBackDrop={true}
				useCircularLoader={true}
				loading={loadingTransfer}
				message={t("Transferring data... Please don't close the browser before the process ends.")}
			/>
		</Box>
	);
};

export default TransferDataToEntersoft