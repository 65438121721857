//@flow
import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import Box from "@mui/material/Box";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {API} from "../../../../../../helpers/constants";
import {AuthenticatedLink} from "../../../../../../components/general/authenticatedLink/authenticatedLink";
import './fastReports.css'
import dayjs from "dayjs";
import CurrentKpis from "./partials/currentKpis";
import {Paper, Tooltip} from "@mui/material";
import ContentLoader from "../../../../../../components/general/contentLoader/contentLoader";
import ExportPreregistationReportPopOver from "./partials/exportPreregistrationReportPopOver";
import ReportGrids from "./partials/reportGrids";
import ExportVisitorReportPopOver from "./partials/exportVisitorReportPopOver";
import {useCountries} from "../../../../../../hooks/countries/useCountries";
import {useCategories} from "../../../../../../hooks/categories/useCategories";
import {useInterests} from "../../../../../../hooks/interests/useInterests";

type Props = {
	exhibitionId: string
}

const FastReports = (props: Props) => {
	const {
		exhibition,
		exhibitionId,
		loading,
		reports: {
			kpi,
			teams
		}
	} = props
	const {t} = useTranslation(EXHIBITION)
	const [exportInExcelAnchor, setExportInExcelAnchor] = useState(null)
	const handleCloseExportInExcel = () => setExportInExcelAnchor(null)
	const [exportPreregInExcelAnchor, setExportPreregInExcelAnchor] = useState(null)
	const handleCloseExportPreregInExcel = () => setExportPreregInExcelAnchor(null)
	const {loading: loadingCountries, countries} = useCountries()
	const {loading: loadingInterests, interests} = useInterests(exhibition.id)
	const {loading: loadingCategories, categories} = useCategories(exhibition.id)
	
	// TODO::Use for controlled accordion expansion
	// const [expanded, setExpanded] = useState('panel0');
	// const handleChange = (panel) => (event, isExpanded) => {
	// 	setExpanded(isExpanded ? panel : 'panel0');
	// };
	
	const currentExhKpis = useMemo(() => (
		kpi.filter(i => dayjs(i.exhibitionStartDt).isSame(exhibition.dateFrom))
	), [exhibition.dateFrom, kpi])
	
	const isCoorganization = useMemo(() => (Boolean(exhibition.coorganizationDescription)), [exhibition.coorganizationDescription])
	
	return !loading && !loadingCountries && !loadingCategories && !loadingInterests ? (
		<Box>
			<Box pb={1}>
				<Paper sx={{mb: 1, p: 1}} variant={"outlined"}>
					<Stack direction="row" width={'100%'} gap={1} flexWrap={'wrap'}>
						<AuthenticatedLink
							filename={'export'}
							url={API + "/en/exhibition/visits/export/" + exhibitionId}
						>
							<Button
								variant={"outlined"}
								size={"small"}
							>
								<FileDownloadIcon/>
								<Tooltip
									title={t('Export all visitor data. This excel can be used to import visitors in EnterSoft.')}>
									{t('EXPORT DATA')}
								</Tooltip>
							</Button>
						</AuthenticatedLink>
						<Button
							variant={"outlined"}
							size={"small"}
							onClick={(evt) => setExportInExcelAnchor(evt.currentTarget)}
						>
							<Tooltip
								title={t('Export visitor data by applying with specific filters.')}>
								{t('EXPORT VISITOR REPORT')}
							</Tooltip>
						</Button>
						<Button
							variant={"outlined"}
							size={"small"}
							onClick={(evt) => setExportPreregInExcelAnchor(evt.currentTarget)}
						>
							<Tooltip
								title={t('Export preregistration data by applying with specific filters.')}>
								{t('EXPORT PREREGISTRATION REPORT')}
							</Tooltip>
						</Button>
					</Stack>
				</Paper>
			</Box>
			<Box sx={{maxWidth: 'calc(100vw - 120px)'}}>
				<Box>
					{!loading ? (
						<>
							{currentExhKpis && currentExhKpis.length > 0 && (
								<CurrentKpis
									data={currentExhKpis[0]}
									loading={loading}
									t={t}
									isCoorganization={isCoorganization}
								/>
							)}
						</>
					) : (
						<ContentLoader
							loading={loading}
						/>
					)}
					<ReportGrids
						reports={props.reports}
						loading={loading}
						isCoorganization={isCoorganization}
					/>
				</Box>
			</Box>
			<ExportVisitorReportPopOver
				countries={countries}
				interests={interests}
				categories={categories}
				teams={teams}
				exhibitionId={exhibitionId}
				exportInExcelAnchor={exportInExcelAnchor}
				handleCloseExportInExcel={handleCloseExportInExcel}
			/>
			<ExportPreregistationReportPopOver
				countries={countries}
				interests={interests}
				categories={categories}
				exhibitionId={exhibitionId}
				exportPreregInExcelAnchor={exportPreregInExcelAnchor}
				handleCloseExportPreregInExcel={handleCloseExportPreregInExcel}
			/>
		</Box>
	) : (
		<ContentLoader loading={loading || loadingInterests || loadingCountries || loadingCategories}/>
	)
}

export default FastReports;