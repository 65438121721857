import {useCallback} from "react";
import {useDispatch} from "react-redux";
import * as actions from "../../actions/actions";

export const useMessageTemplatesAction = (exhibitionId) => {
    const dispatch = useDispatch()

    const getMessageTemplate = useCallback((typeId) => {
        dispatch(actions.getMessageTemplate({
            exhibitionId: exhibitionId,
            typeId: typeId
        }))
    }, [dispatch, exhibitionId])

    const getMessageTemplateTypes = useCallback(() => {
        dispatch(actions.getMessageTemplateTypes())
    }, [dispatch])

    const getMessageTemplateTypeVariables = useCallback((typeId) => {
        dispatch(actions.getMessageTemplateTypeVariables({
            typeId: typeId
        }))
    }, [dispatch])

    const updateMessageTemplate = useCallback((values, typeId) => {
        dispatch(actions.updateMessageTemplate({
            exhibitionId: exhibitionId,
            typeId: typeId,
            TitleEN: values.title?.en,
            TitleEL: values.title?.el,
            BodyEN: values.body?.en,
            BodyEL: values.body?.el,
            isActive: values.isActive
        }))
    }, [dispatch, exhibitionId])

    return {
        getMessageTemplate,
        getMessageTemplateTypes,
        getMessageTemplateTypeVariables,
        updateMessageTemplate
    }
}