// @flow
import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import {Switch} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
};

const DisplayBrandsInCatalog = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props

    const toggleDisplayBrandsInCatalog = () => {
        updateExhibition(id, !exhibition.hasExhibitorBrands, Update.hasExhibitorBrands)
    }
    return (
        <ListItem>
            <ListItemText
                primary={t('Display Brands')}
                secondary={t('If you disable this, then brand entry will now be accessible in exhibitor \'s catalogue.')}
            />
            <Switch
                edge="end"
                onChange={toggleDisplayBrandsInCatalog}
                checked={exhibition.hasExhibitorBrands}
            />
        </ListItem>
    );
};

export default DisplayBrandsInCatalog