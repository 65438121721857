//@flow
import React from 'react';
import {API, PaperSize} from "../../../helpers/constants";
import {AuthenticatedFile} from "../../general/authenticatedLink/authenticatedFile";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import {printFile} from "../../../helpers/fileHandle";
import {Print} from "@mui/icons-material";

type Props = {
    isPreview?: boolean,
    autoReloadFile?: boolean,
    userId?: string,
    printSize?: string,
    type: "visitor" | "partner",
    exhibitionId?: string,
    categoryTypeId?: string
}

export const ButtonComponent = ({
                             loading,
                             fetchFile,
                             localLink,
                             autoReloadFile,
                             component: Component
                         }) => {
    const onClick = (evt) => {
        evt.stopPropagation()
        if (!autoReloadFile) {
            fetchFile()
        } else {
            printFile(localLink)
        }
    }
    return loading ? (
        <CircularProgress
            color="inherit"
            size={16}
            sx={{mx: 1.25, alignSelf: "center"}}
        />
    ) : (!autoReloadFile || (autoReloadFile && localLink)) ? (
        Component ? (
            <Component
                onClick={onClick}
            />
        ) : (
            <IconButton
                color="primary"
                onClick={onClick}
            >
                <Print/>
            </IconButton>
        )
    ) : null;
}

export const getPdfBadgeUrl = (type, previewPart, printSize, id, exhibitionId, categoryTypeId) => {
    return !id ? API + `/en/${type}/badge${previewPart}/${printSize}/${exhibitionId}/${categoryTypeId}` : API + `/en/${type}/badge${previewPart}/${printSize}/${id}`;
}

const PrintBadgeButton = (props: Props) => {
    const {userId, type, autoReloadFile, isPreview, component, exhibitionId, categoryTypeId} = props
    const printSize = props.printSize || PaperSize.A4
    const previewPart = isPreview ? '/preview' : ''
    const url = getPdfBadgeUrl(type, previewPart, printSize, userId, exhibitionId, categoryTypeId)

    return (
        <AuthenticatedFile
            autoReloadFile={autoReloadFile}
            url={url}
            automaticallyUsePrintFile={true}
        >
            {(params) => (
                <ButtonComponent
                    {...params}
                    component={component}
                    autoReloadFile={autoReloadFile}
                />
            )}
        </AuthenticatedFile>
    )
}

export default PrintBadgeButton;
