//@flow
import * as React from 'react'
import {useEffect} from 'react'
import {useAuthenticatedFile} from "./useAuthenticatedFile";
import {printFile} from "../../../helpers/fileHandle";

type Props = {
    autoReloadFile: boolean,
    url: string,
    children: React.Node,
}

export const AuthenticatedFile = (props: Props) => {

    const {url, children, autoReloadFile, automaticallyUsePrintFile} = props
    const {localLink, loading, fetchFile} = useAuthenticatedFile()

    useEffect(() => {
        if (autoReloadFile) {
            // noinspection JSIgnoredPromiseFromCall
            fetchFile(url)
        }
    }, [autoReloadFile, fetchFile, url])
    return children({
        localLink,
        loading,
        fetchFile: () => fetchFile(url, automaticallyUsePrintFile ? printFile : undefined)
    })
}
