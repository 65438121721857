//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import {parseCoorganizations, parseStatus} from "../../types/coorganizations/parse";
import type {Coorganization} from "../../types/coorganizations/types";

const selectLoading = createSelector(
    (state) => state.coorganizations.get('loading'),
    (item) => item
)

const selectUpdateGridSource = createSelector(
    (state) => state.coorganizations.get('updateGridSource'),
    (item) => item
)

const selectUpdateExhibitionList = createSelector(
    (state) => state.coorganizations.get('updateExhibitionList'),
    (item) => item
)

const selectStatus = createSelector(
    (state) => state.coorganizations.get('status'),
    (item) => parseStatus(item)
)

const selectItems = createSelector(
    [
        (state) => state.coorganizations.get('byId'),
        (state) => state.currentUser.get('language')
    ],
    (byId, userLanguage) => parseMultiple(byId, (i) => parseCoorganizations(i, userLanguage))
)

export const useCoorganizationsData = (ruleId) => {
    const loading: boolean = useSelector(selectLoading)
    const coorganizations: Array<Coorganization> = useSelector(selectItems)
    const status = useSelector(selectStatus)
    const updateGridSource = useSelector(selectUpdateGridSource)
    const updateExhibitionList = useSelector(selectUpdateExhibitionList)

    return {
        loading,
        coorganizations,
        status,
        updateGridSource,
        updateExhibitionList
    }
}