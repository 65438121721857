// @flow
import React from 'react';
import {ListSubheader} from "@mui/material";
import Logo from "./components/Logo";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_EXHIBITION_SETTINGS_COPY,
	ROUTE_PAGE_EXHIBITION_SETTINGS_INTERESTS,
	ROUTE_PAGE_EXHIBITION_SETTINGS_TRANSLATIONS,
	ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE
} from "../../../../../routers/routes";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import Typography from "@mui/material/Typography";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";

type Props = {};

const GeneralExhibitionSettings = (props: Props) => {
	const {id, exhibition, updateExhibition} = props
	const history = useHistory()
	const {t} = useTranslation(EXHIBITION)
	return (
		<List
			subheader={<ListSubheader sx={{backgroundColor: '#F9FBF2'}}><Typography variant={'button'}
																					sx={{color: '#000000'}}>{normalizeGreek(t('General'))}</Typography></ListSubheader>}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<Logo
				language={"el"}
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<Logo
				language={"en"}
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE, {id: id}))
				}}
				alignItems="flex-start"
			>
				<ListItemText
					primary={t("Welcome Page")}
					secondary={
						<React.Fragment>
							{t("Enter details about the event and files you want to display to exhibitors.")}
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_INTERESTS, {id: id}))
				}}
				alignItems="flex-start"
			>
				<ListItemText
					primary={t("Profession Domains")}
					secondary={
						<React.Fragment>
							{t("List of profession domains connected to current exhibition. Set appearance order of profession domains for preregistration form.")}
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_TRANSLATIONS, {id: id}))
				}}
				alignItems="flex-start"
			>
				<ListItemText
					primary={t("Customize Info Messages To Exhibitors")}
					secondary={
						<React.Fragment>
							{t("Customize information messages in pages available to exhibitors.")}
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_COPY, {id: id}))
				}}
				alignItems="flex-start"
			>
				<ListItemText
					primary={t("Copy Exhibition Settings")}
					secondary={
						<React.Fragment>
							{t("Select an other exhibition, to copy its settings to current exhibition.")}
						</React.Fragment>
					}
				/>
			</ListItem>
		</List>
	);
};

export default GeneralExhibitionSettings