import _ from "lodash";

export type TreeNode = {
    id: string,
    name: string,
    data: Object,
    parent: string,
    depth: number,
    children: Array<TreeNode>,
    hasChildren: boolean,
}

type Params = {
    items: Array<{
        id: string,
        parent: string,
        weight: number
    }>,
    parentId?: string,
    nestingLevel?: number
}
export const listToTree: Array<TreeNode> = (params: Params = {}) => {
    const {items = [], parentId = 0, nestingLevel = 1} = params
    const siblings = _.orderBy(items.filter(item => item.parent === parentId), 'weight', 'asc')
    return siblings
        .map(item => {
            const children = listToTree({
                items,
                parentId: item.id,
                nestingLevel: nestingLevel + 1
            })
            return ({
                id: item.id,
                parent: parentId,
                depth: nestingLevel,
                data: item,
                hasChildren: children.length > 0,
                children
            })
        })
}
