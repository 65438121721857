import * as ActionTypes from '../../actions/types';
import {fromJS} from "immutable";
import {
    mapEmail
} from "../../types/email/map";


const INITIAL_STATE = {
    loading: false,
    emails: {}
}

function getExhibitionEmails(state) {
    return state
        .set('loading', true)
}

function getExhibitionEmailsSucceeded(state, action) {
    const {payload} = action
    const unmappedData = payload[0]
    const mappedData = mapEmail(unmappedData)
    return state
        .set('loading', false)
        .set('emails', fromJS(mappedData))
}

function getExhibitionEmailsFailed(state, action) {
    return state
        .set('loading', false)
}

const email = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_EXHIBITION_EMAILS]: getExhibitionEmails,
        [ActionTypes.GET_EXHIBITION_EMAILS_SUCCEEDED]: getExhibitionEmailsSucceeded,
        [ActionTypes.GET_EXHIBITION_EMAILS_FAILED]: getExhibitionEmailsFailed,
    }
}

export default email
