// @flow
import React from 'react';
import DialogTransition from "../../../../../components/general/dialogTransition/dialogTransition";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {AuthenticatedLink} from "../../../../../components/general/authenticatedLink/authenticatedLink";
import {ACCEPT_EXCEL, API} from "../../../../../helpers/constants";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import Button from "@mui/material/Button";
import FileUpload from "../../../../../components/form/fileUpload/fileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import {DialogActions, FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import useQuickBadgeImport from "../../../../../hooks/quickBadgePrint/useQuickBadgeImport";
import {ErrorMessage, FormikProvider} from "formik";
import Box from "@mui/material/Box";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import MenuItem from "@mui/material/MenuItem";

type Props = {};

const ExcelImport = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {show, handleDialog, exhibition, badgeTemplateTypes} = props
	const onClose = (evt, reason) => {
		handleDialog(false)
	}
	const {loading, formik, onFileChange} = useQuickBadgeImport({
		exhibitionId: exhibition.id,
		onClose,
		badgeTemplateTypes
	})
	
	return (
		<Dialog
			maxWidth={"lg"}
			open={show}
			TransitionComponent={DialogTransition}
			keepMounted
			onClose={onClose}
		>
			<FormikProvider value={formik}>
				<Box
					sx={{
						'& .MuiTextField-root': {my: 1},
					}}
					component="form"
					onSubmit={formik.handleSubmit}
				>
					<DialogTitle>{t("Import from excel")}</DialogTitle>
					<DialogContent>
						<DialogContentText component={"div"}>
							<Trans
								i18nKey={'import_excel_msg_quick_print'}
								ns={EXHIBITION}
							>
								If you want to add more than one people quickly you can upload
								an <strong>Excel</strong> file.<br/>
								<ul>
									<li>Your excel file should follow the structure described bellow.</li>
									<li>Make sure your columns are in order as in the example table.</li>
									<li>For your convenience, you you also download our blank excel template.</li>
									<li>If you omit font/background colors for characterization, default values are
										FFFFFF/6D6D6D in hex respectively.
									</li>
									<li>If you omit print size selection, default size is A5.</li>
								</ul>
							</Trans>
						</DialogContentText>
						<FormControl
							variant="outlined"
							error={
								formik.touched["templateId"] &&
								Boolean(formik.errors["templateId"])
							}
							sx={{
								mr: 1,
								textAlign: "center",
								paddingRight: 1,
								paddingBottom: 1,
								minWidth: '240px',
								maxWidth: '60%',
								width: '100%'
							}}
						>
							<InputLabel
								id="templateId-simple-select">{t('Print Size')}</InputLabel>
							<Select
								labelId="templateId-simple-select"
								autoComplete={"chrome-off"}
								size={"small"}
								name={"templateId"}
								value={formik.values.templateId}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								label={t('Print Size')}
							>
								{badgeTemplateTypes.filter(f => f.id === '1' || f.id === '2').map(item => (
									<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
								))}
							</Select>
							<FormHelperText>
								<ErrorMessage name={"templateId"}/>
							</FormHelperText>
						</FormControl>
						<TableContainer component={Paper} variant={"outlined"} sx={{mb: 1}}>
							<Table size={"small"}>
								<TableHead>
									<TableRow>
										<TableCell>{t('Name EL')}</TableCell>
										<TableCell>{t('Name EN')}</TableCell>
										<TableCell>{t('Company Name EL')}</TableCell>
										<TableCell>{t('Company Name EN')}</TableCell>
										<TableCell>{t('Characterization EL')}</TableCell>
										<TableCell>{t('Characterization EN')}</TableCell>
										<TableCell>{t('Characterization Background Color')} ({t('optional')})</TableCell>
										<TableCell>{t('Characterization Text Color')} ({t('optional')})</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
										<TableCell>ΑΛΕΞΑΝΔΡΑ ΑΛΕΞΑΝΔΡΗ</TableCell>
										<TableCell>ALEXANDRA ALEXANDRI</TableCell>
										<TableCell>ΕΡΓΟΛΟΓΙΚΗ ΑΕΒΕ</TableCell>
										<TableCell>ERGOLOGIC S.A.</TableCell>
										<TableCell>ΠΡΟΓΡΑΜΜΑΤΙΣΤΡΙΑ</TableCell>
										<TableCell>DEVELOPER</TableCell>
										<TableCell>6d6d6d</TableCell>
										<TableCell>FFFFFF</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<AuthenticatedLink
							url={`${API}/en/visitor/badge/quick-import-template`}
							filename={normalizeGreek(t('Download excel template'))}
						>
							<Button
								sx={{mb: 1}}
								size={"small"}
								component={"button"}
								target={"_blank"}
							>
								{normalizeGreek(t('Download excel template'))}
							</Button>
						</AuthenticatedLink>
						<FormControl
							sx={{mb: 1}}
							variant="standard"
							fullWidth
							error={
								formik.touched["files"] &&
								Boolean(formik.errors["files"])
							}
						>
							<FileUpload
								accept={{[ACCEPT_EXCEL]: ['.xlsx']}}
								multiple={false}
								onRemove={onFileChange}
								onFilesUpload={onFileChange}
							/>
							<FormHelperText>
								<ErrorMessage name={"files"}/>
							</FormHelperText>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							size={"small"}
							type={"submit"}
							loading={loading}
							variant="contained"
						>
							{normalizeGreek(t('Import selected file'))}
						</LoadingButton>
						<Button
							size={"small"}
							onClick={onClose}>
							{normalizeGreek(t('Cancel'))}
						</Button>
					</DialogActions>
				</Box>
			</FormikProvider>
			<ContentLoader
				withBackDrop={true}
				useCircularLoader={true}
				loading={loading}
				message={t("Printing... Please don't close the browser before the process ends.")}
			/>
		</Dialog>
	);
};

export default ExcelImport