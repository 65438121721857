// @flow
import React from 'react';
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton
} from "@mui/x-data-grid-premium";

type Props = {};

const CustomToolbar = (props: Props) => {
	const {
		showFilters = true,
		showColumns = true,
		showDensity = true,
		showExport = true,
		filtersSlotProps = null,
		columnsSlotProps = null,
		densitySlotProps = null,
		exportSlotProps = null,
	} = props
	return (
		<GridToolbarContainer>
			{showFilters && (
				<GridToolbarColumnsButton
					{...filtersSlotProps ? filtersSlotProps : {}}
				/>
			)}
			{showColumns && (
				<GridToolbarFilterButton
					{...columnsSlotProps ? columnsSlotProps : {}}
				/>
			)}
			{showDensity && (
				<GridToolbarDensitySelector
					{...densitySlotProps ? densitySlotProps : {}}
				/>
			)}
			{showExport && (
				<GridToolbarExport
					{...exportSlotProps ? exportSlotProps : {}}
				/>
			)}
			{props.children}
		</GridToolbarContainer>
	);
};

export default CustomToolbar