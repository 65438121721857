//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseUser} from "../../types/user/parse";

const selectIsLoggedIn = createSelector(
    (state) => state.currentUser,
    (item) => item.get('isLoggedIn')
)

const selectLoginName = createSelector(
    (state) => state.currentUser,
    (item) => item.getIn(['data', 'loginName'])
)

const selectLoading = createSelector(
    (state) => state.currentUser,
    (item) => item.get('loading')
)

const selectErrorLogin = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorLogin')
)

const selectErrorConnectivity = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorConnectivity')
)

const selectErrorRegister = createSelector(
    (state) => state.currentUser,
    (item) => item.get('errorRegister')
)

const selectRegisteredSuccessfully = createSelector(
    (state) => state.currentUser,
    (item) => item.get('registeredSuccessfully')
)

const selectLanguage = createSelector(
    (state) => state.currentUser,
    (item) => item.get('language')
)

const selectDecimalSeparator = createSelector(
    (state) => state.currentUser,
    (item) => item.get('decimalSeparator')
)

const selectThousandSeparator = createSelector(
    (state) => state.currentUser,
    (item) => item.get('thousandSeparator')
)

const selectDateFormat = createSelector(
    (state) => state.currentUser,
    (item) => item.get('dateFormat')
)

const selectTimeFormat = createSelector(
    (state) => state.currentUser,
    (item) => item.get('timeFormat')
)

const selectData = createSelector(
    [
        (state) => state.currentUser.get('data'),
        (state) => state.currentUser.get('language'),
    ],
    (item, language) => parseUser(item, language)
)

const selectToken = createSelector(
    (state) => state.currentUser,
    (item) => item.get('token')
)

const useUserData = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const loginName = useSelector(selectLoginName)
    const loading = useSelector(selectLoading)
    const errorLogin = useSelector(selectErrorLogin)
    const errorConnectivity = useSelector(selectErrorConnectivity)
    const errorRegister = useSelector(selectErrorRegister)
    const registeredSuccessfully = useSelector(selectRegisteredSuccessfully)
    const language = useSelector(selectLanguage)
    const data = useSelector(selectData)
    const token = useSelector(selectToken)
    const decimalSeparator = useSelector(selectDecimalSeparator)
    const thousandSeparator = useSelector(selectThousandSeparator)
    const dateFormat = useSelector(selectDateFormat)
    const timeFormat = useSelector(selectTimeFormat)
    return {
        isLoggedIn,
        loginName,
        loading,
        errorLogin,
        errorConnectivity,
        errorRegister,
        registeredSuccessfully,
        language,
        data,
        token,
        decimalSeparator,
        thousandSeparator,
        dateFormat,
        timeFormat,
    }
}

export default useUserData