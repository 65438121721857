const mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : 'production'

export const configuration = window.GLOBAL_CONFIGURATION[mode]

export const API = mode === 'production' ? configuration.API_HOST[window.location.hostname] : configuration.API_HOST;
export const API_PORT = API.substring(API.lastIndexOf(':') + 1)
export const API_DOMAIN = API.substring(0, API.lastIndexOf(':'))

export const PortalToken = window.GLOBAL_CONFIGURATION.portalToken
export const EditorAPIKey = window.GLOBAL_CONFIGURATION.editorAPIKey
export const MUI_LICENSE_KEY = '165e31ea195f48fc18e36a0a5d5b4310Tz05MTU1NyxFPTE3NDg2MTg3MzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
export const EXHIBITION_IMAGE_RELATIVE_PATH = '/attachments/exhLogos/'
export const EXHIBITION_FILES_RELATIVE_PATH = '/exhibition/attachment/'

export const FileTypes = {
    WelcomePage: 1,
    Message: 2,
    ExhibitorKit: 4
}

export const ACCEPT_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const Languages = {
    EL: 'el',
    EN: 'en',
}

export const Permissions = {
    BeExhibitor: "EXHIBITOR_CONTENT",
    BeOrganizer: "ORGANIZER_CONTENT",
    BeLobbyUser: "LOBBY_CONTENT",
    SeeReports: "REPORTS_CONTENT"
}

export const CatalogueLanguages = {
    ALL: 0,
    EN: 1,
    EL: 2
}

export const PaperSize = {
    A4: 1,
    A5: 2,
    A6: 4,
    LABEL: 5
}

export const Roles = {
    Organizer: 1,
    Exhibitor: 2,
    Visitor: 3,
}

export const PartnerTypes = {
    Partner: 0,
    ServiceProvider: 1,
    ExhibitionStaff: 2,
    Organizer: 3,
}

export const BulkImportStatus = {
    AllSuccessful: 1,
    AllErrors: 2,
    SucceededWithErrors: 3,
    NothingToDo: 4,
}

export const VisitorStatus = {
    Import: 1,
    Invitation: 2,
    InvitationVerified: 3,
    PreregistrationPendingApproval: 4,
    BadgeSent: 5,
    BadgeSentAndRead: 6,
    PreregistrationApproved: 7,
    PreregistrationRejected: 8,
}

export const RoleTranslations = {
    [Roles.Visitor]: {
        en: 'Visitor',
        el: 'Επισκέπτης',
    },
    [Roles.Exhibitor]: {
        en: 'Exhibitor',
        el: 'Εκθέτης',
    },
    [Roles.Organizer]: {
        en: 'Organizer',
        el: 'Οργανωτής',
    },
}

export const EmailStatus = {
    Sent: 1,
    NotSent: 0,
    Error: 2
}

export const PreregistrationRegions = {
    GreeceOnly: '2',
    Everywhere: '0',
    AbroadOnly: '1'
}

export const PreregistrationRegionsApproval = {
    NoApproval: 0,
    GreeceOnly: 1,
    AbroadOnly: 2,
    Everywhere: 3
}

export const LANG = 'el'

export const API_URL = configuration.API_HOST

export const API_LURL = `${API_URL}/${LANG}/`

export const DATE_FORMAT = 'd-m-Y'

export const operators = [
    {value: 'eq', description: {en: 'Equals', el: 'Ισούται'}},
    {value: 'neq', description: {en: 'Not Equals', el: 'Δεν ισούται'}},
    {value: 'gt', description: {en: 'Greater Than', el: 'Μεγαλύτερο Από'}},
    {value: 'ge', description: {en: 'Greater Than Or Equal', el: 'Μεγαλύτερο Από ή Ίσο'}},
    {value: 'lt', description: {en: 'Less Than', el: 'Μικρότερο Από'}},
    {value: 'le', description: {en: 'Less Than Or Equal', el: 'Μικρότερο Από ή Ίσο'}},
    {value: 'cnt', description: {en: 'Contained In', el: 'Περιέχεται Σε'}},
    {value: 'ncnt', description: {en: 'Not Contained In', el: 'Δεν Περιέχεται Σε'}},
]

export const ruleFields = [
    {value: 'ErpCode', type: 'text', description: {en: 'ERP Code', el: 'Κωδικός ERP'}},
    {value: 'ContactId', type: 'text', description: {en: 'Contact ID', el: 'ID Επαφής'}},
    {value: 'Name', type: 'text', description: {en: 'Name / Surname', el: 'Ονοματεπώνυμο'}},
    {value: 'email', type: 'text', description: {en: 'E-mail', el: 'E-mail'}},
    {value: 'BussinessPhone', type: 'text', description: {en: 'Phone', el: 'Τηλέφωνο'}},
    {value: 'MobilePhone', type: 'text', description: {en: 'Mobile Phone', el: 'Κινητό Τηλέφωνο'}},
    {
        value: 'CompanyName',
        type: 'text',
        description: {en: 'Company Name / Organization', el: 'Επωνυμία Εταιρείας / Οργανισμός'}
    },
    {value: 'CompanyWebSite', type: 'text', description: {en: 'Company WebSite', el: 'Ηλεκτρονική Σελίδα Εταιρείας'}},
    {value: 'CompanyAddress', type: 'text', description: {en: 'Company Address', el: 'Διεύθυνση Εταιρείας'}},
    {value: 'PostalCode', type: 'text', description: {en: 'Postal Code', el: 'Ταχυδρομικός Κώδικας'}},
    {value: 'City', type: 'text', description: {en: 'City', el: 'Πόλη'}},
    {value: 'CountryId', type: 'select', description: {en: 'Country', el: 'Χώρα'}},
    {value: 'PersonCount', type: 'text', description: {en: 'Person Count', el: 'Αριθμός Ατόμων'}},
    {value: 'VatRegNo', type: 'text', description: {en: 'Vat Registration No', el: 'Α.Φ.Μ.'}},
    {value: 'isIndividual', type: 'boolean', description: {en: 'Individual', el: 'Ιδιώτης'}},
    {value: 'hasRevisited', type: 'boolean', description: {en: 'Has Revisited', el: 'Έχει Ξανά Επισκεφτεί'}},
    {value: 'Interests', type: 'select', description: {en: 'Profession Domain', el: 'Επαγγελματική Ιδιότητα'}},
    {
        value: 'FromRegistration',
        type: 'boolean',
        description: {en: 'From Registration', el: 'Εγγραφή από Registration'}
    },
    {
        value: 'FromPreregistration',
        type: 'boolean',
        description: {en: 'From Preregistration', el: 'Εγγραφή από Preregistration'}
    },
    {value: 'FromImport', type: 'boolean', description: {en: 'From Import', el: 'Είσοδος από Import'}},
    {
        value: 'hasInterests',
        type: 'boolean',
        description: {en: 'Has Profession Domain', el: 'Έχει Επαγγελματική Ιδιότητα'}
    },
    {
        value: 'OtherInterests',
        type: 'text',
        description: {en: 'Other Profession Domain', el: 'Άλλη Επαγγελματική Ιδιότητα'}
    },
    {value: 'Position', type: 'text', description: {en: 'Position / Department', el: 'Θέση / Τμήμα'}},
    {value: 'FromImportNum', type: 'text', description: {en: 'From Import Num', el: 'Από αριθμό Import'}},
    {value: 'Facebook', type: 'text', description: {en: 'Facebook', el: 'Facebook'}},
    {value: 'Instagram', type: 'text', description: {en: 'Instagram', el: 'Instagram'}},
]