import React from 'react';
import {Box, IconButton} from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import {Flipped} from 'react-flip-toolkit';
import useDrag from "../useDrag";
import useDrop from "../useDrop";
import useIsClosestDragging from "../useIsClosestDragging";
import Grid from "@mui/material/Grid";

const ItemRenderer = React.memo((props) => {
    const {id, depth, data, renderItemTitle, renderItemActions} = props;
    const [{isDragging}, drag, preview] = useDrag({
        collect: (monitor) => ({isDragging: monitor.isDragging()})
    })
    const [, drop] = useDrop();
    const muted = useIsClosestDragging() || isDragging

    return (
        <Flipped flipId={id}>
            <Grid
                item
                xs={12}
                ref={(ref) => drop(preview(ref))}
                sx={{
                    alignItems: "center",
                    position: 'relative',
                    // mb: 1.5,
                    zIndex: muted ? 1 : 0,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#fff',
                        ml: depth * 4,
                        boxShadow: muted ? '0px 0px 8px #666' : '0px 0px 2px #666',
                        border: muted ? '1px dashed #1976d2' : '1px solid transparent',
                    }}
                >
                    <Box alignItems={"center"} display={"flex"}>
                        <IconButton
                            sx={{cursor: 'move', flex: 0}}
                            ref={drag}>
                            <ReorderIcon/>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            px: 1,
                            flex: 1,
                            display: "flex"
                        }}>
                        {renderItemTitle && renderItemTitle(data)}
                    </Box>
                    <Box alignItems={"center"} display={"flex"}>
                        {renderItemActions && renderItemActions(data)}
                    </Box>
                </Box>
            </Grid>
        </Flipped>
    )
})

export default ItemRenderer;
