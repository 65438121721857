//@flow
import React, {useState} from 'react';
import useExhibitionAction from "../../../hooks/exhibitions/useExhibitionAction";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import type {Visitor} from "../../../types/exhibition/types";
import Button from "@mui/material/Button";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import type {Badge} from "../../../types/badge/types";
import {VisitorStatus} from "../../../helpers/constants";

type Props = {
    exhibitionId: string,
    visitor: Visitor,
    badge: Badge
}

const ChangeApprovalStatus = (props: Props) => {
    const {t} = useTranslation(VISITOR_PROFILE)
    const {visitor, exhibitionId, badge} = props
    const {createVisitor} = useExhibitionAction()
    const [approvalReason, setApprovalReason] = useState(visitor.organizerApprovalRejectReason ?? "")
    const onStatusChange = (value) => {
        createVisitor(exhibitionId, null, {
            visitorId: visitor.id,
            id: visitor.ContactId,
            organizerApprovalStatus: value ? "approve" : "reject",
            organizerApprovalRejectReason: approvalReason
        }, false, true, false)
    }

    if (visitor.StatusId !== VisitorStatus.PreregistrationPendingApproval) return null
    return (
        <React.Fragment>
            <Button
                variant={badge.approved === true ? "contained" : "text"}
                size={"small"}
                onClick={() => onStatusChange(true)}
                startIcon={<DoneIcon/>}
                color="success"
            >
                {t('APPROVE')}
            </Button>
            <Button
                variant={badge.approved === false ? "contained" : "text"}
                size={"small"}
                onClick={() => onStatusChange(false)}
                startIcon={<ClearIcon/>}
                color="error"
            >
                {t('REJECT')}
            </Button>
        </React.Fragment>
    )
}

export default ChangeApprovalStatus;