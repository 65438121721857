import type {
    Message,
    MessagesSentPerDate,
    MessageTemplate,
    MessageTemplateType,
    MessageTemplateTypeVariable,
    UnmappedMessage,
    UnmappedMessagesSentPerDate,
    UnmappedMessageTemplate,
    UnmappedMessageTemplateType,
    UnmappedMessageTemplateTypeVariable
} from "./types";
import {formatDate} from "../../helpers/date";

export const mapMessageTemplate: MessageTemplate = (datum: UnmappedMessageTemplate) => {
    if (!datum) return
    return {
        id: datum.id,
        typeId: datum.TypeId,
        exhibitionId: datum.ExhibitionId,
        title: {
            en: datum.TitleEN ?? '',
            el: datum.TitleEL ?? ''
        },
        body: {
            en: datum.BodyEN ?? '',
            el: datum.BodyEL ?? ''
        },
        isActive: datum.isActive === '1'
    }
}

export const mapMessageTemplateTypes: MessageTemplateType = (datum: UnmappedMessageTemplateType) => {
    if (!(datum && datum.length > 0)) return []
    return datum.map(d => ({
        id: d.id,
        description: d.Description,
        isSMS: d.isSMS === '1'
    }))
}

export const mapMessageTemplateTypeVariables: MessageTemplateTypeVariable = (datum: UnmappedMessageTemplateTypeVariable) => {
    if (!(datum && datum.length > 0)) return []
    return datum.map(d => ({
        parentId: null,
        hasChildren: false,
        id: d.key,
        description: d.dscr
    }))
}

export const mapMessage: Message = (datum: UnmappedMessage) => {
    if (!datum) return
    return {
        Body: datum.Body,
        CreatedAt: new Date(datum.CreatedAt),
        Email: datum.Email,
        ExhibitorId: datum.ExhibitorId,
        ExhibitorName: datum.ExhibitorName,
        MobilePhone: datum.MobilePhone,
        SentAt: datum.SentAt ? new Date(datum.SentAt) : '',
        Title: datum.Title,
        TypeId: datum.TypeId,
        VisitorId: datum.VisitorIds,
        VisitorName: datum.VisitorName,
        id: datum.id,
        isSMS: datum.isSMS === '1',
        isSent: Number(datum.isSent)
    }
}


export const mapMessagesSentPerDate: MessagesSentPerDate = (datum: UnmappedMessagesSentPerDate) => {
    if (!datum) return
    return {
        dt: datum.dt ? formatDate(datum.dt, formatDate.formatTypes.DATE_ONLY) : '-',
        count: parseInt(datum.count)
    }
}