import type {Report, UnmappedReport} from "./types";
import {randomId} from "@mui/x-data-grid-generator";


export const mapReports: Report = (datum: UnmappedReport) => {
	if (!datum) return
	return {
		categories: datum.categories.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEN,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			category: {
				el: i.InterestEL,
				en: i.InterestEN,
			},
			visitorCount: Number(i.VisitorCount),
			visitorPercentage: Number(i.VisitorPercentage)
		})),
		interests: datum.interests.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEN,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			category: {
				el: i.InterestEL,
				en: i.InterestEN,
			},
			visitorCount: Number(i.VisitorCount),
			visitorPercentage: Number(i.VisitorPercentage)
		})),
		kpi: datum.kpi.map(i => ({
			id: i.id,
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEL,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			preregistrationTotalCount: Number(i.preregistrationTotalCount),
			preregistrationVisitorCount: Number(i.preregistrationVisitorCount),
			erpTotalCount: Number(i.erpTotalCount),
			erpVisitorCount: Number(i.erpVisitorCount),
			notErpTotalCount: Number(i.notErpTotalCount),
			notErpVisitorCount: Number(i.notErpVisitorCount),
			preregistrationTotalCountCoorg: Number(i.preregistrationTotalCountCoorg),
			preregistrationVisitorCountCoorg: Number(i.preregistrationVisitorCountCoorg),
			erpTotalCountCoorg: Number(i.erpTotalCountCoorg),
			erpVisitorCountCoorg: Number(i.erpVisitorCountCoorg),
			notErpTotalCountCoorg: Number(i.notErpTotalCountCoorg),
			notErpVisitorCountCoorg: Number(i.notErpVisitorCountCoorg),
			scannedAfterRegistration: Number(i.scannedAfterRegistration)
		})),
		visitsCountries: datum.visitsCountries.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEL,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			country: {
				el: i.CountryEL ? i.CountryEL : '- ΧΩΡΙΣ ΣΤΟΙΧΕΙΑ -',
				en: i.CountryEN ? i.CountryEN : '- NO DATA -'
			},
			visitorCount: Number(i.VisitorCount),
			weight: !i.CountryEL && !i.CountryEN ? 99 : 1
		})),
		visitsDay: datum.visitsDay.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEL,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			entranceDate: i.EntranceDate,
			entranceCount: Number(i.EntranceCount),
			visitorCount: Number(i.VisitorCount),
			entranceLobby1Count: Number(i.EntranceLobby1Count),
			entranceLobby2Count: Number(i.EntranceLobby2Count),
			entranceLoungeCount: Number(i.EntranceLoungeCount)
		})),
		visitsHalfHour: datum.visitsHalfHour.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEL,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			entranceDate: i.EntranceDate,
			entranceTime: i.EntranceTime,
			entranceCount: Number(i.EntranceCount),
			visitorCount: Number(i.VisitorCount),
			entranceLobby1Count: Number(i.EntranceLobby1Count),
			entranceLobby2Count: Number(i.EntranceLobby2Count),
			entranceLoungeCount: Number(i.EntranceLoungeCount),
		})),
		visitsHour: datum.visitsHour.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEL,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			entranceDate: i.EntranceDate,
			entranceTime: i.EntranceTime,
			entranceCount: Number(i.EntranceCount),
			visitorCount: Number(i.VisitorCount),
			entranceLobby1Count: Number(i.EntranceLobby1Count),
			entranceLobby2Count: Number(i.EntranceLobby2Count),
			entranceLoungeCount: Number(i.EntranceLoungeCount),
		})),
		visitsRegion: datum.visitsRegion.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEL,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			region: i.Region ? i.Region : '- ΧΩΡΙΣ ΣΤΟΙΧΕΙΑ -',
			visitorCount: Number(i.VisitorCount),
			weight: !i.Region ? 99 : 1
		})),
		visitsTotal: datum.visitsTotal.map(i => ({
			id: randomId(),
			exhibition: {
				el: i.ExhibitionNameEL,
				en: i.ExhibitionNameEN,
			},
			coorganization: {
				el: i.CoorganizationNameEL,
				en: i.CoorganizationNameEL,
			},
			exhibitionStartDt: i.ExhibitionStartDt,
			entranceCount: Number(i.EntranceCount),
			visitorCount: Number(i.VisitorCount),
			entranceLobby1Count: Number(i.EntranceLobby1Count),
			entranceLobby2Count: Number(i.EntranceLobby2Count),
			entranceLoungeCount: Number(i.EntranceLoungeCount)
		})),
		teams: datum.teams ? datum.teams : [],
		defaultPartnerVisits: datum.defaultPartnerVisits.map(i => ({
			id: randomId(),
			countCoorganization: Number(i.CountCoorganization),
			countExhibition: Number(i.CountExhibition),
			entryDate: i.EntryDate
		}))
		
	}
}