//@flow
import React from 'react';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {showNoValueIfDisabled} from "./catalog";
import {FormHelperText} from "@mui/material";
import {ErrorMessage} from "formik";
import Typography from "@mui/material/Typography";

const CatalogBrands = ({formik, inputVariant, disabled}) => {
    return (
        <Grid container spacing={1.5}>
            <Grid item xs={12}>
                <Typography
                    variant="subtitle2"
                    component={"div"}
                    sx={{
                        fontWeight: "bold",
                        mb: 3,
                        textAlign: "center",
                        bgcolor: 'grey.400',
                        color: 'grey.400.contrastText'
                    }}
                >
                    Brands
                </Typography>
            </Grid>
            {[...Array(10).keys()].map(index => (
                <Grid item xs={12} md={6} key={index}>
                    <FormControl
                        variant="standard"
                        color={'error'}
                        fullWidth
                        error={
                            formik.touched[`brands${index}`] &&
                            Boolean(formik.errors[`brands${index}`])
                        }
                    >
                        <TextField
                            InputLabelProps={{shrink: true}}
                            disabled={disabled}
                            variant={inputVariant}
                            label={`Brand-${index + 1}`}
                            type={'text'}
                            size={"small"}
                            name={`brands${index}`}
                            value={showNoValueIfDisabled(formik.values[`brands${index}`], disabled)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            inputProps={{maxLength: 250}}
                        />
                        <FormHelperText sx={{color: '#d32f2f'}}>
                            <ErrorMessage name={`brands${index}`}/>
                        </FormHelperText>
                    </FormControl>
                </Grid>
            ))}
        </Grid>
    )
}

export default CatalogBrands
