import {useCallback} from "react";
import * as actions from "../../actions/actions";
import {useDispatch} from "react-redux";

export const UpdateType = {
    ChangeOrder: 1,
    Other: 2
}

export const useFAQAction = () => {
    const dispatch = useDispatch()
    const getFaqs = useCallback((exhibitionId) => {
        dispatch(actions.getFaqs({
            id: exhibitionId
        }))
    }, [dispatch])

    const getFaqDetails = useCallback((faqId, exhibitionId) => {
        dispatch(actions.getFaqDetails({
            ExhibitionId: exhibitionId,
            id: faqId,
        }))
    }, [dispatch])

    const createFaq = useCallback((exhibitionId) => {
        dispatch(actions.createFaq({
            ExhibitionId: exhibitionId,
            TitleEN: '',
            TitleEL: '',
            ContentEN: '',
            ContentEL: '',
            isNew: false
        }))
    }, [dispatch])

    const updateFaq = useCallback((id, exhibitionId, values, updateType) => {
        dispatch(actions.updateFaq({
            id: id,
            ExhibitionId: exhibitionId,
            updateType: updateType,
            isNew: values.isNew,
            ...values.title && values.title.en ? {TitleEN: values.title.en} : {},
            ...values.title && values.title.el ? {TitleEL: values.title.el} : {},
            ...values.content && values.content.en ? {ContentEN: values.content.en} : {},
            ...values.content && values.content.el ? {ContentEL: values.content.el} : {},
            ...(typeof values.weight === "number") ? {Ordr: values.weight} : {},
            ...(typeof values.parent === "number") ? {ParentId: values.parent === 0 ? null : values.parent} : {}
        }))
    }, [dispatch])

    const deleteFaq = useCallback((id, exhibitionId) => {
        dispatch(actions.deleteFaq({
            id: id,
            ExhibitionId: exhibitionId,
        }))
    }, [dispatch])

    const reorderFaqs = useCallback((exhibitionId, faqs) => {
        dispatch(actions.reorderFaqs({
            ExhibitionId: exhibitionId,
            Data: faqs.map(f=> ({
                id: f.id,
                Ordr: f.weight,
                ParentId: f.parent === 0 ? null : f.parent,
            }))
        }))
    }, [dispatch])

    return {
        reorderFaqs,
        getFaqs,
        getFaqDetails,
        createFaq,
        updateFaq,
        deleteFaq
    }
}
