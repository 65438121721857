// @flow
import React from 'react';
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DefaultPartnerScans from "./defaultPartnerScans";
import KpisCoorganization from "./kpisCoorganization";
import KpisExhibition from "./kpisExhibition";
import VisitsTotal from "./visitsTotal";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../../locales/components/namespaces";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import VisitsDay from "./visitsDay";
import VisitsHour from "./visitsHour";
import VisitsHalfHour from "./visitsHalfHour";
import VisitsRegions from "./visitsRegions";
import VisitsCountries from "./visitsCountries";
import CategoryStatistics from "./categoryStatistics";
import InterestStatistics from "./interestStatistics";

type Props = {};

const ReportGrids = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {
		reports: {
			categories,
			interests,
			kpi,
			visitsCountries,
			visitsDay,
			visitsHalfHour,
			visitsHour,
			visitsRegion,
			visitsTotal,
			defaultPartnerVisits
		},
		loading,
		isCoorganization,
		generalReport
	} = props
	return (
		<Grid container spacing={2} mt={1}>
			{isCoorganization && (
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Accordion
						defaultExpanded
						// expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Toolbar disableGutters variant={"dense"}>
								<Typography variant="h6" sx={{textAlign: "center"}}>
									{t('Coorganization KPIs')}
								</Typography>
							</Toolbar>
						</AccordionSummary>
						<AccordionDetails>
							<KpisCoorganization
								data={kpi}
								loading={loading}
								t={t}
								generalReport={generalReport}
							/>
						</AccordionDetails>
					</Accordion>
				</Grid>
			)}
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					defaultExpanded={!isCoorganization}
					// expanded={expanded === 'panel0'} onChange={handleChange('panel0')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Exhibition KPIs')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<KpisExhibition
							data={kpi}
							loading={loading}
							t={t}
							isCoorganization={isCoorganization}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Total Entries')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<VisitsTotal
							data={visitsTotal}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Entries per Date')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<VisitsDay
							data={visitsDay}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Entries per Hour')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<VisitsHour
							data={visitsHour}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Entries per Half Hour')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<VisitsHalfHour
							data={visitsHalfHour}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Entries per Region')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<VisitsRegions
							data={visitsRegion}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Entries per Country')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<VisitsCountries
							data={visitsCountries}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Visitor Categories')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<CategoryStatistics
							data={categories}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion
					// expanded={expanded === 'panel9'} onChange={handleChange('panel9')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Profession Domains')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<InterestStatistics
							data={interests}
							loading={loading}
							t={t}
							generalReport={generalReport}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{textAlign: "center"}}>
								{t('Default Partner Badge Scans')}
							</Typography>
						</Toolbar>
					</AccordionSummary>
					<AccordionDetails>
						<DefaultPartnerScans
							data={defaultPartnerVisits}
							loading={loading}
							t={t}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
		</Grid>
	);
};

export default ReportGrids