import * as ActionTypes from '../../actions/types'
import {mapUser} from "../../types/user/map";
import {fromJS} from "immutable";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";
import {getRouteUrl} from "../../helpers/getRouteUrl";

const INITIAL_STATE = {
    loading: false,
    errorLogin: null,
    errorRegister: null,
    errorConnectivity: null,
    registeredSuccessfully: null,
    token: null,
    tokenExpirationDateTime: null,
    data: null,
    isLoggedIn: false,
    language: 'en',
    decimalSeparator: ',',
    thousandSeparator: '.',
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    changeFormatsWithLang: true,
}

function setUserLanguage(state, action) {
    return state
    .set('language', action.payload)
    .set('decimalSeparator', state.get('changeFormatsWithLang') ? action.payload === 'el' ? ',' : '.' : state.get('decimalSeparator'))
    .set('thousandSeparator', state.get('changeFormatsWithLang') ? action.payload === 'el' ? '.' : ',' : state.get('thousandSeparator'))
    .set('dateFormat', state.get('changeFormatsWithLang') ? action.payload === 'el' ? 'DD/MM/YYYY' : 'MM/DD/YYYY' : state.get('dateFormat'))
    .set('timeFormat', state.get('changeFormatsWithLang') ? action.payload === 'el' ? 'HH:mm' : 'HH:mm' : state.get('timeFormat'))
}

function checkAPIConnectivity(state) {
    return state
        .set('loading', true)
        .set('errorLogin', null)
}

function checkAPIConnectivitySucceeded(state) {
    return state
        .set('loading', false)
        .set('errorConnectivity', null)
        .set('errorLogin', null)
}

function checkAPIConnectivityFailed(state) {
    return state
        .set('loading', false)
        .set('errorConnectivity', 'Access failed!')
}

function userLogin(state) {
    return state
        .set('errorLogin', null)
        .set('token', null)
        .set('loading', true)
        .set('selectedStore', null)
        .set('isLoggedIn', false)
}

function userLoginSucceeded(state, action) {
    const {access_token, expires_in, langcode, loginname, username} = action.payload
    const tokenExpirationDateTime = new Date()
    tokenExpirationDateTime.setSeconds(tokenExpirationDateTime.getSeconds() + expires_in)
    const user = mapUser({
        locale: langcode,
        LoginName: loginname,
        username: username
    })
    return state
        .set('errorLogin', null)
        .set('loading', false)
        .set('token', access_token)
        .set('tokenExpirationDateTime', tokenExpirationDateTime)
        .set('isLoggedIn', true)
        .set('data', fromJS(user))
        // .set('language', langcode?.toLowerCase())
}

function userLoginFailed(state, action) {
    const {message} = action.payload
    return state
        .set('errorLogin', message)
        .set('token', null)
        .set('loading', false)
        .set('isLoggedIn', false)
}

function clearRegisteredSuccessfullyFlag(state, action) {
    return state
        .set('registeredSuccessfully', null)
}

function userLogoutSucceeded(state, action) {
    window.location.href = getRouteUrl(ROUTE_PAGE_LOGIN)
}

const currentUser = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
        [ActionTypes.CHECK_API_CONNECTIVITY_SUCCEEDED]: checkAPIConnectivitySucceeded,
        [ActionTypes.CHECK_API_CONNECTIVITY_FAILED]: checkAPIConnectivityFailed,

        [ActionTypes.USER_LOGIN]: userLogin,
        [ActionTypes.USER_LOGIN_SUCCEEDED]: userLoginSucceeded,
        [ActionTypes.USER_LOGIN_FAILED]: userLoginFailed,
        [ActionTypes.USER_LOGOUT_SUCCEEDED]: userLogoutSucceeded,

        [ActionTypes.CLEAR_REGISTERED_SUCCESSFULLY_FLAG]: clearRegisteredSuccessfullyFlag,

        [ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,
    }
}

export default currentUser