import ENGLISH_FLAG from './flags/gb.png'
import GREEK_FLAG from './flags/gr.png'
import KIT_EN from './images/marketingKit/kit_en.png'
import KIT_EL from './images/marketingKit/kit_gr.png'

const Images = {
	englishFlag: ENGLISH_FLAG,
	greekFlag: GREEK_FLAG,
	kitEn: KIT_EN,
	kitEl: KIT_EL
}

export default Images