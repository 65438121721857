// @flow
import React from 'react';
import Grid from "@mui/material/Grid";
import {FormikProvider} from "formik";
import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {TabPanel} from "../../../../../components/general/tabPanel/tabPanel";
import useUITranslations from "../../../../../hooks/uiTranslations/useUITranslations";
import CodeSelect from "./codeSelect";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import EditorByLanguage from "./editorByLanguage";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";

type Props = {};

const MessageCustomizer = (props: Props) => {
	const {exhibitionId} = props
	const {t} = useTranslation(EXHIBITION)
	const {loading, codes, code, onChangeCode, tab, handleChangeTab, formik} = useUITranslations({exhibitionId})
	return (
		<>
			<div className={'actionBuilder'}>
				<FormikProvider value={formik}>
					<Grid container sx={{backgroundColor: 'white', padding: 1}}>
						<Grid item xs={12}>
							{codes && (
								<CodeSelect
									label={t('Select Message')}
									codes={codes}
									code={code}
									onChangeCode={onChangeCode}
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<Box component="form" onSubmit={formik.handleSubmit} sx={{mb: 2}}>
								<Tabs
									variant="scrollable"
									value={tab}
									onChange={handleChangeTab}
								>
									<Tab value={0} label={normalizeGreek(t('English'))}/>
									<Tab value={1} label={normalizeGreek(t('Greek'))}/>
								</Tabs>
								<TabPanel isVisible={tab === 0}>
									<EditorByLanguage
										language={'en'}
										t={t}
									/>
								</TabPanel>
								<TabPanel isVisible={tab === 1}>
									<EditorByLanguage
										language={'el'}
										t={t}
									/>
								</TabPanel>
							</Box>
						</Grid>
					</Grid>
				</FormikProvider>
			</div>
			<ContentLoader loading={loading} useCircularLoader/>
		</>
	)
};

export default MessageCustomizer