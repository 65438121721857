// @flow
import * as React from 'react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Field, FormikProvider, useFormik} from "formik";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import {PAGE_SETTINGS} from "../../../../../locales/pages/namespaces";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../../../../helpers/constants";
import useUserData from "../../../../../hooks/user/useUserData";
import TextInput from "../../../../../components/form/textfield/textfield";
import {FormControl} from "@mui/material";

type Props = {};

export const ResendEmailOrSmsPopover = (props: Props) => {
    const {token} = useUserData()
    const {resendEmailOrSMS, handleResendEmailOrSMS, isSMS, refreshGrid, handleAlert} = props
    const {t} = useTranslation([PAGE_SETTINGS])

    const onSubmit = (values) => {
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/en/exhibition/messages/copy/${resendEmailOrSMS.messageId}`,
            data: {
                Email: values.email,
                MobilePhone: values.mobilePhone
            }
        }).then((response) => {
            handleAlert({
                type: 'success',
                message: t('Email Submitted')
            })
            handlePopoverClose()
            refreshGrid()
        }).catch((error) => {
            handleAlert({
                type: 'failure',
                message: error?.message
            })
        })
    }

    const validationSchema = yup.object({
        email: yup
            .string()
            .email(t('Enter a valid email')),
        mobilePhone: yup
            .string()
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            mobilePhone: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit
    })

    const handlePopoverClose = () => {
        handleResendEmailOrSMS({anchorEl: null, open: false, messageId: ''});
        formik.resetForm()
    }

    return (
        <React.Fragment>
            <Box onClick={(e) => e.stopPropagation()}>
                <Popover
                    disableRestoreFocus
                    onClose={handlePopoverClose}
                    open={resendEmailOrSMS.open}
                    anchorEl={resendEmailOrSMS.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <FormikProvider value={formik}>
                        <Box component="form" onSubmit={formik.handleSubmit} sx={{width: '100%', minWidth: '300px'}}>
                            <Box  sx={{width: '100%', padding: '20px'}}>
                                {isSMS ?
                                    <FormControl variant="standard" color={'error'} fullWidth>
                                        <Field
                                            size={"small"}
                                            name={'mobilePhone'}
                                            component={TextInput}
                                            label={t("Mobile Phone")}
                                        />
                                    </FormControl>
                                    :
                                    <FormControl variant="standard" color={'error'} fullWidth>
                                        <Field
                                            size={"small"}
                                            name={'email'}
                                            label={t("Email")}
                                            component={TextInput}
                                        />
                                    </FormControl>
                                }
                            </Box>
                            <Box sx={{
                                padding: '0 20px 20px',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    size={"small"}
                                    type={"submit"}
                                    variant={"contained"}
                                    startIcon={<SendIcon/>}
                                >
                                    {t('Send')}
                                </Button>
                                <Button
                                    size={"small"}
                                    variant={"outlined"}
                                    startIcon={<CancelIcon/>}
                                    type={"button"}
                                    onClick={handlePopoverClose}
                                >
                                    {normalizeGreek(t('Cancel'))}
                                </Button>
                            </Box>
                        </Box>
                    </FormikProvider>
                </Popover>
            </Box>
        </React.Fragment>
    );
};