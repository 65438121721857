import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import {Checkbox, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../../../locales/components/namespaces";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BusinessCategoriesList from "../../../../../../components/app/businessCategoriesList/businessCategoriesList";
import {useExhibitionBusinessCategories} from "../../../../../../hooks/exhibitions/useExhibitionBusinessCategories";
import useUserData from "../../../../../../hooks/user/useUserData";

type Props = {
    exhibitionId?: string,
    onChange: Function,
    selected: Array<string>
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const showOption = (language) => (option) => {
    if (typeof option === 'string') {
        return option
    }
    if (typeof option.description === 'string') {
        return option.description
    }
    return option.description[language]
}

const SelectExhibitionBusinessCategoriesInner = ({
                                                     loading,
                                                     options,
                                                     value,
                                                     onChange
                                                 }) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {language} = useUserData()
    return (
        <Box sx={{width: '100%'}}>
            <Autocomplete
                multiple
                options={options}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                getOptionLabel={showOption(language)}
                renderOption={(props, option, {selected}) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                        />
                        {showOption(language)(option)}
                    </li>
                )}
                value={value}
                onChange={(event, newValue) => {
                    onChange(newValue)
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableCloseOnSelect
                limitTags={2}
                loading={loading}
                size="small"
                sx={{mt: 1}}
                fullWidth
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'none',
                        }}
                        label={t("Business Categories")}
                    />
                )}
            />
            <BusinessCategoriesList
                items={value}
                onRemove={(i) => onChange(value.filter(j => j !== i))}
            />
        </Box>
    )
}

const SelectExhibitionBusinessCategories = (props: Props) => {
    const {onChange, selected, exhibitionId} = props
    const {businessCategories, loading} = useExhibitionBusinessCategories({id: exhibitionId})
    return (
        <SelectExhibitionBusinessCategoriesInner
            loading={loading}
            value={selected}
            onChange={onChange}
            options={businessCategories}
        />
    )
}

export default SelectExhibitionBusinessCategories
