// @flow
import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import Form from "./partials/form";
import ExcelImport from './partials/excelImport'
import useBadgeAction from "../../../../hooks/badges/useBadgeAction";
import useBadgeData from "../../../../hooks/badges/useBadgeData";
import FooterImage from "./partials/footerImage";
import useExhibitionAction from "../../../../hooks/exhibitions/useExhibitionAction";
import ExhibitionUpdateStatus from "../../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import List from "@mui/material/List";

type Props = {};

const QuickBadgePrint = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {exhibition, loading, id} = useExhibition()
	const {updateQuickPrintFooterImage, deleteQuickPrintFooterImage} = useExhibitionAction()
	const {loading: templatesLoading, badgeTemplateTypes} = useBadgeData()
	const {getBadgeTemplateTypes} = useBadgeAction()
	const [showImport, setShowImport] = useState(false)
	useEffect(() => {
		getBadgeTemplateTypes()
	}, [getBadgeTemplateTypes])
	return exhibition && badgeTemplateTypes.length > 0 ? (
		<Box m={1}>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
					{exhibition.title}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
					{t('Settings')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('Quick Print Badge')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 2, p: 1}} variant={"outlined"}>
				<Typography component={"div"} variant={"body1"}>
					<Trans
						i18nKey={'quick_badge_info'}
						ns={EXHIBITION}
					>
						On this page you can quick print simple <strong>badges</strong> with default template and
						size.<br/>
						Press the <strong>IMPORT FROM EXCEL</strong> button to import multiple badges
						or fill in the form for a single badge and press <strong>PRINT</strong> button.
						<br/><br/>
						<strong>Suggested Footer Image Dimensions: 1000x212.</strong>
						<br/><br/>
						<strong>Attention:</strong> Follow the import guidelines for successful badge
						upload. Please do not close the window before printing process finishes.<br/>
					</Trans>
				</Typography>
			</Paper>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					justifyContent: 'space-between',
					flexWrap: 'wrap'
				}}
			>
				<Form
					id={id}
					badgeTemplateTypes={badgeTemplateTypes}
					setShowImport={setShowImport}
				/>
				<Paper sx={{mb: 2, p: 1, width: {sm: '100%', md: '49%', lg: '30%'}}} variant={"outlined"}>
					<List>
						<FooterImage
							language={"el"}
							id={id}
							exhibition={exhibition}
							updateQuickPrintFooterImage={updateQuickPrintFooterImage}
							deleteQuickPrintFooterImage={deleteQuickPrintFooterImage}
						/>
						<FooterImage
							language={"en"}
							id={id}
							exhibition={exhibition}
							updateQuickPrintFooterImage={updateQuickPrintFooterImage}
							deleteQuickPrintFooterImage={deleteQuickPrintFooterImage}
						/>
					</List>
				</Paper>
			</Box>
			<ExcelImport
				show={showImport}
				handleDialog={(newValue) => setShowImport(newValue)}
				exhibition={exhibition}
				badgeTemplateTypes={badgeTemplateTypes}
			/>
			<ExhibitionUpdateStatus/>
		</Box>
	) : (
		<ContentLoader
			loading={loading || templatesLoading}
		/>
	)
};

export default QuickBadgePrint