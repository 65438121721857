// @flow
import React from 'react';
import type {Exhibition} from "../../../types/exhibition/types";
import {useCountries} from "../../../hooks/countries/useCountries";
import {useInterests} from "../../../hooks/interests/useInterests";
import {useCategories} from "../../../hooks/categories/useCategories";
import Form from "./form";

type Props = {
    id: string,
    exhibition: Exhibition
};

const VisitorForm = ({id, exhibition, preregistrationFormValues}: Props) => {
    const {countries, phoneCodes, defaultCountry, defaultPhoneCode} = useCountries()
    const {interests} = useInterests(exhibition.id)
    const {categories} = useCategories(exhibition.id)

    return exhibition && countries && phoneCodes && defaultPhoneCode && defaultPhoneCode && (
        <Form
            id={id}
            exhibition={exhibition}
            countries={countries}
            interests={interests}
            phoneCodes={phoneCodes}
            defaultCountry={defaultCountry}
            defaultPhoneCode={defaultPhoneCode}
            categories={categories}
            preregistrationFormValues={preregistrationFormValues}
        />
    );
};

export default VisitorForm