// @flow
import { ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CopyToClipboard from "../../../../../components/general/copyToClipboard/copyToClipboard";
import { getRouteUrl } from "../../../../../helpers/getRouteUrl";
import { normalizeGreek } from "../../../../../helpers/normalizeGreek";
import { EXHIBITION } from "../../../../../locales/components/namespaces";
import { ROUTE_PAGE_EXHIBITION_SETTINGS_KIT_AD_LOGOS } from "../../../../../routers/routes";
import ButtonColor from "./components/buttonColor";
import DisplayExhibitorKitAndAdsToExhibitor from "./components/displayExhibitorKitAndAdsToExhibitor.js";
import DisplayPartnersToExhibitor from "./components/displayPartnersToExhibitor";
import DisplayServiceProvidersToExhibitor from "./components/displayServiceProvidersToExhibitor";
import DisplayVisitorsToExhibitor from "./components/displayVisitorsToExhibitor";
import ExhibitorInvitesVisitorsWithBarcode from "./components/exhibitorInvitesVisitorsWithBarcode";
import PartnerNumber from "./components/PartnerNumber";

type Props = {};

const ExhibitorSettings = (props: Props) => {
	const {id, exhibition, updateExhibition} = props
	const {t} = useTranslation(EXHIBITION)
	const history = useHistory()
	return (
		<List
			subheader={<ListSubheader sx={{backgroundColor: '#F9FBF2'}}><Typography variant={'button'}
																					sx={{color: '#000000'}}>{normalizeGreek(t('Exhibitors'))}</Typography></ListSubheader>}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<Box width={'100%'} p={1}>
				<Typography
					variant="body2"
					gutterBottom
					component="div">
					{t("Test Exhibitor Credentials")}
				</Typography>
				<Box>
					<Typography variant="body2" gutterBottom
								component="div">{t("Username")}</Typography>
					<CopyToClipboard
						text={`rotaexh${exhibition.id}`}
					/>
				</Box>
				<Box>
					<Typography variant="body2" gutterBottom
								component="div">{t("Password")}</Typography>
					<CopyToClipboard
						text={`r0t@exh@#$`}
					/>
				</Box>
			</Box>
			<PartnerNumber
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ButtonColor
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<DisplayVisitorsToExhibitor
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<DisplayPartnersToExhibitor
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<DisplayServiceProvidersToExhibitor
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<DisplayExhibitorKitAndAdsToExhibitor
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ExhibitorInvitesVisitorsWithBarcode
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_KIT_AD_LOGOS, {id: id}))
				}}
			>
				<ListItemText primary={t("Marketing Kit & Advertisement Logos")}/>
			</ListItem>
		</List>
	);
};

export default ExhibitorSettings