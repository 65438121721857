// @flow 
import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import {Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Select} from "@mui/material";
import ColorSelect from "../badgeForm/printable/colorSelect/colorSelect";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import Box from "@mui/material/Box";
import type {BadgeTemplateTypes} from "../../../types/badge/types";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";

type Props = {
    onClose: Function,
    badgeTemplateTypes: Array<BadgeTemplateTypes>
};

const Form = (props: Props) => {
    const {
        badgeTemplateTypes,
        onClose,
        initialValues,
        onSubmit,
        title
    } = props
    const {t} = useTranslation(EXHIBITION)

    const validationSchema = yup.object({
        textColor: yup
            .string()
            .required(t('required')),
        backgroundColor: yup
            .string()
            .required(t('required')),
        badgeTemplateTypeId: yup
            .string()
            .required(t('required')),
        regBadgeTemplateTypeId: yup
            .string()
            .required(t('required')),
        text: yup.mixed().test(
            'acceptedValues', t('required'), function (value) {
                return value && value?.en && value?.el;
            }
        ),
    })

    const formik = useFormik({
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: onSubmit,
    })

    return (
        <FormikProvider value={formik}>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Stack direction={"row"} spacing={6} sx={{mb: 1.5}}>
                        <FormControl
                            variant="standard"
                            fullWidth
                            error={
                                formik.touched["backgroundColor"] &&
                                Boolean(formik.errors["backgroundColor"])
                            }
                        >
                            <FormControlLabel
                                control={
                                    <ColorSelect
                                        color={formik.values.backgroundColor}
                                        onColorChange={(color) => formik.setFieldValue(`backgroundColor`, color)}
                                    />
                                }
                                label={t('Background Color')}
                                labelPlacement="start"
                            />
                            <FormHelperText>
                                <ErrorMessage name={"backgroundColor"}/>
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            variant="standard"
                            fullWidth
                            error={
                                formik.touched["textColor"] &&
                                Boolean(formik.errors["textColor"])
                            }
                        >
                            <FormControlLabel
                                control={
                                    <ColorSelect
                                        color={formik.values.textColor}
                                        onColorChange={(color) => formik.setFieldValue(`textColor`, color)}
                                    />
                                }
                                label={t('Text Color')}
                                labelPlacement="start"
                            />
                            <FormHelperText>
                                <ErrorMessage name={"textColor"}/>
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                            formik.touched["badgeTemplateTypeId"] &&
                            Boolean(formik.errors["badgeTemplateTypeId"])
                        }
                        sx={{mb: 2}}
                    >
                        <InputLabel id="demo-simple-select-helper-label">{t('Print Size')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            autoComplete={"chrome-off"}
                            size={"small"}
                            name={"badgeTemplateTypeId"}
                            value={formik.values.badgeTemplateTypeId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t('Print Size')}
                        >
                            {badgeTemplateTypes.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            <ErrorMessage name={"badgeTemplateTypeId"}/>
                        </FormHelperText>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                            formik.touched["regBadgeTemplateTypeId"] &&
                            Boolean(formik.errors["regBadgeTemplateTypeId"])
                        }
                        sx={{mb: 2}}
                    >
                        <InputLabel id="demo-simple-s">{t('Registration Print Size')}</InputLabel>
                        <Select
                            labelId="demo-simple-s"
                            autoComplete={"chrome-off"}
                            size={"small"}
                            name={"regBadgeTemplateTypeId"}
                            value={formik.values.regBadgeTemplateTypeId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t('Registration Print Size')}
                        >
                            {badgeTemplateTypes.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            <ErrorMessage name={"regBadgeTemplateTypeId"}/>
                        </FormHelperText>
                    </FormControl>
                    <FormControl
                        variant="standard"
                        fullWidth
                        error={
                            formik.touched["text"] &&
                            Boolean(formik.errors["text"])
                        }
                        sx={{mb: 2}}
                    >
                        <TextField
                            id="outlined-name"
                            label={t('Greek Category Description')}
                            value={formik.values.text.el}
                            name={`text.el`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            size={'small'}
                            required
                            inputProps={{ maxLength: 150 }}
                        />
                        <FormHelperText>
                            <ErrorMessage name={"text"}/>
                        </FormHelperText>
                    </FormControl>
                    <FormControl
                        variant="standard"
                        fullWidth
                        error={
                            formik.touched["text"] &&
                            Boolean(formik.errors["text"])
                        }
                        sx={{mb: 2}}
                    >
                        <TextField
                            id="outlined-name"
                            label={t('English Category Description')}
                            value={formik.values.text.en}
                            name={`text.en`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            size={'small'}
                            required
                            inputProps={{ maxLength: 150 }}
                        />
                        <FormHelperText>
                            <ErrorMessage name={"text"}/>
                        </FormHelperText>
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <TextField
                            id="outlined-name"
                            label={t('Greek Category Explanation')}
                            value={formik.values.explanation.el}
                            name={`explanation.el`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            size={'small'}
                            sx={{mb: 2}}
                            inputProps={{ maxLength: 150 }}
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <TextField
                            id="outlined-name"
                            label={t('English Category Explanation')}
                            value={formik.values.explanation.en}
                            name={`explanation.en`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            size={'small'}
                            sx={{mb: 2}}
                            inputProps={{ maxLength: 150 }}
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.displayVisitorLabelInBadge}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    size={'small'}
                                    name={'displayVisitorLabelInBadge'}
                                />
                            }
                            label={t('Display Visitor Label In Badge')}
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.displayBadgeInEnglish}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    size={'small'}
                                    name={'displayBadgeInEnglish'}
                                />
                            }
                            label={t('Display Badge Only In English')}
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.showPreregistration}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    size={'small'}
                                    name={'showPreregistration'}
                                />
                            }
                            label={t('Visible In Preregistration')}
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.showOrganizer}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    size={'small'}
                                    name={'showOrganizer'}
                                />
                            }
                            label={t('Visible To Organizer')}
                            labelPlacement="end"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} type={'submit'}>OK</Button>
                    <Button onClick={() => {
                        formik.resetForm();
                        onClose();
                    }}>{normalizeGreek(t('Cancel'))}</Button>
                </DialogActions>
            </Box>
        </FormikProvider>
    );
};

export default Form