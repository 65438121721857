// @flow
import React from 'react';
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";

type Props = {};

const DisplayEmailAsHtmlPopOver = ({displayEmailAsHtml, handleDisplayEmailAsHtml}: Props) => {

    const handlePopoverClose = () => {
        handleDisplayEmailAsHtml({anchorEl: null, open: false, body: ''})
    }

    return (
        <React.Fragment>
            <Box onClick={(e) => e.stopPropagation()}>
                <Popover
                    disableRestoreFocus
                    onClose={handlePopoverClose}
                    open={displayEmailAsHtml.open}
                    anchorEl={displayEmailAsHtml.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <Box sx={{width: '100%', padding: '20px'}}>
                        <span dangerouslySetInnerHTML={{__html: displayEmailAsHtml.body}}/>
                    </Box>
                </Popover>
            </Box>
        </React.Fragment>
    );
};

export default DisplayEmailAsHtmlPopOver