// @flow
import React, {useEffect, useMemo, useState} from 'react';
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import Filters from "./filters";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import useExhibitionAction from "../../../hooks/exhibitions/useExhibitionAction";
import useExhibitionsData from "../../../hooks/exhibitions/useExhibitionsData";
import {enqueueSnackbar} from "notistack";
import List from "@mui/material/List";
import ExhibitionGroupedHeadersPreview from "../../../components/app/exhibitionPreview/exhibitionGroupedHeadersPreview";
import Grid from "@mui/material/Grid";
import ContentLoader from "../../../components/general/contentLoader/contentLoader";
import ExhibitionPreview from "../../../components/app/exhibitionPreview/exhibitionPreview";
import {filterExhibitions, filterHeaders} from "../../../helpers/filterExhibitions";
import FilterHeaders from "./filtersHeaders";

type Props = {};

const OrganizerView = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const [filterFunctionParameters, setFilterFunctionParameters] = useState({})
	const [filterHeadersParameters, setFilterHeadersParameters] = useState({})
	const [selected, setSelected] = useState('')
	const {getExhibitionsGroupedDetails, getExhibitionsGroupedHeaders, setExhibitionMessage} = useExhibitionAction()
	const {exhibitionsGroupedHeaders, message, loadingGroupedHeaders, indexTable} = useExhibitionsData()
	const items = useMemo(() => (filterExhibitions(indexTable.items, filterFunctionParameters)), [filterFunctionParameters, indexTable.items])
	const headers = useMemo(() => (filterHeaders(exhibitionsGroupedHeaders, filterHeadersParameters)), [exhibitionsGroupedHeaders, filterHeadersParameters])
	
	useEffect(() => {
		getExhibitionsGroupedHeaders()
	}, [getExhibitionsGroupedHeaders])
	
	useEffect(() => {
		if (headers.length > 0 && headers[0] && headers[0].DescriptionEN) {
			getExhibitionsGroupedDetails(headers[0].DescriptionEN)
			setSelected(headers[0].DescriptionEN)
		}
	}, [headers, getExhibitionsGroupedDetails, getExhibitionsGroupedHeaders])
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setExhibitionMessage(null)
		}
	}, [message, setExhibitionMessage, t])
	
	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={5} lg={4} sx={{background: '#FFFFFF'}}>
					<Typography variant={"subtitle2"} sx={{mb: -1.25, pt: 2}}>
						<FilterListIcon fontSize={"inherit"}/> <span>{t('FILTER BY')}</span>
					</Typography>
					<FilterHeaders
						onFilterChange={setFilterHeadersParameters}
					/>
					<Divider sx={{mb: 2}}/>
					{!loadingGroupedHeaders ? (
						<List
							sx={{
								height: {
									xs: '300px',
									sm: '300px',
									md: 'inherit'
								},
								overflowY: {
									xs: 'auto',
									sm: 'auto',
									md: 'inherit'
								},
							}}
						>
							{headers.map(i => (
								<ExhibitionGroupedHeadersPreview
									selected={selected}
									setSelected={setSelected}
									key={i.DescriptionEN}
									header={i.DescriptionEN}
									description={i.description}
									getExhibitionsGroupedDetails={getExhibitionsGroupedDetails}
								/>
							))}
						</List>
					) : (
						<ContentLoader
							loading={loadingGroupedHeaders}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={7} lg={8} sx={{background: 'rgba(243,229,245,0.4)'}}>
					<Typography variant={"subtitle2"} sx={{mb: -1.25, pt: 2}}>
						<FilterListIcon fontSize={"inherit"}/> <span>{t('FILTER BY')}</span>
					</Typography>
					<Filters
						onFilterChange={setFilterFunctionParameters}
					/>
					<Divider sx={{mb: 2}}/>
					
					{!indexTable.loading ? (
						<List>
							{items.map(i => (
								<ExhibitionPreview
									key={i.id}
									exhibition={i}
								/>
							))}
						</List>
					) : (
						<ContentLoader
							loading={loadingGroupedHeaders}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	)
};

export default OrganizerView