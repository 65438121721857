// @flow
import React, {Fragment, useEffect, useState} from 'react';
import * as yup from 'yup'
import {FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {normalizeGreek} from "../../helpers/normalizeGreek";
import Typography from "@mui/material/Typography";

const Login = () => {
    const {t} = useTranslation(PAGE_LOGIN)
    const [forgotPassword, setForgotPassword] = useState(false)
    const {loading, errorLogin, errorConnectivity} = useUserData()
    const {userLogin, checkAPIConnectivity} = useUserAction()
    useEffect(() => {
        checkAPIConnectivity()
    }, [checkAPIConnectivity])
    
    const onSubmit = (values) => {
        userLogin(values)
    }
    const validationSchema = yup.object({
        username: yup
            .string()
            .required(t('required')),
        password: yup
            .string()
            .required(t('required')),
    })
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            rememberMe: false,
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })
    return (
        <Box sx={{mt: 8}}>
            <FormikProvider value={formik}>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
                    {!forgotPassword && (
                        <Fragment>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label={t("Username")}
                                id={'username'}
                                name={'username'}
                                autoComplete={'username'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.username}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label={t("Password")}
                                autoComplete="current-password"
                                type={'password'}
                                id={'password'}
                                name={'password'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            {/*<Grid container>*/}
                            {/*    <Grid item xs>*/}
                            {/*        <Button onClick={() => setForgotPassword(true)}>*/}
                            {/*            Forgot password?*/}
                            {/*        </Button>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                {normalizeGreek(t('Sign In'))}
                            </Button>
                            {loading && <div>{t('loading')}...</div>}
                            {errorLogin && (
                                <Box sx={{'maxWidth': '500px'}} color={'error'}>
                                    <Typography variant="body1" component="div" color={'error'} sx={{mr: 1}}>
                                        <strong>{t(errorLogin)}</strong>
                                    </Typography>
                                </Box>
                            )}
                            {errorConnectivity && (
                                <Box sx={{'maxWidth': '500px'}} color={'error'}>
                                    <Typography variant="body1" component="div" color={'error'} sx={{mr: 1}}>
                                        <strong>{t(errorConnectivity)}</strong>
                                    </Typography>
                                </Box>
                            )}
                        </Fragment>
                    )}
                    {/*{forgotPassword && (*/}
                    {/*    <Fragment>*/}
                    {/*        <Typography variant="body">*/}
                    {/*            {t('reset_password_message')}*/}
                    {/*        </Typography>*/}
                    {/*        <TextField*/}
                    {/*            margin="normal"*/}
                    {/*            required*/}
                    {/*            fullWidth*/}
                    {/*            autoComplete="email"*/}
                    {/*            autoFocus*/}
                    {/*            label="Email"*/}
                    {/*            type={'email'}*/}
                    {/*            id={'email'}*/}
                    {/*            name={'email'}*/}
                    {/*            onChange={formik.handleChange}*/}
                    {/*            onBlur={formik.handleBlur}*/}
                    {/*            value={formik.values.email}*/}
                    {/*        />*/}
                    {/*        <Stack direction="row" spacing={1}>*/}
                    {/*            <Button type="submit">*/}
                    {/*                {t('reset')}*/}
                    {/*            </Button>*/}
                    {/*            <Button onClick={() => setForgotPassword(false)}>*/}
                    {/*                {t('CANCEL')}*/}
                    {/*            </Button>*/}
                    {/*        </Stack>*/}
                    {/*    </Fragment>*/}
                    {/*)}*/}
                </Box>
            </FormikProvider>
        </Box>
    )
}

export default Login