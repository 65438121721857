//@flow
import React from 'react';
import Advanced from "./Sortly/Advanced/Advanced";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import ContextProvider from "./Sortly/ContextProvider";

export type Props = {
    items: Array<{
        id: string,
        depth: number,
        data: Object
    }>,
    onDragEnd: Function,
    renderItemTitle: Function,
    renderItemActions: Function,
}


const DragDropTree = (props: Props) => (
    <DndProvider backend={HTML5Backend}>
        <ContextProvider>
            <Advanced
                {...props}
            />
        </ContextProvider>
    </DndProvider>
)

export default DragDropTree
