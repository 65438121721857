import {useEffect, useState} from "react";
import useUserData from "../user/useUserData";
import axios from "axios";
import {API} from "../../helpers/constants";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";
import {mapVisitorCategory} from "../../types/categories/map";

const useVisitorCategories = (exhibitionId) => {
    const {token} = useUserData()
    const {t} = useTranslation(EXHIBITION)
    const [loading, setLoading] = useState(false)
    const [refreshGrid, setRefreshGrid] = useState(false)
    const [visitorCategories, setVisitorCategories] = useState([])
    const [message, setMessage] = useState(null)

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/en/exhibition/characterizations/${exhibitionId}`
        }).then((response) => {
            setVisitorCategories(mapVisitorCategory(response.data))
        }).catch(() => {
            setLoading(false)
        })
        setLoading(false)
    }, [exhibitionId, token, refreshGrid])

    const saveVisitorCategory = (values) => {
        setLoading(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/en/exhibition/characterizations/store`,
            data: {
                ExhibitionId: exhibitionId,
                DescriptionEN: values.text.en,
                DescriptionEL: values.text.el,
                ExplanationEN: values.explanation.en,
                ExplanationEL: values.explanation.el,
                Color2: values.textColor,
                Color1: values.backgroundColor,
                displayBadgeInEnglish: values.displayBadgeInEnglish,
                hasVisitorText: values.displayVisitorLabelInBadge,
                showPreregistration: values.showPreregistration,
                showOrganizer: values.showOrganizer,
                BadgeTemplateTypeId: values.badgeTemplateTypeId,
                RegBadgeTemplateTypeId: values.regBadgeTemplateTypeId
            }
        }).then((response) => {
            setRefreshGrid(!refreshGrid)
            setMessage({message: t('Visitor Category Submitted')})
        }).catch((error) => {
            setLoading(false)
            setMessage({message: t('Visitor Category Submit Failed')})
        })
        setLoading(false)
    }

    const updateVisitorCategory = (id, values) => {
        setLoading(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/en/exhibition/characterizations/update/${id}`,
            data: {
                DescriptionEN: values.text.en,
                DescriptionEL: values.text.el,
                ExplanationEN: values.explanation.en,
                ExplanationEL: values.explanation.el,
                Color2: values.textColor,
                Color1: values.backgroundColor,
                hasVisitorText: values.displayVisitorLabelInBadge,
                displayBadgeInEnglish: values.displayBadgeInEnglish,
                showPreregistration: values.showPreregistration,
                showOrganizer: values.showOrganizer,
                BadgeTemplateTypeId: values.badgeTemplateTypeId,
                RegBadgeTemplateTypeId: values.regBadgeTemplateTypeId
            }
        }).then((response) => {
            setRefreshGrid(!refreshGrid)
            setMessage({message: t('Visitor Category Updated')})
        }).catch((error) => {
            setLoading(false)
            setMessage({message: t('Visitor Category Update Failed')})
        })
        setLoading(false)
    }

    const deleteVisitorCategory = (id) => {
        setLoading(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/en/exhibition/characterizations/delete/${id}`
        }).then((response) => {
            setRefreshGrid(!refreshGrid)
            setMessage({message: t('Visitor Category Deleted')})
        }).catch((error) => {
            setLoading(false)
            setMessage({message: t('Visitor Category Delete Failed')})
        })
        setLoading(false)
    }

    const onAlertClose = () => setMessage(null)

    return {
        loading,
        visitorCategories,
        saveVisitorCategory,
        updateVisitorCategory,
        deleteVisitorCategory,
        message,
        onAlertClose
    }
}

export default useVisitorCategories