import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapBadge, mapBadgeConfiguration, mapBadgeTemplateTypes} from "../../types/badge/map";
import {mapMultiple} from "../../helpers/map";

const INITIAL_STATE = {
    loading: false,
    scannedBadgeId: null,
    badgeById: {},
    badgeConfigurationByExhibitionId: {},
    scanBadgeStatus: null,
    badgeTemplateTypes: []
}

function clearScannedBadge(state, action) {
    return state
        .set('scannedBadgeId', null)
        .set('scanBadgeStatus', null)
}

function registerNewScannedBadge(state, action) {
    return state
        .set('scannedBadgeId', 'new')
}

function getBadge(state) {
    return state
        .set('loading', true);
}

function getBadgeSucceeded(state, action) {
    const {payload: datum, parameters} = action
    const data = datum.Data[0]
    const exhibition = datum.Exhibition[0]
    const referencedBy = datum.ReferencedBy[0]
    const unMappedData = {
        id: parameters.id,
        data: data,
        type: datum.Type,
        exhibition: exhibition,
        referencedBy: referencedBy
    }
    const mappedData = mapBadge(unMappedData)
    return state
        .set('loading', false)
        .setIn(['badgeById', mappedData.id], fromJS(mappedData))
}

function getBadgeFailed(state) {
    return state
        .set('loading', false)
}

function getBadgeConfiguration(state) {
    return state
        .set('loading', true)
}

function getBadgeConfigurationSucceeded(state, action) {
    const {parameters: {id}, payload} = action
    const unmappedData = payload[0]
    return state
        .set('loading', false)
        .setIn(['badgeConfigurationByExhibitionId', id], fromJS(mapBadgeConfiguration(unmappedData)))
}

function getBadgeConfigurationFailed(state) {
    return state
        .set('loading', false)
}

function updateBadgeConfiguration(state) {
    return state
        .set('loading', false)
}

function updateBadgeConfigurationSucceeded(state, action) {
    const {parameters: {id, ...otherParameters}} = action
    return state
        .set('loading', false)
        .setIn(['badgeConfigurationByExhibitionId', id], fromJS(mapBadgeConfiguration(otherParameters)))
}

function updateBadgeConfigurationFailed(state) {
    return state
        .set('loading', false)
}

function setVisitorApprovalStatus(state) {
    return state
        .set('loading', true);
}

function setVisitorApprovalStatusSucceeded(state, action) {
    const {parameters: {badgeId, status}} = action
    return state
        .set('loading', false)
        .setIn(['badgeById', badgeId, 'approved'], status)
}

function setVisitorApprovalStatusFailed(state, action) {
    return state
        .set('loading', false);
}

function badgeTemplateTypes(state) {
    return state
        .set('loading', true)
}

function badgeTemplateTypesSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapMultiple(payload, mapBadgeTemplateTypes)
    return state
        .set('loading', false)
        .set('badgeTemplateTypes', fromJS(mappedData))
}

function badgeTemplateTypesFailed(state, action) {
    return state
        .set('loading', false)
}

const badge = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.CLEAR_SCANNED_BADGE]: clearScannedBadge,
        [ActionTypes.REGISTER_NEW_SCANNED_BADGE]: registerNewScannedBadge,

        [ActionTypes.GET_BADGE]: getBadge,
        [ActionTypes.GET_BADGE_SUCCEEDED]: getBadgeSucceeded,
        [ActionTypes.GET_BADGE_FAILED]: getBadgeFailed,

        [ActionTypes.GET_BADGE_CONFIGURATION]: getBadgeConfiguration,
        [ActionTypes.GET_BADGE_CONFIGURATION_SUCCEEDED]: getBadgeConfigurationSucceeded,
        [ActionTypes.GET_BADGE_CONFIGURATION_FAILED]: getBadgeConfigurationFailed,

        [ActionTypes.UPDATE_BADGE_CONFIGURATION]: updateBadgeConfiguration,
        [ActionTypes.UPDATE_BADGE_CONFIGURATION_SUCCEEDED]: updateBadgeConfigurationSucceeded,
        [ActionTypes.UPDATE_BADGE_CONFIGURATION_FAILED]: updateBadgeConfigurationFailed,

        [ActionTypes.SET_VISITOR_APPROVAL_STATUS]: setVisitorApprovalStatus,
        [ActionTypes.SET_VISITOR_APPROVAL_STATUS_SUCCEEDED]: setVisitorApprovalStatusSucceeded,
        [ActionTypes.SET_VISITOR_APPROVAL_STATUS_FAILED]: setVisitorApprovalStatusFailed,

        [ActionTypes.GET_BADGE_TEMPLATE_TYPES]: badgeTemplateTypes,
        [ActionTypes.GET_BADGE_TEMPLATE_TYPES_SUCCEEDED]: badgeTemplateTypesSucceeded,
        [ActionTypes.GET_BADGE_TEMPLATE_TYPES_FAILED]: badgeTemplateTypesFailed,
    }
}

export default badge
