import {useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";
import axios from "axios";
import {API} from "../../helpers/constants";
import {printFile} from "../../helpers/fileHandle";
import useUserData from "../user/useUserData";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";

const useQuickBadgeImport = ({exhibitionId, onClose, badgeTemplateTypes}) => {
	const {token} = useUserData()
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation(EXHIBITION)
	const validationSchema = yup.object({
		files: yup
		.array()
		.min(1, t("required"))
		.required(t("required"))
	})
	
	const onSubmit = (values, {resetForm}) => {
		setLoading(true)
		axios({
			method: 'POST',
			headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`},
			url: `${API}/en/visitor/badge/quick-import/${exhibitionId}`,
			responseType: 'blob',
			data: {
				file: values.files?.[0],
				TemplateId: values.templateId
			}
		}).then(response => {
			const blob = new Blob([response.data], {type: 'application/pdf'});
			const url = window.URL.createObjectURL(blob);
			printFile(url)
			setLoading(false)
			resetForm()
			onClose()
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to print badge!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const formik = useFormik({
		initialValues: {
			files: [],
			templateId: badgeTemplateTypes.find(f => f.id === '2')?.id
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		enableReinitialize: true
	})
	
	const onFileChange = (val) => {
		formik.setFieldValue('files', val)
	}
	
	return {
		loading,
		formik,
		onFileChange
	}
}

export default useQuickBadgeImport