import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapInterests} from "../../types/interests/map";

const INITIAL_STATE = {
    loading: false,
    byId: []
}

function getInterests(state) {
    return state
        .set('loading', true)
}

function getInterestsSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapInterests(payload)
    return state
        .set('loading', false)
        .set('byId', fromJS(mappedData))

}

function getInterestsFailed(state) {
    return state.set('loading', false)
}

function updateInterests(state) {
    return state
        .set('loading', true)
}

function updateInterestsSucceeded(state, action) {
    const {interestId, Ordr, HidePreregistration} = action.parameters
    const interests = state.get('byId').toJS()
    const updateInterests = interests.map(interest => ({
        ...interest,
        weight: interest.id === interestId ? Ordr : interest.weight,
        hideInPreregistration: interest.id === interestId ? HidePreregistration : interest.hideInPreregistration,
    }))
    return state
        .set('loading', false)
        .set('byId', fromJS(updateInterests))
}

function updateInterestsFailed(state) {
    return state.set('loading', false)
}

const interests = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.GET_INTERESTS]: getInterests,
        [ActionTypes.GET_INTERESTS_SUCCEEDED]: getInterestsSucceeded,
        [ActionTypes.GET_INTERESTS_FAILED]: getInterestsFailed,
        [ActionTypes.UPDATE_INTERESTS]: updateInterests,
        [ActionTypes.UPDATE_INTERESTS_SUCCEEDED]: updateInterestsSucceeded,
        [ActionTypes.UPDATE_INTERESTS_FAILED]: updateInterestsFailed,
    },
}

export default interests