import React from 'react';
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import Toolbar from "@mui/material/Toolbar";
import {Stack} from "@mui/material";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

type Props = {
    submitForm: Function,
    formik: Object
};

const FormToolbar = ({formik, submitForm}: Props) => {
    const {t} = useTranslation(EXHIBITION)
    return (
        <Toolbar disableGutters variant={"dense"}>
            <Stack direction="row" spacing={'10px'} sx={{flexWrap: 'wrap', width: '100%'}}>
                <Button
                    size={"small"}
                    startIcon={<SaveIcon/>}
                    variant="contained"
                    onClick={submitForm}
                    color="success"
                    sx={{minHeight: '40px', marginRight: 'auto', minWidth: '120px'}}
                >
                    {normalizeGreek(t('Save'))}
                </Button>
            </Stack>
        </Toolbar>
    );
};

export default FormToolbar