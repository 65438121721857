import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import useUserData from "../../../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ErrorMessage, useFormikContext} from "formik";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import {showOption} from "./dropDown";
import {VisitorFormContext} from "../../form";

type Props = {

}

const SelectCountry = (props: Props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled, defaultCountry} = props
    const {language} = useUserData()
    const {t} = useTranslation(EXHIBITION)
    const {countries} = useContext(VisitorFormContext)
    const {setFieldValue} = useFormikContext()

    return (
        <Box sx={{width: '100%'}}>
            <Autocomplete
                options={countries}
                value={value}
                isOptionEqualToValue={(opt, val) => {return opt.id === val.id || !val}}
                getOptionLabel={showOption(language)}
                selectOnFocus
                handleHomeEndKeys
                onChange={(event, newValue) => {
                    setFieldValue("fieldValues." + field.id, newValue)
                }}
                autoHighlight={true}
                size="small"
                fullWidth
                renderInput={ (params) => (
                    <TextField
                        required={field.required}
                        {...params}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: "background.paper"
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            shrink: true,
                            startAdornment: value && value?.image && (
                                <img style={{width: '30px', paddingRight: '6px'}} src={value?.image}
                                     alt={value?.code}/>
                            ),
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                        }}
                        label={t("Country")}
                        error={touched["fieldValues"] &&
                            touched["fieldValues"][field.id] &&
                            Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])}
                        helperText={<ErrorMessage name={"fieldValues." + field.id}/>}
                    />
                )}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`${option.image} 2x`}
                            src={option.image}
                            alt=""
                        />
                        {showOption(language)(option)}
                    </Box>
                )}
            />
        </Box>
    )
}

export default SelectCountry