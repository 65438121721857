//@flow
import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import type {Exhibitor} from "../../../../../types/exhibition/types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TransferList from "./transferList";

type Props = {
    pending: Array<Exhibitor>,
    exhibitionId: string
}

const Areas = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {pending, exhibitionId} = props
    return (
        <Box>
            <Toolbar disableGutters variant={"dense"}>
                <Typography variant="h6">
                    {t('Areas')}
                </Typography>
            </Toolbar>
            <Divider sx={{mb: 1}} light/>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{mb: 2}}>
                        <TransferList
                            options={[
                                {id: 1, label: 'name 1'},
                                {id: 2, label: 'name 2'},
                            ]}
                            selected={[2]}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>

                </Grid>
            </Grid>
        </Box>
    )
}

export default Areas;