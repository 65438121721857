import type {FAQ} from "../../types/exhibition/types";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {parseFAQ, sortFAQs} from "../../types/exhibition/parse";
import {parseMultiple} from "../../helpers/parse";

const selectLoading = createSelector(
    (state) => state.faq,
    (item) => item.get('loading')
)

const selectLoadingFaq = createSelector(
    [
        (state) => state.faq.get('loadingFaq'),
        (state, id) => id,
    ],
    (data, id) => data.get(id)
)

const selectFaqsByExhibitionId = createSelector(
    [
        (state, id) => state.faq.getIn(['faqsByExhibitionId', id]),
        (state) => state.currentUser.get('language'),
        (state, id, language) => language
    ],
    (data, userLanguage, overrideLanguage) => sortFAQs(parseMultiple(data, (i) => parseFAQ(i, overrideLanguage ?? userLanguage)))
)

const selectById = createSelector(
    [
        (state, exhibitionId, faqId) => state.faq.getIn(['faqsByExhibitionId', exhibitionId, faqId]),
        (state) => state.currentUser.get('language'),
        (state, exhibitionId, faqId, language) => language
    ],
    (item, userLanguage, overrideLanguage) => parseFAQ(item, overrideLanguage ?? userLanguage)
)

export const useFAQData = ({exhibitionId, faqId, overrideLanguage} = {}) => {
    const loadingFaq: boolean = useSelector((state) => selectLoadingFaq(state, faqId))
    const faqs: Array<FAQ> = useSelector((state) => selectFaqsByExhibitionId(state, exhibitionId, overrideLanguage))
    const byId: FAQ = useSelector((state) => selectById(state, exhibitionId, faqId, overrideLanguage))
    const loading: boolean = useSelector(selectLoading)
    return {
        faq: byId,
        faqs,
        loading,
        loadingFaq
    }
}