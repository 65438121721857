// @flow
import React, {useMemo} from 'react';
import {showOption} from "../../../../helpers/helpers";
import {Checkbox, ListItemText, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useUserData from "../../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {GENERAL_SETTINGS} from "../../../../locales/components/namespaces";
import _ from "lodash";
import {formatDate} from "../../../../helpers/date";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";

type Props = {};

const SelectExhibitions = (props: Props) => {
        const {coorganizationId, exhibitions, addExhibitionToCoorganization, removeExhibitionFromCoorganization} = props
        const {language} = useUserData()
        const {t} = useTranslation(GENERAL_SETTINGS)
        const mappedExhibitions = useMemo(() => (
            exhibitions?.items.map(exhibition => ({
                id: exhibition.id,
                coorganizationId: exhibition.coorganizationId,
                description: `${exhibition.title} ${formatDate(exhibition.dateFrom, formatDate.formatTypes.DATE_ONLY)} - ${formatDate(exhibition.dateTo, formatDate.formatTypes.DATE_ONLY)}`,
                dateFrom: exhibition.dateFrom
            }))
        ), [exhibitions?.items])
        const value = useMemo(() => (mappedExhibitions.filter(f => f.coorganizationId === coorganizationId)), [coorganizationId, mappedExhibitions])

        return (
            <>
                <Autocomplete
                    multiple
                    options={_.orderBy(mappedExhibitions, 'dateFrom', 'desc')}
                    value={value}
                    isOptionEqualToValue={(opt, val) => {
                        return opt.id === val.id || !val
                    }}
                    getOptionLabel={showOption(language)}
                    selectOnFocus
                    clearOnBlur
                    disableCloseOnSelect
                    onChange={(evt, newValue, reason, details) => {
                        if (reason === 'removeOption') removeExhibitionFromCoorganization(details.option.id)
                        else addExhibitionToCoorganization(details.option.id, coorganizationId)
                    }}
                    autoHighlight={true}
                    size="small"
                    fullWidth
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            {showOption(language)(option)}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'none',
                            }}
                            label={t("Select Exhibitions")}
                        />
                    )}
                />
                <List dense sx={{maxHeight: 400, overflow: "auto"}}>
                    {value && value.length > 0 && value.map(i => (
                        <ListItem
                            divider
                            key={i.id}
                            secondaryAction={
                                (
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            removeExhibitionFromCoorganization(i.id)
                                        }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                )}
                        >
                            <ListItemText
                                primary={showOption(language)(i)}
                            />
                        </ListItem>
                    ))}
                </List>
            </>
        );
    }
;

export default SelectExhibitions