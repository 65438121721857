export const GET_FAQS = 'GET_FAQS'
export const GET_FAQS_SUCCEEDED = 'GET_FAQS_SUCCEEDED'
export const GET_FAQS_FAILED = 'GET_FAQS_FAILED'

export const GET_FAQ_DETAILS = 'GET_FAQ_DETAILS'
export const GET_FAQ_DETAILS_SUCCEEDED = 'GET_FAQ_DETAILS_SUCCEEDED'
export const GET_FAQ_DETAILS_FAILED = 'GET_FAQ_DETAILS_FAILED'

export const CREATE_FAQ = 'CREATE_FAQ'
export const CREATE_FAQ_SUCCEEDED = 'CREATE_FAQ_SUCCEEDED'
export const CREATE_FAQ_FAILED = 'CREATE_FAQ_FAILED'

export const UPDATE_FAQ = 'UPDATE_FAQ'
export const UPDATE_FAQ_SUCCEEDED = 'UPDATE_FAQ_SUCCEEDED'
export const UPDATE_FAQ_FAILED = 'UPDATE_FAQ_FAILED'

export const REORDER_FAQS = 'REORDER_FAQS'
export const REORDER_FAQS_SUCCEEDED = 'REORDER_FAQS_SUCCEEDED'
export const REORDER_FAQS_FAILED = 'REORDER_FAQS_FAILED'

export const DELETE_FAQ = 'DELETE_FAQ'
export const DELETE_FAQ_SUCCEEDED = 'DELETE_FAQ_SUCCEEDED'
export const DELETE_FAQ_FAILED = 'DELETE_FAQ_FAILED'
