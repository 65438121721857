// @flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseBadge, parseBadgeConfiguration, parseBadgeTemplateTypes} from "../../types/badge/parse";
import type {Badge, BadgeConfiguration} from "../../types/badge/types";
import {parseMessageStatus} from "../../types/exhibition/parse";
import type {MessageStatus} from "../../types/exhibition/types";
import {parseMultiple} from "../../helpers/parse";

const selectLoading = createSelector(
    (state) => state.badge,
    (item) => item.get('loading')
)

const selectScannedBadgeId = createSelector(
    (state) => state.badge,
    (item) => item.get('scannedBadgeId')
)

const selectBadgeById = createSelector(
    [
        (state) => state.badge.get('badgeById'),
        (state) => state.currentUser.get('language'),
        (state, id) => id
    ],
    (byId, language, id) => parseBadge(byId.get(id), language)
)

const selectBadgeConfigurationByExhibitionId = createSelector(
    (state, id) => state.badge.getIn(['badgeConfigurationByExhibitionId', id]),
    (data) => parseBadgeConfiguration(data)
)

const selectScanBadgeStatus = createSelector(
    (state) => state.badge.get('scanBadgeStatus'),
    (item) => parseMessageStatus(item)
)

const selectBadgeTemplateTypes = createSelector(
    [
        (state) => state.badge.get('badgeTemplateTypes'),
        (state) => state.currentUser.get('language')
    ],
        (badgeTemplateTypes, userLanguage) =>
            parseMultiple(badgeTemplateTypes, (i) => parseBadgeTemplateTypes(i, userLanguage))
)

const useBadgeData = ({badgeId, exhibitionId} = {}) => {
    const scanBadgeStatus: MessageStatus = useSelector(selectScanBadgeStatus)
    const loading = useSelector(selectLoading)
    const scannedBadgeId: string = useSelector(selectScannedBadgeId)
    const requestedBadgeId = badgeId ? badgeId : scannedBadgeId
    const badgeById: Badge = useSelector((state) => selectBadgeById(state, requestedBadgeId))
    const badgeConfigurationByExhibitionId: BadgeConfiguration = useSelector((state) => selectBadgeConfigurationByExhibitionId(state, exhibitionId))
    const badgeTemplateTypes = useSelector(selectBadgeTemplateTypes)
    return {
        scanBadgeStatus,
        badgeById,
        scannedBadgeId,
        loading,
        badgeConfigurationByExhibitionId,
        badgeTemplateTypes
    }
}

export default useBadgeData