// @flow
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { CatalogueLanguages } from "../../../helpers/constants";
import { EXHIBITOR_PROFILE } from "../../../locales/components/namespaces";

type Props = {};

const CatalogueWordPreview = (props: Props) => {
	const { t } = useTranslation(EXHIBITOR_PROFILE)
	const { values, exhibitor, exhibition } = props
	const address1El = useMemo(() => (
		`${values.address1.postalCode.el} ${values.address1.city.el ? values.address1.city.el : ''} ${values.address1.country.el?.description ? '- ' + values.address1.country?.el.description : ''}`
	), [values.address1.city.el, values.address1.country?.el.description, values.address1.postalCode.el])
	const address1En = useMemo(() => (
		`${values.address1.postalCode.en} ${values.address1.city.en ? values.address1.city.en : ''} ${values.address1.country.en?.description ? '- ' + values.address1.country?.en.description : ''}`
	), [values.address1.city.en, values.address1.country?.en.description, values.address1.postalCode.en])
	const address1PhoneEl = useMemo(() => {
		if (values.address1.country.el?.description === 'ΕΛΛΑΣ') {
			if (!values.address1.phone.el.startsWith('+') && !values.address1.phone.el.startsWith('3')) return `Τηλ.: +30${values.address1.phone.el}`
			else if (values.address1.phone.el.startsWith('30')) return `Τηλ.: +${values.address1.phone.el}`
			else if (!values.address1.phone.el.startsWith('+30')) return `Τηλ.: +30${values.address1.phone.el}`
			else return `Τηλ.: +30${values.address1.phone.el}`
		} else {
			return `Τηλ.: ${values.address1.phone.el}`
		}
	}, [values.address1.country.el?.description, values.address1.phone.el])
	const address1PhoneEn = useMemo(() => {
		if (values.address1.country.en?.description === 'GREECE') {
			if (!values.address1.phone.en.startsWith('+') && !values.address1.phone.en.startsWith('3')) return `Τel.: +30${values.address1.phone.en}`
			else if (values.address1.phone.en.startsWith('30')) return `Τel.: +${values.address1.phone.en}`
			else if (!values.address1.phone.en.startsWith('+30')) return `Τel.: +30${values.address1.phone.en}`
			else return `Τel.: +30${values.address1.phone.en}`
		} else {
			return `Τel.: ${values.address1.phone.en}`
		}
	}, [values.address1.country.en?.description, values.address1.phone.en])
	
	const address2El = useMemo(() => (
		`${values.address2.postalCode.el} ${values.address2.city.el ? values.address2.city.el : ''} ${values.address2.country.el?.description ? '- ' + values.address2.country.el?.description : ''}`
	), [values.address2.city.el, values.address2.country.el?.description, values.address2.postalCode.el])
	const address2En = useMemo(() => (
		`${values.address2.postalCode.en} ${values.address2.city.en ? values.address2.city.en : ''} ${values.address2.country.en?.description ? '- ' + values.address2.country.en?.description : ''}`
	), [values.address2.city.en, values.address2.country?.en?.description, values.address2.postalCode.en])
	const address2PhoneEl = useMemo(() => {
		if (values.address2.country.el?.description === 'ΕΛΛΑΔΑ') {
			if (!values.address2.phone.el.startsWith('+') && !values.address2.phone.el.startsWith('3')) return `Τηλ.: +30${values.address2.phone.el}`
			else if (values.address2.phone.el.startsWith('30')) return `Τηλ.: +${values.address2.phone.el}`
			else if (!values.address2.phone.el.startsWith('+30')) return `Τηλ.: +30${values.address2.phone.el}`
			else return `Τηλ.: +30${values.address2.phone.el}`
		} else {
			return `Τηλ.: ${values.address2.phone.el}`
		}
	}, [values.address2.country.el?.description, values.address2.phone.el])
	const address2PhoneEn = useMemo(() => {
		if (values.address2.country.en?.description === 'GREECE') {
			if (!values.address2.phone.en.startsWith('+') && !values.address2.phone.en.startsWith('3')) return `Τel.: +30${values.address2.phone.en}`
			else if (values.address2.phone.en.startsWith('30')) return `Τel.: +${values.address2.phone.en}`
			else if (!values.address2.phone.en.startsWith('+30')) return `Τel.: +30${values.address2.phone.en}`
			else return `Τel.: +30${values.address2.phone.en}`
		} else {
			return `Τel.: ${values.address2.phone.en}`
		}
	}, [values.address2.country.en?.description, values.address2.phone.en])
	const brands = useMemo(() => (
		exhibition.hasExhibitorBrands ? [values.brands0, values.brands1, values.brands2, values.brands3, values.brands4, values.brands5, values.brands6, values.brands7, values.brands8, values.brands9]?.filter(f => f?.trim().length !== 0) : []
	), [exhibition.hasExhibitorBrands, values.brands0, values.brands1, values.brands2, values.brands3, values.brands4, values.brands5, values.brands6, values.brands7, values.brands8, values.brands9])
	
	return (
		<Box p={1} mt={1} sx={{ border: '1px solid #8ea1e0', minWidth: { xs: 'auto', sm: '480px' } }}>
			<Typography
				component={'div'}
				variant={'h6'}
				sx={{ textAlign: 'center', width: '100%', backgroundColor: '#cfdcf8' }}
				mb={1}
			>
				{t('Catalogue Preview')}
			</Typography>
			<Grid container spacing={2}>
				{(exhibition?.catalogueLanguage !== CatalogueLanguages.EN || exhibition?.catalogueLanguage === CatalogueLanguages.ALL) && (
					<Grid item xs={12} sm={exhibition?.catalogueLanguage === CatalogueLanguages.ALL ? 6 : 12}
						  sx={{ lineBreak: 'anywhere' }}>
						<Typography
							component={'div'}
							variant={'h6'}
						>
							{t('Greek Preview')}
						</Typography>
						<Divider/>
						<Typography
							variant={'body1'}
							color={'#8ea1e0'}
							sx={{ fontSize: 20, fontWeight: 'bold' }}
						>
							{values.brand.el}
						</Typography>
						<Typography
							variant={'body1'}
							mb={1}
							sx={{ fontSize: 18, fontWeight: 'bold' }}
						>
							{`${exhibitor.room} ${exhibitor.kiosk}`}
						</Typography>
						<Typography
							variant={'body1'}
						>
							{values.address1.street.el}
						</Typography>
						<Typography
							variant={'body1'}
						>
							{address1El}
						</Typography>
						{values.address1.phone.el && (
							<Typography
								variant={'body1'}
							>
								{address1PhoneEl}
							</Typography>
						)}
						{values.address2.street.el && (
							<Typography
								variant={'body1'}
							>
								{values.address2.street.el}
							</Typography>
						)}
						<Typography
							variant={'body1'}
						>
							{address2El}
						</Typography>
						{values.address2.phone.el && (
							<Typography
								variant={'body1'}
							>
								{address2PhoneEl}
							</Typography>
						)}
						{values.social.website && (
							<Typography
								variant={'body1'}
							>
								{`e-mail: ${values.social.email}`}
							</Typography>
						)}
						{values.social.website && (
							<Typography
								variant={'body1'}
							>
								{`${values.social.website}`}
							</Typography>
						)}
						{values.social.linkedIn && (
							<Typography
								variant={'body1'}
							>
								{`LinkedIn: ${values.social.linkedIn}`}
							</Typography>
						)}
						{values.social.fb && (
							<Typography
								variant={'body1'}
							>
								{`Facebook: ${values.social.fb}`}
							</Typography>
						)}
						{values.social.instagram && (
							<Typography
								variant={'body1'}
							>
								{`Instagram: ${values.social.instagram}`}
							</Typography>
						)}
						<Typography
							variant={'body1'}
							color={'#8ea1e0'}
							mt={2}
						>
							{values.profile.el}
						</Typography>
						{brands.length > 0 && (
							<Typography
								variant={'body1'}
								color={'#8ea1e0'}
								mt={2}
							>
								Brands: {brands.toString()}
							</Typography>
						)}
					</Grid>
				)}
				{(exhibition?.catalogueLanguage !== CatalogueLanguages.EL || exhibition?.catalogueLanguage === CatalogueLanguages.ALL) && (
					<Grid item xs={12} sm={exhibition?.catalogueLanguage === CatalogueLanguages.ALL ? 6 : 12}
						  sx={{ lineBreak: 'anywhere' }}>
						<Typography
							component={'div'}
							variant={'h6'}
						>
							{t('English Preview')}
						</Typography>
						<Divider/>
						<Typography
							variant={'body1'}
							color={'#8ea1e0'}
							sx={{ fontSize: 20, fontWeight: 'bold' }}
						>
							{values.brand.en}
						</Typography>
						<Typography
							variant={'body1'}
							mb={1}
							sx={{ fontSize: 18, fontWeight: 'bold' }}
						>
							{`${exhibitor.room} ${exhibitor.kiosk}`}
						</Typography>
						<Typography
							variant={'body1'}
						>
							{values.address1.street.en}
						</Typography>
						<Typography
							variant={'body1'}
						>
							{address1En}
						</Typography>
						{values.address1.phone.en && (
							<Typography
								variant={'body1'}
							>
								{address1PhoneEn}
							</Typography>
						)}
						{values.address2.street.en && (
							<Typography
								variant={'body1'}
							>
								{values.address2.street.en}
							</Typography>
						)}
						<Typography
							variant={'body1'}
						>
							{address2En}
						</Typography>
						{values.address2.phone.en && (
							<Typography
								variant={'body1'}
							>
								{address2PhoneEn}
							</Typography>
						)}
						{values.social.website && (
							<Typography
								variant={'body1'}
							>
								{`e-mail: ${values.social.email}`}
							</Typography>
						)}
						{values.social.website && (
							<Typography
								variant={'body1'}
							>
								{`${values.social.website}`}
							</Typography>
						)}
						{values.social.linkedIn && (
							<Typography
								variant={'body1'}
							>
								{`LinkedIn: ${values.social.linkedIn}`}
							</Typography>
						)}
						{values.social.fb && (
							<Typography
								variant={'body1'}
							>
								{`Facebook: ${values.social.fb}`}
							</Typography>
						)}
						{values.social.instagram && (
							<Typography
								variant={'body1'}
							>
								{`Instagram: ${values.social.instagram}`}
							</Typography>
						)}
						<Typography
							variant={'body1'}
							mt={2}
							color={'#8ea1e0'}
						>
							{values.profile.en}
						</Typography>
						{brands.length > 0 && (
							<Typography
								variant={'body1'}
								color={'#8ea1e0'}
								mt={2}
							>
								Brands: {brands.toString()}
							</Typography>
						)}
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default CatalogueWordPreview