import {useCallback} from "react";
import {useDispatch} from "react-redux";
import * as actions from "../../actions/actions";

export const useInterestsAction = (id) => {
    const dispatch = useDispatch()
    const getInterests = useCallback(() => {
        dispatch(actions.getInterests({
            id: id
        }))
    }, [dispatch, id])
    
    const updateInterests = useCallback((interestId, weight, hideInPreregistration) => {
        dispatch(actions.updateInterests({
            exhibitionId: id,
            interestId: interestId,
            Ordr: weight,
            HidePreregistration: hideInPreregistration
        }))
    }, [dispatch, id])

    return {
        getInterests,
        updateInterests
    }
}