import {useEffect, useState} from "react";
import {useUITranslationsData} from "./useUITranslationsData";
import {useUITranslationsActions} from "./useUITranslationsActions";
import * as yup from "yup";
import {useFormik} from "formik";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";

const useUITranslations = ({exhibitionId, translations}) => {
	const {t} = useTranslation(EXHIBITION)
	const {getUITranslations, updateUITranslation, setUITransMessage} = useUITranslationsActions()
	const {loading, codes, uiTranslations, message} = useUITranslationsData()
	const [code, setCode] = useState(codes && codes.length > 0 ? codes[0]?.id : '')
	const [tab, setTab] = useState(0)
	const handleChangeTab = (event, newValue) => setTab(newValue)
	const onChangeCode = (evt) => setCode(evt.target.value)
	useEffect(() => {
		if (codes && codes.length > 0 && !code) setCode(codes[0]?.id)
	}, [code, codes])
	useEffect(() => {
		getUITranslations(exhibitionId)
	}, [exhibitionId, getUITranslations])
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setUITransMessage(null)
		}
	}, [message, setUITransMessage, t])
	
	const validationSchema = yup.object({})
	const onSubmit = (values) => {
		updateUITranslation({code, values, exhibitionId})
	}
	
	const formik = useFormik({
		initialValues: {
			body: {
				en: uiTranslations[code]?.value?.en,
				el: uiTranslations[code]?.value?.el,
			}
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		enableReinitialize: true
	})
	
	return {
		loading,
		codes,
		code,
		onChangeCode,
		tab,
		handleChangeTab,
		formik
	}
}

export default useUITranslations