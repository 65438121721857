export const GET_EXHIBITION_FILES = 'GET_EXHIBITION_FILES'
export const GET_EXHIBITION_FILES_SUCCEEDED = 'GET_EXHIBITION_FILES_SUCCEEDED'
export const GET_EXHIBITION_FILES_FAILED = 'GET_EXHIBITION_FILES_FAILED'

export const GET_EXHIBITION_FILE_DETAILS = 'GET_EXHIBITION_FILE_DETAILS'
export const GET_EXHIBITION_FILE_DETAILS_SUCCEEDED = 'GET_EXHIBITION_FILE_DETAILS_SUCCEEDED'
export const GET_EXHIBITION_FILE_DETAILS_FAILED = 'GET_EXHIBITION_FILE_DETAILS_FAILED'

export const CREATE_EXHIBITION_FILE = 'CREATE_EXHIBITION_FILE'
export const CREATE_EXHIBITION_FILE_SUCCEEDED = 'CREATE_EXHIBITION_FILE_SUCCEEDED'
export const CREATE_EXHIBITION_FILE_FAILED = 'CREATE_EXHIBITION_FILE_FAILED'

export const UPDATE_EXHIBITION_FILE = 'UPDATE_EXHIBITION_FILE'
export const UPDATE_EXHIBITION_FILE_SUCCEEDED = 'UPDATE_EXHIBITION_FILE_SUCCEEDED'
export const UPDATE_EXHIBITION_FILE_FAILED = 'UPDATE_EXHIBITION_FILE_FAILED'
export const REORDER_EXHIBITION_FILES = 'REORDER_EXHIBITION_FILES'

export const DELETE_EXHIBITION_FILE = 'DELETE_EXHIBITION_FILE'
export const DELETE_EXHIBITION_FILE_SUCCEEDED = 'DELETE_EXHIBITION_FILE_SUCCEEDED'
export const DELETE_EXHIBITION_FILE_FAILED = 'DELETE_EXHIBITION_FILE_FAILED'

export const GET_EXHIBITION_IMAGES = 'GET_EXHIBITION_IMAGES'
export const GET_EXHIBITION_IMAGES_SUCCEEDED = 'GET_EXHIBITION_IMAGES_SUCCEEDED'
export const GET_EXHIBITION_IMAGES_FAILED = 'GET_EXHIBITION_IMAGES_FAILED'

export const CREATE_EXHIBITION_IMAGE = 'CREATE_EXHIBITION_IMAGE'
export const CREATE_EXHIBITION_IMAGE_SUCCEEDED = 'CREATE_EXHIBITION_IMAGE_SUCCEEDED'
export const CREATE_EXHIBITION_IMAGE_FAILED = 'CREATE_EXHIBITION_IMAGE_FAILED'

export const DELETE_EXHIBITION_IMAGE = 'DELETE_EXHIBITION_IMAGE'
export const DELETE_EXHIBITION_IMAGE_SUCCEEDED = 'DELETE_EXHIBITION_IMAGE_SUCCEEDED'
export const DELETE_EXHIBITION_IMAGE_FAILED = 'DELETE_EXHIBITION_IMAGE_FAILED'

export const SET_FILE_MESSAGE = 'SET_FILE_MESSAGE'