import * as ActionTypes from '../../actions/types'
import {mapTranslations} from "../../types/exhibition/map";
import {fromJS} from "immutable";

const INITIAL_STATE = {
	loading: false,
	uiTranslations: {},
	message: null
}

function setUITransMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getUITranslations(state) {
	return state
	.set('loading', true)
}

function getUITranslationsSucceeded(state, action) {
	const {translations} = action.payload
	const mappedData = mapTranslations(translations)
	return state
	.set('loading', false)
	.set('uiTranslations', fromJS(mappedData))
}

function getUITranslationsFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function updateUITranslation(state) {
	return state
	.set('loading', true)
}

function updateUITranslationSucceeded(state, action) {
	const {Code, Value} = action.parameters
	return state
	.set('loading', false)
	.setIn(['uiTranslations', Code, 'value'], fromJS({en: Value.EN, el: Value.EL}))
	.set('message', fromJS({
		text: 'Message updated successfully!', variant: 'success'
	}))
}

function updateUITranslationFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const uiTranslations = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.GET_UI_TRANSLATIONS]: getUITranslations,
		[ActionTypes.GET_UI_TRANSLATIONS_SUCCEEDED]: getUITranslationsSucceeded,
		[ActionTypes.GET_UI_TRANSLATIONS_FAILED]: getUITranslationsFailed,
		[ActionTypes.UPDATE_UI_TRANSLATION]: updateUITranslation,
		[ActionTypes.UPDATE_UI_TRANSLATION_SUCCEEDED]: updateUITranslationSucceeded,
		[ActionTypes.UPDATE_UI_TRANSLATION_FAILED]: updateUITranslationFailed,
		[ActionTypes.SET_UI_TRANSLATION_MESSAGE]: setUITransMessage
	}
}

export default uiTranslations
