import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import NoContentSection from "../../../../../components/general/noContentSection/noContentSection";
import { TabPanel } from "../../../../../components/general/tabPanel/tabPanel";
import { Permissions } from "../../../../../helpers/constants";
import { getRouteUrl } from "../../../../../helpers/getRouteUrl";
import { normalizeGreek } from "../../../../../helpers/normalizeGreek";
import useQueryParams from "../../../../../hooks/useQueryParams";
import { EXHIBITION } from "../../../../../locales/components/namespaces";
import PermissionHOC from "../../../../../modules/permission/components/permissionHOC/permissionHOC";
import { ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE } from "../../../../../routers/routes";
import FaqSection from "../faqSection/faqSection";
import UseFullFiles from "./useFullFiles";

const SectionTabs = (props) => {
    const queryParams = useQueryParams()
    const faqSection = queryParams.get('faqId') ?? ""
    const {t} = useTranslation(EXHIBITION)
    const {exhibition, loading} = props
    const {id, welcomeMessage} = exhibition
    return (
        <React.Fragment>
            <TabPanel isVisible={!Boolean(faqSection)}>
                {welcomeMessage ? (
                    <Box sx={{px: 1}}>
                        <PermissionHOC requiredPermissions={[Permissions.BeOrganizer]}>
                            <Toolbar disableGutters variant={"dense"}>
                                <Typography variant="h6">
                                    {t('Welcome Page')}
                                </Typography>
                                <Box sx={{flexGrow: 1}}/>
                                <Button
                                    size={"small"}
                                    startIcon={<EditIcon/>}
                                    component={Link}
                                    to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE, {id: id})}
                                >
                                    {normalizeGreek(t('Edit'))}
                                </Button>
                            </Toolbar>
                            <Divider light/>
                        </PermissionHOC>
                        <Typography
                            component={"div"}
                            dangerouslySetInnerHTML={{__html: welcomeMessage}}
                        />
                    </Box>
                ) : !loading ? (
                    <PermissionHOC
                        requiredPermissions={[Permissions.BeOrganizer]}>
                        <NoContentSection
                            title={t('NO WELCOME PAGE')}
                            message={<span>{t('You can edit this exhibition\'s welcome screen')} </span>}
                            cta={(
                                <Button
                                    component={Link}
                                    to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE, {id: id})}>
                                    {normalizeGreek(t('here'))}
                                </Button>
                            )}
                        />
                    </PermissionHOC>
                ) : (
                    <ContentLoader
                        loading={loading}
                    />
                )}
            </TabPanel>
            <TabPanel isVisible={Boolean(faqSection)}>
                <FaqSection
                    faqId={faqSection}
                    exhibitionId={id}
                />
            </TabPanel>
        </React.Fragment>
    )
}

const Details = (props) => {
    const { exhibition: { image, buttonColor } } = props
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <SectionTabs
                    {...props}
                />
            </Grid>
            <Grid item xs={12} md={5}>
                <Paper sx={{padding: 0, display: "flex", overflow: "hidden"}}>
                    <img
                        style={{
                            margin: "auto",
                            maxWidth: "100%",
                            height: "auto",
                            minHeight: {
                                md: 240
                            },
                            objectFit: "contain"
                        }}
                        src={image}
                        alt={'exhibition logo'}
                    />
                </Paper>
                <UseFullFiles color={buttonColor}/>
            </Grid>
        </Grid>
    )
}

export default Details