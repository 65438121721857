import React, {useContext, useEffect, useState} from "react";
import {TopicFormContext} from "../faqsSettings";
import TextField from "@mui/material/TextField";
import {Field, FormikProvider, useFormik} from "formik";
import WysiwygEditor from "../../../components/form/editor/editor";
import {Checkbox, DialogActions, FormControl, FormControlLabel} from "@mui/material";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import type {FAQ} from "../../../types/exhibition/types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Delete, Edit} from "@mui/icons-material";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from '@mui/icons-material/Cancel';
import {useFAQAction} from "../../../hooks/faqs/useFAQAction";
import LoadingButton from "@mui/lab/LoadingButton";
import {useFAQData} from "../../../hooks/faqs/useFAQData";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import useUserData from "../../../hooks/user/useUserData";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ContentLoader from "../../../components/general/contentLoader/contentLoader";

const EditForLanguage = ({formik, language, userSelectedLanguage}) => {
    const {t} = useTranslation(EXHIBITION)
    if (userSelectedLanguage !== language) return null
    return (
        <React.Fragment>
            <TextField
                sx={{mb: 2}}
                fullWidth
                size={"small"}
                label={t("Title")}
                name={`title.${language}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title[language]}
            />
            <FormControl variant="standard" fullWidth>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formik.values.isNew}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            size={'small'}
                            name={'isNew'}
                        />
                    }
                    label={t('Flag as new')}
                    labelPlacement="end"
                />
            </FormControl>
            <Field
                name={`content.${language}`}
                component={WysiwygEditor}
            />
        </React.Fragment>
    )
}

type Props = {
    item: FAQ,
}

const Form = ({faq, onSubmit, setShowEdit, loading}) => {
    const {t} = useTranslation(EXHIBITION)
    const {language: defaultUserLanguage} = useUserData()
    const [language, setLanguage] = useState(defaultUserLanguage)
    const switchLanguage = (event, newValue) => setLanguage(newValue)
    const formik = useFormik({
        initialValues: {
            title: {
                en: faq.title.en ?? '',
                el: faq.title.el ?? ''
            },
            content: {
                en: faq.content.en ?? '',
                el: faq.content.el ?? ''
            },
            weight: faq.weight,
            isNew: faq.isNew,
        },
        onSubmit: onSubmit,
        enableReinitialize: true
    })
    return (
        <FormikProvider value={formik}>
            <Box component="form" onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Toolbar variant={"dense"} disableGutters sx={{mb: 1}}>
                        <ToggleButtonGroup
                            sx={{mr: 2}}
                            color="primary"
                            value={language}
                            exclusive
                            onClick={(e) => e.stopPropagation()}
                            onChange={switchLanguage}
                        >
                            <ToggleButton size={"small"}
                                          value="el">{normalizeGreek(t("Greek"))}</ToggleButton>
                            <ToggleButton size={"small"}
                                          value="en">{normalizeGreek(t("English"))}</ToggleButton>
                        </ToggleButtonGroup>
                    </Toolbar>
                    <EditForLanguage
                        formik={formik}
                        language={'el'}
                        userSelectedLanguage={language}
                    />
                    <EditForLanguage
                        formik={formik}
                        language={'en'}
                        userSelectedLanguage={language}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        size={"small"}
                        type={"submit"}
                        endIcon={<SaveIcon/>}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        {normalizeGreek(t('Save'))}
                    </LoadingButton>
                    <Button
                        size={"small"}
                        type="reset"
                        variant={"outlined"}
                        color={"error"}
                        startIcon={<CancelIcon/>}
                        onClick={() => {
                            setShowEdit(false)
                            formik.resetForm()
                        }}
                    >
                        {normalizeGreek(t('Cancel'))}
                    </Button>
                </DialogActions>
            </Box>
        </FormikProvider>
    )
}

const FormRow = (props: Props) => {
    const {data: item} = props
    const {exhibitionId} = useContext(TopicFormContext)
    const [showEdit, setShowEdit] = useState(false)
    const {updateFaq, deleteFaq} = useFAQAction()
    const {loadingFaq: loading, faq} = useFAQData({
        faqId: String(item.id),
        overrideLanguage: 'any',
        exhibitionId: exhibitionId,
    })
    const {getFaqDetails} = useFAQAction()
    useEffect(() => {
        if (showEdit) {
            getFaqDetails(item.id, exhibitionId)
        }
    }, [exhibitionId, showEdit, item.id, getFaqDetails])
    const onSubmit = (values) => {
        setShowEdit(false)
        updateFaq(item.id, exhibitionId, values)
    }
    return (
        <React.Fragment>
            <IconButton onClick={() => setShowEdit(true)}>
                <Edit/>
            </IconButton>
            <IconButton color={"error"} onClick={() => deleteFaq(item.id, exhibitionId)}>
                <Delete/>
            </IconButton>
            <Dialog open={showEdit} maxWidth={"lg"} fullWidth disableEnforceFocus>
                {!loading && faq ? (
                    <Form
                        faq={faq}
                        loading={loading}
                        showEdit={showEdit}
                        setShowEdit={setShowEdit}
                        onSubmit={onSubmit}
                    />
                ) : (
                    <DialogContent>
                        <ContentLoader loading={loading}/>
                    </DialogContent>
                )}
            </Dialog>
        </React.Fragment>

    )
}

export default FormRow
