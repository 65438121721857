import React from 'react';
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import useUserData from "../../../hooks/user/useUserData";
import {showOption} from "../../../helpers/helpers";

type Props = {

}

const SelectCountry = (props: Props) => {
    const {formik, variant, name, disabled, label, value, onChange, countries, required, shrink = true} = props
    const {language} = useUserData()
    return (
        <Box sx={{width: '100%'}}>
            <Autocomplete
                options={countries}
                value={value}
                isOptionEqualToValue={(opt, val) => {
                    return opt.id === val.id || !val || '-'
                }}
                getOptionLabel={showOption(language)}
                selectOnFocus
                handleHomeEndKeys
                onChange={onChange}
                autoHighlight={true}
                size="small"
                fullWidth
                disabled={disabled}
                name={name}
                renderInput={ (params) => (
                    <TextField
                        required={required}
                        {...params}
                        variant={variant}
                        InputLabelProps={{shrink: shrink}}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: "background.paper"
                            }
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: value && value?.image && (
                                <img style={{width: '30px', paddingRight: '6px'}} src={value?.image}
                                     alt={value?.code}/>
                            )
                        }}
                        label={label}
                    />
                )}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`${option.image} 2x`}
                            src={option.image}
                            alt=""
                        />
                        {showOption(language)(option)}
                    </Box>
                )}
            />
        </Box>
    )
}

export default SelectCountry