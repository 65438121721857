import * as ActionTypes from '../../actions/types'
import {mapMultiple} from "../../helpers/map";
import {mapRule, mapRuleDetails} from "../../types/rules/map";
import {fromJS} from "immutable";
import {randomId} from "@mui/x-data-grid-generator";
import {parseMultiple} from "../../helpers/parse";
import {parseRuleDetails} from "../../types/rules/parse";
import _ from "lodash"
import {operators, ruleFields} from "../../helpers/constants";

const INITIAL_STATE = {
    loading: false,
    byId: {},
    status: null,
    updateRuleGridSource: false,
    updateRuleDetailsSource: false
}

function getRules(state) {
    return state
        .set('loading', true)
}

function getRulesSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapMultiple(payload, mapRule)
    return state
        .set('loading', false)
        .set('byId', fromJS(mappedData))
}

function getRulesFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Fetching rules failed.',
            type: 'error'
        }))
}

function saveRule(state) {
    return state
        .set('loading', true)
}

function saveRuleSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Saving rule succeeded.',
            type: 'success'
        }))
        .set('updateRuleGridSource', !state.get('updateRuleGridSource'))
}

function saveRuleFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Storing rule failed.',
            type: 'error'
        }))
        .set('updateRuleGridSource', !state.get('updateRuleGridSource'))
}

function updateRule(state) {
    return state
        .set('loading', true)
}

function updateRuleSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Updating rule succeeded.',
            type: 'success'
        }))
        .set('updateRuleGridSource', !state.get('updateRuleGridSource'))
}

function updateRuleFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Updating rule failed.',
            type: 'error'
        }))
        .set('updateRuleGridSource', !state.get('updateRuleGridSource'))
}

function deleteRule(state) {
    return state
        .set('loading', true)
}

function deleteRuleSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Deleting rule succeeded.',
            type: 'success'
        }))
        .set('updateRuleGridSource', !state.get('updateRuleGridSource'))
}

function deleteRuleFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Deleting rule failed.',
            type: 'error'
        }))
        .set('updateRuleGridSource', !state.get('updateRuleGridSource'))
}

function getRuleDetails(state) {
    return state
        .set('loading', true)
}

function getRuleDetailsSucceeded(state, action) {
    const {payload, parameters} = action
    const mappedData = mapMultiple(payload, mapRuleDetails)
    return state
        .set('loading', false)
        .setIn(['byId', parameters.ruleId, 'details'], fromJS(mappedData))
}

function getRuleDetailsFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Getting rule details failed.',
            type: 'error'
        }))
}

function saveRuleDetails(state) {
    return state
        .set('loading', true)
}

function saveRuleDetailsSucceeded(state, action) {
    const {payload} = action
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Storing rule details succeeded.',
            type: 'success'
        }))
        .set('updateRuleDetailsSource', !state.get('updateRuleDetailsSource'))
}

function saveRuleDetailsFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Storing rule details failed.',
            type: 'error'
        }))
        .set('updateRuleDetailsSource', !state.get('updateRuleDetailsSource'))
}

function updateRuleDetails(state) {
    return state
        .set('loading', true)
}

function updateRuleDetailsSucceeded(state, action) {
    const {payload} = action
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Updating rule details succeeded.',
            type: 'success'
        }))
        .set('updateRuleDetailsSource', !state.get('updateRuleDetailsSource'))
}

function updateRuleDetailsFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Updating rule details failed.',
            type: 'error'
        }))
        .set('updateRuleDetailsSource', !state.get('updateRuleDetailsSource'))
}

function deleteRuleDetails(state) {
    return state
        .set('loading', true)
}

function deleteRuleDetailsSucceeded(state, action) {
    const {payload} = action
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Deleting rule details succeeded.',
            type: 'success'
        }))
        .set('updateRuleDetailsSource', !state.get('updateRuleDetailsSource'))
}

function deleteRuleDetailsFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Deleting rule details failed.',
            type: 'error'
        }))
        .set('updateRuleDetailsSource', !state.get('updateRuleDetailsSource'))
}

function addRuleDetails(state, action) {
    const {payload} = action
    const details = parseMultiple(state.getIn(['byId', payload.ruleId, 'details']), (i) => parseRuleDetails(i))
    const newDetailsId = randomId()
    details.push({
        field: ruleFields[0].value,
        id: newDetailsId,
        operator: operators[0].value,
        ruleId: payload.ruleId,
        value: '',
        editMode: true
    })
    const mappedData = _.keyBy(details, 'id');
    return state
        .set('status', null)
        .setIn(['byId', payload.ruleId, 'details'], fromJS(mappedData))
}

function editRuleDetails(state, action) {
    const {payload} = action
    const {ruleId, id, value, operator, field, editMode} = payload.row
    return state
        .set('status', null)
        .setIn(['byId', ruleId, 'details', id, 'editMode'], true)
}

function cancelEditRuleDetails(state, action) {
    const {payload} = action
    const {ruleId, id, value, operator, field, editMode} = payload.row
    return state
        .set('status', null)
        .setIn(['byId', ruleId, 'details', id, 'editMode'], false)
}

function clearRuleStatus(state, action) {
    return state
        .set('status', null)
}

function setRuleStatus(state, action) {
    const {payload} = action
    const mappedData = _.keyBy(payload.updateRows, 'id');
    return state
        .set('status', null)
        .setIn(['byId', payload.row.ruleId, 'details'], fromJS(mappedData))
}

const rules = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_RULES]: getRules,
        [ActionTypes.GET_RULES_SUCCEEDED]: getRulesSucceeded,
        [ActionTypes.GET_RULES_FAILED]: getRulesFailed,
        [ActionTypes.SAVE_RULE]: saveRule,
        [ActionTypes.SAVE_RULE_SUCCEEDED]: saveRuleSucceeded,
        [ActionTypes.SAVE_RULE_FAILED]: saveRuleFailed,
        [ActionTypes.UPDATE_RULE]: updateRule,
        [ActionTypes.UPDATE_RULE_SUCCEEDED]: updateRuleSucceeded,
        [ActionTypes.UPDATE_RULE_FAILED]: updateRuleFailed,
        [ActionTypes.DELETE_RULE]: deleteRule,
        [ActionTypes.DELETE_RULE_SUCCEEDED]: deleteRuleSucceeded,
        [ActionTypes.DELETE_RULE_FAILED]: deleteRuleFailed,
        [ActionTypes.GET_RULE_DETAILS]: getRuleDetails,
        [ActionTypes.GET_RULE_DETAILS_SUCCEEDED]: getRuleDetailsSucceeded,
        [ActionTypes.GET_RULE_DETAILS_FAILED]: getRuleDetailsFailed,
        [ActionTypes.SAVE_RULE_DETAILS]: saveRuleDetails,
        [ActionTypes.SAVE_RULE_DETAILS_SUCCEEDED]: saveRuleDetailsSucceeded,
        [ActionTypes.SAVE_RULE_DETAILS_FAILED]: saveRuleDetailsFailed,
        [ActionTypes.UPDATE_RULE_DETAILS]: updateRuleDetails,
        [ActionTypes.UPDATE_RULE_DETAILS_SUCCEEDED]: updateRuleDetailsSucceeded,
        [ActionTypes.UPDATE_RULE_DETAILS_FAILED]: updateRuleDetailsFailed,
        [ActionTypes.DELETE_RULE_DETAILS]: deleteRuleDetails,
        [ActionTypes.DELETE_RULE_DETAILS_SUCCEEDED]: deleteRuleDetailsSucceeded,
        [ActionTypes.DELETE_RULE_DETAILS_FAILED]: deleteRuleDetailsFailed,
        [ActionTypes.ADD_RULE_DETAILS]: addRuleDetails,
        [ActionTypes.EDIT_RULE_DETAILS]: editRuleDetails,
        [ActionTypes.CANCEL_EDIT_RULE_DETAILS]: cancelEditRuleDetails,
        [ActionTypes.CLEAR_RULE_STATUS]: clearRuleStatus,
        [ActionTypes.SET_RULE_DETAILS]: setRuleStatus,
    }
}

export default rules
