// @flow
import * as React from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from '@mui/icons-material/Circle';
import Typography from "@mui/material/Typography";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import Divider from "@mui/material/Divider";
import {CatalogueLanguages} from "../../../helpers/constants";

type Props = {};

const CatalogueExample = (props: Props) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {catalogueLanguage} = props
    return (
        <Box sx={{width: '100%'}}>
            <Typography variant="h6">
                {t('Catalogue Example')}
            </Typography>
            <Divider/>
            <List>
                <ListItem
                    disablePadding
                    sx={{alignItems: 'baseline', borderBottom: '1px solid #bdbdbd'}}
                >
                    <ListItemIcon sx={{
                        minWidth: '20px'
                    }}>
                        <CircleIcon
                            sx={{
                                fontSize: '10px',
                                minWidth: '20px'
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <strong>{t('COMPANY NAME')}{catalogueLanguage === CatalogueLanguages.EN ? t(' (IN CAPITAL LATIN LETTERS)') : ''}</strong>: <em>ROTA
                        SA</em>
                    </ListItemText>
                </ListItem>
                <ListItem
                    disablePadding
                    sx={{alignItems: 'baseline', borderBottom: '1px solid #bdbdbd'}}
                >
                    <ListItemIcon sx={{
                        minWidth: '20px'
                    }}>
                        <CircleIcon
                            sx={{
                                fontSize: '10px',
                                minWidth: '20px'
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <strong>{t('Address')}</strong>: <em>{t('rota_address')}</em>
                    </ListItemText>
                </ListItem>
                <ListItem
                    disablePadding
                    sx={{alignItems: 'baseline', borderBottom: '1px solid #bdbdbd'}}
                >
                    <ListItemIcon sx={{
                        minWidth: '20px'
                    }}>
                        <CircleIcon
                            sx={{
                                fontSize: '10px',
                                minWidth: '20px'
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <strong>{t('Tel.')}</strong>: <em>2111801801, 2111801802</em>
                    </ListItemText>
                </ListItem>
                <ListItem
                    disablePadding
                    sx={{alignItems: 'baseline', borderBottom: '1px solid #bdbdbd'}}
                >
                    <ListItemIcon sx={{
                        minWidth: '20px'
                    }}>
                        <CircleIcon
                            sx={{
                                fontSize: '10px',
                                minWidth: '20px'
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <strong>e-mail</strong>: <em>support@rota.gr</em>
                    </ListItemText>
                </ListItem>
                <ListItem
                    disablePadding
                    sx={{alignItems: 'baseline', borderBottom: '1px solid #bdbdbd'}}
                >
                    <ListItemIcon sx={{
                        minWidth: '20px'
                    }}>
                        <CircleIcon
                            sx={{
                                fontSize: '10px',
                                minWidth: '20px'
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <strong>{t('Website')}</strong>: <em>www.rota.gr</em>
                    </ListItemText>
                </ListItem>
                <ListItem
                    disablePadding
                    sx={{alignItems: 'baseline'}}
                >
                    <ListItemIcon sx={{
                        minWidth: '20px'
                    }}>
                        <CircleIcon
                            sx={{
                                fontSize: '10px',
                                minWidth: '20px'
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <strong>{t('COMPANY PROFILE (Up to 350 characters)')}</strong>:
                        <Trans
                            i18nKey={'catalog_example'}
                            ns={EXHIBITOR_PROFILE}
                            components={{bold: <strong/>, em: <em/>}}
                        />
                    </ListItemText>
                </ListItem>
            </List>
        </Box>
    );
};

export default CatalogueExample