// @flow
import React, {useMemo} from 'react';
import Box from "@mui/material/Box";
import useUserData from "../../../../../../../hooks/user/useUserData";
import {muiGridLocales} from "../../../../../../../helpers/muiGridLocales";
import {DataGridPremium, GridToolbar} from "@mui/x-data-grid-premium";
import {
	CustomPagination
} from "../../../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import {formatDate} from "../../../../../../../helpers/date";

type Props = {};

const VisitsTotal = (props: Props) => {
	const {loading, data, t, generalReport} = props
	const {language, dateFormat} = useUserData()
	
	const columns = useMemo(() => [
			...(generalReport ? [{
				field: 'exhibition',
				description: t('Exhibition'),
				headerName: t('Exhibition'),
				flex: 1
			}] : []),
			...(generalReport ? [{
				field: 'coorganization',
				description: t('Coorganization'),
				headerName: t('Coorganization'),
				flex: 1
			}] : []),
			{
				field: 'exhibitionStartDt',
				description: t('Exhibition Date'),
				headerName: t('Exhibition Date'),
				flex: 1,
				valueFormatter: (value, row, column, apiRef) => {
					return `${formatDate(row.exhibitionStartDt, dateFormat)}`
				}
			},
			{
				field: 'entranceLobby1Count',
				description: t('EntranceLobby1Count'),
				headerName: t('EntranceLobby1Count'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'entranceLobby2Count',
				description: t('EntranceLobby2Count'),
				headerName: t('EntranceLobby2Count'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'entranceLoungeCount',
				description: t('EntranceLoungeCount'),
				headerName: t('EntranceLoungeCount'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'entranceCount',
				description: t('EntranceCount'),
				headerName: t('EntranceCount'),
				flex: 1,
				type: 'number',
			},
			{
				field: 'visitorCount',
				description: t('VisitorCount'),
				headerName: t('VisitorCount'),
				flex: 1,
				type: 'number',
			},
		],
		[dateFormat, t]
	)
	return (
		<Box style={{height: 600, width: '100%'}}>
			<DataGridPremium
				sx={{bgcolor: 'background.paper'}}
				loading={loading}
				initialState={{
					density: "compact"
				}}
				localeText={muiGridLocales(language)}
				rows={data}
				columns={columns}
				pagination
				slotProps={{toolbar: {csvOptions: {disableToolbarButton: true}}}}
				slots={{
					toolbar: GridToolbar,
					pagination: CustomPagination
				}}
			/>
		</Box>
	);
};

export default VisitsTotal