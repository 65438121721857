// @flow
import React, {useMemo, useState} from 'react';
import {useCategories} from "../../../../../hooks/categories/useCategories";
import {useRules} from "../../../../../hooks/rules/useRules";
import EditableMUIDataGrid from "../../../../../components/grids/editableMUIDataGrid/editableMUIDataGrid";
import {useTranslation} from "react-i18next";
import {RULES} from "../../../../../locales/components/namespaces";
import Box from "@mui/material/Box";
import {useRulesAction} from "../../../../../hooks/rules/useRulesAction";
import {useRulesData} from "../../../../../hooks/rules/useRulesData";
import {Alert, Snackbar} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {GridActionsCellItem} from "@mui/x-data-grid-premium";
import {Close, Tune} from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import RuleConditions from "./partials/ruleConditions";
import IconButton from "@mui/material/IconButton";
import {useCountries} from "../../../../../hooks/countries/useCountries";
import {useInterests} from "../../../../../hooks/interests/useInterests";

type Props = {
    exhibitionId: string
};

const Rules = (props: Props) => {
    const {exhibitionId} = props
    const {rules, loading} = useRules(exhibitionId)
    const {loading: loadingCategories, categories} = useCategories(exhibitionId)
    const {loading: loadingCountries, countries} = useCountries()
    const {loading: loadingInterests, interests} = useInterests(exhibitionId)
    const {saveRule, updateRule, deleteRule, clearRuleStatus} = useRulesAction({exhibitionId})
    const {status} = useRulesData()
    const {t} = useTranslation(RULES)
    const [openRuleConditions, setOpenRuleConditions] = useState(null)

    const newRowModel = {
        description: '',
        characterizationId: ''
    }

    const columns = useMemo(() => [
        {
            field: 'ruleConditions',
            headerName: t('Rule Conditions'),
            type: 'actions',
            width: 140,
            getActions: (params) => [
                <GridActionsCellItem
                    onClick={() => setOpenRuleConditions(params.row.id)}
                    icon={<Tune/>}
                    label={t("Open Rule Conditions")}
                />,
            ],
        },
        {
            field: 'description',
            headerName: t('Description'),
            flex: 1,
            editable: true
        },
        {
            field: 'characterizationId',
            headerName: t('Characterization'),
            flex: 1,
            type: 'singleSelect',
            valueOptions: categories.map(category => ({
                value: category.id,
                label: category.text
            })),
            editable: true
        },
    ], [categories, t])

    return (
        <Box sx={{maxWidth: '92vw'}}>
            <EditableMUIDataGrid
                data={rules}
                columns={columns}
                loading={loading}
                newRowModel={newRowModel}
                store={saveRule}
                remove={deleteRule}
                update={updateRule}
            />
            <Snackbar
                open={Boolean(status)}
                anchorOrigin={{
                    vertical: 'top', horizontal: 'right',
                }}
                autoHideDuration={2000}
                onClose={clearRuleStatus}>
                <Alert
                    onClose={clearRuleStatus}
                    severity={status?.type}
                    sx={{width: '100%', fontSize: '16px'}}
                >
                    {t(status?.message)}
                </Alert>
            </Snackbar>
            <Dialog
                disableEscapeKeyDown
                open={Boolean(openRuleConditions)}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') setOpenRuleConditions(null)
                }}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogActions>
                    <IconButton
                        onClick={() => setOpenRuleConditions(null)}
                    >
                        <Close/>
                    </IconButton>
                </DialogActions>
                <DialogTitle>{t('Add Rule Conditions')}</DialogTitle>
                <DialogContent>
                    <RuleConditions
                        ruleId={openRuleConditions}
                        exhibitionId={exhibitionId}
                        countries={countries}
                        interests={interests}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Rules