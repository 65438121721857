//@flow
import { ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import { useDragDropTree } from "../../components/general/dragDropTree/useDragDropTree";
import { normalizeGreek } from "../../helpers/normalizeGreek";
import { useFAQAction } from "../../hooks/faqs/useFAQAction";
import { useFAQData } from "../../hooks/faqs/useFAQData";
import { EXHIBITOR_PROFILE } from "../../locales/components/namespaces";
import ViewRow from "./partials/viewRow";

type Props = {
    exhibitionId: string
}

const FaqsView = (props: Props) => {
    const {exhibitionId} = props
    const { t } = useTranslation(EXHIBITOR_PROFILE)
    const {getFaqs} = useFAQAction()
    const {faqs, loading} = useFAQData({exhibitionId: exhibitionId})
    useEffect(() => {
        getFaqs(exhibitionId)
    }, [exhibitionId, getFaqs])
    const {itemsList} = useDragDropTree({unprocessedItems: faqs})
    return (
        <Box>
            <List dense>
                <ListItem disableGutters>
                    <Typography
                        sx={{ width: '100%', textAlign: 'center' }}
                        variant={'button'}
                        component={'div'}
                    >
                        {normalizeGreek(t('General Info'))}
                    </Typography>
                </ListItem>
                {itemsList && itemsList.length > 0 ? (
                    itemsList.map((f => (
                        <ViewRow
                            key={f.id}
                            item={f.data}
                            depth={f.depth}
                            exhibitionId={exhibitionId}
                        />
                    )))
                ) : (
                    <ContentLoader
                        loading={loading}
                    />
                )}
            </List>
        </Box>
    )
}

export default FaqsView;
