//@flow
import React, {useContext, useEffect} from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import useExhibitionAction, {Update} from "../../../../hooks/exhibitions/useExhibitionAction";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import {Tab, Tabs} from "@mui/material";
import {TabPanel} from "../../../../components/general/tabPanel/tabPanel";
import ExhibitionUpdateStatus from "../../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import {TitleContext} from "../../../main/content";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import FaqsSettings from "../../../faqs/faqsSettings";
import type {ExhibitionFile} from "../../../../types/exhibitionFiles/types";
import type {Exhibition} from "../../../../types/exhibition/types";
import ExhibitionContentWithFilesSection
    from "../../../../components/app/exhibitionContentWithFilesSection/exhibitionContentWithFilesSection";
import {FileTypes} from "../../../../helpers/constants";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {
    ROUTE_PAGE_EXHIBITION,
    ROUTE_PAGE_EXHIBITION_SETTINGS,
    ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE,
    ROUTE_PAGE_HOME
} from "../../../../routers/routes";
import useUserData from "../../../../hooks/user/useUserData";
import {Link} from "react-router-dom";
import useQueryParams from "../../../../hooks/useQueryParams";

type InnerProps = {
    files: Array<ExhibitionFile>,
    exhibition: Exhibition,
    onSubmit: Function
}

const InnerContent = (props: InnerProps) => {
    const {t} = useTranslation(EXHIBITION)
    const {files, exhibition, onSubmit} = props
    const queryParams = useQueryParams()
    const section = queryParams.get('section') ?? ""
    const {setToolbar} = useContext(TitleContext)
    useEffect(() => {
        setToolbar(
            <React.Fragment>
                <Tabs
                    variant="scrollable"
                    value={section}
                    indicatorColor="primary"
                    textColor="inherit"
                >
                    <Tab
                        value={""}
                        label={normalizeGreek(t("Welcome Page"))}
                        component={Link}
                        to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE, {id: exhibition.id})}
                    />
                    <Tab
                        value={"menu"}
                        label={normalizeGreek(t("Menu"))}
                        component={Link}
                        to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE, {id: exhibition.id,}, {section: "menu"})}
                    />
                </Tabs>
            </React.Fragment>
        )
        return () => {
            setToolbar(null)
        }
    }, [setToolbar, t, section, exhibition.id])
    const {language} = useUserData()
    return (
        <React.Fragment>
            <Breadcrumb>
                <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                    {t('Home')}
                </Breadcrumb.Link>
                <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
                    {exhibition.title[language]}
                </Breadcrumb.Link>
                <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
                    {t('Settings')}
                </Breadcrumb.Link>
                <Breadcrumb.Item notFocused>
                    {t('General')}
                </Breadcrumb.Item>
            </Breadcrumb>
            <TabPanel isVisible={section === ""}>
                <ExhibitionContentWithFilesSection
                    text={exhibition.welcomeMessage}
                    files={files.filter(f => f.type === FileTypes.WelcomePage)}
                    onSubmit={onSubmit}
                    exhibitionId={exhibition.id}
                    typeId={FileTypes.WelcomePage}
                />
            </TabPanel>
            <TabPanel isVisible={section === "menu"}>
                <FaqsSettings
                    exhibition={exhibition}
                />
            </TabPanel>
            <ExhibitionUpdateStatus/>
        </React.Fragment>
    )
}

const WelcomePage = () => {
    const {exhibition, files, loadingFiles, loading, id} = useExhibition({language: 'any'})
    const {updateExhibition} = useExhibitionAction()
    const onSubmit = (values) => {
        updateExhibition(id, values, Update.WelcomePage)
    }
    return (
        <React.Fragment>
            {exhibition ? (
                <InnerContent
                    onSubmit={onSubmit}
                    exhibition={exhibition}
                    files={files}
                    loadingFiles={loadingFiles}
                />
            ) : (
                <ContentLoader
                    loading={loading}
                    notFoundWhenNotLoading
                />
            )}
        </React.Fragment>
    )
}

export default WelcomePage;