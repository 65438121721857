//@flow
import React, {useState} from 'react';
import * as yup from "yup";
import {Field, FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {Grid, Paper, Tab, Tabs} from "@mui/material";
import WysiwygEditor from "../editor/editor";
import FileUpload from "../fileUpload/fileUpload";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import {TabPanel} from "../../general/tabPanel/tabPanel";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";

type Props = {
    title?: string,
    initialText: {
        en: string,
        el: string,
    },
    files: Array<{
        name: string,
        url: string,
        alias: string,
    }>,
    onUpdateAlias: Function,
    onFilesUpload: Function,
    onFileRemove: Function,
    onSubmit: Function,
}

const ItemForLanguage = (props) => {
    const {t} = useTranslation(EXHIBITION)
    const {language, files, onUpdateAlias, onFilesUpload, onFileRemove} = props
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <Paper sx={{px: 2, pb: 1}} variant={"outlined"}>
                    <Toolbar disableGutters variant={"dense"}>
                        <Typography variant="h6">
                            {t('Text')}
                        </Typography>
                    </Toolbar>
                    <Divider sx={{mb: 1}} light/>
                    <Box sx={{width: '100%'}}>
                        <Field
                            name={"text." + language}
                            component={WysiwygEditor}
                        />
                    </Box>
                    <Stack direction="row" spacing={1} sx={{mt: 1}}>
                        <Button
                            size={"small"}
                            type={"submit"}
                            startIcon={<SaveIcon/>}
                            variant="contained"
                        >
                            {normalizeGreek(t('Save'))}
                        </Button>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper sx={{px: 2, pb: 2}} variant={"outlined"}>
                    <Toolbar disableGutters variant={"dense"}>
                        <Typography variant="h6">
                            {t('Attachments')}
                        </Typography>
                    </Toolbar>
                    <Divider sx={{mb: 2}} light/>
                    <FileUpload
                        allowAliasEdit
                        allowDisplayOrder
                        files={files[language]}
                        multiple={true}
                        onUpdateAlias={onUpdateAlias}
                        onRemove={onFileRemove}
                        onFilesUpload={(files) => {
                            if (files && files.length > 0) {
                                files.forEach((f) => onFilesUpload(f, language))
                            }
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

const EditorWithAttachmentsForm = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => setValue(newValue)
    const {onSubmit, initialText, ...otherProps} = props
    const validationSchema = yup.object({})

    const formik = useFormik({
        initialValues: {
            text: {
                en: initialText.en,
                el: initialText.el,
            }
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })
    return (
        <FormikProvider value={formik}>
            <Box component="form" onSubmit={formik.handleSubmit} sx={{mb: 2}}>
                <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                >
                    <Tab value={0} label={normalizeGreek(t('English'))}/>
                    <Tab value={1} label={normalizeGreek(t('Greek'))}/>
                </Tabs>
                <TabPanel isVisible={value === 0}>
                    <ItemForLanguage
                        language={"en"}
                        {...otherProps}
                    />
                </TabPanel>
                <TabPanel isVisible={value === 1}>
                    <ItemForLanguage
                        language={"el"}
                        {...otherProps}
                    />
                </TabPanel>
            </Box>
        </FormikProvider>
    )
}

export default EditorWithAttachmentsForm;
