// @flow
import * as React from 'react';
import {useState} from 'react';
import type {Exhibition} from "../../../types/exhibition/types";
import {Link} from "react-router-dom";
import {ROUTE_PAGE_EXHIBITION} from "../../../routers/routes";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import Box from "@mui/material/Box";
import ExhibitionStatus from "../exhibitionStatus/exhibitionStatus";
import {CardMedia} from "@mui/material";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import Toolbar from "@mui/material/Toolbar";
import {styled} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {IMAGE_LOGO} from "../../../assets/images";
import {useMultilingualText} from "../../../hooks/useMultilingualText";

type Props = {
    exhibition: Exhibition
}

const ImageBackdrop = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.3,
    transition: theme.transitions.create('opacity'),
}))

const Image = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ExhibitionPreviewHorizontal = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {exhibition: {id, dateFrom, dateTo, image}} = props
    const [hideImage, setHideImage] = useState(false)
    const defaultImage = useMultilingualText(IMAGE_LOGO)
    return (
        <Paper
            elevation={4}
            sx={{
                borderRadius: 2,
                overflow: "hidden",
                position: "relative",
                maxWidth: 16 * 8 * 10,
                margin: "auto",
                display: "block",
                '& .MuiTypography-root': {
                    // opacity: 0,
                },
                '&:hover, &.Mui-focusVisible': {
                    zIndex: 1,
                    '& .MuiImageBackdrop-root': {
                        opacity: 0.05,
                    },
                    '& .MuiImageMarked-root': {
                        opacity: 0,
                    },
                    '& .MuiTypography-root': {
                        // border: '4px solid currentColor',
                    },
                },
            }}
            component={Link}
            to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {
                id: id
            })}
        >
            <ImageBackdrop className="MuiImageBackdrop-root"/>
            <Image>
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                        border: '4px solid currentColor',
                        position: 'relative',
                        fontWeight: 'bold',
                        letterSpacing: 2,
                        fontSize: (theme) => theme.spacing(2),
                        p: 6,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}
                >
                    {t('read_more')}
                </Typography>
            </Image>
            <Toolbar sx={{position: "absolute", top: 0, width: "100%"}}>
                <Box sx={{flexGrow: 1}}/>
                <ExhibitionStatus
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                />
            </Toolbar>
            <Box>
                <CardMedia
                    sx={{
                        width: "100%",
                        height: "auto",
                        py: !(!hideImage && image) ? 8 : 0,
                        px: !(!hideImage && image) ? 12 : 0,
                    }}
                    onError={() => setHideImage(true)}
                    component="img"
                    image={(!hideImage && image) ? image : defaultImage}
                    alt="exhibition logo"
                />
            </Box>
        </Paper>
    )
}

export default ExhibitionPreviewHorizontal;