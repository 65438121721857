import {useDispatch} from "react-redux";
import {useCallback} from "react";
import * as actions from "../../actions/actions";

export const useEntersoftTransferAction = () => {
	const dispatch = useDispatch()
	const transferExhibitorsFromEntersoft = useCallback(() => {
		dispatch(actions.transferExhibitorsFromEntersoft())
	}, [dispatch])
	const transferExhibitorsToEntersoft = useCallback((exhibitionId, ids = []) => {
		dispatch(actions.transferExhibitorsToEntersoft({
			id: exhibitionId,
			...(ids.length > 0 && {ids: ids})
		}))
	}, [dispatch])
	const clearTransferMessage = useCallback(() => {
		dispatch(actions.clearTransferMessage())
	}, [dispatch])
	return {
		transferExhibitorsFromEntersoft,
		transferExhibitorsToEntersoft,
		clearTransferMessage
	}
}