// @flow 
import React from 'react';
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {DYNAMIC_LIST_AS_TABLE_COMPONENT} from "../../../locales/components/namespaces";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";

type Props = {};

const DynamicListAsTable = (props: Props) => {
    const {
        id,
        row,
        rows = [],
        onAddRow,
        onEditRow,
        onSaveRow,
        onUpdateRow,
        onCancelEditRow,
        onDeleteRow,
        handleRows
    } = props
    const {t} = useTranslation(DYNAMIC_LIST_AS_TABLE_COMPONENT)
    const Row = row
    return (
        <>
            <Button
                color="primary"
                startIcon={<AddIcon/>}
                onClick={() => onAddRow(id)}
            >
                {normalizeGreek(t('Add'))}
            </Button>
            <List>
                {rows.map((row, rowIndex) => (
                    <ListItem key={rowIndex}>
                        <Row
                            rows={rows}
                            row={row}
                            handleRows={handleRows}
                            onUpdateRow={onUpdateRow}
                            onEditRow={onEditRow}
                            onSaveRow={onSaveRow}
                            onCancelEditRow={onCancelEditRow}
                            onDeleteRow={onDeleteRow}
                            rowIndex={rowIndex}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default DynamicListAsTable