import React from 'react';
import Context from './context';

const ContextProvider = (props) => {
  const { children } = props;
  const [dragMonitor, setDragMonitor] = React.useState();
  const [connectedDragSource, setConnectedDragSource] = React.useState();
  const [initialDepth, setInitialDepth] = React.useState();

  const value = {
    dragMonitor,
    connectedDragSource,
    setDragMonitor,
    setConnectedDragSource,
    initialDepth,
    setInitialDepth,
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default ContextProvider;
