export const TABLE_COMPONENT = 'cp_table'
export const HEADER_COMPONENT = 'cp_header'
export const DELETE_CONFIRMATION = 'cp_delete_confirmation'
export const EXHIBITOR_PROFILE = 'cp_exhibitor_profile'
export const VISITOR_PROFILE = 'cp_visitor_profile'
export const EXHIBITION = 'cp_exhibition'
export const RULES = 'cp_rules'
export const NAVIGATION = 'cp_navigation'
export const SEARCH_COMPONENT = 'cp_search';
export const DYNAMIC_LIST_AS_TABLE_COMPONENT = 'cp_dynamic_list_as_table'
export const EDITABLE_MUI_GRID = 'cp_editable_mui_grid'
export const GENERAL_SETTINGS = 'cp_general_settings'
export const AXIOS_ERROR_MESSAGE = 'cp_axios_error_message'
export const GRID_STATE = 'cp_grid_state'