import {useEffect} from "react";
import {useCoorganizationsAction} from "./useCoorganizationsAction";
import {useCoorganizationsData} from "./useCoorganizationsData";

export const useCoorganizations = () => {
    const {getCoorganizations} = useCoorganizationsAction()
    const {loading, coorganizations, updateGridSource, status} = useCoorganizationsData()
    useEffect(() => {
        getCoorganizations()
    }, [getCoorganizations, updateGridSource])

    return {
        coorganizations,
        loading,
        status
    }
}