import {useDispatch} from "react-redux";
import * as actions from '../../actions/actions'
import {useCallback} from "react";

export const useReportsAction = () => {
	const dispatch = useDispatch()
	const setReportMessage = useCallback((message) => {
		dispatch(actions.setReportMessage(message))
	}, [dispatch])
	const getReports = useCallback(({ids, reportId, id}) => {
		dispatch(actions.getReportData({
			...(id && {ExhibitionId: id}),
			...(ids && {ExhibitionIds: ids}),
			ReportId: reportId
		}))
	}, [dispatch])
	return {
		getReports,
		setReportMessage
	}
}