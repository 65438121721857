import React from 'react';
import {useDrag as dndUseDrag,} from 'react-dnd';
import context from './context';
import itemContext from './itemContext';

export default function useDrag(spec) {
    const connectedDragRef = React.useRef();
    const {setDragMonitor, setConnectedDragSource, setInitialDepth} = React.useContext(context);
    const {id, type, depth} = React.useContext(itemContext);
    const [
        collectedProps,
        originalDonnectDragSource,
        connectDragPreview
    ] = dndUseDrag({
        ...spec,
        type,
        collect: (monitor) => {
            const $isDragging = monitor.isDragging();
            return {
                ...(spec && spec.collect ? spec.collect(monitor) : undefined),
                $isDragging,
            };
        },
        isDragging: (monitor) => monitor.getItem().id === id,
        item(monitor) {
            setInitialDepth(depth);
            setDragMonitor(monitor);
            return {
                id
            }
        },
        end(...args) {
            setInitialDepth(undefined);
            setDragMonitor(undefined);

            if (spec && spec.end) {
                spec.end(...args);
            }
        }
    });

    const connectDragSource = (...args: Parameters<typeof originalDonnectDragSource>) => {
        const result = originalDonnectDragSource(...args);
        connectedDragRef.current = result;
        return result;
    };
    const {$isDragging, ...rest} = collectedProps;
    React.useEffect(() => {
        if ($isDragging) {
            setConnectedDragSource(connectedDragRef);
        }
    }, [$isDragging, setConnectedDragSource]);
    return [
        // @ts-ignore
        rest,
        connectDragSource,
        connectDragPreview,
    ]
}
