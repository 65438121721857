// @flow
import React, {Fragment} from 'react';
import {FormControl, FormHelperText} from "@mui/material";
import TextField from "@mui/material/TextField";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import {ErrorMessage} from "formik";
import ChangeVisitorCharacterization from "../../changeApprovalStatus/changeVisitorCharacterization";
import type {VisitorCategory} from "../../../../types/categories/type";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../locales/components/namespaces";
import SelectCountry from "../../../form/selectCountries/selectCountries";
import type {Exhibition} from "../../../../types/exhibition/types";
import type {Countries} from "../../../../types/countries/type";

type Props = {
    formik: Object,
    limitedView?: boolean,
    exhibitionId: string,
    exhibition: Object<Exhibition>,
    isShortOrganizerAddVisitor?: boolean,
    countries?: Array<Countries>
};

const FormFields = (props: Props) => {
    const {
        formik,
        limitedView = false,
        exhibitionId,
        exhibition,
        isShortOrganizerAddVisitor,
        countries
    } = props
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    return (
        <Fragment>
            <FormControl
                sx={{mb: 1}}
                variant="standard"
                fullWidth
                error={
                    formik.touched["name"] &&
                    Boolean(formik.errors["name"])
                }
            >
                <TextField
                    id="outlined-name"
                    label={t("Name")}
                    value={formik.values.name}
                    name={`name`}
                    size={"small"}
                    onChange={(e) => {
                        const oldValue = e.target.value
                        e.target.value = normalizeGreek(oldValue.toUpperCase())
                        formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    fullWidth
                    required
                    autoComplete={"off"}
                    InputLabelProps={{shrink: true}}
                />
                <FormHelperText>
                    <ErrorMessage name={"name"}/>
                </FormHelperText>
            </FormControl>
            {isShortOrganizerAddVisitor && (
                <FormControl
                    sx={{mb: 1}}
                    variant="standard"
                    fullWidth
                    error={
                        formik.touched["companyName"] &&
                        Boolean(formik.errors["companyName"])
                    }
                >
                    <TextField
                        id="outlined-name"
                        label={t("Company Name")}
                        value={formik.values.companyName}
                        name={`companyName`}
                        size={"small"}
                        onChange={(e) => {
                            const oldValue = e.target.value
                            e.target.value = normalizeGreek(oldValue.toUpperCase())
                            formik.handleChange(e)
                        }}
                        onBlur={formik.handleBlur}
                        fullWidth
                        autoComplete={"off"}
                        InputLabelProps={{shrink: true}}
                    />
                    <FormHelperText>
                        <ErrorMessage name={"companyName"}/>
                    </FormHelperText>
                </FormControl>
            )}
            <FormControl
                sx={{mb: 1}}
                variant="standard"
                fullWidth
                error={
                    formik.touched["email"] &&
                    Boolean(formik.errors["email"])
                }
            >
                <TextField
                    id="outlined-email"
                    label={t("Email")}
                    value={formik.values.email}
                    name={`email`}
                    size={"small"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    required={!isShortOrganizerAddVisitor}
                    InputLabelProps={{shrink: true}}
                    autoComplete={"off"}
                />
                <FormHelperText>
                    <ErrorMessage name={"email"}/>
                </FormHelperText>
            </FormControl>
            {!isShortOrganizerAddVisitor && (
                <FormControl
                    variant="standard"
                    fullWidth
                    error={
                        formik.touched["mobilePhone"] &&
                        Boolean(formik.errors["mobilePhone"])
                    }
                    sx={{mb: 1}}
                >
                    <TextField
                        id="outlined-mobilePhone"
                        label={t("Mobile Phone")}
                        type={'number'}
                        value={formik.values.mobilePhone}
                        name={`mobilePhone`}
                        size={"small"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        autoComplete={"off"}
                    />
                    <FormHelperText>
                        <ErrorMessage name={"mobilePhone"}/>
                    </FormHelperText>
                </FormControl>
            )}
            {isShortOrganizerAddVisitor && (
                <FormControl
                    variant="standard"
                    fullWidth
                    error={
                        formik.touched["countries"] &&
                        Boolean(formik.errors["countries"])
                    }
                    sx={{mb: 1}}
                >
                    <SelectCountry
                        formik={formik}
                        label={t("Country")}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('countries', newValue)
                        }}
                        value={formik.values.countries}
                        onBlur={formik.handleBlur}
                        countries={countries}
                        required={true}
                        name={`countries`}
                        shrink={true}
                        autoComplete={"off"}
                    />
                    <FormHelperText>
                        <ErrorMessage name={"countries"}/>
                    </FormHelperText>
                </FormControl>
            )}
            {!limitedView && (
                <>
                    <FormControl
                        sx={{mb: 1}}
                        variant="standard"
                        fullWidth
                        error={
                            formik.touched["vatNumber"] &&
                            Boolean(formik.errors["vatNumber"])
                        }
                    >
                        <TextField
                            label={t("VAT Number")}
                            value={formik.values.vatNumber}
                            name={`vatNumber`}
                            size={"small"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            autoComplete={"off"}
                        />
                        <FormHelperText>
                            <ErrorMessage name={"vatNumber"}/>
                        </FormHelperText>
                    </FormControl>
                    <ChangeVisitorCharacterization
                        shrink={true}
                        exhibitionId={exhibitionId}
                        onChange={(category: VisitorCategory) => {
                            formik.setFieldValue('characterization', category ? category.id : '')
                        }}
                        selected={formik.values.characterization}
                        formik={formik}
                        required={!limitedView && exhibition?.visitorCategoryRequiredInImport}
                    />
                </>
            )}
            {!isShortOrganizerAddVisitor && (
                <FormControl
                    sx={{mb: 1, mt: 1}}
                    variant="standard"
                    fullWidth
                    error={
                        formik.touched["city"] &&
                        Boolean(formik.errors["city"])
                    }
                >
                    <TextField
                        id="outlined-name"
                        label={t("City")}
                        value={formik.values.city}
                        name={`city`}
                        size={"small"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        autoComplete={"off"}
                        InputLabelProps={{shrink: true}}
                    />
                    <FormHelperText>
                        <ErrorMessage name={"city"}/>
                    </FormHelperText>
                </FormControl>
            )}
        </Fragment>
    );
};

export default FormFields