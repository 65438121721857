//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import type {Rule} from "../../types/rules/types";
import {parseRule, parseRuleDetails, parseStatus} from "../../types/rules/parse";

const selectLoading = createSelector(
    (state) => state.rules.get('loading'),
    (item) => item
)

const selectUpdateRuleGridSource = createSelector(
    (state) => state.rules.get('updateRuleGridSource'),
    (item) => item
)

const selectUpdateRuleDetailsSource = createSelector(
    (state) => state.rules.get('updateRuleDetailsSource'),
    (item) => item
)

const selectStatus = createSelector(
    (state) => state.rules.get('status'),
    (item) => parseStatus(item)
)

const selectItems = createSelector(
    [
        (state) => state.rules.get('byId'),
    ],
    (data) => parseMultiple(data, (i) => parseRule(i))
)

const selectById = createSelector(
    (state, id) => state.rules.getIn(['byId', id, 'details']),
    (details) => parseMultiple(details, (i) => parseRuleDetails(i))
)

export const useRulesData = (ruleId) => {
    const loading: boolean = useSelector(selectLoading)
    const rules: Array<Rule> = useSelector(selectItems)
    const status = useSelector(selectStatus)
    const updateRuleGridSource = useSelector(selectUpdateRuleGridSource)
    const ruleDetails = useSelector((state) => selectById(state, ruleId))
    const updateRuleDetailsSource = useSelector(selectUpdateRuleDetailsSource)

    return {
        loading,
        rules,
        status,
        updateRuleGridSource,
        ruleDetails,
        updateRuleDetailsSource
    }
}