//@flow
import React from 'react'
import Row from '../row/row'

type Props = {
    data: Array<{
        id: string,
        parentId: string,
        description: string,
        hasChildren: boolean
    }>,
    parentId?: string,
    level?: number,
    onClick: Function,
}

const RowList = (props: Props) => {
    const {
        data,
        parentId = null,
        level = 0,
        onClick,
        hasNodeIcon
    } = props

    const items = data.filter(item => item.parentId === parentId)
    if (items.length === 0) return null

    return items.map(item => (
        <Row key={item.id} item={item} level={level} onClick={onClick} hasNodeIcon={hasNodeIcon}>
            <RowList data={data} parentId={item.id} level={level + 1} onClick={onClick}/>
        </Row>
    ))
}

export default RowList