// @flow
import React from 'react';
import {ListSubheader} from "@mui/material";
import VisitorCategoryFieldRequiredInImport from "./components/visitorCategoryFieldRequiredInImport";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

type Props = {};

const ImportSettings = (props: Props) => {
	const {id, exhibition, updateExhibition} = props
	return (
		<List
			subheader={<ListSubheader sx={{backgroundColor: '#F9FBF2'}}><Typography variant={'button'}
																					sx={{color: '#000000'}}>Imports</Typography></ListSubheader>}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<VisitorCategoryFieldRequiredInImport
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
		</List>
	);
};

export default ImportSettings