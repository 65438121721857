//@flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import type {Exhibition, Exhibitor} from "../../../types/exhibition/types";
import Box from "@mui/material/Box";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import TextField from "@mui/material/TextField";
import RoomIcon from "@mui/icons-material/Room";
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import {FormControl, FormHelperText} from "@mui/material";
import {ErrorMessage} from "formik";

type Props = {
	exhibitor: Exhibitor,
	exhibition: Exhibition
}

const CatalogHeader = (props: Props) => {
	const {exhibitor, exhibition, formik, disabled, inputVariant} = props
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	const items = [
		{
			icon: HomeWorkIcon,
			key: t('Stand Number'),
			value: exhibitor.kiosk,
		},
		
		{
			icon: RoomIcon,
			key: t('Hall'),
			value: exhibitor.room,
		},
	
	]
	const kioskSignDisabled = useMemo(() => (disabled || !exhibition.isKioskSignEditable || exhibitor.isKioskSignReadOnly), [disabled, exhibition.isKioskSignEditable, exhibitor.isKioskSignReadOnly])
	return (
		<Box>
			{items.map((i) => {
				const {icon: Icon} = i
				return (
					<Box key={i.key} sx={{display: 'flex', alignItems: 'center'}}>
						<Icon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
						<TextField
							sx={{mb: .5}}
							fullWidth
							disabled
							variant={"standard"}
							size={"small"}
							label={i.key}
							value={i.value}
						/>
					</Box>
				);
			})}
			{exhibition.catalogEnableKioskSign && (
				<Box sx={{display: 'flex', alignItems: 'center'}}>
					<CalendarViewDayIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
					<FormControl
						variant="standard"
						color={'error'}
						fullWidth
						error={
							formik.touched['kioskSign'] &&
							Boolean(formik.errors['kioskSign'])
						}
					>
						<TextField
							InputLabelProps={{shrink: true}}
							disabled={kioskSignDisabled}
							fullWidth
							size={"small"}
							variant={inputVariant}
							label={t('Kiosk Sign')}
							name={'kioskSign'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							inputProps={{maxLength: 250}}
							value={formik.values.kioskSign}
						/>
						<FormHelperText sx={{color: '#d32f2f'}}>
							<ErrorMessage name={'kioskSign'}/>
						</FormHelperText>
					</FormControl>
				</Box>
			)}
		</Box>
	)
}

export default CatalogHeader;