import * as React from 'react';
import {useMemo} from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useUserAction from "../../hooks/user/useUserAction";
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
    ROUTE_PAGE_CATALOGUE_EXPORTS,
    ROUTE_PAGE_COORGANIZATIONS,
    ROUTE_PAGE_ENTERSOFT_TRANSFER,
    ROUTE_PAGE_GENERAL_REPORTS,
    ROUTE_PAGE_HOME
} from "../../routers/routes";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../locales/components/namespaces";
import {ListItemButton} from "@mui/material";
import {Analytics, ImportExport, MoveUp, SettingsApplications} from "@mui/icons-material";
import {Permissions} from "../../helpers/constants";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";

const MenuItem = (props) => {
    const {url, icon, title, open} = props
    return (
        <ListItem
            sx={{display: 'block'}}
            disablePadding
        >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
                component={Link}
                to={url}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={title}
                    sx={{opacity: open ? 1 : 0}}
                />
            </ListItemButton>
        </ListItem>
    )
}

const MainNavigation = ({open}) => {
    const {t} = useTranslation(NAVIGATION)
    const {userLogout} = useUserAction()
    const items = useMemo(() => [
            {
                url: getRouteUrl(ROUTE_PAGE_HOME),
                title: t("Home"),
                icon: <HomeIcon/>,
            }, {
                url: getRouteUrl(ROUTE_PAGE_ENTERSOFT_TRANSFER),
                title: t("EnterSoft Transfer"),
                icon: <MoveUp/>,
                permissions: [Permissions.BeOrganizer]
            }, {
                url: getRouteUrl(ROUTE_PAGE_COORGANIZATIONS),
                title: t("Coorganizations"),
                icon: <SettingsApplications/>,
                permissions: [Permissions.BeOrganizer]
            }, {
                url: getRouteUrl(ROUTE_PAGE_CATALOGUE_EXPORTS),
                title: t("Catalogue Exports"),
                icon: <ImportExport/>,
                permissions: [Permissions.BeOrganizer]
            }, {
                url: getRouteUrl(ROUTE_PAGE_GENERAL_REPORTS),
                title: t("General Reports"),
                icon: <Analytics/>,
                permissions: [Permissions.SeeReports]
            },
        ]
        , [t])
    return (
        <React.Fragment>
            <Divider/>
            <List>
                {items.map(({icon, url, title, permissions}) => permissions && permissions.length > 0 ? (
                    <PermissionHOC key={url} requiredPermissions={[...permissions]}>
                        <MenuItem
                            url={url}
                            title={title}
                            icon={icon}
                            open={open}
                        />
                    </PermissionHOC>
                ) : (
                    <MenuItem
                        key={url}
                        url={url}
                        title={title}
                        icon={icon}
                        open={open}
                    />
                ))}
            </List>
            <Divider/>
            <List>
                <ListItem button onClick={userLogout}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("Logout")}/>
                </ListItem>
            </List>
        </React.Fragment>
    )
}

export default MainNavigation;
