export const visitorBodyParameters = ({
                                          isRegistration,
                                          exhibitionId,
                                          exhibitorId,
                                          visitorId,
                                          isUpdate,
                                          sendMessages
                                      }, values) => {

    let characterization = ""
    if(values.characterization) {
        if(typeof values.characterization === 'string') {
            characterization = values.characterization
        } else if(values.characterization.id) {
            characterization = values.characterization.id
        }
    }

    return ({
        ExhibitionId: exhibitionId,
        ...(values.visitorId ? {id: values.visitorId} : {}),
        ...(values.visitorApproved ? {VisitorApproved: values.visitorApproved} : {}),
        ...(values.id && isUpdate ? {ContactId: values.id} : {}),
        ...(values.characterization ? {CharacterizationId: characterization} : {}),
        ...(values.city ? {City: values.city} : {}),
        ...(values.companyAddress ? {CompanyAddress: values.companyAddress} : {}),
        ...(values.companyName ? {CompanyName: values.companyName} : {}),
        ...(values.companyWebsite ? {CompanyWebSite: values.companyWebsite} : {}),
        ...(values.instagram ? {Instagram: values.instagram} : {}),
        ...(values.facebook ? {Facebook: values.facebook} : {}),
        ...(values.countries ? {CountryId: values.countries && values.countries.id ? values.countries.id : ''} : {}),
        ...(values.email ? {email: values.email} : {}),
        ...(values.hasRevisited ? {hasRevisited: values.hasRevisited} : {}),
        ...(values.interests ? {Interests: values.interests.length > 0 ? values.interests.map(v => v.id).toString() : ""} : {}),
        ...(values.isIndividual ? {isIndividual: values.isIndividual} : {}),
        ...(exhibitorId ? {ExhibitorId: exhibitorId} : {}),
        ...(values.organizerApprovalStatus ? {organizerApprovalStatus: values.organizerApprovalStatus} : {}),
        ...(values.organizerApprovalRejectReason ? {organizerApprovalRejectReason: values.organizerApprovalRejectReason} : {}),
        ...(values.vatNumber ? {VatNumber: values.vatNumber} : {}),
        ...(values.name ? {Name: values.name} : {}),
        ...(values.phone ? {Phone: values.phone} : {}),
        ...(values.companyFirm ? {CompanyFirm: values.companyFirm} : {}),
        ...(values.mobilePhone ? {MobilePhone: values.mobilePhone} : {}),
        ...(values.postalCode ? {PostalCode: values.postalCode} : {}),
        ...(values.personCount ? {PersonCount: values.personCount} : {}),
        ...(values.otherInterests ? {OtherInterests: values.otherInterests} : {}),
        ...(values.position ? {Position: values.position} : {}),
    })
}

export const findCountryCode = (currentPhoneCode, mobilePhone, phoneCodes) => {
    let countryPhoneCode = null
    if (!String(mobilePhone).startsWith(String(currentPhoneCode))) {
        let found = false;
        let foundAt = 0;
        for (let i = 0; i < phoneCodes.length; i++) {
            if (phoneCodes[i].phoneCode !== '' && mobilePhone.startsWith(phoneCodes[i].phoneCode)) {
                if (!found) {
                    foundAt = i;
                    found = true;
                } else {
                    found = false;
                    break;
                }
            }
        }
        if (found) {
            countryPhoneCode = phoneCodes[foundAt]
        }
    }
    return countryPhoneCode
}

export const showOption = (language) => (option) => {
    if (typeof option === 'string') {
        return option
    }
    if (typeof option.description === 'string') {
        return option.description
    }
    if (!option.description) {
        return option[language]
    }
    return option.description[language]
}

export const searchFieldMappings = {
    name: "Name",
    email: "email",
    phone: "Phone",
    mobilePhone: "MobilePhone",
    vatNumber: "vatNumber",
    companyName: "CompanyName",
    companyFirm: "CompanyFirm"
}
