//@flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {useVisitor} from "../../../../../hooks/exhibitions/useVisitor";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatDate} from "../../../../../helpers/date";
import VisitorStatus from "../../../../../components/app/visitorStatus/visitorStatus";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import {ROUTE_PAGE_PROFILE_EXHIBITOR} from "../../../../../routers/routes";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {Link} from "react-router-dom";

type Props = {
    id?: string,
    circularLoader?: boolean
}

const VisitorReferences = (props: Props) => {
    const {id, exhibitionId, circularLoader} = props
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {exhibitorReferences, loading} = useVisitor({
        overrideVisitorId: id,
        overrideExhibitionId: exhibitionId,
        doNotFetchProfile: true,
        doNotFetchCategories: true,
    })

    return (
        <React.Fragment>
            {exhibitorReferences && exhibitorReferences.length > 0 ? (
                <TableContainer component={Paper} sx={{p: 2}}>
                    <Table sx={{width: "100%"}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Invited By')}</TableCell>
                                <TableCell>{t('Invitation Date')}</TableCell>
                                <TableCell>{t('Status')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {exhibitorReferences.map((reference) => (
                                <TableRow
                                    key={reference.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link
                                            to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
                                                id: exhibitionId,
                                                exhibitorId: reference.exhibitor.id
                                            })}
                                        >
                                            {reference.exhibitor.name}
                                        </Link>
                                    </TableCell>
									<TableCell>{formatDate(reference.invitationDate, formatDate.formatTypes.FULL_DESCRIPTION)}</TableCell>
                                    <TableCell><VisitorStatus
                                        status={reference.statusId}/></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <ContentLoader
                    loading={loading}
                    useCircularLoader={circularLoader}
                />
            )}
        </React.Fragment>
    )
}

export default VisitorReferences
