import {useParams} from "react-router";
import useExhibitionAction from "./useExhibitionAction";
import useExhibitionData from "./useExhibitionData";
import {useEffect} from "react";
import {useExhibitionFileAction} from "../exhibitionFIles/useExhibitionFileAction";
import {useExhibitionFileData} from "../exhibitionFIles/useExhibitionFileData";
import _ from "lodash";

export const useExhibition = ({language, id: propsId, noFetchFiles, getPublic} = {}) => {
    const {id: paramsId} = useParams()
    const id = propsId ?? paramsId
    const {getExhibitionDetails, getExhibitionDetailsPublic} = useExhibitionAction()
    const {files: unsortedFiles, loading: loadingFiles} = useExhibitionFileData({exhibitionId: id, language})
    const {getExhibitionFiles} = useExhibitionFileAction()
    const {byId: exhibition, loading} = useExhibitionData({id, overrideLanguage: language})
    useEffect(() => {
        if (getPublic) {
            getExhibitionDetailsPublic(id)
        } else {
            getExhibitionDetails(id)
        }
    }, [getExhibitionDetails, getExhibitionDetailsPublic, getPublic, id])
    useEffect(() => {
        if (!noFetchFiles) {
            getExhibitionFiles(id)
        }
    }, [getExhibitionFiles, id, noFetchFiles])
    const files = _.sortBy(unsortedFiles, (file) => file.fileDisplayOrder)
    return {
        id,
        exhibition,
        files,
        loadingFiles,
        loading
    }
}