// @flow 
import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {ClearOutlined} from "@mui/icons-material";

type Props = {};

const GridMultiColumnSearch = (props: Props) => {
	const {t, customSearch, setCustomSearch, refreshGrid} = props
	const clearSearch = async () => {
		await setCustomSearch('')
	}
	return (
		<Box display={'flex'} flexWrap={'wrap'}>
			<Box display={'flex'} alignItems={'center'} mr={1} pb={1}>
				<Typography
					variant={'subtitle1'}
					component={'span'}
					pr={1}
				>
					{t('General Search')}
				</Typography>
				<TextField
					sx={{minWidth: '300px'}}
					value={customSearch}
					name={'customSearch'}
					onChange={(evt) => {
						setCustomSearch(evt.target.value)
					}}
					size={'small'}
					inputProps={{maxLength: 250}}
					variant="standard"
				/>
			</Box>
			<Box display={'flex'} alignItems={'center'} pb={1}>
				<Button
					sx={{marginRight: 1}}
					size={'small'}
					color={'success'}
					startIcon={<SearchIcon/>}
					onClick={() => refreshGrid()}
					variant={'outlined'}
				>
					{normalizeGreek(t('Search'))}
				</Button>
				<Button
					startIcon={<ClearOutlined/>}
					size={'small'}
					color={'error'}
					variant={'outlined'}
					onClick={() => {
						clearSearch().then(res => refreshGrid())
					}}
				>
					{normalizeGreek(t('Clear'))}
				</Button>
			</Box>
		</Box>
	);
};

export default GridMultiColumnSearch