//@flow
import {useTranslation} from 'react-i18next'
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";
import Pagination from "../pagination/pagination";
import {Fragment} from "react";
import ContentLoader from "../../../../general/contentLoader/contentLoader";
import Grid from "@mui/material/Grid";

type Props = {
    usingInfiniteScroll?: boolean,
    noMeta?: boolean,
    noPagination?: boolean,
    renderRow: Function,
    initialState: {
        pageIndex: number,
        pageSize: number,
    }
}

const UnstructuredTableView = (props: Props) => {
    const {t} = useTranslation(TABLE_COMPONENT)
    const {
        usingInfiniteScroll,
        noPagination,
        noMeta,
        renderRow,
        loading,
        onPageSizeChange,
        pageCount: controlledPageCount,
    } = props
    const {
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize
    } = props.table
    return (
        <Fragment>
            {page.map((row, index, array) => {
                prepareRow(row)
                return renderRow(row, index, array)
            })}
            <Grid item xs={12}>
                {loading ?
                    <ContentLoader
                        loading={loading}
                        useCircularLoader
                    />
                    : page.length > 0 ?
                        !noMeta && !usingInfiniteScroll && (
                            <span>
                                {t('Showing')} {page.length} {t('of')} ~{controlledPageCount * pageSize}{' '}
                                {t('results')}
                            </span>
                        )
                        : <span>{t('no_results')}</span>}
            </Grid>
            {!noPagination && !usingInfiniteScroll && (
                <Pagination
                    pageSize={pageSize}
                    setPageSize={(size) => {
                        setPageSize(size)
                        if (onPageSizeChange) {
                            onPageSizeChange(size)
                        }
                    }}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    previousPage={previousPage}
                />
            )}
        </Fragment>
    )
}

export default UnstructuredTableView