// @flow 
import React from 'react';
import ActionStatus from "../../../../../../components/app/alerts/actionStatus/actionStatus";
import FormFields from "../../../../../../components/app/addVisitor/partials/formFields";
import useAddVisitorForm from "../../../../../../components/app/addVisitor/hooks/useAddVisitorForm";
import {FormikProvider} from "formik";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../../../locales/components/namespaces";
import {Print} from "@mui/icons-material";
import {normalizeGreek} from "../../../../../../helpers/normalizeGreek";
import LoadingButton from "@mui/lab/LoadingButton";

type Props = {};

const Form = (props: Props) => {
    const {
        loading,
        exhibitionId,
        exhibition,
        createVisitor,
        actionOnVisitorStatus,
        clearActionOnVisitorStatus,
        countries
    } = props
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {formik} = useAddVisitorForm({
        exhibition,
        exhibitionId,
        createVisitor,
        isShortOrganizerAddVisitor: true,
        clearActionOnVisitorStatus,
        visitorApproved: true
        // defaultCountry,
        // characterizations
    })
    return (
        <FormikProvider value={formik}>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                <ActionStatus
                    status={actionOnVisitorStatus}
                    onClose={clearActionOnVisitorStatus}
                />
                <FormFields
                    formik={formik}
                    exhibitionId={exhibitionId}
                    exhibition={exhibition}
                    isShortOrganizerAddVisitor={true}
                    countries={countries}
                />
                <LoadingButton
                    sx={{mt: 2}}
                    size={"small"}
                    startIcon={<Print/>}
                    variant="contained"
                    color="success"
                    type={'submit'}
                    loading={loading}
                    loadingPosition="start"
                >
                    {normalizeGreek(t('Save Visitor And Print Badge'))}
                </LoadingButton>
            </Box>
        </FormikProvider>
    )
};

export default Form