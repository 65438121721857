// @flow
import * as React from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../../../locales/components/namespaces";

type Props = {
    visitorCategoryRequiredInImport: boolean,
    limitedView: boolean
};

export const Exhibitor = ({limitedView, visitorCategoryRequiredInImport}: Props) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    return (
        <TableContainer component={Paper} variant={"outlined"} sx={{mb: 1}}>
            <Table sx={{minWidth: 650}} size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Name')}</TableCell>
                        <TableCell>{t('Email')}</TableCell>
                        <TableCell>{t('Mobile Phone')} ({t('optional')})</TableCell>
                        <TableCell>{t('City')} ({t('optional')})</TableCell>
                        {!limitedView && <TableCell>{`${t('Characterization')} ${!visitorCategoryRequiredInImport ? t('optional') : ''}`}</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell>ALEXANDRA ALEXANDRI</TableCell>
                        <TableCell>someone@email.com</TableCell>
                        <TableCell><strong>30</strong>6996969696</TableCell>
                        <TableCell>Patra</TableCell>
                        {!limitedView && <TableCell>VIP</TableCell>}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};