import useExhibitionAction from "../exhibitions/useExhibitionAction";
import {useRef} from "react";
import {usePreregistrationForm} from "../usePreregistrationForm";

const useVisitorForm = ({
                            id,
                            exhibition,
                            countries,
                            interests,
                            phoneCodes,
                            defaultCountry,
                            defaultPhoneCode,
                            categories,
                            preregistrationFormValues
                        }) => {
    const companyNameFieldRef = useRef(null)
    const nameFieldRef = useRef(null)
    const surnameFieldRef = useRef(null)
    const vatFieldRef = useRef(null)
    const emailFieldRef = useRef(null)
    const mobilePhoneFieldRef = useRef(null)
    const phoneFieldRef = useRef(null)
    const companyAddressFieldRef = useRef(null)
    const cityFieldRef = useRef(null)
    const companyWebsiteFieldRef = useRef(null)
    const instagramFieldRef = useRef(null)
    const facebookFieldRef = useRef(null)
    const positionFieldRef = useRef(null)
    const personNumberFieldRef = useRef(null)
    const postalCodeFieldRef = useRef(null)
    const otherInterestsFieldRef = useRef(null)

    const {createVisitor} = useExhibitionAction()
    const onSubmit = (values) => {
        createVisitor(exhibition.id, null, {
            ...values.fieldValues,
            otherInterests: otherInterestsFieldRef.current?.value
        }, false, true)

    }
    const {
        formik,
        fieldValues,
        fields
    } = usePreregistrationForm({
        exhibition: exhibition,
        onSubmit: onSubmit,
        preregistrationFormValues: preregistrationFormValues,
        countries: countries,
        interests: interests,
        categories: categories,
        phoneCodes: phoneCodes
    })

    const submitForm = (evt) => {
        formik.submitForm(evt)
    }

    const onPhoneCodeChange = (event, newValue) => {
        formik.setFieldValue('fieldValues.countryPhoneCode', newValue)
        if (newValue) {
            mobilePhoneFieldRef.current.value = newValue.phoneCode
        }
    }

    return {
        companyNameFieldRef,
        nameFieldRef,
        surnameFieldRef,
        vatFieldRef,
        emailFieldRef,
        mobilePhoneFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        instagramFieldRef,
        facebookFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef,
        otherInterestsFieldRef,
        formik,
        fields,
        fieldValues,
        submitForm,
        onPhoneCodeChange
    }
}

export default useVisitorForm