import {useCallback} from "react";
import * as actions from "../../actions/actions";
import {useDispatch} from "react-redux";
import jsonToFormData from "json-form-data";
import {v4 as uuidv4} from 'uuid';

export const useExhibitionFileAction = () => {
    const dispatch = useDispatch()

    const getExhibitionFiles = useCallback((exhibitionId, exhibitorId, messageTemplateTypeId) => {
        dispatch(actions.getExhibitionFiles({
            ExhibitionId: exhibitionId,
            MessageTemplateTypeId: messageTemplateTypeId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {})
        }))
    }, [dispatch])

    const getExhibitionFileDetails = useCallback((exhibitionFileId, exhibitionId) => {
        dispatch(actions.getExhibitionFileDetails({
            ExhibitionId: exhibitionId,
            id: exhibitionFileId,
        }))
    }, [dispatch])

    const createExhibitionFile = useCallback(({exhibitionId, exhibitorId, typeId, messageTemplateTypeId}, values, language) => {
        const dataToSend = {
            ExhibitionId: exhibitionId,
            MessageTemplateTypeId: messageTemplateTypeId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {}),
            ...(language ? {AttachmentLang: language.toUpperCase()} : {}),
            ...(typeId ? {TypeId: typeId} : {}),
            file: values,
            temporaryId: uuidv4()
        }
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.createExhibitionFile(formData))
    }, [dispatch])

    const updateExhibitionFile = useCallback(({exhibitionId, exhibitorId}, file, description, fileDisplayOrder) => {
        dispatch(actions.updateExhibitionFile({
            ExhibitionId: exhibitionId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {}),
            id: file.id,
            Description: description,
            Ordr: fileDisplayOrder
        }))
    }, [dispatch])

    const deleteExhibitionFile = useCallback(({exhibitionId, exhibitorId}, fileId) => {
        dispatch(actions.deleteExhibitionFile({
            ExhibitionId: exhibitionId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {}),
            id: fileId,
        }))
    }, [dispatch])

    const reorderExhibitionFiles = useCallback((exhibitionFiles, exhibitionId) => {
        dispatch(actions.reorderExhibitionFiles({
            ExhibitionId: exhibitionId,
            exhibitionFiles: exhibitionFiles
        }))
    }, [dispatch])

    return {
        reorderExhibitionFiles,
        getExhibitionFiles,
        getExhibitionFileDetails,
        createExhibitionFile,
        updateExhibitionFile,
        deleteExhibitionFile
    }
}