//@flow
import React, {useContext, useEffect, useMemo} from 'react';
import {TitleContext} from "../../../pages/main/content";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {eventStatus, formatDate} from "../../../helpers/date";
import {useMultilingualText} from "../../../hooks/useMultilingualText";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import useUserData from "../../../hooks/user/useUserData";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import {Trans} from "react-i18next";
import dayjs from "dayjs";

const PageTitleContent = ({alertDate, exhibitionTitle}) => {
	const title = useMultilingualText(exhibitionTitle)
	return (
		<Typography variant="h6" component="div">
			<Box>
				{normalizeGreek(title.toUpperCase())}
			</Box>
			{alertDate}
		</Typography>
	)
}

const AlertForCatalogLock = ({exhibition, exhibitor}) => {
	const {setTitle} = useContext(TitleContext)
	const {language, dateFormat} = useUserData()
	const {
		catalogLocked,
		daysForCatalogSubmission,
		dateFrom,
		dateTo,
		daysForCatalogNotification,
		title
	} = exhibition
	
	const alertDate = useMemo(() => {
		const status = eventStatus(dateFrom, dateTo)
		const dayBeforeCatalogSubmission = dayjs(dateFrom).subtract(daysForCatalogSubmission, 'd')
		const dayForCatalogNotification = dayjs(dayBeforeCatalogSubmission).subtract(daysForCatalogNotification, 'd')
		const canFillCatalogIsSame = dayjs().isSame(dayBeforeCatalogSubmission, 'day')
		const canFillCatalogIsBefore = dayjs().isBefore(dayBeforeCatalogSubmission, 'day')
		const canFillCatalog = canFillCatalogIsBefore || canFillCatalogIsSame
		const alertForCatalog = (dayjs().isSame(dayForCatalogNotification, 'day') || dayjs().isAfter(dayForCatalogNotification, 'day')) && (dayjs().isSame(dayBeforeCatalogSubmission, 'day') || dayjs().isBefore(dayBeforeCatalogSubmission, 'day'))
		const catalogDisabled = catalogLocked || status !== eventStatus.status.UPCOMING || !canFillCatalog
		
		// const dayBeforeCatalogSubmission = dayjs(dateFrom).subtract(daysForCatalogSubmission, 'd')
		// const dayForCatalogNotification = dayjs(dayBeforeCatalogSubmission).subtract(daysForCatalogNotification, 'd')
		// const canFillCatalog = dayjs().isSameOrBefore(dayBeforeCatalogSubmission, 'day')
		// const alertForCatalog = dayjs().isSameOrAfter(dayForCatalogNotification, 'day') && dayjs().isSameOrBefore(dayBeforeCatalogSubmission, 'day')
		// const catalogDisabled = catalogLocked || status !== eventStatus.status.UPCOMING || !canFillCatalog
		
		return !catalogDisabled && alertForCatalog && (
			<Typography variant="body2" component="div">
				<Box sx={{color: '#e53935'}}>
					<Trans
						i18nKey={'alert_for_catalog_submission'}
						ns={EXHIBITOR_PROFILE}
						values={{
							date: formatDate(dayBeforeCatalogSubmission, dateFormat)
						}}
						defaults={"<em>Last Date For Catalogue Submission <strong>{{date}}</strong>!</em>"}
					/>
				</Box>
			</Typography>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [catalogLocked, dateFrom, dateTo, daysForCatalogNotification, daysForCatalogSubmission, language])
	useEffect(() => {
		setTitle(
			<PageTitleContent
				alertDate={alertDate}
				language={language}
				exhibitionTitle={title}
			/>
		)
		return () => {
			setTitle(null)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, alertDate])
	return null
}

export default AlertForCatalogLock;