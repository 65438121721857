import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {parseMultiple} from "../../helpers/parse";
import {parseExhibitionImage} from "../../types/exhibitionFiles/parse";
import type {ExhibitionImage} from "../../types/exhibitionFiles/types";
import {parseUserMessage} from "../../types/user/parse";

const selectLoading = createSelector(
	(state) => state.exhibitionFiles,
	(item) => item.get('loading')
)

const selectFiles = createSelector(
	[
		(state, exhibitionId) => state.exhibitionFiles.getIn(['imagesByExhibitionId', exhibitionId])
	],
	(data) => parseMultiple(data, (i) => parseExhibitionImage(i))
)

const selectMessage = createSelector(
	(state) => state.exhibitionFiles.get('message'),
	(item) => parseUserMessage(item)
)

export const useExhibitionImageData = (id) => {
	const loading: boolean = useSelector(selectLoading)
	const images: Array<ExhibitionImage> = useSelector((state) => selectFiles(state, id))
	const message = useSelector(selectMessage)
	return {
		images,
		loading,
		message
	}
}