// @flow
import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import {Switch} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
};

const DisplayVisitorsToExhibitor = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props

    const toggleDisplayVisitorsToExhibitor = () => {
        updateExhibition(id, !exhibition.displayVisitorsToExhibitor, Update.ExhibitorVisitors)
    }
    return (
        <ListItem>
            <ListItemText
                primary={t('Display Visitors Tab To Exhibitor')}
                secondary={t('If you disable this, then exhibitors will not be able to access visitor list from their profile.')}
            />
            <Switch
                edge="end"
                onChange={toggleDisplayVisitorsToExhibitor}
                checked={exhibition.displayVisitorsToExhibitor}
            />
        </ListItem>
    );
};

export default DisplayVisitorsToExhibitor