//@flow
import {useParams} from "react-router";
import useExhibitionAction from "./useExhibitionAction";
import useExhibitionData from "./useExhibitionData";
import {useEffect} from "react";

type Params = {
    overrideLanguage: string,
    overrideVisitorId: string,
    overrideExhibitionId: string,
    doNotFetchCategories: boolean,
    doNotFetchProfile: boolean,
    doNotFetchExhibitors: boolean,
    refreshCategories?: boolean
}

export const useVisitor = (params: Params = {}) => {
    const {
        overrideLanguage,
        overrideVisitorId,
        overrideExhibitionId,
        doNotFetchProfile,
        doNotFetchExhibitors,
    } = params
    const {
        visitorId: paramsId,
        id: paramsExhibitionId,
    } = useParams()
    const id = overrideVisitorId ?? paramsId
    const exhibitionId = overrideExhibitionId ?? paramsExhibitionId
    const {
        visitorById: visitor,
        exhibitorsByVisitorId: exhibitorReferences,
        loading
    } = useExhibitionData({visitorId: id, id: exhibitionId, overrideLanguage: overrideLanguage})
    const {
        getVisitorProfile,
        getVisitorExhibitors,
    } = useExhibitionAction()
    useEffect(() => {
        if (id && !doNotFetchProfile) {
            getVisitorProfile(id, exhibitionId)
        }
    }, [doNotFetchProfile, exhibitionId, getVisitorProfile, id])
    useEffect(() => {
        if (id && !doNotFetchExhibitors) {
            getVisitorExhibitors(id, exhibitionId)
        }
    }, [doNotFetchExhibitors, exhibitionId, getVisitorExhibitors, id])

    return {
        exhibitionId,
        visitorId: id,
        exhibitorReferences,
        visitor,
        loading
    }
}
