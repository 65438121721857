import type {ExhibitionFile, ExhibitionImage, UnmappedExhibitionFile, UnmappedExhibitionImage} from "./types";
import {API, EXHIBITION_FILES_RELATIVE_PATH} from "../../helpers/constants";

export const mapExhibitionFile: ExhibitionFile = (datum: UnmappedExhibitionFile) => {
    if (!datum) return
    return {
        isTemporary: datum.isTemporary,
        id: datum.id,
        name: datum.OriginFileName,
        type: Number(datum.TypeId),
        messageTemplateTypeId: datum.MessageTemplateTypeId,
        description: datum.Description,
        language: datum.AttachmentLang ? datum.AttachmentLang?.toLowerCase() : '',
        url: API + '/en' + EXHIBITION_FILES_RELATIVE_PATH + datum.id,
        fileDisplayOrder: Number(datum.Ordr)
    }
}

export const mapExhibitionImage: ExhibitionImage = (datum: UnmappedExhibitionImage) => {
    if (!datum) return
    return {
        id: datum.id,
        url: API + datum.url,
        isLatest: datum.isLatest ? datum.isLatest : false
    }
}