import {useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import axios from "axios";
import useUserData from "../user/useUserData";
import {API} from "../../helpers/constants";
import {printFile} from "../../helpers/fileHandle";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";

const useQuickBadgePrint = ({exhibitionId, badgeTemplateTypes}) => {
	const {t} = useTranslation(EXHIBITION)
	const [loading, setLoading] = useState(false)
	const {token} = useUserData()
	const validationSchema = yup.object({
		nameEl: yup.string().required(t('required')),
		nameEn: yup.string().required(t('required'))
	})
	
	const onSubmit = (values) => {
		setLoading(true)
		axios({
			method: 'POST',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/en/visitor/badge/quick/${exhibitionId}`,
			responseType: 'blob',
			data: {
				Visitors: [{
					CompanyEN: values.companyEn,
					CompanyEL: values.companyEl,
					NameEN: values.nameEn,
					NameEL: values.nameEl,
					CharacterizationEN: values.characterizationEn,
					CharacterizationEL: values.characterizationEl,
					Color1: values.backgroundColor?.replace(/#/, ''),
					Color2: values.textColor?.replace(/#/, '')
				}],
				TemplateId: values.templateId
			}
		}).then(response => {
			const blob = new Blob([response.data], {type: 'application/pdf'});
			const url = window.URL.createObjectURL(blob);
			printFile(url)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to print badge!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const formik = useFormik({
		initialValues: {
			companyEn: '',
			companyEl: '',
			nameEl: '',
			nameEn: '',
			characterizationEn: '',
			characterizationEl: '',
			textColor: '#FFFFFF',
			backgroundColor: '#6d6d6d',
			templateId: badgeTemplateTypes.find(f => f.id === '1')?.id
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		enableReinitialize: true
	})
	
	return {
		loading,
		formik
	}
}

export default useQuickBadgePrint