//@flow
import React from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {ListItemText} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import Button from "@mui/material/Button";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import useUserData from "../../../hooks/user/useUserData";
import {
    showOption
} from "../../../pages/exhibitionSettings/components/subSettings/generalExhibitionSettings/components/selectExhibitionBusinessCategories";
import type {BusinessCategory} from "../../../types/exhibition/types";

type Props = {
    items: Array<BusinessCategory>,
    onRemove?: Function,
    asTable?: boolean
}

const AsList = (props) => {
    const {items, onRemove} = props
    const {language} = useUserData()
    return (
        <List dense sx={{maxHeight: 400, overflow: "auto"}}>
            {items && items.length > 0 && items.map(i => (
                <ListItem
                    divider
                    key={i.id}
                    secondaryAction={
                        onRemove && (
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => onRemove(i)}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        )}
                >
                    <ListItemText
                        primary={showOption(language)(i)}
                    />
                </ListItem>
            ))}
        </List>
    )
}

const AsTable = (props) => {
    const {t} = useTranslation(EXHIBITION)
    const {items, onRemove} = props
    return (
        <TableContainer component={Paper} variant={"outlined"} sx={{mb: 1}}>
            <Table sx={{minWidth: 650}} size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>{t('English Description')}</TableCell>
                        <TableCell>{t('Greek Description')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items && items.length > 0 && items.map(i => (
                        <TableRow
                            key={i.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>{i.id}</TableCell>
                            <TableCell>{i.description.en}</TableCell>
                            <TableCell>{i.description.el}</TableCell>
                            {onRemove && (
                                <TableCell>
                                    <Button
                                        color={"error"}
                                        onClick={() => onRemove(i)}
                                        startIcon={<DeleteIcon/>}
                                    >
                                        {normalizeGreek(t('Remove'))}
                                    </Button>
                                </TableCell>
                            )}
                        </TableRow>)
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const businessCategoriesList = (props: Props) => {
    const {asTable} = props
    return asTable ? <AsTable {...props}/> : <AsList {...props}/>
}

export default businessCategoriesList;