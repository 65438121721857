//@flow
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ColorSelect from "../../../../../../components/app/badgeForm/printable/colorSelect/colorSelect";
import { Update } from "../../../../../../hooks/exhibitions/useExhibitionAction";
import { useDebouncedCallback } from "../../../../../../hooks/useDebouncedCallback";
import { EXHIBITION } from "../../../../../../locales/components/namespaces";
import type { Exhibition } from "../../../../../../types/exhibition/types";

type Props = {
	id: string,
	exhibition: Exhibition,
	updateExhibition: Function
}

const ButtonColor = (props: Props) => {
	const { t } = useTranslation(EXHIBITION)
	const { id, exhibition, updateExhibition } = props
	const onChange = (value) => {
		updateExhibition(id, value, Update.ButtonColor)
	}
	const { value, debouncedOnChange } = useDebouncedCallback(onChange, exhibition.buttonColor)
	return (
		<ListItem>
			<ListItemText
				primary={t("Set a color for exhibitor buttons.")}
			/>
			<ColorSelect
				color={value}
				onColorChange={(color) => {
					const value = { target: { value: color } }
					debouncedOnChange(value)
				}}
			/>
		</ListItem>
	)
}

export default ButtonColor
