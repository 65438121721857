//@flow
import {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";
import Pagination from "../pagination/pagination";
import classNames from "classnames";

type Props = {
    noHeader?: boolean,
    noMeta?: boolean,
    noPagination?: boolean,
    showIndex?: boolean,
    getRowProps?: Function,
    initialState: {
        pageIndex: number,
        pageSize: number,
    }
}

const defaultPropGetter = () => ({})

const DefaultView = (props: Props) => {
    const {t} = useTranslation(TABLE_COMPONENT)
    const {
        noPagination,
        noMeta,
        showIndex,
        onRowClick,
        loading,
        noHeader,
        getRowProps = defaultPropGetter,
        pageCount: controlledPageCount,
        onPageSizeChange
    } = props

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        index,
        pageIndex,
        pageSize
    } = props.table

    return (
        <Fragment>
            <table
                {...getTableProps()}
                className={classNames('table', {
                    'table--has-row-action': Boolean(onRowClick)
                })}>
                {!noHeader && (
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {showIndex && <th/>}
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                )}
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps(getRowProps(row))}
                            onClick={(evt) => onRowClick && onRowClick(row.original, i, evt)}>
                            {showIndex && <td style={{width: 0}}>{index + i + 1}</td>}
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                <tr>
                    {loading ? (
                        // Use our custom loading state to show a loading indicator
                        <td colSpan="10000">{t('Loading')}...</td>
                    ) : page.length > 0 ? !noMeta && (
                        <td colSpan="10000">
                            {t('Showing')} {page.length} {t('of')} ~{controlledPageCount * pageSize}{' '}
                            {t('results')}
                        </td>
                    ) : (
                        <td colSpan="10000">
                            {t('no_results')}
                        </td>
                    )}
                </tr>
                </tbody>
            </table>
            {!noPagination && (
                <Pagination
                    pageSize={pageSize}
                    setPageSize={(size) => {
                        setPageSize(size)
                        if (onPageSizeChange) {
                            onPageSizeChange(size)
                        }
                    }}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    previousPage={previousPage}
                />
            )}
        </Fragment>
    )
}

export default DefaultView