//@flow
import React from 'react';
import {Chip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {VisitorStatus as Status} from "../../../helpers/constants";
import {normalizeGreek} from "../../../helpers/normalizeGreek";

type Props = {
    status: string,
    upperCase?: boolean
}

const VisitorStatusColor = {
    [Status.Import]: 'step1',
    [Status.Invitation]: 'step2',
    [Status.InvitationVerified]: 'step3',
    [Status.PreregistrationPendingApproval]: 'step4',
    [Status.BadgeSent]: 'step5',
    [Status.BadgeSentAndRead]: 'step6',
    [Status.PreregistrationApproved]: 'success',
    [Status.PreregistrationRejected]: 'error',
}

const VisitorStatus = (props: Props) => {
    const {status, upperCase} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    let label = t('status_' + status)
    if(upperCase){
        label = normalizeGreek(label.toUpperCase())
    }
    return (
        <Chip
            size={"small"}
            label={label}
            color={VisitorStatusColor[status]}
        />
    )
}

export default VisitorStatus