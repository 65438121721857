//@flow
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FileRowMenu from "./fileRowMenu";
import Button from "@mui/material/Button";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import Stack from "@mui/material/Stack";
import {EXHIBITION} from "../../../locales/components/namespaces";
import CircularProgress from "@mui/material/CircularProgress";
import {FormControl, FormHelperText} from "@mui/material";

type Props = {
    showAliasInput: boolean,
    file: {
        isTemporary: boolean,
        fileDisplayOrder?: number,
        name: string,
        url: string,
        alias: {
            en?: string,
            el?: string,
        },
    },
    onRemoveFile: Function,
    onUpdateAlias: Function
}

const FileRow = (props: Props) => {
    const {showAliasInput, file, onRemoveFile, onUpdateAlias, allowDisplayOrder} = props
    const [modeEdit, setModeEdit] = useState(false)
    const {t} = useTranslation(EXHIBITION)
    const [text, setText] = useState('')
    const [fileDisplayOrder, setFileDisplayOrder] = useState(0)
    useEffect(() => {
        if (modeEdit && file.alias) {
            setText(file.alias)
        }
        if (modeEdit && file.fileDisplayOrder) {
            setFileDisplayOrder(file.fileDisplayOrder)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modeEdit])
    return (
        <ListItem
            disabled={file.isTemporary}
            sx={{py: .25}}
            secondaryAction={
                file.isTemporary ? (
                    <CircularProgress
                        color="inherit"
                        size={16}
                        sx={{mx: 1.25}}
                    />
                ) : !modeEdit ? (
                    <FileRowMenu
                        showAliasInput={showAliasInput}
                        onDelete={onRemoveFile}
                        onEdit={() => setModeEdit(!modeEdit)}
                        downloadUrl={file.url}
                        downloadFileName={file.name}
                    />
                ) : null}
        >
            {showAliasInput && modeEdit ? (
                <Box style={{flexGrow: 1}}>
                    <TextField
                        label={t('Description')}
                        fullWidth
                        size={"small"}
                        name={'alias'}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        sx={{mb: 1}}
                    />
                    {allowDisplayOrder && (
                        <FormControl error={fileDisplayOrder <= 0}>
                            <TextField
                                label={t('Display Order')}
                                fullWidth
                                inputProps={{type: 'number'}}
                                size={"small"}
                                name={'fileDisplayOrder'}
                                value={fileDisplayOrder}
                                onChange={(e) => setFileDisplayOrder(e.target.value)}
                                sx={{mb: 1}}
                            />
                            {fileDisplayOrder && fileDisplayOrder <= 0 && (
                                <FormHelperText color={'error'}>
                                    {t('Enter a positive number')}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                    <Stack
                        sx={{mb: 1}}
                        spacing={1}
                        direction={"row"}
                    >
                        <Button
                            size={"small"}
                            variant={"contained"}
                            color={"primary"}
                            disabled={allowDisplayOrder && fileDisplayOrder <= 0}
                            onClick={() => {
                                onUpdateAlias(text, fileDisplayOrder)
                                setModeEdit(false)
                            }}
                        >
                            {normalizeGreek(t('Save'))}
                        </Button>
                        <Button
                            size={"small"}
                            color={"error"}
                            onClick={() => setModeEdit(false)}
                        >
                            {normalizeGreek(t('Cancel'))}
                        </Button>
                    </Stack>
                </Box>
            ) : (
                <ListItemText
                    primary={
                        file.isTemporary ? t('Uploading') + '...' :
                            showAliasInput ?
                                file.alias && file.alias !== '' ?
                                    file.alias : t('No description yet') :
                                file.name ?? file.path
                    }
                    secondary={showAliasInput ? (
                        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                            <Box>{file.name}</Box>
                            {allowDisplayOrder && <Box>{file.fileDisplayOrder ? file.fileDisplayOrder : '-'}</Box>}
                        </Stack>
                    ) : null}
                />
            )}
        </ListItem>
    )
}

export default FileRow