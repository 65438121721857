import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapGridState} from "../../types/gridState/map";

const INITIAL_STATE = {
	loading: false,
	grids: {},
	message: null
}

function setGridStateMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getGridState(state) {
	return state
	.set('loading', true)
}

function getGridStateSucceeded(state, action) {
	const {code} = action.parameters
	const {Grids} = action.payload
	const updateGrid = Grids.length > 0 ? mapGridState(Grids[0]) : {}
	return state
	.set('loading', false)
	.setIn(['grids', code], fromJS(updateGrid))
}

function getGridStateFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function saveGridState(state) {
	return state
	.set('loading', true)
}

function saveGridStateSucceeded(state, action) {
	const {Grids, code} = action.parameters
	const updateGrid = Grids.length > 0 ? mapGridState(Grids[0]) : {}
	return state
	.set('loading', false)
	.setIn(['grids', code], fromJS(updateGrid))
}

function saveGridStateFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function deleteGridState(state, action) {
	return state
	.set('loading', true)
}

function deleteGridStateSucceeded(state, action) {
	const {code, viewId, Grids} = action.parameters
	const updateGrid = Grids.length > 0 ? mapGridState(Grids[0]) : {}
	return state
	.set('loading', false)
	.setIn(['grids', code], fromJS(updateGrid))
	
}

function deleteGridStateFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function setActiveGridState(state, action) {
	const {code, viewId, Grids} = action.payload
	const updateGrid = Grids.length > 0 ? mapGridState(Grids[0]) : {}
	return state
	.setIn(['grids', code], fromJS(updateGrid))
}

function setNewGridStateLabel(state, action) {
	const {code, label} = action.payload
	const getGridIfExists = state.getIn(['grids', code])?.toJS()
	if (getGridIfExists) {
		return state
		.setIn(['grids', code, 'newViewLabel'], label)
	} else {
		const newGrid = {
			gridUniqueCode: code,
			activeViewId: '',
			newViewLabel: label,
			isMenuOpened: false,
			menuAnchorEl: null,
			views: {}
		}
		return state
		.setIn(['grids', code], fromJS(newGrid))
	}
}

function toggleGridPopper(state, action) {
	const {code, element} = action.payload
	const getGrid = state.getIn(['grids', code])?.toJS()
	const updateGrid = {
		...getGrid,
		isMenuOpened: !getGrid.isMenuOpened,
		...(element && {menuAnchorEl: element})
	}
	return state
	.setIn(['grids', code], fromJS(updateGrid))
}

const gridState = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.GET_GRID_STATE]: getGridState,
		[ActionTypes.GET_GRID_STATE_SUCCEEDED]: getGridStateSucceeded,
		[ActionTypes.GET_GRID_STATE_FAILED]: getGridStateFailed,
		[ActionTypes.SAVE_GRID_STATE]: saveGridState,
		[ActionTypes.SAVE_GRID_STATE_SUCCEEDED]: saveGridStateSucceeded,
		[ActionTypes.SAVE_GRID_STATE_FAILED]: saveGridStateFailed,
		[ActionTypes.DELETE_GRID_STATE]: deleteGridState,
		[ActionTypes.DELETE_GRID_STATE_SUCCEEDED]: deleteGridStateSucceeded,
		[ActionTypes.DELETE_GRID_STATE_FAILED]: deleteGridStateFailed,
		[ActionTypes.SET_ACTIVE_GRID_STATE]: setActiveGridState,
		[ActionTypes.SET_NEW_GRID_STATE_LABEL]: setNewGridStateLabel,
		[ActionTypes.TOGGLE_GRID_POPPER]: toggleGridPopper,
		[ActionTypes.SET_GRID_STATE_MESSAGE]: setGridStateMessage,
	}
}

export default gridState
