// @flow
import React, {useMemo} from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {operators, ruleFields} from "../../../../../../helpers/constants";
import useUserData from "../../../../../../hooks/user/useUserData";
import {showOption} from "../../../../../../helpers/helpers";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import GenerateValueInput from "./generateValueInput";
import {OutlinedInput} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DYNAMIC_LIST_AS_TABLE_COMPONENT} from "../../../../../../locales/components/namespaces";

type Props = {};

const RowInputs = (props: Props) => {
    const {rows, row, rowIndex, handleRows, isEditMode} = props
    const {language} = useUserData()
    const {t} = useTranslation(DYNAMIC_LIST_AS_TABLE_COMPONENT)

    const filterOperators = useMemo(() => {
        if (['CountryId', 'Interests'].includes(row.field)) {
            return operators.filter(f => f.value !== 'cnt' && f.value !== 'ncnt')
        } else if (['isIndividual', 'hasRevisited', 'FromRegistration', 'FromPreregistration', 'FromImport', 'hasInterests'].includes(row.field)) {
            return operators.filter(f => !['cnt', 'ncnt', 'le', 'lt', 'ge', 'gt'].includes(f.value))
        } else {
            return operators
        }
    })
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    select
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                        "& .Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                    }}
                    fullWidth
                    variant={"standard"}
                    size={"small"}
                    value={row.field}
                    label={t("Field")}
                    name={"field"}
                    disabled={!isEditMode}
                    onChange={(evt) => {
                        const updateRows = [...rows].map(item => {
                            if (item.id === row.id) {
                                item.field = evt.target.value
                                item.value = ''
                            }
                            return item
                        })
                        handleRows(updateRows, row)
                    }}
                >
                    <MenuItem
                        value={''}
                    />
                    {_.orderBy(ruleFields, `description.${language}`, ['asc']).map((field, index) => (
                        <MenuItem
                            key={index}
                            value={field.value}
                        >
                            {showOption(language)(field.description)}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                        "& .Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        }
                    }}
                    label={t("Operator")}
                    fullWidth
                    variant={"standard"}
                    size={"small"}
                    value={row.operator}
                    name={"operator"}
                    disabled={!isEditMode}
                    input={<OutlinedInput label={"Operator"}/>}
                    onChange={(evt) => {
                        const updateRows = [...rows].map(item => {
                            if (item.id === row.id) {
                                item.operator = evt.target.value
                            }
                            return item
                        })
                        handleRows(updateRows, row)
                    }}
                >
                    <MenuItem
                        value={''}
                    />
                    {_.orderBy(filterOperators, `description.${language}`, 'asc').map((operator, index) => (
                        <MenuItem
                            key={index}
                            value={operator.value}
                        >
                            {showOption(language)(operator.description)}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <GenerateValueInput
                    row={row}
                    rows={rows}
                    rowIndex={rowIndex}
                    isEditMode={isEditMode}
                    handleRows={handleRows}
                />
            </Grid>
        </Grid>
    );
};

export default RowInputs