//@flow
import React from 'react'
import {SketchPicker} from 'react-color'
import Box from "@mui/material/Box";
import Popover from '@mui/material/Popover';

type Props = {
    color: string,
    onColorChange: (string) => any
}

const ColorSelect = (props: Props) => {
    const {color: initialColor, onColorChange} = props
    const [color, setColor] = React.useState(initialColor ?? '#ffffff')
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const open = Boolean(anchorEl);
    return (
        <Box sx={{display: "flex", margin: "auto"}}>
            <div
                style={{
                    padding: 4,
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                <div
                    style={{
                        width: 28,
                        height: 28,
                        borderRadius: 4,
                        border: '1px solid lightgray',
                        background: color,
                    }}
                />
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <SketchPicker
                    color={color}
                    onChange={(c) => {
                        setColor(c.hex)
                        onColorChange(c.hex)
                    }}
                />
            </Popover>
        </Box>
    )
}

export default ColorSelect
