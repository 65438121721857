import {withLanguage} from "../../helpers/parse";

export const parseVisitorCategory = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        textColor: datum.get('textColor'),
        backgroundColor: datum.get('backgroundColor'),
        text: withLanguage(language)(datum.get('text')),
        explanation: withLanguage(language)(datum.get('explanation')),
        displayVisitorLabelInBadge: datum.get('displayVisitorLabelInBadge'),
		displayBadgeInEnglish: datum.get('displayBadgeInEnglish'),
        showPreregistration: datum.get('showPreregistration'),
        showOrganizer: datum.get('showOrganizer'),
        badgeTemplateTypeId: datum.get('badgeTemplateTypeId'),
    }
}