import {useDispatch} from "react-redux";
import {useCallback} from "react";
import * as actions from "../../actions/actions";

export const useUITranslationsActions = () => {
	const dispatch = useDispatch()
	const getUITranslations = useCallback((exhibitionId) => {
		dispatch(actions.getUITranslations({
			Descriptions: true,
			ParseVariables: false,
			Attributes: {
				ExhibitionId: exhibitionId
			}
		}))
	}, [dispatch])
	
	const updateUITranslation = useCallback(({code, values, exhibitionId}) => {
		dispatch(actions.updateUITranslation({
			Code: code,
			Value: {
				EN: values.body?.en,
				EL: values.body?.el,
			},
			Attributes: {
				ExhibitionId: exhibitionId
			}
		}))
	}, [dispatch])
	
	const setUITransMessage = useCallback((message) => {
		dispatch(actions.setUITransMessage(message))
	}, [dispatch])
	
	return {
		getUITranslations,
		updateUITranslation,
		setUITransMessage
	}
}