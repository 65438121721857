// @flow
import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import {Switch} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";

type Props = {
	id: string,
	exhibition: Exhibition,
	updateExhibition: Function
};

const DisplayPartnersToExhibitor = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {id, exhibition, updateExhibition} = props
	
	const toggleDisplayPartnersToExhibitor = () => {
		updateExhibition(id, !exhibition.displayPartnersToExhibitor, Update.ExhibitorPartners)
	}
	return (
		<ListItem>
			<ListItemText
				primary={t('Display Partners Tab To Exhibitor')}
				secondary={t('If you disable this, then exhibitors will not be able to access partner list from their profile.')}
			/>
			<Switch
				edge="end"
				onChange={toggleDisplayPartnersToExhibitor}
				checked={exhibition.displayPartnersToExhibitor}
			/>
		</ListItem>
	);
};

export default DisplayPartnersToExhibitor