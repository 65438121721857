import {useReportsAction} from "./useReportsAction";
import {useEffect} from "react";
import {useReportsData} from "./useReportsData";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";

export const useReports = (exhibitionId) => {
	const {t} = useTranslation(EXHIBITION)
	const {getReports, setReportMessage} = useReportsAction()
	const {loading, reports, message} = useReportsData()
	useEffect(() => {
		if (exhibitionId) getReports({id: exhibitionId, reportId: 2})
	}, [exhibitionId, getReports])
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setReportMessage(null)
		}
	}, [message, setReportMessage, t])
	return {
		loading,
		reports
	}
}