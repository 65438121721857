//@flow
import React, {useContext, useEffect} from 'react';
import {TitleContext} from "../../../../main/content";
import {Fab, Tab, Tabs} from "@mui/material";
import {TabPanel} from "../../../../../components/general/tabPanel/tabPanel";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../../routers/routes";
import {Settings} from "@mui/icons-material";
import {useParams} from "react-router";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import Box from "@mui/material/Box";
import Exhibitors from "../exhibitors/exhibitors";
import Visitors from "../../common/visitors/visitors";
import Details from "../../common/details/details";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import Reports from "../reports/reports";
import useQueryParams from "../../../../../hooks/useQueryParams";
import Breadcrumb from "../../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import Grid from "@mui/material/Grid";
import FaqsView from "../../../../faqs/faqsView";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import {Permissions} from "../../../../../helpers/constants";
import PermissionHOC from "../../../../../modules/permission/components/permissionHOC/permissionHOC";
import FastPrintBadges from "../fastPrintBadges/fastPrintBadges";
import {useHasPermission} from "../../../../../modules/permission/hooks/useHasPermission";

const Main = () => {
	const {t} = useTranslation(EXHIBITION)
	const queryParams = useQueryParams()
	const section = queryParams.get('section') ?? ""
	const {setToolbar} = useContext(TitleContext)
	const {id} = useParams()
	const {byId: exhibition, loading} = useExhibitionData({id})
	const hasReportsAccess = useHasPermission([Permissions.SeeReports], {shouldView: true})
	useEffect(() => {
		setToolbar(hasReportsAccess ? (
				<React.Fragment>
					<Tabs
						variant="scrollable"
						value={section}
						indicatorColor="primary"
						textColor="inherit"
					>
						<Tab
							component={Link}
							value={""}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id})}
							label={normalizeGreek(t("Details"))}
						/>
						<Tab
							component={Link}
							value={"exhibitors"}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id}, {section: "exhibitors"})}
							label={normalizeGreek(t("Exhibitors"))}
						/>
						<Tab
							component={Link}
							value={"visitors"}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id}, {section: "visitors"})}
							label={normalizeGreek(t("Visitors"))}
						/>
						<Tab
							component={Link}
							value={"badges"}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id}, {section: "badges"})}
							label={normalizeGreek(t("Quick Print Visitor Badges"))}
						/>
						<Tab
							component={Link}
							value={"reports"}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id}, {section: "reports"})}
							label={normalizeGreek(t("Reports"))}
						/>
					</Tabs>
					<Link
						to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: id})}>
						<Fab
							color="primary"
							variant="extended"
							size="medium"
							sx={{
								position: "fixed",
								bottom: 16,
								left: 6
							}}
						>
							<Settings/>
							{/*{normalizeGreek(t('Settings'))}*/}
						</Fab>
					</Link>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Tabs
						variant="scrollable"
						value={section}
						indicatorColor="primary"
						textColor="inherit"
					>
						<Tab
							component={Link}
							value={""}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id})}
							label={normalizeGreek(t("Details"))}
						/>
						<Tab
							component={Link}
							value={"exhibitors"}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id}, {section: "exhibitors"})}
							label={normalizeGreek(t("Exhibitors"))}
						/>
						<Tab
							component={Link}
							value={"visitors"}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id}, {section: "visitors"})}
							label={normalizeGreek(t("Visitors"))}
						/>
						<Tab
							component={Link}
							value={"badges"}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id}, {section: "badges"})}
							label={normalizeGreek(t("Quick Print Visitor Badges"))}
						/>
					</Tabs>
					<Link
						to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: id})}>
						<Fab
							color="primary"
							variant="extended"
							size="medium"
							sx={{
								position: "fixed",
								bottom: 16,
								left: 6
							}}
						>
							<Settings/>
							{/*{normalizeGreek(t('Settings'))}*/}
						</Fab>
					</Link>
				</React.Fragment>
			)
		)
		return () => {
			setToolbar(null)
		}
	}, [hasReportsAccess, id, section, setToolbar, t])
	return (
		<Box>
			{section !== "" && (
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
						{t('Home')}
					</Breadcrumb.Link>
					<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id})}>
						{exhibition.title}
					</Breadcrumb.Link>
					{section && section !== "" && (
						<Breadcrumb.Item>
							{t(section.charAt(0).toUpperCase() + section.slice(1))}
						</Breadcrumb.Item>
					)}
				</Breadcrumb>
			)}
			<TabPanel isVisible={section === ""} sx={{pt: 2}}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={2}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6" sx={{pl: 2}}>
								{t('Menu')}
							</Typography>
						</Toolbar>
						<Divider light/>
						<FaqsView
							exhibitionId={id}
						/>
					</Grid>
					<Grid item xs={12} md={10}>
						<Details
							loading={loading}
							exhibition={exhibition}
						/>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel isVisible={section === "exhibitors"}>
				<Exhibitors
					exhibitionId={exhibition.id}
					exhibitors={exhibition.exhibitors}
				/>
			</TabPanel>
			<TabPanel isVisible={section === "visitors"}>
				<Visitors
					exhibitionId={exhibition.id}
					visitors={exhibition.visitors}
				/>
			</TabPanel>
			<TabPanel isVisible={section === "reports"}>
				<PermissionHOC requiredPermissions={[Permissions.SeeReports]}>
					<Reports
						exhibition={exhibition}
					/>
				</PermissionHOC>
			</TabPanel>
			<TabPanel isVisible={section === "badges"}>
				<FastPrintBadges
					exhibitionId={exhibition.id}
				/>
			</TabPanel>
		</Box>
	)
}

export default Main