//@flow
import React, {useEffect, useMemo, useState} from 'react';
import {DataGridPremium, GridActionsCellItem, GridToolbar} from '@mui/x-data-grid-premium';
import type {Exhibition, Partner} from "../../../../../types/exhibition/types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import AddPartnerDialog from "../addPartner/addPartner";
import ExcelImport from "./excelImport";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import {useExhibition} from "../../../../../hooks/exhibitions/useExhibition";
import {Alert, AlertTitle} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import useExhibitionAction from "../../../../../hooks/exhibitions/useExhibitionAction";
import ConfirmDialog from "../../../../../components/app/dialogs/confirmDialog/confirmDialog";
import ActionStatus from "../../../../../components/app/alerts/actionStatus/actionStatus";
import {Print, Send} from "@mui/icons-material";
import {EventStatus} from "../../../../../helpers/date";
import PrintBadgeButton from "../../../../../components/app/printBadgeButton/printBadgeButton";
import {useHasPermission} from "../../../../../modules/permission/hooks/useHasPermission";
import {CatalogueLanguages, PaperSize, PartnerTypes, Permissions} from "../../../../../helpers/constants";
import {SendPopOver} from "./sendPopOver";
import {muiGridLocales} from "../../../../../helpers/muiGridLocales";
import useUserData from "../../../../../hooks/user/useUserData";
import {
    CustomPagination
} from "../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";

type Props = {
    exhibitorId: string,
    exhibitionId: string,
    partners: Array<Partner>,
}

type InnerProps = Props & {
    exhibition: Exhibition
}

const PartnersInner = (props: InnerProps) => {
    const {partners, exhibition, exhibitionId, exhibitorId} = props
    const {language} = useUserData()
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const [showFileUpload, setShowFileUpload] = useState(false)
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [selectionModel, setSelectionModel] = useState([])
    const [partnerIdToRemove, setPartnerIdToRemove] = useState(null)
    const [sendPopOver, setSendPopOver] = useState({
        anchorEl: null,
        open: false,
    })
    const {actionOnPartnerStatus, loading} = useExhibitionData()
    const {
        removePartner,
        clearActionOnPartnerStatus,
        sendPartnerBadges,
        clearBulkPartnersStatus
    } = useExhibitionAction()
    const isExhibitor = useHasPermission([Permissions.BeExhibitor], {shouldView: true})
    const handleSendPopOver = (value) => {
        setSendPopOver({...value})
    }

    const onRemove = () => {
        removePartner(exhibitionId, exhibitorId, partnerIdToRemove)
        setPartnerIdToRemove(null)
    }
    const onRemoveButtonClick = (id) => {
        setPartnerIdToRemove(id)
    }
    const onSendBadge = (values) => {
        sendPartnerBadges(selectionModel, values.email)
    }
    useEffect(() => {
        if (actionOnPartnerStatus && actionOnPartnerStatus.type === "success") {
            setSelectionModel([])
        }
    }, [actionOnPartnerStatus])
    const printSize = props.type === PartnerTypes.Partner ? exhibition.partnerBadgeTemplateTypeId :
        props.type === PartnerTypes.ServiceProvider ? exhibition.providerBadgeTemplateTypeId :
            props.type === PartnerTypes.ExhibitionStaff ? exhibition.staffBadgeTemplateTypeId :
                props.type === PartnerTypes.Organizer ? exhibition.organizerBadgeTemplateTypeId : PaperSize.A4
    const columns = useMemo(() => {
        const result = [
            ...(exhibition.catalogueLanguage !== CatalogueLanguages.EN ? [{
                field: 'name',
                headerName: t('Name (EL)'),
                width: 180
            }] : []),
            ...(exhibition.catalogueLanguage !== CatalogueLanguages.EL ? [{
                field: 'nameEn',
                headerName: t('Name (EN)'),
                width: 180
            }] : []),
            {field: 'email', headerName: t('Email'), width: 200},
            ...(props.type === PartnerTypes.ServiceProvider || props.type === PartnerTypes.ExhibitionStaff ? [
                {field: 'companyName', headerName: t('Company Name'), width: 200, flex: 1}
            ] : []),
            {
                field: 'id',
                type: 'actions',
                headerName: t('Badge'),
                minWidth: 80,
                renderCell: (({value}) => (
                    <PrintBadgeButton
                        printSize={printSize}
                        userId={value}
                        type={"partner"}
                    />
                ))
            },
        ]
        if (exhibition.status !== EventStatus.EXPIRED) {
            result.push(({
                headerName: t('Actions'),
                field: 'actions',
                type: 'actions',
                width: 100,
                getActions: (params) => [
                    <GridActionsCellItem
                        onClick={() => onRemoveButtonClick(params.id)}
                        icon={<DeleteIcon/>}
                        label={t("Remove")}
                        color={"error"}
                    />,
                ],
            }))
        }
        return result
    }, [exhibition.catalogueLanguage, exhibition.status, printSize, props.type, t])
    const limitedNumberOfPartners = !isNaN(exhibition.numberOfPartnersPerExhibitor) && exhibition.numberOfPartnersPerExhibitor > 0
    const canAddPartner = !limitedNumberOfPartners || (limitedNumberOfPartners && (exhibition.numberOfPartnersPerExhibitor >= partners.length + 1))
    const addButtonText = props.type === PartnerTypes.Partner ? 'Add a partner' :
        props.type === PartnerTypes.ServiceProvider ? 'Add a service provider' :
            props.type === PartnerTypes.ExhibitionStaff ? 'Add exhibition staff' : 'Add organizer'
    const selectionButtonText = props.type === PartnerTypes.Partner ? 'Send badge to {{count}} partners'
        : props.type === PartnerTypes.ServiceProvider ? 'Send badge to {{count}} service providers' :
            props.type === PartnerTypes.ExhibitionStaff ? 'Send badge to {{count}} exhibition staff' : 'Send badge to {{count}} organizers'
    const printBadgeButtonText = props.type === PartnerTypes.Partner ? 'Print badges of {{count}} partners' :
        props.type === PartnerTypes.ServiceProvider ? 'Print badges of {{count}} service providers' :
            props.type === PartnerTypes.ExhibitionStaff ? 'Print badges of {{count}} exhibition staff' : 'Print badges of {{count}} organizers'
    return (
        <Box>
            {exhibition.status !== EventStatus.EXPIRED && (
                <React.Fragment>
                    {limitedNumberOfPartners && props.type === PartnerTypes.Partner && (
                        <Alert severity="info">
                            <AlertTitle>{t('Info')}</AlertTitle>
                            <Trans
                                i18nKey={'partner_limit'}
                                ns={EXHIBITOR_PROFILE}
                                values={{
                                    maximum: exhibition.numberOfPartnersPerExhibitor,
                                    remainder: canAddPartner ?
                                        exhibition.numberOfPartnersPerExhibitor - partners.length : 0
                                }}
                                defaults={"You can add <bold>maximum of {{maximum}}</bold> partners. <bold>Remaining {{remainder}}</bold>"}
                                components={{bold: <strong/>}}
                            />
                        </Alert>
                    )}
                    <Toolbar disableGutters variant={"dense"}>
                        <Box sx={{flexGrow: 1}}/>
                        <Stack direction="row">
                            <Button
                                disabled={!canAddPartner}
                                size={"small"}
                                variant={"contained"}
                                onClick={() => setShowAddDialog(true)}
                            >
                                {normalizeGreek(t(addButtonText))}
                            </Button>
                            <Button
                                disabled={!canAddPartner}
                                size={"small"}
                                onClick={() => setShowFileUpload(true)}
                            >
                                {normalizeGreek(t('Import from excel'))}
                            </Button>
                        </Stack>
                    </Toolbar>
                </React.Fragment>
            )}
            <Stack spacing={1} direction={"row"} sx={{mb: 1}}>
                <Button
                    disabled={!(selectionModel && selectionModel.length > 0)}
                    onClick={(evt) => setSendPopOver({
                        anchorEl: evt.currentTarget,
                        open: true,
                    })}
                    startIcon={<Send/>}
                    variant={"outlined"}
                    size={"small"}
                >
                    {normalizeGreek(t(selectionButtonText, {
                        count: selectionModel.length
                    }))}
                </Button>
                <PrintBadgeButton
                    printSize={printSize}
                    userId={selectionModel}
                    type={"partner"}
                    component={({onClick}) => (
                        <Button
                            variant={"outlined"}
                            startIcon={<Print/>}
                            size={"small"}
                            onClick={onClick}
                            disabled={!(selectionModel && selectionModel.length > 0)}
                        >
                            {normalizeGreek(t(printBadgeButtonText, {
                                count: selectionModel.length
                            }))}
                        </Button>
                    )}
                />
            </Stack>
            <div style={{height: 400, width: '100%'}}>
                {columns && columns.length > 0 && (
                    <DataGridPremium
                        disableRowGrouping
                        disableAggregation
                        sx={{bgcolor: 'background.paper'}}
                        initialState={{density: "compact"}}
                        rows={partners}
                        columns={columns}
                        slots={{
                            toolbar: GridToolbar,
                            pagination: CustomPagination
                        }}
                        slotProps={{toolbar: {csvOptions: {disableToolbarButton: true}}}}
                        localeText={muiGridLocales(language)}
                        paginationModel={{page: 1, pageSize: 40}}
                        pageSizeOptions={[5, 10, 20, 40, 60]}
                        checkboxSelection={exhibition.status !== EventStatus.EXPIRED}
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={(newSelectionModel) => exhibition.status !== EventStatus.EXPIRED && setSelectionModel(newSelectionModel)}
                    />
                )}
            </div>
            <ExcelImport
                exhibitorId={exhibitorId}
                exhibitionId={exhibitionId}
                show={showFileUpload}
                onClose={() => {
                    setShowFileUpload(false);
                    clearBulkPartnersStatus();
                }}
                type={props.type}
            />
            <AddPartnerDialog
                canAddPartner={canAddPartner}
                catalogueLanguage={exhibition?.catalogueLanguage}
                exhibitorId={exhibitorId}
                exhibitionId={exhibitionId}
                open={showAddDialog}
                onClose={() => setShowAddDialog(false)}
                type={props.type}
                addButtonText={addButtonText}
            />
            <SendPopOver
                sendPopOver={sendPopOver}
                handleSendPopOver={handleSendPopOver}
                selectionModel={selectionModel}
                loading={loading}
                onSendBadge={onSendBadge}
                type={props.type}
            />
            <ConfirmDialog
                onYes={onRemove}
                onNo={() => setPartnerIdToRemove(false)}
                title={t('Confirm Delete')}
                open={Boolean(partnerIdToRemove)}
                handleDialog={(newValue) => setPartnerIdToRemove(newValue)}
            >
                {t('This action is permanent. Press OK if you want to proceed.')}
            </ConfirmDialog>
            <ActionStatus
                status={actionOnPartnerStatus}
                onClose={clearActionOnPartnerStatus}
            />
        </Box>
    )
}

const Partners = (props) => {
    const {exhibition, loading} = useExhibition()
    return (
        <React.Fragment>
            {exhibition ? (
                <PartnersInner
                    {...props}
                    exhibition={exhibition}
                />
            ) : (
                <ContentLoader
                    loading={loading}
                />
            )}
        </React.Fragment>
    )
}

export default Partners;
