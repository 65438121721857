//@flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";
import Button from "@mui/material/Button";
import {FormControl, IconButton, Select} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuItem from '@mui/material/MenuItem';

type Props = {
    pageIndex: number,
    pageSize: number,
    setPageSize: Function,
    previousPage: Function,
    nextPage: Function,
    canPreviousPage: boolean,
    canNextPage: boolean,
};

const Pagination = (props: Props) => {
    const {t} = useTranslation(TABLE_COMPONENT)
    const {pageSize, setPageSize, canPreviousPage, canNextPage, nextPage, previousPage, pageIndex} = props
    return (
        <div className="pagination">
            <FormControl variant="standard">
                {/*<InputLabel id="items-per-page">Age</InputLabel>*/}
                <Select
                    labelId="items-per-page"
                    // id="demo-simple-select"
                    // label="Age"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <MenuItem key={pageSize} value={pageSize}>
                            {pageSize}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button disabled>
                {pageIndex * pageSize} - {pageSize * pageIndex + pageSize}
            </Button>
            <IconButton aria-label="previous" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <ArrowBackIosNewIcon/>
            </IconButton>
            <IconButton aria-label="next"
                        onClick={() => nextPage()} disabled={!canNextPage}>
                <ArrowForwardIosIcon/>
            </IconButton>
        </div>
    )
}

export default Pagination;