// @flow
import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {ErrorMessage} from "formik";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import TextField from "@mui/material/TextField";
import {VisitorFormContext} from "../../form";
import {useTextFieldChange} from "../../../preregistrationForm/hooks/useTextFieldChange";

type Props = {};

export const textfieldProps = (props, t) => {
    const {field, touched, errors} = props
    return {
        label: t(field.name),
        name: "fieldValues." + field.id,
        required: field.required,
        autoComplete: "off",
        size: "small",
        fullWidth: true,
        variant: "outlined",
        sx: {
            '& .MuiOutlinedInput-input': {
                bgcolor: "background.paper"
            }
        },
        error: touched["fieldValues"] &&
            touched["fieldValues"][field.id] &&
            Boolean(errors["fieldValues"] && errors["fieldValues"][field.id]),
        helperText: <ErrorMessage name={"fieldValues." + field.id}/>
    }
}

const Text = (props: Props) => {
    const {formik, values, field, value: propValue, handleBlur, handleChange, touched, errors, disabled} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    const {
        companyNameFieldRef,
        nameFieldRef,
        surnameFieldRef,
        vatFieldRef,
        emailFieldRef,
        phoneFieldRef,
        companyAddressFieldRef,
        cityFieldRef,
        companyWebsiteFieldRef,
        instagramFieldRef,
        facebookFieldRef,
        positionFieldRef,
        personNumberFieldRef,
        postalCodeFieldRef
    } = useContext(VisitorFormContext)
    const {handleChange: onChange, value} = useTextFieldChange({
        handleChange,
        field,
        value: propValue
    })

    let fieldRef = null
    switch (field.id) {
        case 'companyName':
            fieldRef = companyNameFieldRef
            break
        case 'name':
            fieldRef = nameFieldRef
            break
        case 'surname':
            fieldRef = surnameFieldRef
            break
        case 'vatNumber':
            fieldRef = vatFieldRef
            break
        case 'email':
            fieldRef = emailFieldRef
            break
        case 'phone':
            fieldRef = phoneFieldRef
            break
        case 'companyAddress':
            fieldRef = companyAddressFieldRef
            break
        case 'city':
            fieldRef = cityFieldRef
            break
        case 'companyWebsite':
            fieldRef = companyWebsiteFieldRef
            break
        case 'instagram':
            fieldRef = instagramFieldRef
            break
        case 'facebook':
            fieldRef = facebookFieldRef
            break
        case 'position':
            fieldRef = positionFieldRef
            break
        case 'personCount':
            fieldRef = personNumberFieldRef
            break
        default:
            break
    }

    return (
        <React.Fragment>
            {field.id === "postalCode" ? (
                <TextField
                    {...textfieldProps(props, t)}
                    type={field.inputType}
                    onChange={onChange}
                    InputLabelProps={{shrink: true}}
                    defaultValue={value}
                    autoComplete='off'
                    inputRef={postalCodeFieldRef}
                    inputProps={{maxLength: field?.maxLength}}
                />
            ) : (
                <TextField
                    {...textfieldProps(props, t)}
                    type={field.inputType}
                    defaultValue={value}
                    onBlur={handleBlur}
                    inputRef={fieldRef}
                    autoComplete='off'
                    onChange={(e) => {
                        fieldRef.current.value = (field.type !== 'email' && field.type !== 'companyWebsite' && field.type !== 'instagram' && field.type !== 'facebook') ? normalizeGreek(fieldRef.current.value.toUpperCase()) : fieldRef.current.value
                        formik.setFieldValue('fieldValues.' + field.id, fieldRef.current.value)
                    }}
                    InputLabelProps={{shrink: true}}
                    inputProps={{maxLength: field?.maxLength}}
                />
            )}
        </React.Fragment>
    )
};

export default Text