//@flow
import React from 'react'
import './tree.css'
import CheckboxList from './components/checkBoxList/checkBoxList'
import RowList from './components/rowList/rowList'
import {useTree} from './hooks/useTree'
import CheckBox from './components/checkBox/checkBox'

type Props = {
    withCheckboxList?: boolean,
    hasNodeIcon?: boolean,
    selectAll?: string,
    onClick?: Function,
    data?: Array<{
        id: string,
        parentId: string,
        description: string,
        hasChildren: boolean
    }>
}

const Tree = (props: Props) => {
    const {
        hasNodeIcon,
        withCheckboxList,
        data,
        selectAll, // if exists, select all checkbox appears on top of the string. Contains checkbox text
        onClick = () => {}, // tales 2 arguments onClick(evt, item)
    } = props

    const {
        getItemState,
        clickHandler,
        allSelected,
        handleSelectAll
    } = useTree(data, onClick)
    return data.length > 0 && (
        <div className="tree">
            {!withCheckboxList ? (
                <RowList
                    data={data}
                    onClick={onClick}
                    hasNodeIcon={hasNodeIcon}
                />
            ) : (
                <React.Fragment>
                    {selectAll && <li className="tree-list-item" style={{ backgroundColor: '#FFEFD5', width: '100%', padding: '4px 0' }}>
                        <CheckBox
                            onClick={() => handleSelectAll(!allSelected)}
                            ischecked={allSelected}
                        />
                        <span>{selectAll}</span>
                    </li>
                    }
                    <CheckboxList
                        data={data}
                        onClick={clickHandler}
                        getStateForId={getItemState}
                    />
                </React.Fragment>
            )}
        </div>
    )
}

export default Tree