//@flow
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import {useDebouncedCallback} from "../../../../../../hooks/useDebouncedCallback";
import ListItem from "@mui/material/ListItem";
import {TextField} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {NumericFormat} from "react-number-format";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
}

const PartnerNumber = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props
    const onChange = (value) => {
        updateExhibition(id, value, Update.NumberOfPartnersPerExhibitor)
    }
    const {value, debouncedOnChange} = useDebouncedCallback(onChange, exhibition.numberOfPartnersPerExhibitor)
    return (
        <ListItem>
            <ListItemText
                primary={t("Number of partners per exhibitor")}
                secondary={t("Limit the number of partners an exhibitor can invite. Leave empty to allow infinite number of partners.")}
            />
            <NumericFormat
                defaultValue={value}
                onChange={debouncedOnChange}
                variant={'outlined'}
                inputProps={{maxLength: 5}}
                required
                thousandSeparator={''}
                decimalScale={0}
                allowNegative={false}
                customInput={TextField}
                size={'small'}
                allowLeadingZeros={true}
            />
        </ListItem>
    )
}

export default PartnerNumber
