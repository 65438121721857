import {useDispatch} from "react-redux";
import {useCallback} from "react";
import * as actions from "../../actions/actions";
import {v4 as uuidv4} from "uuid";
import jsonToFormData from "json-form-data";

export const useExhibitionImageAction = () => {
	const dispatch = useDispatch()
	
	const getExhibitionImages = useCallback((exhibitionId) => {
		dispatch(actions.getExhibitionImages({
			id: exhibitionId
		}))
	}, [dispatch])
	
	const createExhibitionImage = useCallback((exhibitionId, file) => {
		const dataToSend = {
			ExhibitionId: exhibitionId,
			file: file,
			temporaryId: uuidv4()
		}
		const formData = jsonToFormData(dataToSend)
		dispatch(actions.createExhibitionImage(formData))
	}, [dispatch])
	
	const deleteExhibitionImage = useCallback((exhibitionId, imageId) => {
		dispatch(actions.deleteExhibitionImage({
			ExhibitionId: exhibitionId,
			id: imageId,
		}))
	}, [dispatch])
	
	const setFileMessage = useCallback((message) => {
		dispatch(actions.setFileMessage(message))
	}, [dispatch])
	
	return {
		getExhibitionImages,
		createExhibitionImage,
		deleteExhibitionImage,
		setFileMessage
	}
	
}