// @flow
import React from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardContent, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {InfoOutlined} from "@mui/icons-material";

type Props = {};

const CurrentKpis = (props: Props) => {
	const {data, t, isCoorganization} = props
	return (
		<Grid container spacing={2}>
			{isCoorganization && (
				<>
					<Grid item xs={12}>
						<Typography variant="h6" sx={{textAlign: "center"}}>
							{t('Coorganization KPIs')}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center"
											color="text.secondary" gutterBottom>
									{t('erpTotalCountCoorg')}
									<Tooltip arrow
											 title={t('erpTotalCountCoorg_info')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" component="div" align="center">
									{data.erpTotalCountCoorg}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center"
											color="text.secondary" gutterBottom>
									{t('notErpTotalCountCoorg')}
									<Tooltip arrow
											 title={t('notErpTotalCountCoorg_info')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" align="center" component="div">
									{data.notErpTotalCountCoorg}
								</Typography>
							</CardContent></Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center"
											color="text.secondary" gutterBottom>
									{t('preregistrationTotalCountCoorg')}
									<Tooltip arrow
											 title={t('preregistrationTotalCountCoorg_info')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" align="center" component="div">
									{data.preregistrationTotalCountCoorg}
								</Typography>
							</CardContent></Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center"
											color="text.secondary" gutterBottom>
									{t('preregistrationVisitorCountCoorg')}
									<Tooltip arrow
											 title={t('preregistrationVisitorCountCoorg_info')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" align="center" component="div">
									{data.preregistrationVisitorCountCoorg}
								</Typography>
							</CardContent></Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center"
											color="text.secondary" gutterBottom>
									{t('scannedAfterRegistration')}
									<Tooltip arrow
											 title={t('scannedAfterRegistration_info')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" align="center" component="div">
									{data.scannedAfterRegistration}
								</Typography>
							</CardContent></Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center"
											color="text.secondary" gutterBottom>
									{t('erpVisitorCountCoorg')}
									<Tooltip arrow
											 title={t('erpVisitorCountCoorg_info')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" align="center" component="div">
									{data.erpVisitorCountCoorg}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center"
											color="text.secondary" gutterBottom>
									{t('notErpVisitorCountCoorg')}
									<Tooltip arrow
											 title={t('notErpVisitorCountCoorg_info')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" align="center" component="div">
									{data.notErpVisitorCountCoorg}
								</Typography>
							</CardContent></Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card sx={{width: '100%', height: '100%'}}>
							<CardContent style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}>
								<Typography sx={{fontSize: 14, fontWeight: 'bold', color: '#556b2f'}} align="center"
											color="text.secondary" gutterBottom>
									{t('Total Number Of Actual Visitors')}
									<Tooltip arrow
											 title={t('Total Visitors = Actual Visits From Visitors Submitted Through ERP + Actual Visits From Non ERP Submitted Visitors')}
									>
										<IconButton size={'small'}>
											<InfoOutlined/>
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography variant="h4" align="center" component="div">
									{data.notErpVisitorCountCoorg + data.erpVisitorCountCoorg}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<Typography variant="h6" sx={{textAlign: "center"}}>
					{t('Exhibition KPIs')}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card sx={{width: '100%', height: '100%'}}>
					<CardContent style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center" color="text.secondary"
									gutterBottom>
							{t('erpTotalCount')}
							<Tooltip arrow
									 title={t('erpTotalCount_info')}
							>
								<IconButton size={'small'}>
									<InfoOutlined/>
								</IconButton>
							</Tooltip>
						</Typography>
						<Typography variant="h4" component="div" align="center">
							{data.erpTotalCount}
						</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card sx={{width: '100%', height: '100%'}}>
					<CardContent style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center" color="text.secondary"
									gutterBottom>
							{t('notErpTotalCount')}
							<Tooltip arrow
									 title={t('notErpTotalCount_info')}
							>
								<IconButton size={'small'}>
									<InfoOutlined/>
								</IconButton>
							</Tooltip>
						</Typography>
						<Typography variant="h4" align="center" component="div">
							{data.notErpTotalCount}
						</Typography>
					</CardContent></Card>
			</Grid>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card sx={{width: '100%', height: '100%'}}>
					<CardContent style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center" color="text.secondary"
									gutterBottom>
							{t('preregistrationTotalCount')}
							<Tooltip arrow
									 title={t('preregistrationTotalCount_info')}
							>
								<IconButton size={'small'}>
									<InfoOutlined/>
								</IconButton>
							</Tooltip>
						</Typography>
						<Typography variant="h4" align="center" component="div">
							{data.preregistrationTotalCount}
						</Typography>
					</CardContent></Card>
			</Grid>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card sx={{width: '100%', height: '100%'}}>
					<CardContent style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center" color="text.secondary"
									gutterBottom>
							{t('preregistrationVisitorCount')}
							<Tooltip arrow
									 title={t('preregistrationVisitorCount_info')}
							>
								<IconButton size={'small'}>
									<InfoOutlined/>
								</IconButton>
							</Tooltip>
						</Typography>
						<Typography variant="h4" align="center" component="div">
							{data.preregistrationVisitorCount}
						</Typography>
					</CardContent></Card>
			</Grid>
			{!isCoorganization && (
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Card sx={{width: '100%', height: '100%'}}>
						<CardContent style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: '100%'
						}}>
							<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center" color="text.secondary"
										gutterBottom>
								{t('scannedAfterRegistration')}
								<Tooltip arrow
										 title={t('scannedAfterRegistration_info')}
								>
									<IconButton size={'small'}>
										<InfoOutlined/>
									</IconButton>
								</Tooltip>
							</Typography>
							<Typography variant="h4" align="center" component="div">
								{data.scannedAfterRegistration}
							</Typography>
						</CardContent></Card>
				</Grid>
			)}
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card sx={{width: '100%', height: '100%'}}>
					<CardContent style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center" color="text.secondary"
									gutterBottom>
							{t('erpVisitorCount')}
							<Tooltip arrow
									 title={t('erpVisitorCount_info')}
							>
								<IconButton size={'small'}>
									<InfoOutlined/>
								</IconButton>
							</Tooltip>
						</Typography>
						<Typography variant="h4" align="center" component="div">
							{data.erpVisitorCount}
						</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card sx={{width: '100%', height: '100%'}}>
					<CardContent style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<Typography sx={{fontSize: 14, fontWeight: 'bold'}} align="center" color="text.secondary"
									gutterBottom>
							{t('notErpVisitorCount')}
							<Tooltip arrow
									 title={t('notErpVisitorCount_info')}
							>
								<IconButton size={'small'}>
									<InfoOutlined/>
								</IconButton>
							</Tooltip>
						</Typography>
						<Typography variant="h4" align="center" component="div">
							{data.notErpVisitorCount}
						</Typography>
					</CardContent></Card>
			</Grid>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card sx={{width: '100%', height: '100%'}}>
					<CardContent style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<Typography sx={{fontSize: 14, fontWeight: 'bold', color: '#556b2f'}} align="center"
									color="text.secondary" gutterBottom>
							{t('Total Number Of Actual Visitors')}
							<Tooltip arrow
									 title={t('Total Visitors = Actual Visits From Visitors Submitted Through ERP + Actual Visits From Non ERP Submitted Visitors')}
							>
								<IconButton size={'small'}>
									<InfoOutlined/>
								</IconButton>
							</Tooltip>
						</Typography>
						<Typography variant="h4" align="center" component="div">
							{data.erpVisitorCount + data.notErpVisitorCount}
						</Typography>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
};

export default CurrentKpis