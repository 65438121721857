import {actionCreator} from "../../helpers/actionCreator";
import * as ActionTypes from "./types";

export const getFaqs = actionCreator(ActionTypes.GET_FAQS)
export const getFaqsSucceeded = actionCreator(ActionTypes.GET_FAQS_SUCCEEDED)
export const getFaqsFailed = actionCreator(ActionTypes.GET_FAQS_FAILED)

export const getFaqDetails = actionCreator(ActionTypes.GET_FAQ_DETAILS)
export const getFaqDetailsSucceeded = actionCreator(ActionTypes.GET_FAQ_DETAILS_SUCCEEDED)
export const getFaqDetailsFailed = actionCreator(ActionTypes.GET_FAQ_DETAILS_FAILED)

export const createFaq = actionCreator(ActionTypes.CREATE_FAQ)
export const createFaqSucceeded = actionCreator(ActionTypes.CREATE_FAQ_SUCCEEDED)
export const createFaqFailed = actionCreator(ActionTypes.CREATE_FAQ_FAILED)

export const updateFaq = actionCreator(ActionTypes.UPDATE_FAQ)
export const updateFaqSucceeded = actionCreator(ActionTypes.UPDATE_FAQ_SUCCEEDED)
export const updateFaqFailed = actionCreator(ActionTypes.UPDATE_FAQ_FAILED)

export const reorderFaqs = actionCreator(ActionTypes.REORDER_FAQS)
export const reorderFaqsSucceeded = actionCreator(ActionTypes.REORDER_FAQS_SUCCEEDED)
export const reorderFaqsFailed = actionCreator(ActionTypes.REORDER_FAQS_FAILED)

export const deleteFaq = actionCreator(ActionTypes.DELETE_FAQ)
export const deleteFaqSucceeded = actionCreator(ActionTypes.DELETE_FAQ_SUCCEEDED)
export const deleteFaqFailed = actionCreator(ActionTypes.DELETE_FAQ_FAILED)
