import {createSelector} from "reselect";
import {parseUserMessage} from "../../types/user/parse";
import {useSelector} from "react-redux";

const selectLoading = createSelector(
	(state) => state.gridState,
	(item) => item.get('loading')
)

const selectMessage = createSelector(
	(state) => state.gridState.get('message'),
	(item) => parseUserMessage(item)
)

const selectGrid = createSelector(
	(state, uniqueGridCode) => state.gridState.getIn(['grids', uniqueGridCode]),
	(data) => data?.toJS()
)

const selectActiveViewId = createSelector(
	(state, uniqueGridCode) => state.gridState.getIn(['grids', uniqueGridCode, 'activeViewId']),
	(item) => item
)

const useGridStateData = ({gridUniqueCode}) => {
	const loading: boolean = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const grid = useSelector((state) => selectGrid(state, gridUniqueCode))
	const activeViewId = useSelector((state) => selectActiveViewId(state, gridUniqueCode))
	return {
		loading,
		message,
		grid,
		activeViewId
	}
}

export default useGridStateData