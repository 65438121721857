// @flow
import * as React from 'react';
import Box from "@mui/material/Box";
import {Trans, useTranslation} from "react-i18next";
import {ListItem} from "@mui/material";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import {Cancel} from "@mui/icons-material";
import {eventStatus, formatDate} from "../../../helpers/date";
import {useHasPermission} from "../../../modules/permission/hooks/useHasPermission";
import {Permissions} from "../../../helpers/constants";
import CatalogueExample from "./catalogueExample";
import dayjs from "dayjs";
import useUserData from "../../../hooks/user/useUserData";
import CustomTranslation from "../../../components/general/customTranslation/customTranslation";

type Props = {};

export const CatalogueInfo = (props: Props) => {
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	const {open, onClose, exhibition, exhibitor} = props
	const {dateFormat} = useUserData()
	const {catalogLocked, dateFrom, dateTo, daysForCatalogSubmission, translations} = exhibition
	const isOrganizer = useHasPermission([Permissions.BeOrganizer], {shouldView: true})
	const status = eventStatus(dateFrom, dateTo)
	const dayBeforeCatalogSubmission = dayjs(dateFrom).subtract(daysForCatalogSubmission, 'd')
	const canFillCatalogIsBefore = dayjs().isSame(dayBeforeCatalogSubmission, 'day')
	const canFillCatalogIsSame = dayjs().isBefore(dayBeforeCatalogSubmission, 'day')
	const canFillCatalog = canFillCatalogIsBefore || canFillCatalogIsSame
	const catalogDisabled = (isOrganizer && catalogLocked) || (!isOrganizer && (catalogLocked || status !== eventStatus.status.UPCOMING || !canFillCatalog))
	
	return !catalogDisabled && (
		<React.Fragment>
			<Box onClick={(e) => e.stopPropagation()}>
				<Dialog
					open={open}
					onClose={onClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogActions>
						<IconButton
							onClick={onClose}
						>
							<Cancel/>
						</IconButton>
					</DialogActions>
					<DialogTitle sx={{pl: '38px'}}>
						{t("Catalogue Entry Instructions")}
					</DialogTitle>
					<DialogContent>
						<CustomTranslation
							code={'catalogue_instructions'}
							translations={translations}
						>
							<List>
								<ListItem component={"div"}>
									<ListItemText
										primary={
											<Typography component={"div"} variant={"body1"}>
												<Trans
													i18nKey={'catalog_info'}
													ns={EXHIBITOR_PROFILE}
													components={{bold: <strong/>, a: <a/>}}
													values={{
														email: "support@rota.gr",
														date: dayBeforeCatalogSubmission ? formatDate(dayBeforeCatalogSubmission, dateFormat) : ''
													}}
													defaults={'Please check and carefully complete your details for the catalogue according to the instructions displayed bellow. <br/><br/> The "Company Name" you will fill in should appear in the exhibitor list. <br/><br/>  Final submission deadline for catalog details: <strong>{{date}}</strong>.'}
												/>
											</Typography>
										}
									/>
								</ListItem>
								<ListItem component={"div"}>
									<CatalogueExample
										catalogueLanguage={exhibition?.catalogueLanguage}
									/>
								</ListItem>
							</List>
						</CustomTranslation>
					</DialogContent>
				</Dialog>
			</Box>
		</React.Fragment>
	);
};