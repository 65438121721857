import type {Exhibition} from "../../../../../../types/exhibition/types";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import ImageFileUpload from "../../../../../../components/general/imageFileUpload/imageFileUpload";
import * as yup from "yup";
import {FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
}
const Logo = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, language, exhibition, updateExhibition} = props
    const onChange = (value) => {
        updateExhibition(id, {file: value, language}, Update.Logo)
    }
    const validationSchema = yup.object({})
    const formik = useFormik({
        initialValues: {
            files: (
                exhibition.image &&
                exhibition.image[language]
            ) ? [
                {
                    preview: exhibition.image[language],
                    name: "logo",
                }
            ] : []
        },
        validationSchema: validationSchema,
    })
    const onFileChange = (val) => {
        onChange(val)
    }
    return (
        <ListItem>
            <ListItemText
                primary={t("Logo") + " " + language.toUpperCase()}
            />
            <FormikProvider value={formik}>
                <Box
                    sx={{
                        '& .MuiTextField-root': {my: 1},
                    }}
                    component="form"
                    onSubmit={formik.handleSubmit}
                >
                    <ImageFileUpload
                        files={formik.values.files}
                        accept={{
                            "image/*": [".jpeg", ".png"]
                        }}
                        multiple={false}
                        onRemove={onFileChange}
                        onSelected={onFileChange}
                    />
                </Box>
            </FormikProvider>
        </ListItem>
    )
}

export default Logo