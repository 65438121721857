//@flow
import React from 'react';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import FileUpload from "../../../../../components/form/fileUpload/fileUpload";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {DialogActions} from "@mui/material";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import {FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import * as yup from "yup";
import {Trans, useTranslation} from "react-i18next";
import useExhibitionAction from "../../../../../hooks/exhibitions/useExhibitionAction";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import BulkImportStatus from "../../../../../components/app/alerts/bulkImportStatus/bulkImportStatus";
import LoadingButton from '@mui/lab/LoadingButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {ACCEPT_EXCEL, API, PartnerTypes} from "../../../../../helpers/constants";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {AuthenticatedLink} from "../../../../../components/general/authenticatedLink/authenticatedLink";
import {EXHIBITOR_PROFILE} from "../../../../../locales/components/namespaces";
import DialogTransition from "../../../../../components/general/dialogTransition/dialogTransition";

type Props = {
    exhibitionId: string,
    exhibitorId?: string,
    show: boolean,
    onClose: Function,
}

const ExcelImport = (props: Props) => {
    const {show, onClose, exhibitionId, exhibitorId, type} = props
    const {t} = useTranslation()
    const {createBulkPartners, clearBulkPartnersStatus} = useExhibitionAction()
    const {bulkImporting, partnerBulkImportStatus} = useExhibitionData()
    const onSubmit = (values, {resetForm}) => {
        createBulkPartners(exhibitionId, exhibitorId, values, type)
        resetForm()
    }
    const validationSchema = yup.object({
        files: yup
            .array()
            .min(1)
            .required(t("at least one item needs to be here"))
    })
    const formik = useFormik({
        initialValues: {
            files: []
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })
    const onFileChange = (val) => {
        formik.setFieldValue('files', val)
    }
    return (
        <Dialog
            maxWidth={"md"}
            open={show}
            TransitionComponent={DialogTransition}
            keepMounted
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Box
                    sx={{
                        '& .MuiTextField-root': {my: 1},
                    }}
                    component="form"
                    onSubmit={formik.handleSubmit}
                >
                    <DialogTitle>{"Import from Excel"}</DialogTitle>
                    <DialogContent>
                        <BulkImportStatus
                            onClose={clearBulkPartnersStatus}
                            status={partnerBulkImportStatus}
                        />
                        <DialogContentText component={"div"}>
                            <Trans
                                i18nKey={'import_excel_msg'}
                                ns={EXHIBITOR_PROFILE}
                            >
                                If you want to add more than one people quickly you can upload
                                an <strong>Excel</strong> file.<br/>
                                <ul>
                                    <li>Your excel file should follow the structure described bellow.</li>
                                    <li>Make sure your columns are in order as in the example table.</li>
                                    <li>For your convenience, you you also download our blank excel template.</li>
                                </ul>
                            </Trans>
                        </DialogContentText>
                        <TableContainer component={Paper} variant={"outlined"} sx={{mb: 1}}>
                            <Table sx={{minWidth: 650}} size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name EL</TableCell>
                                        <TableCell>Name EN</TableCell>
                                        <TableCell>Email</TableCell>
                                        {(props.type === PartnerTypes.ServiceProvider || props.type === PartnerTypes.ExhibitionStaff) &&
                                            <TableCell>Company</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell>ΓΙΑΝΝΗΣ ΝΤΟ</TableCell>
                                        <TableCell>JOHN DOE</TableCell>
                                        <TableCell>someone@email.com</TableCell>
                                        {(props.type === PartnerTypes.ServiceProvider || props.type === PartnerTypes.ExhibitionStaff) &&
                                            <TableCell>ROTA</TableCell>}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <AuthenticatedLink
                            url={API + "/en/partner/export"}
                            filename={normalizeGreek(t('Download excel template'))}
                        >
                            <Button
                                sx={{mb: 1}}
                                size={"small"}
                                component={"button"}
                            >
                                {normalizeGreek(t('Download excel template'))}
                            </Button>
                        </AuthenticatedLink>
                        <FileUpload
                            accept={{[ACCEPT_EXCEL]: ['.xlsx']}}
                            multiple={false}
                            onRemove={onFileChange}
                            onFilesUpload={onFileChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            size={"small"}
                            type={"submit"}
                            disabled={!(formik.isValid && formik.dirty)}
                            endIcon={<UploadFileIcon/>}
                            loading={bulkImporting}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Import selected file
                        </LoadingButton>
                        <Button
                            size={"small"}
                            onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </FormikProvider>
        </Dialog>

    )
}

export default ExcelImport;