// @flow
import React, {useMemo} from 'react';
import {useInterests} from "../../../../hooks/interests/useInterests";
import {DataGridPremium, GridToolbar} from "@mui/x-data-grid-premium";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import {useInterestsAction} from "../../../../hooks/interests/useInterestsAction";
import _ from "lodash";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import useUserData from "../../../../hooks/user/useUserData";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import {Box, ListItem} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {
	CustomPagination
} from "../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

type Props = {};

const Interests = (props: Props) => {
	const {exhibition, id, loading: loadingExhibition} = useExhibition({language: 'any'})
	const {t} = useTranslation(EXHIBITION)
	const {language} = useUserData()
	const {loading, interests} = useInterests(id)
	const {updateInterests} = useInterestsAction(id)
	const interestsInOrder = useMemo(() => (_.orderBy(interests, ['weight'], ['asc'])), [interests])
	
	const columns = useMemo(() => [
		{
			field: 'weight',
			headerName: t('Order'),
			editable: true,
			type: 'number',
			flex: 1,
			minWidth: 140,
			maxWidth: 140,
			cellClassName: 'custom-column-color',
		},
		{
			field: 'hideInPreregistration',
			headerName: t('Hide In Preregistration'),
			flex: 1,
			minWidth: 120,
			type: 'boolean',
			editable: true,
			cellClassName: 'custom-column-color',
		},
		{
			field: 'id',
			headerName: t('id'),
			editable: false,
			flex: 1,
			minWidth: 120,
		},
		{
			field: 'description',
			headerName: t('Description'),
			flex: 1,
			minWidth: 120,
		},
		{
			field: 'code',
			headerName: t('Code'),
			flex: 1,
			minWidth: 180,
			maxWidth: 180
		}
	], [t])
	
	const processRowUpdate = (updatedRow, originalRow) => {
		updateInterests(updatedRow.id, updatedRow.weight, updatedRow.hideInPreregistration)
		return updatedRow
	}
	const onProcessRowUpdateError = (error) => {
		console.log(error)
	}
	return exhibition ? (
		<Box sx={{maxWidth: '92vw'}}>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: id})}>
					{exhibition.title[language]}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: id})}>
					{t('Settings')}
				</Breadcrumb.Link>
				<Breadcrumb.Item notFocused>
					{t('Profession Domains')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<List>
					<ListItem component={"div"}>
						<ListItemText
							primary={
								<Typography component={"div"} variant={"body1"}>
									<Trans
										i18nKey={'interest_order'}
										ns={EXHIBITION}
									>
										<strong>Double click</strong> on a cell of the <strong>'Order' column</strong>,
										to set the order
										that a profession domain will appear on preregistration form. (Bigger numbers
										will appear at the
										bottom of the list.) <br/><br/>
										<strong>Double click</strong> on a cell of the <strong>'Hide In Preregistration'
										column</strong>,
										to select
										which profession domain will not be displayed in Preregistration form of the
										exhibition.
										<br/><br/>
										Press <strong>Enter</strong> or click out of the cell to save your change.<br/>
									</Trans>
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Paper>
			<Box
				sx={{
					height: 'auto',
					'& .MuiDataGrid-renderingZone': {
						maxHeight: 'none !important',
					},
					'& .MuiDataGrid-cell': {
						lineHeight: 'unset !important',
						maxHeight: 'none !important',
						whiteSpace: 'normal!important',
					},
					'& .MuiDataGrid-row': {
						maxHeight: 'none !important',
					},
					'& .custom-column-color': {
						backgroundColor: '#357a38',
						color: '#FFFFFF',
						fontWeight: 'bold'
					},
					'.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="true"]': {
						color: '#FFFFFF',
					},
					'.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="false"]': {
						color: '#FFFFFF'
					},
				}}
			>
				{interests.length > 0 ? (
					<DataGridPremium
						loading={loading}
						columns={columns}
						initialState={{density: "compact"}}
						rows={interestsInOrder}
						localeText={muiGridLocales(language)}
						processRowUpdate={processRowUpdate}
						onProcessRowUpdateError={onProcessRowUpdateError}
						slots={{
							toolbar: GridToolbar,
							pagination: CustomPagination
						}}
						columnVisibilityModel={{
							id: false
						}}
						sx={{bgcolor: 'background.paper'}}
					/>
				) : (
					<Typography variant={'h6'} component={'div'} sx={{backgroundColor: 'background.paper', p: 1}}>
						{t('No Profession Domains Found')}
					</Typography>
				)}
			</Box>
		</Box>
	) : (
		<ContentLoader
			loading={loadingExhibition}
			notFoundWhenNotLoading
		/>
	)
};

export default Interests