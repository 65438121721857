export const defaultPreregistrationFields = [
    {
        id: "companyName",
        name: "Company Name",
        type: "companyName",
        inputType: "text",
        required: false,
        enabled: true,
        searchable: true,
        maxLength: 100,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: true
    },
    {
        id: "name",
        name: "Name",
        type: "name",
        inputType: "text",
        required: true, // NOT NULLABLE
        enabled: true,
        searchable: true,
        maxLength: 100,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: false
    },
    {
        id: "surname",
        name: "Surname",
        type: "surname",
        inputType: "text",
        required: true,
        enabled: true,
        maxLength: 100,
        visibleOnPreRegistrationForm: true,
        groupOfCompany: false
    },
    {
        id: "vatNumber",
        name: "VAT Number",
        type: "vat",
        inputType: "text",
        required: false,
        enabled: true,
        searchable: true,
        maxLength: 20,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        enableTaxisSearchRegistration: true,
        enableTaxisSearchPreregistration: true,
        groupOfCompany: true
    },
    {
        id: "email",
        name: "Email",
        type: "email",
        inputType: "email",
        required: false,
        enabled: true,
        searchable: true,
        maxLength: 100,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        confirmEmailInPreRegistration: true,
        groupOfCompany: false
    },
    {
        id: "mobilePhone",
        name: "Mobile Phone",
        type: "mobile",
        inputType: "number",
        required: false,
        enabled: true,
        searchable: true,
        maxLength: 50,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: false
    },
    {
        id: "phone",
        name: "Phone",
        type: "tel",
        inputType: "number",
        required: false,
        enabled: true,
        searchable: true,
        maxLength: 50,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: false
    },
    {
        id: "companyAddress",
        name: "Company Address",
        type: "address",
        inputType: "text",
        required: false,
        enabled: true,
        maxLength: 200,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: true
    },
    {
        id: "postalCode",
        name: "Postal Code",
        type: "postalCode",
        inputType: "text",
        required: false,
        enabled: true,
        maxLength: 50,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: true
    },
    {
        id: "city",
        name: "City",
        type: "city",
        inputType: "text",
        required: false,
        enabled: true,
        maxLength: 50,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: true
    },
    {
        id: "countries",
        name: "Countries",
        type: "country",
        inputType: "select",
        required: false,
        enabled: true,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: true
    },
    {
        id: "companyWebsite",
        name: "Company Website",
        type: "companyWebsite",
        inputType: "text",
        enabled: true,
        required: false,
        maxLength: 100,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: true
    },
    {
        id: "interests",
        name: "Interests",
        type: "interests",
        inputType: "select",
        required: false,
        enabled: true,
        multiple: true,
        addNew: true, // nvarchar(MAX)
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: false
    },
    {
        id: "characterization",
        name: "Characterization",
        type: "characterization",
        inputType: "select",
        required: false,
        enabled: true,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: false,
        groupOfCompany: false
    },
    {
        id: "position",
        name: "Position",
        type: "position",
        inputType: 'text',
        required: false,
        enabled: true,
        maxLength: 250,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: false,
        groupOfCompany: true,
    },
    {
        id: "personCount",
        name: "Person Number",
        type: "personCount",
        inputType: 'number',
        required: false,
        enabled: true,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: false
    },
    {
        id: "isIndividual",
        name: "Is individual",
        type: "isIndividual",
        inputType: 'checkbox',
        enabled: true,
        required: false,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: false,
        groupOfCompany: false
    },
    {
        id: "agreeToTerms",
        name: "I agree to terms",
        type: "agreeToTerms",
        inputType: 'checkbox',
        required: false,
        enabled: true,
        visibleOnRegistrationForm: false,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: true,
        groupOfCompany: false
    },
    {
        id: "hasRevisited",
        name: "Has Revisited",
        type: "hasRevisited",
        inputType: 'checkbox',
        enabled: true,
        required: false,
        visibleOnRegistrationForm: true,
        visibleOnPreRegistrationForm: true,
        groupOfCompany: false
    },
    {
        id: "facebook",
        name: "Facebook",
        type: "facebook",
        inputType: "text",
        enabled: true,
        required: false,
        maxLength: 100,
        visibleOnRegistrationForm: false,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: false,
        groupOfCompany: false
    },
    {
        id: "instagram",
        name: "Instagram",
        type: "instagram",
        inputType: "text",
        enabled: true,
        required: false,
        maxLength: 100,
        visibleOnRegistrationForm: false,
        visibleOnPreRegistrationForm: true,
        visibleOnConfirmationDisplay: false,
        groupOfCompany: false
    },
]