//@flow
import React from 'react'
import CheckBoxListRow from '../checkBoxListRow/checkBoxListRow'

const CheckboxList = ({
    data,
    onClick = () => {},
    getStateForId,
    idsToRender = [],
    indentLevel = 0,
}) => {

    // On initial render get top level items with no parent (as an array of ids)
    if (!idsToRender.length) {
        idsToRender = data.filter((i) => !i.parentId).map((i) => i.id);
    }

    const getChildNodes = (parentId) => {
        const nodeItems = data.filter((i) => i.parentId === parentId);
        if (!nodeItems.length) return null;
        return (
            <CheckboxList
                data={data}
                idsToRender={nodeItems.map((i) => i.id)}
                indentLevel={indentLevel + 1}
                onClick={onClick}
                getStateForId={getStateForId}
            />
        );
    };

    return (
        <ul className="tree-list" style={{ paddingLeft: indentLevel * 20 }}>
            {/* Loop over top level items' ids*/}
            {idsToRender.map((id) => {
                // Get item by its id
                const item = data.find((i) => i.id === id)
                // Get item checkbox state
                const itemState = getStateForId(id)
                // Foreach top level return a list
                return (
                    <CheckBoxListRow
                        key={item.id}
                        item={item}
                        itemState={itemState}
                        getChildNodes={getChildNodes}
                        onClick={onClick}
                        indentLevel={indentLevel}
                    />
                )
            })}
        </ul>
    );
};


export default CheckboxList