// @flow
import React, {useEffect, useMemo} from 'react';
import CustomToolbar from "../customToolbar/customToolbar";
import {useGridApiContext} from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";
import {ClickAwayListener, Fade, MenuList, Paper, Popper} from "@mui/material";
import NewViewListButton from "./partials/newViewListButton";
import ViewListItem from "./partials/viewListItem";
import useGridState from "../../../../../hooks/gridState/useGridState";
import {useTranslation} from "react-i18next";
import {GRID_STATE} from "../../../../../locales/components/namespaces";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import _ from "lodash";

type Props = {};

const ToolbarWithState = (props: Props) => {
	const {code} = props
	const apiRef = useGridApiContext()
	const {t} = useTranslation(GRID_STATE)
	const {
		loading,
		activeViewId,
		grid,
		toggleGridPopper,
		setNewGridStateLabel,
		setActiveGridState,
		deleteGridState,
		saveGridState
	} = useGridState({gridUniqueCode: code})
	
	useEffect(() => {
		if (activeViewId) {
			apiRef.current.restoreState(grid.views[activeViewId].state)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeViewId])
	
	const canBeMenuOpened = useMemo(() => (
		!_.isEmpty(grid) && grid.isMenuOpened && Boolean(grid.menuAnchorEl)
	), [grid])
	
	const popperId = useMemo(() => (
		canBeMenuOpened ? `transition-popper-${code}` : undefined
	), [canBeMenuOpened, code])
	
	const isNewViewLabelValid = useMemo(() => {
		if (grid?.newViewLabel?.length === 0) {
			return false;
		}
		return grid && grid.newViewLabel && (!grid.views || (grid.views && Object.values(grid.views).every(
			(view) => view.label !== grid.newViewLabel,
		)))
	}, [grid])
	
	const createNewView = () => {
		saveGridState({
			code: code,
			state: apiRef.current.exportState(),
			isNewView: true,
			grid: grid
		})
	}
	
	const handleDeleteView = (viewId) => {
		deleteGridState({code, viewId, grid})
	}
	
	const handleNewViewLabelChange = (evt) => {
		setNewGridStateLabel({code: code, label: evt.target.value})
	}
	
	const handleClosePopper = () => {
		toggleGridPopper({code})
	}
	
	const handlePopperAnchorClick = (evt) => {
		toggleGridPopper({code, element: evt.currentTarget})
		evt.stopPropagation()
	}
	
	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault()
			toggleGridPopper({code})
		} else if (event.key === 'Escape') {
			toggleGridPopper({code})
		}
	}
	
	const handleSetActiveView = (viewId) => {
		apiRef.current.restoreState(grid.views[viewId].state);
		setActiveGridState({code, viewId, grid})
	}
	
	return (
		<CustomToolbar
			{...props}
		>
			<Button
				aria-describedby={popperId}
				type="button"
				size="small"
				id={`custom-view-button-${code}`}
				aria-controls={grid?.isMenuOpened ? 'custom-view-menu' : undefined}
				aria-expanded={grid?.isMenuOpened ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handlePopperAnchorClick}
				disabled={!grid && !grid?.views && _.isEmpty(grid?.views)}
			>
				{`${normalizeGreek(t('Custom view'))} ${grid && grid.views ? '(' + Object.keys(grid.views).length + ')' : '(0)'}`}
			</Button>
			<ClickAwayListener onClickAway={handleClosePopper}>
				<Popper
					id={popperId}
					open={Boolean(grid?.isMenuOpened)}
					anchorEl={grid?.menuAnchorEl}
					role={undefined}
					transition
					placement="bottom-start"
					sx={{zIndex: 'modal'}}
				>
					{({TransitionProps}) => (
						<Fade {...TransitionProps} timeout={350}>
							<Paper>
								<MenuList
									autoFocusItem={grid?.isMenuOpened}
									id="custom-view-menu"
									aria-labelledby="custom-view-button"
									onKeyDown={handleListKeyDown}
								>
									{grid && grid?.views && Object.entries(grid?.views).map(([viewId, view]) => (
										<ViewListItem
											key={viewId}
											view={view}
											viewId={viewId}
											selected={viewId === grid?.activeViewId}
											onDelete={handleDeleteView}
											onSelect={handleSetActiveView}
										/>
									))}
								</MenuList>
							</Paper>
						</Fade>
					)}
				</Popper>
			</ClickAwayListener>
			<NewViewListButton
				t={t}
				label={grid?.newViewLabel}
				onLabelChange={handleNewViewLabelChange}
				onSubmit={createNewView}
				isValid={isNewViewLabelValid}
			/>
			{props.children}
		</CustomToolbar>
	);
};

export default ToolbarWithState