//@flow
import React, {useMemo, useState} from 'react';
import {
	DataGridPremium,
	GridActionsCellItem,
	gridFilterModelSelector,
	gridSortModelSelector,
	gridVisibleColumnDefinitionsSelector,
	useGridApiRef
} from '@mui/x-data-grid-premium';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import AddVisitorDialog from "../../../../../components/app/addVisitor/addVisitor";
import Toolbar from "@mui/material/Toolbar";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_PROFILE_VISITOR} from "../../../../../routers/routes";
import {EventStatus} from "../../../../../helpers/date";
import VisitorStatus from "../../../../../components/app/visitorStatus/visitorStatus";
import {API, Permissions, VisitorStatus as Status} from "../../../../../helpers/constants";
import {useTranslation} from "react-i18next";
import ExcelImport from "./excelImport";
import {EXHIBITOR_PROFILE, VISITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../../../../components/app/dialogs/confirmDialog/confirmDialog";
import useExhibitionAction from "../../../../../hooks/exhibitions/useExhibitionAction";
import ActionStatus from "../../../../../components/app/alerts/actionStatus/actionStatus";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import ExhibitorsCell from "./exhibitorsCell";
import LoadingButton from "@mui/lab/LoadingButton";
import {Send} from "@mui/icons-material";
import {useExhibition} from "../../../../../hooks/exhibitions/useExhibition";
import PrintBadgeButton from "../../../../../components/app/printBadgeButton/printBadgeButton";
import {useMuiGridServerSide} from "../../../../../hooks/useMuiGridServerSide";
import {mapVisitor} from "../../../../../types/exhibition/map";
import Divider from "@mui/material/Divider";
import {
	showOption
} from "../../../../exhibitionSettings/components/subSettings/generalExhibitionSettings/components/selectExhibitionBusinessCategories";
import useUserData from "../../../../../hooks/user/useUserData";
import {useCountries} from "../../../../../hooks/countries/useCountries";
import {useCategories} from "../../../../../hooks/categories/useCategories";
import {useHasPermission} from "../../../../../modules/permission/hooks/useHasPermission";
import {Chip, Tooltip} from "@mui/material";
import {AuthenticatedLink} from "../../../../../components/general/authenticatedLink/authenticatedLink";
import PermissionHOC from "../../../../../modules/permission/components/permissionHOC/permissionHOC";
import dayjs from "dayjs";
import {muiGridLocales} from "../../../../../helpers/muiGridLocales";
import {
	CustomPagination
} from "../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import axios from "axios";
import {downloadFile} from "../../../../../helpers/fileHandle";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../../../helpers/errorHandling";
import ToolbarWithState
	from "../../../../../components/grids/dataGridCustom/toolbars/toolbarWithState/toolbarWithState";

type Props = {
	limitedView?: boolean,
	exhibitionId: string,
	exhibitorId?: string,
}

const GridToolbar = () => (
	<ToolbarWithState
		code={'visitors_page_all'}
		exportSlotProps={{
			csvOptions: {
				disableToolbarButton: true
			},
			excelOptions: {
				disableToolbarButton: true
			},
			printOptions: {
				disableToolbarButton: true
			}
		}}
	/>
)

const Visitors = (props: Props) => {
	const apiRef = useGridApiRef();
	const {t} = useTranslation([EXHIBITOR_PROFILE, VISITOR_PROFILE])
	const {language, dateFormat, timeFormat, token} = useUserData()
	const [loadingExport, setLoadingExport] = useState(false)
	const {exhibitionId, exhibitorId, limitedView} = props
	const {exhibition} = useExhibition({id: exhibitionId, noFetchFiles: true})
	const {countries} = useCountries()
	const {categories} = useCategories(exhibitionId)
	const isOrganizer = useHasPermission([Permissions.BeOrganizer], {shouldView: true})
	const [showFileUpload, setShowFileUpload] = useState(false)
	const [showAddDialog, setShowAddDialog] = useState(false)
	const [visitorIdToRemove, setVisitorIdToRemove] = useState(null)
	const [confirmDeleteVisitors, setConfrimDeleteVisitors] = useState(null)
	const [sendInvitations, setSendInvitations] = useState({
		send: false,
		type: 'all',
		selectionModel: [],
		recipientEmail: '',
		returnCount: false,
		exhibitorId: exhibitorId ? exhibitorId : 0,
		alert: false
	})
	const {actionOnVisitorStatus, loading} = useExhibitionData()
	const {
		deleteMultipleVisitors,
		removeVisitor,
		clearActionOnVisitorStatus,
		sendVisitorInvitations,
		clearBulkVisitorStatus
	} = useExhibitionAction()
	const url = `exhibition/${exhibitionId}/exhibitor/${exhibitorId ? exhibitorId : 0}/visitors`
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		convertSortModel,
		onFilterModelChange,
		convertFilterModel,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		mapFunction: mapVisitor,
		initialSortModel: [{name: 'LastModifiedAt', direction: 'descending'}]
	})
	const onRemove = async (visitorIdToRemove) => {
		await removeVisitor(exhibitionId, exhibitorId, visitorIdToRemove)
		setVisitorIdToRemove(null)
		refreshGrid()
	}
	const onRemoveButtonClick = (id) => {
		setVisitorIdToRemove(id)
	}
	
	const columns = useMemo(() => [
			...(!limitedView ? [{
					field: 'id',
					headerName: t('Badge'),
					type: 'actions',
					renderCell: (({row, value}) => {
						return [6, 7].includes(Number(row.StatusId)) && <PrintBadgeButton
							userId={value}
							type={"visitor"}
							printSize={exhibition.visitorBadgeTemplateTypeId}
						/>
					})
				}] : []
			),
			{
				field: 'VisitorName',
				headerName: t('Name'),
				renderCell: ({row}) => !limitedView ? (
					<Link
						to={getRouteUrl(ROUTE_PAGE_PROFILE_VISITOR, {
							id: exhibitionId,
							visitorId: row.id
						})}
					>
						{row.VisitorName}
					</Link>
				) : row.VisitorName,
				minWidth: 160,
				flex: 1
			},
			{field: 'VisitorEmail', headerName: t('Email'), minWidth: 160, flex: 1},
			{
				field: 'InvitationStatus',
				headerName: t(VISITOR_PROFILE + ':Invitation Status'),
				minWidth: 150, flex: 1,
				type: 'boolean',
				renderCell: ({value}) => {
					return (
						<Chip
							size={"small"}
							label={value ? t(VISITOR_PROFILE + ':Sent') : t(VISITOR_PROFILE + ':Pending')}
							color={value ? "success" : "error"}
						/>
					)
				}
			},
			{
				field: 'StatusId',
				headerName: t('Status'),
				minWidth: 200, flex: 1,
				type: 'singleSelect',
				valueOptions: Object.entries(Status).map(status => {
					const value = status[1]
					return {
						value: value,
						label: t(VISITOR_PROFILE + ':status_' + value)
					}
				}),
				renderCell: (({value}) => <VisitorStatus status={value}/>),
			},
			...(!limitedView ? [
				{field: 'VisitorBusinessPhone', headerName: t('Phone'), minWidth: 120, flex: 1}
			] : []),
			{field: 'VisitorMobilePhone', headerName: t('Mobile Phone'), minWidth: 120, flex: 1},
			{
				field: 'Characterization',
				headerName: t('Characterization'),
				minWidth: 200, flex: 1,
				type: 'singleSelect',
				valueOptions: categories.map(category => ({
					value: category.id,
					label: category.text
				})),
				renderCell: ({value}) => value?.id ? showOption(language)(value) : '-',
			},
			{
				field: 'CountryId',
				headerName: t('Country'),
				minWidth: 160,
				type: 'singleSelect',
				valueOptions: countries.map(type => ({
					value: type.id,
					label: type.description
				})),
				flex: 1
			},
			...(!limitedView ? [{
				field: 'InvitedBy', headerName: t('Invited By'), flex: 1, minWidth: 160,
				renderCell: ({row}) => (
					<ExhibitorsCell
						exhibitionId={exhibitionId}
						row={row}
					/>
				)
			}] : []),
			...(!limitedView ? [{
				field: 'FromPreregistration',
				headerName: t(VISITOR_PROFILE + ':From Preregistration'),
				flex: 1,
				minWidth: 140,
				type: 'boolean'
			}] : []),
			...(!limitedView ? [{
				field: 'FromRegistration',
				headerName: t(VISITOR_PROFILE + ':From Registration'),
				flex: 1,
				minWidth: 120,
				type: 'boolean'
			}] : []),
			...(!limitedView ? [{
				field: 'InvitationDt',
				type: 'date',
				headerName: t('Invitation Date'),
				valueGetter: (value, row, column, apiRef) => {
					return value && new Date(value)
				},
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format(dateFormat) + " " + dayjs(value).format(timeFormat) : '-')
				},
				flex: 1,
				minWidth: 200
			}] : []),
			...(!limitedView ? [{
				field: 'PreregistrationDt',
				type: 'date',
				headerName: t('Preregistration Date'),
				valueGetter: (value, row, column, apiRef) => {
					return value && new Date(value)
				},
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format(dateFormat) + " " + dayjs(value).format(timeFormat) : '-')
				},
				flex: 1,
				minWidth: 200
			}] : []),
			...(!limitedView ? [
				{
					field: 'LastModifiedAt',
					headerName: t('Last Modified Date'),
					flex: 1,
					type: 'date',
					minWidth: 200,
					valueGetter: (value, row, column, apiRef) => {
						return value && new Date(value)
					},
					valueFormatter: (value, row, column, apiRef) => {
						return (value ? dayjs(value).format(dateFormat) + " " + dayjs(value).format(timeFormat) : '-')
					},
				},
			] : []),
			...(data.length > 0 && exhibition && ((isOrganizer && exhibition.status !== EventStatus.EXPIRED) || (!isOrganizer && dayjs().isBefore(exhibition.dateFrom, 'date'))) ? [{
				headerName: t('Actions'),
				field: 'actions',
				type: 'actions',
				getActions: (params) => [
					<GridActionsCellItem
						onClick={() => onRemoveButtonClick(params.id)}
						icon={<DeleteIcon/>}
						label={t("Remove")}
						color={"error"}
					/>,
				],
			}] : [])
		],
		[limitedView, t, categories, countries, data.length, exhibition, isOrganizer, exhibitionId, language, dateFormat, timeFormat]
	)
	
	const onDeleteMultipleVisitors = async (ids) => {
		await deleteMultipleVisitors(ids)
		setConfrimDeleteVisitors(false)
		refreshGrid()
	}
	
	const mapSortModel = model => {
		return model.map(f => {
			if (f.name === 'InvitedBy') return {name: 'ExhibitorName', direction: f.direction}
			if (f.name === 'Characterization') return {name: 'CharacterizationId', direction: f.direction}
			return f
		})
	}
	
	const mapFilterModel = model => {
		if (model.length > 0) {
			model[0].predicates = model[0].predicates.map(f => {
				if (f.field === 'InvitationStatus') return {...f, value: f.value === 'true' ? '1' : '0'}
				if (f.field === 'InvitedBy') return {...f, field: 'ExhibitorName'}
				if (f.field === 'Characterization') return {...f, field: 'CharacterizationId'}
				return f
			})
			return model
		}
	}
	
	const mapColumnFields = field => {
		if (field === 'InvitedBy') return 'ExhibitorName'
		if (field === 'Characterization') return 'CharacterizationId'
		return field
	}
	
	const exportVisitors = () => {
		const sortModel = gridSortModelSelector(apiRef)
		const where = gridFilterModelSelector(apiRef)
		const columns = gridVisibleColumnDefinitionsSelector(apiRef)
		const data = {
			sorted: convertSortModel(sortModel, mapSortModel),
			where: convertFilterModel(where, null, mapFilterModel),
			columns: columns.filter(f => f.type !== 'custom' && f.type !== 'actions').map(i => ({
				id: mapColumnFields(i.field),
				title: i.headerName
			}))
		}
		setLoadingExport(true)
		axios({
			url: `${API}/${language}/${url}/export`,
			method: 'post',
			responseType: "blob",
			data: data,
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
		}).then((response) => {
			const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			const href = window.URL.createObjectURL(blob)
			downloadFile(href, 'export-visitors')
			setLoadingExport(false)
		}).catch((error) => {
			setLoadingExport(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to export!'), {
				variant: 'warning',
				autoHideDuration: null,
			}))
		})
	}
	return (
		<Box sx={{maxWidth: '92vw'}}>
			{exhibition && (
				<>
					<Toolbar disableGutters variant={"dense"}>
						<Box sx={{flexGrow: 1}}/>
						<Stack direction="row">
							<PermissionHOC requiredPermissions={[Permissions.BeOrganizer]}>
								<>
									<Tooltip
										title={t('Export is based on the columns that are visible on your grid and uses any filters or sorting that are applied in your grid.')}>
										<LoadingButton
											loading={loadingExport}
											variant={"outlined"}
											size={"small"}
											onClick={exportVisitors}
										>
											{normalizeGreek(t('Export Visitor Grid'))}
										</LoadingButton>
									</Tooltip>
									<AuthenticatedLink
										filename={'export'}
										url={`${API}/en/exhibition/export-visitors/${exhibitionId}`}
									>
										<Button
											size={"small"}
											sx={{m: '0 1px'}}
											variant={"outlined"}
										>
											{normalizeGreek(t('Export Visitors (Marketing)'))}
										</Button>
									</AuthenticatedLink>
								</>
							</PermissionHOC>
							{((isOrganizer && exhibition.status !== EventStatus.EXPIRED) || (!isOrganizer && dayjs().isBefore(exhibition.dateFrom, 'date'))) && (
								<>
									<Button
										size={"small"}
										onClick={() => setShowFileUpload(true)}
										sx={{m: '0 1px'}}
										variant={"outlined"}
									>
										{normalizeGreek(t('Import from excel'))}
									</Button>
									<Button
										size={"small"}
										sx={{m: '0 1px'}}
										variant={"contained"}
										onClick={() => setShowAddDialog(true)}
									>
										{normalizeGreek(t('Add a visitor'))}
									</Button>
								</>
							)}
						</Stack>
					</Toolbar>
					<ExcelImport
						preregistrationNeedsApproval={exhibition?.preregistrationNeedsApproval}
						visitorCategoryRequiredInImport={exhibition?.visitorCategoryRequiredInImport}
						limitedView={limitedView}
						exhibitionId={exhibitionId}
						exhibitorId={exhibitorId}
						show={showFileUpload}
						onClose={() => {
							setShowFileUpload(false);
							clearBulkVisitorStatus();
							refreshGrid()
						}}
					/>
					<AddVisitorDialog
						loading={loading}
						limitedView={limitedView}
						exhibitorId={exhibitorId}
						exhibitionId={exhibitionId}
						exhibition={exhibition}
						open={showAddDialog}
						onClose={() => {
							setShowAddDialog(false);
							refreshGrid()
						}}
					/>
					<Divider sx={{mb: 2}} light/>
					{((isOrganizer && exhibition.status !== EventStatus.EXPIRED) || (!isOrganizer && dayjs().isBefore(exhibition.dateFrom, 'date'))) && (
						<Stack spacing={1} direction={"row"} sx={{mb: 1}}>
							<LoadingButton
								disabled={sendInvitations?.selectionModel.length === 0}
								loading={loading}
								loadingPosition="start"
								variant={"outlined"}
								size={"small"}
								startIcon={<Send/>}
								onClick={() => {
									sendVisitorInvitations(exhibitionId, sendInvitations.exhibitorId, 'ids', sendInvitations.selectionModel, '', true)
									setSendInvitations(old => ({...old, type: 'ids', send: true, alert: false}))
								}}
							>
								{normalizeGreek(t('Send invitation to {{count}} visitors', {count: sendInvitations?.selectionModel.length}))}
							</LoadingButton>
							<LoadingButton
								loading={loading}
								loadingPosition="start"
								variant={"outlined"}
								size={"small"}
								startIcon={<Send/>}
								onClick={() => {
									sendVisitorInvitations(exhibitionId, sendInvitations.exhibitorId, 'notsent', [], '', true)
									setSendInvitations(old => ({
										...old,
										type: 'notsent',
										selectionModel: [],
										send: true,
										alert: false
									}))
								}}
							>
								{normalizeGreek(t('Send Invitation (New)'))}
							</LoadingButton>
							<PermissionHOC requiredPermissions={[Permissions.BeOrganizer]}>
								<LoadingButton
									loading={loading}
									loadingPosition="start"
									variant={"outlined"}
									size={"small"}
									startIcon={<Send/>}
									onClick={() => {
										sendVisitorInvitations(exhibitionId, sendInvitations.exhibitorId, 'all', [], '', true)
										setSendInvitations(old => ({
											...old,
											type: 'all',
											selectionModel: [],
											send: true,
											alert: false
										}))
									}}
								>
									{normalizeGreek(t('Send Invitation (All)'))}
								</LoadingButton>
							</PermissionHOC>
							<LoadingButton
								loading={loading}
								loadingPosition="start"
								variant={"outlined"}
								size={"small"}
								color={'error'}
								startIcon={<DeleteIcon/>}
								disabled={sendInvitations.selectionModel.length === 0}
								onClick={() => {
									setConfrimDeleteVisitors(true)
								}}
							>
								{normalizeGreek(t('Delete {{count}} visitors', {count: sendInvitations?.selectionModel.length}))}
							</LoadingButton>
						</Stack>
					)}
					<Box
						sx={{
							height: 600
						}}>
						{columns && columns.length > 0 && (
							<DataGridPremium
								apiRef={apiRef}
								disableRowGrouping
								disableAggregation
								sx={{bgcolor: 'background.paper', maxHeight: '600px'}}
								initialState={{density: "compact"}}
								columns={columns}
								// keepNonExistentRowsSelected // Use to keep row selections when changing server side page
								loading={isLoading}
								localeText={muiGridLocales(language)}
								pagination
								paginationMode='server'
								paginationModel={{page, pageSize}}
								onPaginationModelChange={onPaginationModelChange}
								rows={data}
								slots={{
									toolbar: GridToolbar,
									pagination: CustomPagination
								}}
								slotProps={{
									toolbar: {
										csvOptions: {
											disableToolbarButton: true
										},
										excelOptions: {
											disableToolbarButton: true
										},
										printOptions: {
											disableToolbarButton: true
										}
									}
								}}
								rowCount={Number(total)}
								pageSizeOptions={[5, 10, 15, 20, 50, 100]}
								sortingMode="server"
								onSortModelChange={(sortModel) => onSortModelChange(sortModel, mapSortModel)}
								filterMode='server'
								onFilterModelChange={(filterModel, details) => onFilterModelChange(filterModel, details, mapFilterModel)}
								checkboxSelection={exhibition &&
									exhibition.status !== EventStatus.EXPIRED}
								rowSelectionModel={sendInvitations?.selectionModel}
								onRowSelectionModelChange={(newSelectionModel) => exhibition &&
									exhibition.status !== EventStatus.EXPIRED && setSendInvitations(old => ({
										...old,
										selectionModel: newSelectionModel
									}))}
							/>
						)}
					</Box>
					<ConfirmDialog
						onYes={() => {
							onRemove(visitorIdToRemove)
						}}
						onNo={() => setVisitorIdToRemove(false)}
						handleDialog={(newValue) => setVisitorIdToRemove(newValue)}
						title={t('Confirm Delete')}
						open={Boolean(visitorIdToRemove)}
					>
						{t('This action is permanent. Press OK if you want to proceed.')}
					</ConfirmDialog>
					<ConfirmDialog
						onYes={() => {
							onDeleteMultipleVisitors(sendInvitations.selectionModel)
						}}
						onNo={() => setConfrimDeleteVisitors(false)}
						handleDialog={(newValue) => setConfrimDeleteVisitors(newValue)}
						title={t('Confirm Delete')}
						open={Boolean(confirmDeleteVisitors)}
					>
						{t('This action is permanent. Press OK if you want to proceed.')}
					</ConfirmDialog>
					<ConfirmDialog
						onYes={() => {
							sendVisitorInvitations(exhibitionId, sendInvitations.exhibitorId, sendInvitations.type, sendInvitations.selectionModel, sendInvitations.recipientEmail, false);
							setSendInvitations(old => ({...old, alert: true, send: false}))
						}}
						onNo={() => setSendInvitations(old => ({...old, send: false}))}
						title={t('Confirm Sent')}
						open={sendInvitations?.send}
						handleDialog={(newValue) => setSendInvitations(old => ({...old, send: newValue}))}
					>
						{actionOnVisitorStatus ? t(actionOnVisitorStatus.message, actionOnVisitorStatus.parameters ?? {}) : ''}
					</ConfirmDialog>
					<ActionStatus
						status={actionOnVisitorStatus}
						onClose={() => {
							clearActionOnVisitorStatus();
							setSendInvitations(old => ({...old, selectionModel: []}));
							refreshGrid()
						}}
						autoHideDuration={sendInvitations?.alert === false ? null : 3000}
					/>
				</>
			)}
		</Box>
	)
}

export default Visitors
