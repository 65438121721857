import useGridStateAction from "./useGridStateAction";
import {useEffect} from "react";
import useGridStateData from "./useGridStateData";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {GRID_STATE} from "../../locales/components/namespaces";

const useGridState = ({gridUniqueCode}) => {
	const {t} = useTranslation(GRID_STATE)
	const {
		getGridState,
		setGridStateMessage,
		toggleGridPopper,
		setNewGridStateLabel,
		setActiveGridState,
		deleteGridState,
		saveGridState
	} = useGridStateAction()
	const {loading, message, grid, activeViewId} = useGridStateData({gridUniqueCode})
	useEffect(() => {
		if (gridUniqueCode) getGridState(gridUniqueCode)
	}, [gridUniqueCode, getGridState])
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setGridStateMessage(null)
		}
	}, [message, setGridStateMessage, t])
	return {
		loading,
		grid,
		activeViewId,
		toggleGridPopper,
		setNewGridStateLabel,
		setActiveGridState,
		deleteGridState,
		saveGridState
	}
}

export default useGridState