// @flow
import React, {useEffect, useMemo, useState} from 'react';
import EditableMUIDataGrid from "../../../../components/grids/editableMUIDataGrid/editableMUIDataGrid";
import {useCoorganizations} from "../../../../hooks/coorganizations/useCoorganizations";
import {useCoorganizationsAction} from "../../../../hooks/coorganizations/useCoorganizationsAction";
import {Alert, ListItem, Snackbar} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION, GENERAL_SETTINGS} from "../../../../locales/components/namespaces";
import {GridActionsCellItem} from "@mui/x-data-grid-premium";
import {Close, Tune} from "@mui/icons-material";
import {useCoorganizationsData} from "../../../../hooks/coorganizations/useCoorganizationsData";
import Box from "@mui/material/Box";
import useExhibitionAction from "../../../../hooks/exhibitions/useExhibitionAction";
import useExhibitionData from "../../../../hooks/exhibitions/useExhibitionData";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import SelectExhibitions from "./selectExhibitions";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {Permissions} from "../../../../helpers/constants";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";

type Props = {};

const Coorganizations = (props: Props) => {
	const {t} = useTranslation(GENERAL_SETTINGS)
	const [openSelectExhibitions, setOpenSelectExhibitions] = useState(null)
	const {indexTable: exhibitions} = useExhibitionData()
	const {getExhibitions} = useExhibitionAction()
	const {
		coorganizations,
		loading
	} = useCoorganizations()
	const {
		saveCoorganization,
		updateCoorganization,
		deleteCoorganization,
		clearCoorganizationStatus,
		addExhibitionToCoorganization,
		removeExhibitionFromCoorganization
	} = useCoorganizationsAction()
	const {status, updateExhibitionList} = useCoorganizationsData()
	useEffect(() => {
		getExhibitions()
	}, [getExhibitions, updateExhibitionList])
	const newRowModel = {
		descriptionEn: '',
		descriptionEl: ''
	}
	const columns = useMemo(() => [
		{
			field: 'selectExhibitions',
			headerName: t('Select Exhibitions'),
			type: 'actions',
			width: 140,
			getActions: (params) => [
				<GridActionsCellItem
					onClick={() => setOpenSelectExhibitions(params.row.id)}
					icon={<Tune/>}
					label={t("Select Exhibitions")}
				/>,
			],
		},
		{
			field: 'descriptionEn',
			headerName: t('Description EN'),
			editable: true,
			flex: 1
		},
		{
			field: 'descriptionEl',
			headerName: t('Description EL'),
			editable: true,
			flex: 1
		},
	
	], [t])
	return (
		<React.Fragment>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('Coorganizations')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<List>
					<ListItem component={"div"}>
						<ListItemText
							primary={
								<Typography component={"div"} variant={"body1"}>
									<Trans
										i18nKey={'create-coorganization'}
										ns={EXHIBITION}
									>
										On this page you can set a new <strong>Coorganization</strong> and connect it
										with exhibitions.
									</Trans>
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Paper>
			<Box>
				<EditableMUIDataGrid
					data={coorganizations}
					columns={columns}
					loading={loading}
					newRowModel={newRowModel}
					store={saveCoorganization}
					remove={deleteCoorganization}
					update={updateCoorganization}
				/>
				<Dialog
					disableEscapeKeyDown
					open={Boolean(openSelectExhibitions)}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') setOpenSelectExhibitions(null)
					}}
					fullWidth={true}
					maxWidth={'md'}
				>
					<DialogActions>
						<IconButton
							onClick={() => setOpenSelectExhibitions(null)}
						>
							<Close/>
						</IconButton>
					</DialogActions>
					<DialogTitle>{t('Select Exhibitions')}</DialogTitle>
					<DialogContent>
						<SelectExhibitions
							coorganizationId={openSelectExhibitions}
							exhibitions={exhibitions}
							removeExhibitionFromCoorganization={removeExhibitionFromCoorganization}
							addExhibitionToCoorganization={addExhibitionToCoorganization}
						/>
					</DialogContent>
				</Dialog>
				<Snackbar
					open={Boolean(status)}
					anchorOrigin={{
						vertical: 'top', horizontal: 'right',
					}}
					autoHideDuration={2000}
					onClose={clearCoorganizationStatus}>
					<Alert
						onClose={clearCoorganizationStatus}
						severity={status?.type}
						sx={{width: '100%', fontSize: '16px'}}
					>
						{t(status?.message)}
					</Alert>
				</Snackbar>
			</Box>
		</React.Fragment>
	);
};

export default withPermissionHOC(Coorganizations, [Permissions.BeOrganizer])