//@flow
import React, {useEffect} from 'react';
import {Flipper} from 'react-flip-toolkit';
import {Box} from '@mui/material';
import Sortly from '../Sortly';
import ItemRenderer from './ItemRenderer';
import type {Props} from "../../dragDropTree";
import _ from "lodash"
import Grid from "@mui/material/Grid";

const Advanced = (props: Props) => {
    const {items: propItems, onDragEnd: propDragEnd, renderItemActions, renderItemTitle} = props
    const [items, setItems] = React.useState([])
    const handleChange = (newItems) => setItems(newItems)
    useEffect(() => {
        setItems(propItems)
    }, [propItems])
    const onDragEnd = () => {
        if (!_.isEqual(propItems, items)) {
            propDragEnd(items)
        }
    }
    return (
        <Box>
            <Flipper flipKey={items.map(({id}) => id).join('.')}>
                <Grid container spacing={1}>
                    <Sortly
                        items={items}
                        onChange={handleChange}
                        onDragEnd={onDragEnd}
                    >
                        {(props) => (
                            <ItemRenderer
                                {...props}
                                renderItemActions={renderItemActions}
                                renderItemTitle={renderItemTitle}
                            />
                        )}
                    </Sortly>
                </Grid>
            </Flipper>
        </Box>
    )
}

export default Advanced
