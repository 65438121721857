// @flow
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import useExhibitionAction from "../../../../../hooks/exhibitions/useExhibitionAction";
import ActionStatus from "../../../../../components/app/alerts/actionStatus/actionStatus";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import {EXHIBITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {CatalogueLanguages, PartnerTypes} from "../../../../../helpers/constants";
import Typography from "@mui/material/Typography";

type Props = {
	open: boolean,
	exhibitorId: string,
	exhibitionId: string,
	onClose: Function,
	canAddPartner?: boolean,
	type?: string,
	catalogueLanguage?: boolean,
	addButtonText?: string
}

export default function AddPartnerDialog(props: Props) {
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	const {open, onClose, exhibitionId, exhibitorId, type, catalogueLanguage, canAddPartner, addButtonText} = props
	const {actionOnPartnerStatus} = useExhibitionData()
	const {createPartner, clearActionOnPartnerStatus} = useExhibitionAction()
	const validationSchema = yup.object({
		name: catalogueLanguage === CatalogueLanguages.EN ? yup.string() : yup.string().required(t('required')),
		nameEn: catalogueLanguage === CatalogueLanguages.EL ? yup.string() : yup.string().required(t('required')).matches(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi, t('Only latin and special characters allowed.')),
		email: yup
		.string()
		.email(t('Enter a valid email')),
		companyName: catalogueLanguage === CatalogueLanguages.EN ? yup.string().matches(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi, t('Only latin and special characters allowed.')) : yup.string().notRequired(),
		
	})
	const onSubmit = (values, {resetForm}) => {
		createPartner(exhibitionId, exhibitorId, values, type)
		resetForm()
	}
	const formik = useFormik({
		initialValues: {
			name: '',
			nameEn: '',
			email: '',
			companyName: ''
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	const dialogueText = type === PartnerTypes.Partner ? 'Type contact information of the person you want to add as a partner for this event.' :
		type === PartnerTypes.ServiceProvider ? 'Type contact information of the person you want to add as a service provider for this event.' : 'Type contact information of the person you want to add as exhibition staff for this event.'
	
	// const
	return (
		<FormikProvider value={formik}>
			<Dialog
				open={open}
				onClose={(event, reason) => {
					if (reason === 'backdropClick' || reason === 'escapeKeyDown') return
					formik.resetForm();
					onClose();
				}}
			>
				<Box component="form" onSubmit={formik.handleSubmit} noValidate>
					<DialogTitle>{t(addButtonText)}</DialogTitle>
					<ActionStatus
						status={actionOnPartnerStatus}
						onClose={clearActionOnPartnerStatus}
					/>
					<DialogContent>
						<DialogContentText>
							{t(dialogueText)}
						</DialogContentText>
						<Box
							sx={{
								'& .MuiTextField-root': {my: 1},
							}}>
							{catalogueLanguage !== CatalogueLanguages.EN && (
								<TextField
									fullWidth
									variant={"filled"}
									margin="dense"
									id="name"
									label={t("Name (EL)")}
									value={formik.values.name}
									onBlur={formik.handleBlur}
									onChange={(e) => {
										const oldValue = e.target.value
										formik.setFieldValue('name', normalizeGreek(oldValue.toUpperCase()))
									}}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
								/>
							)}
							{catalogueLanguage !== CatalogueLanguages.EL && (
								<TextField
									fullWidth
									variant={"filled"}
									margin="dense"
									label={t("Name (EN)")}
									value={formik.values.nameEn}
									onBlur={formik.handleBlur}
									onChange={(e) => {
										const oldValue = e.target.value
										formik.setFieldValue('nameEn', normalizeGreek(oldValue.toUpperCase()))
									}}
									error={formik.touched.nameEn && Boolean(formik.errors.nameEn)}
									helperText={formik.touched.nameEn && formik.errors.nameEn}
								/>
							)}
							<TextField
								fullWidth
								variant={"filled"}
								margin="dense"
								id="email"
								label={t("Email")}
								type="email"
								value={formik.values.email}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
							/>
							{(type === PartnerTypes.ServiceProvider || type === PartnerTypes.ExhibitionStaff) && (
								<TextField
									fullWidth
									variant={"filled"}
									margin="dense"
									id="companyName"
									label={t("Company Name")}
									value={formik.values.companyName}
									onBlur={formik.handleBlur}
									onChange={(e) => {
										const oldValue = e.target.value
										formik.setFieldValue('companyName', normalizeGreek(oldValue.toUpperCase()))
									}}
									error={formik.touched.companyName && Boolean(formik.errors.companyName)}
									helperText={formik.touched.companyName && formik.errors.companyName}
								/>
							)}
						</Box>
						{!canAddPartner && type === PartnerTypes.Partner && (
							<Typography
								variant={'body1'}
								color={'error'}
							>
								{t('You reached the limit of partners you can add.')}
							</Typography>
						
						)}
					</DialogContent>
					<DialogActions>
						<Button variant={"contained"} type={'submit'} disabled={!canAddPartner}>{t("OK")}</Button>
						<Button onClick={onClose}>{normalizeGreek(t("Cancel"))}</Button>
					</DialogActions>
				</Box>
			</Dialog>
		</FormikProvider>
	)
}