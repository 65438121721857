//@flow
import React, {useContext, useEffect} from 'react';
import Box from "@mui/material/Box";
import {Logo, Title} from "../../../pages/main/common";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Toolbar from "@mui/material/Toolbar";
import MainNavigation from "../mainNavigation";
import ContentRouter from "../../../routers/contentRouter";
import {TitleContext} from "../../../pages/main/content";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import {Permissions} from "../../../helpers/constants";
import {useHasPermission} from "../../../modules/permission/hooks/useHasPermission";
import Divider from "@mui/material/Divider";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

const MenuWithDrawer = () => {
    const {toolbar, menuNavigation} = useContext(TitleContext)
    const [open, setOpen] = React.useState(false)
    const handleDrawerOpen = () => setOpen(true)
    const handleDrawerClose = () => setOpen(false)
    const isHome = window.location.pathname === "/"
    const isOrganizer = useHasPermission([Permissions.BeOrganizer], {shouldView: true})
    const shouldDisplayDrawer = (isOrganizer || (!isOrganizer && !isHome))
    const shouldDrawerRemainOpen = (!isOrganizer && !isHome)
    useEffect(() => {
        if (!shouldDisplayDrawer) {
            setOpen(false)
        }
    }, [shouldDisplayDrawer])
    const isOpen = shouldDrawerRemainOpen ? shouldDrawerRemainOpen : open
    return (
        <Box sx={{display: 'flex'}}>
            <AppBar
                elevation={0}
                color={"secondary"}
                position="fixed"
                open={isOpen}
            >
                <Title showLogo={!shouldDisplayDrawer}>
                    {shouldDisplayDrawer && (
                        <IconButton
                            color="inherit"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(isOpen && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )}
                </Title>
            </AppBar>
            {shouldDisplayDrawer && (
                <Drawer variant="permanent" open={isOpen}>
                    <DrawerHeader>
                        <Logo/>
                        {!shouldDrawerRemainOpen && (
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        )}
                    </DrawerHeader>
                    {toolbar && <Toolbar variant={"dense"}/>}
                    {!isOrganizer && <Divider/>}
                    {menuNavigation ? menuNavigation : null}
                    {(isOrganizer) && (
                        <MainNavigation
                            open={isOpen}
                        />
                    )}
                </Drawer>
            )}
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: 'grey.100', p: 2, minHeight: "100vh", pt: toolbar ? 10 : 4}}
            >
                <Toolbar variant={"dense"}/>
                <ContentRouter/>
            </Box>
        </Box>

    )
}

export default MenuWithDrawer;