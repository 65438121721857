//@flow
import React, {useEffect} from 'react';
import {Alert, Snackbar} from "@mui/material";
import useExhibitionData from "../../../../hooks/exhibitions/useExhibitionData";
import useExhibitionAction from "../../../../hooks/exhibitions/useExhibitionAction";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";

const ExhibitionUpdateStatus = () => {
    const {t} = useTranslation(EXHIBITION)
    const {clearUpdateExhibitionStatus} = useExhibitionAction()
    const {updatingStatus} = useExhibitionData()
    const handleCloseAlert = () => {
        clearUpdateExhibitionStatus()
    }
    useEffect(() => {
        return () => {
            clearUpdateExhibitionStatus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Snackbar
            open={Boolean(updatingStatus)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
        >
            <Alert
                onClose={handleCloseAlert}
                severity={updatingStatus?.type}
                sx={{width: '100%'}}
            >
                {updatingStatus ? t(updatingStatus.message) : ''}
            </Alert>
        </Snackbar>
    )
}

export default ExhibitionUpdateStatus;