import router from '../routers/routes'
import componentLocales from '../locales/components/locales'
import pageLocales from '../locales/pages/locales'
import api from "../api/api"
import userReducer from "../reducer/currentUser/currentUser"
import exhibitionReducer from "../reducer/exhibition/exhibition";
import activeExhibitionsReducer from "../reducer/activeExhibitions/activeExhibitions";
import exhibitionFilesReducer from "../reducer/exhibitionFiles/exhibitionFiles";
import faqReducer from "../reducer/faq/faq";
import badgeReducer from "../reducer/badge/badge";
import emailReducer from "../reducer/email/email"
import countriesReducer from "../reducer/countries/countries"
import interestsReducer from "../reducer/interests/interests"
import categoriesReducer from "../reducer/categories/categories"
import messageTemplatesReducer from "../reducer/messageTemplates/messageTemplates"
import entersoftTransfer from "../reducer/entersoftTransfer/entersoftTransfer";
import rulesReducer from '../reducer/rules/rules'
import coorganizationsReducer from '../reducer/coorganizations/coorganizations'
import reportsReducer from '../reducer/reports/reports'
import gridStateReducer from '../reducer/gridState/gridState'
import uiTranslationsReducer from '../reducer/uiTranslations/uiTranslations'

export const loadModules = (modules) => {
    return ({
            loadPermissions: () => {
                return modules.reduce((prv, curr) => {
                    const {permissions, modules} = curr
                    if (!permissions) return prv
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).loadPermissions() : {}),
                        ...permissions,
                    })
                }, {})
            },
            loadRoutes: () => {
                const applicationRoutes = router ? router : {}
                const routes = modules.reduce((prv, curr) => {
                    const {routes, modules} = curr
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).loadRoutes() : {}),
                        ...(routes ? routes : {}),
                    })
                }, applicationRoutes)
                return Object.keys(routes).map(key => routes[key])
            },
            getTranslations: () => {
                return modules.reduce((prv, curr) => {
                    const {name, locales, modules} = curr
                    if (!locales) return prv
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).getTranslations() : {}),
                        ...(locales ? {[name]: locales} : {}),
                    })
                }, {
                    component_translations: componentLocales,
                    page_translations: pageLocales,
                })
            },
            getRequestHandlers: () => {
                return modules.reduce((prv, curr) => {
                    const {name, api, modules} = curr
                    if (!api) return prv
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).getRequestHandlers() : {}),
                        [name]: api,
                    })
                }, {
                    app_request: api,
                })
            },
            loadReducers: () => {
                return modules.reduce((prv, curr) => {
                    const {name, reducer, modules} = curr
                    if (!reducer) return prv
                    return {
                        ...prv,
                        ...(modules ? loadModules(modules).loadReducers() : {}),
                        [name]: {
                            initialState: reducer.initialState,
                            handlers: reducer.handlers
                        },
                    }
                }, {
                    currentUser: userReducer,
                    exhibition: exhibitionReducer,
                    exhibitionFiles: exhibitionFilesReducer,
                    activeExhibitions: activeExhibitionsReducer,
                    faq: faqReducer,
                    badge: badgeReducer,
                    email: emailReducer,
                    countries: countriesReducer,
                    interests: interestsReducer,
                    categories: categoriesReducer,
                    messageTemplates: messageTemplatesReducer,
                    entersoftTransfer: entersoftTransfer,
                    rules: rulesReducer,
                    coorganizations: coorganizationsReducer,
                    reports: reportsReducer,
                    gridState: gridStateReducer,
                    uiTranslations: uiTranslationsReducer
                })
            }
        }
    )
}
