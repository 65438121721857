// @flow
import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import MenuItem from "@mui/material/MenuItem";
import {CatalogueLanguages} from "../../../../../../helpers/constants";
import Select from "@mui/material/Select";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
};

const CatalogueLanguage = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props
    
    const onChange = (value) => {
        updateExhibition(id, value, Update.CatalogueLanguage)
    }
    return (
        <ListItem>
            <ListItemText
                primary={t('Catalogue Language')}
                secondary={t('Select if catalogue will be displayed in english, greek or both languages.')}
            />
            <Select
                labelId={'catalogueCountryLabel'}
                variant={'standard'}
                value={exhibition.catalogueLanguage}
                onChange={(evt) => onChange(evt.target.value)}
                size={"small"}
                sx={{minWidth: '220px'}}
            >
                <MenuItem
                    value={CatalogueLanguages.ALL}
                >
                    {t('English & Greek')}
                </MenuItem>
                <MenuItem
                    value={CatalogueLanguages.EN}
                >
                    {t('English')}
                </MenuItem>
                <MenuItem
                    value={CatalogueLanguages.EL}
                >
                    {t('Greek')}
                </MenuItem>
            </Select>
        </ListItem>
    );
};

export default CatalogueLanguage