//@flow
import React, {useEffect, useMemo, useState} from 'react';
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import EditIcon from "@mui/icons-material/Edit";
import PrintBadgeButton from "../../../../../components/app/printBadgeButton/printBadgeButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import Toolbar from "@mui/material/Toolbar";
import useBadgeAction from "../../../../../hooks/badges/useBadgeAction";
import useBadgeData from "../../../../../hooks/badges/useBadgeData";
import AddVisitorCategory from "../../../../../components/app/addVisitorCategory/addVisitorCategory";
import DeleteIcon from "@mui/icons-material/Delete";
import useVisitorCategories from "../../../../../hooks/visitorCategories/useVisitorCategories";
import ActionStatus from "../../../../../components/app/alerts/actionStatus/actionStatus";
import {muiGridLocales} from "../../../../../helpers/muiGridLocales";
import useUserData from "../../../../../hooks/user/useUserData";

const Categories = ({exhibitionId: id}) => {
	const {t} = useTranslation(EXHIBITION)
	const {language} = useUserData()
	const [open, setOpen] = useState({
		open: false,
		category: null
	})
	const {getBadgeTemplateTypes} = useBadgeAction()
	const {badgeTemplateTypes} = useBadgeData()
	const {
		loading,
		visitorCategories,
		saveVisitorCategory,
		updateVisitorCategory,
		deleteVisitorCategory,
		message,
		onAlertClose
	} = useVisitorCategories(id)
	
	useEffect(() => {
		getBadgeTemplateTypes()
	}, [getBadgeTemplateTypes])
	
	const columns = useMemo(() => [
		{
			headerName: t('Actions'),
			field: 'actions',
			type: 'actions',
			getActions: (params) => [
				<GridActionsCellItem
					onClick={(evt) => {
						setOpen({
							open: true,
							category: params.row
						})
					}}
					icon={<EditIcon/>}
					label={t("Edit")}
					color={"info"}
				/>,
				<GridActionsCellItem
					onClick={(evt) => {
						deleteVisitorCategory(params.row.id);
					}}
					icon={<DeleteIcon/>}
					label={t("Delete")}
					color={"error"}
				/>
			]
		},
		{
			field: 'preview',
			headerName: t('Preview'),
			type: 'actions',
			minWidth: 120,
			renderCell: ({row}) => (
				<PrintBadgeButton
					isPreview={true}
					printSize={row.badgeTemplateTypeId}
					categoryTypeId={row.id}
					type={"visitor"}
					exhibitionId={id}
				/>
			)
		},
		{
			field: 'registrationPreview',
			headerName: t('Registration Preview'),
			type: 'actions',
			renderCell: ({row}) => (
				<PrintBadgeButton
					isPreview={true}
					printSize={row.regBadgeTemplateTypeId}
					categoryTypeId={row.id}
					type={"visitor"}
					exhibitionId={id}
				/>
			)
		},
		{
			field: 'backgroundColor',
			headerName: t('Background Color'),
			flex: 1,
			minWidth: 100,
			renderCell: ({row}) => (
				<div style={{
					backgroundColor: row.backgroundColor,
					width: '80px',
					height: '24px',
					border: '1px dotted #b0bec5'
				}}></div>
			)
		},
		{
			field: 'textColor',
			headerName: t('Text Color'),
			flex: 1,
			minWidth: 120,
			renderCell: ({row}) => (
				<div style={{
					backgroundColor: row.textColor,
					width: '80px',
					height: '24px',
					border: '1px dotted #b0bec5'
				}}></div>
			)
		},
		{
			field: 'text.el',
			headerName: t('Greek Category Description'),
			minWidth: 180,
			flex: 1,
			renderCell: ({row}) => (<div>{row.text.el}</div>)
		},
		{
			field: 'text.en',
			headerName: t('English Category Description'),
			minWidth: 180,
			flex: 1,
			renderCell: ({row}) => (<div>{row.text.en}</div>)
		},
		{
			field: 'explanation.el',
			headerName: t('Greek Category Explanation'),
			minWidth: 180,
			flex: 1,
			renderCell: ({row}) => (<div>{row.explanation.el}</div>)
		},
		{
			field: 'explanation.en',
			headerName: t('English Category Explanation'),
			minWidth: 180,
			flex: 1,
			renderCell: ({row}) => (<div>{row.explanation.en}</div>)
		},
		{
			field: 'displayVisitorLabelInBadge',
			headerName: t('Display Visitor Label In Badge'),
			type: 'boolean',
			minWidth: 240,
			flex: 1
		},
		{
			field: 'displayBadgeInEnglish',
			headerName: t('Display Badge Only In English'),
			type: 'boolean',
			minWidth: 240,
			flex: 1
		},
		{
			field: 'showPreregistration',
			headerName: t('Visible In Preregistration'),
			type: 'boolean',
			minWidth: 220,
			flex: 1
		},
		{
			field: 'showOrganizer', headerName: t('Visible To Organizer'), type: 'boolean',
			minWidth: 180,
			flex: 1
		},
		{
			field: 'badgeTemplateTypeId',
			headerName: t('Print Paper Size'),
			minWidth: 180,
			type: 'singleSelect',
			valueOptions: badgeTemplateTypes.map(type => ({
				value: type.id,
				label: type.description
			})),
			flex: 1
		},
		{
			field: 'regBadgeTemplateTypeId',
			headerName: t('Registration Print Paper Size'),
			minWidth: 260,
			type: 'singleSelect',
			valueOptions: badgeTemplateTypes.map(type => ({
				value: type.id,
				label: type.description
			})),
			flex: 1
		}
	], [badgeTemplateTypes, deleteVisitorCategory, id, t])
	return (
		<React.Fragment>
			<Box sx={{width: '92vw'}}>
				<Toolbar disableGutters variant={"dense"}>
					<Box sx={{flexGrow: 1}}/>
					<Stack direction="row">
						<Button
							size={"small"}
							variant={"contained"}
							onClick={() => setOpen({
								...open, open: true
							})}
						>
							{normalizeGreek(t('Add'))}
						</Button>
					</Stack>
				</Toolbar>
				<div style={{height: 600, width: '100%'}}>
					<DataGridPremium
						localeText={muiGridLocales(language)}
						disableAggregation
						disableRowGrouping
						sx={{bgcolor: 'background.paper'}}
						initialState={{density: "compact"}}
						columns={columns}
						loading={loading}
						rows={visitorCategories}
					/>
				</div>
				{badgeTemplateTypes.length > 0 && <AddVisitorCategory
					open={open.open}
					onClose={() => {
						setOpen({open: false, category: null});
					}}
					exhibitionId={id}
					badgeTemplateTypes={badgeTemplateTypes}
					saveVisitorCategory={saveVisitorCategory}
					updateVisitorCategory={updateVisitorCategory}
					category={open.category}
				/>}
				<ActionStatus
					status={message}
					onClose={onAlertClose}
				/>
			</Box>
		</React.Fragment>
	)
}

export default Categories;
