import {Rule, RuleDetails, UnmappedRule, UnmappedRuleDetails} from "./types";

export const mapRule: Rule = (datum: UnmappedRule) => {
    if (!datum) return
    return {
        id: datum.id,
        description: datum.Description,
        exhibitionId: datum.ExhibitionId,
        characterizationId: datum.CharacterizationId,
    }
}

export const mapRuleDetails: RuleDetails = (datum: UnmappedRuleDetails) => {
    if (!datum) return
    return {
        ruleId: datum.RuleId,
        id: datum.id,
        field: datum.Field,
        operator: datum.Type,
        value: datum.Value,
        editMode: datum?.editMode ? datum.editMode : false
    }
}