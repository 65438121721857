import type {UnmappedVisitorCategory, VisitorCategory} from "./type";

export const mapVisitorCategory: VisitorCategory = (datum: UnmappedVisitorCategory) => {
    if (!(datum && datum.length > 0)) return []
    return datum.map(d => ({
        id: d.id,
        textColor: d.Color2,
        backgroundColor: d.Color1,
        text: {
            en: d.DescriptionEN,
            el: d.DescriptionEL,
        },
        explanation: {
            en: d.ExplanationEN,
            el: d.ExplanationEL,
        },
        displayVisitorLabelInBadge: d.hasVisitorText === '1',
		displayBadgeInEnglish: d.displayBadgeInEnglish === '1',
        showPreregistration: d.showPreregistration === '1',
        showOrganizer: d.showOrganizer === '1',
        badgeTemplateTypeId: d.BadgeTemplateTypeId,
        regBadgeTemplateTypeId: d.RegBadgeTemplateTypeId,
    }))
}