 import {useCallback, useMemo} from "react";
import _ from "lodash";

type ItemsList = Array<{
    id: number,
    depth: number,
    data: Object
}>

type Params = {
    unprocessedItems: Array<{
        id: number,
        parent: number,
    }>,
    onDragEndCallback: (updatedItems: Array<ItemsList>, newItems: Array<ItemsList>) => void
}


export const useDragDropTree = (params: Params = {}) => {
    const {unprocessedItems = [], onDragEndCallback} = params

    const itemsList: ItemsList = useMemo(() => {
            const mapItem = (f, depth = 0) => {
                const children = unprocessedItems
                    .filter(n => n.parent === f.id)
                    .map(n => mapItem(n, depth + 1))
                return [
                    {
                        id: f.id,
                        depth: depth,
                        data: f,
                    },
                    ...children
                ]
            }
            return _.flattenDeep(unprocessedItems
                .map(f => ({
                    ...f,
                    parent: f.parent ?? 0
                }))
                .filter(f => f.parent === 0)
                .map(f => mapItem(f, 0))
            )

        }, [unprocessedItems]
    )


    const onDragEnd = useCallback((rawOutputItems) => {
        const outputItems = []
        const updatedItems = []
        rawOutputItems
            .forEach((i, index) => {
                const {depth, data} = i
                const {parent, weight} = data
                const newParent = depth === 0 ? 0 : _.findLast(rawOutputItems, j => j.depth < depth, index).id
                const previousSibling = _.findLast(outputItems, j => j.parent === newParent)
                const newWeight = previousSibling ? previousSibling.weight + 1 : 1
                const newObj = {
                    ...data,
                    parent: newParent,
                    weight: newWeight,
                }
                if ((newParent !== parent) || (newWeight !== weight)) {
                    updatedItems.push(newObj)
                }
                outputItems.push(newObj)
            })
        if (onDragEndCallback) {
            onDragEndCallback(updatedItems, outputItems, rawOutputItems)
        }
    }, [onDragEndCallback])

    return {
        itemsList,
        onDragEnd
    }
}
