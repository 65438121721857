// @flow 
import React, {useEffect} from 'react';
import DynamicListAsTable from "../../../../../../components/general/dynamicListAsTable/dynamicListAsTable";
import Row from "./row";
import {useRulesAction} from "../../../../../../hooks/rules/useRulesAction";
import {useRulesData} from "../../../../../../hooks/rules/useRulesData";

type Props = {};

export const RuleContext = React.createContext(null)

const RuleConditions = (props: Props) => {
    const {ruleId, exhibitionId, countries, interests} = props
    const {
        getRuleDetails,
        addRuleDetails,
        editRuleDetails,
        cancelEditRuleDetails,
        updateRuleDetails,
        saveRuleDetails,
        deleteRuleDetails,
        setRuleDetails
    } = useRulesAction({exhibitionId})
    const {ruleDetails, updateRuleDetailsSource} = useRulesData(ruleId)

    useEffect(() => {
        if (ruleId) getRuleDetails(ruleId)
    }, [getRuleDetails, ruleId, updateRuleDetailsSource])

    return (
        <RuleContext.Provider
            value={{
                countries,
                interests
            }}
        >
            <DynamicListAsTable
                id={ruleId}
                row={Row}
                rows={ruleDetails}
                onAddRow={addRuleDetails}
                onEditRow={editRuleDetails}
                onSaveRow={saveRuleDetails}
                onUpdateRow={updateRuleDetails}
                onDeleteRow={deleteRuleDetails}
                onCancelEditRow={cancelEditRuleDetails}
                handleRows={setRuleDetails}
            />
        </RuleContext.Provider>
    );
};

export default RuleConditions