// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {FormControl, FormHelperText, InputLabel, Paper, Select} from "@mui/material";
import TextField from "@mui/material/TextField";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {ErrorMessage, FormikProvider} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import {Print} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import ColorSelect from "../../../../../components/app/badgeForm/printable/colorSelect/colorSelect";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import useQuickBadgePrint from "../../../../../hooks/quickBadgePrint/useQuickBadgePrint";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {};

const Form = (props: Props) => {
	const {badgeTemplateTypes, id, setShowImport} = props
	const {t} = useTranslation(EXHIBITION)
	const {formik, loading} = useQuickBadgePrint({exhibitionId: id, badgeTemplateTypes})
	return (
		<FormikProvider value={formik}>
			<Paper sx={{mb: 2, p: 1, width: {sm: '100%', md: '49%', lg: '69%'}}} variant={"outlined"}>
				<Toolbar disableGutters variant={"dense"}>
					<Stack direction="row" justifyContent={'space-between'} width={'100%'}>
						<Button
							size={"small"}
							startIcon={<ClearIcon/>}
							color="error"
							onClick={formik.resetForm}
						>
							{normalizeGreek(t('Clear Form'))}
						</Button>
						<Button
							size={"small"}
							onClick={() => setShowImport(true)}
						>
							{normalizeGreek(t('Import from excel'))}
						</Button>
					</Stack>
				</Toolbar>
				<Box
					component="form"
					onSubmit={formik.handleSubmit}
					noValidate
					pt={1}
				>
					<FormControl
						sx={{mb: 1}}
						variant="standard"
						fullWidth
						error={
							formik.touched["nameEn"] &&
							Boolean(formik.errors["nameEn"])
						}
					>
						<TextField
							label={t("Name EN")}
							value={formik.values.nameEn}
							name={`nameEn`}
							size={"small"}
							onChange={(e) => {
								const oldValue = e.target.value
								e.target.value = normalizeGreek(oldValue.toUpperCase())
								formik.handleChange(e)
							}}
							onBlur={formik.handleBlur}
							fullWidth
							required
							autoComplete={"off"}
							InputLabelProps={{shrink: true}}
						/>
						<FormHelperText>
							<ErrorMessage name={"nameEn"}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						sx={{mb: 1}}
						variant="standard"
						fullWidth
						error={
							formik.touched["nameEl"] &&
							Boolean(formik.errors["nameEl"])
						}
					>
						<TextField
							label={t("Name EL")}
							value={formik.values.nameEl}
							name={`nameEl`}
							size={"small"}
							onChange={(e) => {
								const oldValue = e.target.value
								e.target.value = normalizeGreek(oldValue.toUpperCase())
								formik.handleChange(e)
							}}
							onBlur={formik.handleBlur}
							fullWidth
							required
							autoComplete={"off"}
							InputLabelProps={{shrink: true}}
						/>
						<FormHelperText>
							<ErrorMessage name={"nameEl"}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						sx={{mb: 1}}
						variant="standard"
						fullWidth
						error={
							formik.touched["companyEn"] &&
							Boolean(formik.errors["companyEn"])
						}
					>
						<TextField
							label={t("Company Name EN")}
							value={formik.values.companyEn}
							name={`companyEn`}
							size={"small"}
							onChange={(e) => {
								const oldValue = e.target.value
								e.target.value = normalizeGreek(oldValue.toUpperCase())
								formik.handleChange(e)
							}}
							onBlur={formik.handleBlur}
							fullWidth
							autoComplete={"off"}
							InputLabelProps={{shrink: true}}
						/>
						<FormHelperText>
							<ErrorMessage name={"companyEn"}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						sx={{mb: 1}}
						variant="standard"
						fullWidth
						error={
							formik.touched["companyEl"] &&
							Boolean(formik.errors["companyEl"])
						}
					>
						<TextField
							label={t("Company Name EL")}
							value={formik.values.companyEl}
							name={`companyEl`}
							size={"small"}
							onChange={(e) => {
								const oldValue = e.target.value
								e.target.value = normalizeGreek(oldValue.toUpperCase())
								formik.handleChange(e)
							}}
							onBlur={formik.handleBlur}
							fullWidth
							autoComplete={"off"}
							InputLabelProps={{shrink: true}}
						/>
						<FormHelperText>
							<ErrorMessage name={"companyEl"}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						sx={{mb: 1}}
						variant="standard"
						fullWidth
						error={
							formik.touched["characterizationEn"] &&
							Boolean(formik.errors["characterizationEn"])
						}
					>
						<TextField
							label={t("Characterization EN")}
							value={formik.values.characterizationEn}
							name={`characterizationEn`}
							size={"small"}
							onChange={(e) => {
								const oldValue = e.target.value
								e.target.value = normalizeGreek(oldValue.toUpperCase())
								formik.handleChange(e)
							}}
							onBlur={formik.handleBlur}
							fullWidth
							autoComplete={"off"}
							InputLabelProps={{shrink: true}}
						/>
						<FormHelperText>
							<ErrorMessage name={"characterizationEn"}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						sx={{mb: 1}}
						variant="standard"
						fullWidth
						error={
							formik.touched["characterizationEl"] &&
							Boolean(formik.errors["characterizationEl"])
						}
					>
						<TextField
							label={t("Characterization EL")}
							value={formik.values.characterizationEl}
							name={`characterizationEl`}
							size={"small"}
							onChange={(e) => {
								const oldValue = e.target.value
								e.target.value = normalizeGreek(oldValue.toUpperCase())
								formik.handleChange(e)
							}}
							onBlur={formik.handleBlur}
							fullWidth
							autoComplete={"off"}
							InputLabelProps={{shrink: true}}
						/>
						<FormHelperText>
							<ErrorMessage name={"characterizationEl"}/>
						</FormHelperText>
					</FormControl>
					<Box>
						<FormControl
							variant="standard"
							sx={{
								mr: 1,
								textAlign: "center",
								paddingRight: 1,
								paddingBottom: 1,
								minWidth: '280px'
							}}
						>
							<FormLabel sx={{mb: .5}}>
								{t('Background Color')}
							</FormLabel>
							<ColorSelect
								color={formik.values.backgroundColor}
								onColorChange={(color) => formik.setFieldValue(`backgroundColor`, color)}
							/>
						</FormControl>
						<FormControl
							variant="standard"
							sx={{
								mr: 1,
								textAlign: "center",
								paddingRight: 1,
								paddingBottom: 1,
								minWidth: '280px'
							}}
						>
							<FormLabel sx={{mb: .5}}>
								{t('Text Color')}
							</FormLabel>
							<ColorSelect
								color={formik.values.textColor}
								onColorChange={(color) => formik.setFieldValue(`textColor`, color)}
							/>
						</FormControl>
						<FormControl
							variant="outlined"
							error={
								formik.touched["templateId"] &&
								Boolean(formik.errors["templateId"])
							}
							sx={{
								mr: 1,
								textAlign: "center",
								paddingRight: 1,
								paddingBottom: 1,
								minWidth: '240px',
								maxWidth: '60%',
								width: '100%'
							}}
						>
							<InputLabel
								id="templateId-simple-select">{t('Print Size')}</InputLabel>
							<Select
								labelId="templateId-simple-select"
								autoComplete={"chrome-off"}
								size={"small"}
								name={"templateId"}
								value={formik.values.templateId}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								label={t('Print Size')}
							>
								{badgeTemplateTypes.filter(f => f.id === '1' || f.id === '2').map(item => (
									<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
								))}
							</Select>
							<FormHelperText>
								<ErrorMessage name={"templateId"}/>
							</FormHelperText>
						</FormControl>
					</Box>
					<LoadingButton
						size={"small"}
						startIcon={<Print/>}
						variant="contained"
						color="success"
						type={'submit'}
						loading={loading}
						loadingPosition="start"
					>
						{normalizeGreek(t('PRINT'))}
					</LoadingButton>
				</Box>
			</Paper>
		</FormikProvider>
	);
};

export default Form