//@flow
import React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {copyToClipboard} from "../../../helpers/copyToClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";

type Props = {
    text: string,
}

const CopyToClipboard = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const [open, setOpen] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const handleClose = () => {
        setTimeout(() => {
            setOpen(false)
        }, 1000)
    }
    const handleOpen = () => {
        setTitle(t('Copy'))
        setOpen(true)
    }
    const {text, bgColor = null, color = null} = props
    return (
        <ListItem sx={{bgcolor: bgColor ? bgColor : '#aebbff', color: color ? color : '#000000'}}>
            <ListItemText
                primary={text}
            />
            <Tooltip
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                title={title}
            >
                <IconButton
                    color={"inherit"}
                    onClick={(e) => {
                        setTitle(t('Copied'))
                        copyToClipboard(text)
                        e.stopPropagation()
                    }}>
                    <ContentCopyIcon/>
                </IconButton>
            </Tooltip>
        </ListItem>
    )
}

export default CopyToClipboard;