import {useParams} from "react-router";
import useExhibitionAction from "./useExhibitionAction";
import useExhibitionData from "./useExhibitionData";
import {useEffect} from "react";

export const useExhibitionBusinessCategories = ({language, id: propsId} = {}) => {
    const {id: paramsId} = useParams()
    const id = propsId ?? paramsId
    const {getExhibitionBusinessCategories} = useExhibitionAction()
    const {businessCategoriesByExhibitionId: businessCategories, loading} = useExhibitionData({id, overrideLanguage: language})
    useEffect(() => {
        getExhibitionBusinessCategories(id)
    }, [getExhibitionBusinessCategories, id])
    return {
        id,
        businessCategories,
        loading
    }
}