// @flow
import React from 'react';
import List from "@mui/material/List";
import {ListSubheader, Switch, TextField} from "@mui/material";
import CopyToClipboard from "../../../../../components/general/copyToClipboard/copyToClipboard";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import type {Exhibition} from "../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import {Update} from "../../../../../hooks/exhibitions/useExhibitionAction";
import {useDebouncedCallback} from "../../../../../hooks/useDebouncedCallback";
import {NumericFormat} from "react-number-format";
import Typography from "@mui/material/Typography";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";

type Props = {
	id: string,
	updateExhibition: Function,
	exhibition: Exhibition,
}

const RegistrationSettings = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {exhibition, updateExhibition, id} = props
	const toggleDisableRegistrationMessages = () => {
		updateExhibition(id, !exhibition.disableRegistrationMessages, Update.DisableRegistrationMessages)
	}
	const onChangeRegistrationStartDays = (value) => {
		updateExhibition(id, value, Update.RegistrationStartDays)
	}
	const {
		value: registrationStartDays,
		debouncedOnChange: debouncedOnChangeRegistrationStartDays
	} = useDebouncedCallback(onChangeRegistrationStartDays, exhibition.registrationStartDays)
	const onChangeRegistrationEndDays = (value) => {
		updateExhibition(id, value, Update.RegistrationEndDays)
	}
	const {
		value: registrationEndDays,
		debouncedOnChange: debouncedOnChangeRegistrationEndDays
	} = useDebouncedCallback(onChangeRegistrationEndDays, exhibition.registrationEndDays)
	return (
		<List
			subheader={
				<ListSubheader sx={{backgroundColor: '#F9FBF2'}}>
					<Typography
						variant={'button'}
						sx={{color: '#000000'}}
					>
						{normalizeGreek(t('Registration'))}
					</Typography>
				</ListSubheader>
			}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<CopyToClipboard
				text={`${exhibition.domainRegistration}`}
			/>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t("Disable Registration Messages")}
					secondary={t('If enabled emails and SMS are not sent after registering a visitor.')}
				/>
				<Switch
					edge="end"
					onChange={toggleDisableRegistrationMessages}
					checked={exhibition.disableRegistrationMessages}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t("Number of days to activate registration before exhibition start")}
				/>
				<NumericFormat
					defaultValue={registrationStartDays}
					variant={'outlined'}
					onChange={debouncedOnChangeRegistrationStartDays}
					inputProps={{maxLength: 4}}
					required
					thousandSeparator={''}
					decimalScale={0}
					allowNegative={false}
					customInput={TextField}
					size={'small'}
					allowLeadingZeros={true}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem>
				<ListItemText
					primary={t("Number of days to de-activate registration after exhibition end")}
				/>
				<NumericFormat
					defaultValue={registrationEndDays}
					onChange={debouncedOnChangeRegistrationEndDays}
					variant={'outlined'}
					inputProps={{maxLength: 4}}
					required
					thousandSeparator={''}
					decimalScale={0}
					allowNegative={false}
					customInput={TextField}
					size={'small'}
					allowLeadingZeros={true}
				/>
			</ListItem>
		</List>
	);
};

export default RegistrationSettings