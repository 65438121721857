//@flow
import React, {useContext, useState} from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import useExhibitionAction from "../../../../hooks/exhibitions/useExhibitionAction";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import {TabPanel} from "../../../../components/general/tabPanel/tabPanel";
import ExhibitionUpdateStatus from "../../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import {TitleContext} from "../../../main/content";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import type {Exhibition} from "../../../../types/exhibition/types";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import Exhibitors from "./partials/exhibitors";
import Areas from "./partials/areas";

type InnerProps = {
    exhibition: Exhibition,
    onSubmit: Function
}

const InnerContent = (props: InnerProps) => {
    const {t} = useTranslation(EXHIBITION)
    const {files, exhibition, onSubmit} = props
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => setValue(newValue)
    const {setToolbar} = useContext(TitleContext)
    // useEffect(() => {
    //     setToolbar(
    //         <React.Fragment>
    //             <Tabs
    //                 variant="scrollable"
    //                 value={value}
    //                 onChange={handleChange}
    //                 indicatorColor="secondary"
    //                 textColor="inherit"
    //             >
    //                 {/*<Tab value={0} label={normalizeGreek(t("Welcome Page"))}/>*/}
    //                 {/*<Tab value={1} label={normalizeGreek(t("FAQs"))}/>*/}
    //             </Tabs>
    //         </React.Fragment>
    //     )
    //     return () => {
    //         setToolbar(null)
    //     }
    // }, [setToolbar, t, value])
    return (
        <React.Fragment>
            <Breadcrumb>
                <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                    {t('Home')}
                </Breadcrumb.Link>
                <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
                    {exhibition.title}
                </Breadcrumb.Link>
                <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
                    {t('Settings')}
                </Breadcrumb.Link>
                <Breadcrumb.Item notFocused>
                    {t('B2B')}
                </Breadcrumb.Item>
            </Breadcrumb>
            <TabPanel isVisible={value === 0}>
                <Areas
                />
                <Exhibitors
                    pending={[{name: "hello", id: "2"}, {name: "world", id: "1"}]}
                />
            </TabPanel>
            <TabPanel isVisible={value === 1}>

            </TabPanel>
            <ExhibitionUpdateStatus/>
        </React.Fragment>
    )
}

const B2B = () => {
    const {exhibition, loading, id} = useExhibition({
        noFetchFiles: true
    })
    const {updateExhibition} = useExhibitionAction()
    const onSubmit = (values) => {
        // updateExhibition(id, values, Update.WelcomePage)
    }
    return (
        <React.Fragment>
            {exhibition ? (
                <InnerContent
                    onSubmit={onSubmit}
                    exhibition={exhibition}
                />
            ) : (
                <ContentLoader
                    loading={loading}
                    notFoundWhenNotLoading
                />
            )}
        </React.Fragment>
    )
}

export default B2B;