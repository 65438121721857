import {useDispatch} from "react-redux";
import {useCallback} from "react";
import * as actions from "../../actions/actions";
import {randomId} from "@mui/x-data-grid-generator";

const useGridStateAction = () => {
	const dispatch = useDispatch()
	
	const setGridStateMessage = useCallback((message) => {
		dispatch(actions.setGridStateMessage(message))
	}, [dispatch])
	
	const getGridState = useCallback((code) => {
		dispatch(actions.getGridState({
			Grids: [{Code: code}],
			code: code
		}))
	}, [dispatch])
	
	const saveGridState = useCallback(({code, state, isNewView, grid}) => {
		const newId = randomId()
		const stateObject = {
			gridUniqueCode: code,
			activeViewId: newId,
			newViewLabel: '',
			isMenuOpened: false,
			menuAnchorEl: undefined,
			views: {
				...grid.views,
				[newId]: {
					id: newId,
					label: grid.newViewLabel,
					state: state
				}
			}
		}
		dispatch(actions.saveGridState({
			Grids: [{Code: code, State: JSON.stringify(stateObject)}],
			code: code
		}))
	}, [dispatch])
	
	const deleteGridState = useCallback(({code, viewId, grid}) => {
		const filterViews = Object.fromEntries(
			Object.entries(grid.views).filter(([id]) => id !== viewId)
		);
		let updateGrid = {}
		if (grid.activeViewId === viewId) {
			const viewIds = Object.keys(filterViews)
			updateGrid = {
				...grid,
				activeViewId: viewIds.length > 0 ? viewIds[0] : null,
				menuAnchorEl: undefined,
				isMenuOpened: false,
				views: filterViews,
			}
		} else {
			updateGrid = {
				...grid,
				menuAnchorEl: undefined,
				isMenuOpened: false,
				views: filterViews,
			}
		}
		dispatch(actions.deleteGridState({
			Grids: [{Code: code, State: JSON.stringify(updateGrid)}],
			code: code,
			viewId: viewId
		}))
	}, [dispatch])
	
	const setActiveGridState = useCallback(({code, viewId, grid}) => {
		const updateGrid = {
			...grid,
			activeViewId: viewId,
			menuAnchorEl: undefined,
			isMenuOpened: false
		}
		dispatch(actions.setActiveGridState({
			Grids: [{Code: code, State: JSON.stringify(updateGrid)}],
			code,
			viewId
		}))
	}, [dispatch])
	
	const setNewGridStateLabel = useCallback(({code, label}) => {
		dispatch(actions.setNewGridStateLabel({code, label}))
	}, [dispatch])
	
	const toggleGridPopper = useCallback(({code, element = null}) => {
		dispatch(actions.toggleGridPopper({
			...(element ? {element} : {}),
			code
		}))
	}, [dispatch])
	
	return {
		setGridStateMessage,
		getGridState,
		saveGridState,
		deleteGridState,
		setActiveGridState,
		setNewGridStateLabel,
		toggleGridPopper
	}
}

export default useGridStateAction