import * as React from 'react';
import Grid from "@mui/material/Grid";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_HOME} from "../../routers/routes";
import HomeIcon from '@mui/icons-material/Home';
import useExhibitionAction from "../../hooks/exhibitions/useExhibitionAction";
import {useExhibition} from "../../hooks/exhibitions/useExhibition";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import RegistrationSettings from "./components/subSettings/registrationSettings/registrationSettings";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../locales/components/namespaces";
import CatalogSettings from "./components/subSettings/catalogSettings/catalogSettings";
import ExhibitionUpdateStatus from "../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import Breadcrumb from "../../components/general/breadcrumb/breadcrumb";
import {useMultilingualText} from "../../hooks/useMultilingualText";
import ExhibitionPageTitle from "../../components/app/exhibitionPageTitle/exhibitionPageTitle";
import MessageSettings from "./components/subSettings/messageSettings/messageSettings";
import GeneralExhibitionSettings from "./components/subSettings/generalExhibitionSettings/generalExhibitionSettings";
import PreregistrationSettings from "./components/subSettings/preregistrationSettings/preregistrationSettings";
import ExhibitorSettings from "./components/subSettings/exhibitorSettings/exhibitorSettings";
import ImportSettings from "./components/subSettings/importSettings/importSettings";
import BadgeSettings from "./components/subSettings/badgeSettings/badgeSettings";

const ExhibitionSettings = () => {
	const {t} = useTranslation(EXHIBITION)
	const {id, exhibition, loading} = useExhibition({language: 'any'})
	const {updateExhibition} = useExhibitionAction()
	const title = useMultilingualText(exhibition?.title)
	return (
		<React.Fragment>
			{exhibition ? (
				<React.Fragment>
					<ExhibitionPageTitle
						exhibition={exhibition}
					/>
					<Breadcrumb>
						<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
							{t('Home')}
						</Breadcrumb.Link>
						<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id})}>
							{title}
						</Breadcrumb.Link>
						<Breadcrumb.Item>
							{t('Settings')}
						</Breadcrumb.Item>
					</Breadcrumb>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<GeneralExhibitionSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
							<CatalogSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
							<ExhibitorSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
							{/*<B2bSettings*/}
							{/*    id={id}*/}
							{/*    exhibition={exhibition}*/}
							{/*    updateExhibition={updateExhibition}*/}
							{/*/>*/}
						</Grid>
						<Grid item xs={12} md={6}>
							<PreregistrationSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
							<RegistrationSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
							<MessageSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
							<ImportSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
							<BadgeSettings
								id={id}
								exhibition={exhibition}
								updateExhibition={updateExhibition}
							/>
						</Grid>
					</Grid>
				</React.Fragment>
			) : (
				<ContentLoader
					loading={loading}
					notFoundWhenNotLoading
				/>
			)}
			<ExhibitionUpdateStatus/>
		</React.Fragment>
	)
}
export default ExhibitionSettings;
