import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import useUserData from "../../../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ErrorMessage, useFormikContext} from "formik";
import {VISITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {showOption} from "./dropDown";
import {VisitorFormContext} from "../../form";

type Props = {

}

const SelectCategory = (props: Props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled, defaultCountry} = props
    const {language} = useUserData()
    const {t} = useTranslation(VISITOR_PROFILE)
    const {countries, categories} = useContext(VisitorFormContext)
    const {setFieldValue} = useFormikContext()
    return (
        <Box sx={{width: '100%'}}>
            <Autocomplete
                options={categories}
                value={value}
                isOptionEqualToValue={(opt, val) => {return opt.id === val.id || !val}}
                getOptionLabel={showOption(language)}
                selectOnFocus
                handleHomeEndKeys
                onChange={(event, newValue) => {
                    setFieldValue("fieldValues." + field.id, newValue)
                }}
                autoHighlight={true}
                size="small"
                fullWidth
                renderInput={ (params) => (
                    <TextField
                        {...params}
                        required={field.required}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: "background.paper"
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'chrome-off',
                        }}
                        label={t("Characterization")}
                        error={touched["fieldValues"] &&
                            touched["fieldValues"][field.id] &&
                            Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])}
                        helperText={<ErrorMessage name={"fieldValues." + field.id}/>}
                    />
                )}
            />
        </Box>
    )
}

export default SelectCategory