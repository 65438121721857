export const parseRule = (datum) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        description: datum.get('description'),
        exhibitionId: datum.get('exhibitionId'),
        characterizationId: datum.get('characterizationId'),
    }
}

export const parseRuleDetails = (datum) => {
    if (!datum) return;
    return {
        ruleId: datum.get('ruleId'),
        id: datum.get('id'),
        field: datum.get('field'),
        operator: datum.get('operator'),
        value: datum.get('value'),
        editMode: datum.get('editMode'),
    }
}

export const parseStatus = (datum) => {
    if (!datum) return;
    return {
        message: datum.get('message'),
        type: datum.get('type')
    }
}