import {withLanguage} from "../../helpers/parse";
import type {Interests} from "./type";

export const parseInterests: Interests = (datum, language) => {
    if(!datum) return
    return {
        id: datum.get('id'),
        description: withLanguage(language)(datum.get('description')),
        code: datum.get('code'),
        weight: datum.get('weight'),
        hideInPreregistration: datum.get('hideInPreregistration')
    }
}