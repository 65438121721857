//@flow
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import {Cancel, Check} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

type Props = {
	open: boolean,
	handleDialog: Function
}

const ConfirmDialog = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {
		open,
		handleDialog,
		title,
		onYes = () => {
		},
		onNo = () => {
		},
		yesBtnText,
		noBtnText,
		hasBtnIcons = true,
		children,
		maxWidth,
		withLoadingButton = false,
		loading
	} = props;
	const handleClose = (evt, details) => {
		if (details === 'backdropClick') return
		handleDialog(false)
	}
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={maxWidth || 'xs'}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{children}</DialogContent>
			<DialogActions>
				<Button
					color="error"
					size={"small"}
					variant={"contained"}
					onClick={onNo}
					startIcon={hasBtnIcons ? <Cancel/> : null}
					sx={{
						marginRight: 'auto'
					}}
				>
					{noBtnText ? noBtnText : normalizeGreek(t('Cancel'))}
				</Button>
				{withLoadingButton ? (
					<LoadingButton
						size={"small"}
						variant={"contained"}
						color={'success'}
						onClick={onYes}
						endIcon={hasBtnIcons ? <Check/> : null}
						loading={loading}
						loadingPosition="end"
					>
						{yesBtnText ? yesBtnText : t('OK')}
					</LoadingButton>
				) : (
					<Button
						size={"small"}
						variant={"contained"}
						color={'success'}
						onClick={onYes}
						startIcon={hasBtnIcons ? <Check/> : null}
					>
						{yesBtnText ? yesBtnText : t('OK')}
					</Button>
				)}
			
			
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDialog;