// @flow
import React, {useMemo, useState} from 'react';
import * as yup from "yup";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {eventStatus, formatDate} from "../../../../../helpers/date";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FormControl, FormHelperText, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {showOption} from "../../../../../helpers/helpers";
import {BarChartOutlined} from "@mui/icons-material";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ConfirmDialog from "../../../../../components/app/dialogs/confirmDialog/confirmDialog";
import {enqueueSnackbar} from "notistack";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import ExhibitionUpdateStatus from "../../../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import dayjs from "dayjs";

type Props = {};

const SelectExhibitionToCopy = (props: Props) => {
	const {
		toExhibition,
		exhibitions,
		t,
		copyExhibitionSettings,
		loading,
		language,
		exhibition,
		getExhibitionDetails
	} = props
	const {dateFrom, dateTo, preregistrationEnabled} = exhibition
	const [openConfirm, setOpenConfirm] = useState(false)
	
	const copySettings = async (values) => {
		const fromExhibitionId = values.fromExhibition?.id
		await copyExhibitionSettings(fromExhibitionId, toExhibition)
		
	}
	const onSubmit = (values) => {
		copySettings(values)
		.then(() => {
			setOpenConfirm(false)
			getExhibitionDetails(toExhibition)
		})
	}
	
	const validationSchema = yup.object({
		'fromExhibition': yup.object().shape({
			id: yup.string().required()
		}).nonNullable(t('required')).required(t('required'))
	})
	
	const formik = useFormik({
		initialValues: {
			fromExhibition: ''
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	const mappedExhibitions = useMemo(() => (
		exhibitions.map(exhibition => ({
			id: exhibition.id,
			coorganizationId: exhibition.coorganizationId,
			description: `${exhibition.title} ${formatDate(exhibition.dateFrom, formatDate.formatTypes.DATE_ONLY)} - ${formatDate(exhibition.dateTo, formatDate.formatTypes.DATE_ONLY)}`,
			dateFrom: exhibition.dateFrom
		}))?.filter(f => f.dateFrom.isAfter(dayjs('2024-06-01'), 'day') && f.id !== toExhibition)
	), [exhibitions, toExhibition])
	
	const confirmSubmit = (evt) => {
		formik.validateForm()
		.then((response) => {
				if (Object.keys(response).length === 0) {
					setOpenConfirm(true)
				} else {
					enqueueSnackbar(t('Please select an exhibition!'), {
						variant: 'warning',
					})
				}
			}
		)
	}

	const disableCopy = useMemo(() => {
		const status = eventStatus(dateFrom, dateTo)
		const dayToDisableCopy = dayjs(dateFrom).subtract(20, 'd')
		return status !== eventStatus.status.UPCOMING || dayjs().isAfter(dayToDisableCopy, 'day') || preregistrationEnabled
	}, [dateFrom, dateTo, preregistrationEnabled])
	
	return (
		<FormikProvider value={formik}>
			<Box component="form" onSubmit={formik.handleSubmit} noValidate>
				<Stack
					direction={'column'}
					justifyContent={'space-around'}
					p={1}
					width={'100%'}
				>
					<Typography
						mt={1}
						mb={1}
						variant="body"
						color={'textSecondary'}
					>
						{t('Select exhibition')}
					</Typography>
					<FormControl
						sx={{mb: 1}}
						variant="standard"
						fullWidth
						error={
							formik.touched["fromExhibition"] &&
							Boolean(formik.errors["fromExhibition"])
						}
					>
						<Autocomplete
							disabled={disableCopy}
							options={mappedExhibitions}
							value={formik.values.fromExhibition}
							isOptionEqualToValue={(opt, val) => {
								return opt.id === val.id || !val || '-'
							}}
							getOptionLabel={showOption(language)}
							selectOnFocus
							handleHomeEndKeys
							onChange={(event, newValue, reason) => {
								formik.setFieldValue('fromExhibition', newValue)
							}}
							autoHighlight={true}
							size="small"
							fullWidth
							name={'fromExhibitionId'}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									variant={'standard'}
									InputLabelProps={{shrink: true}}
									sx={{
										'& .MuiOutlinedInput-root': {
											bgcolor: "background.paper"
										}
									}}
									inputProps={{
										...params.inputProps,
										autoComplete: 'off',
									}}
									label={t('Exhibition')}
								/>
							)}
						/>
						<FormHelperText>
							<ErrorMessage name={"fromExhibition"}/>
						</FormHelperText>
					</FormControl>
					<Button
						size={"small"}
						startIcon={<BarChartOutlined/>}
						variant="contained"
						color="success"
						onClick={confirmSubmit}
						disabled={disableCopy}
					>
						{normalizeGreek(t('Copy Settings'))}
					</Button>
				</Stack>
			</Box>
			<ConfirmDialog
				onYes={formik.submitForm}
				onNo={() => setOpenConfirm(false)}
				handleDialog={(newValue) => setOpenConfirm(newValue)}
				title={t('Confirm Settings Copy')}
				open={Boolean(openConfirm)}
				yesBtnText={t('YES')}
				noBtnText={t('NO')}
				maxWidth={'md'}
				withLoadingButton
				loading={loading}
			>
				{t('This action cannot be revoked. Press YES if you want to proceed.')}
			</ConfirmDialog>
			<ExhibitionUpdateStatus/>
			<ContentLoader
				loading={loading}
				withBackDrop
				useCircularLoader
			/>
		</FormikProvider>
	);
};

export default SelectExhibitionToCopy