//@flow
import * as React from 'react';
import {useMemo, useState} from 'react';
import * as yup from "yup";
import {ErrorMessage, Field, FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import "./badgePreview.css"
import Button from "@mui/material/Button";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import ColorSelect from "./printable/colorSelect/colorSelect";
import SaveIcon from '@mui/icons-material/Save';
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";
import ContentLoader from "../../general/contentLoader/contentLoader";
import {useBadgeConfiguration} from "../../../hooks/badges/useBadgeConfiguration";
import type {BadgeConfiguration} from "../../../types/badge/types";
import ExhibitionUpdateStatus from "../alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import useBadgeAction from "../../../hooks/badges/useBadgeAction";
import WysiwygEditor from "../../form/editor/editor";
import PrintBadgeButton from "../printBadgeButton/printBadgeButton";
import Stack from "@mui/material/Stack";
import {PaperSize} from "../../../helpers/constants";
import {FormHelperText, InputLabel, ListItem, Paper, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

type Props = {
	exhibitionId: string,
	initialValues: BadgeConfiguration,
}

const BadgeFormInner = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {initialValues, exhibitionId, badgeTemplateTypes} = props
	const {updateBadgeConfiguration} = useBadgeAction()
	const onSubmit = (values) => {
		updateBadgeConfiguration(exhibitionId, values)
	}
	const [previewType, setPreviewType] = useState('partner')
	const validationSchema = yup.object({})
	const formik = useFormik({
		initialValues: {
			info: initialValues.content,
			infoPartner: initialValues.contentPartner,
			colorPartner1: initialValues.colorPartner1,
			colorPartner2: initialValues.colorPartner2,
			colorFont: initialValues.colorFont,
			colorVisitor1: initialValues.colorVisitor1,
			colorVisitor2: initialValues.colorVisitor2,
			colorProvider1: initialValues.colorProvider1,
			colorProvider2: initialValues.colorProvider2,
			colorStaff1: initialValues.colorStaff1,
			colorStaff2: initialValues.colorStaff2,
			colorOrganizer1: initialValues.colorOrganizer1,
			colorOrganizer2: initialValues.colorOrganizer2,
			visitorBadgeTemplateTypeId: initialValues.visitorBadgeTemplateTypeId,
			visitorRegBadgeTemplateTypeId: initialValues.visitorRegBadgeTemplateTypeId,
			partnerBadgeTemplateTypeId: initialValues.partnerBadgeTemplateTypeId,
			providerBadgeTemplateTypeId: initialValues.providerBadgeTemplateTypeId,
			staffBadgeTemplateTypeId: initialValues.staffBadgeTemplateTypeId,
			organizerBadgeTemplateTypeId: initialValues.organizerBadgeTemplateTypeId
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	const printSize = useMemo(() => (
		previewType === 'partner' ? formik.values.partnerBadgeTemplateTypeId :
			previewType === 'visitor' ? formik.values.visitorBadgeTemplateTypeId :
				previewType === 'visitorRegistration' ? formik.values.visitorRegBadgeTemplateTypeId :
					previewType === 'provider' ? formik.values.providerBadgeTemplateTypeId :
						previewType === 'staff' ? formik.values.staffBadgeTemplateTypeId :
							previewType === 'organizer' ? formik.values.organizerBadgeTemplateTypeId : PaperSize.A4
	), [formik.values.organizerBadgeTemplateTypeId, formik.values.partnerBadgeTemplateTypeId, formik.values.providerBadgeTemplateTypeId, formik.values.staffBadgeTemplateTypeId, formik.values.visitorBadgeTemplateTypeId, formik.values.visitorRegBadgeTemplateTypeId, previewType])
	return (
		<FormikProvider value={formik}>
			<Box component="form" onSubmit={formik.handleSubmit} sx={{mb: 2}}>
				<Paper sx={{mb: 1}} variant={"outlined"}>
					<List>
						<ListItem component={"div"}>
							<ListItemText
								primary={
									<Typography component={"div"} variant={"body1"}>
										<Trans
											i18nKey={'badge-settings'}
											ns={EXHIBITION}
										>
											On this page you can change <strong>general print settings</strong> for
											badges or <strong>design
											badge content</strong>.<br/> Badge text from partners, is also used
											for <strong>service
											providers</strong>, <strong>exhibition
											staff</strong> and <strong>organizers</strong>. <br/><br/>
											Press <strong>SAVE</strong> button to submit any changes in badge
											settings.<br/>
										</Trans>
									</Typography>
								}
							/>
						</ListItem>
					</List>
				</Paper>
				<Box sx={{px: 1}}>
					<Toolbar disableGutters variant={"dense"}>
						<Box sx={{flexGrow: 1}}/>
						<Stack spacing={2} direction={"row"}>
							<PrintBadgeButton
								isPreview
								printSize={printSize}
								userId={exhibitionId}
								type={previewType === 'visitorRegistration' ? 'visitor' : previewType}
								component={({onClick}) => (
									<Button
										onClick={onClick}
										size={"small"}
									>
										{normalizeGreek(t('Preview'))}
									</Button>
								)}
							/>
							<Button
								size={"small"}
								startIcon={<SaveIcon/>}
								type="submit"
								variant="contained"
							>
								{normalizeGreek(t('Save'))}
							</Button>
						</Stack>
					</Toolbar>
					<Paper sx={{mb: 1, p: 1.2}} variant={"outlined"}>
						<Typography component={"div"} variant={"h6"}>
							{t('Badge Print Settings')}
						</Typography>
						<Divider sx={{mb: 1.2}} light/>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={12} sm={12} md={12}>
								<Box sx={{display: 'flex', width: '100%', alignItems: 'center'}}>
									<Typography variant={"body1"}
												sx={{paddingRight: 1}}>{t('Preview Badge Type')}</Typography>
									<Select
										autoComplete={"chrome-off"}
										size={"small"}
										name={"previewBadgeType"}
										value={previewType}
										onChange={(evt) => setPreviewType(evt.target.value)}
									>
										<MenuItem value={'visitor'}>{t('Visitor')}</MenuItem>
										<MenuItem value={'visitorRegistration'}>{t('Visitor Registration')}</MenuItem>
										<MenuItem value={'partner'}>{t('Partner')}</MenuItem>
										<MenuItem value={'provider'}>{t('Service Provider')}</MenuItem>
										<MenuItem value={'staff'}>{t('Exhibition Staff')}</MenuItem>
										<MenuItem value={'organizer'}>{t('Organizer')}</MenuItem>
									</Select>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Font Color')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorFont}
										onColorChange={(color) => formik.setFieldValue('colorFont', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={8}>
								<FormControl
									variant="outlined"
									error={
										formik.touched["visitorRegBadgeTemplateTypeId"] &&
										Boolean(formik.errors["visitorRegBadgeTemplateTypeId"])
									}
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '240px',
										maxWidth: '60%',
										width: '100%'
									}}
								>
									<InputLabel
										id="demo-simple-select">{t('Visitor Registration Print Size')}</InputLabel>
									<Select
										labelId="demo-simple-select"
										autoComplete={"chrome-off"}
										size={"small"}
										name={"visitorRegBadgeTemplateTypeId"}
										value={formik.values.visitorRegBadgeTemplateTypeId}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										label={t('Visitor Registration Print Size')}
									>
										{badgeTemplateTypes.map(item => (
											<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
										))}
									</Select>
									<FormHelperText>
										<ErrorMessage name={"visitorBadgeTemplateTypeId"}/>
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Partner Color 1')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorPartner1}
										onColorChange={(color) => formik.setFieldValue('colorPartner1', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Partner Color 2')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorPartner2}
										onColorChange={(color) => formik.setFieldValue('colorPartner2', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="outlined"
									error={
										formik.touched["partnerBadgeTemplateTypeId"] &&
										Boolean(formik.errors["partnerBadgeTemplateTypeId"])
									}
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '240px'
									}}
								>
									<InputLabel
										id="demo-simple-select-helper-label">{t('Partner Print Size')}</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										autoComplete={"chrome-off"}
										size={"small"}
										name={"partnerBadgeTemplateTypeId"}
										value={formik.values.partnerBadgeTemplateTypeId}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										label={t('Partner Print Size')}
									>
										{badgeTemplateTypes.map(item => (
											<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
										))}
									</Select>
									<FormHelperText>
										<ErrorMessage name={"partnerBadgeTemplateTypeId"}/>
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Visitor Color 1')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorVisitor1}
										onColorChange={(color) => formik.setFieldValue('colorVisitor1', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Visitor Color 2')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorVisitor2}
										onColorChange={(color) => formik.setFieldValue('colorVisitor2', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="outlined"
									error={
										formik.touched["visitorBadgeTemplateTypeId"] &&
										Boolean(formik.errors["visitorBadgeTemplateTypeId"])
									}
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '240px'
									}}
								>
									<InputLabel
										id="demo-simple-select-helper-label">{t('Visitor Print Size')}</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										autoComplete={"chrome-off"}
										size={"small"}
										name={"visitorBadgeTemplateTypeId"}
										value={formik.values.visitorBadgeTemplateTypeId}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										label={t('Visitor Print Size')}
									>
										{badgeTemplateTypes.map(item => (
											<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
										))}
									</Select>
									<FormHelperText>
										<ErrorMessage name={"visitorBadgeTemplateTypeId"}/>
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Service Provider Color 1')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorProvider1}
										onColorChange={(color) => formik.setFieldValue('colorProvider1', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Service Provider Color 2')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorProvider2}
										onColorChange={(color) => formik.setFieldValue('colorProvider2', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="outlined"
									error={
										formik.touched["providerBadgeTemplateTypeId"] &&
										Boolean(formik.errors["providerBadgeTemplateTypeId"])
									}
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '240px'
									}}
								>
									<InputLabel
										id="demo-simple-select-helper-label">{t('Service Provider Print Size')}</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										autoComplete={"chrome-off"}
										size={"small"}
										name={"providerBadgeTemplateTypeId"}
										value={formik.values.providerBadgeTemplateTypeId}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										label={t('Service Provider Print Size')}
									>
										{badgeTemplateTypes.map(item => (
											<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
										))}
									</Select>
									<FormHelperText>
										<ErrorMessage name={"providerBadgeTemplateTypeId"}/>
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Exhibition Staff Color 1')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorStaff1}
										onColorChange={(color) => formik.setFieldValue('colorStaff1', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Exhibition Staff Color 2')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorStaff2}
										onColorChange={(color) => formik.setFieldValue('colorStaff2', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="outlined"
									error={
										formik.touched["staffBadgeTemplateTypeId"] &&
										Boolean(formik.errors["staffBadgeTemplateTypeId"])
									}
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '240px'
									}}
								>
									<InputLabel
										id="demo-simple-select-helper-label">{t('Exhibition Staff Print Size')}</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										autoComplete={"chrome-off"}
										size={"small"}
										name={"staffBadgeTemplateTypeId"}
										value={formik.values.staffBadgeTemplateTypeId}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										label={t('Exhibition Staff Print Size')}
									>
										{badgeTemplateTypes.map(item => (
											<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
										))}
									</Select>
									<FormHelperText>
										<ErrorMessage name={"staffBadgeTemplateTypeId"}/>
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Organizer Color 1')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorOrganizer1}
										onColorChange={(color) => formik.setFieldValue('colorOrganizer1', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="standard"
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '280px'
									}}
								>
									<FormLabel sx={{mb: .5}}>
										{t('Organizer Color 2')}
									</FormLabel>
									<ColorSelect
										color={formik.values.colorOrganizer2}
										onColorChange={(color) => formik.setFieldValue('colorOrganizer2', color)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl
									variant="outlined"
									error={
										formik.touched["organizerBadgeTemplateTypeId"] &&
										Boolean(formik.errors["organizerBadgeTemplateTypeId"])
									}
									sx={{
										mr: 1,
										textAlign: "center",
										paddingRight: 1,
										paddingBottom: 1,
										minWidth: '240px'
									}}
								>
									<InputLabel
										id="demo-simple-select-helper-label">{t('Organizer Print Size')}</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										autoComplete={"chrome-off"}
										size={"small"}
										name={"organizerBadgeTemplateTypeId"}
										value={formik.values.organizerBadgeTemplateTypeId}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										label={t('Organizer Print Size')}
									>
										{badgeTemplateTypes.map(item => (
											<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
										))}
									</Select>
									<FormHelperText>
										<ErrorMessage name={"organizerBadgeTemplateTypeId"}/>
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Paper>
					<Paper sx={{mb: 1, p: 1.2}} variant={"outlined"}>
						<Typography component={"div"} variant={"h6"}>
							{t('Visitor Badge Content')}
						</Typography>
						<Divider sx={{mb: 1.2}} light/>
						<Grid container spacing={2} sx={{paddingTop: 2}}>
							<Grid item xs={12} md={6}>
								<FormControl variant="standard" sx={{mb: 1}} fullWidth>
									<FormLabel>
										{t('Greek Description')}
									</FormLabel>
									<Field
										name="info.el"
										component={WysiwygEditor}
										editorType={2}
										minHeight={'600px'}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl variant="standard" sx={{mb: 1}} fullWidth>
									<FormLabel>
										{t('English Description')}
									</FormLabel>
									<Field
										name="info.en"
										component={WysiwygEditor}
										editorType={2}
										minHeight={'600px'}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Paper>
					<Paper sx={{mb: 1, p: 1.2}} variant={"outlined"}>
						<Typography component={"div"} variant={"h6"}>
							{t('Partner Badge Content')}
						</Typography>
						<Divider sx={{mb: 1.2}} light/>
						<Grid container spacing={2} sx={{paddingTop: 2}}>
							<Grid item xs={12} md={6}>
								<FormControl variant="standard" sx={{mb: 1}} fullWidth>
									<FormLabel>
										{t('Greek Description')}
									</FormLabel>
									<Field
										name="infoPartner.el"
										component={WysiwygEditor}
										editorType={2}
										minHeight={'600px'}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl variant="standard" sx={{mb: 1}} fullWidth>
									<FormLabel>
										{t('English Description')}
									</FormLabel>
									<Field
										name="infoPartner.en"
										component={WysiwygEditor}
										editorType={2}
										minHeight={'600px'}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Paper>
				</Box>
			</Box>
			<ExhibitionUpdateStatus/>
		</FormikProvider>
	)
}

const BadgeForm = (props) => {
	const {badgeConfiguration, id, loading} = useBadgeConfiguration()
	return (
		<React.Fragment>
			{badgeConfiguration ? (
				<BadgeFormInner
					exhibitionId={id}
					initialValues={badgeConfiguration}
					badgeTemplateTypes={props.badgeTemplateTypes}
				/>
			) : (
				<ContentLoader
					loading={loading}
				/>
			)}
		</React.Fragment>
	)
}

export default BadgeForm;
