import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import parametersMenuEN from './en/parametersMenu';
import parametersMenuGR from './el/parametersMenu';
import parametersPageTitlesEN from './en/parametersPageTitles';
import parametersPageTitlesGR from './el/parametersPageTitles';
import usersPageTitlesEN from './en/usersPageTitles';
import usersPageTitlesGR from './el/usersPageTitles';
import itemsPageTitlesEN from './en/itemsPageTitles.json';
import itemsPageTitlesGR from './el/itemsPageTitles.json';
import actionButtonsEN from './en/actionButtons';
import actionButtonsGR from './el/actionButtons';
import userPropertiesEN from './en/userProperties';
import userPropertiesGR from './el/userProperties';
import itemPropertiesEN from './en/itemProperties';
import itemPropertiesGR from './el/itemProperties';
import pricelistPropertiesEN from './en/pricelistProperties';
import pricelistPropertiesGR from './el/pricelistProperties';
import alertsEN from './en/alerts';
import alertsGR from './el/alerts';
import gridHeadersEN from './en/gridHeaders';
import gridHeadersGR from './el/gridHeaders';
import yupEN from './en/yup';
import yupGR from './el/yup';
import uiComponentsEN from './en/uiComponents';
import uiComponentsGR from './el/uiComponents';
import b2bPageTitlesEN from './en/b2bPageTitles';
import b2bPageTitlesGR from './el/b2bPageTitles';
import orderSheetPropertiesEN from './en/orderSheetProperties';
import orderSheetPropertiesGR from './el/orderSheetProperties';
import {ENABLED_MODULES} from '../App'

const resources = {
	en: {
		parametersMenu: parametersMenuEN,
		parametersPageTitles: parametersPageTitlesEN,
		usersPageTitles: usersPageTitlesEN,
		itemsPageTitles: itemsPageTitlesEN,
		actionButtons: actionButtonsEN,
		userProperties: userPropertiesEN,
		itemProperties: itemPropertiesEN,
		pricelistProperties: pricelistPropertiesEN,
		alerts: alertsEN,
		gridHeaders: gridHeadersEN,
		yup: yupEN,
		uiComponents: uiComponentsEN,
		b2bPageTitles: b2bPageTitlesEN,
		orderSheetProperties: orderSheetPropertiesEN
	},
	el: {
		parametersMenu: parametersMenuGR,
		parametersPageTitles: parametersPageTitlesGR,
		usersPageTitles: usersPageTitlesGR,
		itemsPageTitles: itemsPageTitlesGR,
		actionButtons: actionButtonsGR,
		userProperties: userPropertiesGR,
		itemProperties: itemPropertiesGR,
		pricelistProperties: pricelistPropertiesGR,
		alerts: alertsGR,
		gridHeaders: gridHeadersGR,
		yup: yupGR,
		uiComponents: uiComponentsGR,
		b2bPageTitles: b2bPageTitlesGR,
		orderSheetProperties: orderSheetPropertiesGR
	}
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		react: {
			useSuspense: false,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ul', 'li', 'a', 'em']
		},
		lng: 'el',
		fallbackLng: "en",
		keySeparator: true,
		interpolation: {
			escapeValue: false
		},
	});

const moduleResources = ENABLED_MODULES.getTranslations();

Object.keys(moduleResources).forEach(module => {
	const namespaceObject = moduleResources[module];
	Object.keys(namespaceObject).forEach(namespace => {
		i18n.addResourceBundle('en', namespace, namespaceObject[namespace].en)
		i18n.addResourceBundle('el', namespace, namespaceObject[namespace].el)
	});
})


export default i18n;