import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {parseMultiple} from "../../helpers/parse";
import {parseExhibitionFile} from "../../types/exhibitionFiles/parse";
import type {ExhibitionFile} from "../../types/exhibitionFiles/types";

const selectLoading = createSelector(
    (state) => state.exhibitionFiles,
    (item) => item.get('loading')
)

const selectFiles = createSelector(
    [
        (state, exhibitionId, exhibitorId) => {
            if (exhibitorId) {
                return state.exhibitionFiles.getIn(['filesByExhibitorId', exhibitorId])
            }
            return state.exhibitionFiles.getIn(['filesByExhibitionId', exhibitionId])
        },
        (state) => state.currentUser.get('language'),
        (state, exhibitionId, exhibitorId, language) => language
    ],
    (data, currentUserLanguage, overrideLanguage) => parseMultiple(data, (i) => parseExhibitionFile(i, overrideLanguage ?? currentUserLanguage))
)

export const useExhibitionFileData = ({exhibitionId, exhibitorId, language} = {}) => {
    const loading: boolean = useSelector(selectLoading)
    const files: Array<ExhibitionFile> = useSelector((state) => selectFiles(state, exhibitionId, exhibitorId, language))
    return {
        files,
        loading,
    }
}