// @flow
import * as React from 'react';
import {useState} from 'react';
import type {Exhibition} from "../../../types/exhibition/types";
import {Link} from "react-router-dom";
import {ROUTE_PAGE_EXHIBITION} from "../../../routers/routes";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import Box from "@mui/material/Box";
import {ListItem, ListItemAvatar, ListItemButton} from "@mui/material";
import {useMultilingualText} from "../../../hooks/useMultilingualText";
import ListItemText from "@mui/material/ListItemText";
import {PageTitleContent} from "../exhibitionPageTitle/exhibitionPageTitle";
import {IMAGE_LOGO} from "../../../assets/images";
import Divider from "@mui/material/Divider";
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from "@mui/material/IconButton";

type Props = {
    exhibition: Exhibition
}

const ExhibitionPreview = (props: Props) => {
    const {id, image} = props.exhibition
    const [hideImage, setHideImage] = useState(false)
    const defaultImage = useMultilingualText(IMAGE_LOGO)
    return (
        <React.Fragment>
            <ListItem component={"div"} disableGutters>
                <ListItemButton
                    component={Link}
                    to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {
                        id: id
                    })}
                >
                    <ListItemAvatar>
                        <IconButton disableRipple>
                            <CircleIcon color={"inherit"}/>
                        </IconButton>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Box>
                                <PageTitleContent
                                    exhibition={props.exhibition}
                                />
                            </Box>
                        }
                    />
                    <ListItemAvatar>
                        <Box
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{width: 128, mr: .5, display: "flex"}}>
                            <img
                                onError={() => setHideImage(true)}
                                src={(!hideImage && image) ? image : defaultImage}
                                alt="preview"
                                style={{maxWidth: "100%", height: "auto"}}
                            />
                        </Box>
                    </ListItemAvatar>
                </ListItemButton>
            </ListItem>
            <Divider variant="inset" component="div"/>
        </React.Fragment>
    )
}

export default ExhibitionPreview;