// @flow
import * as React from 'react';
import Box from "@mui/material/Box";
import {ListItem, ListItemAvatar, ListItemButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import Typography from "@mui/material/Typography";
import {FormatListBulleted} from "@mui/icons-material";

type Props = {}

const ExhibitionGroupedHeadersPreview = (props: Props) => {
	const {header, getExhibitionsGroupedDetails, description, selected, setSelected} = props
	const onClick = () => {
		getExhibitionsGroupedDetails(header)
		setSelected(header)
	}
	return (
		<React.Fragment>
			<ListItem
				component={"div"}
				disableGutters
			>
				<ListItemButton
					onClick={onClick}
					selected={selected === header}
				>
					<ListItemAvatar>
						<IconButton disableRipple>
							<FormatListBulleted color={"inherit"}/>
						</IconButton>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Box>
								<Typography variant="h6" component="div">
									<Box>
										{normalizeGreek(description.toUpperCase())}
									</Box>
								</Typography>
							</Box>
						}
					/>
				</ListItemButton>
			</ListItem>
			<Divider variant="inset" component="div"/>
		</React.Fragment>
	)
}

export default ExhibitionGroupedHeadersPreview;