import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple} from "../../helpers/map";
import {mapExhibitionFile, mapExhibitionImage} from "../../types/exhibitionFiles/map";
import {parseMultiple} from "../../helpers/parse";
import {parseExhibitionImage} from "../../types/exhibitionFiles/parse";

const INITIAL_STATE = {
	loading: false,
	updating: false,
	filesByExhibitionId: {},
	imagesByExhibitionId: {},
	filesByExhibitorId: {},
	message: null,
}

function setFileMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getExhibitionFiles(state) {
	return state
	.set('loading', true)
}

const stateToUpdate = ({ExhibitionId, ExhibitorId}) => {
	return ExhibitorId ? ['filesByExhibitorId', ExhibitorId] : ['filesByExhibitionId', ExhibitionId]
}

function getExhibitionFilesSucceeded(state, action) {
	const {parameters, payload} = action
	const mappedData = mapMultiple(payload, mapExhibitionFile)
	return state
	.set('loading', false)
	.setIn(stateToUpdate(parameters), fromJS(mappedData))
}

function getExhibitionFilesFailed(state) {
	return state
	.set('loading', false)
}

function createExhibitionFile(state, action) {
	const {payload} = action
	const {
		temporaryId,
		AttachmentLang,
		ExhibitorId,
		ExhibitionId,
		TypeId,
		MessageTemplateTypeId,
		...createData
	} = Object.fromEntries(payload)
	const stateArray = stateToUpdate({ExhibitionId, ExhibitorId})
	const unmappedData = {
		id: temporaryId,
		isTemporary: true,
		Description: '',
		OriginFileName: createData.file.name,
		TypeId: TypeId,
		MessageTemplateTypeId: MessageTemplateTypeId,
		AttachmentLang: AttachmentLang,
	}
	const mappedData = mapExhibitionFile(unmappedData)
	return state
	.setIn([...stateArray, mappedData.id], fromJS(mappedData))
	.set('loading', true)
}

function createExhibitionFileSucceeded(state, action) {
	const {payload: {id}, parameters} = action
	const {
		temporaryId,
		AttachmentLang,
		ExhibitorId,
		ExhibitionId,
		TypeId,
		MessageTemplateTypeId,
		...createData
	} = Object.fromEntries(parameters)
	const stateArray = stateToUpdate({ExhibitionId, ExhibitorId})
	const unmappedData = {
		id: String(id),
		Description: '',
		OriginFileName: createData.file.name,
		TypeId: TypeId,
		AttachmentLang: AttachmentLang,
		MessageTemplateTypeId: MessageTemplateTypeId
	}
	const mappedData = mapExhibitionFile(unmappedData)
	return state
	.set('loading', false)
	.removeIn([...stateArray, temporaryId])
	.setIn([...stateArray, mappedData.id], fromJS(mappedData))
}

function createExhibitionFileFailed(state, action) {
	const {parameters} = action
	const {
		temporaryId,
		ExhibitorId,
		ExhibitionId,
	} = Object.fromEntries(parameters)
	const stateArray = stateToUpdate({ExhibitionId, ExhibitorId})
	return state
	.set('loading', false)
	.removeIn([...stateArray, temporaryId])
}

function deleteExhibitionFile(state) {
	return state
	.set('loading', true)
}

function deleteExhibitionFileSucceeded(state, action) {
	const {parameters} = action
	return state
	.set('loading', false)
	.removeIn([...stateToUpdate(parameters), parameters.id])
}

function deleteExhibitionFileFailed(state, action) {
	return state
	.set('loading', false)
}

function updateExhibitionFile(state) {
	return state
	.set('loading', true)
}

function updateExhibitionFileSucceeded(state, action) {
	const {parameters: {id, ExhibitorId, ExhibitionId, Description, Ordr}} = action
	const stateArray = [...stateToUpdate({ExhibitionId, ExhibitorId}), id, 'description']
	const stateArrayOrder = [...stateToUpdate({ExhibitionId, ExhibitorId}), id, 'fileDisplayOrder']
	return state
	.set('loading', false)
	.setIn(stateArray, Description)
	.setIn(stateArrayOrder, Ordr)
}

function updateExhibitionFileFailed(state) {
	return state
	.set('loading', false)
}

function getExhibitionImages(state) {
	return state
	.set('loading', true)
}

function getExhibitionImagesSucceeded(state, action) {
	const {parameters, payload} = action
	const {id} = parameters
	const mappedData = mapMultiple(payload, mapExhibitionImage)
	return state
	.set('loading', false)
	.setIn(['imagesByExhibitionId', id], fromJS(mappedData))
}

function getExhibitionImagesFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function createExhibitionImage(state) {
	return state
	.set('loading', true)
}

function createExhibitionImageSucceeded(state, action) {
	const {payload: {id, url}, parameters} = action
	const {ExhibitionId} = Object.fromEntries(parameters)
	const mappedData = mapExhibitionImage({id: String(id), url: url, isLatest: true})
	const images = parseMultiple(state.getIn(['imagesByExhibitionId', ExhibitionId]), i => parseExhibitionImage(i))
	const updateImages = images.map(image => ({...image, isLatest: false}))
	const newData = [...updateImages, mappedData]
	return state
	.set('loading', false)
	.setIn(['imagesByExhibitionId', ExhibitionId], fromJS(newData))
	.set('message', fromJS({
		text: 'Image uploaded successfully!', variant: 'success'
	}))
}

function createExhibitionImageFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function deleteExhibitionImage(state) {
	return state
	.set('loading', true)
}

function deleteExhibitionImageSucceeded(state, action) {
	const {parameters: {id, ExhibitionId}} = action
	const images = parseMultiple(state.getIn(['imagesByExhibitionId', ExhibitionId]), i => parseExhibitionImage(i))
	const updateImages = images.filter(f => f.id !== id)
	return state
	.set('loading', false)
	.setIn(['imagesByExhibitionId', ExhibitionId], fromJS(updateImages))
	.set('message', fromJS({
		text: 'Image deleted successfully!', variant: 'success'
	}))
}

function deleteExhibitionImageFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const exhibitionFiles = {
	initialState: INITIAL_STATE, handlers: {
		[ActionTypes.GET_EXHIBITION_FILES]: getExhibitionFiles,
		[ActionTypes.GET_EXHIBITION_FILES_SUCCEEDED]: getExhibitionFilesSucceeded,
		[ActionTypes.GET_EXHIBITION_FILES_FAILED]: getExhibitionFilesFailed,
		
		[ActionTypes.CREATE_EXHIBITION_FILE]: createExhibitionFile,
		[ActionTypes.CREATE_EXHIBITION_FILE_SUCCEEDED]: createExhibitionFileSucceeded,
		[ActionTypes.CREATE_EXHIBITION_FILE_FAILED]: createExhibitionFileFailed,
		
		[ActionTypes.UPDATE_EXHIBITION_FILE]: updateExhibitionFile,
		[ActionTypes.UPDATE_EXHIBITION_FILE_SUCCEEDED]: updateExhibitionFileSucceeded,
		[ActionTypes.UPDATE_EXHIBITION_FILE_FAILED]: updateExhibitionFileFailed,
		
		[ActionTypes.DELETE_EXHIBITION_FILE]: deleteExhibitionFile,
		[ActionTypes.DELETE_EXHIBITION_FILE_SUCCEEDED]: deleteExhibitionFileSucceeded,
		[ActionTypes.DELETE_EXHIBITION_FILE_FAILED]: deleteExhibitionFileFailed,
		
		[ActionTypes.GET_EXHIBITION_IMAGES]: getExhibitionImages,
		[ActionTypes.GET_EXHIBITION_IMAGES_SUCCEEDED]: getExhibitionImagesSucceeded,
		[ActionTypes.GET_EXHIBITION_IMAGES_FAILED]: getExhibitionImagesFailed,
		
		[ActionTypes.DELETE_EXHIBITION_IMAGE]: deleteExhibitionImage,
		[ActionTypes.DELETE_EXHIBITION_IMAGE_SUCCEEDED]: deleteExhibitionImageSucceeded,
		[ActionTypes.DELETE_EXHIBITION_IMAGE_FAILED]: deleteExhibitionImageFailed,
		
		[ActionTypes.CREATE_EXHIBITION_IMAGE]: createExhibitionImage,
		[ActionTypes.CREATE_EXHIBITION_IMAGE_SUCCEEDED]: createExhibitionImageSucceeded,
		[ActionTypes.CREATE_EXHIBITION_IMAGE_FAILED]: createExhibitionImageFailed,
		
		[ActionTypes.SET_FILE_MESSAGE]: setFileMessage,
	}
}

export default exhibitionFiles