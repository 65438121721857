// @flow
import React from 'react';
import {Permissions} from "../../helpers/constants";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import Box from "@mui/material/Box";
import OrganizerView from "./partials/organizerView";
import ExhibitorView from "./partials/exhibitorView";


const Home = () => {
	return (
		<Box>
			<PermissionHOC requiredPermissions={[Permissions.BeOrganizer]}>
				<OrganizerView/>
			</PermissionHOC>
			<PermissionHOC requiredPermissions={[Permissions.BeExhibitor]}>
				<Box sx={{mb: 5}}/>
				<ExhibitorView/>
			</PermissionHOC>
		</Box>
	)
}

export default Home