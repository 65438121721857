// @flow
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Box, Paper} from "@mui/material";
import {DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridActionsCellItem} from '@mui/x-data-grid-premium';
import {useMuiGridServerSide} from "../../../../../hooks/useMuiGridServerSide";
import {mapMessage} from "../../../../../types/messageTemplates/map";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_PROFILE_EXHIBITOR, ROUTE_PAGE_PROFILE_VISITOR} from "../../../../../routers/routes";
import {useTranslation} from "react-i18next";
import {PAGE_SETTINGS} from "../../../../../locales/pages/namespaces";
import {OpenInNew, RestartAlt, Send} from "@mui/icons-material";
import {ResendEmailOrSmsPopover} from "./resendEmailOrSMSPopover";
import DisplayEmailAsHtmlPopOver from "./displayEmailAsHtmlPopOver";
import LoadingButton from "@mui/lab/LoadingButton";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import axios from "axios";
import {API, EmailStatus} from "../../../../../helpers/constants";
import useUserData from "../../../../../hooks/user/useUserData";
import ActionStatus from "../../../../../components/app/alerts/actionStatus/actionStatus";
import {muiGridLocales} from "../../../../../helpers/muiGridLocales";
import {
	CustomPagination
} from "../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import ToolbarWithState
	from "../../../../../components/grids/dataGridCustom/toolbars/toolbarWithState/toolbarWithState";
import GridMultiColumnSearch from "../../../../../components/app/gridMultiColumnSearch/gridMultiColumnSearch";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";


type Props = {
	exhibitionId: string,
	messageTemplateTypeId: string
};

const GridToolbar = () => (
	<ToolbarWithState
		code={'messages_page_all'}
		exportSlotProps={{
			csvOptions: {
				disableToolbarButton: true
			}
		}}
	/>
)

const MessagesGrid = ({exhibitionId, messageTemplateTypeId, isSMS, tabValue}: Props) => {
	const {token} = useUserData()
	const {language} = useUserData()
	const {t} = useTranslation(PAGE_SETTINGS)
	const [resendEmailOrSMS, setResendEmailOrSMS] = useState({
		anchorEl: null,
		open: false,
		messageId: ''
	})
	const [displayEmailAsHtml, setDisplayEmailAsHtml] = useState({
		anchorEl: null,
		open: false,
		body: ''
	})
	
	const [alert, setAlert] = useState(null)
	const handleAlert = (value) => setAlert(value)
	const [customSearch, setCustomSearch] = useState('')
	const handleResendEmailOrSMS = (value) => {
		setResendEmailOrSMS({...value})
	}
	const handleDisplayEmailAsHtml = (value) => {
		setDisplayEmailAsHtml({...value})
	}
	
	const url = `exhibition/messages/${exhibitionId}/${messageTemplateTypeId}`
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		mapFunction: mapMessage,
		initialSortModel: [{name: 'CreatedAt', direction: 'descending'}],
		otherDataParams: {
			customSearch: customSearch
		}
	})
	
	useEffect(() => {
		if (messageTemplateTypeId) refreshGrid()
		// eslint-disable-next-line
	}, [messageTemplateTypeId])
	
	const [sendMultiple, setSendMultiple] = useState([])
	const onSendMultiple = (selectionModel) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/en/exhibition/messages/copy/multiple`,
			data: {
				ids: selectionModel
			}
		}).then((response) => {
			refreshGrid()
			setAlert({
				type: 'success',
				message: t('Email Submitted')
			})
		}).catch((error) => {
			setAlert({
				type: 'failure',
				message: error?.message
			})
		})
	}
	
	const columns = useMemo(() => [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			maxWidth: 40,
			minWidth: 40,
			filterable: false,
			sortable: false,
			groupable: false,
			aggregable: false,
			resizable: false
		},
		{
			headerName: t('Actions'),
			field: 'actions',
			type: 'actions',
			minWidth: 100,
			maxWidth: 100,
			resizable: false,
			getActions: (params) => [
				<GridActionsCellItem
					onClick={(evt) => setResendEmailOrSMS({
						anchorEl: evt.currentTarget,
						open: true,
						messageId: params.id
					})}
					icon={<RestartAlt/>}
					label={t("Resend")}
					color={"info"}
				/>,
				<GridActionsCellItem
					onClick={(evt) => setDisplayEmailAsHtml({
						anchorEl: evt.currentTarget,
						open: true,
						body: params.row.Body
					})}
					icon={<OpenInNew/>}
					label={t("OpenInNew")}
					color={"info"}
				/>
			]
		},
		{field: 'Email', headerName: t('Email'), minWidth: 220, flex: 1},
		{field: 'MobilePhone', headerName: t('Mobile Phone'), minWidth: 160, flex: 1},
		{
			field: 'isSent',
			headerName: t('Sent Status'),
			minWidth: 160,
			type: 'singleSelect',
			valueOptions: [
				{value: EmailStatus.Sent, label: t('Sent')},
				{value: EmailStatus.NotSent, label: t('Not Sent')},
				{value: EmailStatus.Error, label: t('Error Sending')},
			],
			flex: 1
		},
		{
			field: 'CreatedAt',
			headerName: t('Created At'),
			minWidth: 200,
			flex: 1,
			type: 'dateTime',
		},
		{
			field: 'SentAt',
			headerName: t('Sent At'),
			minWidth: 200,
			flex: 1,
			type: 'dateTime'
		},
		{
			field: 'VisitorName',
			headerName: t('Visitor'),
			renderCell: ({row}) => {
				return (<Link
					to={getRouteUrl(ROUTE_PAGE_PROFILE_VISITOR, {
						id: exhibitionId,
						visitorId: row.VisitorId
					})}
				>
					{row.VisitorName}
				</Link>)
			},
			minWidth: 220,
			flex: 1
		},
		{
			field: 'ExhibitorName',
			headerName: t('Exhibitor'),
			renderCell: ({row}) => (
				<Link
					to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
						id: exhibitionId,
						exhibitorId: row.ExhibitorId
					})}
				>
					{row.ExhibitorName}
				</Link>
			),
			minWidth: 220,
			flex: 1
		},
		{field: 'Title', headerName: t('Title'), minWidth: 240, flex: 1},
	], [exhibitionId, t])
	
	return (
		<Box
			sx={{
				height: 'calc(100% - 500px)',
			}}>
			<Toolbar disableGutters variant={"dense"}>
				<Box sx={{flexGrow: 1}}/>
				<Stack direction="row" width={'100%'} justifyContent={'space-between'} flexWrap={'wrap'}>
					<LoadingButton
						disabled={sendMultiple.length === 0}
						loadingPosition="start"
						variant={"outlined"}
						size={"small"}
						startIcon={<Send/>}
						onClick={() => {
							onSendMultiple(sendMultiple)
							setSendMultiple([])
						}}
					>
						{normalizeGreek(t('Send Multiple'))}
					</LoadingButton>
				</Stack>
			</Toolbar>
			<Divider sx={{mb: 2}}/>
			<Paper sx={{mb: 1, pt: 1, pl: 1, pr: 1}} variant={"outlined"}>
				<GridMultiColumnSearch
					t={t}
					customSearch={customSearch}
					setCustomSearch={setCustomSearch}
					refreshGrid={refreshGrid}
				/>
			</Paper>
			<Box
				sx={{
					height: 600
				}}>
				{columns && columns.length > 0 && (
					<DataGridPremium
						disableRowGrouping
						disableAggregation
						sx={{bgcolor: 'background.paper'}}
						initialState={{
							density: "compact",
							pinnedColumns: {
								left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions']
							},
						}}
						columns={columns}
						// keepNonExistentRowsSelected // Use to keep row selections when changing server side page
						loading={isLoading}
						rows={data}
						slots={{
							toolbar: GridToolbar,
							pagination: CustomPagination
						}}
						localeText={muiGridLocales(language)}
						rowCount={Number(total)}
						pageSizeOptions={[5, 10, 15, 20, 50, 100]}
						pagination
						paginationMode='server'
						paginationModel={{page, pageSize}}
						onPaginationModelChange={onPaginationModelChange}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode='server'
						onFilterModelChange={onFilterModelChange}
						checkboxSelection
						rowSelectionModel={sendMultiple}
						onRowSelectionModelChange={(newSelectionModel) => setSendMultiple(newSelectionModel)}
					/>
				)}
			</Box>
			<ResendEmailOrSmsPopover
				resendEmailOrSMS={resendEmailOrSMS}
				handleResendEmailOrSMS={handleResendEmailOrSMS}
				isSMS={isSMS}
				refreshGrid={refreshGrid}
				handleAlert={handleAlert}
			/>
			<DisplayEmailAsHtmlPopOver
				displayEmailAsHtml={displayEmailAsHtml}
				handleDisplayEmailAsHtml={handleDisplayEmailAsHtml}
			/>
			<ActionStatus
				status={alert}
				onClose={() => setAlert(null)}
			/>
		</Box>
	
	);
};

export default MessagesGrid