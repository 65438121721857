import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";

const INITIAL_STATE = {
    loading: false,
    transferStarted: null,
    transferEnded: null,
    message: null
}

function transferExhibitorsFromEntersoft(state) {
    return state
        .set('loading', true)
        .set('transferStarted', true)
        .set('transferEnded', false)
        .set('message', null)
}

function transferExhibitorsFromEntersoftSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('transferStarted', false)
        .set('transferEnded', true)
        .set('message', fromJS({
            type: 'success',
            message: 'Transfer Successful!'
        }))
}

function transferExhibitorsFromEntersoftFailed(state, action) {
    const {message} = action.payload
    return state
        .set('loading', false)
        .set('transferStarted', false)
        .set('transferEnded', false)
        .set('message', fromJS({
            type: 'error',
            message: message
        }))
}

function transferExhibitorsToEntersoft(state) {
    return state
    .set('loading', true)
    .set('transferStarted', true)
    .set('transferEnded', false)
    .set('message', null)
}

function transferExhibitorsToEntersoftSucceeded(state, action) {
    return state
    .set('loading', false)
    .set('transferStarted', false)
    .set('transferEnded', true)
    .set('message', fromJS({
        type: 'success',
        message: 'Transfer Successful!'
    }))
}

function transferExhibitorsToEntersoftFailed(state, action) {
    const {message} = action.payload
    return state
    .set('loading', false)
    .set('transferStarted', false)
    .set('transferEnded', false)
    .set('message', fromJS({
        type: 'error',
        message: message
    }))
}

function clearTransferMessage(state, action) {
    return state
        .set('message', null)
}

const entersoftTransfer = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.TRANSFER_EXHIBITORS_FROM_ENTERSOFT]: transferExhibitorsFromEntersoft,
        [ActionTypes.TRANSFER_EXHIBITORS_FROM_ENTERSOFT_SUCCEEDED]: transferExhibitorsFromEntersoftSucceeded,
        [ActionTypes.TRANSFER_EXHIBITORS_FROM_ENTERSOFT_FAILED]: transferExhibitorsFromEntersoftFailed,
        [ActionTypes.TRANSFER_EXHIBITORS_TO_ENTERSOFT]: transferExhibitorsToEntersoft,
        [ActionTypes.TRANSFER_EXHIBITORS_TO_ENTERSOFT_SUCCEEDED]: transferExhibitorsToEntersoftSucceeded,
        [ActionTypes.TRANSFER_EXHIBITORS_TO_ENTERSOFT_FAILED]: transferExhibitorsToEntersoftFailed,
        [ActionTypes.CLEAR_TRANSFER_MESSAGE]: clearTransferMessage,
    }
}

export default entersoftTransfer
