// @flow
import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import {Switch} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
};

const ExhibitorInvitesVisitorsWithBarcode = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props

    const toggleExhibitorInvitesVisitorsWithBarcode = () => {
        updateExhibition(id, !exhibition.exhibitorInvitesVisitorsWithBarcode, Update.ExhibitorInvitesVisitorsWithBarcode)
    }
    return (
        <ListItem>
            <ListItemText
                primary={t('Exhibitor Invites Visitors With Barcode')}
                secondary={t('If you disable this, then when exhibitors sends visitors an invitation, barcode will not be included as pdf in the email.')}
            />
            <Switch
                edge="end"
                onChange={toggleExhibitorInvitesVisitorsWithBarcode}
                checked={exhibition.exhibitorInvitesVisitorsWithBarcode}
            />
        </ListItem>
    );
};

export default ExhibitorInvitesVisitorsWithBarcode