// @flow
import React from 'react';
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {};

const ViewListItem = (props: Props) => {
	const {view, viewId, selected, onDelete, onSelect, ...other} = props;
	return (
		<MenuItem
			selected={selected}
			onClick={() => onSelect(viewId)}
			{...other}
		>
			{view.label}
			<IconButton
				edge="end"
				aria-label="delete"
				size="small"
				onClick={(event) => {
					event.stopPropagation();
					onDelete(viewId);
				}}
			>
				<DeleteIcon/>
			</IconButton>
		</MenuItem>
	);
};

export default ViewListItem