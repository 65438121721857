// @flow
import React, {useContext} from 'react';
import TextField from "@mui/material/TextField";
import {RuleContext} from "./ruleConditions";
import {OutlinedInput} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {DYNAMIC_LIST_AS_TABLE_COMPONENT} from "../../../../../../locales/components/namespaces";

type Props = {};

const GenerateValueInput = (props: Props) => {
    const {
        row,
        rows,
        rowIndex,
        isEditMode,
        handleRows
    } = props
    const {countries, interests} = useContext(RuleContext)
    const {t} = useTranslation(DYNAMIC_LIST_AS_TABLE_COMPONENT)

    let input = <></>
    switch (row.field) {
        case 'isIndividual':
        case 'hasRevisited':
        case 'FromRegistration':
        case 'FromPreregistration':
        case 'FromImport':
        case 'hasInterests':
            input = (
                <TextField
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                        "& .Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        }
                    }}
                    select
                    label={t(row.field)}
                    fullWidth
                    variant={"standard"}
                    size={"small"}
                    value={row.value}
                    name={row.field}
                    disabled={!isEditMode}
                    input={<OutlinedInput label={t(row.field)}/>}
                    onChange={(evt) => {
                        const updateRows = [...rows].map(item => {
                            if (item.id === row.id) {
                                item.value = evt.target.value
                            }
                            return item
                        })
                        handleRows(updateRows, row)
                    }}
                >
                    <MenuItem
                        value={''}
                    />
                    <MenuItem
                        value={1}
                    >
                        true
                    </MenuItem>
                    <MenuItem
                        value={0}
                    >
                        false
                    </MenuItem>
                </TextField>
            )
            break
        case 'Interests':
            input = (
                <TextField
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                        "& .Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        }
                    }}
                    select
                    label={t(row.field)}
                    fullWidth
                    variant={"standard"}
                    size={"small"}
                    value={row.value}
                    name={row.field}
                    disabled={!isEditMode}
                    input={<OutlinedInput label={t(row.field)}/>}
                    onChange={(evt) => {
                        const updateRows = [...rows].map(item => {
                            if (item.id === row.id) {
                                item.value = evt.target.value
                            }
                            return item
                        })
                        handleRows(updateRows, row)
                    }}
                >
                    <MenuItem
                        value={''}
                    />
                    {interests.map((interest, index) => (
                        <MenuItem
                            key={index}
                            value={interest.id}
                        >
                            {interest.description}
                        </MenuItem>
                    ))}
                </TextField>
            )
            break
        case 'CountryId':
            input = (
                <TextField
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                        "& .Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        }
                    }}
                    select
                    label={t(row.field)}
                    fullWidth
                    variant={"standard"}
                    size={"small"}
                    value={row.value}
                    name={row.field}
                    disabled={!isEditMode}
                    input={<OutlinedInput label={t(row.field)}/>}
                    onChange={(evt) => {
                        const updateRows = [...rows].map(item => {
                            if (item.id === row.id) {
                                item.value = evt.target.value
                            }
                            return item
                        })
                        handleRows(updateRows, row)
                    }}
                >
                    <MenuItem
                        value={''}
                    />
                    {countries.map((country, index) => (
                        <MenuItem
                            key={index}
                            value={country.id}
                        >
                            {country.description}
                        </MenuItem>
                    ))}
                </TextField>
            )
            break
        default:
            input = (
                <TextField
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                        "& .Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        }
                    }}
                    variant={'standard'}
                    fullWidth
                    disabled={!isEditMode}
                    label={t(row.field)}
                    value={row.value}
                    name={`${row.field}-${rowIndex}`}
                    onChange={(evt) => {
                        const updateRows = [...rows].map(item => {
                            if (item.id === row.id) {
                                item.value = evt.target.value
                            }
                            return item
                        })
                        handleRows(updateRows, row)
                    }}
                    size={'small'}
                />
            )
            break
    }

    return input
};

export default GenerateValueInput