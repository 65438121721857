//@flow
import React from 'react';
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import BadgeDetails from "../badgeDetails/badgeDetails";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {VISITOR_PROFILE} from "../../../locales/components/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {RoleTranslations} from "../../../helpers/constants";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import useExhibitionAction from "../../../hooks/exhibitions/useExhibitionAction";
import ActionStatus from "../alerts/actionStatus/actionStatus";
import useExhibitionData from "../../../hooks/exhibitions/useExhibitionData";
import type {Badge} from "../../../types/badge/types";
import ChangeApprovalStatus from "../changeApprovalStatus/changeApprovalStatus";
import ScanDetailsPageTitle from "./scanDetailsPageTitle";
import {useMultilingualText} from "../../../hooks/useMultilingualText";
import type {Partner, Visitor} from "../../../types/exhibition/types";
import {useVisitor} from "../../../hooks/exhibitions/useVisitor";
import ContentLoader from "../../general/contentLoader/contentLoader";
import PrintBadgeButton from "../printBadgeButton/printBadgeButton";
import Breadcrumb from "../../general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_HOME} from "../../../routers/routes";
import VisitorForm from "../visitorForm/visitorForm";

type Props = {
    profile?: Visitor | Partner,
    badge: Badge,
    limitedView?: boolean,
    useLocalStorage?: boolean
}

type PropsVisitor = Props & {
    profile: Visitor,
}

const ScanDetailsVisitor = (props: PropsVisitor) => {
    const {badge, limitedView, useLocalStorage, profile} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    const {clearActionOnVisitorStatus, createVisitor} = useExhibitionAction()
    const {actionOnVisitorStatus} = useExhibitionData()
    const onSubmit = (values) => createVisitor(badge.exhibition.id, null, values.fieldValues, false, true)
    const {exhibition} = badge
    const username = useMultilingualText(badge.user.name)
    return (
        <React.Fragment>
            <Breadcrumb>
                <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                    {t('Home')}
                </Breadcrumb.Link>
                <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
                    {exhibition.title}
                </Breadcrumb.Link>
                <Breadcrumb.Link
                    to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id}, {section: "visitors"})}>
                    {t('Visitors')}
                </Breadcrumb.Link>
                <Breadcrumb.Item notFocused>
                    {username}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {/*<TabPanel isVisible={value === 0}>*/}
                    <Paper sx={{p: 2, pt: 0}} elevation={1}>
                        <Toolbar disableGutters variant={"dense"}>
                            <Button
                                form={"preregistration"}
                                size={"small"}
                                startIcon={<SaveIcon/>}
                                type="submit"
                                variant="contained"
                            >
                                {normalizeGreek(t('Save'))}
                            </Button>
                            <Box sx={{flexGrow: 1}}/>
                            <PrintBadgeButton
                                printSize={exhibition.visitorBadgeTemplateTypeId}
                                userId={badge.user.id}
                                type={"visitor"}
                                autoReloadFile={false}
                            />
                        </Toolbar>
                        <Divider sx={{mb: 1}} light/>
                        {badge && (
                            <VisitorForm
                                id={profile.id}
                                exhibition={exhibition}
                                preregistrationFormValues={badge.preregistrationFormValues}
                            />
                        )}
                        {/*<PreregistrationForm*/}
                        {/*    onSubmit={onSubmit}*/}
                        {/*    exhibition={badge.exhibition}*/}
                        {/*    preregistrationFormValues={badge.preregistrationFormValues}*/}
                        {/*    useLocalStorage={useLocalStorage}*/}
                        {/*    limitedView={limitedView}*/}
                        {/*/>*/}
                    </Paper>
                    {/*</TabPanel>*/}
                    {/*<TabPanel isVisible={value === 1}>*/}
                    {/*</TabPanel>*/}
                    {/*<TabPanel isVisible={value === 2}>*/}
                    {/*    <VisitorReferences*/}
                    {/*        id={badge.user.id}*/}
                    {/*        exhibitionId={badge.exhibition.id}*/}
                    {/*    />*/}
                    {/*</TabPanel>*/}
                </Grid>
                {!limitedView && (
                    <Grid item xs={12} md={4}>
                        <Paper sx={{px: 2, pb: 2}}>
                            <ChangeApprovalStatus
                                badge={badge}
                                visitor={profile}
                                exhibitionId={badge.exhibition.id}
                            />
                            <BadgeDetails
                                badge={badge}
                                printSize={exhibition.visitorBadgeTemplateId}
                            />
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <ActionStatus
                status={actionOnVisitorStatus}
                onClose={clearActionOnVisitorStatus}
            />
        </React.Fragment>
    )
}

const ScanDetails = (props: Props) => {
    const {badge, limitedView, profile} = props
    const role = useMultilingualText(RoleTranslations[badge.user.role])
    const name = useMultilingualText(badge.user.name)
    const {visitor, loading} = useVisitor({
        overrideVisitorId: badge.user.id,
        overrideExhibitionId: badge.exhibition.id,
        doNotFetchProfile: (badge.type !== "visitor" || (badge.type === "visitor" && profile)),
        doNotFetchExhibitors: true,
        doNotFetchCategories: true
    })
    return (
        <React.Fragment>
            <ScanDetailsPageTitle
                limitedView={limitedView}
                name={normalizeGreek(name)}
                visitorStatus={profile.StatusId}
                role={normalizeGreek(role.toUpperCase())}
            />
            {badge.type === "visitor" && (
                <React.Fragment>
                    {(profile || visitor) ? (
                        <ScanDetailsVisitor
                            {...props}
                            profile={profile ?? visitor}
                        />
                    ) : (
                        <ContentLoader
                            loading={loading}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default ScanDetails;