import {useEffect, useState} from 'react'
import {usePagination, useTable} from "react-table";

const useTableIndex = ({
                           columns,
                           data,
                           controlledPageCount,
                           initialState,
                           fetchData,
                           loading
                       }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        // Get the state from the instance
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: initialState,
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
    )

    const [index, setIndex] = useState(initialState.pageIndex * initialState.pageSize)

    useEffect(() => {
        fetchData({pageIndex, pageSize})
    }, [fetchData, pageIndex, pageSize])

    useEffect(() => {
        gotoPage(0)
    }, [fetchData])

    useEffect(() => {
        const newIndex = pageIndex * pageSize
        if (!(loading)) {
            setIndex(newIndex)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    return {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        index,
        pageIndex,
        pageSize
    }
}

export default useTableIndex