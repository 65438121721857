// @flow
import React, {useMemo} from 'react';
import Box from "@mui/material/Box";
import useUserData from "../../../../../../../hooks/user/useUserData";
import {muiGridLocales} from "../../../../../../../helpers/muiGridLocales";
import {DataGridPremium, GridToolbar} from "@mui/x-data-grid-premium";
import {
	CustomPagination
} from "../../../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import {formatDate} from "../../../../../../../helpers/date";

type Props = {};

const KpisCoorganization = (props: Props) => {
	const {loading, data, t, generalReport} = props
	const {language, dateFormat} = useUserData()
	const columns = useMemo(() => [
			...(generalReport ? [{
				field: 'exhibition',
				description: t('Exhibition'),
				headerName: t('Exhibition'),
				flex: 1
			}] : []),
			...(generalReport ? [{
				field: 'coorganization',
				description: t('Coorganization'),
				headerName: t('Coorganization'),
				flex: 1
			}] : []),
			{
				field: 'exhibitionStartDt',
				description: t('Exhibition Date'),
				headerName: t('Exhibition Date'),
				width: 180,
				valueFormatter: (value, row, column, apiRef) => {
					return `${formatDate(row.exhibitionStartDt, dateFormat)}`
				}
			},
			{
				field: 'preregistrationTotalCountCoorg',
				description: t('preregistrationTotalCountCoorg_info'),
				headerName: t('preregistrationTotalCountCoorg'),
				flex: 1,
				type: 'number'
			},
			{
				field: 'preregistrationVisitorCountCoorg',
				description: t('preregistrationVisitorCountCoorg_info'),
				headerName: t('preregistrationVisitorCountCoorg'),
				flex: 1,
				type: 'number'
			},
			{
				field: 'erpTotalCountCoorg',
				description: t('erpTotalCountCoorg_info'),
				headerName: t('erpTotalCountCoorg'),
				flex: 1,
				type: 'number'
			},
			{
				field: 'notErpTotalCountCoorg',
				description: t('notErpTotalCountCoorg_info'),
				headerName: t('notErpTotalCountCoorg'),
				flex: 1,
				type: 'number'
			},
			
			{
				field: 'scannedAfterRegistration',
				description: t('scannedAfterRegistration_info'),
				headerName: t('scannedAfterRegistration'),
				flex: 1,
				type: 'number'
			},
			{
				field: 'erpVisitorCountCoorg',
				description: t('erpVisitorCountCoorg_info'),
				headerName: t('erpVisitorCountCoorg'),
				flex: 1,
				type: 'number'
			},
			{
				field: 'notErpVisitorCountCoorg',
				description: t('notErpVisitorCountCoorg_info'),
				headerName: t('notErpVisitorCountCoorg'),
				flex: 1,
				type: 'number'
			},
			{
				field: 'totalNoActualVisitors',
				description: t('Total Visitors = Actual Visits From Visitors Submitted Through ERP + Actual Visits From Non ERP Submitted Visitors'),
				headerName: t('Total Number Of Actual Visitors'),
				flex: 1,
				type: 'number',
				valueGetter: (value, row, column, apiRef) => {
					return row.erpVisitorCountCoorg + row.notErpVisitorCountCoorg
				},
			},
		],
		[dateFormat, generalReport, t]
	)
	
	return (
		<Box style={{height: 400, width: '100%', maxWidth: '100%'}}>
			<DataGridPremium
				sx={{
					bgcolor: 'background.paper',
					"& .MuiDataGrid-columnHeaderTitle": {
						whiteSpace: "normal",
						lineHeight: "normal"
					},
					"& .MuiDataGrid-columnHeader": {
						// Forced to use important since overriding inline styles
						height: "unset !important"
					},
					"& .MuiDataGrid-columnHeaders": {
						// Forced to use important since overriding inline styles
						maxHeight: "200px !important",
						minHeight: "80px !important",
						// padding: 1,
						textAlign: 'right'
					}
				}}
				loading={loading}
				initialState={{
					density: "compact"
				}}
				localeText={muiGridLocales(language)}
				rows={data}
				columns={columns}
				pagination
				slots={{
					toolbar: GridToolbar,
					pagination: CustomPagination
				}}
				slotProps={{toolbar: {csvOptions: {disableToolbarButton: true}}}}
			/>
		</Box>
	);
};

export default KpisCoorganization