import type {
    Badge,
    BadgeConfiguration,
    BadgeTemplateTypes,
    PreregistrationFormValues,
    UnmappedBadge,
    UnmappedBadgeConfiguration,
    UnmappedBadgeTemplateTypes,
    UnmappedPreregistrationFormValues
} from "./types";
import {Roles} from "../../helpers/constants";
import {mapExhibition} from "../exhibition/map";

export const mapVisitorDataToPreregistrationFormValues: PreregistrationFormValues = (datum: UnmappedPreregistrationFormValues) => {
    if (!datum) return
    return {
        characterization: datum.CharacterizationId ?? '',
        city: datum.City ?? '',
        companyAddress: datum.Address ?? '',
        companyFirm: datum.CompanyFirm ?? '',
        companyName: datum.CompanyName ?? '',
        companyWebsite: datum.WebSite ?? '',
        facebook: datum.Facebook ?? '',
        instagram: datum.Instagram ?? '',
        countries: datum.CountryId,
        email: datum.VisitorEmail ?? '',
        existsInExhibition: datum.existsInExhibition === "1",
        hasRevisited: datum.hasRevisited === "1",
        id: datum.ContactId,
        interests: datum.Interests ? datum.Interests.split(',').filter(i => i !== '') : [],
        isIndividual: datum.isIndividual === "1",
        mobilePhone: datum.VisitorMobilePhone ?? '',
        name: datum.VisitorName ?? '',
        personCount: datum.PersonCount ?? 1,
        phone: datum.VisitorBusinessPhone ?? '',
        postalCode: datum.ZipCode ?? '',
        vatNumber: datum.VatNumber ?? '',
        otherInterests: datum.OtherInterests ?? '',
        position: datum.Position ?? ''
    }
}

export const mapBadge: Badge = (datum: UnmappedBadge) => {
    if (!datum) return
    const {data} = datum
    return {
        id: datum.id,
        statusId: Number(data.StatusId),
        type: datum.type,
        user: {
            name: {
                en: data.Name ?? data.PartnerName ?? '',
                el: data.Name ?? data.PartnerName ?? '',
            },
            id: data.id,
            company: data.CompanyName,
            email: data.email,
            role: datum.type === "visitor" ? Roles.Visitor : Roles.Exhibitor,
        },
        exhibition: mapExhibition(datum.exhibition),
        preregistrationFormValues: mapVisitorDataToPreregistrationFormValues(datum.data)
    }
}

export const mapBadgeConfiguration: BadgeConfiguration = (datum: UnmappedBadgeConfiguration) => {
    if (!datum) return
    return {
        content: {
            en: datum.ContentEN,
            el: datum.ContentEL,
        },
        contentPartner: {
            en: datum.ContentPartnerEN,
            el: datum.ContentPartnerEL,
        },
        colorPartner1: datum.ColorPartner1,
        colorPartner2: datum.ColorPartner2,
        colorFont: datum.ColorFont,
        colorVisitor1: datum.ColorVisitor1,
        colorVisitor2: datum.ColorVisitor2,
        colorProvider1: datum.ColorProvider1,
        colorProvider2: datum.ColorProvider2,
        colorStaff1: datum.ColorStaff1,
        colorStaff2: datum.ColorStaff2,
        colorOrganizer1: datum.ColorOrganizer1,
        colorOrganizer2: datum.ColorOrganizer2,
        visitorBadgeTemplateTypeId: datum.VisitorBadgeTemplateTypeId,
        visitorRegBadgeTemplateTypeId: datum.VisitorRegBadgeTemplateTypeId,
        partnerBadgeTemplateTypeId: datum.PartnerBadgeTemplateTypeId,
        providerBadgeTemplateTypeId: datum.ProviderBadgeTemplateTypeId,
        staffBadgeTemplateTypeId: datum.StaffBadgeTemplateTypeId,
        organizerBadgeTemplateTypeId: datum.OrganizerBadgeTemplateTypeId,
    }
}

export const mapBadgeTemplateTypes: BadgeTemplateTypes = (datum: UnmappedBadgeTemplateTypes) => {
    if(!datum) return
    return {
        id: datum.id,
        description: {
            en: datum.DescriptionEN,
            el: datum.DescriptionEL
        }
    }
}
