// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {useMuiGridServerSide} from "../../../../../hooks/useMuiGridServerSide";
import {mapMessagesSentPerDate} from "../../../../../types/messageTemplates/map";
import {useTranslation} from "react-i18next";
import {PAGE_SETTINGS} from "../../../../../locales/pages/namespaces";
import {DataGridPremium, GridActionsCellItem, GridToolbar} from "@mui/x-data-grid-premium";
import {Box, Modal} from "@mui/material";
import {RestartAlt} from "@mui/icons-material";
import axios from "axios";
import {API} from "../../../../../helpers/constants";
import useUserData from "../../../../../hooks/user/useUserData";
import CircularProgress from "@mui/material/CircularProgress";
import ActionStatus from "../../../../../components/app/alerts/actionStatus/actionStatus";
import {muiGridLocales} from "../../../../../helpers/muiGridLocales";
import {
    CustomPagination
} from "../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";

type Props = {};

const ResendsPerDateGrid = ({exhibitionId, messageTemplateTypeId, tabValue}: Props) => {
    const {token} = useUserData()
    const {t} = useTranslation(PAGE_SETTINGS)
    const [isSending, setIsSending] = useState(false)
    const [alert, setAlert] = useState(null)
    const handleAlert = (value) => setAlert(value)
    const {language} = useUserData()
    const url = `exhibition/messages-per-date/${exhibitionId}/${messageTemplateTypeId}`
    const {
        isLoading,
        data,
        total,
        page,
        pageSize,
        onPaginationModelChange,
        onSortModelChange,
        onFilterModelChange,
        refreshGrid
    } = useMuiGridServerSide({
        url,
        initialPageSize: 20,
        mapFunction: mapMessagesSentPerDate,
        initialSortModel: [{name: 'dt', direction: 'descending'}]
    })

    useEffect(() => {
        if (messageTemplateTypeId) refreshGrid()
        // eslint-disable-next-line
    }, [messageTemplateTypeId])

    const sendEmails = (date) => {
        setIsSending(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/en/exhibition/messages-per-date/copy`,
            data: {
                Date: date.split('/').reverse().join('-'),
                TypeId: messageTemplateTypeId,
                ExhibitionId: exhibitionId
            }
        }).then((response) => {
            refreshGrid()
            setIsSending(false)
            setAlert({
                type: 'success',
                message: t('Email Submitted')
            })
        }).catch((error) => {
            setIsSending(false)
            setAlert({
                type: 'failure',
                message: error?.message
            })
        })
    }

    const columns = useMemo(() => [
        {
            headerName: t('Actions'),
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    onClick={(evt) => sendEmails(params.row.dt)}
                    icon={<RestartAlt/>}
                    label={t("Resend")}
                    color={"info"}
                />
            ]
        },
        {field: 'dt', headerName: t('Sent At'), minWidth: 200},
        {field: 'count', headerName: t('Number Of Messages Sent'), minWidth: 220, type: 'number'},
    ], [sendEmails, t])

    return (
        <Box
            sx={{
                height: 'calc(100% - 180px)',
                maxWidth: '550px',
                paddingTop: '2px',
                '& .MuiDataGrid-renderingZone': {
                    maxHeight: 'none !important',
                },
                '& .MuiDataGrid-cell': {
                    lineHeight: 'unset !important',
                    maxHeight: 'none !important',
                    whiteSpace: 'normal!important',
                },
                '& .MuiDataGrid-row': {
                    maxHeight: 'none !important',
                },
            }}>
            {columns && columns.length > 0 && (
                <DataGridPremium
                    disableRowGrouping
                    disableAggregation
                    sx={{bgcolor: 'background.paper'}}
                    initialState={{density: "compact"}}
                    columns={columns}
                    getRowId={(row: any) => row.dt + row.count}
                    // keepNonExistentRowsSelected // Use to keep row selections when changing server side page
                    loading={isLoading}
                    rows={data}
                    rowCount={Number(total)}
                    pageSizeOptions={[5, 10, 15, 20, 50, 100]}
                    pagination
                    paginationMode='server'
                    paginationModel={{page, pageSize}}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode="server"
                    onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
                    filterMode='server'
                    onFilterModelChange={onFilterModelChange}
                    localeText={muiGridLocales(language)}
					slotProps={{toolbar: {csvOptions: {disableToolbarButton: true}}}}
                    slots={{
                        toolbar: GridToolbar,
                        pagination: CustomPagination
                    }}
                />
            )}
            <Modal
                open={isSending}
            >
                <Box sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress/>
                </Box>
            </Modal>
            <ActionStatus
                status={alert}
                onClose={() => setAlert(null)}
            />
        </Box>
    );
};

export default ResendsPerDateGrid