// @flow
import * as React from 'react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import {FormControl, FormHelperText} from "@mui/material";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import SaveIcon from "@mui/icons-material/Save";
import useExhibitionAction, {UpdateExhibitor} from "../../../hooks/exhibitions/useExhibitionAction";
import TextField from "@mui/material/TextField";

type Props = {};

export const CatalogueLength = (props: Props) => {
    const {catalogueLength, handleCatalogueLength, refreshGrid, exhibitionId} = props
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {updateExhibitorProfile} = useExhibitionAction()

    const onSubmit = (values) => {
        updateExhibitorProfile(catalogueLength.exhibitorId, exhibitionId, values, UpdateExhibitor.CatalogueLength, refreshGrid)
        setTimeout(() => {refreshGrid(); handlePopoverClose()}, 800)
    }

    const validationSchema = yup.object({
        catalogueProfileLength: yup.number().positive().integer().min(200, t('Catalogue length must be at least 200 characters')).nullable()
    })

    const formik = useFormik({
        initialValues: {
            catalogueProfileLength: catalogueLength?.length
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        enableReinitialize: true
    })

    const handlePopoverClose = () => {
        handleCatalogueLength({anchorEl: null, open: false, exhibitorId: '', length: ''});
        formik.resetForm()
    }

    return (
        <React.Fragment>
            <Box onClick={(e) => e.stopPropagation()}>
                <Popover
                    disableRestoreFocus
                    onClose={handlePopoverClose}
                    open={catalogueLength.open}
                    anchorEl={catalogueLength.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <FormikProvider value={formik}>
                        <Box component="form" onSubmit={formik.handleSubmit} sx={{width: '100%', minWidth: '400px'}}>
                            <Box sx={{width: '100%', padding: '20px'}}>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    error={
                                        formik.touched["catalogueProfileLength"] &&
                                        Boolean(formik.errors["catalogueProfileLength"])
                                    }
                                >
                                    <TextField
                                        id="outlined-name"
                                        label={t('catalogueProfileLength')}
                                        value={formik.values.catalogueProfileLength}
                                        name={`catalogueProfileLength`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        size={'small'}
                                        type={'number'}
                                    />
                                    <FormHelperText>
                                        <ErrorMessage name={"catalogueProfileLength"}/>
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Box sx={{
                                padding: '0 20px 20px',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    size={"small"}
                                    type={"submit"}
                                    variant={"contained"}
                                    startIcon={<SaveIcon/>}
                                >
                                    {normalizeGreek(t('Save'))}
                                </Button>
                                <Button
                                    size={"small"}
                                    variant={"outlined"}
                                    startIcon={<CancelIcon/>}
                                    type={"button"}
                                    onClick={handlePopoverClose}
                                >
                                    {normalizeGreek(t('Cancel'))}
                                </Button>
                            </Box>
                        </Box>
                    </FormikProvider>
                </Popover>
            </Box>
        </React.Fragment>
    );
};