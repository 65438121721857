// @flow
import React, {useEffect} from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import useUserData from "../../../../hooks/user/useUserData";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {ListItem, Paper} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import useExhibitionAction from "../../../../hooks/exhibitions/useExhibitionAction";
import useExhibitionData from "../../../../hooks/exhibitions/useExhibitionData";
import SelectExhibitionToCopy from "./partials/selectExhibitionToCopy";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../../helpers/constants";

type Props = {};

const CopyExhibitionSettings = (props: Props) => {
	const {exhibition, id, loading: loadingExhibition} = useExhibition({noFetchFiles: true, language: 'any'})
	const {getExhibitionsList, copyExhibitionSettings, getExhibitionDetails} = useExhibitionAction()
	const {exhibitionsList: exhibitions} = useExhibitionData()
	const {language} = useUserData()
	const {t} = useTranslation(EXHIBITION)
	useEffect(() => {
		getExhibitionsList()
	}, [getExhibitionsList])
	
	return exhibition ? (
		<React.Fragment>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
					{exhibition.title[language]}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
					{t('Settings')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('Copy Exhibition Settings')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<List>
					<ListItem component={"div"}>
						<ListItemText
							primary={
								<Typography component={"div"} variant={"body1"}>
									<Trans
										i18nKey={'exhibition_copy_settings'}
										ns={EXHIBITION}
									>
										On this page you can <strong>copy settings</strong> of an other exhibition
										to <strong>current exhibition</strong>. Settings that will be copied are: <br/>
										<ul>
											<li>Catalogue settings for language, locking, maximum characters, submission
												limit and notification days.
											</li>
											<li>Kiosk sign settings.</li>
											<li>Brands settings.</li>
											<li>Partner settings.</li>
											<li>Settings for pages available to exhibitors.</li>
											<li>Preregistration settings for countries, approval, group fields and form
												fields. <strong>Preregistration dates and activation settings are not
													copied!</strong></li>
											<li>Registration settings for days before and after exhibition that
												registration opens, form field settings and disabling preregistration
												messages.
											</li>
											<li>Email sender settings.</li>
											<li>Visitor categories settings.</li>
											<li>QR settings.</li>
											<li>Email and SMS text content. <strong>Email and sms types become inactive
												when copied!</strong></li>
											<li>Exhibition home page and menu content.</li>
											<li>Badges text.</li>
											<li>Color and print size settings for visitors, partners, service providers,
												exhibition staff, organizers and registration.
											</li>
											<li>Visitor Categories and special print rules.</li>
										</ul>
										<br/>
										<strong>Attention: You cannot copy settings to an ongoing exhibition! This
											functionality is not available during the exhibition, 20 days prior to the
											exhibition start and for exhibitions that have active
											preregistration!</strong>
										<br/><br/>
										<strong>Available exhibitions to copy settings from are exhibitions after
											01/06/2024.</strong>
									</Trans>
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Paper>
			{exhibitions && (
				<SelectExhibitionToCopy
					language={language}
					loading={loadingExhibition}
					toExhibition={id}
					exhibitions={exhibitions}
					t={t}
					copyExhibitionSettings={copyExhibitionSettings}
					exhibition={exhibition}
					getExhibitionDetails={getExhibitionDetails}
				/>
			)}
		</React.Fragment>
	) : (
		<ContentLoader
			loading={loadingExhibition}
		/>
	)
};

export default withPermissionHOC(CopyExhibitionSettings, [Permissions.BeOrganizer])