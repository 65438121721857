import React from 'react';

const warn = () => {
    throw new Error('Expected react-sortly context');
};

const context = React.createContext({
    setDragMonitor: () => {
        warn();
    },
    setConnectedDragSource: () => {
        warn();
    },
    setInitialDepth: () => {
        warn();
    },
});

export default context;
