import * as ActionTypes from '../../actions/types';
import {fromJS} from "immutable";
import {mapReports} from "../../types/reports/map";

const initialReports = {
	categories: [],
	interests: [],
	kpi: [],
	visitsCountries: [],
	visitsDay: [],
	visitsHalfHour: [],
	visitsHour: [],
	visitsRegion: [],
	visitsTotal: [],
}

const INITIAL_STATE = {
	loading: false,
	data: initialReports,
	message: null
}

function setReportMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getReportData(state) {
	return state
	.set('loading', true)
	.set('data', fromJS(initialReports))
}

function getReportDataSucceeded(state, action) {
	const {payload} = action
	const mappedData = mapReports(payload)
	return state
	.set('loading', false)
	.set('data', fromJS(mappedData))
}

function getReportDataFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const reports = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.GET_REPORT_DATA]: getReportData,
		[ActionTypes.GET_REPORT_DATA_SUCCEEDED]: getReportDataSucceeded,
		[ActionTypes.GET_REPORT_DATA_FAILED]: getReportDataFailed,
		[ActionTypes.SET_REPORT_MESSAGE]: setReportMessage,
	}
}

export default reports
