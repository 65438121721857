//@flow
import React, {useEffect} from 'react';
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import {CatalogueLanguages, Languages, Permissions} from "../../helpers/constants";
import {useExhibition} from "../../hooks/exhibitions/useExhibition";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import OrganizerMain from "./partials/organizer/main/main";
import ExhibitorMain from "./partials/exhibitor/main/main";
import ExhibitionPageTitle from "../../components/app/exhibitionPageTitle/exhibitionPageTitle";
import useUserAction from "../../hooks/user/useUserAction";

const Exhibition = () => {
    const {exhibition, loading} = useExhibition()
    const {setUserLanguage} = useUserAction()
    useEffect(() => {
        if (exhibition?.catalogueLanguage === CatalogueLanguages.EN) {
            setUserLanguage(Languages.EN)
        }
        if (exhibition?.catalogueLanguage === CatalogueLanguages.EL) {
            setUserLanguage(Languages.EL)
        }
    }, [exhibition?.catalogueLanguage, setUserLanguage])
    return (
        <React.Fragment>
            {exhibition ? (
                <React.Fragment>
                    <PermissionHOC requiredPermissions={[Permissions.BeOrganizer]}>
                        <ExhibitionPageTitle
                            exhibition={exhibition}
                        />
                        <OrganizerMain/>
                    </PermissionHOC>
                    <PermissionHOC requiredPermissions={[Permissions.BeExhibitor]}>
                        <ExhibitorMain/>
                    </PermissionHOC>
                </React.Fragment>
            ) : <ContentLoader loading={loading}/>}
        </React.Fragment>
    )
}

export default Exhibition;