import {withLanguage} from "../../helpers/parse";
import type {Coorganization} from "./types";

export const parseCoorganizations: Coorganization = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        descriptionEn: datum.get('descriptionEn'),
        descriptionEl: datum.get('descriptionEl'),
        description: withLanguage(language)(datum.get('description'))
    }
}

export const parseStatus = (datum) => {
    if (!datum) return;
    return {
        message: datum.get('message'),
        type: datum.get('type')
    }
}