//@flow
import React, {useContext, useEffect} from 'react';
import {Chip} from "@mui/material";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {TitleContext} from "../../../pages/main/content";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import VisitorStatus from "../visitorStatus/visitorStatus";

const ScanDetailsPageTitle = ({limitedView, role, name, visitorStatus}) => {
    const {setTitle} = useContext(TitleContext)
    useEffect(() => {
        setTitle(
            <React.Fragment>
                <Typography variant="h6" component="div" sx={{mr: 1}}>
                    {name}
                </Typography>
                {!limitedView && (
                    <Stack spacing={1} direction={"row"}>
                        <Chip
                            label={normalizeGreek(role)}
                            size="small"
                            color="secondary"
                        />
                        {Boolean(visitorStatus) && (
                            <VisitorStatus
                                status={visitorStatus}
                                upperCase
                            />
                        )}
                    </Stack>
                )}
            </React.Fragment>
        )
        return () => {
            setTitle(null)
        }
    }, [limitedView, name, role, setTitle, visitorStatus])
    return null
}

export default ScanDetailsPageTitle