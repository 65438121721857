import {CheckboxState} from '../helpers/constants'
import {useCallback, useEffect, useState} from 'react'

export const useTree = (items = [], onClickCallback) => {
    // Define an initial state for all checkboxes
    const defaultItemStates = items.map(item => ({
            ...item,
            state: item.isChecked ? CheckboxState.CHECKED : CheckboxState.UNCHECKED
        }))
    // Create state variable for all list items states
    const [itemStates, setItemStates] = useState(defaultItemStates)

    useEffect(() => {
        setItemStates(defaultItemStates)
    }, [items])

    const [allSelected, setAllSelected] = useState(true)

    // Getter. Get specific item's state by its id.
    const getItemState = useCallback(
        id => itemStates.find(i => i.id === id)?.state,
        [itemStates]
    )

    const updateItemStates = (oldItemsState, items, clickedItemId) => {
        const newItemsState = [...oldItemsState]
        const clickedItemState = getItemState(clickedItemId)
        // What happens when a user clicks a checkbox, depends on the current state of that clicked checkbox.
        if (clickedItemState === CheckboxState.CHECKED) {
            // If initially checked, unchecked it.
            setUnchecked(clickedItemId, newItemsState)
        } else {
            // If initially unchecked, check it.
            setChecked(clickedItemId, newItemsState)
        }
        // Return the new state for all items, because clicking one checkbox can affect the state of multiple checkboxes.
        return newItemsState
    }

    const updateParent = (parentId, newState) => {
        // Find the item, which is a parent. (first loop gets the clicked item since it has children)
        const item = items.find((i) => i.id === parentId)
        // If this item has a parent of its own, find its parent
        const parent = items.find((i) => i.id === item.parentId)
        // If this item doesn't have parent stop recursive behavior.
        if (!parent) return

        // If this item has a parent, get all parent's children as an array of ids.
        const childIds = items.filter((i) => i.parentId === parent.id).map((i) => i.id)
        // Loop over the children ids and get their respective states.
        const childStates = childIds.map((childId) => getItemState(childId, newState))

        // Check it all children are checked. If so, find the parent and set its state as checked too
        if (childStates.length === childStates.filter((s) => s === CheckboxState.CHECKED).length) {
            newState.find((i) => i.id === parent.id).state = CheckboxState.CHECKED
            // Check it all children are unChecked. If so, find the parent and set its state as unChecked too
        } else if (childStates.length === childStates.filter((s) => s === CheckboxState.UNCHECKED).length) {
            newState.find((i) => i.id === parent.id).state = CheckboxState.UNCHECKED
            // In any other case, there are children with checked states and other children with unChecked state.
            // If so, set parent state as indeterminate
        } else {
            newState.find((i) => i.id === parent.id).state = CheckboxState.INDETERMINATE
        }
        // Use recursive behavior since the parent may have its own parent too, etc.
        updateParent(parent.id, newState)
    }

    const setChecked = (clickedItemId, newState) => {
        // Set checkbox state as checked
        newState.find((i) => i.id === clickedItemId).state = CheckboxState.CHECKED
        // Filter initial items and get clicked item children
        // Map children and get an array of children ids
        // Loop over array of ids.
        // Foreach child id, call set checked again
        // i.e. When setChecked is called for first child, again it finds the state of the child
        // and sets it to checked etc.
        // The result of this recursive behavior is to check all child items of clicked item (parent item) since
        // the parent itself was checked.
        items
            .filter((i) => i.parentId === clickedItemId)
            .map((i) => i.id)
            .forEach((childId) => setChecked(childId, newState))
        // If clicked item has a parent, update the state of its parent too
        updateParent(clickedItemId, newState)
    }

    const setUnchecked = (clickedItemId, newState) => {
        // Set checkbox state as unChecked
        newState.find((i) => i.id === clickedItemId).state = CheckboxState.UNCHECKED
        // Filter initial items and get clicked item children
        // Map children and get an array of children ids
        // Loop over array of ids.
        // Foreach child id, call set checked again
        // i.e. When setChecked is called for first child, again it finds the state of the child
        // and sets it to unChecked etc.
        // The result of this recursive behavior is to unCheck all child items of clicked item (parent item) since
        // the parent itself was unChecked.
        items
            .filter((i) => i.parentId === clickedItemId)
            .map((i) => i.id)
            .forEach((childId) => setUnchecked(childId, newState))
        // If clicked item has a parent, update the state of its parent too
        updateParent(clickedItemId, newState)
    }

    const clickHandler = useCallback((clickedItemId, item) => {
        // Get items state
        const newState = updateItemStates(itemStates, items, clickedItemId)
        // Use a callback for custom behavior when a checkbox is clicked
        if (onClickCallback) onClickCallback(clickedItemId, newState, item)
        // Update items states when a checkbox is clicked
        setItemStates(newState)
        setAllSelected(!(newState.reduce((previousValue, currentValue) => previousValue * currentValue.state, 1) === 0))
    }, [itemStates])

    const handleSelectAll = (selectAll) => {
        setAllSelected(selectAll)
        const newState = itemStates.map( item => ({...item, state: selectAll ? 1 : 0}))
        setItemStates(newState)
        onClickCallback(null, newState)
    }

    return {
        getItemState,
        clickHandler,
        allSelected,
        handleSelectAll
    }
}