// @flow
import React, {useEffect, useMemo} from 'react';
import Box from "@mui/material/Box";
import useExhibitionData from "../../../../hooks/exhibitions/useExhibitionData";
import * as yup from "yup";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION, GENERAL_SETTINGS} from "../../../../locales/components/namespaces";
import {Checkbox, FormControl, FormHelperText, ListItem, TextField} from "@mui/material";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useExhibitionAction from "../../../../hooks/exhibitions/useExhibitionAction";
import {formatDate} from "../../../../helpers/date";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import _ from "lodash";
import {showOption} from "../../../../helpers/helpers";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import useUserData from "../../../../hooks/user/useUserData";
import {BarChartOutlined} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";
import {useReportsAction} from "../../../../hooks/reports/useReportsAction";
import {useReportsData} from "../../../../hooks/reports/useReportsData";
import ReportGrids from "../../../exhibition/partials/organizer/reports/fastReports/partials/reportGrids";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../../helpers/constants";

type Props = {};

const GeneralReports = (props: Props) => {
	const {language} = useUserData()
	const {t} = useTranslation(GENERAL_SETTINGS)
	const {getExhibitions} = useExhibitionAction()
	const {indexTable: exhibitions} = useExhibitionData()
	const {getReports, setReportMessage} = useReportsAction()
	const {loading, reports, message} = useReportsData()
	
	useEffect(() => {
		getExhibitions()
	}, [getExhibitions])
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setReportMessage(null)
		}
	}, [message, setReportMessage, t])
	
	const validationSchema = yup.object({
		exhibitionIds: yup
		.array()
		.min(1, t("select at least one exhibition"))
		.required(t("select at least one exhibition"))
	})
	
	const onSubmit = (values) => {
		const exhibitionIds = values.exhibitionIds.map(e => e.id)
		getReports({ids: exhibitionIds, reportId: 3})
	}
	
	const formik = useFormik({
		initialValues: {
			exhibitionIds: []
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	const mappedExhibitions = useMemo(() => (
		exhibitions?.items.map(exhibition => ({
			id: exhibition.id,
			coorganizationId: exhibition.coorganizationId,
			description: `${exhibition.title} ${formatDate(exhibition.dateFrom, formatDate.formatTypes.DATE_ONLY)} - ${formatDate(exhibition.dateTo, formatDate.formatTypes.DATE_ONLY)}`,
			dateFrom: exhibition.dateFrom
		}))
	), [exhibitions?.items])
	
	return (
		<React.Fragment>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('General Reports')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Box sx={{maxWidth: 'calc(100vw - 120px)'}}>
				<Paper sx={{mb: 1}} variant={"outlined"}>
					<List>
						<ListItem component={"div"}>
							<ListItemText
								primary={
									<Typography component={"div"} variant={"body1"}>
										<Trans
											i18nKey={'reports_general_statistics'}
											ns={EXHIBITION}
										>
											On this page you can have an overview of <strong>multiple exhibition
											statistics</strong>.
											<br/>
											Select at least one exhibition to get reports.
										</Trans>
									</Typography>
								}
							/>
						</ListItem>
					</List>
				</Paper>
				<FormikProvider value={formik}>
					{mappedExhibitions && mappedExhibitions.length > 0 ? (
						<Box component="form" onSubmit={formik.handleSubmit}>
							<Stack
								direction={'column'}
								justifyContent={'space-around'}
								p={1}
								width={'100%'}
							>
								<Typography
									mt={1}
									mb={1}
									variant="body"
									color={'textSecondary'}
								>
									{t('Select one or more exhibitions')}
								</Typography>
								<FormControl
									sx={{mb: 1}}
									variant="standard"
									fullWidth
									error={
										formik.touched["exhibitionIds"] &&
										Boolean(formik.errors["exhibitionIds"])
									}
								>
									<Autocomplete
										multiple
										options={_.orderBy(mappedExhibitions, 'dateFrom', 'desc')}
										value={formik.values.exhibitionIds}
										isOptionEqualToValue={(opt, val) => {
											return opt.id === val.id || !val
										}}
										getOptionLabel={showOption(language)}
										selectOnFocus
										clearOnBlur
										disableCloseOnSelect
										onChange={(evt, newValue, reason, details) => {
											formik.setFieldValue('exhibitionIds', newValue)
										}}
										autoHighlight={true}
										size="small"
										fullWidth
										renderOption={(props, option, {selected}) => (
											<li {...props}>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
													checkedIcon={<CheckBoxIcon fontSize="small"/>}
													style={{marginRight: 8}}
													checked={selected}
												/>
												{showOption(language)(option)}
											</li>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												inputProps={{
													...params.inputProps,
													autoComplete: 'none',
												}}
												label={t("Select Exhibitions")}
											/>
										)}
									/>
									<FormHelperText>
										<ErrorMessage name={"exhibitionIds"}/>
									</FormHelperText>
								</FormControl>
								<LoadingButton
									size={"small"}
									startIcon={<BarChartOutlined/>}
									variant="contained"
									color="success"
									type={'submit'}
									loading={loading}
									loadingPosition="start"
								>
									{normalizeGreek(t('Get Reports'))}
								</LoadingButton>
							</Stack>
						</Box>
					) : (
						<ContentLoader
							loading={exhibitions?.loading}
						/>
					)}
				</FormikProvider>
				<ReportGrids
					reports={reports}
					loading={loading}
					isCoorganization={true}
					generalReport={true}
				/>
			</Box>
		</React.Fragment>
	)
};

export default withPermissionHOC(GeneralReports, [Permissions.SeeReports])