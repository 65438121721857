import React from 'react';
import {useDrop as dndUseDrop} from 'react-dnd';

import itemContext from './itemContext';

export default function useDrop(spec) {
    const connectedDropRef = React.useRef();
    const wasHoveredRef = React.useRef(false);
    const { id, type, onHoverBegin, onHoverEnd } = React.useContext(itemContext);
    const { ...rest } = spec || {};
    const [collectedProps, originalConnectDropTarget] = dndUseDrop({
        ...rest,
        accept: type,
        collect: (monitor) => {
            const hovered = monitor.isOver({ shallow: true });
            return {
                hovered,
                ...(spec && spec.collect ? spec.collect(monitor) : {})
            };
        },
    });

    React.useEffect(() => {
        if (collectedProps.hovered) {
            onHoverBegin(id, connectedDropRef);
            wasHoveredRef.current = true;
        } else if (wasHoveredRef.current === true) {
            onHoverEnd(id);
        }
    }, [id, collectedProps.hovered, onHoverBegin, onHoverEnd]);

    const connectDropTarget = (...args) => {
        const result = originalConnectDropTarget(...args);
        connectedDropRef.current = result;
        return result;
    };

    return [collectedProps, connectDropTarget];
}
