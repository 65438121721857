// @flow
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FormikProvider} from "formik";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import useExhibitionAction from "../../../hooks/exhibitions/useExhibitionAction";
import ActionStatus from "../alerts/actionStatus/actionStatus";
import useExhibitionData from "../../../hooks/exhibitions/useExhibitionData";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import FormFields from "./partials/formFields";
import useAddVisitorForm from "./hooks/useAddVisitorForm";
import LoadingButton from "@mui/lab/LoadingButton";

type Props = {
    limitedView?: boolean,
    exhibitionId: string,
    open: boolean,
    onClose: Function,
}

export default function AddVisitorDialog(props: Props) {
    const {open, onClose, exhibitionId, exhibitorId, limitedView, exhibition, loading} = props
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {actionOnVisitorStatus} = useExhibitionData()
    const {createVisitor, clearActionOnVisitorStatus} = useExhibitionAction()
    const {formik} = useAddVisitorForm({exhibition, limitedView, exhibitionId, exhibitorId, createVisitor})
    
    const closeDialogue = () => {
        formik.resetForm();
        onClose();
    }
    return (
        <Dialog open={open} onClose={closeDialogue}>
            <FormikProvider value={formik}>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                    <DialogTitle>{t('Add a visitor')}</DialogTitle>
                    <ActionStatus
                        status={actionOnVisitorStatus}
                        onClose={clearActionOnVisitorStatus}
                    />
                    <DialogContent>
                        <DialogContentText sx={{pb: 2}}>
                            {t('Type contact information of the person you want to add as a visitor for this event.')}
                        </DialogContentText>
                        <FormFields
                            formik={formik}
                            limitedView={limitedView}
                            exhibitionId={exhibitionId}
                            exhibition={exhibition}
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={loading}
                            variant={"contained"}
                            type={'submit'}
                        >OK</LoadingButton>
                        <Button onClick={closeDialogue}>{normalizeGreek(t('Cancel'))}</Button>
                    </DialogActions>
                </Box>
            </FormikProvider>
        </Dialog>
    )
}
