// @flow 
import React, {useMemo} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type Props = {};

const CodeSelect = (props: Props) => {
	const {codes, code, onChangeCode, label} = props
	
	const selectItems = useMemo(() => {
		return codes.map(code => (
			<MenuItem
				key={code.id}
				value={code.id}
			>
				{code.value}
			</MenuItem>
		))
	}, [codes])
	
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			alignItems: 'center',
			padding: '10px 4px',
			width: '100%',
			backgroundColor: 'rgba(140, 158, 255, 0.08)'
		}}>
			<Typography variant="h6" sx={{paddingRight: 2}}>
				{label}
			</Typography>
			<Select
				labelId={'codeLabel'}
				variant={'standard'}
				value={code}
				onChange={(evt) => onChangeCode(evt)}
			>
				{selectItems}
			</Select>
		</Box>
	);
};

export default CodeSelect