import {Exhibitor as ExhibitorTemplate} from "./exhibitor";
import {OrganizerShort as ShortOrganizerTemplate} from "./organizerShort";
import {Organizer as OrganizerTemplate} from "./organizer";
import React from "react";

const ExcelTemplate = (props) => {
    const {isExhibitorTemplate, limitedView, visitorCategoryRequiredInImport, isShortOrganizerImport} = props
    if (isExhibitorTemplate) {
        return (
            <ExhibitorTemplate
                limitedView={limitedView}
                visitorCategoryRequiredInImport={visitorCategoryRequiredInImport}
            />
        )
    }
    return isShortOrganizerImport ? (
        <ShortOrganizerTemplate
            limitedView={limitedView}
            visitorCategoryRequiredInImport={visitorCategoryRequiredInImport}
        />
    ) : (
        <OrganizerTemplate
            limitedView={limitedView}
            visitorCategoryRequiredInImport={visitorCategoryRequiredInImport}
        />
    )
}

export default ExcelTemplate