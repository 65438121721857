import * as ActionTypes from '../../actions/types'
import {mapCoorganizations} from "../../types/coorganizations/map";
import {mapMultiple} from "../../helpers/map";
import {fromJS} from "immutable";

const INITIAL_STATE = {
    loading: false,
    byId: {},
    status: null,
    updateGridSource: false,
    updateExhibitionList: false
}

function getCoorganizations(state) {
    return state
        .set('loading', true)
}

function getCoorganizationsSucceeded(state, action) {
    const {payload} = action
    const mappedData = mapMultiple(payload, mapCoorganizations)
    return state
        .set('loading', false)
        .set('byId', fromJS(mappedData))
}

function getCoorganizationsFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Fetching coorganizations failed.',
            type: 'error'
        }))
}


function saveCoorganization(state) {
    return state
        .set('loading', true)
}

function saveCoorganizationSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Saving coorganization succeeded.',
            type: 'success'
        }))
        .set('updateGridSource', !state.get('updateGridSource'))
}

function saveCoorganizationFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Storing coorganization failed.',
            type: 'error'
        }))
        .set('updateGridSource', !state.get('updateGridSource'))
}

function updateCoorganization(state) {
    return state
        .set('loading', true)
}

function updateCoorganizationSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Updating coorganization succeeded.',
            type: 'success'
        }))
        .set('updateGridSource', !state.get('updateGridSource'))
}

function updateCoorganizationFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Updating coorganization failed.',
            type: 'error'
        }))
        .set('updateGridSource', !state.get('updateGridSource'))
}

function deleteCoorganization(state) {
    return state
        .set('loading', true)
}

function deleteCoorganizationSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Deleting coorganization succeeded.',
            type: 'success'
        }))
        .set('updateGridSource', !state.get('updateGridSource'))
}

function deleteCoorganizationFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Deleting coorganization failed.',
            type: 'error'
        }))
        .set('updateGridSource', !state.get('updateGridSource'))
}

function clearCoorganizationStatus(state, action) {
    return state
        .set('status', null)
}

function addExhibitionToCoorganization(state, action) {
    return state
        .set('loading', true)
}

function addExhibitionToCoorganizationSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Exhibition Added To Coorganization Successfully.',
            type: 'success'
        }))
        .set('updateExhibitionList', !state.get('updateExhibitionList'))
}

function addExhibitionToCoorganizationFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Failed to Add Exhibition To Coorganization.',
            type: 'error'
        }))
        .set('updateExhibitionList', !state.get('updateExhibitionList'))
}

function removeExhibitionFromCoorganization(state, action) {
    return state
        .set('loading', true)
}

function removeExhibitionFromCoorganizationSucceeded(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Exhibition Removed From Coorganization Successfully.',
            type: 'success'
        }))
        .set('updateExhibitionList', !state.get('updateExhibitionList'))
}

function removeExhibitionFromCoorganizationFailed(state, action) {
    return state
        .set('loading', false)
        .set('status', fromJS({
            message: 'Failed to Remove Exhibition From Coorganization.',
            type: 'error'
        }))
        .set('updateExhibitionList', !state.get('updateExhibitionList'))
}

const coorganizations = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_COORGANIZATIONS]: getCoorganizations,
        [ActionTypes.GET_COORGANIZATIONS_SUCCEEDED]: getCoorganizationsSucceeded,
        [ActionTypes.GET_COORGANIZATIONS_FAILED]: getCoorganizationsFailed,
        [ActionTypes.SAVE_COORGANIZATION]: saveCoorganization,
        [ActionTypes.SAVE_COORGANIZATION_SUCCEEDED]: saveCoorganizationSucceeded,
        [ActionTypes.SAVE_COORGANIZATION_FAILED]: saveCoorganizationFailed,
        [ActionTypes.UPDATE_COORGANIZATION]: updateCoorganization,
        [ActionTypes.UPDATE_COORGANIZATION_SUCCEEDED]: updateCoorganizationSucceeded,
        [ActionTypes.UPDATE_COORGANIZATION_FAILED]: updateCoorganizationFailed,
        [ActionTypes.DELETE_COORGANIZATION]: deleteCoorganization,
        [ActionTypes.DELETE_COORGANIZATION_SUCCEEDED]: deleteCoorganizationSucceeded,
        [ActionTypes.DELETE_COORGANIZATION_FAILED]: deleteCoorganizationFailed,
        [ActionTypes.CLEAR_COORGANIZATION_STATUS]: clearCoorganizationStatus,
        [ActionTypes.ADD_EXHIBITION_TO_COORGANIZATION]: addExhibitionToCoorganization,
        [ActionTypes.ADD_EXHIBITION_TO_COORGANIZATION_SUCCEEDED]: addExhibitionToCoorganizationSucceeded,
        [ActionTypes.ADD_EXHIBITION_TO_COORGANIZATION_FAILED]: addExhibitionToCoorganizationFailed,
        [ActionTypes.REMOVE_EXHIBITION_FROM_COORGANIZATION]: removeExhibitionFromCoorganization,
        [ActionTypes.REMOVE_EXHIBITION_FROM_COORGANIZATION_SUCCEEDED]: removeExhibitionFromCoorganizationSucceeded,
        [ActionTypes.REMOVE_EXHIBITION_FROM_COORGANIZATION_FAILED]: removeExhibitionFromCoorganizationFailed,
    }
}

export default coorganizations
