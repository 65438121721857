import {Coorganization, UnmappedCoorganization} from "./types";

export const mapCoorganizations: Coorganization = (datum: UnmappedCoorganization) => {
    if (!datum) return
    return {
        description: {
            en: datum.DescriptionEN ?? '',
            el: datum.DescriptionEL ?? '',
        },
        id: datum.id ?? '',
        descriptionEn: datum.DescriptionEN ?? '',
        descriptionEl: datum.DescriptionEL ?? '',
    }
}