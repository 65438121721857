//@flow
import React from 'react';
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_PROFILE_EXHIBITOR} from "../../../../../routers/routes";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import type {Visitor} from "../../../../../types/exhibition/types";
import VisitorReferences from "./visitorReferences";

type Props = {
    row: Visitor,
    exhibitionId: string
}

const ExhibitorsCell = (props: Props) => {
    const {row, exhibitionId} = props
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handlePopoverClose = () => {
        setAnchorEl(null);
    }
    const open = Boolean(anchorEl)
    if (!row.InvitedBy.id) return "-"
    return (
        <React.Fragment>
            <Link
                to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
                    id: exhibitionId,
                    exhibitorId: row.InvitedBy.id
                })}
            >
                {row.InvitedBy.name}
            </Link>
            {row.InvitedBy.totalInvitations && row.InvitedBy.totalInvitations > 1 && (
                <Box onClick={(e) => e.stopPropagation()}>
                    <Popover
                        disableRestoreFocus
                        onClose={handlePopoverClose}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <VisitorReferences
                            exhibitionId={exhibitionId}
                            id={row.id}
                            circularLoader
                        />
                    </Popover>
                    <Button
                        onClick={handlePopoverOpen}
                        size={"small"}
                        color={"secondary"}
                    >
                        + {row.InvitedBy.totalInvitations - 1}&nbsp;&nbsp;
                    </Button>
                </Box>
            )}
        </React.Fragment>
    )
}

export default ExhibitorsCell;