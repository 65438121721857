//@flow
import React from 'react';
import MUITextfield from "@mui/material/TextField";
import {ErrorMessage} from "formik";

const TextInput = ({
                       field, // { name, value, onChange, onBlur }
                       form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                       applyTransformation,
                       ...props
                   }) => {
    const helperText = (touched.fieldValues && touched.fieldValues[field.name.split('.')[1]] ? touched.fieldValues[field.name.split('.')[1]] : null) && (errors.fieldValues && errors.fieldValues[field.name.split('.')[1]] ? errors.fieldValues[field.name.split('.')[1]] : null);

    return <MUITextfield
        {...props}
        type={field.type}
        fullWidth
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
        onChange={(e) => {
            if (applyTransformation) {
                const oldValue = e.target.value
                e.target.value = applyTransformation(oldValue)
            }
            field.onChange(e)
        }}
        error={helperText && Boolean(helperText)}
        helperText={<ErrorMessage name={field.name}/>}
    />
}

export default TextInput


