import {actionCreator} from "../../helpers/actionCreator";
import * as ActionTypes from "./types";

export const getExhibitionFiles = actionCreator(ActionTypes.GET_EXHIBITION_FILES)
export const getExhibitionFilesSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_FILES_SUCCEEDED)
export const getExhibitionFilesFailed = actionCreator(ActionTypes.GET_EXHIBITION_FILES_FAILED)

export const getExhibitionFileDetails = actionCreator(ActionTypes.GET_EXHIBITION_FILE_DETAILS)
export const getExhibitionFileDetailsSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_FILE_DETAILS_SUCCEEDED)
export const getExhibitionFileDetailsFailed = actionCreator(ActionTypes.GET_EXHIBITION_FILE_DETAILS_FAILED)

export const createExhibitionFile = actionCreator(ActionTypes.CREATE_EXHIBITION_FILE)
export const createExhibitionFileSucceeded = actionCreator(ActionTypes.CREATE_EXHIBITION_FILE_SUCCEEDED)
export const createExhibitionFileFailed = actionCreator(ActionTypes.CREATE_EXHIBITION_FILE_FAILED)

export const updateExhibitionFile = actionCreator(ActionTypes.UPDATE_EXHIBITION_FILE)
export const updateExhibitionFileSucceeded = actionCreator(ActionTypes.UPDATE_EXHIBITION_FILE_SUCCEEDED)
export const updateExhibitionFileFailed = actionCreator(ActionTypes.UPDATE_EXHIBITION_FILE_FAILED)
export const reorderExhibitionFiles = actionCreator(ActionTypes.REORDER_EXHIBITION_FILES)

export const deleteExhibitionFile = actionCreator(ActionTypes.DELETE_EXHIBITION_FILE)
export const deleteExhibitionFileSucceeded = actionCreator(ActionTypes.DELETE_EXHIBITION_FILE_SUCCEEDED)
export const deleteExhibitionFileFailed = actionCreator(ActionTypes.DELETE_EXHIBITION_FILE_FAILED)

export const getExhibitionImages = actionCreator(ActionTypes.GET_EXHIBITION_IMAGES)
export const getExhibitionImagesSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_IMAGES_SUCCEEDED)
export const getExhibitionImagesFailed = actionCreator(ActionTypes.GET_EXHIBITION_IMAGES_FAILED)

export const createExhibitionImage = actionCreator(ActionTypes.CREATE_EXHIBITION_IMAGE)
export const createExhibitionImageSucceeded = actionCreator(ActionTypes.CREATE_EXHIBITION_IMAGE_SUCCEEDED)
export const createExhibitionImageFailed = actionCreator(ActionTypes.CREATE_EXHIBITION_IMAGE_FAILED)

export const deleteExhibitionImage = actionCreator(ActionTypes.DELETE_EXHIBITION_IMAGE)
export const deleteExhibitionImageSucceeded = actionCreator(ActionTypes.DELETE_EXHIBITION_IMAGE_SUCCEEDED)
export const deleteExhibitionImageFailed = actionCreator(ActionTypes.DELETE_EXHIBITION_IMAGE_FAILED)

export const setFileMessage = actionCreator(ActionTypes.SET_FILE_MESSAGE)