//@flow
import React, {Fragment, useContext, useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {useParams} from "react-router";
import {ListItem, Tab, Tabs} from "@mui/material";
import {TabPanel} from "../../components/general/tabPanel/tabPanel";
import Visitors from "../exhibition/partials/common/visitors/visitors";
import Partners from "../exhibition/partials/common/partners/partners";
import Catalog from "./partials/catalog";
import ExhibitionUpdateStatus from "../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import {TitleContext} from "../main/content";
import {normalizeGreek} from "../../helpers/normalizeGreek";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import Box from "@mui/material/Box";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../locales/components/namespaces";
import {PartnerTypes, Permissions} from "../../helpers/constants";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";
import {useExhibitor} from "../../hooks/exhibitions/useExhibitor";
import useQueryParams from "../../hooks/useQueryParams";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_HOME, ROUTE_PAGE_PROFILE_EXHIBITOR} from "../../routers/routes";
import {useExhibition} from "../../hooks/exhibitions/useExhibition";
import Breadcrumb from "../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import type {Exhibitor} from "../../types/exhibition/types";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import ExhibitorKit from "../exhibition/partials/exhibitor/exhibitorKit/exhibitorKit";
import CustomTranslation from "../../components/general/customTranslation/customTranslation";

type Props = {
	exhibitor: Exhibitor,
	propsId: string,
	exhibitionId: string
}

function InfoIcon() {
	return null;
}

const InnerContent = (props: Props) => {
	const {exhibitor, propsId, exhibitionId, loading, section} = props
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	const {setTitle, setToolbar} = useContext(TitleContext)
	const {exhibition} = useExhibition({noFetchFiles: true})
	const isOrganizer = useHasPermission([Permissions.BeOrganizer], {shouldView: true})
	useEffect(() => {
		if (!propsId && isOrganizer) {
			setTitle(
				<React.Fragment>
					<Typography variant="h6" component="div" sx={{mr: 1}}>
						{exhibitor?.name}
					</Typography>
				</React.Fragment>
			)
		}
		return () => {
			if (isOrganizer) {
				setTitle(null)
			}
		}
	}, [exhibitor?.name, isOrganizer, propsId, setTitle])
	useEffect(() => {
		if (isOrganizer) {
			setToolbar(
				<Tabs
					variant="scrollable"
					value={section}
					indicatorColor="primary"
					textColor="inherit"
				>
					<Tab
						component={Link}
						value={""}
						to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
							id: exhibitionId,
							exhibitorId: exhibitor.id
						}, {section: "profile"})}
						label={normalizeGreek(t("Partners"))}
					/>
					<Tab
						component={Link}
						value={"serviceProviders"}
						to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {id: exhibitionId, exhibitorId: exhibitor.id}, {
							section: "profile",
							subSection: "serviceProviders"
						})}
						label={normalizeGreek(t("Service Providers"))}
					/>
					<Tab
						component={Link}
						value={"exhibitionStaff"}
						to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
							id: exhibitionId,
							exhibitorId: exhibitor.id
						}, {
							section: "profile",
							subSection: "exhibitionStaff"
						})}
						label={normalizeGreek(t("Exhibition Staff"))}
					/>
					<Tab
						component={Link}
						value={"organizers"}
						to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
							id: exhibitionId,
							exhibitorId: exhibitor.id
						}, {
							section: "profile",
							subSection: "organizers"
						})}
						label={normalizeGreek(t("Organizers"))}
					/>
					<Tab
						component={Link}
						value={"visitors"}
						to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {id: exhibitionId, exhibitorId: exhibitor.id}, {
							section: "profile",
							subSection: "visitors"
						})}
						label={normalizeGreek(t("Visitors"))}
					/>
					<Tab
						component={Link}
						value={"catalog"}
						to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {id: exhibitionId, exhibitorId: exhibitor.id}, {
							section: "profile",
							subSection: "catalog"
						})}
						label={normalizeGreek(t("Catalogue"))}
					/>
					<Tab
						component={Link}
						value={"kit"}
						to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {id: exhibitionId, exhibitorId: exhibitor.id}, {
							section: "profile",
							subSection: "kit"
						})}
						label={normalizeGreek(t("Marketing Kit"))}
					/>
					{/*<Tab*/}
					{/*    component={Link}*/}
					{/*    value={"misc"}*/}
					{/*    to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {id: exhibitionId, exhibitorId: exhibitor.id}, {*/}
					{/*        section: "profile",*/}
					{/*        subSection: "misc"*/}
					{/*    })}*/}
					{/*    label={normalizeGreek(t("Misc Information"))}*/}
					{/*/>*/}
				</Tabs>
			)
		}
		return () => {
			if (isOrganizer) {
				setToolbar(null)
			}
		}
	}, [isOrganizer, setToolbar, t, section, exhibitionId, propsId, exhibitor.id])
	return (
		<Box sx={{display: 'flex'}}>
			<Box sx={{flexGrow: 1, pl: 2}}>
				{exhibition && isOrganizer && (
					<Breadcrumb>
						<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
							{t('Home')}
						</Breadcrumb.Link>
						<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
							{exhibition.title}
						</Breadcrumb.Link>
						<Breadcrumb.Link
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id}, {section: "exhibitors"})}>
							{t('Exhibitors')}
						</Breadcrumb.Link>
						<Breadcrumb.Item notFocused>
							{exhibitor.name}
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							{t(
								section === "" ? "Partners" :
									section === "serviceProviders" ? "Service Providers" :
										section === "exhibitionStaff" ? "Exhibition Staff" :
											section === "organizers" ? "Organizers" :
												section === "visitors" ? "Visitors" :
													section === "catalog" ? "Catalogue" :
														section === "kit" ? "Marketing Kit" :
															section === "misc" ? "Misc Information" : ""
							)}
						</Breadcrumb.Item>
					</Breadcrumb>
				)}
				<TabPanel isVisible={section === ""}>
					<Paper sx={{mb: 1}} variant={"outlined"}>
						<List>
							<ListItem component={"div"}>
								<ListItemText
									primary={
										<CustomTranslation
											code={'exhibitor_partners'}
											translations={exhibition?.translations}
										>
											<Typography component={"div"} variant={"body1"}>
												<Trans
													i18nKey={'badge_info'}
													ns={EXHIBITOR_PROFILE}
												>
													On this page you can print the entry cards for you and your
													staff.<br/>
													Press the <strong>ADD PARTNER</strong> button or the <strong>IMPORT
													FROM
													EXCEL</strong> button.<br/><br/>
													<strong>Attention:</strong> For your smooth entrance to the
													exhibition,
													it is necessary to have the entrance card printed.<br/><br/> We
													would
													like to
													inform you that your Entrance card also serves as a <strong>Parking
													Pass</strong> in the Exhibition Centre parking areas.
												</Trans>
											</Typography>
										</CustomTranslation>
									}
								/>
							</ListItem>
						</List>
					</Paper>
					<Partners
						type={PartnerTypes.Partner}
						hideInvitedBy={!isOrganizer}
						exhibitorId={exhibitor.id}
						exhibitionId={exhibitionId}
						partners={exhibitor.partners.filter(f => Number(f.type) === PartnerTypes.Partner)}
					/>
				</TabPanel>
				<TabPanel isVisible={section === "serviceProviders"}>
					<Paper sx={{mb: 1}} variant={"outlined"}>
						<List>
							<ListItem component={"div"}>
								<ListItemText
									primary={
										<CustomTranslation
											code={'exhibitor_serviceProviders'}
											translations={exhibition?.translations}
										>
											<Typography component={"div"} variant={"body1"}>
												<Trans
													i18nKey={'badge_info_service_provider'}
													ns={EXHIBITOR_PROFILE}
												>
													On this page you can print the entry cards for your service
													providers.<br/>
													Press the <strong>ADD SERVICE PROVIDER</strong> button or
													the <strong>IMPORT
													FROM
													EXCEL</strong> button.<br/><br/>
													<strong>Attention:</strong> For your smooth entrance to the
													exhibition,
													it is necessary to have the entrance card with you.<br/>
												</Trans>
											</Typography>
										</CustomTranslation>
									}
								/>
							</ListItem>
						</List>
					</Paper>
					<Partners
						type={PartnerTypes.ServiceProvider}
						hideInvitedBy={!isOrganizer}
						exhibitorId={exhibitor.id}
						exhibitionId={exhibitionId}
						partners={exhibitor.partners.filter(f => Number(f.type) === PartnerTypes.ServiceProvider)}
					/>
				</TabPanel>
				<PermissionHOC requiredPermissions={[Permissions.BeOrganizer]}>
					<TabPanel isVisible={section === "exhibitionStaff"}>
						<Paper sx={{mb: 1}} variant={"outlined"}>
							<List>
								<ListItem component={"div"}>
									<ListItemText
										primary={
											<Typography component={"div"} variant={"body1"}>
												<Trans
													i18nKey={'badge_info_exhibition_staff'}
													ns={EXHIBITOR_PROFILE}
												>
													On this page you can print the entry cards for general exhibition
													staff.<br/>
													Press the <strong>ADD EXHIBITION STAFF</strong> button or
													the <strong>IMPORT FROM
													EXCEL</strong> button.<br/>
												</Trans>
											</Typography>
										}
									/>
								</ListItem>
							</List>
						</Paper>
						<Partners
							type={PartnerTypes.ExhibitionStaff}
							hideInvitedBy={!isOrganizer}
							exhibitorId={exhibitor.id}
							exhibitionId={exhibitionId}
							partners={exhibitor.partners.filter(f => Number(f.type) === PartnerTypes.ExhibitionStaff)}
						/>
					</TabPanel>
					<TabPanel isVisible={section === "organizers"}>
						<Paper sx={{mb: 1}} variant={"outlined"}>
							<List>
								<ListItem component={"div"}>
									<ListItemText
										primary={
											<Typography component={"div"} variant={"body1"}>
												<Trans
													i18nKey={'badge_info_organizer'}
													ns={EXHIBITOR_PROFILE}
												>
													On this page you can print the entry cards for general exhibition
													staff.<br/>
													Press the <strong>ADD ORGANIZER</strong> button or
													the <strong>IMPORT FROM
													EXCEL</strong> button.<br/>
												</Trans>
											</Typography>
										}
									/>
								</ListItem>
							</List>
						</Paper>
						<Partners
							type={PartnerTypes.Organizer}
							hideInvitedBy={!isOrganizer}
							exhibitorId={exhibitor.id}
							exhibitionId={exhibitionId}
							partners={exhibitor.partners.filter(f => Number(f.type) === PartnerTypes.Organizer)}
						/>
					</TabPanel>
				</PermissionHOC>
				<TabPanel isVisible={section === "visitors"}>
					<Box sx={{maxWidth: '80vw'}}>
						<Paper sx={{mb: 1}} variant={"outlined"}>
							<List>
								<ListItem component={"div"}>
									<ListItemText
										primary={
											<CustomTranslation
												code={'exhibitor_visitors'}
												translations={exhibition?.translations}
											>
												<Typography component={"div"} variant={"body1"}>
													<Trans
														i18nKey={'invitation_info'}
														ns={EXHIBITOR_PROFILE}
													>
														On this page you can send exhibition invitations by
														email. <br/><br/>
														Press <strong>ADD A VISITOR</strong> or <strong>IMPORT FROM
														EXCEL.</strong>
														<br/>
														After adding one or more visitors, press <strong>SEND INVITATION
														(NEW)</strong>, to send invitations by email to the newly added
														visitors.
														<br/>
														Alternatively, select specific visitors from the grid and
														press <strong>SEND
														INVITATION TO VISITORS</strong>, to send email invitations to
														selected
														visitors.
														<br/>
														<br/>
														<strong>Attention:</strong> Invitation concerns only commercial
														visitors..
													</Trans>
												</Typography>
											</CustomTranslation>
										}
									/>
								</ListItem>
							</List>
						</Paper>
						<Visitors
							limitedView={!isOrganizer}
							exhibitorId={exhibitor.id}
							exhibitionId={exhibitionId}
							visitors={exhibitor.visitors}
						/>
					</Box>
					{/*</Grid>*/}
					{/*<Grid item xs={12} md={4}>*/}
					{/*    <Paper sx={{px: 2, pb: 3}} variant={"outlined"}>*/}
					{/*        <ExhibitorFileUpload*/}
					{/*            exhibitorId={exhibitor.id}*/}
					{/*        />*/}
					{/*    </Paper>*/}
					{/*</Grid>*/}
					{/*</Grid>*/}
				</TabPanel>
				<TabPanel isVisible={section === "catalog"}>
					<Catalog
						exhibitionId={exhibitionId}
						exhibitor={exhibitor}
						loading={loading}
					/>
				</TabPanel>
				<TabPanel isVisible={section === "kit"}>
					<Box sx={{my: 1.5}}/>
					<ExhibitorKit
						exhibitor={exhibitor}
					/>
				</TabPanel>
			</Box>
			<ExhibitionUpdateStatus/>
		</Box>
	)
}

const ExhibitorProfile = ({id: propsId}) => {
	const {id: exhibitionId} = useParams()
	const queryParams = useQueryParams()
	const section = queryParams.get('subSection') ?? ""
	const {exhibitor, loading} = useExhibitor({
		language: 'any',
		overrideExhibitorId: propsId,
		section: section
	})
	return (
		<Fragment>
			{exhibitor ? (
				<InnerContent
					section={section}
					propsId={propsId}
					exhibitionId={exhibitionId}
					exhibitor={exhibitor}
					loading={loading}
				/>
			) : (
				<ContentLoader
					loading={loading}
				/>
			)}
		</Fragment>
	)
}

export default ExhibitorProfile
