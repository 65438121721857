// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";

export const useCoorganizationsAction = () => {
    const dispatch = useDispatch()
    const getCoorganizations = useCallback(() => {
        dispatch(actions.getCoorganizations())
    }, [dispatch])

    const saveCoorganization = useCallback((values) => {
        dispatch(actions.saveCoorganization({
            DescriptionEN: values.descriptionEn,
            DescriptionEL: values.descriptionEl
        }))
    }, [dispatch])

    const updateCoorganization = useCallback((values) => {
        dispatch(actions.updateCooranization({
            id: values.id,
            DescriptionEN: values.descriptionEn,
            DescriptionEL: values.descriptionEl
        }))
    }, [dispatch])

    const deleteCoorganization = useCallback((id) => {
        dispatch(actions.deleteCoorganization({
            id: id
        }))
    }, [dispatch])

    const clearCoorganizationStatus = useCallback(() => {
        dispatch(actions.clearCoorganizationStatus())
    }, [dispatch])

    const addExhibitionToCoorganization = useCallback((exhibitionId, coorganizationId) => {
        dispatch(actions.addExhibitionToCoorganization({
            exhibitionId: exhibitionId,
            coorganizationId: coorganizationId
        }))
    }, [dispatch])

    const removeExhibitionFromCoorganization = useCallback((exhibitionId) => {
        dispatch(actions.removeExhibitionFromCoorganization({
            exhibitionId: exhibitionId
        }))
    }, [dispatch])

    return {
        getCoorganizations,
        saveCoorganization,
        updateCoorganization,
        deleteCoorganization,
        clearCoorganizationStatus,
        addExhibitionToCoorganization,
        removeExhibitionFromCoorganization
    }
}