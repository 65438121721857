import React, {useState} from 'react'
import {ChevronRight, Description, Folder, FolderOpen} from '@mui/icons-material'

const Row = ({ item, level, children, onClick, hasNodeIcon }) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const icon = item.hasChildren ? (
        isCollapsed ? (
            <Folder className="tree-folder"/>
        ) : (
            <FolderOpen className="tree-folder_open"/>
        )
    ) : (
        <Description className="tree-file"/>
    )

    return (
        <div className={`tree-row-container`} key={`section-${item.id}-${item.description}`}>
            <div
                className={`tree-row level-${level}`}
                onClick={(evt) => {onClick(evt, item); setIsCollapsed(!isCollapsed)}}
            >
                {!item.hasChildren ? null : (
                    <ChevronRight
                        className={`tree-chevron ${!isCollapsed && 'tree-chevron_rotated'}`}
                    />
                )}
                {hasNodeIcon ? icon : null}
                <span className="text">{item.description}</span>
            </div>
            <div className={`tree-children ${!isCollapsed && 'tree-children_collapsed'}`}>{children}</div>
        </div>
    )
}

export default Row