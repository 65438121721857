//@flow
import React, {useMemo} from 'react';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import type {Exhibitor} from "../../../../../types/exhibition/types";
import DoneIcon from '@mui/icons-material/Done';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import IconButton from "@mui/material/IconButton";
import {ListSubheader, Tooltip} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Link as RouterLink} from "react-router-dom";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_PROFILE_EXHIBITOR} from "../../../../../routers/routes";
import Link from "@mui/material/Link";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip as GraphTooltip} from 'recharts';
import TransferList from "./transferList";

type Props = {
    pending: Array<Exhibitor>,
    exhibitionId: string
}

const data01 = [
    {name: 'Group A', value: 400},
    {name: 'Group B', value: 300},
    {name: 'Group C', value: 300},
    {name: 'Group D', value: 200},
];
const data02 = [
    {name: 'A1', value: 100},
    {name: 'A2', value: 300},
    {name: 'B1', value: 100},
    {name: 'B2', value: 80},
    {name: 'B3', value: 40},
    {name: 'B4', value: 30},
    {name: 'B5', value: 50},
    {name: 'C1', value: 100},
    {name: 'C2', value: 200},
    {name: 'D1', value: 150},
    {name: 'D2', value: 50},
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

const Exhibitors = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {pending, exhibitionId} = props
    const onApprove = () => {

    }
    const onReject = () => {

    }
    const pieData1 = useMemo(() => [
        {name: t('Eligible'), value: 100},
        {name: t('Not Eligible'), value: 20},
    ], [t])

    const pieData2 = useMemo(() => [
        {name: t('Not requested'), value: 20},
        {name: t('Request Pending'), value: 10},
        {name: t('Request Approved'), value: 40},
        {name: t('Request Rejected'), value: 30},
    ], [t])

    return (
        <Box>
            <Toolbar disableGutters variant={"dense"}>
                <Typography variant="h6">
                    {t('Exhibitors')}
                </Typography>
            </Toolbar>
            <Divider sx={{mb: 1}} light/>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{mb: 2}}>
                        <TransferList
                            options={[
                                {id: 1, label: 'name 1'},
                                {id: 2, label: 'name 2'},
                            ]}
                            selected={[2]}
                        />
                    </Box>
                    <Paper>
                        <List
                            dense
                            subheader={<ListSubheader>{t('Pending Approvals')}</ListSubheader>}
                        >
                            {pending.map(p => (
                                <ListItem key={p.id}>
                                    <ListItemText
                                        primary={
                                            <Link
                                                component={RouterLink}
                                                to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
                                                    id: exhibitionId,
                                                    exhibitorId: p.id
                                                })}
                                            >
                                                {p.name}
                                            </Link>
                                        }
                                    />
                                    <Tooltip title={t('Approve')}>
                                        <IconButton
                                            sx={{mr: 1.5}}
                                            color={"success"}
                                            size={"small"}
                                        >
                                            <DoneIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('Reject')}>
                                        <IconButton
                                            color={"error"}
                                            size={"small"}
                                        >
                                            <DoDisturbIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <ResponsiveContainer width="100%" aspect={16.0 / 9.0}>
                                <PieChart>
                                    <Legend
                                        layout="horizontal"
                                        verticalAlign="top"
                                        align="center"
                                    />
                                    <Pie
                                        data={pieData1}
                                        fill="#8884d8"
                                        dataKey="value"
                                        label={renderCustomizedLabel}
                                        labelLine={false}
                                    >
                                        <Cell
                                            fill={'#00C49F'}
                                        />
                                        <Cell
                                            fill={'#FF8042'}
                                        />
                                    </Pie>
                                    <GraphTooltip/>
                                </PieChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ResponsiveContainer width="100%" aspect={16.0 / 9.0}>
                                <PieChart>
                                    <Legend
                                        layout="horizontal"
                                        verticalAlign="top"
                                        align="center"
                                    />
                                    <Pie
                                        data={pieData2}
                                        fill="#8884d8"
                                        dataKey="value"
                                        label={renderCustomizedLabel}
                                        labelLine={false}
                                    >
                                        <Cell
                                            fill={'#00C49F'}
                                        />
                                        <Cell
                                            fill={'#FF8042'}
                                        />
                                        <Cell
                                            fill={'#FF8042'}
                                        />
                                        <Cell
                                            fill={'#FF8042'}
                                        />
                                        <Cell
                                            fill={'#FF8042'}
                                        />
                                        <Cell
                                            fill={'#FF8042'}
                                        />
                                    </Pie>
                                    <GraphTooltip/>
                                </PieChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Exhibitors;