import Home from "../pages/home/home";
import {routeCreator} from "../helpers/routeCreator";
import NotFound from "../pages/notFound/notFound";
import Exhibition from "../pages/exhibition/exhibition";
import Login from "../pages/login/login";

import ExhibitorProfile from "../pages/profileExhibitor/profileExhibitor";
import VisitorProfile from "../pages/profileVisitor/profileVisitor";
import ExhibitionSettings from "../pages/exhibitionSettings/exhibitionSettings";
import PreregistrationFields from "../pages/exhibitionSettings/pages/preregistrationFields/preregistrationFields";
import InvitationMail from "../pages/exhibitionSettings/pages/invitationMail/invitationMail";
import BadgeCustomize from "../pages/exhibitionSettings/pages/badgeCustomize/badgeCustomize";
import WelcomePage from "../pages/exhibitionSettings/pages/welcomePage/welcomePage";
import BusinessCategories from "../pages/exhibitionSettings/pages/businessCategories/businessCategories";

import B2B from "../pages/exhibitionSettings/pages/B2B/B2B";
import Messages from "../pages/exhibitionSettings/pages/messages/messages";
import Interests from "../pages/exhibitionSettings/pages/interests/interests";
import QuickBadgePrint from "../pages/exhibitionSettings/pages/quickBadgePrint/quickBadgePrint";
import ExhibitorKitAndLogos from "../pages/exhibitionSettings/pages/exhibitorKitAndLogos/exhibitorKitAndLogos";
import ExhibitorKit from "../pages/exhibition/partials/exhibitor/exhibitorKit/exhibitorKit";
import ImagesForMessages from "../pages/exhibitionSettings/pages/imagesForMessages/imagesForMessages";
import EntersoftTransfer from "../pages/generalSettings/partials/entersoftTransfer/entersoftTransfer";
import Coorganizations from "../pages/generalSettings/partials/coorganizations/coorganizations";
import MultipleExports from "../pages/generalSettings/partials/multipleExports/multipleExports";
import GeneralReports from "../pages/generalSettings/partials/generalReports/generalReports";
import CustomizeMessagesToExhibitor
    from "../pages/exhibitionSettings/pages/customizeMessagesToExhibitor/customizeMessagesToExhibitor";
import CopyExhibitionSettings from "../pages/exhibitionSettings/pages/copyExhibitionSettings/copyExhibitionSettings";

export const ROUTE_PAGE_HOME = routeCreator(Home, '/', 'Home', 'page.home', {exact: true})
export const ROUTE_PAGE_NOT_FOUND = routeCreator(NotFound, '/', 'Not Found', 'page.notFound')

export const ROUTE_PAGE_EXHIBITION = routeCreator(Exhibition, '/exhibition/:id', 'Exhibition', 'page.exhibitions.details')
export const ROUTE_PAGE_EXHIBITION_KIT_AD_LOGOS = routeCreator(ExhibitorKit, '/exhibition/:id/exhibitor-kit', 'Exhibitor Kid', 'page.exhibitions.exhibitor.kit', {exact: true})

export const ROUTE_PAGE_ENTERSOFT_TRANSFER = routeCreator(EntersoftTransfer, '/entersoft-transfer', 'Entersoft Transfer', 'page.entersoft.transfer')

export const ROUTE_PAGE_COORGANIZATIONS = routeCreator(Coorganizations, '/coorganizations', 'Coorganizations', 'page.coorganizations')

export const ROUTE_PAGE_CATALOGUE_EXPORTS = routeCreator(MultipleExports, '/catalogue-exports', 'Catalogue Exports', 'page.catalogue.exports')

export const ROUTE_PAGE_GENERAL_REPORTS = routeCreator(GeneralReports, '/general-reports', 'General Reports', 'page.general.reports')

export const ROUTE_PAGE_EXHIBITION_SETTINGS = routeCreator(ExhibitionSettings, '/exhibition/:id/settings', 'Exhibition Settings', 'page.exhibitions.settings')
export const ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE = routeCreator(WelcomePage, '/exhibition/:id/settings/welcome-page', 'Exhibition Settings', 'page.exhibitions.settings.welcome.page')
export const ROUTE_PAGE_EXHIBITION_SETTINGS_PREREGISTRATION_FIELDS = routeCreator(PreregistrationFields, '/exhibition/:id/settings/preregistration-fields', 'Exhibition Settings', 'page.exhibitions.settings.preregistration.fields', {exact: true})
export const ROUTE_PAGE_EXHIBITION_SETTINGS_B2B = routeCreator(B2B, '/exhibition/:id/settings/b2b', 'Exhibition Settings B2B', 'page.exhibitions.settings.b2b', {exact: true})
export const ROUTE_PAGE_EXHIBITION_SETTINGS_INVITATION_MAIL = routeCreator(InvitationMail, '/exhibition/:id/settings/invitation', 'Exhibition Settings', 'page.exhibitions.settings.invitation', {exact: true})
export const ROUTE_PAGE_EXHIBITION_SETTINGS_KIT_AD_LOGOS = routeCreator(ExhibitorKitAndLogos, '/exhibition/:id/settings/kit-and-logos', 'Exhibition Settings', 'page.exhibitions.settings.kitAndLogos', {exact: true})
export const ROUTE_PAGE_EXHIBITION_SETTINGS_MESSAGES_LIST = routeCreator(Messages, '/exhibition/:id/settings/messages', 'Exhibition Settings', 'page.exhibitions.settings.messages', {exact: true})
export const ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE = routeCreator(BadgeCustomize, '/exhibition/:id/settings/badge/customize', 'Exhibition Badge Customize', 'page.exhibitions.badge.customize')
export const ROUTE_PAGE_EXHIBITION_QUICK_BADGE = routeCreator(QuickBadgePrint, '/exhibition/:id/settings/quick-badge-print', 'Exhibition Badge Quick Print', 'page.exhibitions.badge.quick.print', {exact: true})
export const ROUTE_PAGE_EXHIBITION_SETTINGS_IMAGES = routeCreator(ImagesForMessages, '/exhibition/:id/settings/images-for-messages', 'Exhibition Settings Images', 'page.exhibitions.settings.images', {exact: true})
export const ROUTE_PAGE_EXHIBITION_SETTINGS_BUSINESS_CATEGORIES = routeCreator(BusinessCategories, '/exhibition/:id/businessCategories', 'Exhibition Business Categories', 'page.exhibition.settings.businessCategories', {exact: true})

export const ROUTE_PAGE_EXHIBITION_SETTINGS_INTERESTS = routeCreator(Interests, '/exhibition/:id/settings/interests', 'Exhibition Interests', 'page.exhibition.settings.interests')

export const ROUTE_PAGE_EXHIBITION_SETTINGS_TRANSLATIONS = routeCreator(CustomizeMessagesToExhibitor, '/exhibition/:id/settings/customize-messages', 'Customize Messages', 'page.exhibition.settings.custom.messages')

export const ROUTE_PAGE_EXHIBITION_SETTINGS_COPY = routeCreator(CopyExhibitionSettings, '/exhibition/:id/settings/copy-settings', 'Copy Settings', 'page.exhibition.settings.copy')

export const ROUTE_PAGE_PROFILE_EXHIBITOR = routeCreator(ExhibitorProfile, '/exhibition/:id/exhibitor-profile/:exhibitorId', 'Profile', 'page.profile')
export const ROUTE_PAGE_PROFILE_VISITOR = routeCreator(VisitorProfile, '/exhibition/:id/visitor-profile/:visitorId', 'Profile', 'page.profile')

export const ROUTE_PAGE_LOGIN = routeCreator(Login, '/', 'login', 'Login', 'page.login')

const router = {
    ROUTE_PAGE_HOME,
    ROUTE_PAGE_PROFILE_EXHIBITOR,
    ROUTE_PAGE_PROFILE_VISITOR,
    ROUTE_PAGE_EXHIBITION_KIT_AD_LOGOS,
    ROUTE_PAGE_CATALOGUE_EXPORTS,
    ROUTE_PAGE_COORGANIZATIONS,
    ROUTE_PAGE_EXHIBITION_SETTINGS_COPY,
    ROUTE_PAGE_EXHIBITION_SETTINGS_TRANSLATIONS,
    ROUTE_PAGE_ENTERSOFT_TRANSFER,
    ROUTE_PAGE_GENERAL_REPORTS,
    ROUTE_PAGE_EXHIBITION_SETTINGS_B2B,
    ROUTE_PAGE_EXHIBITION_SETTINGS_BUSINESS_CATEGORIES,
    ROUTE_PAGE_EXHIBITION_SETTINGS_INTERESTS,
    ROUTE_PAGE_EXHIBITION_SETTINGS_WELCOME_PAGE,
    ROUTE_PAGE_EXHIBITION_SETTINGS_BADGE_CUSTOMIZE,
    ROUTE_PAGE_EXHIBITION_SETTINGS_MESSAGES_LIST,
    ROUTE_PAGE_EXHIBITION_SETTINGS_KIT_AD_LOGOS,
    ROUTE_PAGE_EXHIBITION_SETTINGS_INVITATION_MAIL,
    ROUTE_PAGE_EXHIBITION_SETTINGS_PREREGISTRATION_FIELDS,
    ROUTE_PAGE_EXHIBITION_QUICK_BADGE,
    ROUTE_PAGE_EXHIBITION_SETTINGS_IMAGES,
    ROUTE_PAGE_EXHIBITION_SETTINGS,
    ROUTE_PAGE_EXHIBITION,
    ROUTE_PAGE_NOT_FOUND
}

export default router;