// @flow
import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {MoveDown} from "@mui/icons-material";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import Toolbar from "@mui/material/Toolbar";
import {useEntersoftTransferData} from "../../../../hooks/entersoftTransfer/useEntersoftTransferData";
import {useEntersoftTransferAction} from "../../../../hooks/entersoftTransfer/useEntersoftTransferAction";
import ActionStatus from "../../../../components/app/alerts/actionStatus/actionStatus";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION, EXHIBITOR_PROFILE} from "../../../../locales/components/namespaces";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../../helpers/constants";

type Props = {};

const EntersoftTransfer = (props: Props) => {
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	const {loadingTransfer, transferEnded, transferMessage} = useEntersoftTransferData()
	const {transferExhibitorsFromEntersoft, clearTransferMessage} = useEntersoftTransferAction()
	return (
		<React.Fragment>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('EnterSoft Transfer')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<List>
					<ListItem component={"div"}>
						<ListItemText
							primary={
								<Typography component={"div"} variant={"body1"}>
									<Trans
										i18nKey={'entersoft-transfers'}
										ns={EXHIBITION}
									>
										On this page you can update exhibitions with data transfer from EnterSoft.
									</Trans>
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Paper>
			<Paper sx={{p: 2}} variant={"outlined"}>
				<Box>
					<Toolbar disableGutters variant={"dense"}>
						<Box sx={{flexGrow: 1}}/>
						<Stack direction="row" width={'100%'}>
							<LoadingButton
								loading={loadingTransfer}
								loadingPosition="start"
								variant={"outlined"}
								size={"small"}
								startIcon={<MoveDown/>}
								onClick={transferExhibitorsFromEntersoft}
								sx={{
									marginRight: 'auto'
								}}
							>
								{normalizeGreek(t('Transfer Data From Entersoft'))}
							</LoadingButton>
						</Stack>
					</Toolbar>
					<ActionStatus
						status={transferMessage}
						onClose={clearTransferMessage}
					/>
					<ContentLoader
						withBackDrop={true}
						useCircularLoader={true}
						loading={loadingTransfer}
						message={t("Transferring data... Please don't close the browser before the process ends.")}
					/>
				</Box>
			</Paper>
		</React.Fragment>
	);
};

export default withPermissionHOC(EntersoftTransfer, [Permissions.BeOrganizer])