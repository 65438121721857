// @flow
import React, {useMemo} from 'react';
import Box from "@mui/material/Box";
import useUserData from "../../../hooks/user/useUserData";

type Props = {};

const CustomTranslation = (props: Props) => {
	const {translations, code} = props
	const {language} = useUserData()
	const translation = useMemo(() => {
		if (translations && translations[code]) {
			return typeof translations[code]?.value === 'string' ? translations[code]?.value : translations[code]?.value?.[language]
		}
		return ''
	}, [code, language, translations])
	return translation ? (
		<Box p={1} dangerouslySetInnerHTML={{__html: translation}}/>
	) : (
		<>
			{props.children}
		</>
	)
}

export default CustomTranslation