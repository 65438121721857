// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";

const useBadgeAction = () => {
    const dispatch = useDispatch()
    
    const getBadge = useCallback((id) => {
        dispatch(actions.getBadge({
            id: id
        }))
    }, [dispatch])

    const clearScannedBadge = useCallback(() => {
        dispatch(actions.clearScannedBadge())
    }, [dispatch])

    const registerNewScannedBadge = useCallback(() => {
        dispatch(actions.registerNewScannedBadge())
    }, [dispatch])

    const getBadgeConfiguration = useCallback((id) => {
        dispatch(actions.getBadgeConfiguration({
            id: id
        }))
    }, [dispatch])

    const getBadgeTemplateTypes = useCallback(() => {
        dispatch(actions.getBadgeTemplateTypes())
    }, [dispatch])

    const updateBadgeConfiguration = useCallback((id, values) => {
        dispatch(actions.updateBadgeConfiguration({
            id: id,
            ContentEN: values.info.en,
            ContentEL: values.info.el,
            ContentPartnerEN: values.infoPartner.en,
            ContentPartnerEL: values.infoPartner.el,
            ColorPartner1: values.colorPartner1,
            ColorPartner2: values.colorPartner2,
            ColorFont: values.colorFont,
            ColorVisitor1: values.colorVisitor1,
            ColorVisitor2: values.colorVisitor2,
            ColorProvider1: values.colorProvider1,
            ColorProvider2: values.colorProvider2,
            ColorStaff1: values.colorStaff1,
            ColorStaff2: values.colorStaff2,
            ColorOrganizer1: values.colorOrganizer1,
            ColorOrganizer2: values.colorOrganizer2,
            VisitorBadgeTemplateTypeId: values.visitorBadgeTemplateTypeId,
            VisitorRegBadgeTemplateTypeId: values.visitorRegBadgeTemplateTypeId,
            PartnerBadgeTemplateTypeId: values.partnerBadgeTemplateTypeId,
            ProviderBadgeTemplateTypeId: values.providerBadgeTemplateTypeId,
            StaffBadgeTemplateTypeId: values.staffBadgeTemplateTypeId,
            OrganizerBadgeTemplateTypeId: values.organizerBadgeTemplateTypeId,
        }))
    }, [dispatch])

    return {
        getBadgeConfiguration,
        updateBadgeConfiguration,
        registerNewScannedBadge,
        clearScannedBadge,
        getBadge,
        getBadgeTemplateTypes
    }
}

export default useBadgeAction