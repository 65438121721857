import type {Email, UnmappedEmail} from "./types";

export const mapEmail: Email = (datum: UnmappedEmail) => {
    if (!datum) return
    return {
        invitation: {
            en: datum.InvitationEN ?? '',
            el: datum.InvitationEL ?? '',
        },
        invitationExhibitor: {
            en: datum.InvitationExhibitorEN ?? '',
            el: datum.InvitationExhibitorEL ?? '',
        }
    }
}
