import type {Report} from "./types";
import {parseMultiple, withLanguage} from "../../helpers/parse";
import {formatDate} from "../../helpers/date";

const parseCategory = (datum, language) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		exhibition: withLanguage(language)(datum.get('exhibition')),
		coorganization: withLanguage(language)(datum.get('coorganization')),
		exhibitionStartDt: datum.get('exhibitionStartDt'),
		category: withLanguage(language)(datum.get('category')),
		visitorCount: datum.get('visitorCount'),
		visitorPercentage: datum.get('visitorPercentage'),
		groupingColumn: `${formatDate(datum.get('exhibitionStartDt'), formatDate.formatTypes.DATE_ONLY)} - ${withLanguage(language)(datum.get('exhibition'))}${withLanguage(language)(datum.get('coorganization')) ? ' (' + withLanguage(language)(datum.get('coorganization')) + ')' : ''}`
	}
}

const parseKpis = (datum, language) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		exhibition: withLanguage(language)(datum.get('exhibition')),
		coorganization: withLanguage(language)(datum.get('coorganization')),
		exhibitionStartDt: datum.get('exhibitionStartDt'),
		preregistrationTotalCount: datum.get('preregistrationTotalCount'),
		preregistrationVisitorCount: datum.get('preregistrationVisitorCount'),
		erpTotalCount: datum.get('erpTotalCount'),
		erpVisitorCount: datum.get('erpVisitorCount'),
		notErpTotalCount: datum.get('notErpTotalCount'),
		notErpVisitorCount: datum.get('notErpVisitorCount'),
		preregistrationTotalCountCoorg: datum.get('preregistrationTotalCountCoorg'),
		preregistrationVisitorCountCoorg: datum.get('preregistrationVisitorCountCoorg'),
		erpTotalCountCoorg: datum.get('erpTotalCountCoorg'),
		erpVisitorCountCoorg: datum.get('erpVisitorCountCoorg'),
		notErpTotalCountCoorg: datum.get('notErpTotalCountCoorg'),
		notErpVisitorCountCoorg: datum.get('notErpVisitorCountCoorg'),
		scannedAfterRegistration: datum.get('scannedAfterRegistration'),
	}
}

const parseCountries = (datum, language) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		exhibition: withLanguage(language)(datum.get('exhibition')),
		coorganization: withLanguage(language)(datum.get('coorganization')),
		exhibitionStartDt: datum.get('exhibitionStartDt'),
		country: withLanguage(language)(datum.get('country')),
		visitorCount: datum.get('visitorCount'),
		weight: datum.get('weight'),
		groupingColumn: `${formatDate(datum.get('exhibitionStartDt'), formatDate.formatTypes.DATE_ONLY)} - ${withLanguage(language)(datum.get('exhibition'))}${withLanguage(language)(datum.get('coorganization')) ? ' (' + withLanguage(language)(datum.get('coorganization')) + ')' : ''}`
	}
}

const parseVisitsDay = (datum, language) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		exhibition: withLanguage(language)(datum.get('exhibition')),
		coorganization: withLanguage(language)(datum.get('coorganization')),
		exhibitionStartDt: datum.get('exhibitionStartDt'),
		entranceDate: datum.get('entranceDate'),
		entranceTime: datum.get('entranceTime'),
		entranceCount: datum.get('entranceCount'),
		visitorCount: datum.get('visitorCount'),
		entranceLobby1Count: datum.get('entranceLobby1Count'),
		entranceLobby2Count: datum.get('entranceLobby2Count'),
		entranceLoungeCount: datum.get('entranceLoungeCount'),
		groupingColumn: `${formatDate(datum.get('exhibitionStartDt'), formatDate.formatTypes.DATE_ONLY)} - ${withLanguage(language)(datum.get('exhibition'))}${withLanguage(language)(datum.get('coorganization')) ? ' (' + withLanguage(language)(datum.get('coorganization')) + ')' : ''}`
	}
}

const parseVisitsRegion = (datum, language) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		exhibition: withLanguage(language)(datum.get('exhibition')),
		coorganization: withLanguage(language)(datum.get('coorganization')),
		exhibitionStartDt: datum.get('exhibitionStartDt'),
		region: datum.get('region'),
		visitorCount: datum.get('visitorCount'),
		weight: datum.get('weight'),
		groupingColumn: `${formatDate(datum.get('exhibitionStartDt'), formatDate.formatTypes.DATE_ONLY)} - ${withLanguage(language)(datum.get('exhibition'))}${withLanguage(language)(datum.get('coorganization')) ? ' (' + withLanguage(language)(datum.get('coorganization')) + ')' : ''}`
	}
}

const parseVisitsTotal = (datum, language) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		exhibition: withLanguage(language)(datum.get('exhibition')),
		coorganization: withLanguage(language)(datum.get('coorganization')),
		exhibitionStartDt: datum.get('exhibitionStartDt'),
		entranceCount: datum.get('entranceCount'),
		visitorCount: datum.get('visitorCount'),
		entranceLobby1Count: datum.get('entranceLobby1Count'),
		entranceLobby2Count: datum.get('entranceLobby2Count'),
		entranceLoungeCount: datum.get('entranceLoungeCount')
	}
}

const parseTeams = (datum) => {
	if(!datum) return
	return {
		id: datum.get('id'),
		name: datum.get('name'),
	}
}

const parseDefaultPartnerVisits = (datum) => {
	if(!datum) return
	return {
		id: datum.get('id'),
		countCoorganization: datum.get('countCoorganization'),
		countExhibition: datum.get('countExhibition'),
		entryDate: datum.get('entryDate'),
	}
}

export const parseReports: Report = (datum, language) : Report => {
	if (!datum) return
	return {
		categories: parseMultiple(datum.get('categories'), i => parseCategory(i, language)),
		interests: parseMultiple(datum.get('interests'), i => parseCategory(i, language)),
		kpi: parseMultiple(datum.get('kpi'), i => parseKpis(i, language)),
		visitsCountries: parseMultiple(datum.get('visitsCountries'), i => parseCountries(i, language)),
		visitsDay: parseMultiple(datum.get('visitsDay'), i => parseVisitsDay(i, language)),
		visitsHalfHour: parseMultiple(datum.get('visitsHalfHour'), i => parseVisitsDay(i, language)),
		visitsHour: parseMultiple(datum.get('visitsHour'), i => parseVisitsDay(i, language)),
		visitsRegion: parseMultiple(datum.get('visitsRegion'), i => parseVisitsRegion(i, language)),
		visitsTotal: parseMultiple(datum.get('visitsTotal'), i => parseVisitsTotal(i, language)),
		teams: parseMultiple(datum.get('teams'), i => parseTeams(i)),
		defaultPartnerVisits: parseMultiple(datum.get('defaultPartnerVisits'), i => parseDefaultPartnerVisits(i))
	}
}