// @flow
import React from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import useUserData from "../../../../hooks/user/useUserData";
import {ListItem, Paper} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import MessageCustomizer from "./partials/messageCustomizer";
import CopyToClipboard from "../../../../components/general/copyToClipboard/copyToClipboard";

type Props = {};

const CustomizeMessagesToExhibitor = (props: Props) => {
	const {exhibition, id, loading: loadingExhibition} = useExhibition({language: 'any', noFetchFiles: true})
	const {language} = useUserData()
	const {t} = useTranslation(EXHIBITION)
	
	return exhibition ? (
		<React.Fragment>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
					{exhibition.title[language]}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
					{t('Settings')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('Customize Info Messages To Exhibitors')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<List>
					<ListItem component={"div"}>
						<ListItemText
							primary={
								<Typography component={"div"} variant={"body1"}>
									{t('On this page you can customize informative messages, that appear on pages available to exhibitors.')}
									<br/>
									{t('You can copy and paste this variable in your text to display final date of catalogue submission.')}
									<br/>
									<strong>{t('In English Text')}</strong>
									<CopyToClipboard text={t(`<%${'ExhibitionCatalogueDtEN'}%>`)}/>
									<strong>{t('In Greek Text')}</strong>
									<CopyToClipboard text={t(`<%${'ExhibitionCatalogueDtEL'}%>`)}/>
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Paper>
			<MessageCustomizer
				exhibitionId={id}
			/>
		</React.Fragment>
	) : (
		<ContentLoader
			loading={loadingExhibition}
			notFoundWhenNotLoading
		/>
	)
};

export default CustomizeMessagesToExhibitor