export * from "./faqs/types"
export * from "./currentUser/types"
export * from "./exhibitionFiles/types"
export * from "./badge/types"

export const GET_EXHIBITIONS = 'GET_EXHIBITIONS'
export const GET_EXHIBITIONS_SUCCEEDED = 'GET_EXHIBITIONS_SUCCEEDED'
export const GET_EXHIBITIONS_FAILED = 'GET_EXHIBITIONS_FAILED'

export const GET_EXHIBITIONS_LIST = 'GET_EXHIBITIONS_LIST'
export const GET_EXHIBITIONS_LIST_SUCCEEDED = 'GET_EXHIBITIONS_LIST_SUCCEEDED'
export const GET_EXHIBITIONS_LIST_FAILED = 'GET_EXHIBITIONS_LIST_FAILED'

export const GET_ACTIVE_EXHIBITIONS = 'GET_ACTIVE_EXHIBITIONS'
export const GET_ACTIVE_EXHIBITIONS_SUCCEEDED = 'GET_ACTIVE_EXHIBITIONS_SUCCEEDED'
export const GET_ACTIVE_EXHIBITIONS_FAILED = 'GET_ACTIVE_EXHIBITIONS_FAILED'

export const GET_EXHIBITION_DETAILS = 'GET_EXHIBITION_DETAILS'
export const GET_EXHIBITION_DETAILS_SUCCEEDED = 'GET_EXHIBITION_DETAILS_SUCCEEDED'
export const GET_EXHIBITION_DETAILS_FAILED = 'GET_EXHIBITION_DETAILS_FAILED'

export const GET_EXHIBITION_DETAILS_PUBLIC = 'GET_EXHIBITION_DETAILS_PUBLIC'
export const GET_EXHIBITION_DETAILS_PUBLIC_SUCCEEDED = 'GET_EXHIBITION_DETAILS_PUBLIC_SUCCEEDED'
export const GET_EXHIBITION_DETAILS_PUBLIC_FAILED = 'GET_EXHIBITION_DETAILS_PUBLIC_FAILED'

export const CLEAR_REGISTERED_SUCCESSFULLY_FLAG = 'CLEAR_REGISTERED_SUCCESSFULLY_FLAG'

export const SET_VISITOR_APPROVAL_STATUS = 'SET_VISITOR_APPROVAL_STATUS'
export const SET_VISITOR_APPROVAL_STATUS_SUCCEEDED = 'SET_VISITOR_APPROVAL_STATUS_SUCCEEDED'
export const SET_VISITOR_APPROVAL_STATUS_FAILED = 'SET_VISITOR_APPROVAL_STATUS_FAILED'

export const GET_EXHIBITOR_PROFILE = 'GET_EXHIBITOR_PROFILE'
export const GET_EXHIBITOR_PROFILE_SUCCEEDED = 'GET_EXHIBITOR_PROFILE_SUCCEEDED'
export const GET_EXHIBITOR_PROFILE_FAILED = 'GET_EXHIBITOR_PROFILE_FAILED'

export const GET_VISITOR_PROFILE = 'GET_VISITOR_PROFILE'
export const GET_VISITOR_PROFILE_SUCCEEDED = 'GET_VISITOR_PROFILE_SUCCEEDED'
export const GET_VISITOR_PROFILE_FAILED = 'GET_VISITOR_PROFILE_FAILED'

export const GET_VISITOR_EXHIBITORS = 'GET_VISITOR_EXHIBITORS'
export const GET_VISITOR_EXHIBITORS_SUCCEEDED = 'GET_VISITOR_EXHIBITORS_SUCCEEDED'
export const GET_VISITOR_EXHIBITORS_FAILED = 'GET_VISITOR_EXHIBITORS_FAILED'

export const UPDATE_EXHIBITOR_PROFILE = 'UPDATE_EXHIBITOR_PROFILE'
export const UPDATE_EXHIBITOR_PROFILE_SUCCEEDED = 'UPDATE_EXHIBITOR_PROFILE_SUCCEEDED'
export const UPDATE_EXHIBITOR_PROFILE_FAILED = 'UPDATE_EXHIBITOR_PROFILE_FAILED'

export const CLEAR_UPDATE_EXHIBITION_STATUS = 'CLEAR_UPDATE_EXHIBITION_STATUS'
export const UPDATE_EXHIBITION = 'UPDATE_EXHIBITION'
export const UPDATE_EXHIBITION_SUCCEEDED = 'UPDATE_EXHIBITION_SUCCEEDED'
export const UPDATE_EXHIBITION_FAILED = 'UPDATE_EXHIBITION_FAILED'
export const UPDATE_EXHIBITION_LOGO = 'UPDATE_EXHIBITION_LOGO'
export const UPDATE_EXHIBITION_LOGO_SUCCEEDED = 'UPDATE_EXHIBITION_LOGO_SUCCEEDED'
export const UPDATE_EXHIBITION_LOGO_FAILED = 'UPDATE_EXHIBITION_LOGO_FAILED'

export const CLEAR_ACTION_ON_VISITOR_STATUS = 'CLEAR_ACTION_ON_VISITOR_STATUS'
export const CLEAR_ACTION_ON_EXHIBITOR_STATUS = 'CLEAR_ACTION_ON_EXHIBITOR_STATUS'
export const CREATE_VISITOR = 'CREATE_VISITOR'
export const CREATE_VISITOR_SUCCEEDED = 'CREATE_VISITOR_SUCCEEDED'
export const CREATE_VISITOR_FAILED = 'CREATE_VISITOR_FAILED'

export const UPDATE_VISITOR = 'UPDATE_VISITOR'
export const UPDATE_VISITOR_SUCCEEDED = 'UPDATE_VISITOR_SUCCEEDED'
export const UPDATE_VISITOR_FAILED = 'UPDATE_VISITOR_FAILED'

export const REMOVE_VISITOR = 'REMOVE_VISITOR'
export const REMOVE_VISITOR_SUCCEEDED = 'REMOVE_VISITOR_SUCCEEDED'
export const REMOVE_VISITOR_FAILED = 'REMOVE_VISITOR_FAILED'

export const CLEAR_BULK_VISITORS_STATUS = 'CLEAR_BULK_VISITORS_STATUS'
export const CREATE_BULK_VISITORS = 'CREATE_BULK_VISITORS'
export const CREATE_BULK_VISITORS_SUCCEEDED = 'CREATE_BULK_VISITORS_SUCCEEDED'
export const CREATE_BULK_VISITORS_FAILED = 'CREATE_BULK_VISITORS_FAILED'

export const CREATE_BULK_VISITORS_FOR_BADGES = 'CREATE_BULK_VISITORS_FOR_BADGES'
export const CREATE_BULK_VISITORS_FOR_BADGES_SUCCEEDED = 'CREATE_BULK_VISITORS_FOR_BADGES_SUCCEEDED'
export const CREATE_BULK_VISITORS_FOR_BADGES_FAILED = 'CREATE_BULK_VISITORS_FOR_BADGES_FAILED'

export const CLEAR_ACTION_ON_PARTNER_STATUS = 'CLEAR_ACTION_ON_PARTNER_STATUS'
export const CREATE_PARTNER = 'CREATE_PARTNER'
export const CREATE_PARTNER_SUCCEEDED = 'CREATE_PARTNER_SUCCEEDED'
export const CREATE_PARTNER_FAILED = 'CREATE_PARTNER_FAILED'

export const CLEAR_BULK_PARTNERS_STATUS = 'CLEAR_BULK_PARTNERS_STATUS'
export const CREATE_BULK_PARTNERS = 'CREATE_BULK_PARTNERS'
export const CREATE_BULK_PARTNERS_SUCCEEDED = 'CREATE_BULK_PARTNERS_SUCCEEDED'
export const CREATE_BULK_PARTNERS_FAILED = 'CREATE_BULK_PARTNERS_FAILED'

export const STORE_EXHIBITION_ATTACHMENT = 'STORE_EXHIBITION_ATTACHMENT'
export const STORE_EXHIBITION_ATTACHMENT_SUCCEEDED = 'STORE_EXHIBITION_ATTACHMENT_SUCCEEDED'
export const STORE_EXHIBITION_ATTACHMENT_FAILED = 'STORE_EXHIBITION_ATTACHMENT_FAILED'

export const GET_INTERESTS = 'GET_INTERESTS'
export const GET_INTERESTS_SUCCEEDED = 'GET_INTERESTS_SUCCEEDED'
export const GET_INTERESTS_FAILED = 'GET_INTERESTS_FAILED'

export const UPDATE_INTERESTS = 'UPDATE_INTERESTS'
export const UPDATE_INTERESTS_SUCCEEDED = 'UPDATE_INTERESTS_SUCCEEDED'
export const UPDATE_INTERESTS_FAILED = 'UPDATE_INTERESTS_FAILED'

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCEEDED = 'GET_COUNTRIES_SUCCEEDED'
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED'

export const GET_VISITOR_CATEGORIES = 'GET_VISITOR_CATEGORIES'
export const GET_VISITOR_CATEGORIES_SUCCEEDED = 'GET_VISITOR_CATEGORIES_SUCCEEDED'
export const GET_VISITOR_CATEGORIES_FAILED = 'GET_VISITOR_CATEGORIES_FAILED'

export const SAVE_VISITOR_CATEGORIES = 'SAVE_VISITOR_CATEGORIES'
export const SAVE_VISITOR_CATEGORIES_SUCCEEDED = 'SAVE_VISITOR_CATEGORIES_SUCCEEDED'
export const SAVE_VISITOR_CATEGORIES_FAILED = 'SAVE_VISITOR_CATEGORIES_FAILED'

export const UPDATE_VISITOR_CATEGORIES = 'UPDATE_VISITOR_CATEGORIES'
export const UPDATE_VISITOR_CATEGORIES_SUCCEEDED = 'UPDATE_VISITOR_CATEGORIES_SUCCEEDED'
export const UPDATE_VISITOR_CATEGORIES_FAILED = 'UPDATE_VISITOR_CATEGORIES_FAILED'

export const DELETE_VISITOR_CATEGORY = 'DELETE_VISITOR_CATEGORY'
export const DELETE_VISITOR_CATEGORY_SUCCEEDED = 'DELETE_VISITOR_CATEGORY_SUCCEEDED'
export const DELETE_VISITOR_CATEGORY_FAILED = 'DELETE_VISITOR_CATEGORY_FAILED'

export const REMOVE_PARTNER = 'REMOVE_PARTNER'
export const REMOVE_PARTNER_SUCCEEDED = 'REMOVE_PARTNER_SUCCEEDED'
export const REMOVE_PARTNER_FAILED = 'REMOVE_PARTNER_FAILED'

export const SEND_VISITOR_INVITATIONS = 'SEND_VISITOR_INVITATIONS'
export const SEND_VISITOR_INVITATIONS_SUCCEEDED = 'SEND_VISITOR_INVITATIONS_SUCCEEDED'
export const SEND_VISITOR_INVITATIONS_FAILED = 'SEND_VISITOR_INVITATIONS_FAILED'

export const SEND_EXHIBITOR_INVITATIONS = 'SEND_EXHIBITOR_INVITATIONS'
export const SEND_EXHIBITOR_INVITATIONS_SUCCEEDED = 'SEND_EXHIBITOR_INVITATIONS_SUCCEEDED'
export const SEND_EXHIBITOR_INVITATIONS_FAILED = 'SEND_EXHIBITOR_INVITATIONS_FAILED'

export const SEND_PARTNER_BADGES = 'SEND_PARTNER_BADGES'
export const SEND_PARTNER_BADGES_SUCCEEDED = 'SEND_PARTNER_BADGES_SUCCEEDED'
export const SEND_PARTNER_BADGES_FAILED = 'SEND_PARTNER_BADGES_FAILED'

export const GET_EXHIBITION_BUSINESS_CATEGORIES = 'GET_EXHIBITION_BUSINESS_CATEGORIES'
export const GET_EXHIBITION_BUSINESS_CATEGORIES_SUCCEEDED = 'GET_EXHIBITION_BUSINESS_CATEGORIES_SUCCEEDED'
export const GET_EXHIBITION_BUSINESS_CATEGORIES_FAILED = 'GET_EXHIBITION_BUSINESS_CATEGORIES_FAILED'

export const GET_EXHIBITION_EMAILS = 'GET_EXHIBITION_EMAILS'
export const GET_EXHIBITION_EMAILS_SUCCEEDED = 'GET_EXHIBITION_EMAILS_SUCCEEDED'
export const GET_EXHIBITION_EMAILS_FAILED = 'GET_EXHIBITION_EMAILS_FAILED'

export const GET_MESSAGE_TEMPLATE_TYPES = 'GET_MESSAGE_TEMPLATE_TYPES'
export const GET_MESSAGE_TEMPLATE_TYPES_SUCCEEDED = 'GET_MESSAGE_TEMPLATE_TYPES_SUCCEEDED'
export const GET_MESSAGE_TEMPLATE_TYPES_FAILED = 'GET_MESSAGE_TEMPLATE_TYPES_FAILED'

export const GET_MESSAGE_TEMPLATE_TYPE_VARIABLES = 'GET_MESSAGE_TEMPLATE_TYPE_VARIABLES'
export const GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_SUCCEEDED = 'GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_SUCCEEDED'
export const GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_FAILED = 'GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_FAILED'

export const GET_MESSAGE_TEMPLATE = 'GET_MESSAGE_TEMPLATE'
export const GET_MESSAGE_TEMPLATE_SUCCEEDED = 'GET_MESSAGE_TEMPLATE_SUCCEEDED'
export const GET_MESSAGE_TEMPLATE_FAILED = 'GET_MESSAGE_TEMPLATE_FAILED'

export const UPDATE_MESSAGE_TEMPLATE = 'UPDATE_MESSAGE_TEMPLATE'
export const UPDATE_MESSAGE_TEMPLATE_SUCCEEDED = 'UPDATE_MESSAGE_TEMPLATE_SUCCEEDED'
export const UPDATE_MESSAGE_TEMPLATE_FAILED = 'UPDATE_MESSAGE_TEMPLATE_FAILED'

export const TRANSFER_EXHIBITORS_FROM_ENTERSOFT = 'TRANSFER_EXHIBITORS_FROM_ENTERSOFT'
export const TRANSFER_EXHIBITORS_FROM_ENTERSOFT_SUCCEEDED = 'TRANSFER_EXHIBITORS_FROM_ENTERSOFT_SUCCEEDED'
export const TRANSFER_EXHIBITORS_FROM_ENTERSOFT_FAILED = 'TRANSFER_EXHIBITORS_FROM_ENTERSOFT_FAILED'
export const TRANSFER_EXHIBITORS_TO_ENTERSOFT = 'TRANSFER_EXHIBITORS_TO_ENTERSOFT'
export const TRANSFER_EXHIBITORS_TO_ENTERSOFT_SUCCEEDED = 'TRANSFER_EXHIBITORS_TO_ENTERSOFT_SUCCEEDED'
export const TRANSFER_EXHIBITORS_TO_ENTERSOFT_FAILED = 'TRANSFER_EXHIBITORS_TO_ENTERSOFT_FAILED'
export const CLEAR_TRANSFER_MESSAGE = 'CLEAR_TRANSFER_MESSAGE'

export const EXHIBITOR_CATALOGUE_CONFIRM = 'EXHIBITOR_CATALOGUE_CONFIRM'
export const EXHIBITOR_CATALOGUE_CONFIRM_SUCCEEDED = 'EXHIBITOR_CATALOGUE_CONFIRM_SUCCEEDED'
export const EXHIBITOR_CATALOGUE_CONFIRM_FAILED = 'EXHIBITOR_CATALOGUE_CONFIRM_FAILED'

export const EXHIBITOR_CATALOGUE_CONFIRM_RESET = 'EXHIBITOR_CATALOGUE_CONFIRM_RESET'
export const EXHIBITOR_CATALOGUE_CONFIRM_RESET_SUCCEEDED = 'EXHIBITOR_CATALOGUE_CONFIRM_RESET_SUCCEEDED'
export const EXHIBITOR_CATALOGUE_CONFIRM_RESET_FAILED = 'EXHIBITOR_CATALOGUE_CONFIRM_RESET_FAILED'

export const GET_RULES = 'GET_RULES'
export const GET_RULES_SUCCEEDED = 'GET_RULES_SUCCEEDED'
export const GET_RULES_FAILED = 'GET_RULES_FAILED'

export const SAVE_RULE = 'SAVE_RULE'
export const SAVE_RULE_SUCCEEDED = 'SAVE_RULE_SUCCEEDED'
export const SAVE_RULE_FAILED = 'SAVE_RULE_FAILED'

export const UPDATE_RULE = 'UPDATE_RULE'
export const UPDATE_RULE_SUCCEEDED = 'UPDATE_RULE_SUCCEEDED'
export const UPDATE_RULE_FAILED = 'UPDATE_RULE_FAILED'

export const DELETE_RULE = 'DELETE_RULE'
export const DELETE_RULE_SUCCEEDED = 'DELETE_RULE_SUCCEEDED'
export const DELETE_RULE_FAILED = 'DELETE_RULE_FAILED'

export const GET_RULE_DETAILS = 'GET_RULE_DETAILS'
export const GET_RULE_DETAILS_SUCCEEDED = 'GET_RULE_DETAILS_SUCCEEDED'
export const GET_RULE_DETAILS_FAILED = 'GET_RULE_DETAILS_FAILED'

export const SAVE_RULE_DETAILS = 'SAVE_RULE_DETAILS'
export const SAVE_RULE_DETAILS_SUCCEEDED = 'SAVE_RULE_DETAILS_SUCCEEDED'
export const SAVE_RULE_DETAILS_FAILED = 'SAVE_RULE_DETAILS_FAILED'

export const UPDATE_RULE_DETAILS = 'UPDATE_RULE_DETAILS'
export const UPDATE_RULE_DETAILS_SUCCEEDED = 'UPDATE_RULE_DETAILS_SUCCEEDED'
export const UPDATE_RULE_DETAILS_FAILED = 'UPDATE_RULE_DETAILS_FAILED'

export const DELETE_RULE_DETAILS = 'DELETE_RULE_DETAILS'
export const DELETE_RULE_DETAILS_SUCCEEDED = 'DELETE_RULE_DETAILS_SUCCEEDED'
export const DELETE_RULE_DETAILS_FAILED = 'DELETE_RULE_DETAILS_FAILED'

export const ADD_RULE_DETAILS = 'ADD_RULE_DETAILS'
export const EDIT_RULE_DETAILS = 'EDIT_RULE_DETAILS'
export const CANCEL_EDIT_RULE_DETAILS = 'CANCEL_EDIT_RULE_DETAILS'
export const SET_RULE_DETAILS = 'SET_RULE_DETAILS'
export const CLEAR_RULE_STATUS = 'CLEAR_RULE_STATUS'

export const GET_COORGANIZATIONS = 'GET_COORGANIZATIONS'
export const GET_COORGANIZATIONS_SUCCEEDED = 'GET_COORGANIZATIONS_SUCCEEDED'
export const GET_COORGANIZATIONS_FAILED = 'GET_COORGANIZATIONS_FAILED'

export const SAVE_COORGANIZATION = 'SAVE_COORGANIZATION'
export const SAVE_COORGANIZATION_SUCCEEDED = 'SAVE_COORGANIZATION_SUCCEEDED'
export const SAVE_COORGANIZATION_FAILED = 'SAVE_COORGANIZATION_FAILED'

export const UPDATE_COORGANIZATION = 'UPDATE_COORGANIZATION'
export const UPDATE_COORGANIZATION_SUCCEEDED = 'UPDATE_COORGANIZATION_SUCCEEDED'
export const UPDATE_COORGANIZATION_FAILED = 'UPDATE_COORGANIZATION_FAILED'

export const DELETE_COORGANIZATION = 'DELETE_COORGANIZATION'
export const DELETE_COORGANIZATION_SUCCEEDED = 'DELETE_COORGANIZATION_SUCCEEDED'
export const DELETE_COORGANIZATION_FAILED = 'DELETE_COORGANIZATION_FAILED'

export const CLEAR_COORGANIZATION_STATUS = 'CLEAR_COORGANIZATION_STATUS'

export const ADD_EXHIBITION_TO_COORGANIZATION = 'ADD_EXHIBITION_TO_COORGANIZATION'
export const ADD_EXHIBITION_TO_COORGANIZATION_SUCCEEDED = 'ADD_EXHIBITION_TO_COORGANIZATION_SUCCEEDED'
export const ADD_EXHIBITION_TO_COORGANIZATION_FAILED = 'ADD_EXHIBITION_TO_COORGANIZATION_FAILED'

export const REMOVE_EXHIBITION_FROM_COORGANIZATION = 'REMOVE_EXHIBITION_FROM_COORGANIZATION'
export const REMOVE_EXHIBITION_FROM_COORGANIZATION_SUCCEEDED = 'REMOVE_EXHIBITION_FROM_COORGANIZATION_SUCCEEDED'
export const REMOVE_EXHIBITION_FROM_COORGANIZATION_FAILED = 'REMOVE_EXHIBITION_FROM_COORGANIZATION_FAILED'

export const SET_EXHIBITOR_EDIT_MODE = 'SET_EXHIBITOR_EDIT_MODE'

export const UPDATE_QUICK_PRINT_FOOTER_IMAGE = 'UPDATE_QUICK_PRINT_FOOTER_IMAGE'
export const UPDATE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED = 'UPDATE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED'
export const UPDATE_QUICK_PRINT_FOOTER_IMAGE_FAILED = 'UPDATE_QUICK_PRINT_FOOTER_IMAGE_FAILED'

export const DELETE_QUICK_PRINT_FOOTER_IMAGE = 'DELETE_QUICK_PRINT_FOOTER_IMAGE'
export const DELETE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED = 'DELETE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED'
export const DELETE_QUICK_PRINT_FOOTER_IMAGE_FAILED = 'DELETE_QUICK_PRINT_FOOTER_IMAGE_FAILED'

export const DELETE_MULTIPLE_VISITORS = 'DELETE_MULTIPLE_VISITORS'
export const DELETE_MULTIPLE_VISITORS_SUCCEEDED = 'DELETE_MULTIPLE_VISITORS_SUCCEEDED'
export const DELETE_MULTIPLE_VISITORS_FAILED = 'DELETE_MULTIPLE_VISITORS_FAILED'

export const GET_REPORT_DATA = 'GET_REPORT_DATA'
export const GET_REPORT_DATA_SUCCEEDED = 'GET_REPORT_DATA_SUCCEEDED'
export const GET_REPORT_DATA_FAILED = 'GET_REPORT_DATA_FAILED'
export const SET_REPORT_MESSAGE = 'SET_REPORT_MESSAGE'

export const GET_GRID_STATE = 'GET_GRID_STATE'
export const GET_GRID_STATE_SUCCEEDED = 'GET_GRID_STATE_SUCCEEDED'
export const GET_GRID_STATE_FAILED = 'GET_GRID_STATE_FAILED'
export const SAVE_GRID_STATE = 'SAVE_GRID_STATE'
export const SAVE_GRID_STATE_SUCCEEDED = 'SAVE_GRID_STATE_SUCCEEDED'
export const SAVE_GRID_STATE_FAILED = 'SAVE_GRID_STATE_FAILED'
export const SET_GRID_STATE_MESSAGE = 'SET_GRID_STATE_MESSAGE'
export const DELETE_GRID_STATE = 'DELETE_GRID_STATE'
export const DELETE_GRID_STATE_SUCCEEDED = 'DELETE_GRID_STATE_SUCCEEDED'
export const DELETE_GRID_STATE_FAILED = 'DELETE_GRID_STATE_FAILED'
export const SET_ACTIVE_GRID_STATE = 'SET_ACTIVE_GRID_STATE'
export const SET_ACTIVE_GRID_STATE_SUCCEEDED = 'SET_ACTIVE_GRID_STATE_SUCCEEDED'
export const SET_ACTIVE_GRID_STATE_FAILED = 'SET_ACTIVE_GRID_STATE_FAILED'
export const SET_NEW_GRID_STATE_LABEL = 'SET_NEW_GRID_STATE_LABEL'
export const TOGGLE_GRID_POPPER = 'TOGGLE_GRID_POPPER'

export const GET_EXHIBITIONS_GROUPED_HEADERS = 'GET_EXHIBITIONS_GROUPED_HEADERS'
export const GET_EXHIBITIONS_GROUPED_HEADERS_SUCCEEDED = 'GET_EXHIBITIONS_GROUPED_HEADERS_SUCCEEDED'
export const GET_EXHIBITIONS_GROUPED_HEADERS_FAILED = 'GET_EXHIBITIONS_GROUPED_HEADERS_FAILED'

export const GET_EXHIBITIONS_GROUPED_DETAILS = 'GET_EXHIBITIONS_GROUPED_DETAILS'
export const GET_EXHIBITIONS_GROUPED_DETAILS_SUCCEEDED = 'GET_EXHIBITIONS_GROUPED_DETAILS_SUCCEEDED'
export const GET_EXHIBITIONS_GROUPED_DETAILS_FAILED = 'GET_EXHIBITIONS_GROUPED_DETAILS_FAILED'

export const SET_EXHIBITION_MESSAGE = 'SET_EXHIBITION_MESSAGE'

export const GET_UI_TRANSLATIONS = 'GET_UI_TRANSLATIONS'
export const GET_UI_TRANSLATIONS_SUCCEEDED = 'GET_UI_TRANSLATIONS_SUCCEEDED'
export const GET_UI_TRANSLATIONS_FAILED = 'GET_UI_TRANSLATIONS_FAILED'

export const UPDATE_UI_TRANSLATION = 'UPDATE_UI_TRANSLATION'
export const UPDATE_UI_TRANSLATION_SUCCEEDED = 'UPDATE_UI_TRANSLATION_SUCCEEDED'
export const UPDATE_UI_TRANSLATION_FAILED = 'UPDATE_UI_TRANSLATION_FAILED'

export const SET_UI_TRANSLATION_MESSAGE = 'SET_UI_TRANSLATION_MESSAGE'

export const COPY_EXHIBITION_SETTINGS = 'COPY_EXHIBITION_SETTINGS'
export const COPY_EXHIBITION_SETTINGS_SUCCEEDED = 'COPY_EXHIBITION_SETTINGS_SUCCEEDED'
export const COPY_EXHIBITION_SETTINGS_FAILED = 'COPY_EXHIBITION_SETTINGS_FAILED'