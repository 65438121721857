import type {ExhibitionFile, ExhibitionImage} from "./types";

export const parseExhibitionFile: ExhibitionFile = (datum) => {
    if (!datum) return
    return {
        id: datum.get('id'),
        isTemporary: datum.get('isTemporary'),
        name: datum.get('name'),
        description: datum.get('description'),
        url: datum.get('url'),
        type: datum.get('type'),
        messageTemplateTypeId: datum.get('messageTemplateTypeId'),
        language: datum.get('language'),
        fileDisplayOrder: datum.get('fileDisplayOrder')
    }
}

export const parseExhibitionImage: ExhibitionImage = (datum) => {
    if (!datum) return
    return {
        id: datum.get('id'),
        url: datum.get('url'),
        isLatest: datum.get('isLatest')
    }
}
