// @flow
import * as React from 'react';
import {Checkbox, FormControl, FormHelperText, InputLabel, OutlinedInput, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {ErrorMessage} from "formik";
import {VISITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import SelectCountry from "./selectCountry";

type Props = {

};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

export const showOption = (language) => (option) => {
    if (typeof option === 'string') {
        return option
    }
    if (typeof option.description === 'string') {
        return option.description
    }
    return option.description[language]
}

const DropDown = (props: Props) => {
    const {formik, values, field, value, handleBlur, handleChange, touched, errors, disabled, defaultCountry, defaultPhoneCode} = props
    const {t} = useTranslation(VISITOR_PROFILE)
    // const {setFieldValue} = useFormikContext()
    // const {exhibitionId, interests, countries} = useContext(PreregistrationFormContext)
    // if (field.id === "businessCategories") {
    //     return (
    //         <SelectExhibitionBusinessCategories
    //             exhibitionId={exhibitionId}
    //             selected={value}
    //             onChange={(v) => setFieldValue("fieldValues." + field.id, v)}
    //         />
    //     )
    // }
    // if (field.id === "interests") {
    //     return (
    //         <SelectExhibitionInterests
    //             field={field}
    //             errors={errors}
    //             touched={touched}
    //             exhibitionId={exhibitionId}
    //             selected={value}
    //             interests={interests}
    //             onChange={(v) => {setFieldValue("fieldValues." + field.id, v)}}
    //         />
    //     )
    // }
    // if (field.id === "characterization") {
    //     return (
    //         <ChangeVisitorCharacterization
    //             exhibitionId={exhibitionId}
    //             selected={value}
    //             onChange={(category: VisitorCategory) => {
    //                 setFieldValue("fieldValues." + field.id, category && category.id !== undefined ? category.id : "")
    //             }}
    //         />
    //     )
    // }
    if (field.id === "countries") {
        return (
            <SelectCountry
                defaultCountry={defaultCountry}
                formik={formik}
                values={values}
                field={field}
                value={value}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                disabled={disabled}
            />
        )
    }

    return (
        <FormControl
            size={"small"}
            fullWidth
            // margin={"dense"}
            sx={{bgcolor: "background.paper"}}
            error={
                touched &&
                touched["fieldValues"] &&
                touched["fieldValues"][field.id] &&
                Boolean(errors["fieldValues"] && errors["fieldValues"][field.id])
            }
        >
            <InputLabel id={"input" + field.id}>{t(field.name)}</InputLabel>
            <Select
                autoComplete={"off"}
                disabled={disabled}
                labelId={"input" + field.id}
                multiple={field.multiple}
                value={value}
                name={"fieldValues." + field.id}
                onBlur={handleBlur}
                onChange={handleChange}
                MenuProps={MenuProps}
                input={<OutlinedInput label={field.name}/>}
                renderValue={() => value.join(', ')}
            >
                {field.items && field.items.map((item) => (
                    <MenuItem key={item} value={item}>
                        <Checkbox checked={value.indexOf(item) > -1}/>
                        <ListItemText primary={item}/>
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>
                <ErrorMessage name={"fieldValues." + field.id}/>
            </FormHelperText>
        </FormControl>
    )
};

export default DropDown