import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import * as actions from '../actions/actions'
import {requestCreator} from '../helpers/requestCreator'
import {API, PortalToken} from "../helpers/constants";

const checkAPIConnectivity = requestCreator({
	nonApiUrl: true,
	url: API + '/portcheck.txt?t=' + (new Date()).getTime(),
	method: 'GET',
	onSuccess: Actions.checkAPIConnectivitySucceeded,
	onFailure: Actions.checkAPIConnectivityFailed
})

const userLogin = requestCreator({
	url: '/login',
	method: 'POST',
	onSuccess: Actions.userLoginSucceeded,
	onFailure: Actions.userLoginFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		'TokenGuid': PortalToken
	}
})

const userLogout = requestCreator({
	url: '/logout',
	method: 'GET',
	onSuccess: Actions.userLogoutSucceeded,
	onFailure: Actions.userLogoutFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		'TokenGuid': PortalToken
	}
})

// Exhibition ----------------------------------------------
const getExhibitions = requestCreator({
	url: '/getExhibitions',
	method: 'GET',
	onSuccess: Actions.getExhibitionsSucceeded,
	onFailure: Actions.getExhibitionsFailed,
})

const getExhibitionsList = requestCreator({
	url: '/getExhibitions',
	method: 'GET',
	onSuccess: Actions.getExhibitionsListSucceeded,
	onFailure: Actions.getExhibitionsListFailed,
})

const getActiveExhibitions = requestCreator({
	url: '/getActiveExhibitions',
	method: 'GET',
	onSuccess: Actions.getActiveExhibitionsSucceeded,
	onFailure: Actions.getActiveExhibitionsFailed,
})

const getExhibitionsGroupedHeaders = requestCreator({
	url: '/getExhibitionsGroupedHeader',
	method: 'GET',
	onSuccess: Actions.getExhibitionsGroupedHeadersSucceeded,
	onFailure: Actions.getExhibitionsGroupedHeadersFailed,
})

const getExhibitionsGroupedDetails = requestCreator({
	url: '/getExhibitionsGroupedDetails',
	method: 'GET',
	onSuccess: Actions.getExhibitionsGroupedDetailsSucceeded,
	onFailure: Actions.getExhibitionsGroupedDetailsFailed,
})

const getExhibitionDetails = requestCreator({
	url: '/exhibitionDetails/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getExhibitionDetailsSucceeded,
	onFailure: Actions.getExhibitionDetailsFailed,
})

const getExhibitionDetailsPublic = requestCreator({
	url: '/exhibitionDetailsPublic/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getExhibitionDetailsPublicSucceeded,
	onFailure: Actions.getExhibitionDetailsPublicFailed,
})

const updateExhibition = requestCreator({
	url: '/exhibition/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateExhibitionSucceeded,
	onFailure: Actions.updateExhibitionFailed,
})

const updateExhibitionLogo = requestCreator({
	url: '/exhibition/updatelogo/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateExhibitionLogoSucceeded,
	onFailure: Actions.updateExhibitionLogoFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const storeExhibitionAttachment = requestCreator({
	url: '/exhibition/attachment/store',
	method: 'POST',
	onSuccess: Actions.storeExhibitionAttachmentSucceeded,
	onFailure: Actions.storeExhibitionAttachmentFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const getFaqs = requestCreator({
	url: '/exhibition/faq/pagetitles/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getFaqsSucceeded,
	onFailure: Actions.getFaqsFailed,
})

const getFaqDetails = requestCreator({
	url: '/exhibition/faq/pagecontent/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getFaqDetailsSucceeded,
	onFailure: Actions.getFaqDetailsFailed,
})

const createFaq = requestCreator({
	url: '/exhibition/faq/store',
	method: 'POST',
	onSuccess: Actions.createFaqSucceeded,
	onFailure: Actions.createFaqFailed,
})

const updateFaq = requestCreator({
	url: '/exhibition/faq/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateFaqSucceeded,
	onFailure: Actions.updateFaqFailed,
})

const reorderFaqs = requestCreator({
	url: '/exhibition/faq/update-multiple',
	method: 'POST',
	onSuccess: Actions.reorderFaqsSucceeded,
	onFailure: Actions.reorderFaqsFailed,
})

const deleteFaq = requestCreator({
	url: '/exhibition/faq/delete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.deleteFaqSucceeded,
	onFailure: Actions.deleteFaqFailed,
})

const getExhibitionFiles = requestCreator({
	url: '/exhibition/attachment/list',
	method: 'GET',
	onSuccess: Actions.getExhibitionFilesSucceeded,
	onFailure: Actions.getExhibitionFilesFailed,
})

const getExhibitionFileDetails = requestCreator({
	url: '/exhibition/attachment/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getExhibitionFileDetailsSucceeded,
	onFailure: Actions.getExhibitionFileDetailsFailed,
})

const createExhibitionFile = requestCreator({
	url: '/exhibition/attachment/store',
	method: 'POST',
	headers: {
		'Content-Type': 'multipart/form-data',
	},
	onSuccess: Actions.createExhibitionFileSucceeded,
	onFailure: Actions.createExhibitionFileFailed,
})

const updateExhibitionFile = requestCreator({
	url: '/exhibition/attachment/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateExhibitionFileSucceeded,
	onFailure: Actions.updateExhibitionFileFailed,
})

const deleteExhibitionFile = requestCreator({
	url: '/exhibition/attachment/delete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.deleteExhibitionFileSucceeded,
	onFailure: Actions.deleteExhibitionFileFailed,
})

const updateQuickPrintFooterImage = requestCreator({
	url: '/exhibition/updatequickprintfooter/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateQuickPrintFooterImageSucceeded,
	onFailure: Actions.updateQuickPrintFooterImageFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const deleteQuickPrintFooterImage = requestCreator({
	url: '/exhibition/deletequickprintfooter/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.deleteQuickPrintFooterImageSucceeded,
	onFailure: Actions.deleteQuickPrintFooterImageFailed,
})

const getExhibitionImages = requestCreator({
	url: '/exhibition/image/list/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getExhibitionImagesSucceeded,
	onFailure: Actions.getExhibitionImagesFailed
})

const createExhibitionImage = requestCreator({
	url: '/exhibition/image/store',
	method: 'POST',
	onSuccess: Actions.createExhibitionImageSucceeded,
	onFailure: Actions.createExhibitionImageFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const deleteExhibitionImage = requestCreator({
	url: '/exhibition/image/delete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.deleteExhibitionImageSucceeded,
	onFailure: Actions.deleteExhibitionImageFailed
})

const copyExhibitionSettings = requestCreator({
	url: '/exhibition/copy',
	method: 'POST',
	onSuccess: Actions.copyExhibitionSettingsSucceeded,
	onFailure: Actions.copyExhibitionSettingsFailed
})

// Badge ----------------------------------------------
const getBadge = requestCreator({
	url: '/exhibition/badge/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getBadgeSucceeded,
	onFailure: Actions.getBadgeFailed,
})

const getBadgeConfiguration = requestCreator({
	url: '/exhibition/badges/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getBadgeConfigurationSucceeded,
	onFailure: Actions.getBadgeConfigurationFailed,
})

const updateBadgeConfiguration = requestCreator({
	url: '/exhibition/badges/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateBadgeConfigurationSucceeded,
	onFailure: Actions.updateBadgeConfigurationFailed,
})

const getBadgeTemplateTypes = requestCreator({
	url: '/exhibition/badges/templatetypes',
	method: 'GET',
	onSuccess: Actions.getBadgeTemplateTypesSucceeded,
	onFailure: Actions.getBadgeTemplateTypesFailed,
})

// Visitor ----------------------------------------------
const setVisitorApprovalStatus = requestCreator({
	url: '/badge/scan/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.setVisitorApprovalStatusSucceeded,
	onFailure: Actions.setVisitorApprovalStatusFailed,
	overrideTask: async () => {
		return ({
			data: {
				message: "success"
			}
		})
	}
})

const getVisitorProfile = requestCreator({
	url: '/visitorProfile/:id/:exhibitionId',
	hasPathParameters: ['id', 'exhibitionId'],
	method: 'GET',
	onSuccess: Actions.getVisitorProfileSucceeded,
	onFailure: Actions.getVisitorProfileFailed,
})


const createVisitor = requestCreator({
	url: '/visitor/store',
	method: 'POST',
	onSuccess: Actions.createVisitorSucceeded,
	onFailure: Actions.createVisitorFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const updateVisitor = requestCreator({
	url: '/visitor/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateVisitorSucceeded,
	onFailure: Actions.updateVisitorFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const createBulkVisitors = requestCreator({
	url: '/visitor/import',
	method: 'POST',
	onSuccess: Actions.createBulkVisitorsSucceeded,
	onFailure: Actions.createBulkVisitorsFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const afterCreateBulkVisitorsSucceeded = requestCreator({
	overrideTask: async (responseData, {parameters, dispatch}) => {
		const {ExhibitorId, ExhibitionId} = Object.fromEntries(parameters)
		const {ids} = responseData
		if (!(ids && ids.length > 0)) return
		if (ExhibitorId) {
			dispatch(actions.getExhibitorProfile({
				id: ExhibitorId,
				exhibitionId: ExhibitionId
			}))
		} else {
			dispatch(actions.getExhibitionDetails({
				id: ExhibitionId
			}))
		}
	}
})

const createBulkVisitorsForBadges = requestCreator({
	url: '/visitor/import-organizer-short',
	method: 'POST',
	onSuccess: Actions.creatBulkVisitorsForBadgesSucceeded,
	onFailure: Actions.creatBulkVisitorsForBadgesFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const deleteMultipleVisitors = requestCreator({
	url: '/visitor/delete-multiple',
	method: 'POST',
	onSuccess: Actions.deleteMultipleVisitorsSucceeded,
	onFailure: Actions.deleteMultipleVisitorsFailed,
})

const removeVisitor = requestCreator({
	url: '/visitor/delete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.removeVisitorSucceeded,
	onFailure: Actions.removeVisitorFailed,
})

const sendVisitorInvitations = requestCreator({
	url: '/exhibitor/:exhibitorId/visitor/sendinvitations',
	hasPathParameters: ['exhibitorId'],
	method: 'POST',
	onSuccess: Actions.sendVisitorInvitationsSucceeded,
	onFailure: Actions.sendVisitorInvitationsFailed,
})

const getVisitorExhibitors = requestCreator({
	url: '/visitorExhibitors/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getVisitorExhibitorsSucceeded,
	onFailure: Actions.getVisitorExhibitorsFailed,
})

// Exhibitor ----------------------------------------------
const getExhibitorProfile = requestCreator({
	url: '/exhibitorProfile/:id/:exhibitionId',
	hasPathParameters: ['id', 'exhibitionId'],
	method: 'GET',
	onSuccess: Actions.getExhibitorProfileSucceeded,
	onFailure: Actions.getExhibitorProfileFailed,
})

const updateExhibitorProfile = requestCreator({
	url: '/exhibitor/update/:id/:exhibitorId',
	hasPathParameters: ['id', 'exhibitorId'],
	method: 'POST',
	onSuccess: Actions.updateExhibitorProfileSucceeded,
	onFailure: Actions.updateExhibitorProfileFailed,
})

const sendExhibitorInvitations = requestCreator({
	url: '/exhibitor/sendinvitations',
	method: 'POST',
	onSuccess: Actions.sendExhibitorInvitationsSucceeded,
	onFailure: Actions.sendExhibitorInvitationsFailed,
})

const exhibitorCatalogueConfirm = requestCreator({
	url: '/exhibitor/confirm/:exhibitionId/:exhibitorId',
	hasPathParameters: ['exhibitionId', 'exhibitorId'],
	method: 'POST',
	onSuccess: Actions.exhibitorCatalogueConfirmSucceeded,
	onFailure: Actions.exhibitorCatalogueConfirmFailed,
})

const exhibitorCatalogueConfirmReset = requestCreator({
	url: '/exhibitor/reset-confirm/:exhibitionId/:exhibitorId',
	hasPathParameters: ['exhibitionId', 'exhibitorId'],
	method: 'POST',
	onSuccess: Actions.exhibitorCatalogueConfirmResetSucceeded,
	onFailure: Actions.exhibitorCatalogueConfirmResetFailed,
})

// Partner ----------------------------------------------
const createPartner = requestCreator({
	url: '/partner/store',
	method: 'POST',
	onSuccess: Actions.createPartnerSucceeded,
	onFailure: Actions.createPartnerFailed,
})

const createBulkPartners = requestCreator({
	url: '/partner/import',
	method: 'POST',
	onSuccess: Actions.createBulkPartnersSucceeded,
	onFailure: Actions.createBulkPartnersFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

const afterCreateBulkPartnersSucceeded = requestCreator({
	overrideTask: async (responseData, {parameters, dispatch}) => {
		const {ExhibitorId, ExhibitionId} = Object.fromEntries(parameters)
		const {ids} = responseData
		if (!(ids && ids.length > 0)) return
		dispatch(actions.getExhibitorProfile({
			id: ExhibitorId,
			exhibitionId: ExhibitionId
		}))
	}
})

const removePartner = requestCreator({
	url: '/partner/delete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.removePartnerSucceeded,
	onFailure: Actions.removePartnerFailed,
})

const sendPartnerBadges = requestCreator({
	url: '/partner/sendbadges',
	method: 'POST',
	onSuccess: Actions.sendPartnerBadgesSucceeded,
	onFailure: Actions.sendPartnerBadgesFailed,
})

// Countries ----------------------------------------------
const getCountries = requestCreator({
	url: '/country/list',
	method: 'GET',
	onSuccess: Actions.getCountriesSucceeded,
	onFailure: Actions.getCountriesFailed,
})

// Interests ----------------------------------------------
const getInterests = requestCreator({
	url: '/exhibition/interests/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getInterestsSucceeded,
	onFailure: Actions.getInterestsFailed,
})

const updateInterests = requestCreator({
	url: '/exhibition/interests/:exhibitionId/:interestId',
	hasPathParameters: ['exhibitionId', 'interestId'],
	method: 'POST',
	onSuccess: Actions.updateInterestsSucceeded,
	onFailure: Actions.updateInterestsFailed,
})

// Visitor Categories ----------------------------------------------
const getVisitorCategories = requestCreator({
	url: '/exhibition/characterizations/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getVisitorCategoriesSucceeded,
	onFailure: Actions.getVisitorCategoriesFailed,
})

const saveVisitorCategories = requestCreator({
	url: '/exhibition/characterizations/store',
	method: 'POST',
	onSuccess: Actions.saveVisitorCategoriesSucceeded,
	onFailure: Actions.saveVisitorCategoriesFailed,
})

const updateVisitorCategories = requestCreator({
	url: '/exhibition/characterizations/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateVisitorCategoriesSucceeded,
	onFailure: Actions.updateVisitorCategoriesFailed,
})

const deleteVisitorCategory = requestCreator({
	url: '/exhibition/characterizations/delete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.deleteVisitorCategorySucceeded,
	onFailure: Actions.deleteVisitorCategoryFailed,
})


// Business Categories ----------------------------------------------
const getExhibitionBusinessCategories = requestCreator({
	url: '/exhibition/businesscategs/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getExhibitionBusinessCategoriesSucceeded,
	onFailure: Actions.getExhibitionBusinessCategoriesFailed,
})

// Messages ---------------------------------------------------
const getExhibitionEmails = requestCreator({
	url: '/exhibition/emails/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getExhibitionEmailsSucceeded,
	onFailure: Actions.getExhibitionEmailsFailed,
})

const getMessageTemplateTypes = requestCreator({
	url: '/exhibition/template/types',
	method: 'GET',
	onSuccess: Actions.getMessageTemplateTypesSucceeded,
	onFailure: Actions.getMessageTemplateTypesFailed,
})

const getMessageTemplateTypeVariables = requestCreator({
	url: '/exhibition/template/:typeId/variables',
	hasPathParameters: ['typeId'],
	method: 'GET',
	onSuccess: Actions.getMessageTemplateTypeVariablesSucceeded,
	onFailure: Actions.getMessageTemplateTypeVariablesFailed,
})

const getMessageTemplate = requestCreator({
	url: '/exhibition/:exhibitionId/template/:typeId/view',
	hasPathParameters: ['exhibitionId', 'typeId'],
	method: 'GET',
	onSuccess: Actions.getMessageTemplateSucceeded,
	onFailure: Actions.getMessageTemplateFailed,
})

const updateMessageTemplate = requestCreator({
	url: '/exhibition/:exhibitionId/template/:typeId/update',
	hasPathParameters: ['exhibitionId', 'typeId'],
	method: 'POST',
	onSuccess: Actions.updateMessageTemplateSucceeded,
	onFailure: Actions.updateMessageTemplateFailed,
})

// Transfers -----------------------------------------------
const transferExhibitorsFromEntersoft = requestCreator({
	url: '/exhibition/transferData',
	method: 'POST',
	onSuccess: Actions.transferExhibitorsFromEntersoftSucceeded,
	onFailure: Actions.transferExhibitorsFromEntersoftFailed,
})

const transferExhibitorsToEntersoft = requestCreator({
	url: '/exhibition/transferExhibitors/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.transferExhibitorsToEntersoftSucceeded,
	onFailure: Actions.transferExhibitorsToEntersoftFailed,
})

// Print Badge Rules --------------------------------------------
const getRules = requestCreator({
	url: '/exhibition/characterizations/rules/:exhibitionId',
	hasPathParameters: ['exhibitionId'],
	method: 'GET',
	onSuccess: Actions.getRulesSucceeded,
	onFailure: Actions.getRulesFailed,
})

const saveRule = requestCreator({
	url: '/exhibition/characterizations/rules/store',
	method: 'POST',
	onSuccess: Actions.saveRuleSucceeded,
	onFailure: Actions.saveRuleFailed,
})

const updateRule = requestCreator({
	url: '/exhibition/characterizations/rules/update/:ruleId',
	hasPathParameters: ['ruleId'],
	method: 'POST',
	onSuccess: Actions.updateRuleSucceeded,
	onFailure: Actions.updateRuleFailed,
})

const deleteRule = requestCreator({
	url: '/exhibition/characterizations/rules/delete/:ruleId',
	hasPathParameters: ['ruleId'],
	method: 'POST',
	onSuccess: Actions.deleteRuleSucceeded,
	onFailure: Actions.deleteRuleFailed,
})

const getRuleDetails = requestCreator({
	url: '/exhibition/characterizations/ruledetails/:ruleId',
	hasPathParameters: ['ruleId'],
	method: 'GET',
	onSuccess: Actions.getRuleDetailsSucceeded,
	onFailure: Actions.getRuleDetailsFailed,
})

const saveRuleDetails = requestCreator({
	url: '/exhibition/characterizations/ruledetails/store',
	method: 'POST',
	onSuccess: Actions.saveRuleDetailsSucceeded,
	onFailure: Actions.saveRuleDetailsFailed,
})

const updateRuleDetails = requestCreator({
	url: '/exhibition/characterizations/ruledetails/update/:ruleDetailsId',
	hasPathParameters: ['ruleDetailsId'],
	method: 'POST',
	onSuccess: Actions.updateRuleDetailsSucceeded,
	onFailure: Actions.updateRuleDetailsFailed,
})

const deleteRuleDetails = requestCreator({
	url: '/exhibition/characterizations/ruledetails/delete/:ruleDetailsId',
	hasPathParameters: ['ruleDetailsId'],
	method: 'POST',
	onSuccess: Actions.deleteRuleDetailsSucceeded,
	onFailure: Actions.deleteRuleDetailsFailed,
})

// Coorganizations -------------------------------------
const getCoorganizations = requestCreator({
	url: '/coorganization/list',
	method: 'GET',
	onSuccess: Actions.getCoorganizationsSucceeded,
	onFailure: Actions.getCoorganizationsFailed,
})

const saveCoorganization = requestCreator({
	url: '/coorganization/store',
	method: 'POST',
	onSuccess: Actions.saveCoorganizationSucceeded,
	onFailure: Actions.saveCoorganizationFailed,
})

const updateCoorganization = requestCreator({
	url: '/coorganization/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateCooranizationSucceeded,
	onFailure: Actions.updateCooranizationFailed,
})

const deleteCoorganization = requestCreator({
	url: '/coorganization/delete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.deleteCoorganizationSucceeded,
	onFailure: Actions.deleteCoorganizationFailed,
})

const addExhibitionToCoorganization = requestCreator({
	url: '/coorganization/add/:exhibitionId/:coorganizationId',
	hasPathParameters: ['exhibitionId', 'coorganizationId'],
	method: 'POST',
	onSuccess: Actions.addExhibitionToCoorganizationSucceeded,
	onFailure: Actions.addExhibitionToCoorganizationFailed,
})

const removeExhibitionFromCoorganization = requestCreator({
	url: '/coorganization/remove/:exhibitionId',
	hasPathParameters: ['exhibitionId'],
	method: 'POST',
	onSuccess: Actions.removeExhibitionFromCoorganizationSucceeded,
	onFailure: Actions.removeExhibitionFromCoorganizationFailed,
})

// Reports ----------------------------------------------------
const getReports = requestCreator({
	url: '/exhibition/reports',
	method: 'POST',
	onSuccess: Actions.getReportDataSucceeded,
	onFailure: Actions.getReportDataFailed,
})

// Grid State -------------------------------------------------
const getGridState = requestCreator({
	url: '/uigrid/state/load',
	method: 'POST',
	onSuccess: Actions.getGridStateSucceeded,
	onFailure: Actions.getGridStateFailed,
})

const saveGridState = requestCreator({
	url: '/uigrid/state/save',
	method: 'POST',
	onSuccess: Actions.saveGridStateSucceeded,
	onFailure: Actions.saveGridStateFailed,
})

const deleteGridState = requestCreator({
	url: '/uigrid/state/save',
	method: 'POST',
	onSuccess: Actions.deleteGridStateSucceeded,
	onFailure: Actions.deleteGridStateFailed,
})

const setActiveGridState = requestCreator({
	url: '/uigrid/state/save',
	method: 'POST',
	onSuccess: Actions.setActiveGridStateSucceeded,
	onFailure: Actions.setActiveGridStateFailed,
})

// Customize UI messages to exhibitor pages
const getUITranslations = requestCreator({
	url: '/uitranslation/get',
	method: 'POST',
	onSuccess: Actions.getUITranslationsSucceeded,
	onFailure: Actions.getUITranslationsFailed,
})

const updateUITranslations = requestCreator({
	url: '/uitranslation/update',
	method: 'POST',
	onSuccess: Actions.updateUITranslationSucceeded,
	onFailure: Actions.updateUITranslationFailed,
})

const requestHandler = {
	[ActionTypes.USER_LOGIN]: userLogin,
	[ActionTypes.USER_LOGOUT]: userLogout,
	[ActionTypes.REORDER_FAQS]: reorderFaqs,
	[ActionTypes.GET_EXHIBITIONS_LIST]: getExhibitionsList,
	[ActionTypes.GET_EXHIBITIONS]: getExhibitions,
	[ActionTypes.GET_ACTIVE_EXHIBITIONS]: getActiveExhibitions,
	[ActionTypes.GET_EXHIBITION_DETAILS]: getExhibitionDetails,
	[ActionTypes.GET_EXHIBITION_DETAILS_PUBLIC]: getExhibitionDetailsPublic,
	[ActionTypes.GET_BADGE]: getBadge,
	[ActionTypes.SET_VISITOR_APPROVAL_STATUS]: setVisitorApprovalStatus,
	[ActionTypes.GET_EXHIBITOR_PROFILE]: getExhibitorProfile,
	[ActionTypes.GET_VISITOR_PROFILE]: getVisitorProfile,
	[ActionTypes.UPDATE_EXHIBITION]: updateExhibition,
	[ActionTypes.UPDATE_EXHIBITION_LOGO]: updateExhibitionLogo,
	[ActionTypes.UPDATE_EXHIBITOR_PROFILE]: updateExhibitorProfile,
	[ActionTypes.CREATE_VISITOR]: createVisitor,
	[ActionTypes.UPDATE_VISITOR]: updateVisitor,
	[ActionTypes.REMOVE_VISITOR]: removeVisitor,
	[ActionTypes.CREATE_BULK_VISITORS]: createBulkVisitors,
	[ActionTypes.CREATE_BULK_VISITORS_SUCCEEDED]: afterCreateBulkVisitorsSucceeded,
	[ActionTypes.CREATE_BULK_VISITORS_FOR_BADGES]: createBulkVisitorsForBadges,
	[ActionTypes.CREATE_PARTNER]: createPartner,
	[ActionTypes.CREATE_BULK_PARTNERS]: createBulkPartners,
	[ActionTypes.CREATE_BULK_PARTNERS_SUCCEEDED]: afterCreateBulkPartnersSucceeded,
	[ActionTypes.STORE_EXHIBITION_ATTACHMENT]: storeExhibitionAttachment,
	[ActionTypes.GET_FAQS]: getFaqs,
	[ActionTypes.GET_FAQ_DETAILS]: getFaqDetails,
	[ActionTypes.CREATE_FAQ]: createFaq,
	[ActionTypes.UPDATE_FAQ]: updateFaq,
	[ActionTypes.DELETE_FAQ]: deleteFaq,
	[ActionTypes.GET_EXHIBITION_FILES]: getExhibitionFiles,
	[ActionTypes.GET_EXHIBITION_FILE_DETAILS]: getExhibitionFileDetails,
	[ActionTypes.CREATE_EXHIBITION_FILE]: createExhibitionFile,
	[ActionTypes.UPDATE_EXHIBITION_FILE]: updateExhibitionFile,
	[ActionTypes.DELETE_EXHIBITION_FILE]: deleteExhibitionFile,
	[ActionTypes.GET_VISITOR_CATEGORIES]: getVisitorCategories,
	[ActionTypes.SAVE_VISITOR_CATEGORIES]: saveVisitorCategories,
	[ActionTypes.UPDATE_VISITOR_CATEGORIES]: updateVisitorCategories,
	[ActionTypes.DELETE_VISITOR_CATEGORY]: deleteVisitorCategory,
	[ActionTypes.REMOVE_PARTNER]: removePartner,
	[ActionTypes.SEND_VISITOR_INVITATIONS]: sendVisitorInvitations,
	[ActionTypes.SEND_EXHIBITOR_INVITATIONS]: sendExhibitorInvitations,
	[ActionTypes.SEND_PARTNER_BADGES]: sendPartnerBadges,
	[ActionTypes.GET_VISITOR_EXHIBITORS]: getVisitorExhibitors,
	[ActionTypes.GET_EXHIBITION_BUSINESS_CATEGORIES]: getExhibitionBusinessCategories,
	[ActionTypes.GET_INTERESTS]: getInterests,
	[ActionTypes.UPDATE_INTERESTS]: updateInterests,
	[ActionTypes.GET_BADGE_CONFIGURATION]: getBadgeConfiguration,
	[ActionTypes.UPDATE_BADGE_CONFIGURATION]: updateBadgeConfiguration,
	[ActionTypes.GET_EXHIBITION_EMAILS]: getExhibitionEmails,
	[ActionTypes.GET_COUNTRIES]: getCountries,
	[ActionTypes.GET_MESSAGE_TEMPLATE_TYPES]: getMessageTemplateTypes,
	[ActionTypes.GET_MESSAGE_TEMPLATE_TYPE_VARIABLES]: getMessageTemplateTypeVariables,
	[ActionTypes.GET_MESSAGE_TEMPLATE]: getMessageTemplate,
	[ActionTypes.UPDATE_MESSAGE_TEMPLATE]: updateMessageTemplate,
	[ActionTypes.GET_BADGE_TEMPLATE_TYPES]: getBadgeTemplateTypes,
	[ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
	[ActionTypes.TRANSFER_EXHIBITORS_FROM_ENTERSOFT]: transferExhibitorsFromEntersoft,
	[ActionTypes.TRANSFER_EXHIBITORS_TO_ENTERSOFT]: transferExhibitorsToEntersoft,
	[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM]: exhibitorCatalogueConfirm,
	[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_RESET]: exhibitorCatalogueConfirmReset,
	[ActionTypes.GET_RULES]: getRules,
	[ActionTypes.SAVE_RULE]: saveRule,
	[ActionTypes.UPDATE_RULE]: updateRule,
	[ActionTypes.DELETE_RULE]: deleteRule,
	[ActionTypes.GET_RULE_DETAILS]: getRuleDetails,
	[ActionTypes.SAVE_RULE_DETAILS]: saveRuleDetails,
	[ActionTypes.UPDATE_RULE_DETAILS]: updateRuleDetails,
	[ActionTypes.DELETE_RULE_DETAILS]: deleteRuleDetails,
	[ActionTypes.GET_COORGANIZATIONS]: getCoorganizations,
	[ActionTypes.SAVE_COORGANIZATION]: saveCoorganization,
	[ActionTypes.UPDATE_COORGANIZATION]: updateCoorganization,
	[ActionTypes.DELETE_COORGANIZATION]: deleteCoorganization,
	[ActionTypes.ADD_EXHIBITION_TO_COORGANIZATION]: addExhibitionToCoorganization,
	[ActionTypes.REMOVE_EXHIBITION_FROM_COORGANIZATION]: removeExhibitionFromCoorganization,
	[ActionTypes.DELETE_QUICK_PRINT_FOOTER_IMAGE]: deleteQuickPrintFooterImage,
	[ActionTypes.UPDATE_QUICK_PRINT_FOOTER_IMAGE]: updateQuickPrintFooterImage,
	[ActionTypes.GET_EXHIBITION_IMAGES]: getExhibitionImages,
	[ActionTypes.CREATE_EXHIBITION_IMAGE]: createExhibitionImage,
	[ActionTypes.DELETE_EXHIBITION_IMAGE]: deleteExhibitionImage,
	[ActionTypes.DELETE_MULTIPLE_VISITORS]: deleteMultipleVisitors,
	[ActionTypes.GET_REPORT_DATA]: getReports,
	[ActionTypes.GET_GRID_STATE]: getGridState,
	[ActionTypes.SAVE_GRID_STATE]: saveGridState,
	[ActionTypes.DELETE_GRID_STATE]: deleteGridState,
	[ActionTypes.SET_ACTIVE_GRID_STATE]: setActiveGridState,
	[ActionTypes.GET_EXHIBITIONS_GROUPED_HEADERS]: getExhibitionsGroupedHeaders,
	[ActionTypes.GET_EXHIBITIONS_GROUPED_DETAILS]: getExhibitionsGroupedDetails,
	[ActionTypes.GET_UI_TRANSLATIONS]: getUITranslations,
	[ActionTypes.UPDATE_UI_TRANSLATION]: updateUITranslations,
	[ActionTypes.COPY_EXHIBITION_SETTINGS]: copyExhibitionSettings,
}

export default requestHandler
