import type {Visitor} from "../../../types/exhibition/types";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import useExhibitionAction from "../../../hooks/exhibitions/useExhibitionAction";
import FormControl from "@mui/material/FormControl";
import {FormHelperText, InputLabel, ListItem, OutlinedInput, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {useCategories} from "../../../hooks/categories/useCategories";
import {ErrorMessage} from "formik";

type Props = {
    exhibitionId: string,
    visitor?: Visitor,
    onChange?: Function
}

const ChangeVisitorCharacterization = (props: Props) => {
    const {onChange, visitor, exhibitionId, selected, formik, required, shrink = true} = props
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {loading, categories} = useCategories(exhibitionId)
    const {updateVisitor} = useExhibitionAction()
    const onCharacterizationChange = (event) => {
        const value = event.target.value
        if (visitor) {
            updateVisitor(exhibitionId, visitor.id, {
                characterization: value
            })
        } else {
            onChange(categories.find(c => c.id === value))
        }
    }

    return (
        <FormControl
            fullWidth
            size={"small"}
            error={
                formik.touched["characterization"] &&
                Boolean(formik.errors["characterization"])
            }
        >
            <InputLabel
                required={required}
                id={'characterization-label'}
                shrink={shrink}
            >
                {t("Characterization")}
            </InputLabel>
            <Select
                autoComplete={"off"}
                size={"small"}
                value={selected}
                labelId="characterization-label"
                label={t("Characterization")}
                onChange={onCharacterizationChange}
                onBlur={formik.handleBlur}
                input={<OutlinedInput notched={shrink} label={'characterization'}/>}
            >
                <MenuItem dense value="">
                    <em>{t('None')}</em>
                </MenuItem>
                {!loading && categories.map(c => (
                    <MenuItem
                        dense
                        key={c.id}
                        value={c.id}
                    >
                        <ListItem component={"div"}>
                            {c.description}
                        </ListItem>
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>
                <ErrorMessage name={"characterization"}/>
            </FormHelperText>
        </FormControl>
    )
}

export default ChangeVisitorCharacterization
