//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import {parseCountries} from "../../types/countries/parse";
import type {Countries} from "../../types/countries/type";
import _ from "lodash";

const selectLoading = createSelector(
    (state) => state.countries.get('loading'),
    (item) => item
)

const selectItems = createSelector(
    [
        (state) => state.countries.get('byId'),
        (state) => state.currentUser.get('language')
    ],
    (byId, userLanguage) => parseMultiple(byId, (i) => parseCountries(i, userLanguage))
)

const selectCountriesEn = createSelector(
    [
        (state) => state.countries.get('byId')
    ],
    (byId) => parseMultiple(byId, (i) => parseCountries(i, 'en'))
)

const selectCountriesEl = createSelector(
    [
        (state) => state.countries.get('byId')
    ],
    (byId) => parseMultiple(byId, (i) => parseCountries(i, 'el'))
)

export const useCountriesData = () => {
    const loading: boolean = useSelector(selectLoading)
    const countries: Array<Countries> = useSelector(selectItems)
    const countriesEl: Array<Countries> = useSelector(selectCountriesEl)
    const countriesEn: Array<Countries> = useSelector(selectCountriesEn)
    const sortedCountries = _.sortBy(countries, ['description'])
    const sortedCountriesEn = _.sortBy(countriesEn, ['description'])
    const sortedCountriesEl = _.sortBy(countriesEl, ['description'])
    const phoneCodes = _.sortBy(sortedCountries.map(country => ({
        id: `${country.id}`,
        country: country.description,
        description: `(+${country.phoneCode}) ${country.description}`,
        phoneCode: country.phoneCode,
        countryCode: country.countryCode,
        image: country.image
    })), ['country'])
    const phoneCodesEn = _.sortBy(sortedCountriesEn.map(country => ({
        id: `${country.id}`,
        country: country.description,
        description: `(+${country.phoneCode}) ${country.description}`,
        phoneCode: country.phoneCode,
        countryCode: country.countryCode,
        image: country.image
    })), ['country'])
    const phoneCodesEl = _.sortBy(sortedCountriesEl.map(country => ({
        id: `${country.id}`,
        country: country.description,
        description: `(+${country.phoneCode}) ${country.description}`,
        phoneCode: country.phoneCode,
        countryCode: country.countryCode,
        image: country.image
    })), ['country'])
    const defaultPhoneCode = phoneCodes.find(f => f.countryCode === 'GR')
    const defaultCountry = sortedCountries.find(f => f.countryCode === 'GR')
    return {
        loading,
        phoneCodes,
        phoneCodesEn,
        phoneCodesEl,
        sortedCountries,
        sortedCountriesEn,
        sortedCountriesEl,
        defaultCountry,
        defaultPhoneCode
    }
}