import u from 'immutability-helper';
import memoizeOne from 'memoize-one';

/**
 * @hidden
 */
function memoize(fn){
  return memoizeOne(fn);
}

/**
 * @hidden
 */
// @ts-ignore
const update = memoizeOne(u);

const findDescendants = (items, index) => {
  const item = items[index];
  const descendants: typeof items = [];

  for (let i = index + 1; i < items.length; i += 1) {
    const next = items[i];

    if (next.depth <= item.depth) {
      break;
    }

    descendants.push(next);
  }

  return descendants;
}

const findDeepestDescendant = (items, index) => {
  const descendants = findDescendants(items, index);
  if (descendants.length === 0) {
    return null;
  }
  return descendants.reduce(
    (accumulator, currentValue) => accumulator.id > currentValue.id ? accumulator : currentValue,
    descendants[0],
  )
}

const findParent = (items, index) => {
  if (index === 0) {
    return null;
  }

  const item = items[index];

  for (let i = index - 1; i >= 0; i -= 1) {
    const prev = items[i];
    if (prev.depth === item.depth - 1) {
      return prev;
    }
  }

  return null;
}

const getSiblings = (items, index) => {
  const item = items[index];
  const parent = findParent(items, index);

  if (!parent) {
    return items.filter(({ depth }) => depth === item.depth);
  }

  const descendants = findDescendants(items, items.indexOf(parent));
  return descendants.filter(({ depth }) => depth === item.depth);
}

const findNextSibling = (items, index) => {
  const item = items[index];

  for (let i = index + 1; i < items.length; i += 1) {
    const prev = items[i];
    if (prev.depth === item.depth) {
      return prev;
    }
  }
  return null;
}

const findPrevSibling = (items, index) => {
  const item = items[index];

  for (let i = index - 1; i >= 0; i -= 1) {
    const prev = items[i];
    if (prev.depth === item.depth) {
      return prev;
    }
  }

  return null;
};

const isNextSibling = (items, index, siblingIndex) => {
  const nextSibling = findNextSibling(items, index);
  return nextSibling !== null && items.indexOf(nextSibling) === siblingIndex;
};

const isPrevSibling = (items, index, siblingIndex) => {
  const prevSibling = findPrevSibling(items, index);
  return prevSibling !== null && items.indexOf(prevSibling) === siblingIndex;
};

const isClosestOf = (items, index, descendantIndex) => {
  if (index >= descendantIndex) {
    return false;
  }

  return findDescendants(items, index).includes(items[descendantIndex]);
};

const isDescendantOf = (items, index, closestIndex) => {
  if (index <= closestIndex) {
    return false;
  }

  return findDescendants(items, closestIndex).includes(items[index]);
};

const move = (items, sourceIndex, targetIndex) => {
  const sourceItem = items[sourceIndex];
  const targetItem = items[targetIndex];

  if (isClosestOf(items, sourceIndex, targetIndex)) {
    return items;
  }
  const diffDepth = targetItem.depth - sourceItem.depth;
  const descendants = findDescendants(items, sourceIndex);
  let movingItems = [sourceItem, ...descendants];
  const updateDepthFn: any = {};
  movingItems.forEach((item, index) => {
    updateDepthFn[index] = { depth: { $set: item.depth + diffDepth } };
  });
  movingItems = update(movingItems, updateDepthFn);

  const updateFn: any = {};
  let newIndex = targetIndex;

  if (sourceIndex < targetIndex) {
    const targetDescendants = findDescendants(items, targetIndex);
    newIndex = targetIndex + targetDescendants.length - descendants.length;
  }

  updateFn.$splice = [
    [sourceIndex, movingItems.length],
    [newIndex, 0, ...movingItems]
  ];

  return update(items, updateFn);
};

const updateDepth = (
  items,
  index,
  depth,
  maxDepth = Infinity
) => {
  depth = Math.max(depth, 0); // eslint-disable-line no-param-reassign
  const item = items[index];

  if (depth === item.depth) {
    return items;
  }

  if (depth > item.depth) {
    if (index === 0) {
      return items;
    }
    const prev = items[index - 1];
    depth = Math.min(depth, prev.depth + 1); // eslint-disable-line no-param-reassign
  } else if (findNextSibling(items, index)) {
    return items;
  }

  let offsetDepth = depth - item.depth;
  if (maxDepth < Infinity) {
    const itemToCheckMaxDepth = findDeepestDescendant(items, index) || item;

    if (itemToCheckMaxDepth.depth + offsetDepth > maxDepth) {
      offsetDepth = maxDepth - itemToCheckMaxDepth.depth;
    }
  }

  const descendants = findDescendants(items, index);
  const updateFn: any = {
    [index]: { depth: { $set: depth } }
  };

  descendants.forEach((descendant, i) => {
    updateFn[i + index + 1] = {
      depth: { $set: descendant.depth + offsetDepth }
    };
  });

  return update(items, updateFn);
};

const add = (items, data ) => {
  let newItems = (Array.isArray(data) ? data : [data]) // eslint-disable-line no-param-reassign
    .map((item) => ({ ...item, depth: item.depth || 0 }));
  const first = newItems[0];
  const reduceDepth = first.depth;

  if (reduceDepth > 0) {
    newItems = newItems.map((item) => ({ ...item, depth: item.depth - reduceDepth }));
  }

  return update(items, { $push: newItems });
};

const insert = (items, data , targetIndex) => {
  const currentItemAtIndex = items[targetIndex];
  const currentItemDescendants = findDescendants(items, targetIndex);
  const { depth } = currentItemAtIndex;
  const newItem = { ...data, depth };

  return update(items, {
    $splice: [[targetIndex + currentItemDescendants.length + 1, 0, newItem]]
  });
};

const remove = (items, index) => {
  const descendants = findDescendants(items, index);

  return update(items, {
    $splice: [[index, descendants.length + 1]]
  });
};

const convert = ( items, parentId, depth) => {
  const result = items
    .filter((item) => {
      if (parentId === undefined) {
        return !item.parentId;
      }
      return item.parentId === parentId;
    })
    .sort((a, b) => a.index - b.index)
    .map((item) => {
      const { index, parentId: parent, ...data } = item;
      return { ...data, depth: depth || 0 };
    });

  [...result].forEach((item) => {
    const children = convert(items, item.id, item.depth + 1);
    result.splice(result.indexOf(item) + 1, 0, ...children);
  });

  return result;
};

const buildTree = ( items, depth = 0) => {
  const buildChildren = (item) => {
    const descendants = findDescendants(items, items.indexOf(item));
    return buildTree(descendants, depth + 1);
  };
  const tree = items.filter((item) => item.depth === depth)
    .map((item) => {
      const { depth: d, ...data } = item;
      return {
        ...data,
        children: buildChildren(item)
      };
    });

  return tree;
};

const flatten = (items) => (
  items.map((item, index) => {
    const { depth, ...data } = item;
    const parent = findParent(items, index);
    const siblings = getSiblings(items, index);

    return {
      ...data,
      index: siblings.indexOf(item),
      parentId: parent ? parent.id : 0
    };
  })
);

/**
 * @hidden
 */
const memoizedFindDescendants = memoize(findDescendants) ;
/**
 * @hidden
 */
const memoizedFindDeepestDescendant = memoize(findDeepestDescendant) ;
/**
 * @hidden
 */
const memoizedFindParent = memoize(findParent) ;
/**
 * @hidden
 */
const memoizedFindNextSibling = memoize(findNextSibling) ;
/**
 * @hidden
 */
const memoizedFindPrevSibling = memoize(findPrevSibling) ;
/**
 * @hidden
 */
const memoizedIsNextSibling = memoize(isNextSibling) ;
/**
 * @hidden
 */
const memoizedIsPrevSibling = memoize(isPrevSibling) ;
/**
 * @hidden
 */
const memoizedIsClosestOf = memoize(isClosestOf) ;
/**
 * @hidden
 */
const memoizedIsDescendantOf = memoize(isDescendantOf);
/**
 * @hidden
 */
const memoizedMove = memoize(move) ;
/**
 * @hidden
 */
const memoizedUpdateDepth = memoize(updateDepth) ;

/**
 * @hidden
 */
const memoizedConvert = memoize(convert) ;

/**
 * @hidden
 */
const memoizedBuildTree = memoize(buildTree);

/**
 * @hidden
 */
const memoizedFlatten = memoize(flatten) ;

export {
  memoizedFindDescendants as findDescendants,
  memoizedFindDeepestDescendant as findDeepestDescendant,
  memoizedFindParent as findParent,
  memoizedFindNextSibling as findNextSibling,
  memoizedFindPrevSibling as findPrevSibling,
  memoizedIsNextSibling as isNextSibling,
  memoizedIsPrevSibling as isPrevSibling,
  memoizedIsClosestOf as isClosestOf,
  memoizedIsDescendantOf as isDescendantOf,
  memoizedMove as move,
  memoizedUpdateDepth as updateDepth,
  add,
  insert,
  remove,
  memoizedConvert as convert,
  memoizedBuildTree as buildTree,
  memoizedFlatten as flatten,
};
