import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {parseTranslations} from "../../types/exhibition/parse";
import {useMemo} from "react";
import {parseUserMessage} from "../../types/user/parse";
import _ from "lodash";

const selectMessage = createSelector(
	(state) => state.uiTranslations.get('message'),
	(item) => parseUserMessage(item)
)

const selectLoading = createSelector(
	(state) => state.uiTranslations.get('loading'),
	(item) => item
)

const selectUITranslations = createSelector(
	[
		(state) => state.uiTranslations.get('uiTranslations'),
		(state) => state.currentUser.get('language')
	],
	(item, language) => parseTranslations(item, language, false)
)

export const useUITranslationsData = () => {
	const loading: boolean = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const uiTranslations = useSelector(selectUITranslations)
	const codes = useMemo(() => {
		const codes = []
		for (let [key, value] of Object.entries(uiTranslations)) {
			codes.push({
				id: key,
				value: value.description
			})
		}
		return _.sortBy(codes, ['value'])
	}, [uiTranslations])
	return {
		loading,
		message,
		codes,
		uiTranslations
	}
}