// @flow
import React, {useState} from 'react';
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import useUserData from "../../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import MessagesGrid from "./partials/messagesGrid";
import useMessageTemplate from "../../../../hooks/messageTemplates/useMessageTemplate";
import TemplateTypeSelect from "../../../../components/form/actionBuilder/partials/templateTypeSelect";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import {TabPanel} from "../../../../components/general/tabPanel/tabPanel";
import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import ResendsPerDateGrid from "./partials/resendsPerDateGrid";

const Messages = () => {
    const {t} = useTranslation(EXHIBITION)
    const {language} = useUserData()
    const {exhibition, loading, id} = useExhibition({language: 'any'})
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => setValue(newValue)
    const {
        messageTemplateTypes,
        messageTemplateTypeId,
        onChangeMessageTemplateType
    } = useMessageTemplate(id, true, true)
    return exhibition && messageTemplateTypes && messageTemplateTypeId ? (
        <React.Fragment>
            <Breadcrumb>
                <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                    {t('Home')}
                </Breadcrumb.Link>
                <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
                    {exhibition.title[language]}
                </Breadcrumb.Link>
                <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
                    {t('Settings')}
                </Breadcrumb.Link>
                <Breadcrumb.Item>
                    {t('Sent Email/SMS List And Resends')}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Box sx={{maxWidth: '92vw'}}>
                <TemplateTypeSelect
                    label={t('messageTemplateType')}
                    messageTemplateTypes={messageTemplateTypes}
                    messageTemplateTypeId={messageTemplateTypeId}
                    onChangeMessageTemplateType={onChangeMessageTemplateType}
                />
                <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                >
                    <Tab value={0} label={normalizeGreek(t('Email/SMS List'))}/>
                    <Tab value={1} label={normalizeGreek(t('Resends Per Date'))}/>
                </Tabs>
                <TabPanel isVisible={value === 0} sx={{height: '100%'}}>
                    <MessagesGrid
                        exhibitionId={id}
                        messageTemplateTypeId={messageTemplateTypeId}
                        isSMS={messageTemplateTypes.find(f => f.id === messageTemplateTypeId)?.isSMS}
                        tabValue={0}
                    />
                </TabPanel>
                <TabPanel isVisible={value === 1} sx={{height: '100%'}}>
                    <ResendsPerDateGrid
                        exhibitionId={id}
                        messageTemplateTypeId={messageTemplateTypeId}
                        tabValue={1}
                    />
                </TabPanel>
            </Box>
        </React.Fragment>
    ) : (
        <ContentLoader
            loading={loading}
        />
    );
};

export default Messages