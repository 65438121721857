import {useCallback} from "react";
import {useExhibitionFileAction} from "./useExhibitionFileAction";
import type {ExhibitionFile} from "../../types/exhibitionFiles/types";

export const useExhibitionFileUpload = ({exhibitionId, typeId, messageTemplateTypeId} = {}) => {
	const {updateExhibitionFile, createExhibitionFile, deleteExhibitionFile} = useExhibitionFileAction()
	const getFiles = useCallback((files, language) => files
	.filter(f => (messageTemplateTypeId ? f.messageTemplateTypeId === messageTemplateTypeId && f.language === language : f.language === language))
	.map((f: ExhibitionFile) => ({
		isTemporary: f.isTemporary,
		id: f.id,
		alias: f.description,
		name: f.name,
		url: f.url,
		messageTemplateTypeId: f.messageTemplateTypeId,
		fileDisplayOrder: f.fileDisplayOrder
	})), [messageTemplateTypeId])
	const onUpdateAlias = (file, alias, fileDisplayOrder) => {
		updateExhibitionFile({
				exhibitionId: exhibitionId
			},
			file,
			alias,
			fileDisplayOrder
		)
	}
	const onFilesUpload = (file, language) => {
		createExhibitionFile({
				exhibitionId: exhibitionId,
				typeId: typeId,
				...(messageTemplateTypeId && {messageTemplateTypeId: messageTemplateTypeId})
			},
			file,
			language
		)
	}
	const onFileRemove = (file) => {
		deleteExhibitionFile({
				exhibitionId: exhibitionId
			},
			file.id
		)
	}
	return {
		onUpdateAlias,
		onFilesUpload,
		onFileRemove,
		getFiles
	}
}