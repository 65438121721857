import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapFAQ} from "../../types/exhibition/map";

const INITIAL_STATE = {
    loading: false,
    loadingFaq: {},
    updating: false,
    faqsByExhibitionId: {},
}

function getFaqs(state) {
    return state
        .set('loading', true)
}

function getFaqsSucceeded(state, action) {
    const {payload, parameters: {id}} = action
    const mappedData = mapMultiple(payload, mapFAQ)
    return state
        .setIn(['faqsByExhibitionId', id],
            state.hasIn(['faqsByExhibitionId', id]) ?
                state.getIn(['faqsByExhibitionId', id])
                    .mergeWith(
                        preserveFieldsIfNotPresentInNew(['content']),
                        fromJS(mappedData)
                    ) :
                fromJS(mappedData)
        )
        .set('loading', false)
}

function getFaqsFailed(state, action) {
    return state
        .set('loading', false)
}

function getFaqDetails(state, action) {
    const {id} = action.payload
    return state
        .setIn(['loadingFaq', id], true)
}

function getFaqDetailsSucceeded(state, action) {
    const {payload, parameters: {id, ExhibitionId}} = action
    const mappedData = mapFAQ(payload)
    return state
        .setIn(['loadingFaq', id], false)
        .setIn(['faqsByExhibitionId', ExhibitionId, String(id)], fromJS(mappedData))
}

function getFaqDetailsFailed(state, action) {
    const {parameters: {id}} = action
    return state
        .setIn(['loadingFaq', id], false)
}

function updateFaq(state, action) {
    const {payload} = action
    const mappedData = mapFAQ(payload)
    delete mappedData.parent
    delete mappedData.weight
    return state
        .set('loading', true)
        .mergeIn(['faqsByExhibitionId', payload.ExhibitionId, String(mappedData.id)], fromJS(mappedData))
}

function updateFaqSucceeded(state, action) {
    return state
        .set('loading', false)
}

function updateFaqFailed(state, action) {
    return state
        .set('loading', false)
}

function createFaq(state) {
    return state
        .set('loading', true)
}

function createFaqSucceeded(state, action) {
    const {payload: {id}, parameters: {ExhibitionId, ...createData}} = action
    const previousItems = state.getIn(['faqsByExhibitionId', ExhibitionId])
    const unmappedData = {
        ...createData,
        id: String(id),
        ParentId: null,
        Ordr: previousItems ? previousItems.size : 0
    }
    const mappedData = mapFAQ(unmappedData)
    return state
        .set('loading', false)
        .setIn(['faqsByExhibitionId', ExhibitionId, String(id)], fromJS(mappedData))
}

function createFaqFailed(state, action) {
    return state
        .set('loading', false)
}

function deleteFaq(state) {
    return state
        .set('loading', true)
}

function deleteFaqSucceeded(state, action) {
    const {parameters: {id, ExhibitionId}} = action
    return state
        .set('loading', false)
        .removeIn(['faqsByExhibitionId', ExhibitionId, String(id)])
}

function deleteFaqFailed(state, action) {
    return state
        .set('loading', false)
}

function reorderFaqs(state, action) {
    // const {faqs, ExhibitionId} = action.payload
    // const newFaqs = faqs.reduce((p, n) => ({...p, [n.id]: n}), {})
    // return state
        // .set('loading', true)
        // .setIn(['faqsByExhibitionId', ExhibitionId], fromJS(newFaqs))
}

const exhibition = {
    initialState: INITIAL_STATE, handlers: {
        [ActionTypes.GET_FAQS]: getFaqs,
        [ActionTypes.GET_FAQS_SUCCEEDED]: getFaqsSucceeded,
        [ActionTypes.GET_FAQS_FAILED]: getFaqsFailed,

        [ActionTypes.GET_FAQ_DETAILS]: getFaqDetails,
        [ActionTypes.GET_FAQ_DETAILS_SUCCEEDED]: getFaqDetailsSucceeded,
        [ActionTypes.GET_FAQ_DETAILS_FAILED]: getFaqDetailsFailed,

        [ActionTypes.UPDATE_FAQ]: updateFaq,
        [ActionTypes.UPDATE_FAQ_SUCCEEDED]: updateFaqSucceeded,
        [ActionTypes.UPDATE_FAQ_FAILED]: updateFaqFailed,

        [ActionTypes.CREATE_FAQ]: createFaq,
        [ActionTypes.CREATE_FAQ_SUCCEEDED]: createFaqSucceeded,
        [ActionTypes.CREATE_FAQ_FAILED]: createFaqFailed,

        [ActionTypes.DELETE_FAQ]: deleteFaq,
        [ActionTypes.DELETE_FAQ_SUCCEEDED]: deleteFaqSucceeded,
        [ActionTypes.DELETE_FAQ_FAILED]: deleteFaqFailed,
        // [ActionTypes.REORDER_FAQS]: reorderFaqs,
    }
}

export default exhibition
