//@flow
import React from 'react';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import AddressLine from "./addressLine";
import {showNoValueIfDisabled} from "./catalog";
import {FormControl, FormHelperText} from "@mui/material";
import {ErrorMessage} from "formik";
import {normalizeGreek} from "../../../helpers/normalizeGreek";

const CatalogDataEntry = ({
                              formik,
                              language,
                              inputVariant,
                              disabled,
                              catalogueProfileLength,
                              catalogueLanguage,
                              countriesEl,
                              countriesEn,
                              phoneCodesEl,
                              phoneCodesEn
                          }) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    return (
        <Grid item xs={12}>
            <Typography
                variant="subtitle2"
                component={"div"}
                sx={{
                    fontWeight: "bold",
                    mb: 3,
                    textAlign: "center",
                    bgcolor: 'grey.400',
                    color: 'grey.400.contrastText'
                }}
            >
                {language === 'el' ? t('DATA ENTRY FOR GREEK CATALOGUE') : t('DATA ENTRY FOR ENGLISH CATALOGUE')}
            </Typography>
            <FormControl
                variant="standard"
                color={'error'}
                fullWidth
                error={
                    formik.touched['brand.' + language] &&
                    Boolean(formik.errors['brand.' + language])
                }
            >
                <TextField
                    InputLabelProps={{shrink: true}}
                    disabled={disabled}
                    sx={{mb: 1.5}}
                    fullWidth
                    required
                    variant={inputVariant}
                    size={"small"}
                    label={t('catalogue_company_name')}
                    name={'brand.' + language}
                    value={showNoValueIfDisabled(formik.values.brand[language], disabled)}
                    onChange={(e) => {
                        const oldValue = e.target.value
                        formik.setFieldValue('brand.' + language, normalizeGreek(oldValue.toUpperCase()))
                    }}
                    onBlur={formik.handleBlur}
                    inputProps={{maxLength: 100}}
                />
                <FormHelperText sx={{color: '#d32f2f'}}>
                    <ErrorMessage name={'brand.' + language}/>
                </FormHelperText>
            </FormControl>
            <FormControl
                variant="standard"
                color={'error'}
                fullWidth
                error={
                    formik.touched['profile.' + language] &&
                    Boolean(formik.errors['profile.' + language])
                }
            >
                <TextField
                    InputLabelProps={{shrink: true}}
                    disabled={disabled}
                    sx={{mb: 1.5}}
                    fullWidth
                    variant={inputVariant}
                    size={"small"}
                    label={`${t('catalogue_company_profile')} ${!disabled ? formik.values.profile[language].length + '/' + catalogueProfileLength : ''}`}
                    name={'profile.' + language}
                    value={showNoValueIfDisabled(formik.values.profile[language], disabled)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiline
                    rows={3}
                    inputProps={{maxLength: catalogueProfileLength ?? 800}}
                />
                <FormHelperText sx={{color: '#d32f2f'}}>
                    <ErrorMessage name={'profile.' + language}/>
                </FormHelperText>
            </FormControl>
            <AddressLine
                t={t}
                disabled={disabled}
                formik={formik}
                language={language}
                inputVariant={inputVariant}
                index={1}
                countriesEl={countriesEl}
                countriesEn={countriesEn}
                phoneCodesEl={phoneCodesEl}
                phoneCodesEn={phoneCodesEn}
                catalogueLanguage={catalogueLanguage}
            />
            <AddressLine
                t={t}
                disabled={disabled}
                formik={formik}
                language={language}
                inputVariant={inputVariant}
                index={2}
                countriesEl={countriesEl}
                countriesEn={countriesEn}
                phoneCodesEl={phoneCodesEl}
                phoneCodesEn={phoneCodesEn}
                catalogueLanguage={catalogueLanguage}
            />
        </Grid>
    )
}

export default CatalogDataEntry;