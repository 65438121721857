import React, {useEffect} from "react";
import Toolbar from "@mui/material/Toolbar";
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput,} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";

const FilterHeaders = ({onFilterChange}) => {
	const {t} = useTranslation(EXHIBITION)
	const [filterByQuery, setFilterByQuery] = React.useState('')
	const [changedByUser, setChangedByUser] = React.useState(false)
	const handleChange = (callback, value) => {
		setChangedByUser(true)
		callback(value)
	}
	useEffect(() => {
		if (changedByUser) {
			onFilterChange({
				query: filterByQuery
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterByQuery])
	return (
		<Toolbar disableGutters>
			<FormControl sx={{mr: 1, width: 240}} size={"small"} variant="outlined">
				<InputLabel htmlFor="filter-query">{t('Title')}</InputLabel>
				<OutlinedInput
					value={filterByQuery}
					onChange={(e) => handleChange(setFilterByQuery, e.target.value)}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								edge="end"
							>
								<SearchIcon/>
							</IconButton>
						</InputAdornment>
					}
					label={t('Title')}
				/>
			</FormControl>
		</Toolbar>
	)
}

export default FilterHeaders