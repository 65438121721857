// @flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseUserMessage} from "../../types/user/parse";
import {parseMultiple, parseMultipleFromReference} from "../../helpers/parse";
import {parseExhibition, parseExhibitionsGroupedHeaders, sortExhibitions} from "../../types/exhibition/parse";
import {useMemo} from "react";
import _ from "lodash";

const selectMessage = createSelector(
	(state) => state.exhibition.get('message'),
	(item) => parseUserMessage(item)
)

const selectLoadingGroupedHeaders = createSelector(
	(state) => state.exhibition,
	(item) => item.get('loadingGroupedHeaders')
)

const selectExhibitionsGroupedHeaders = createSelector(
	[
		(state) => state.exhibition.get('exhibitionsGroupedHeaders'),
		(state) => state.currentUser.get('language'),
	],
	(data, userLanguage) => parseMultiple(data, (i) => parseExhibitionsGroupedHeaders(i, userLanguage))
)

const selectIndexTable = createSelector(
	[
		(state) => state.exhibition.get('indexTable'),
		(state) => state.exhibition.get('byId'),
		(state) => state.currentUser.get('language'),
	],
	(indexTable, byId, language) => ({
		loading: indexTable.get('loading'),
		count: indexTable.get('count'),
		items: sortExhibitions(
			parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseExhibition(i, language))
		),
	})
)

const useExhibitionsData = ({id, overrideLanguage, exhibitorId, visitorId} = {}) => {
	const loadingGroupedHeaders = useSelector(selectLoadingGroupedHeaders)
	const message = useSelector(selectMessage)
	const indexTable = useSelector(selectIndexTable)
	const exhibitionsGroupedHeaders = useSelector(selectExhibitionsGroupedHeaders)
	const exhibitionsGroupedHeadersSorted = useMemo(() => (
		_.sortBy(exhibitionsGroupedHeaders, ['description'])
	), [exhibitionsGroupedHeaders])
	
	return {
		indexTable,
		loadingGroupedHeaders,
		message,
		exhibitionsGroupedHeaders: exhibitionsGroupedHeadersSorted
	}
}

export default useExhibitionsData