// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";

export const useRulesAction = ({exhibitionId}) => {
    const dispatch = useDispatch()
    const getRules = useCallback(() => {
        dispatch(actions.getRules({
            exhibitionId: exhibitionId
        }))
    }, [dispatch, exhibitionId])

    const saveRule = useCallback((values) => {
        dispatch(actions.saveRule({
            Description: values.description,
            ExhibitionId: exhibitionId,
            CharacterizationId: values.characterizationId
        }))
    }, [dispatch, exhibitionId])

    const updateRule = useCallback((values) => {
        dispatch(actions.updateRule({
            ruleId: values.id,
            Description: values.description,
            ExhibitionId: exhibitionId,
            CharacterizationId: values.characterizationId
        }))
    }, [dispatch, exhibitionId])

    const deleteRule = useCallback((ruleId) => {
        dispatch(actions.deleteRule({
            ruleId: ruleId
        }))
    }, [dispatch])

    const getRuleDetails = useCallback((ruleId) => {
        dispatch(actions.getRuleDetails({
            ruleId: ruleId
        }))
    }, [dispatch])

    const saveRuleDetails = useCallback((rowIndex, row) => {
        dispatch(actions.saveRuleDetails({
            RuleId: row.ruleId,
            Field: row.field,
            Type: row.operator,
            Value: row.value
        }))
    }, [dispatch])

    const updateRuleDetails = useCallback((rowIndex, row) => {
        dispatch(actions.updateRuleDetails({
            ruleDetailsId: row.id,
            RuleId: row.ruleId,
            Field: row.field,
            Type: row.operator,
            Value: row.value
        }))
    }, [dispatch])

    const deleteRuleDetails = useCallback((rowIndex, row) => {
        dispatch(actions.deleteRuleDetails({
            ruleDetailsId: row.id
        }))
    }, [dispatch])

    const addRuleDetails = useCallback((ruleId) => {
        dispatch(actions.addRuleDetails({
            ruleId
        }))
    }, [dispatch])

    const editRuleDetails = useCallback((rowIndex, row) => {
        dispatch(actions.editRuleDetails({
            row: row,
            rowIndex: rowIndex
        }))
    }, [dispatch])

    const setRuleDetails = useCallback((updateRows, row) => {
        dispatch(actions.setRuleDetails({
            updateRows: updateRows,
            row: row
        }))
    }, [dispatch])

    const cancelEditRuleDetails = useCallback((rowIndex, row) => {
        dispatch(actions.cancelEditRuleDetails({
            row: row,
            rowIndex: rowIndex
        }))
    }, [dispatch])

    const clearRuleStatus = useCallback(() => {
        dispatch(actions.clearRuleStatus())
    }, [dispatch])

    return {
        getRules,
        saveRule,
        updateRule,
        deleteRule,
        getRuleDetails,
        saveRuleDetails,
        updateRuleDetails,
        deleteRuleDetails,
        clearRuleStatus,
        addRuleDetails,
        editRuleDetails,
        cancelEditRuleDetails,
        setRuleDetails
    }
}