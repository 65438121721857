import type {Interests, UnmappedInterests} from "./type";

export const mapInterests: Array<Interests> = (datum: Array<UnmappedInterests>) => {
    if (!(datum && datum.length > 0)) return []
    return datum.map(d => ({
        id: d.id,
        description: {
            en: d.DescriptionEN.trim(),
            el: d.DescriptionEL.trim()
        },
        code: d.Code,
        weight: Number(d.Ordr),
        hideInPreregistration: d.HidePreregistration === '1'
    }))
}