//@flow
import React from 'react';
import FileUpload from "../../../../../components/form/fileUpload/fileUpload";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Checkbox, DialogActions, FormControl, FormControlLabel} from "@mui/material";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import {FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import * as yup from "yup";
import {Trans, useTranslation} from "react-i18next";
import useExhibitionAction from "../../../../../hooks/exhibitions/useExhibitionAction";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import BulkImportStatus from "../../../../../components/app/alerts/bulkImportStatus/bulkImportStatus";
import LoadingButton from '@mui/lab/LoadingButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {EXHIBITOR_PROFILE} from "../../../../../locales/components/namespaces";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {useHasPermission} from "../../../../../modules/permission/hooks/useHasPermission";
import {ACCEPT_EXCEL, API, Permissions} from "../../../../../helpers/constants";
import {AuthenticatedLink} from "../../../../../components/general/authenticatedLink/authenticatedLink";
import ExcelTemplate from "./excellTemplates/excelTemplate";
import DialogTransition from "../../../../../components/general/dialogTransition/dialogTransition";

type Props = {
    exhibitionId: string,
    exhibitorId?: string,
    show: boolean,
    onClose: Function,
    limitedView?: boolean,
    isShortOrganizerImport?: boolean
}

const ExcelImport = (props: Props) => {
    const {
        show,
        onClose,
        exhibitionId,
        exhibitorId,
        limitedView,
        preregistrationNeedsApproval,
        visitorCategoryRequiredInImport,
        isShortOrganizerImport
    } = props
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {createBulkVisitors, clearBulkVisitorStatus, createBulkVisitorsForBadges} = useExhibitionAction()
    const {bulkImporting, visitorBulkImportStatus} = useExhibitionData()
    const isOrganizer = useHasPermission([Permissions.BeOrganizer], {shouldView: true})

    const onSubmit = (values, {resetForm}) => {
        if (isShortOrganizerImport) {
            createBulkVisitorsForBadges(exhibitionId, values)
        } else {
            createBulkVisitors(exhibitionId, exhibitorId, values)
        }
        resetForm()
    }
    const validationSchema = yup.object({
        files: yup
            .array()
            .min(1)
            .required(t("at least one item needs to be here"))
    })
    const formik = useFormik({
        initialValues: {
            files: [],
            visitorApproved: false
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })
    const onFileChange = (val) => {
        formik.setFieldValue('files', val)
    }

    const isExhibitorTemplate = !isOrganizer || (isOrganizer && exhibitorId && preregistrationNeedsApproval && !formik.values.visitorApproved)
    let url = isExhibitorTemplate ? "/en/visitor/export" :
        isShortOrganizerImport ? "/en/visitor/export-organizer-short" : "/en/visitor/export-organizer"

    return (
        <Dialog
            maxWidth={"md"}
            open={show}
            TransitionComponent={DialogTransition}
            keepMounted
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Box
                    sx={{
                        '& .MuiTextField-root': {my: 1},
                    }}
                    component="form"
                    onSubmit={formik.handleSubmit}
                >
                    <DialogTitle>{t("Import from excel")}</DialogTitle>
                    <DialogContent>
                        <BulkImportStatus
                            onClose={clearBulkVisitorStatus}
                            status={visitorBulkImportStatus}
                        />
                        <DialogContentText component={"div"}>
                            <Trans
                                i18nKey={'import_excel_msg'}
                                ns={EXHIBITOR_PROFILE}
                            >
                                If you want to add more than one people quickly you can upload
                                an <strong>Excel</strong> file.<br/>
                                <ul>
                                    <li>Your excel file should follow the structure described bellow.</li>
                                    <li>Make sure your columns are in order as in the example table.</li>
                                    <li>For your convenience, you you also download our blank excel template.</li>
                                </ul>
                            </Trans>
                        </DialogContentText>
                        <ExcelTemplate
                            isExhibitorTemplate={isExhibitorTemplate}
                            limitedView={limitedView}
                            visitorCategoryRequiredInImport={visitorCategoryRequiredInImport}
                            isShortOrganizerImport={isShortOrganizerImport}
                        />
                        {exhibitorId && isOrganizer && preregistrationNeedsApproval && (
                            <FormControl variant="standard" fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.visitorApproved}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            size={'small'}
                                            name={'visitorApproved'}
                                        />
                                    }
                                    label={t('Visitor Approved')}
                                    labelPlacement="end"
                                />
                            </FormControl>
                        )}
                        <AuthenticatedLink
                            url={API + url}
                            filename={normalizeGreek(t('Download excel template'))}
                        >
                            <Button
                                sx={{mb: 1}}
                                size={"small"}
                                component={"button"}
                                target={"_blank"}
                            >
                                {normalizeGreek(t('Download excel template'))}
                            </Button>
                        </AuthenticatedLink>
                        <FileUpload
                            accept={{[ACCEPT_EXCEL]: ['.xlsx']}}
                            multiple={false}
                            onRemove={onFileChange}
                            onFilesUpload={onFileChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            size={"small"}
                            type={"submit"}
                            disabled={!(formik.isValid && formik.dirty)}
                            endIcon={<UploadFileIcon/>}
                            loading={bulkImporting}
                            loadingPosition="end"
                            variant="contained"
                        >
                            {normalizeGreek(t('Import selected file'))}
                        </LoadingButton>
                        <Button
                            size={"small"}
                            onClick={onClose}>
                            {normalizeGreek(t('Cancel'))}
                        </Button>
                    </DialogActions>
                </Box>
            </FormikProvider>
        </Dialog>

    )
}

export default ExcelImport;