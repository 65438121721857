import React from "react";
import type {FAQ} from "../../../types/exhibition/types";
import {ListItem, ListItemButton} from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemText from "@mui/material/ListItemText";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION} from "../../../routers/routes";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../locales/components/namespaces";

type Props = {
    exhibitionId: string,
    item: FAQ,
}

const ViewRow = ((props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {item: {id, title, isNew}, exhibitionId, depth} = props
    return (
        <ListItem disableGutters>
            <ListItemButton
                sx={{pl: (depth + 1) * 2}}
                to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {
                    id: exhibitionId
                }, {
                    faqId: id,
                })}
                component={Link}
            >
                {depth > 0 ? (
                    <ListItemIcon sx={{paddingRight: 2, minWidth: 24}}>
                        <CircleIcon sx={{fontSize: 7}} />
                    </ListItemIcon>
                ) : null }
                <ListItemText
                    sx={{whiteSpace: "initial"}}
                    primary={isNew ? <span>{title}<sup style={{
                        color: '#d32f2f',
                        paddingLeft: '4px',
                        fontWeight: 'bold'
                    }}>{t('NEW')}</sup></span> : title}
                />
            </ListItemButton>
        </ListItem>
    )
})

export default ViewRow
