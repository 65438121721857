// @flow
import * as React from 'react';
import {useState} from 'react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import {FormControl} from "@mui/material";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import {AuthenticatedLink} from "../../../components/general/authenticatedLink/authenticatedLink";
import {API} from "../../../helpers/constants";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {formatDate} from "../../../helpers/date";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {muiDatePickersLocales} from "../../../helpers/muiGridLocales";
import useUserData from "../../../hooks/user/useUserData";

type Props = {};

export const ExportInExcelPopOver = (props: Props) => {
    const {t} = useTranslation(EXHIBITOR_PROFILE)
    const {language, dateFormat} = useUserData()
    const {exportInExcelAnchor, handleCloseExportInExcel, exhibitionId} = props
    const [confirmedCatalogue, setConfirmedCatalogue] = useState('all')
    const [value, setValue] = React.useState([null, null])
    const updateDates = (newV, index) => {
        const newValue = value.map((v, j) => j === index ? newV : v)
        setValue(newValue)
    }
    return (
        <React.Fragment>
            <Box onClick={(e) => e.stopPropagation()}>
                <Popover
                    disableRestoreFocus
                    onClose={handleCloseExportInExcel}
                    open={Boolean(exportInExcelAnchor)}
                    anchorEl={exportInExcelAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Stack
                        direction={'column'}
                        minHeight={'240px'}
                        maxWidth={'360px'}
                        justifyContent={'space-around'}
                        p={2}
                        width={'100%'}
                    >
                        <Typography
                            variant="body"
                            mb={1}
                            color={'textSecondary'}
                        >
                            {t('Select export filters and press EXPORT.')}
                        </Typography>
                        <FormControl sx={{mb: 1}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}
                                                  localeText={muiDatePickersLocales(language)}>
                                <DatePicker
                                    format={dateFormat}
                                    label={t('From Issue Date')}
                                    value={value[0]}
                                    onChange={(newValue) => {
                                        updateDates(newValue, 0);
                                    }}
                                    renderInput={(params) => <TextField size={"small"} {...params} />}
                                />
                                <Box sx={{my: 0.9}}/>
                                <DatePicker
                                    format={dateFormat}
                                    size={"small"}
                                    label={t('To Issue Date')}
                                    value={value[1]}
                                    onChange={(newValue) => {
                                        updateDates(newValue, 1);
                                    }}
                                    renderInput={(params) => <TextField size={"small"} {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl>
                            <Select
                                variant={'outlined'}
                                value={confirmedCatalogue}
                                onChange={(evt) => setConfirmedCatalogue(evt.target.value)}
                                size={"small"}
                                sx={{minWidth: '220px', mb: 1}}
                            >
                                <MenuItem
                                    value={'all'}
                                >
                                    {t('Both Confirmed and Unconfirmed Catalogues')}
                                </MenuItem>
                                <MenuItem
                                    value={1}
                                >
                                    {t('Confirmed Catalogues Only')}
                                </MenuItem>
                                <MenuItem
                                    value={0}
                                >
                                    {t('Unconfirmed Catalogues Only')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <AuthenticatedLink
                            filename={'export'}
                            url={`${API}/en/exhibition/exportcatalog/${exhibitionId}?${value[0] ? 'FromPublishedAt=' + formatDate(value[0], formatDate.formatTypes.DATE_ONLY_FILTER) : ''}${value[1] ? '&ToPublishedAt=' + formatDate(value[1], formatDate.formatTypes.DATE_ONLY_FILTER) : ''}${confirmedCatalogue !== 'all' ? '&ConfirmedCatalogue=' + confirmedCatalogue : ''}`}
                        >
                            <Button
                                variant={"outlined"}
                                size={"small"}
                            >
                                {normalizeGreek(t('Export'))}
                            </Button>
                        </AuthenticatedLink>
                    </Stack>
                </Popover>
            </Box>
        </React.Fragment>
    );
};