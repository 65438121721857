import {useEffect, useMemo, useState} from "react";
import {useMessageTemplatesAction} from "./useMessageTemplatesAction";
import {useMessageTemplatesData} from "./useMessageTemplatesData";
import messageTemplates from "../../reducer/messageTemplates/messageTemplates";

const useMessageTemplate = (exhibitionId, dontFetchVariables = false, dontFetchTemplate = false) => {
    const [messageTemplateTypeId, setMessageTemplateTypeId] = useState('')
    const {
        loading: loadingMessageTemplate,
        messageTemplate,
        messageTemplateTypes,
        messageTemplateTypeVariables
    } = useMessageTemplatesData()
    const {
        getMessageTemplate,
        getMessageTemplateTypes,
        getMessageTemplateTypeVariables,
        updateMessageTemplate
    } = useMessageTemplatesAction(exhibitionId)

    useEffect(() => {
        getMessageTemplateTypes()
    }, [getMessageTemplateTypes])
    
    useEffect(() => {
        if(messageTemplateTypeId === '' && messageTemplateTypes.length > 0) {
            setMessageTemplateTypeId(messageTemplateTypes[0].id)
        }
    }, [getMessageTemplateTypes, messageTemplateTypeId, messageTemplateTypes])

    useEffect(() => {
        if(!dontFetchVariables && messageTemplateTypeId !== '') {
            getMessageTemplateTypeVariables(messageTemplateTypeId)
        }
    }, [messageTemplateTypeId, getMessageTemplateTypeVariables, dontFetchVariables])

    useEffect(() => {
        if(!dontFetchTemplate && messageTemplateTypeId !== '') {
            getMessageTemplate(messageTemplateTypeId)
        }
    }, [dontFetchTemplate, getMessageTemplate, messageTemplateTypeId])

    const selectedMessageTemplateType = useMemo(() => {
        return messageTemplateTypes.find(f => f.id === messageTemplateTypeId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageTemplateTypeId])

    const onChangeMessageTemplateType = (evt, fetchTemplate = true) => {
        const selectedTypeId = evt.target.value
        setMessageTemplateTypeId(selectedTypeId)
        if(fetchTemplate) {
            getMessageTemplate(selectedTypeId)
        }
    }

    const onSubmit = (values) => {
        updateMessageTemplate(values, messageTemplateTypeId)
    }

    return {
        loadingMessageTemplate,
        messageTemplateTypeId,
        selectedMessageTemplateType,
        messageTemplate,
        messageTemplateTypes,
        messageTemplateTypeVariables,
        onSubmit,
        onChangeMessageTemplateType
    }

}

export default useMessageTemplate