// @flow
import React, {useMemo, useState} from 'react';
import {useExhibitionImages} from "../../../../hooks/exhibitionImages/useExhibitionImages";
import FileUpload from "../../../../components/form/fileUpload/fileUpload";
import {useExhibitionImageAction} from "../../../../hooks/exhibitionImages/useExhibitionImageAction";
import Box from "@mui/material/Box";
import {ListItem, Paper} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import useUserData from "../../../../hooks/user/useUserData";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {DataGridPremium, GridActionsCellItem, GridToolbar} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyToClipboard from "../../../../components/general/copyToClipboard/copyToClipboard";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import {OpenInNew} from "@mui/icons-material";
import DisplayImagePopOver from "./partials/displayImagePopOver";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {
	CustomPagination
} from "../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

type Props = {};

const ImagesForMessages = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {language} = useUserData()
	const {exhibition, loading, id} = useExhibition({language: 'any', noFetchFiles: true})
	const {loading: loadingImages, images} = useExhibitionImages(id)
	const {createExhibitionImage, deleteExhibitionImage} = useExhibitionImageAction()
	const [displayImage, setDisplayImage] = useState({
		anchorEl: null,
		open: false,
		src: ''
	})
	const handleDisplayImage = (value) => {
		setDisplayImage({...value})
	}
	const latestImage = useMemo(() => images.find(image => image.isLatest), [images])
	const columns = useMemo(() => [
		{
			headerName: t('Actions'),
			field: 'actions',
			type: 'actions',
			getActions: (params) => [
				<GridActionsCellItem
					onClick={() => deleteExhibitionImage(id, params.id)}
					icon={<DeleteIcon/>}
					label={t("Remove")}
					color={"error"}
				/>,
				<GridActionsCellItem
					onClick={(evt) => setDisplayImage({
						anchorEl: evt.currentTarget,
						open: true,
						url: params.row.url
					})}
					icon={<OpenInNew/>}
					label={t("OpenInNew")}
					color={"info"}
				/>
			],
		},
		{
			field: 'id',
			headerName: t('id')
		},
		{
			field: 'url',
			headerName: t('Image Url'),
			flex: 1,
			minWidth: '500px',
			renderCell: ({row}) => {
				return (
					<CopyToClipboard
						bgColor={'#eeeeff'}
						text={row.url}
					/>
				)
			}
		},
	], [deleteExhibitionImage, id, t])
	return exhibition ? (
		<Box sx={{maxWidth: '1400px'}}>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
					{t('Home')}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: id})}>
					{exhibition.title[language]}
				</Breadcrumb.Link>
				<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
					{t('Settings')}
				</Breadcrumb.Link>
				<Breadcrumb.Item>
					{t('Images For Emails')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Paper sx={{mb: 1}} variant={"outlined"}>
				<List>
					<ListItem component={"div"}>
						<ListItemText
							primary={
								<Typography component={"div"} variant={"body1"}>
									<Trans
										i18nKey={'exhibition_images'}
										ns={EXHIBITION}
									>
										On this page you can <strong>upload</strong> images and use their link in
										emails.
									</Trans>
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Paper>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Paper sx={{px: 2, pb: 2}} variant={"outlined"}>
						<Toolbar disableGutters variant={"dense"}>
							<Typography variant="h6">
								{t('Image Upload')}
							</Typography>
						</Toolbar>
						<Divider sx={{mb: 2}} light/>
						{latestImage && latestImage.url && (
							<Box p={1}>
								<CopyToClipboard
									bgColor={'#eeeeff'}
									text={latestImage.url}
								/>
							</Box>
						)}
						<FileUpload
							accept={{
								"image/*": [".jpeg", ".png"]
							}}
							multiple={false}
							onFilesUpload={(files) => {
								if (files && files.length > 0) createExhibitionImage(id, files[0])
							}}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Box style={{width: '100%'}}>
						<DataGridPremium
							disableColumnSelector
							sx={{bgcolor: 'background.paper'}}
							density={'compact'}
							columns={columns}
							loading={loadingImages}
							rows={images}
							localeText={muiGridLocales(language)}
							pagination
							slots={{
								toolbar: GridToolbar,
								pagination: CustomPagination
							}}
							slotProps={{toolbar: {csvOptions: {disableToolbarButton: true}}}}
							initialState={{
								sorting: {
									sortModel: [{field: 'id', sort: 'desc'}],
								},
								pagination: {paginationModel: {pageSize: 20}},
								columns: {
									columnVisibilityModel: {
										id: false
									},
								},
								density: 'compact'
							}}
							pageSizeOptions={[5, 10, 20, 50]}
						/>
					</Box>
				</Grid>
			</Grid>
			<DisplayImagePopOver
				displayImage={displayImage}
				handleDisplayImage={handleDisplayImage}
			/>
		</Box>
	) : (
		<ContentLoader
			loading={loading}
		/>
	)
};

export default ImagesForMessages