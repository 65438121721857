//@flow
import { Download } from "@mui/icons-material";
import { ListItem } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { AuthenticatedLink } from "../../../../../components/general/authenticatedLink/authenticatedLink";
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import { FileTypes } from "../../../../../helpers/constants";
import { normalizeGreek } from "../../../../../helpers/normalizeGreek";
import { useExhibition } from "../../../../../hooks/exhibitions/useExhibition";
import useUserData from "../../../../../hooks/user/useUserData";
import { EXHIBITION } from "../../../../../locales/components/namespaces";

const UseFullFiles = (props) => {
	const { t } = useTranslation(EXHIBITION)
	const { files, loadingFiles } = useExhibition()
	const { language } = useUserData()
	const { color } = props
	const fileList = useMemo(() => {
		const customExhibitorButtonStyles = {
			width: '100%',
			backgroundColor: '#FFFFFF',
			whiteSpace: "break-spaces",
			borderLeft: `6px solid ${color}`,
			paddingLeft: '8px',
			paddingRight: '8px',
			justifyContent: 'space-between',
			'& .MuiButton-endIcon': {
				color: color
			},
			'&:hover': {
				backgroundColor: '#FFFFFF',
				boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.1) inset'
			},
		}
		const customExhibitorButtonIconStyles = { fontSize: "1.8rem", paddingRight: "0.2rem", width: "1.8rem" }
		return (
			files
			.filter((f) => (
				f.type === FileTypes.WelcomePage &&
				f.language === language
			))
			.map((file) => (
				<AuthenticatedLink
					key={file.id}
					url={file.url}
					filename={file.name}
				>
					<ListItem disableGutters>
						<Button
							variant="contained"
							sx={customExhibitorButtonStyles}
							endIcon={<Download style={customExhibitorButtonIconStyles}/>}
						>
							{normalizeGreek(t(file.description && file.description !== '' ?
								file.description : file.name))}
						</Button>
					</ListItem>
				</AuthenticatedLink>
			))
		)
	}, [files, language, t])
	return (
		<React.Fragment>
			<Toolbar disableGutters variant={"dense"}>
				<Typography variant="h6">
					{t('Useful Files')}
				</Typography>
			</Toolbar>
			<Divider/>
			<List sx={{ width: "100%" }} dense>
				{files && files.length > 0 ? (
					fileList
				) : (
					<ContentLoader
						loading={loadingFiles}
					/>
				)}
			</List>
		</React.Fragment>
	)
}

export default UseFullFiles;